import { useState } from 'react';
import { useToast } from '../../../hooks/useToast';
import postUploadBulkItems from '../../../queries/menu/postUploadBulkItems';
import { useAppSelector } from '../../../app/hooks';

const useBulkUploadMeals = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ id: number; name: string }[]>();

  const toast = useToast(3000);

  const { inventoryStore } = useAppSelector((state) => state.dashboard);

  const handleUploadItems = async (file: FormData, refetch: () => void) => {
    setIsLoading(true);
    const { result, error } = await postUploadBulkItems({ file, storeId: inventoryStore?.storeId as string });
    setIsLoading(false);
    if (result?.items && result?.items.length > 0) {
      toast('success', 'Meals Uploaded Successfully');
      refetch();
    }
    if (result?.failedItems) {
      setErrors(result?.failedItems);
      toast('error', 'An error occured while creating Items');
      return;
    }
    if (error) {
      toast('error', 'An error occured while creating Items');
    }
  };

  return {
    isLoading,
    handleUploadItems,
    errors,
    setErrors,
  };
};

export default useBulkUploadMeals;
