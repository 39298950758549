import moment from 'moment';
import { FilterConfig, FilterConfigOption } from '../../components/newFilter/defs';
import { bankOptions } from '../../data';

const statusOptions: FilterConfigOption[] = [
  { text: 'Success', value: 'SUCCESS' },
  { text: 'Failed', value: 'FAILED' },
  { text: 'Refund', value: 'REFUND' },
  { text: 'Pending', value: 'PENDING' },
];
const requestOptions: FilterConfigOption[] = [
  { text: 'Approved', value: 'APPROVED' },
  { text: 'Pending Approval', value: 'PENDING_APPROVAL' },
  { text: 'Request Rejected', value: 'REJECTED' },
  { text: 'Request Expired', value: 'EXPIRED' },
];

export const ScheduledFilterConfig: FilterConfig[] = [
  {
    name: 'From',
    type: 'date',
    query: 'startDate',
    defaultValue: moment().startOf('month').toDate(),
    options: [],
    // dateFilterType: 'from',
  },
  {
    name: 'To',
    type: 'date',
    query: 'endDate',
    defaultValue: moment().endOf('day').toDate(),
    options: [],
    // dateFilterType: 'to',
  },
  {
    name: 'Status',
    type: 'select',
    query: 'scheduled.status',
    options: [
      { text: 'Success', value: 'SUCCESS' },
      { text: 'Failed', value: 'FAILED' },
      { text: 'Refund', value: 'REFUND' },
      { text: 'Pending', value: 'PENDING' },
    ],
  },
];

export const TransferFilterConfigV2: FilterConfig[] = [
  {
    name: 'Date',
    type: 'date',
    query: 'dateRange',
    options: [],
  },
  {
    name: 'Status',
    type: 'select',
    query: 'status',
    options: statusOptions,
  },
];

export const TransferRequestsFilterConfig: FilterConfig[] = [
  {
    name: 'Date',
    type: 'date',
    query: 'dateRange',
    options: [],
  },
  {
    name: 'Status',
    type: 'select',
    query: 'status',
    options: requestOptions,
  },
];

export const BeneficiariesFilterConfigV2: FilterConfig[] = [
  {
    name: 'Date',
    type: 'date',
    query: 'dateRange',
    options: [],
  },
  {
    name: 'Bank',
    type: 'select',
    query: 'bank',
    options: bankOptions[0],
  },
];
