import { OutletTeamMemberRole } from '../../types/outlets/outlets';

export type IamRole =
  | 'OPERATOR'
  | 'USER'
  | 'ADMIN'
  | 'OWNER'
  | 'ACCOUNTANT'
  | 'CASHIER'
  | 'MEMBER'
  | 'KITCHEN_STAFF'
  | 'CLIENT_ADMIN'
  | 'IT_DEVELOPER'
  | 'BUSINESS_PARTNER';

type AllRoles = IamRole | OutletTeamMemberRole;

const RoleGuard = ({
  allowedRoles,
  children,
  isOutlet = false,
}: {
  allowedRoles: AllRoles[];
  children: React.ReactNode;
  isOutlet?: boolean;
}) => {
  const role = JSON.parse(localStorage.getItem('IAMuserRole') as '{}') as string;
  const outletRole = JSON.parse(localStorage.getItem('outletRole') as string) as OutletTeamMemberRole;

  return allowedRoles.includes(isOutlet ? outletRole : (role as IamRole)) ? children : null;
};

export default RoleGuard;
