import { createAsyncThunk } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../../app/store';
import { ReduxAction } from '../../../../types/redux';
import axios from '../../../../utils/http';
import { issueResolutionURL } from '../../constants';
import { Issue } from '../../defs';
import { generateIssueRequestBody } from '../../functions';

interface CreateAnIssueResponse {
  message: string;
  data: Issue;
}

export const CreateAnIssue = createAsyncThunk<CreateAnIssueResponse, ReduxAction, { state: RootState }>(
  '/support/create_an_issues',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { fullUser },
        support: {
          issueCreation: { selectedTransaction, selectedType, issueDecription, issueDocumentList },
        },
      } = getState();

      const body = generateIssueRequestBody({
        selectedTransaction,
        selectedType,
        issueDecription,
        issueDocumentList,
        fullUser,
      });

      const data: CreateAnIssueResponse = await axios.post(`/${issueResolutionURL}/ticket`, body);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
