/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography, NTheme as theme } from 'kudi-component-library';

export const PageWrapper = styled.div`
  padding: 2rem;

  @media ${theme.media.lg} {
    padding: 5rem 2rem;
  }
`;

export const OverviewFlexHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 15px;

  @media ${theme.media.md} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 35px;
  }
`;

export const FilterWrapper = styled.div`
  @media ${theme.media.md} {
    width: 50%;
  }
`;

export const OutlineInfoWrapper = styled.div``;

export const TooltipInfoWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const HeaderText = styled.h3<{ marginNumber?: string; fontSize?: string }>`
  margin-top: ${({ marginNumber }) => marginNumber || '2rem'};
  margin-bottom: ${({ marginNumber }) => marginNumber || '2rem'};
  font-style: normal;
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize || '24px'};
  line-height: 40px;
  letter-spacing: -0.03em;
  color: ${theme.colors.primary.green1};

  @media ${theme.media.lg} {
    margin-top: 0;
    margin-bottom: ${({ marginNumber }) => marginNumber || '40px'};
  }
`;
export const SubheaderText = styled.h4`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #121212;
`;
export const SubheaderLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const SubheaderLinkText = styled(Link)`
  color: #717171;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  margin-right: 10px;
`;

export const HeaderTopFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MainAccountHeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media ${theme.media.md} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

// onboarding2

export const OnboardingCardsWrapper = styled.div`
  width: 100%;
  background-color: ${theme.colors.primary.white};
  padding: 2rem;
  border-radius: 5px;
`;

export const OnboardingCardTitle = styled.h4`
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${theme.colors.primary.green1};
  margin-bottom: 2rem;
`;

export const OnboardingCardWrapper = styled.div<{ count?: number }>`
  display: flex;
  flex-wrap: wrap;

  section {
    width: 100%;
    margin: 5px;

    @media ${theme.media.xl} {
      width: ${({ count }) => (count === 3 ? 'calc(32% - 0.5rem)' : '48%')};
      margin: 10px;
    }
  }
`;

export const OnboardingTextWrapper = styled.div`
  justify-content: space-between;
  margin-bottom: 20px;
  display: none;

  @media ${theme.media.sm} {
    display: flex;
  }
`;

export const OnboardingTextWrapperSm = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media ${theme.media.sm} {
    display: none;
  }
`;

export const LeftOnboardingText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
`;

export const RightOnboardingText = styled.p`
  font-weight: 400;
  font-size: 14px;
`;

// onboarding card

export const OnboardingCardSm = styled.section<{ sm?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 1.5rem;
  border: 1px solid ${theme.colors.primary.black6};
  border-radius: 0.5rem;

  ${({ sm }) =>
    sm
      ? `@media ${theme.media.md} {
    display: none;
  }`
      : `display: none`};
`;

export const OnboardingCardSmText = styled.div<{ active?: boolean }>`
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  color: ${({ active }) => (active ? theme.colors.primary.green1 : theme.colors.primary.green2)};
`;

export const CheckboxWrapper = styled.div<{ active?: boolean; size?: string; ml?: string }>`
  width: ${({ size }) => size || '2rem'};
  height: ${({ size }) => size || '2rem'};
  border-radius: 3px;
  background-color: ${({ active }) => (active ? theme.colors.primary.blue1 : theme.colors.primary.black6)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ ml }) => ml || 0};
`;

// large screen

export const OnboardingCard = styled.section<{
  active?: boolean;
  sm?: boolean;
  manualFocus?: boolean;
  horizontal?: boolean;
  available?: boolean;
}>`
  padding: ${({ horizontal }) => (horizontal ? '2.4rem' : '2rem')};
  width: 100%;
  border: 1px solid
    ${({ active, manualFocus }) =>
      active ? theme.colors.primary.blue1 : manualFocus ? theme.nColors['n-dark'] : theme.colors.primary.black6};
  border-radius: 0.5rem;
  display: none;
  cursor: ${({ available }) => (available ? 'pointer' : 'not-allowed')};

  @media ${theme.media.md} {
    border: 1px solid ${({ active }) => (active ? theme.colors.primary.blue1 : theme.colors.primary.black6)};

    :hover {
      border: 1px solid ${theme.colors.primary.blue1};
    }
  }

  ${({ sm }) =>
    sm
      ? `@media ${theme.media.md} {
    display: block;
  }`
      : `display: block`};
`;

export const OnboardingCardIconWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active }) => (active ? theme.colors.primary.blue1 : theme.colors.primary.black6)};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OnboardingCardTop = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
`;

export const OnboardingCardButtom = styled.div<{ active?: boolean }>`
  margin-top: 3rem;
`;

export const OnboardingCardCenter = styled.div`
  width: 94%;
`;

export const OnboardingCardCenterTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OnboardingCardCenterTopStatusWrapper = styled.div<{ color?: string }>`
  padding: 2px 10px;
  background: ${({ color }) => color || theme.nColors['n-yellow1']};
  border-radius: 100px;
`;

export const OnboardingCardMainContent = styled.div<{ horizontal?: boolean }>`
  display: flex;
  width: ${({ horizontal }) => (horizontal ? 'calc(100% - 80px)' : 'fit-content')};
  justify-content: space-between;
`;

export const OnboardingCardSubTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${theme.colors.primary.green1};
  margin-bottom: 10px;
`;

export const OnboardingCardSubText = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #717171;
`;

export const OnboardingCardMobileShow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const OnboardingCardIconWrapperMobile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.nColors['n-grey1']};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    scale: 0.6;
  }
`;

export const OnboardingCardMobileTextHolder = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  max-width: 75%;
`;

// Overview Balance card

export const OverviewBalanceMiniTitle = styled.h3`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 2rem;

  @media ${theme.media.lg} {
    display: none;
  }
`;

export const OverviewBalaceCardWrapper = styled.div`
  padding: 2rem;
  background-color: ${theme.colors.primary.white};
  border-radius: 0.5rem;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid ${theme.nColors['n-grey1']};

  @media ${theme.media.lg} {
    flex-direction: row;
  }
`;

export const OverviewWalletFilterWrapper = styled.div`
  padding: 2rem;
  background-color: ${theme.colors.primary.white};
  height: 50px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    height: max-content;
  }
`;
export const OverviewDateRangeFilterWrapper = styled.div`
  width: 320px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const OverviewWalletFilterMobileDivider = styled.hr`
  display: none;
  @media (max-width: 600px) {
    display: block;
    width: 100%;
    border: 1px solid #c6c6c6;
  }
`;

export const OverviewChartsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const OverviewBarChart = styled.div`
  padding: 2rem;
  background-color: ${theme.colors.primary.white};
  border-radius: 0.5rem;
  width: 60%;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const OverviewDoughnutChart = styled.div`
  padding: 2rem;
  background-color: ${theme.colors.primary.white};
  border-radius: 0.5rem;
  width: 39%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ToolTipWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 32px rgba(31, 47, 70, 0.12);
  border-radius: 10px;
  width: 122px;
  padding: 10px;
`;

export const ToolTipHeader = styled.h3`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #999999;
`;

export const OverviewBalanceCardWrapper = styled.div<{ hasBorderBottom?: boolean }>`
  border-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? `1px solid ${theme.nColors['n-grey4']}` : 'none')};
  padding-bottom: 15px;

  @media ${theme.media.md} {
    border-bottom: none;
    padding: 0;
  }
`;

export const OverviewBalanceFlexWrapper = styled.div`
  display: flex;
  gap: 5px;

  span {
    height: 16px;
    width: 15px;
    margin-top: 3px;
  }
`;

export const TooltipText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #121212;
  margin-bottom: 5px;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  align-items: flex-start;
`;
export const BarLegendWrapper = styled(LegendWrapper)`
  margin-bottom: 80px;
`;
export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
export const LegendIcon = styled.div<{ color?: string; size?: string }>`
  width: ${({ size }) => size || '8px'};
  height: ${({ size }) => size || '8px'};
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;
export const LegendText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
`;
export const LegendTitle = styled.p`
  color: #717171;
  font-weight: 400;
  font-size: 1rem;
`;
export const LegendValue = styled.p`
  color: #121212;
  font-weight: 700;
  font-size: 1.2rem;
`;

export const OverviewBalaceCardLeft = styled.div`
  width: 100%;
  @media ${theme.media.md} {
    display: flex;
    justify-content: space-between;
  }
  @media ${theme.media.lg} {
    display: flex;
    width: 60%;
  }
`;

export const OverviewTopCardBalance = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;

  @media ${theme.media.md} {
    flex-direction: row;
    gap: 30px;
  }
`;

export const OverviewBalaceCardBalance = styled.div<{ flexDirection?: string; justify?: string }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;

  @media ${theme.media.md} {
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    gap: ${({ flexDirection }) => (flexDirection ? '0' : '30px')};
    align-items: ${({ justify }) => justify || 'start'};
  }

  @media ${theme.media.lg} {
    align-items: start;
  }
`;

export const SolidLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SolidLine = styled.span`
  @media ${theme.media.md} {
    border-right: 1px solid ${theme.nColors['n-grey2']};

    height: 40px;
  }
`;

// font-size: 1.4rem;
export const OverviewBalaceCardBalanceText = styled.h4`
  color: #717171;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  @media ${theme.media.lg} {
    margin-bottom: 0.6rem;
  }
`;

export const OverviewBalaceCardBalanceValue = styled.span`
  display: block;
  color: ${theme.colors.primary.green1};
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
`;

export const OverviewBalaceCardRight = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  @media ${theme.media.lg} {
    width: 50%;
    margin-top: 0.7rem;
    justify-content: flex-end;
    button:first-child {
      margin-right: 2rem;
    }
  }
`;

export const OverviewBalaceCardButtonText = styled.h4`
  margin-left: 1rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const OverviewButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 500;
  padding: 0.4rem;
  white-space: nowrap;

  svg {
    margin-right: 1rem;
    width: 16px;
    height: 16px;
    stroke-width: 1.5px;
  }
`;

export const Separator = styled.div<{ height?: string; width?: string }>`
  width: ${({ width }) => width || '0px'};
  height: ${({ height }) => height || '0px'};
`;

export const ViewTransactions = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.primary.white};
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 20px;
  border-top: 1px solid ${theme.colors.primary.black6};
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  text-decoration: none;
  color: ${theme.nColors['n-grey4']};
  :visited {
    color: ${theme.nColors['n-dark']};
  }

  @media ${theme.media.lg} {
    justify-content: center;

    span {
      margin-right: 1rem;
    }
  }
`;

export const MobileRightTop = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${theme.colors.primary.green1};
`;

export const MobileRightBottom = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #717171;
`;

// Welcome modal

export const InfoModalWrapper = styled.div`
  padding-top: 4.2rem;
  padding-bottom: 4rem;
  @media ${theme.media.md} {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoModalTitle = styled.h4`
  margin-top: 6rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.primary.green1};

  @media ${theme.media.lg} {
    margin-top: 5.6rem;
  }
`;

export const InfoModalDescription = styled.p<{ mb?: number }>`
  margin-top: 2rem;
  margin-bottom: 4rem;
  color: #717171;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  width: 100%;
  padding: 0 2.8rem;

  @media ${theme.media.lg} {
    margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : '10.3rem')};
    width: 100%;
    padding: 0;
  }
`;

export const InfoModalSkipButton = styled.button`
  margin-top: 3.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #717171;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @media ${theme.media.lg} {
    margin-top: 2rem;
  }
`;

export const InfoModalButtonWrapper = styled.div`
  width: 100%;

  @media ${theme.media.lg} {
    padding: 0 4rem 2rem;
  }
`;

export const ModalBackButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #121212;
  outline: none;
  height: fit-content;
  padding-bottom: 0.5rem;
  width: fit-content;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #ffcc00;
  margin: 0 auto;
`;

export const ActionModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 4rem 0;

  @media ${theme.media.lg} {
    padding: 0px 1rem 0rem;
  }
`;

export const ActionModalTitle = styled(Typography)`
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const ActionModalDescription = styled(Typography)`
  text-align: center;
  margin-bottom: 4rem;
  @media ${theme.media.lg} {
    margin-bottom: 2rem;
  }
`;

export const InputWrappper = styled.div<{ mb?: string; mbs?: string }>`
  width: 100%;
  margin-bottom: ${({ mbs }) => mbs || '2rem'};
`;

// business set up
export const BusinessSetupPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media ${theme.media.lg} {
    padding: 3rem 5rem;
  }
`;

export const BusinessSetupPageWrapperContent = styled.div`
  width: 100%;
  @media ${theme.media.xl} {
    min-width: 50rem;
    max-width: 75%;
  }
`;

export const BusinessSetupTitle = styled.div`
  margin: 2rem 0 0 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: ${theme.colors.primary.green1};
  width: 100%;

  @media ${theme.media.lg} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const MilestoneWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0rem 0 2rem;
  width: 100%;
  padding: 2rem 0;
  background-color: ${theme.colors.primary.blue6};
  position: sticky;
  top: 0;
  z-index: 40;

  @media ${theme.media.lg} {
    padding: 2rem;
    margin: 0;
    justify-content: flex-start;
  }
`;

// Milestone
export const Milestone = styled.div<{ active?: boolean; completed?: boolean }>`
  display: flex;
  height: 30px;
  background: ${({ completed }) => (completed ? theme.colors.primary.blue1 : theme.colors.primary.white)};
  border: ${({ active }) => (active ? `1px solid ${theme.colors.primary.blue1}` : 'none')};
  box-sizing: border-box;
  border-radius: 100px;
  align-items: center;
  padding: 0.6rem 2.1rem;
  cursor: pointer;

  @media ${theme.media.md} {
    background-color: transparent;
    border: none !important;
  }
`;

export const MilestoneText = styled.h4`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.colors.primary.green1};
  white-space: nowrap;
  @media ${theme.media.md} {
    display: none;
  }
`;

export const MilestoneTextLg = styled.h4`
  display: none;
  @media ${theme.media.md} {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${theme.colors.primary.green1};
    white-space: nowrap;
  }
`;

export const MilestoneIcon = styled.div`
  margin-right: 0.5rem;
  margin-top: 0.3rem;

  @media ${theme.media.md} {
    display: none;
  }
`;

export const MileStoneNUmber = styled.div<{ active?: boolean; completed?: boolean }>`
  display: none;

  @media ${theme.media.md} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    background-color: ${({ active, completed }) => {
      switch (true) {
        case active && !completed:
          return theme.colors.primary.white;

        case completed:
          return theme.colors.primary.blue1;

        default:
          return 'transparent';
      }
    }};
    border: 2px solid
      ${({ completed, active }) => (completed || active ? theme.colors.primary.blue1 : theme.colors.primary.green2)};
    border-radius: 50%;
    margin-right: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    text-align: right;

    /* n-dark */

    color: ${theme.colors.primary.green1};
  }
`;

export const MileStoneSeparator = styled.div`
  display: none;

  @media ${theme.media.md} {
    display: flex;
    flex-direction: column;
    height: 2px;
    width: 100%;
    max-width: 200px;
    background-color: ${theme.colors.primary.green2};
    border-radius: 2px;

    :last-child {
      display: none;
    }
  }
`;

// business details form wrapper
export const BusinessDetailsFormWrapper = styled.div`
  padding: 2rem;
  background-color: ${theme.colors.primary.white};
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${theme.media.lg} {
    padding: 5.6rem;
  }
`;

export const BusinessDetailsFormTitle = styled.h4`
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.primary.green1};
  margin-bottom: 1rem;

  @media ${theme.media.lg} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const BusinessDetailsFormDescrition = styled.h5<{ nmb?: boolean }>`
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #717171;
  margin-bottom: ${({ nmb }) => (nmb ? '0' : '2rem')};

  @media ${theme.media.lg} {
    font-size: 1.6rem;
    line-height: 4rem;
  }
`;

export const BusinessDetailsInputsWrapper = styled.div`
  display: grid;
  width: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 2rem;

  @media ${theme.media.lg} {
    width: 100%;
    grid-template-columns: 1fr 1fr;
    width: 2fr;
    gap: 2rem;
  }
`;

export const DirectorSeparatorWrapper = styled.div`
  margin: 2rem 0 0;
  padding-top: 2rem;
  border-top: 1px solid ${theme.colors.primary.blue6};
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  @media ${theme.media.md} {
    flex-direction: row;
  }
`;

export const AddSecondaryButton = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  background: ${theme.colors.primary.black6};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1.5rem;

  @media ${theme.media.md} {
    width: fit-content;
    height: 5.8rem;
  }
`;

export const AddSecondaryButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.colors.primary.green1};
  margin-left: 1rem;
  white-space: nowrap;
`;

export const DirectorsInputsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 2rem;
  position: relative;
  padding: 2rem 0;

  @media ${theme.media.lg} {
    width: 2fr;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const DirectorRemoveWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0.5rem;
`;

export const DirectorRemove = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${theme.colors.primary.blue6};
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const DetailsButtonWrapper = styled.div`
  width: 100%;
  margin-top: 4rem;

  @media ${theme.media.lg} {
    margin-top: 6rem;
  }
`;

// Tansfer modal content

export const TransferWrapper = styled.div<{ mb?: string; mobileMb?: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px dashed ${theme.colors.primary.blue1};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #121212;
  text-align: start;
  margin-bottom: ${({ mobileMb }) => mobileMb || '3rem'};

  @media ${theme.media.lg} {
    margin-bottom: ${({ mb }) => mb || '9rem'};
  }
`;

export const TransferValue = styled.span`
  text-align: end;
`;

export const TransferNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  span {
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #121212;
  }

  svg {
    margin-left: 0.8rem;
  }
`;

export const BvnCheckWrappper = styled.div`
  margin-bottom: 0.5rem;
`;

export const OBcompleteCOntentWrapper = styled.div`
  width: 100%;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media ${theme.media.lg} {
    padding: 4rem;
  }
`;

export const CopiedIconWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  svg.copied {
    position: initial;
    width: 16px;
    height: 16px;
    path {
      stroke: ${theme.nColors['n-green']};
    }
  }
`;

export const ChangePasswordActionWrapper = styled.div`
  padding: 0 2rem;
  @media ${theme.media.md} {
    padding: 0 4rem;
  }
`;

export const NoShowOnMobile = styled.div`
  position: absolute;
  display: none;
  @media ${theme.media.md} {
    position: relative;
    display: block;
  }
`;

export const NoShowOnDesktop = styled.div`
  position: relative;
  display: block;
  @media ${theme.media.md} {
    position: absolute;
    display: none;
  }
`;

// Settlement modal
export const SettlementModalWrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 7rem;
  @media ${theme.media.md} {
    padding-right: 4rem;
    padding-left: 4rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SettlementModalTitle = styled.div`
  margin-top: 6rem;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.primary.green1};

  @media ${theme.media.lg} {
    margin-top: 5.6rem;
  }
`;

export const SettlementModalTitle2 = styled.div`
  /* margin-top: 6rem; */
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.primary.green1};

  @media ${theme.media.lg} {
    /* margin-top: 5.6rem; */
  }
`;

export const SettlementModalDesc = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #717171;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  width: 100%;
  padding: 0 2.8rem;

  @media ${theme.media.lg} {
    margin-bottom: 10rem;
    width: 100%;
    padding: 0;
  }
`;
export const SettlementModalDesc2 = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #717171;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  width: 100%;
  padding: 0 2.8rem;

  @media ${theme.media.lg} {
    margin-bottom: 2rem;
    width: 100%;
    padding: 0;
  }
`;

export const InputWrapper = styled.div<{ mb?: string; mbs?: string }>`
  width: 100%;
  margin-bottom: ${({ mbs }) => mbs || '2rem'};
`;

export const BalanceMainWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const LedgerBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 36px;
  cursor: pointer;
  background-color: ${theme.nColors['n-grey1']};
  gap: 5px;
`;

export const IntlBandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 36px;
  padding: 8px 0;
  cursor: pointer;
  background-color: ${theme.nColors['n-yellow3']};
  gap: 10px;
`;

export const BalanceInnerWrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  gap: 5px;
`;

export const InfoIconWrapper = styled.span<{ isMasked?: boolean; giveFlex?: boolean }>`
  display: ${({ giveFlex }) => (giveFlex ? 'flex' : 'inline')};
  margin-left: 6px;
  margin-top: 7px;
  width: fit-content;
  cursor: ${({ isMasked = false }) => (isMasked ? 'pointer' : 'default')};

  svg {
    height: ${({ giveFlex }) => (giveFlex ? '16px' : '12px')};
    width: ${({ giveFlex }) => (giveFlex ? '15px' : '12px')};
  }
`;

export const LedgerModalHeader = styled.div`
  background-color: ${theme.nColors['n-grey1']};
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LedgerBalanceInfo = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const LedgerBalanceAmount = styled.div`
  display: flex;
  gap: 10px;
`;

export const LedgerBalanceMainCard = styled.div`
  background-color: ${theme.nColors['n-grey0']};
  margin: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid ${theme.nColors['n-grey1']};
`;

export const LedgerBalanceCard = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${theme.nColors['n-grey1']};

  @media ${theme.media.md} {
    align-items: center;
  }
`;

export const WalletIconWrapper = styled.div<{ active?: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? theme.nColors['n-yellow'] : theme.nColors['n-grey1'])};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileButtons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.md} {
    display: none;
  }
`;
export const MobileArrowBtn = styled.div`
  display: flex;

  @media ${({ theme }) => theme.media.md} {
    display: none;
  }
`;
export const DesktopButtons = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.md} {
    display: flex;
    align-items: center;
  }
`;

// Outlets

export const OutletOverviewBalanceText = styled(Typography)`
  font-weight: 700;
`;

export const OutletBalancesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  @media ${theme.media.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${theme.media.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const OutletOverviewBalaceCardWrapper = styled.div`
  padding: 2rem;
  background-color: ${theme.colors.primary.white};
  border-radius: 0.5rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 15px;

  border: 1px solid ${theme.nColors['n-grey1']};
`;

export const OverviewAnalyticsWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media ${theme.media.md} {
    justify-content: flex-end;
  }
`;

export const AnalyticsInnerWrapper = styled.div`
  border-bottom: 1px solid ${theme.nColors['n-yellow']};
  display: flex;
  align-items: end;
  padding-bottom: 5px;
  gap: 10px;
  cursor: pointer;
`;

export const DottedSquareWrapper = styled.div`
  border: 2px dotted ${theme.nColors['n-yellow']};
  border-radius: 4px;
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
`;
