/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import merchantAxios from '../../utils/merchantAcqServiceHttp';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { BusinessProfile } from '../../types/auth';
import { UpdateBusinessProfileValues } from '../../types/settings';
import { generateBusinessLogo } from '../../utils';
import { AppEnumList } from '../../types/enumTypes';
// ---------- MOCKS ----------//

// ---------------------------   UPDATE BUSINESS PROFILE START   ---------------------------//

export interface UpdateBusinessProfileResponse extends ApiResponse {
  data?: BusinessProfile;
  dataList?: null;
  meta?: null;
}

interface UpdateBusinessProfileArgs extends ReduxAction {
  values: UpdateBusinessProfileValues;
}

export const updateBusinessProfile = createAsyncThunk(
  'update-profile',
  async ({ values, onSuccess, onFailure }: UpdateBusinessProfileArgs, { rejectWithValue }) => {
    try {
      const response: UpdateBusinessProfileResponse = await axios.put(`business/update-business-profile`, values);
      if (onSuccess) onSuccess(response.message);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   UPDATE BUSINESS PROFILE END   ---------------------------//

// ---------------------------   VERIFY FACE EXISTS START   ---------------------------//

export interface VerifyFaceResponse extends ApiResponse {
  data?: BusinessProfile;
  dataList?: null;
  meta?: null;
}

interface VerifyFaceArgs extends ReduxAction {
  usrImg: string;
}

export const verifyFace = createAsyncThunk(
  'verify-face',
  async ({ usrImg, onSuccess, onFailure }: VerifyFaceArgs, { rejectWithValue }) => {
    try {
      const response: VerifyFaceResponse = await axios.post(`/verification/v1/detect-face`, {
        usr_img: usrImg,
      });
      if (onSuccess) onSuccess(response.message);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   VERIFY FACE EXISTS END   ---------------------------//

// ---------------------------   VERIFY CREDENTIALS START   ---------------------------//

export interface VerifyCredentialsResponse extends ApiResponse {
  data?: null;
  dataList?: null;
  meta?: null;
  message: string;
}

interface VerifyCredentialsArgs extends ReduxAction {
  values: {
    userId: number | string;
    credentialType: 'PIN' | 'PASSWORD';
    credentialValue: string;
  };
}

export const verifyCredentials = createAsyncThunk(
  'verify-credentials',
  async ({ values, onSuccess, onFailure }: VerifyCredentialsArgs, { rejectWithValue }) => {
    try {
      const response: VerifyCredentialsResponse = await axios.post(`/user/verify-credential`, values);
      if (onSuccess) onSuccess(response.message);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   VERIFY CREDENTIALS END   ---------------------------//

// ---------------------------   RESET PASSWORD START   ---------------------------//

export interface ResetPasswordResponse extends ApiResponse {
  data?: null;
  dataList?: null;
  meta?: null;
  message: string;
}

interface ResetPasswordArgs extends ReduxAction {
  values: {
    phone: string;
    newPassword: string;
  };
}

export const resetPassword = createAsyncThunk(
  'reset-password',
  async ({ values, onSuccess, onFailure }: ResetPasswordArgs, { rejectWithValue }) => {
    try {
      const response: ResetPasswordResponse = await axios.post(`/user/reset-password`, values);
      if (onSuccess) onSuccess(response.message);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   RESET PASSWORD END   ---------------------------//

// ---------------------------   RESET PIN START   ---------------------------//

export interface ResetPinResponse extends ApiResponse {
  data?: null;
  dataList?: null;
  meta?: null;
  message: string;
}

interface ResetPinArgs extends ReduxAction {
  values: {
    businessId: number | string;
    newPin: string;
  };
}

export const resetPin = createAsyncThunk(
  'reset-pin',
  async ({ values, onSuccess, onFailure }: ResetPinArgs, { rejectWithValue }) => {
    try {
      const response: ResetPinResponse = await axios.post(`/business/reset-pin`, values);
      if (onSuccess) onSuccess(response.message);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   RESET PIN END   ---------------------------//

// ---------------------------   VALIDATE TOKEN START   ---------------------------//

export interface ValidateTokenResponse extends ApiResponse {
  data?: null;
  dataList?: null;
  meta?: null;
  message: string;
}

interface ValidateTokenArgs extends ReduxAction {
  values: {
    phoneNumber: string;
    token: string;
  };
}

export const validateToken = createAsyncThunk(
  'validate-token',
  async ({ values, onSuccess, onFailure }: ValidateTokenArgs, { rejectWithValue }) => {
    try {
      const response: ValidateTokenResponse = await axios.post(`/user/validate-otp`, values);
      if (onSuccess) onSuccess(response.message);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   VALIDATE TOKEN END   ---------------------------//

// ---------------------------   BUSINESS LOGO UPLOAD   ---------------------------//
export interface UploadBusinessLogoArgs {
  file: File;
  businessId: string | number;
  onSuccess?: () => void;
  onFailure?: (error: any) => void;
}
export interface UploadBusinessLogoResponse extends ApiResponse {
  errors?: string[];
  message?: string;
  success: boolean;
  data?: {
    businessId?: string | number;
  };
  uploadId?: string;
}

export const uploadBusinessLogo = createAsyncThunk(
  'upload-business-logo',
  async ({ file, businessId, onSuccess, onFailure }: UploadBusinessLogoArgs, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { success, message }: UploadBusinessLogoResponse = await merchantAxios.post(
        `/webbe/v1/media/profile-image/${businessId}`,
        formData
      );
      if (success) onSuccess?.();
      return message;
    } catch (error) {
      onFailure?.(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   BUSINESS LOGO UPLOAD END ---------------------------//

// ---------------------------   FETCH BUSINESS LOGO ---------------------------//
interface FetchBusinessLogoArgs {
  businessId: string | number;
  onSuccess?: () => void;
  onFailure?: (error: any) => void;
}

export const fecthBusinessLogo = createAsyncThunk(
  'fetch-business-logo',
  async ({ businessId, onSuccess, onFailure }: FetchBusinessLogoArgs, { rejectWithValue }) => {
    try {
      const response = await merchantAxios.get(`/webbe/v1/media/profile-image/${businessId}`);
      if (response) {
        onSuccess?.();
        return generateBusinessLogo(businessId);
      }
      return '';
    } catch (error) {
      onFailure?.(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH BUSINESS LOGO END ---------------------------//

// ---------------------------   FETCH BUSINESS LOGO ---------------------------//
export interface FetchAppEnumListResponse extends ApiResponse {
  status: true;
  message: string;
  data: AppEnumList;
  dataList: null;
  meta: null;
  error: null;
}
interface FetchAppEnumListArgs {
  businessId: string | number;
  onSuccess?: () => void;
  onFailure?: (error: any) => void;
}

export const fetcAppEnumList = createAsyncThunk(
  'fetch-app-enums',
  async ({ businessId, onSuccess, onFailure }: FetchAppEnumListArgs, { rejectWithValue }) => {
    try {
      const data: FetchAppEnumListResponse = await merchantAxios.get(`/types/list`);
      if (onSuccess) onSuccess();
      return data;
    } catch (error) {
      onFailure?.(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH BUSINESS LOGO END ---------------------------//
