import { createSlice } from '@reduxjs/toolkit';
import { fetchQRCode } from './thunkActions';

interface InitialState {
  userId: string;
  code: string;
  isLoading: boolean;
}

const initialState: InitialState = {
  userId: "",
  code: "",
  isLoading: false,
};

export const qrCodeSlice = createSlice({
  name: 'qrCode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch QR Code 
    
    builder
      .addCase(fetchQRCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchQRCode.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchQRCode.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.userId = payload?.userId || "";
        state.code = payload?.code || "";
      });
  },
});

export default qrCodeSlice.reducer;
export * from './thunkActions';