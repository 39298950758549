import dayjs from 'dayjs';
import { DefaultTheme } from 'styled-components';
import { transactionTypes } from '../../../../utils/filterTypes';

export const formatRequestDate = (date?: Date | string) =>
  date ? dayjs(date).format().slice(0, -6) : dayjs().format().slice(0, -6);

export const getResultStatusColor = (state: string, theme: DefaultTheme) => {
  switch (state) {
    case transactionTypes.DELIVERED:
    case transactionTypes.SUCCESS:
    case transactionTypes.PAYMENT_SUCCESSFUL:
      return theme.nColors['n-green'];
    case transactionTypes.PAYMENT_FAILED:
    case transactionTypes.CANCELLED:
    case transactionTypes.REVERSED_BY_VENDOR:
      return theme.nColors['n-red'];
    default:
      return theme.nColors['n-grey5'];
  }
};

export const transformStatustext = (status: string) => {
  switch (status) {
    case transactionTypes.DELIVERED:
    case transactionTypes.SUCCESS:
    case transactionTypes.PAYMENT_SUCCESSFUL:
      return 'success';
    case transactionTypes.PAYMENT_FAILED:
    case transactionTypes.CANCELLED:
    case transactionTypes.REVERSED_BY_VENDOR:
      return 'Failed';
    default:
      return status.toLowerCase();
  }
};
