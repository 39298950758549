import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  position: relative;

  button {
    cursor: pointer;
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f20;
  color: #fff;
`;

export const NotificationDropdownWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 25px 0px #0000000d;
  width: 428px;
  height: 510px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 60px;
  overflow-y: scroll;
  padding: 24px;

  @media (max-width: 600px) {
    width: 100vw;
    right: 0;
    position: fixed;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  button {
    border: none;
    background: none;
  }
`;

export const Content = styled.div`
  display: grid;
  gap: 20px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 380px;
  gap: 8px;
`;

export const ViewContainer = styled.div`
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  padding: 0 24px 24px;
  background: #fff;
`;

export const ViewBtn = styled.button`
  border-radius: 10px;
  height: 41px;
  border: ${({ theme }) => `1px solid ${theme.nColors['n-grey3']}`};
  width: 100%;
  background: none;
`;

export const ItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const ItemContent = styled.div`
  display: flex;
  gap: 13px;

  .texts {
    display: grid;
    gap: 5px;

    .notif {
      width: 314px;
      color: #555555;

      @media (max-width: 600px) {
        width: 220px;
      }
    }

    .time {
      color: #999999;
    }
  }
`;

export const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
`;

export const DetailsContainer = styled.div`
  .header {
    display: flex;
    alignitems: center;
    justify-content: center;
    margin-bottom: 23px;
    position: relative;

    button {
      position: absolute;
      left: 0;
      background: none;
      border: none;
    }
  }

  .body {
    display: grid;
    margin-top: 20px;
    gap: 20px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  padding: 24px;
  background: #fafafa;
  display: flex;
  gap: 16px;
`;
