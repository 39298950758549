import { NIcons } from 'kudi-component-library';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const FilterContainer = styled(Flex)<{ alignItems?: string }>`
  @media ${({ theme }) => theme.media.md} {
    align-items: ${({ alignItems }) => alignItems || 'unset'};
  }
`;

export const FilterMainWrapper = styled(Flex)`
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    align-items: center;
  }
`;
export const FilterWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    width: 180px;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  gap: 10px;
  padding: 20px;

  @media ${({ theme }) => theme.media.md} {
    width: 180px;
    justify-content: center;
  }
`;
export const FilterItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px;
  width: 100%;
  z-index: 1;
  margin-top: 10px;
  padding: 20px;
  box-shadow: 0px 8px 20px rgba(196, 196, 196, 0.3);
  right: 0;

  @media ${({ theme }) => theme.media.md} {
    width: 385px;
  }
`;
export const FilterItemWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  margin-bottom: 20px;
  height: 50px;
`;
export const FilterItemHeaderWrapper = styled.div<{ show?: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  align-items: center;
  border-radius: 0.5rem;
  justify-content: space-between;
  cursor: pointer;

  svg:not(.filter-icon-svg) {
    transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;
export const FilterIcon = styled(NIcons)`
  stroke-width: 1;
`;
export const FlexGap = styled(Flex)<{ gap?: string }>`
  align-items: center;
  gap: ${({ gap }) => gap || '10px'};
`;
export const FilterItemDropDown = styled.div`
  position: absolute;
  bottom: 0;
  top: initial;
  @media ${({ theme }) => theme.media.md} {
    top: 6rem;
    bottom: initial;
    left: 0;
  }

  max-height: 400px;
  overflow-y: scroll;

  left: 0;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  width: 100%;
  overflow-y: auto;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  z-index: 3;
`;
export const FilterItemDropDownItem = styled.div<{ active?: boolean }>`
  padding: 1.4rem 2rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.green1};
  background-color: ${({ active, theme }) => (active ? theme.nColors['n-grey2'] : 'white')};
  cursor: pointer;

  :hover {
    background-color: ${({ active, theme }) => (active ? theme.nColors['n-grey2'] : theme.nColors['n-grey1'])};
  }
`;
export const AppliedFiltersWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  justify-content: start;

  @media ${({ theme }) => theme.media.md} {
    margin-top: 10px !important;
    width: fit-content;
  }
`;
export const AppliedFilterWrapper = styled(Flex)`
  justify-content: center;
  gap: 5px;
  align-items: center;
  padding: 5px 10px;
  height: 28px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  max-width: fit-content;
`;
export const AppliedFilterIconWrapper = styled(Flex)`
  cursor: pointer;
`;
export const FilterActionsWrapper = styled(Flex)`
  justify-content: end;
  align-items: center;
  gap: 20px;
`;
export const CalendarFilterWrapper = styled.div`
  position: absolute;
  top: initial;
  bottom: 34.5rem;
  left: 0;

  @media ${({ theme }) => theme.media.md} {
    position: absolute;
    bottom: initial;
    top: 6rem;
  }

  .react-date-picker {
    display: inline-flex;
    position: relative;
  }
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid gray;
  }
  .react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }
  .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
  }
  .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-date-picker__button {
    border: 0;
    padding: 4px 6px;
  }
  .react-date-picker__button:enabled {
    cursor: pointer;
  }
  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
  }
  .react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-date-picker__button svg {
    display: inherit;
  }

  .react-calendar__navigation__label {
    grid-column: 1;
    grid-row: 1;
  }

  .react-calendar__navigation {
    display: grid;
    grid-template-columns: 5fr repeat(4, 1fr);
  }
  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-date-picker__calendar--closed {
    display: none;
  }
  .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }

  /* custom css */
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__wrapper {
    border: none;
  }
  .react-date-picker__calendar {
    top: -4px !important;
  }
  .react-date-picker__calendar .react-calendar {
    border: 1px solid #eaedf3;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }

  .react-calendar__month-view__weekdays {
    margin: 0 20px;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 12px 0 7px;
    color: ${({ theme }) => theme.nColors['n-dark']};

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days {
    margin: 0 20px 30px 20px;
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.nColors['n-grey1']};
    border-radius: 2px;
    color: ${({ theme }) => theme.nColors['n-light']};
  }

  .react-calendar__tile .react-calendar__month-view__days__day {
    color: ${({ theme }) => theme.nColors['n-grey4']} !important;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.nColors['n-grey4']} !important;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd
    .react-calendar__tile--rangeBothEnds
    .react-calendar__month-view__days__day
    .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.nColors['n-light']} !important;
  }

  .react-calendar__tile {
    color: ${({ theme }) => theme.nColors['n-grey4']};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .react-calendar__tile .react-calendar__tile--active {
    color: ${({ theme }) => theme.nColors['n-light']} !important;
  }

  .react-calendar__tile--active {
    background: #212121;
    border-radius: 2px;
    color: ${({ theme }) => theme.nColors['n-light']} !important;
  }

  .react-calendar__tile--active .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.nColors['n-light']};
  }

  .react-calendar__tile .react-calendar__month-view__days__day {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'cv11' on;
    color: ${({ theme }) => theme.nColors['n-grey4']} !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #aeacac !important;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #18379a;
  }

  .react-date-picker__inputGroup {
    height: 0;
    width: 0;
    opacity: 0;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: ${({ theme }) => theme.nColors['n-yellow']};
    border-radius: 2px;
  }

  .react-calendar__tile:disabled {
    background: ${({ theme }) => theme.nColors['n-yellow']} !important;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .react-date-picker {
    position: absolute;
  }

  .react-date-picker .react-date-picker--closed .react-date-picker--enabled {
    position: absolute;
  }

  .react-date-picker__button {
    height: 0;
    width: 0;
    opacity: 0;
  }
`;
