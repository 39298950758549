import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Customers = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M9.647 3.824a2.059 2.059 0 1 0 0 4.117 2.059 2.059 0 0 0 0-4.117ZM6.765 5.882a2.882 2.882 0 1 1 5.764 0 2.882 2.882 0 0 1-5.764 0ZM3.882 7.118a1.235 1.235 0 1 0 0 2.47 1.235 1.235 0 0 0 0-2.47ZM1.824 8.353a2.059 2.059 0 1 1 4.117 0 2.059 2.059 0 0 1-4.117 0Zm13.588-1.235a1.235 1.235 0 1 0 0 2.47 1.235 1.235 0 0 0 0-2.47Zm-2.06 1.235a2.059 2.059 0 1 1 4.119 0 2.059 2.059 0 0 1-4.118 0Zm-3.705 2.882a3.707 3.707 0 0 0-3.706 3.706v1.236h7.412V14.94a3.707 3.707 0 0 0-3.706-3.706Zm4.53 4.942h3.294V14.94a2.059 2.059 0 0 0-3.529-1.442c.152.454.235.939.235 1.442v1.236Zm-.588-3.468a4.528 4.528 0 0 0-3.942-2.297c-1.69 0-3.163.925-3.942 2.296A2.882 2.882 0 0 0 1 14.942v1.647c0 .228.184.412.412.412h16.47a.412.412 0 0 0 .412-.412v-1.647a2.882 2.882 0 0 0-4.705-2.232Zm-8.237.79a2.059 2.059 0 0 0-3.529 1.442v1.236h3.295V14.94c0-.503.082-.988.234-1.442Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Customers;
