import { Typography } from 'kudi-component-library';
import { OfficeItemWrapper } from '../../styles';
import { Separator } from '../../../overview/styles';

const OfficeItem = ({
  location,
  address,
  extra,
  href,
}: {
  location: string;
  address: string;
  extra?: React.ReactNode;
  href?: string;
}) => (
  <OfficeItemWrapper href={href}>
    <Typography as="h4" fontSize="16px" fontWeight={500} lineHeight="24px" color="n-grey3a">
      {location}
    </Typography>
    <Typography as="p" fontSize="14px" lineHeight="22px" fontWeight={400}>
      {address}
    </Typography>

    {extra ? (
      <>
        <Separator height="18px" />
        <Typography as="p" fontSize="14px" lineHeight="22px" fontWeight={400}>
          {extra}
        </Typography>
      </>
    ) : null}
  </OfficeItemWrapper>
);

export default OfficeItem;
