import appConfig from '../../config';
import { NmMode } from '../../constants';

const matchParam = appConfig.onbaordingV2Param;

const jsonValue = localStorage.getItem(NmMode);

const NmModeValue = jsonValue ? (JSON.parse(jsonValue) as string) : '';

const IS_ONBOARDING_V2_ON = appConfig.onboardingV2Flipper === 'ON' || NmModeValue === matchParam;

export { IS_ONBOARDING_V2_ON };
