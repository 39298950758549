import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif !important; }
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}
#root{
  margin:0 auto;   
}
* {
  overscroll-behavior-y: contain;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  font-feature-settings: "pnum" 1, "lnum" 1, "cv11" 1;
  font-family: 'Inter var', sans-serif !important;
  font-weight: normal;
  font-style: normal;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
input:focus {
  outline: none;
}
input::placeholder {
  color: #999999;
}
   #nprogress {
  pointer-events: none;
}
.transfer-modal-amount {
  border: none !important;
  background-color: #fff !important;
  text-align: center !important;
  color: #121212 !important;
  font-size: 33px !important;
  font-weight: bold !important;
}
#nprogress .bar {
  background: #FFCC00;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #FFCC00, 0 0 5px #FFCC00;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #FFCC00;
  border-left-color: #FFCC00;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill:disabled {
  -webkit-box-shadow: 0 0 0 30px #EFEFEF inset !important;
}
#searchInput {
  font-size: 16px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.select__input{
  color: transparent !important;
}

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0.9;
    }
    80% {
      transform: translateY(-5%);
      opacity: 1;
    }

    100% {
      transform: scale(100%);
    }
  }

  @-webkit-keyframes slideUp {
    0% {
      -webkit-transform: translateY(100%);
      opacity: 0.9;
    }
    90% {
      -webkit-transform: translateY(-5%);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(100%);
    }
  }

    @keyframes slideIn {
      0% {
        transform: translateX(100%);
        opacity: 0.9;
      }
      60% {
        transform: translateX(-2%);
        opacity: 1;
      }

      100% {
        transform: scale(100%);
      }
    }

    @-webkit-keyframes slideIn {
      0% {
        -webkit-transform: translateX(100%);
        opacity: 0.9;
      }
      60% {
        -webkit-transform: translateX(-2%);
        opacity: 1;
      }

      100% {
        -webkit-transform: scale(100%);
      }
    }

    .CircularProgressbar-path {
    stroke: #FFCC00 !important;
  }
  .CircularProgressbar-trail {
    stroke: #F2F2F2 !important;
  }
`;
