/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import {
  fetchActiveTerminals,
  fetchRequestedTerminals,
  fetchTerminalPrices,
  requestTerminal,
  activateSingleTerminal,
  fetchTerminalTransactions,
  fetchTerminalInformation,
  updateTerminalInformation,
  exportTerminalData,
  searchActivatedTerminal,
  fetchTerminalByRequestID,
  fetchTerminalOverview,
} from './thunkActions';
import {
  ActiveTerminal,
  RequestedTerminal,
  SubscriptionTerminalTransaction,
  TerminalOverview,
  TerminalPrice,
  TerminalTransactions,
} from '../../types/terminals';

interface InitialState {
  active: Array<ActiveTerminal>;
  terminalTransactions: Array<TerminalTransactions>;
  requested: Array<RequestedTerminal>;
  priceList: Array<TerminalPrice>;
  isLoading: boolean;
  filters: Record<string, string | Date>;
  isFetchingTerminalPrices: boolean;
  showTerminalActivationDropdown: boolean;
  showSingleTerminalActivationModal: boolean;
  showMultipleTerminalActivationModal: boolean;
  isCreatingTerminalRequest: boolean;
  isActivatingTerminal: boolean;
  terminalInformation: Array<ActiveTerminal>;
  isUpdatingTerminal: boolean;
  isExportingTerminalData: boolean;
  subscriptionTerminalTransactions: Array<SubscriptionTerminalTransaction>;
  terminalOverview?: TerminalOverview;
}

const initialState: InitialState = {
  isLoading: false,
  isFetchingTerminalPrices: false,
  isCreatingTerminalRequest: false,
  isExportingTerminalData: false,
  active: [],
  requested: [],
  priceList: [],
  terminalTransactions: [],
  terminalInformation: [],
  showTerminalActivationDropdown: false,
  showSingleTerminalActivationModal: false,
  showMultipleTerminalActivationModal: false,
  isActivatingTerminal: false,
  filters: {},
  isUpdatingTerminal: false,
  subscriptionTerminalTransactions: [],
  terminalOverview: {
    amountPaid: 0,
    amountLeft: 0,
    startedOn: null,
    endsOn: null,
    nextPaymentAmount: 0,
    nextPaymentDate: null,
  },
};

export const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    setShowActivateTerminalDropdown: (state, action: PayloadAction<boolean>) => {
      state.showTerminalActivationDropdown = action.payload;
    },
    setSingleTerminalActivationModal: (state, action: PayloadAction<boolean>) => {
      state.showSingleTerminalActivationModal = action.payload;
    },
    setMultipleTerminalActivationModal: (state, action: PayloadAction<boolean>) => {
      state.showMultipleTerminalActivationModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch terminals price list

    builder
      .addCase(fetchTerminalPrices.pending, (state) => {
        state.isFetchingTerminalPrices = true;
      })
      .addCase(fetchTerminalPrices.rejected, (state) => {
        state.isFetchingTerminalPrices = false;
      })

      .addCase(fetchTerminalPrices.fulfilled, (state, { payload }) => {
        state.isFetchingTerminalPrices = false;
        state.priceList = payload || [];
      });

    //   fetch active terminals
    builder
      .addCase(fetchActiveTerminals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActiveTerminals.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchActiveTerminals.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.active = payload || [];
        // initializeActivatedTerminalsPagination(state, payload ?? []);
      });

    //   fetch requested terminals
    builder
      .addCase(fetchRequestedTerminals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRequestedTerminals.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchRequestedTerminals.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.requested = payload || [];
        // initializeRequestedTerminalsPagination(state, payload ?? []);
      });

    //   fetch requested terminals
    builder
      .addCase(searchActivatedTerminal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchActivatedTerminal.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(searchActivatedTerminal.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.active = payload || [];
      });

    // request terminal
    builder
      .addCase(requestTerminal.pending, (state) => {
        state.isCreatingTerminalRequest = true;
      })
      .addCase(requestTerminal.rejected, (state) => {
        state.isCreatingTerminalRequest = false;
      })
      .addCase(requestTerminal.fulfilled, (state) => {
        state.isCreatingTerminalRequest = false;
      });

    // activate terminal
    builder
      .addCase(activateSingleTerminal.pending, (state) => {
        state.isActivatingTerminal = true;
      })
      .addCase(activateSingleTerminal.rejected, (state) => {
        state.isActivatingTerminal = false;
      })
      .addCase(activateSingleTerminal.fulfilled, (state) => {
        state.isActivatingTerminal = false;
      });

    // fetch terminal transactions
    builder
      .addCase(fetchTerminalTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTerminalTransactions.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchTerminalTransactions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.terminalTransactions = payload || [];
      });

    // fetch terminal information
    builder
      .addCase(fetchTerminalInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTerminalInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchTerminalInformation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.terminalInformation = payload || [];
      });

    // update terminal information
    builder
      .addCase(updateTerminalInformation.pending, (state) => {
        state.isUpdatingTerminal = true;
      })
      .addCase(updateTerminalInformation.rejected, (state) => {
        state.isUpdatingTerminal = false;
      })
      .addCase(updateTerminalInformation.fulfilled, (state) => {
        state.isUpdatingTerminal = false;
      });

    // export terminal data
    builder
      .addCase(exportTerminalData.pending, (state) => {
        state.isExportingTerminalData = true;
      })
      .addCase(exportTerminalData.rejected, (state) => {
        state.isExportingTerminalData = false;
      })
      .addCase(exportTerminalData.fulfilled, (state) => {
        state.isExportingTerminalData = false;
      });

    //   fetch requested terminals
    builder
      .addCase(fetchTerminalByRequestID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTerminalByRequestID.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchTerminalByRequestID.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.subscriptionTerminalTransactions = payload || [];
      });

    //   fetch terminal overview
    builder
      .addCase(fetchTerminalOverview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTerminalOverview.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchTerminalOverview.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.terminalOverview = payload;
      });
  },
});

export const { setShowActivateTerminalDropdown, setSingleTerminalActivationModal, setMultipleTerminalActivationModal } =
  terminalSlice.actions;
export default terminalSlice.reducer;
export * from './thunkActions';
