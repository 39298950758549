import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { useNavigate } from 'react-router-dom';

import {
  NotificationMobileClose,
  NotificationMobileHeaderTop,
  NotificationMobileHeaderTopSettings,
  NotificationMobileHeaderWrapper,
} from '../../styles';
import { NavigationTab } from '../../../../components/navigationTab';
import { toggleMobileActions } from '../../reducer/slice';
import useRedux from '../../../../hooks/useRedux';

export const NotificationMobileHeaderUI = ({
  onClose,
  openMenu,
  activeRoute,
  routes,
}: {
  onClose: () => void;
  openMenu: () => void;
  activeRoute: string;
  routes: Record<string, string>;
}) => (
  <NotificationMobileHeaderWrapper>
    <NotificationMobileHeaderTop>
      <NotificationMobileClose onClick={onClose}>
        <NIcons icon={IconsEnum.OUTLINE_X} />
      </NotificationMobileClose>
      <Typography fontWeight={700} fontSize="20px" lineHeight="24.2px">
        Notification
      </Typography>
      <NotificationMobileHeaderTopSettings onClick={openMenu} data-testid="HeaderToggle">
        <NIcons icon={IconsEnum.OUTLINE_SETTINGS} />
      </NotificationMobileHeaderTopSettings>
    </NotificationMobileHeaderTop>
    <NavigationTab
      active={activeRoute}
      options={Object.values(routes).map((item) => ({
        text: item,
        route: item,
      }))}
    />
  </NotificationMobileHeaderWrapper>
);

const NotificationMobileHeader = ({ activeRoute, routes }: { activeRoute: string; routes: Record<string, string> }) => {
  const { dispatch } = useRedux();
  const navigate = useNavigate();

  const openMenu = () => {
    dispatch(toggleMobileActions());
  };
  return (
    <NotificationMobileHeaderUI
      onClose={() => navigate('/dashboard/overview/')}
      openMenu={openMenu}
      routes={routes}
      activeRoute={activeRoute}
    />
  );
};

export default NotificationMobileHeader;
