import Modal from '../../../components/Modal';
import { Button, Typography } from 'kudi-component-library';
import { Clock } from '../../../assets/svg';
import {
  ActionWrapper,
  LogoutOutlineButton,
  TextSection,
  WarningClockWrapper,
  WarningModalContent,
} from './components';
import { Separator } from '../../../styles/generic';
import useCountdown from '../../../hooks/useCountdown';

const SessionWarningModal = ({
  showModal,
  onClose,
  logOutFn,
  getRemainingTime,
  handleContinueSession,
}: {
  showModal: boolean;
  onClose: () => void;
  logOutFn: () => void;
  getRemainingTime: () => number;
  handleContinueSession: () => void;
}) => {
  const initialTime = Math.floor(getRemainingTime() / 1000);
  const { minutes, seconds } = useCountdown(initialTime);

  return (
    <Modal showModal={showModal} onClose={onClose}>
      <WarningModalContent>
        <WarningClockWrapper>
          <Clock width={48} height={48} />
        </WarningClockWrapper>
        <Separator height="20px" />
        <TextSection>
          <Typography variant="n-text5">You will be logged out soon</Typography>
          <Typography variant="n-text3" fontWeight="light">
            For your security, you will be logged out in <br />
            <Typography as="span" variant="n-text3" fontWeight="heavy" color="n-yellow8">
              {' '}
              {minutes}m:{seconds}s
            </Typography>{' '}
            due to inactivity
          </Typography>
        </TextSection>
        <ActionWrapper>
          <LogoutOutlineButton variant="small" color="tertiary" onClick={logOutFn}>
            Log out now
          </LogoutOutlineButton>
          <Button variant="small" onClick={handleContinueSession}>
            Continue Session
          </Button>
        </ActionWrapper>
      </WarningModalContent>
    </Modal>
  );
};

export default SessionWarningModal;
