import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const MakePaymentTransfer = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M5.31 5.788a.722.722 0 0 0-.722.722v9.123c0 .399.324.722.722.722h3.65a.494.494 0 1 1 0 .988H5.31a1.71 1.71 0 0 1-1.71-1.71V6.51c0-.944.766-1.71 1.71-1.71h13.38c.944 0 1.71.766 1.71 1.71v6.082a.494.494 0 1 1-.988 0V6.51a.722.722 0 0 0-.722-.722H5.31Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M3.6 9.551c0-.273.221-.494.494-.494h15.812a.494.494 0 1 1 0 .989H4.094A.494.494 0 0 1 3.6 9.55ZM6.641 13.808c0-.273.221-.494.494-.494H8.96a.494.494 0 1 1 0 .988H7.135a.494.494 0 0 1-.494-.494ZM11.506 16.849c0-.273.221-.494.494-.494h7.906a.494.494 0 1 1 0 .988H12a.494.494 0 0 1-.494-.494Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M17.123 14.067a.494.494 0 0 1 .7 0l2.432 2.432a.494.494 0 0 1 0 .7l-2.433 2.432a.494.494 0 1 1-.698-.699l2.083-2.083-2.084-2.083a.494.494 0 0 1 0-.7Z"
      clipRule="evenodd"
    />
  </svg>
);

export default MakePaymentTransfer;
