import {
  ICreateInventoryProduct,
  CreateProductResponse,
  ICreateProductResponse,
} from '../../../types/inventoryProducts';
import axios from '../../../utils/merchantAcqServiceHttp';

const createStorehouseProduct = async (payload: ICreateInventoryProduct, outletId?: string) => {
  try {
    const { data }: ICreateProductResponse = await axios.post('/webbe/v1/storehouse/items', payload, {
      headers: { ...(outletId && { outletId }) },
    });

    return Promise.resolve<CreateProductResponse>({ error: null, data, status: true });
    // disabling lint rule because dependencies have in HQ menu and Inventory use this error message as is.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return Promise.resolve<CreateProductResponse>({
      error: error?.data?.errors[0] || error?.data?.message,
      data: null,
      status: false,
    });
  }
};

export default createStorehouseProduct;
