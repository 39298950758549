/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestHeaders } from 'axios';
import axios from '../../utils/merchantAcqServiceHttp';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { customFilterDateFormatter } from '../../utils';
import {
  IFields,
  ActiveTerminal,
  RequestedTerminal,
  TerminalPrice,
  TerminalTransactions,
  TerminalOverview,
  SubscriptionTerminalTransaction,
} from '../../types/terminals';
import { formatRequestDate } from '../../components/header/components/GlobalSearch/utils';
import { DownloadAPIResponse } from '../../types/download';

// ---------- MOCKS ----------//

// ---------------------------   FETCH TERMINALS PRICELIST STARTS HERE  ---------------------------//

interface FetchTerminalPricesArgs extends ReduxAction {
  id: string | number | undefined | null;
}

interface FetchTerminalInformationArgs extends ReduxAction {
  id: string | number | undefined | null;
  terminalId: string | number | undefined | null;
  outletId?: string;
}

interface UpdateTerminalInformationArgs extends ReduxAction {
  terminalId?: string;
  outletId?: string;
  id: string | number | undefined | null;
  string?: string;
  terminalLabel?: string;
  narrationName?: string;
  merchantName?: string;
}

export interface FetchTerminalPricesResponse extends ApiResponse {
  data?: Array<TerminalPrice>;
  meta?: null;
}

export interface FetchTerminalTransactionsResponse extends ApiResponse {
  data: Array<TerminalTransactions>;
  prev?: string;
  next?: string;
  query?: string;
  message: string;
}

export interface ActivateMultipleTerminalArgs extends ReduxAction {
  file: File;
  headers?: AxiosRequestHeaders;
  isOutlet?: boolean;
}

export interface FetchTerminalTransactionsArgs extends ReduxAction {
  businessId: string | number | null;
  terminalId: string;
  dateParams: { startDate: Date | string; endDate: Date | string };
  configFilters: Record<string, string>;
  limit: number;
  outletId?: string;
}

export interface ExportTerminalDataArgs extends ReduxAction {
  id: string | number | null;
  terminalId: string;
  outletId?: string;
  dateParams: { startDate: Date | string; endDate: Date | string };
}

interface FetchActiveTerminalsArgs extends ReduxAction {
  id: number | string;
  limit: number;
  page: number;
}

export interface FetchActiveTerminalsResponse extends ApiResponse {
  data: Array<ActiveTerminal>;
  message: string;
  status: boolean;
  successful: boolean;
}

export interface ActivateTerminalArgs extends ReduxAction {
  values: {
    serialNumber: string | number;
    businessId: string | number;
    terminalLabel: string;
  };
  headers?: AxiosRequestHeaders;
  isOutlet?: boolean;
}

interface RequestTerminalsArgs extends ReduxAction {
  values: {
    quantity: number;
    pricingListingId: string | number;
    businessId: string | number;
    deliveryAddress: string;
  };
}

interface CancelTerminalFreeTrialArgs extends ReduxAction {
  values: {
    posRequestId: string;
    reason: string;
  };
}

interface FetchRequestedTerminalsArgs extends ReduxAction {
  id: number | string;
  limit: number;
  page: number;
  dateParams?: { startDate: Date | string; endDate: Date | string };
  type?: string;
}

export interface FetchRequestedTerminalsResponse extends ApiResponse {
  data: Array<RequestedTerminal> | null;
  meta: null;
}

interface SearchActivatedTerminalArgs extends ReduxAction {
  searchValue: string;
  businessId: string | number | null;
}

export const fetchTerminalPrices = createAsyncThunk(
  '/terminals/fetch-prices',
  async ({ onSuccess, onFailure, id }: FetchTerminalPricesArgs, { rejectWithValue }) => {
    try {
      const response: FetchTerminalPricesResponse = await axios.get(
        `webbe/v1/terminals/requests/price-list/${id as string}`
      );
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH TERMINALS PRICELIST ENDS HERE  ---------------------------//

// ---------------------------   FETCH ACTIVE TERMINALS STARTS HERE  ---------------------------//

export const fetchActiveTerminals = createAsyncThunk(
  '/terminals/active-terminals',
  async ({ id, page, limit, onSuccess, onFailure }: FetchActiveTerminalsArgs, { rejectWithValue }) => {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.toString());
    searchParams.append('limit', limit.toString());
    try {
      const { data }: FetchActiveTerminalsResponse = await axios.get(`/webbe/v1/terminals/${id}`, {
        params: searchParams,
      });
      if (onSuccess) onSuccess(data || []);
      return data || [];
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH REQUESTED TERMINALS STARTS HERE  ---------------------------//

export const fetchRequestedTerminals = createAsyncThunk(
  '/terminals/requested-terminals',
  async (
    { id, page, limit, dateParams, type, onSuccess, onFailure }: FetchRequestedTerminalsArgs,
    { rejectWithValue }
  ) => {
    try {
      const { data }: FetchRequestedTerminalsResponse = await axios.get(
        `/webbe/v1/terminals/requests/filter/${id}?page=${page}&limit=${limit}${
          dateParams
            ? `&dateFrom=${customFilterDateFormatter(dateParams.startDate)}&dateTo=${customFilterDateFormatter(
                dateParams.endDate
              )}`
            : ''
        }&type=${type as string}`
      );
      if (onSuccess) onSuccess(data || []);
      return data || [];
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH REQUESTED TERMINALS ENDS HERE  ---------------------------//

// ---------------------------   REQUEST TERMINALS STARTS HERE  ---------------------------//

export const requestTerminal = createAsyncThunk(
  '/terminals/request',
  async ({ values, onSuccess, onFailure }: RequestTerminalsArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/webbe/v1/terminals/requests/create`, values);
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   REQUEST TERMINALS ENDS HERE  ---------------------------//

// ---------------------------  ACTIVATE TERMINALS STARTS HERE  ---------------------------//

export const activateSingleTerminal = createAsyncThunk(
  '/terminals/activate-single-terminal',
  async ({ values, headers, isOutlet, onSuccess, onFailure }: ActivateTerminalArgs, { rejectWithValue }) => {
    const url = isOutlet ? `/webbe/v1/accounts/outlet/terminals/assign/single` : `/webbe/v1/terminals/assign/single`;
    try {
      const { data } = await axios.post(url, values, { headers });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const activateMultipleTerminal = createAsyncThunk(
  '/terminals/activate-multiple-terminal',
  async ({ file, headers, isOutlet, onSuccess, onFailure }: ActivateMultipleTerminalArgs, { rejectWithValue }) => {
    const url = isOutlet
      ? `/webbe/v1/accounts/outlet/terminals/assign/multiple`
      : `/webbe/v1/terminals/assign/multiple`;
    try {
      const formData = new FormData();
      formData.append('file', file);
      let progress;
      const response: ReduxAction = await axios.post(url, formData, {
        headers,
      });
      const finalResponse = {
        response,
        progress,
      };
      if (onSuccess) onSuccess(finalResponse);
      return finalResponse;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const fetchTerminalTransactions = createAsyncThunk(
  '/terminals/fetch-terminal-trasnactions',
  async (
    {
      businessId,
      terminalId,
      limit,
      dateParams,
      configFilters,
      outletId,
      onSuccess,
      onFailure,
    }: FetchTerminalTransactionsArgs,
    { rejectWithValue }
  ) => {
    const formattedDateParams = { ...dateParams };

    const queryParamsObject = { ...configFilters };

    const queryParams = new URLSearchParams(queryParamsObject);
    queryParams.append('limit', limit.toString());
    queryParams.append('dateFrom', formatRequestDate(formattedDateParams.startDate));
    queryParams.append('dateTo', formatRequestDate(formattedDateParams.endDate));
    queryParams.append('terminalId', terminalId);

    try {
      const url = outletId
        ? `/webbe/v1/accounts/outlet/terminals/transactions`
        : `/webbe/v1/terminals/transactions/${businessId as string}`;
      const response: FetchTerminalTransactionsResponse = await axios.get(url, {
        params: queryParams,
        ...(outletId && { headers: { subAccountId: outletId } }),
      });

      if (onSuccess) onSuccess(response || []);
      return response.data || [];
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const fetchTerminalInformation = createAsyncThunk(
  '/terminals/terminal-information',
  async ({ id, terminalId, outletId, onSuccess, onFailure }: FetchTerminalInformationArgs, { rejectWithValue }) => {
    const url = outletId
      ? `/webbe/v1/accounts/outlet/terminals?terminalId=${terminalId as string}`
      : `/webbe/v1/terminals/${id as string}?terminalId=${terminalId as string}`;
    try {
      const { data }: FetchActiveTerminalsResponse = await axios.get(url, {
        ...(outletId && { headers: { subAccountId: outletId } }),
      });
      if (onSuccess) onSuccess(data || []);
      return data || [];
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const exportTerminalData = createAsyncThunk(
  '/terminals/export-terminal-data',
  async (
    { id, terminalId, outletId, dateParams, onSuccess, onFailure }: ExportTerminalDataArgs,
    { rejectWithValue }
  ) => {
    try {
      const formattedDateParams = { ...dateParams };
      const queryParams = new URLSearchParams();

      queryParams.append('terminalId', terminalId);
      queryParams.append('dateFrom', customFilterDateFormatter(formattedDateParams.startDate));
      queryParams.append('dateTo', customFilterDateFormatter(formattedDateParams.endDate));

      const data: DownloadAPIResponse = await axios.get(
        `/webbe/v1/${outletId ? 'accounts/outlet/' : ''}terminals/transactions/download/polling`,
        {
          headers: {
            businessId: id as string,
            ...(outletId && { subAccountId: outletId }),
          },
          params: queryParams,
        }
      );

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const updateTerminalInformation = createAsyncThunk(
  '/terminals/update-terminal-information',
  async (
    {
      id,
      terminalId,
      outletId,
      terminalLabel,
      narrationName,
      merchantName,
      onSuccess,
      onFailure,
    }: UpdateTerminalInformationArgs,
    { rejectWithValue }
  ) => {
    const defaultValues = {
      terminalId,
      terminalLabel,
      narrationName,
    };
    try {
      const url = outletId
        ? '/webbe/v1/accounts/outlet/terminals/update'
        : `/webbe/v1/terminals/update/${id as string}`;
      const data = await axios.put(url, merchantName ? { ...defaultValues, merchantName } : defaultValues, {
        ...(outletId && { headers: { subAccountId: outletId } }),
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const downloadBulkTerminalCSVSample = createAsyncThunk(
  '/terminals/download-bulk-terminal-csv-sample',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const data = await axios.get('/webbe/v1/media/example-file/multiple-terminals-assignment');
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const searchActivatedTerminal = createAsyncThunk(
  '/terminals/search-terminal',
  async ({ searchValue, businessId, onSuccess, onFailure }: SearchActivatedTerminalArgs, { rejectWithValue }) => {
    const searchParams = new URLSearchParams();

    const queryParams: Array<IFields> = [{ text: 'value', value: searchValue }];

    queryParams.forEach((item: IFields) => {
      if (item.value) {
        searchParams.append(item.text, item.value as string);
      }
    });

    try {
      const { data }: FetchActiveTerminalsResponse = await axios.get(
        `/webbe/v1/terminals/search/${businessId as string}`,
        {
          params: searchParams,
        }
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   ACTIVATE TERMINALS ENDS HERE  ---------------------------//

// ---------------------------   SUBSCRIPTION TERMINALS STARTS HERE  ---------------------------//

interface FetchSubscriptionTerminalsArgs extends ReduxAction {
  id: number | string;
  limit: number;
  page: number;
}

export interface FetchSubscriptionTerminalsResponse extends ApiResponse {
  data: Array<RequestedTerminal>;
  message: string;
  status: boolean;
  successful: boolean;
}

export const fetchSubscriptionTerminals = createAsyncThunk(
  '/terminals/subscription-terminals',
  async ({ id, page, limit, onSuccess, onFailure }: FetchSubscriptionTerminalsArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchSubscriptionTerminalsResponse = await axios.get(
        `/terminal/pos-request?posRequestPaymentId/${id}?page=${page}&limit=${limit}`
      );
      if (onSuccess) onSuccess(data || []);
      return data || [];
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);
// ---------------------------   SUBSCRIPTION TERMINALS ENDS HERE  ---------------------------//

// ---------------------------   CANCEL FREE TRIAL STARTS HERE  ---------------------------//

export const cancelTerminalFreeTrial = createAsyncThunk(
  '/terminals/cancel-free-trial',
  async ({ values, onSuccess, onFailure }: CancelTerminalFreeTrialArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `/terminal/pos-request/cancel-free-trial/${values?.posRequestId}?cancellationReason=${values?.reason}`
      );
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   CANCEL FREE TRIAL ENDS HERE  ---------------------------//

// ---------------------------   FETCH TERMINAL BY POS REQUEST ID STARTS HERE  ---------------------------//

interface FetchTerminalByRequestIdArgs extends ReduxAction {
  posRequestId: number | string;
}

export interface FetchTerminalByRequestIdResponse extends ApiResponse {
  data: {
    content: Array<SubscriptionTerminalTransaction>;
  };
  message: string;
  status: boolean;
  successful: boolean;
}

export const fetchTerminalByRequestID = createAsyncThunk(
  '/terminals/pos-request-id',
  async ({ posRequestId, onSuccess, onFailure }: FetchTerminalByRequestIdArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchTerminalByRequestIdResponse = await axios.get(
        `/terminal/pos-request-payment-subscription/filter?posRequestPaymentId=${posRequestId}`
      );
      if (onSuccess) onSuccess(data || []);
      return data?.content;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH TERMINAL BY POS REQUEST ID ENDS HERE  ---------------------------//

// ---------------------------   FETCH TERMINAL OVERVIEW BY POS REQUEST ID STARTS HERE  ---------------------------//

export interface FetchTerminalOverviewResponse extends ApiResponse {
  data: TerminalOverview;
  message: string;
  status: boolean;
  successful: boolean;
}

export const fetchTerminalOverview = createAsyncThunk(
  '/terminals/pos-request-id/overview',
  async ({ posRequestId, onSuccess, onFailure }: FetchTerminalByRequestIdArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchTerminalOverviewResponse = await axios.get(
        `/terminal/pos-request-payment-subscription/overview/?posRequestPaymentId=${posRequestId}`
      );
      if (onSuccess) onSuccess(data || []);
      return data || [];
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH TERMINAL OVERVIEW BY POS REQUEST ID ENDS HERE  ---------------------------//
