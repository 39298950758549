import { Button, Typography } from 'kudi-component-library';
import { useEffect, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

import { ActionModalWrapper } from '../overview/styles';
import Modal from '../../components/Modal';
import useRedux from '../../hooks/useRedux';
import { toggleQRModal } from './reducer/slice';
import { QRBgWrapper, QRModalBody, QRModalBr, QRModalContent, QRnModalText } from './styles';
import { Separator } from '../../styles/generic';
import { UnderInputText } from '../auth/styles';
import { useToast } from '../../hooks/useToast';
import config from '../../utils/config';
import QRBg from '../../assets/svg/qr-bg.svg';

const { QrCodeServiceExternalClient } = require('../../proto/qr_grpc_web_pb');
const { FetchQrCodesExternalRequest, FetchQrCodesResponse, RequestMetaData, QrCodeType } = require('../../proto/qr_pb');

const QRModal = () => {
  const {
    state: {
      QR: { showModal },
    },
    dispatch,
  } = useRedux();

  const toast = useToast(3000);

  const [code, setCode] = useState('');

  const token = localStorage.getItem('token');

  const parsedToken: string = JSON.parse(token) as string;

  const client = new QrCodeServiceExternalClient(config.grpcEnvoy, null, null);

  const request = new FetchQrCodesExternalRequest();

  const metadata = new RequestMetaData();
  metadata.setToken(parsedToken);

  request.setMetadata(metadata);

  request.setQrcodetype(QrCodeType.MERCHANT_STATIC);

  useEffect(() => {
    client.fetchQrCodes(request, {}, (err, response) => {
      if (response !== null) {
        const bytes = response.serializeBinary();

        const deserializedResponse = FetchQrCodesResponse.deserializeBinary(bytes).toObject();

        const code: string = deserializedResponse?.codeList[0]?.code;
        setCode(code);
      }
    });
  }, []);

  const onClose = () => {
    dispatch(toggleQRModal(false));
  };

  const createImage = (options: { src: string }) => {
    options = options || {};
    const img = Image ? new Image() : document.createElement('img');
    if (options.src) {
      img.src = options.src;
    }
    return img;
  };

  const copyToClipboard = async (blob: Blob) => {
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      toast('success', `Image copied to clipboard`);
    } catch (error) {
      toast('error', `Error copying image. Try again.`);
    }
  };

  const copyImg = async () => {
    const canvas = document.querySelector('canvas');

    if (canvas) {
      const imageUrl = canvas.toDataURL();
      const ctx = canvas.getContext('2d');
      const imageEl = createImage({ src: imageUrl });
      imageEl.onload = (e) => {
        canvas.width = e.target?.width;
        canvas.height = e.target?.height;
        ctx?.drawImage(e.target, 0, 0, e.target.width, e.target.height);
        canvas.toBlob(copyToClipboard, 'image/png', 1);
      };
    }
  };

  const downloadCode = () => {
    const canvas = document.querySelector('canvas');
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `Nomba QR.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      toast('success', `Image downloaded successfully`);
    }
  };

  return (
    <Modal showModal={showModal} onClose={onClose}>
      <ActionModalWrapper>
        <Separator height="10px" />
        <QRnModalText variant="n-text5" color="dark">
          Your QR
        </QRnModalText>
        <Separator height="5px" />
        <QRnModalText variant="n-text2" color="grey4">
          Download to QR to receive payments from your customers
        </QRnModalText>
        <Separator height="20px" />
        <QRModalBr />
        <Separator height="20px" />

        <QRModalContent>
          <QRModalBody>
            <QRBgWrapper src={QRBg}>
              <QRCode value={`${config.qrUrl}/${code}`} size={130} logoImage="/Nomba-qr-logo.png" id="qr-code" />
            </QRBgWrapper>
          </QRModalBody>
          <Typography fontSize="14px" fontWeight={400} color="n-grey3" style={{ margin: '8px 0 43px' }}>
            QR Dimension: 400px by 400px
          </Typography>

          <Button variant="medium" width="100%" onClick={downloadCode}>
            Download Image for Print
          </Button>

          <UnderInputText className="under-text" top={20} onClick={copyImg}>
            <span> Copy Image to Clipboard </span>
          </UnderInputText>
        </QRModalContent>
      </ActionModalWrapper>
    </Modal>
  );
};

export default QRModal;
