import { AnalyticsResponse } from '../../../types/menu/menuList';
import axios from '../../../utils/merchantAcqServiceHttp';

const fetchMenuAnalytics = async () => {
  try {
    const result: AnalyticsResponse = await axios.get(`/webbe/v1/menus/analytics`);

    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchMenuAnalytics;
