/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFlags } from 'flagsmith/react';
import { Button, IconsEnum, NIcons, Radio, Text, Typography } from 'kudi-component-library';
import { ChevronRight, CloseModal, Logo, Spinner } from '../../../../assets/svg';
import {
  TransactionInfoWrapper,
  TransactionInfoBacground,
  TransactionInfoContentWrapper,
  TransactionInfoClose,
} from '../../../../components/TransactionInfo/styles';
import { capitalizeFirstLetter, formatCurrency } from '../../../../utils';
import useTransactionDetailsModal from './useTransactionDetails';
import LoadingState from './loading-state';
import ShortText from '../../../../components/ShortText';
import { TerminalStatusText } from '../../../terminals/styles';
import { NewTransactions, TransferList } from '../../../../types/transactions';
import {
  ContentItem,
  FloatingItemsContainer,
  HeaderIconWrapper,
  HeaderTextWrapper,
  IssueBox,
  MainContentWrapper,
  Subheader,
  TransactionDetailsHeader,
  TransactionFooter,
} from './styles';
import {
  IssueTypeModalContainer,
  IssueTypeModalContent,
  IssueTypeModalContentClose,
  IssueTypeModalContentTypesWrapper,
} from '../../../banking/transactions/styles';
import useIssueFromTrasaction from '../../../support/models/issueFromTrasaction';
import { FLAGSMITH_FEATURES } from '../../../../utils/constants';
import OutletHelpIcon from '../../../../assets/svg/outlet-help';
import { FullBox, FullFlex } from '../../../../styles/generic';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../app/hooks';
import SingleTransfer from '../singletransfermodal';
import html2canvas from 'html2canvas';
import { jsPDF as JSPDF } from 'jspdf';
import moment from 'moment';

const Wrapper = styled(TransactionInfoWrapper)`
  align-items: flex-start;
  flex-direction: column;
`;

const ContentWrapper = styled(TransactionInfoContentWrapper)`
  max-width: 540px;
  align-items: start;
  height: 100%;
  padding: 0rem;
  border-radius: 0px;
`;
const ContentWrapperTop = styled(ContentWrapper)`
  border-radius: 5px 5px 0 0;
  padding: 2rem;
  height: fit-content;
`;
const ContentWrapperBottom = styled(ContentWrapper)``;
const ContentWrapperLoader = styled(ContentWrapper)`
  justify-content: center;
  align-items: center;
`;
const Header = styled(Typography)`
  top: 2rem;
  left: 2rem;
`;

const DownloadButton = styled(Button)`
  background: ${({ theme }) => theme.nColors['n-light']};
  border: ${({ theme }) => `1px solid ${theme.nColors['n-grey2']}`};
`;

const TransactionDetailsModal = ({
  showModal,
  onClose,
  transactionRef,
  outletId,
  fullTransaction,
  isOutletSettlement,
}: {
  showModal: boolean;
  onClose: () => void;
  transactionRef: string;
  outletId?: string;
  fullTransaction?: NewTransactions;

  isOutletSettlement?: boolean;
}) => {
  const { bb_issue_resolution: BBIssueResolution } = useFlags([FLAGSMITH_FEATURES.BB_ISSUE_RESOLUTION]);

  const [showSingleTransfer, setShowSingleTransfer] = useState<boolean>(false);
  const [showRepeatTransferModal, setShowRepeatTransferModal] = useState<boolean>(false);
  const [showReceipt, setShowReceipt] = useState<boolean>(false);

  const { bankList } = useAppSelector((state) => state.dashboard);

  const { isLoading, handleOnClose, transactionType } = useTransactionDetailsModal({
    transactionRef,
    outletId,
    isOutletSettlement,
  });

  const {
    handleTransactionIssueButtonCLick,
    handleIssueTypeSelection,
    issues,
    isFetching,
    selectedType,
    onContinue,
    showIssuetypesModal,
    closeModal,
  } = useIssueFromTrasaction({
    transactionInfo: fullTransaction as NewTransactions,
  });

  const showDownloadButton = false;
  const isCheckoutTransaction = transactionType?.value === 'ONLINE_CHECKOUT';
  const containButtons = showDownloadButton || isCheckoutTransaction;

  const { fullUser } = useAppSelector((state) => state.auth);
  const { business } = useAppSelector((state) => state.business);
  const senderName =
    business?.merchantType === 'INDIVIDUAL' ? `${fullUser?.firstName} ${fullUser?.lastName}` : `${business?.name}`;

  const [bankName, setBankName] = React.useState<string>('');

  function addCommasToNumber(amount: String) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const handleRepeatTransaction = () => {
    setShowRepeatTransferModal(true);
    setShowSingleTransfer(true);
  };

  function getBankNameByCode(code: String | undefined) {
    for (let i = 0; i < bankList.length; i++) {
      if (bankList[i].code === fullTransaction?.bankCode) {
        setBankName(bankList[i].name);
        return;
      }
    }
  }

  useEffect(() => {
    getBankNameByCode(fullTransaction?.bankCode);
  }, [fullTransaction?.bankCode]);

  const downloadTransaction = () => {
    const input: HTMLElement | null = document.getElementById('transfer-receipt-id');
    if (input !== null) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new JSPDF('p', 'mm', [400, 700]);
        const imgProperties = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('nomba-receipt.pdf');
      });
    }
  };

  function Receipt({ data, sender }: { data: NewTransactions | undefined; sender: string }) {
    return (
      <FullBox id="transfer-receipt-id" p="10px 20px">
        <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pt="20px">
          <Logo />
          <Text color="#717171" fontSize="20px" mt="20px">
            Transaction receipt
          </Text>
        </FullFlex>

        <FullFlex id="transaction-type" justifyContent="space-between" mt="30px" mb="30px">
          <Text id="transaction-type-heading" color="#999999" fontSize="14px">
            Transaction Type
          </Text>
          <Text id="transaction-type-value" fontSize="16px" textAlign="right">
            {data?.type}
          </Text>
        </FullFlex>
        <FullFlex id="transaction-date" justifyContent="space-between" mb="30px">
          <Text id="transaction-date-heading" color="#999999" fontSize="14px">
            Transaction Date
          </Text>
          <Text id="transaction-date-value" fontSize="16px" textAlign="right">
            {moment(data?.time).date()} {moment(data?.time).format('MMM')}, {moment(data?.time).year()},{' '}
            {moment(data?.time).format('LT')}
          </Text>
        </FullFlex>

        <FullFlex id="transaction-amount" justifyContent="space-between" mb="30px">
          <Text id="transaction-amount-heading" color="#999999" fontSize="14px">
            Amount
          </Text>
          <Text id="transaction-amount-value" fontSize="16px" textAlign="right">
            {formatCurrency(data?.amount as string)}
          </Text>
        </FullFlex>

        <FullFlex id="transaction-fee" justifyContent="space-between" mb="30px">
          <Text id="transaction-fee-heading" color="#999999" fontSize="14px">
            Sender
          </Text>
          <Text id="transaction-fee-value" fontSize="16px" textAlign="right">
            {sender}
          </Text>
        </FullFlex>
        <FullFlex id="transaction-channel" justifyContent="space-between" mb="30px">
          <Text id="transaction-channel-heading" color="#999999" fontSize="14px">
            Channel
          </Text>
          <Text id="transaction-channel-value" fontSize="16px" textAlign="right">
            {data?.source}
          </Text>
        </FullFlex>
        <FullFlex id="transaction-accountname" justifyContent="space-between" mb="30px">
          <Text id="transaction-accountname-heading" color="#999999" fontSize="14px">
            Recipient Account Name
          </Text>
          <Text id="transaction-accountname-value" fontSize="16px" textAlign="right">
            {data?.beneficiary}
          </Text>
        </FullFlex>
        <FullFlex id="transaction-accountnumber" justifyContent="space-between" mb="30px">
          <Text id="transaction-accountnumber-heading" color="#999999" fontSize="14px">
            Recipient Account Number
          </Text>
          <Text id="transaction-accountnumber-value" fontSize="16px" textAlign="right">
            {data?.accountNumber}
          </Text>
        </FullFlex>
        <FullFlex id="transaction-status" justifyContent="space-between" mb="30px">
          <Text id="transaction-status-heading" color="#999999" fontSize="14px">
            Transaction Status
          </Text>
          <Text id="transaction-status-value" fontSize="16px" textAlign="right">
            {data?.status}
          </Text>
        </FullFlex>
        <FullFlex id="transaction-ref" justifyContent="space-between" mb="30px">
          <Text id="transaction-ref-heading" color="#999999" fontSize="14px">
            Reference
          </Text>
          <Text id="transaction-ref-value" fontSize="14px" textAlign="right">
            {data?.reference}
          </Text>
        </FullFlex>
        <FullFlex id="transaction-ref" justifyContent="space-between" mb="30px">
          <Text id="transaction-ref-heading" color="#999999" fontSize="14px">
            Description
          </Text>
          <Text id="transaction-ref-value" fontSize="14px" textAlign="right">
            {data?.narration?.substring(0, 200)}
          </Text>
        </FullFlex>
      </FullBox>
    );
  }

  const closeModalSetShowReceipt = () => {
    handleOnClose(onClose);
    setShowReceipt(false);
  };

  return showModal ? (
    <Wrapper>
      <TransactionInfoBacground onClick={() => closeModalSetShowReceipt()} />
      <ContentWrapperTop>
        <TransactionInfoClose onClick={() => closeModalSetShowReceipt()}>
          <CloseModal />
        </TransactionInfoClose>
        <Header variant="n-text4" color="dark">
          Transaction details
        </Header>
      </ContentWrapperTop>

      {isLoading ? (
        <ContentWrapperLoader>
          <LoadingState />
        </ContentWrapperLoader>
      ) : null}

      {!isLoading ? (
        <ContentWrapperBottom>
          <MainContentWrapper withButtons={containButtons} showIssueResolution={BBIssueResolution.enabled}>
            <TransactionDetailsHeader>
              <HeaderIconWrapper>
                <NIcons icon={IconsEnum.OUTLINE_UP} />
              </HeaderIconWrapper>
              <HeaderTextWrapper>
                <Typography
                  variant="n-text1"
                  fontWeight="heavy"
                  color="n-grey3a"
                  style={{ textTransform: 'capitalize' }}
                >
                  Money out
                </Typography>
                {fullTransaction?.amount && (
                  <Typography variant="n-text5" fontWeight="heavy">
                    ₦{addCommasToNumber(fullTransaction?.amount)}
                  </Typography>
                )}
                {fullTransaction?.time && (
                  <Typography variant="n-text1" color="grey5">
                    Initiated on{' '}
                    {dayjs(new Date(fullTransaction?.time)).add(1, 'hour').format('DD MMM YYYY, h:mm:ss A')}
                  </Typography> // the is a fix for the time return as a string not UTC format
                )}
              </HeaderTextWrapper>
            </TransactionDetailsHeader>

            {!showReceipt ? (
              <>
                {/* ----------------- */}

                <Subheader>
                  <ContentItem width="48%">
                    {/* <Title title="To be sent to" /> */}
                    <Typography variant="n-text1" color="n-grey3a">
                      To be sent to
                    </Typography>
                    <Typography variant="n-text3" color="n-dark">
                      {/* {(TransactionTypes as MapEnums)[transactionType.value.toLowerCase()] || transactionType.value} */}
                      {fullTransaction?.beneficiary}
                    </Typography>
                  </ContentItem>
                  {fullTransaction?.status && (
                    <ContentItem width="50%">
                      <Typography variant="n-text1" color="n-grey3a">
                        Status
                      </Typography>
                      <TerminalStatusText state={fullTransaction?.status}>
                        <Typography
                          variant="n-text1"
                          style={{ fontWeight: 500, marginBottom: '0px', color: 'n-green1' }}
                        >
                          {capitalizeFirstLetter(fullTransaction?.status)}
                        </Typography>
                      </TerminalStatusText>
                    </ContentItem>
                  )}
                </Subheader>

                {/* ----------------- */}

                <Subheader>
                  <ContentItem width="48%">
                    <Typography variant="n-text1" color="n-grey3a">
                      Bank name
                    </Typography>
                    <Typography variant="n-text3" color="n-dark">
                      {bankName}
                    </Typography>
                  </ContentItem>
                  {fullTransaction?.status && (
                    <ContentItem width="50%">
                      <Typography variant="n-text1" color="n-grey3a">
                        Account number
                      </Typography>
                      <Typography variant="n-text3" color="n-dark">
                        {fullTransaction?.accountNumber}
                      </Typography>
                    </ContentItem>
                  )}
                </Subheader>

                {/* -------------------- */}
                <Subheader>
                  <ContentItem width="100%">
                    <Typography variant="n-text1" color="n-grey3a">
                      Description
                    </Typography>
                    <Typography variant="n-text3" color="n-dark">
                      {fullTransaction?.narration}
                    </Typography>
                  </ContentItem>
                </Subheader>

                {/* -------------------- */}

                <Subheader>
                  <ContentItem width="48%">
                    <Typography variant="n-text1" color="n-grey3a">
                      Transaction type
                    </Typography>
                    <Typography variant="n-text3" color="n-dark">
                      {fullTransaction?.type}
                    </Typography>
                  </ContentItem>
                  {senderName && (
                    <ContentItem width="50%">
                      <Typography variant="n-text1" color="n-grey3a">
                        Initiator
                      </Typography>
                      <Typography variant="n-text3" color="n-dark">
                        {senderName}
                      </Typography>
                    </ContentItem>
                  )}
                </Subheader>

                {/* ------------------- */}

                <Subheader>
                  <ContentItem width="100%">
                    <Typography variant="n-text1" color="n-grey3a">
                      Transction ID
                    </Typography>
                    <Typography variant="n-text3" color="n-dark">
                      <ShortText
                        length={47}
                        mobileTextLength={25}
                        text={fullTransaction?.reference as string}
                        withCopy
                      />
                    </Typography>
                  </ContentItem>
                </Subheader>
              </>
            ) : null}

            {/* -------------------- */}
            {showReceipt ? <Receipt data={fullTransaction} sender={senderName} /> : null}
          </MainContentWrapper>

          <FloatingItemsContainer>
            {showReceipt ? (
              <FullFlex justifyContent="space-between" width="100%" padding="0px 20px" style={{ gap: '2%' }}>
                <DownloadButton variant="medium" width="50%" onClick={() => downloadTransaction()}>
                  Download transaction
                </DownloadButton>
              </FullFlex>
            ) : (
              <FullFlex justifyContent="space-between" width="100%" padding="0px 20px" style={{ gap: '2%' }}>
                <DownloadButton variant="medium" width="50%" onClick={() => setShowReceipt(true)}>
                  Download transaction
                </DownloadButton>

                <Button variant="medium" width="50%" onClick={() => handleRepeatTransaction()}>
                  Repeat transaction
                </Button>
              </FullFlex>
            )}

            {BBIssueResolution.enabled ? (
              <>
                <TransactionFooter>
                  <IssueBox onClick={handleTransactionIssueButtonCLick}>
                    <OutletHelpIcon />
                    <div className="content">
                      <Typography className="header-text">Report Transaction</Typography>
                      <Typography>Report an issue with this payment</Typography>
                    </div>
                    {isFetching ? <Spinner /> : <ChevronRight />}
                  </IssueBox>
                </TransactionFooter>
                {showIssuetypesModal ? (
                  <IssueTypeModalContainer onClick={closeModal}>
                    <IssueTypeModalContent onClick={(e) => e.stopPropagation()}>
                      <IssueTypeModalContentClose onClick={closeModal}>
                        <NIcons icon={IconsEnum.OUTLINE_X} />
                      </IssueTypeModalContentClose>
                      <Typography fontSize="20px" lineHeight="30px" fontWeight={400}>
                        Select what’s wrong
                      </Typography>
                      <IssueTypeModalContentTypesWrapper>
                        {issues.map((item) => (
                          <Radio
                            onChange={() => handleIssueTypeSelection(item)}
                            defaultChecked={selectedType?.id === item.id}
                            name={item.description}
                            value={item.id}
                          >
                            {item.description}
                          </Radio>
                        ))}
                      </IssueTypeModalContentTypesWrapper>
                      <Button disabled={!selectedType} variant="medium" width="100%" onClick={onContinue}>
                        Continue
                      </Button>
                    </IssueTypeModalContent>
                  </IssueTypeModalContainer>
                ) : null}
              </>
            ) : null}

            {showRepeatTransferModal && (
              <SingleTransfer
                amountUsed={fullTransaction?.amount}
                bankUsed={fullTransaction?.bankName}
                accountNumberUsed={fullTransaction?.accountNumber}
                bankCodeUsed={fullTransaction?.bankCode}
                showSingleTransfer={showSingleTransfer}
                setShowSingleTransfer={setShowSingleTransfer}
                showFlyoutValue="Resend"
              />
            )}
          </FloatingItemsContainer>
        </ContentWrapperBottom>
      ) : null}
    </Wrapper>
  ) : null;
};

export default TransactionDetailsModal;
