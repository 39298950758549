import { Typography, IconsEnum, NIcons } from 'kudi-component-library';
import moment from 'moment';
import {
  MobileTableItemIconWrapper,
  MobileTableItemReadStatus,
  MobileTableItemTextrapper,
  MobileTableItemWrapper,
} from '../../styles';
import { Separator } from '../../../../styles/generic';
import { Notification } from '../../types';
import useRedux from '../../../../hooks/useRedux';
import { addMobileSelected } from '../../reducer/slice';
import { GetAllNotificationsCategoryEnum } from '../../../../api_spec_client';
import { toggleNotification } from '../../reducer/thunkActions';
import useTracking from '../../models/analytics';
import AnnouncementIcon from '../../../../assets/svg/announcement';
import useIssueDetailsFromNotification from '../../../support/models/issueDetailsFromNotification';

const IconsMap: Record<GetAllNotificationsCategoryEnum, IconsEnum> = {
  ANNOUNCEMENT: IconsEnum.OUTLINE_ANNOUNCEMENT,
  CAMPAIGN: IconsEnum.OUTLINE_GIFT,
  SYSTEM: IconsEnum.OUTLINE_BUSINESS,
};

export const MobileTableItemUI = ({
  data,
  onClick,
  textWidth,
}: {
  data: Notification;
  onClick: () => void;
  textWidth?: string;
}) => (
  <MobileTableItemWrapper data-testid="MobileTableItem" onClick={onClick}>
    <MobileTableItemIconWrapper>
      {data.category === 'ANNOUNCEMENT' ? <AnnouncementIcon /> : <NIcons icon={IconsMap[data?.category || 'SYSTEM']} />}
    </MobileTableItemIconWrapper>

    <MobileTableItemTextrapper textWidth={textWidth}>
      <Typography fontSize="14px" fontWeight={500} lineHeight="17px">
        {data.title}
      </Typography>
      <Separator height="5px" />
      <Typography fontSize="12px" lineHeight="16px" color="n-grey5">
        {data.message}
      </Typography>
      <Separator height="2px" />
      <Typography fontSize="12px" lineHeight="16px" color="n-grey3">
        {moment(data?.createdAt).fromNow()}
      </Typography>
    </MobileTableItemTextrapper>
    <MobileTableItemReadStatus open={data.status === 'READ'}>
      <div />
    </MobileTableItemReadStatus>
  </MobileTableItemWrapper>
);

const MobileTableItem = ({ data }: { data: Notification }) => {
  const { dispatch } = useRedux();
  const { trackNotificationOpened } = useTracking();
  const { SelectIssue } = useIssueDetailsFromNotification();

  const handleToggle = () => {
    if (data.type === 'TICKET') {
      SelectIssue(data.data);
    } else {
      dispatch(addMobileSelected(data));
    }
    trackNotificationOpened(data.type as string, data.id as number, data.category);
    if (data?.status === 'UNREAD') {
      dispatch(toggleNotification({ notification: data }));
    }
  };

  return <MobileTableItemUI data={data} onClick={handleToggle} />;
};

export default MobileTableItem;
