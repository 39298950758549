import { useNavigate } from 'react-router-dom';
import { Typography } from 'kudi-component-library';
import { useFlags } from 'flagsmith/react';
import { HeaderWrapper, HeaderMenuWrapper, LogoutWrapper, RightSectionWrapper } from './styles';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Menu } from '../../assets/svg';
import { EmptyButton } from '../../styles/generic';
import GlobalSearch from './components/GlobalSearch';
import { logout } from '../../utils';
import { FLAGSMITH_FEATURES } from '../../utils/constants';
import NotificationDropdown from './components/NotificationDropdown';
import SupportIconButton from '../../pages/support/components/base/support_icon_button';
import RoleGuard from '../RoleGuard';
import QRHeaderButton from '../../pages/qr/header-button';

interface HeaderProps {
  setOpen: (value: boolean) => void;
}

const Header = ({ setOpen }: HeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { bb_dashboard_notification: webNotificationModel, bb_issue_resolution: BBIssueResolution } = useFlags([
    FLAGSMITH_FEATURES.BB_DASHBOARD_NOTIFICATION,
    FLAGSMITH_FEATURES.BB_ISSUE_RESOLUTION,
  ]);

  const { fullUser } = useAppSelector((state) => state.auth);

  return (
    <HeaderWrapper>
      <HeaderMenuWrapper>
        <EmptyButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <Menu />
        </EmptyButton>
      </HeaderMenuWrapper>
      <RoleGuard allowedRoles={['ADMIN', 'OWNER']}>
        <GlobalSearch />
      </RoleGuard>
      <div />

      <RightSectionWrapper>
        <QRHeaderButton />
        {BBIssueResolution.enabled ? <SupportIconButton /> : null}

        {webNotificationModel.enabled ? <NotificationDropdown /> : null}

        <LogoutWrapper onClick={() => logout({ dispatch, navigate, userId: String(fullUser?.id) })}>
          <Typography variant="n-text2" as="span">
            {' '}
            Log out
          </Typography>
        </LogoutWrapper>
      </RightSectionWrapper>
    </HeaderWrapper>
  );
};

const GuestHeader = ({ setOpen }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <HeaderWrapper>
      <HeaderMenuWrapper>
        <EmptyButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <Menu />
        </EmptyButton>
      </HeaderMenuWrapper>
      <LogoutWrapper onClick={() => navigate('/auth/signup')}>
        <Typography variant="n-text2" as="span">
          Sign up
        </Typography>
      </LogoutWrapper>
    </HeaderWrapper>
  );
};
export { Header, GuestHeader };
