import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Button, IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { Box, Flex } from 'rebass';
import { HeaderTopFlex, MenuListWrapper } from './styles';
import { NavigationTab } from '../../components/navigationTab';
import ErrorBoundary from '../../utils/errorBoundary';
import { PageWrapper, Separator } from '../../styles/generic';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import fetchStore from '../../queries/inventory/store/fetchStore';
import { setInventoryStore } from '../../features/dashboard/slice';
import { Mixpanel } from '../../hooks/useMixpanel';
import { getUserBrowser } from '../../utils';
import useMenuListProductsModel from './models/useMenuListProducts';
import { EmptyPageState } from './menu-list/components/EmptyMenuState';
import useAddNewMenuItem from './menu-list/models/useAddNewMenuItem';
import useAddNewMenuDiscounts from './menu-list/models/useAddNewMenuDiscount';
import useAddNewMenuCategories from './menu-list/models/useAddNewMenuCategories';
import AddCoverImage from './components/AddCoverImage';
import {
  AddMenuProductSuccessModal,
  AddNewMenuItemModal,
  SelectItemsToAddModal,
  SelectMealTypeModal,
} from './menu-list/components';
import CreateStoreModal from './menu-list/components/CreateStoreModal';
import NewMenuCategoryModal from './menu-list/components/AddNewMenuCategoriesModal';
import Loading from '../teams/auth/components/loading';
import { Product } from '../../types/inventory/products';
import AppRoles from '../../utils/roles';
import ButtonDropdown from '../../components/button-dropdown';
import BulkUploadModal from './components/BulkUploadModal';
import { MenuListProduct, MenuListResponse } from '../../types/menu/menuList';
import { AppliedFilter } from '../../components/newFilter/defs';

interface MenuLayoutProps {
  routes: {
    [x: string]: string;
  };
}

export interface MenuOutletContext {
  newMenu: boolean;
  showMealType: boolean;
  setNewMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMealType: React.Dispatch<React.SetStateAction<boolean>>;
  saveChanges: boolean;
  addOrder: boolean;
  setAddOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddCoverImage: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetMenuValues: (product: Product) => void;
  handleOpenAddNewProductsModal: () => void;
  productBody: MenuListResponse;
  isProductBodyLoading: boolean;
  refetchProductBody: () => void;
  handleEditCoverImage: ({ menuId, coverImageUrl }: { menuId: string; coverImageUrl: string }) => void;
  removeMenuListItem: ({ menuId, itemId }: { menuId: string; itemId: number }) => void;
  toggleMenuListItemStatus: (product: MenuListProduct) => void;
  handleOnchangePagination: (page: number) => void;
  isLastPage: boolean;
  handleDownload: () => void;
  handleUploadImage: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  imageUrl: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  handleFilterMenuList: (filterItems: AppliedFilter[]) => void;
  handleSearchMenuList: (name: string) => void;
  handleClearSearch: () => void;
  handleClearFilters: () => void;
  handleFetchProducts: () => Promise<void>;
}

export const MenuLayout = ({ routes }: MenuLayoutProps) => {
  const match = useLocation();
  const activeRoute = match.pathname.split('/')[4];
  const [newMenu, setNewMenu] = useState<boolean>(true);
  const [saveChanges, setSaveChanges] = useState<boolean>(false);
  const [showMealType, setShowMealType] = useState<boolean>(false);
  const [addOrder, setAddOrder] = useState<boolean>(false);

  const { inventoryStore } = useAppSelector((state) => state.dashboard);
  const hasStore = !!inventoryStore?.storeId;

  const { fullUser } = useAppSelector((state) => state.auth);

  const { iamTokenRole } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const handleCheckStore = async () => {
    const { result } = await fetchStore();
    if (result) {
      dispatch(setInventoryStore(result.data));
    }
  };

  const {
    productBody,
    isLoading,
    handleRefetch,
    createMenuListItem,
    setImageUrl,
    imageUrl,
    handleUploadImage,
    handleEditCoverImage,
    removeMenuListItem,
    toggleMenuListItemStatus,
    handleOnchangePagination,
    isLastPage,
    handleDownload,
    handleFilterMenuList,
    handleSearchMenuList,
    handleClearSearch,
    handleClearFilters,
    handleFetchProducts,
    handleCreateMenu,
  } = useMenuListProductsModel();

  const [showAddCoverImage, setShowAddCoverImage] = useState(false);
  const [showSelectItems, setShowSelectItems] = useState(false);
  const [createStoreModal, setCreateStoreModal] = useState(false);
  const [showBulkUploadModal, setShowUploadModal] = useState(false);

  const {
    showAddNewProductsModal,
    handleCloseAddNewProductsModal,
    handleCreateMenuItem,
    handleOpenAddNewProductsModal,
    productValues,
    handleAddAnotherMenuItem,
    handleCloseAddMenuSuccessModal,
    showAddMenuItemSuccessModal,
    handleSetMenuValues,
    isLoadingAddMeal,
  } = useAddNewMenuItem({
    productBody,
    createMenuListItem,
    handleCreateMenu,
  });

  const {
    categories,
    showNewCategoryModal,
    handleOpenNewCategoryModal,
    handleCloseNewCategoryModal,
    handleAddNewCategory,
    handleRefetchCategories,
  } = useAddNewMenuCategories();

  const { handleRefetchDiscounts } = useAddNewMenuDiscounts();

  useEffect(() => {
    handleCheckStore();
  }, []);

  return (
    <>
      {isLoading && (
        <Flex
          style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '22' }}
          backgroundColor="#fff"
          justifyContent="center"
          alignItems="center"
        >
          <Loading message="Loading..." isDefault={false} />
        </Flex>
      )}
      <PageWrapper>
        <ErrorBoundary>
          <div>
            {!productBody && !hasStore ? (
              <MenuListWrapper id="menu_list_wrapper">
                <EmptyPageState
                  onGetStarted={() => {
                    Mixpanel.track('MENU_CLICK_GETTING_STARTED_ON_PAGE', {
                      userID: fullUser?.id as string,
                      timeStamp: new Date().toString(),
                      userBrowser: getUserBrowser(),
                    });
                    if (hasStore) {
                      setShowAddCoverImage(true);
                    } else {
                      setCreateStoreModal(true);
                    }
                  }}
                />
              </MenuListWrapper>
            ) : (
              <>
                <HeaderTopFlex>
                  <Box>
                    <Typography variant="n-text6" color="dark">
                      Menu
                    </Typography>
                    {inventoryStore?.storeId && (
                      <Flex style={{ gap: 10 }} mt="2" justifyContent="center" alignItems="end">
                        <Typography variant="n-text4" fontWeight="light" color="n-grey4">
                          {inventoryStore.storeName}
                        </Typography>
                        <Flex style={{ gap: 5 }} justifyContent="center" alignItems="center">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_63069_183275)">
                              <path
                                d="M14.7127 13.8807C13.8138 14.7797 11.7849 16.8086 10.7054 17.8881C10.3149 18.2786 9.68294 18.279 9.29242 17.8885C8.23061 16.8267 6.24533 14.8414 5.28465 13.8807C2.68116 11.2772 2.68116 7.05612 5.28465 4.45262C7.88815 1.84913 12.1092 1.84913 14.7127 4.45262C17.3162 7.05612 17.3162 11.2772 14.7127 13.8807Z"
                                stroke="#121212"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12.4987 9.16667C12.4987 10.5474 11.3794 11.6667 9.9987 11.6667C8.61799 11.6667 7.4987 10.5474 7.4987 9.16667C7.4987 7.78596 8.61799 6.66667 9.9987 6.66667C11.3794 6.66667 12.4987 7.78596 12.4987 9.16667Z"
                                stroke="#121212"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_63069_183275">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <Typography variant="n-text4" fontWeight="light" color="n-grey4">
                            {inventoryStore.storeLocation}
                          </Typography>
                        </Flex>
                      </Flex>
                    )}
                  </Box>

                  {activeRoute === 'menu-list' && productBody && (
                    <ButtonDropdown
                      type="primary"
                      buttonText="Add New Item"
                      variant="small"
                      showDropDownIcon
                      options={[
                        {
                          text: 'Upload Single Item',
                          onClick: () => {
                            setShowMealType(true);
                            setNewMenu(false);
                            Mixpanel.track('MENU_ADD_MENU_ITEM_CLICKED', {
                              userID: fullUser?.id as string,
                              timeStamp: new Date().toString(),
                              userBrowser: getUserBrowser(),
                            });
                          },
                        },
                        {
                          text: 'Bulk Upload',
                          onClick: () => {
                            setShowUploadModal(true);
                            Mixpanel.track('MENU_ADD_MENU_BULK_ITEM_CLICKED', {
                              userID: fullUser?.id as string,
                              timeStamp: new Date().toString(),
                              userBrowser: getUserBrowser(),
                            });
                          },
                        },
                      ]}
                    />
                  )}

                  {activeRoute === 'order-list' && (
                    <Button
                      variant="small"
                      onClick={() => {
                        setAddOrder(true);
                      }}
                    >
                      Take/Add order
                    </Button>
                  )}

                  {activeRoute === 'settings' && (
                    <Button
                      variant="small"
                      onClick={() => {
                        setSaveChanges(true);
                      }}
                    >
                      Save Changes
                    </Button>
                  )}

                  {activeRoute === 'analytics' && (
                    <Button
                      variant="small"
                      disabled
                      onClick={() => {
                        // setSaveChanges(true);
                      }}
                    >
                      <Flex style={{ gap: '10px' }} justifyContent="center" alignItems="center">
                        Export <NIcons width="16" height="16" icon={IconsEnum.OUTLINE_DOWNLOAD} />
                      </Flex>
                    </Button>
                  )}
                </HeaderTopFlex>
                {productBody?.data ? (
                  <NavigationTab
                    active={activeRoute}
                    options={
                      iamTokenRole === AppRoles.KitchenStaff
                        ? [{ text: 'Order List', route: activeRoute }]
                        : Object.values(routes).map((item) => ({ text: item.replace('-', ' '), route: item }))
                    }
                  />
                ) : (
                  <NavigationTab active={activeRoute} options={[{ text: 'Menu List', route: activeRoute }]} />
                )}
                <Separator height="2rem" />
                <Outlet
                  context={{
                    newMenu,
                    setNewMenu,
                    showMealType,
                    setShowMealType,
                    saveChanges,
                    setSaveChanges,
                    setAddOrder,
                    addOrder,
                    handleSetMenuValues,
                    handleOpenAddNewProductsModal,
                    setShowAddCoverImage,
                    productBody,
                    isProductBodyLoading: isLoading,
                    refetchProductBody: handleRefetch,
                    handleEditCoverImage,
                    removeMenuListItem,
                    toggleMenuListItemStatus,
                    handleOnchangePagination,
                    isLastPage,
                    handleDownload,
                    handleUploadImage,
                    handleFilterMenuList,
                    handleSearchMenuList,
                    handleClearSearch,
                    handleClearFilters,
                    setImageUrl,
                    imageUrl,
                  }}
                />
              </>
            )}
          </div>

          {showAddNewProductsModal && (
            <AddNewMenuItemModal
              showModal={showAddNewProductsModal}
              onClose={() => {
                handleCloseAddNewProductsModal();
              }}
              handleCreateMenuItem={handleCreateMenuItem}
              productValues={productValues}
              newMenu={newMenu}
              handleOpenNewCategoryModal={handleOpenNewCategoryModal}
              categories={categories}
              imageUrl={imageUrl}
              isLoading={isLoadingAddMeal}
              productBody={productBody}
              handleCreateMenu={handleCreateMenu}
              createMenuListItem={createMenuListItem}
            />
          )}
          {showAddMenuItemSuccessModal && (
            <AddMenuProductSuccessModal
              showSuccessModal={showAddMenuItemSuccessModal}
              onClose={() => {
                handleCloseAddMenuSuccessModal();
              }}
              handleAddAnotherMenuItem={handleAddAnotherMenuItem}
              productValues={productValues}
              handleRefetch={handleRefetch}
            />
          )}
          {showAddCoverImage && (
            <AddCoverImage
              showModal={showAddCoverImage}
              newMenu={newMenu}
              onContinue={(imageContinueUrl: string) => {
                setImageUrl(imageContinueUrl);
                handleOpenAddNewProductsModal();
              }}
              imageUrl={imageUrl}
              handleUploadImage={handleUploadImage}
              isProductBodyLoading={isLoading}
              setImageUrl={setImageUrl}
              currentImage={productBody?.data?.coverImageUrl}
              onClose={() => {
                setShowAddCoverImage(false);
                handleRefetch();
              }}
            />
          )}
          {showMealType && (
            <SelectMealTypeModal
              showModal={showMealType}
              newMenu={newMenu}
              products={productBody?.data?.items as MenuListProduct[]}
              handleFetchProducts={handleFetchProducts}
              onSelectMealType={(mealType: any) => {
                if (mealType === 'inventory') {
                  setShowSelectItems(true);
                } else {
                  handleOpenAddNewProductsModal();
                }
              }}
              onClose={() => {
                setShowMealType(false);
              }}
            />
          )}
          {showSelectItems && (
            <SelectItemsToAddModal
              showModal={showSelectItems}
              createNewMeal={() => {
                setShowSelectItems(false);
                handleOpenAddNewProductsModal();
              }}
              addListToMenu={async (list) => {
                await createMenuListItem({
                  menuId: productBody?.data?.menuId as string,
                  itemId: list,
                });
              }}
              onClose={() => {
                setShowSelectItems(false);
              }}
              products={productBody?.data?.items}
              handleFetchProducts={handleFetchProducts}
              isLoading={isLoading}
            />
          )}
          {createStoreModal && (
            <CreateStoreModal
              showModal={createStoreModal}
              newMenu={newMenu}
              onClose={() => {
                setCreateStoreModal(false);
              }}
              onContinue={() => {
                setShowAddCoverImage(true);
                handleRefetchCategories();
                handleRefetchDiscounts();
              }}
            />
          )}
          {showNewCategoryModal && (
            <NewMenuCategoryModal
              handleAddNewCategory={handleAddNewCategory}
              showNewCategoryModal={showNewCategoryModal}
              handleCloseNewCategoryModal={() => {
                handleCloseNewCategoryModal();
              }}
            />
          )}
          {showBulkUploadModal && (
            <BulkUploadModal
              showModal={showBulkUploadModal}
              onClose={() => setShowUploadModal(false)}
              refetch={handleRefetch}
            />
          )}
        </ErrorBoundary>
      </PageWrapper>
    </>
  );
};
