import { FormInputWrapper } from './styles';
import { InputItemErrorWrapper } from '../component';
import ErrorIcon from '../ErrorIcon';
import { DropdownInputProps } from '../DropdownInput/types';
import { dropdownThemeStyles, dropdownV2Styles } from '../DropdownInput/styles';
import Select from 'react-select';

interface DropdownInputV2Props extends DropdownInputProps {
  error?: string | boolean;
}

const DropdownInputV2 = ({ label, error, ...props }: DropdownInputV2Props) => {
  return (
    <FormInputWrapper padding="0px" width="100%">
      <label style={{ padding: '10px 16px 0px' }}>{label}</label>
      <Select
        isSearchable
        styles={dropdownV2Styles}
        theme={dropdownThemeStyles}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        options={props.options}
        isDisabled={props.isDisabled}
      />
      {error ? (
        <InputItemErrorWrapper>
          <ErrorIcon /> <span> {error} </span>
        </InputItemErrorWrapper>
      ) : null}
    </FormInputWrapper>
  );
};

export default DropdownInputV2;
