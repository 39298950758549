import React, { useEffect } from 'react';
import { Typography, Avatar } from 'kudi-component-library';
import { Logo, CloseModal } from '../../assets/svg';
import {
  Wrapper,
  NavTop,
  MobileNavTop,
  MobileNavTopClose,
  NavigationContentHolder,
  NavigationFooterHolder,
  NavigationOptionName,
  UserSection,
  UserDetails,
  NavigationFooterWrapper,
} from './components';
import NavigationSection from './navigationSection';

import { navigationConfig, navigationFooterConfig, NavigationContext, NavigationProps } from './data';

// redux
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fecthBusinessLogo, setBusinessImageUrl } from '../../features/settings/slice';
import { generateBusinessLogo } from '../../utils';
import { NavigationSectionInterface } from '../../types/navigation';
import AppRoles from '../../utils/roles';

const Navigation = ({ open, setOpen }: { open: boolean } & NavigationProps) => {
  const dispatch = useAppDispatch();
  const { fullUser, parentBusinessId } = useAppSelector((state) => state.auth);
  const { businessImageUrl } = useAppSelector((state) => state.settings);

  const iamTokenRole = JSON.parse(localStorage.getItem('IAMuserRole') as '{}') as string;
  const { KitchenStaff, BusinessPartner } = AppRoles;

  const userInitials =
    String(fullUser?.firstName).charAt(0).toUpperCase() + String(fullUser?.lastName).charAt(0).toUpperCase();

  useEffect(() => {
    if (parentBusinessId) {
      dispatch(
        fecthBusinessLogo({
          businessId: parentBusinessId,
          onSuccess: () => {
            setBusinessImageUrl({
              businesImageUrl: generateBusinessLogo(parentBusinessId),
            });
          },
        })
      );
    }
  }, [dispatch, parentBusinessId]);

  const filterNavigationConfig = (navConfig: NavigationSectionInterface[]) => {
    switch (iamTokenRole) {
      case KitchenStaff:
        return navConfig.filter((section) => section.data.some((item) => item.name === 'Menu'));
      case BusinessPartner:
        return navConfig.filter((section) => section.data.some((item) => item.name === 'Outlets'));
      default:
        return navConfig;
    }
  };

  return (
    <NavigationContext.Provider value={React.useMemo(() => ({ setOpen }), [setOpen])}>
      <Wrapper id="navigation-wrapper" onClick={(e) => e.stopPropagation()} open={open}>
        {/* Logo top */}

        <NavTop id="navigation-top">
          <Logo id="navigation-logo" />
        </NavTop>
        <MobileNavTop id="navigation-mobile-top">
          <MobileNavTopClose id="navigation-mobile-top-close" onClick={() => setOpen(false)}>
            <CloseModal id="navigation-mobile-top-close-icon" />
          </MobileNavTopClose>
        </MobileNavTop>

        {/* content map */}
        <NavigationContentHolder id="navigation-content-list-wrapper" onScroll={(e) => e.stopPropagation()}>
          {filterNavigationConfig(navigationConfig).map(({ title, data, baseRoute }, index) => {
            let newData = [...data];

            switch (iamTokenRole) {
              case KitchenStaff:
                newData = newData.filter((val) => val.name === 'Menu');
                break;
              case BusinessPartner:
                newData = newData.filter((val) => val.name === 'Outlets');
                break;
              default:
                break;
            }

            return (
              <NavigationSection
                data={newData}
                title={title}
                baseRoute={baseRoute}
                key={`navigation-section-${index}`}
              />
            );
          })}
        </NavigationContentHolder>

        {/* footer */}
        <NavigationFooterWrapper>
          {iamTokenRole === KitchenStaff || iamTokenRole === BusinessPartner ? null : (
            <NavigationFooterHolder>
              <NavigationSection {...navigationFooterConfig} />
            </NavigationFooterHolder>
          )}
          <UserSection>
            <Avatar initials={userInitials} imgUrl={businessImageUrl} size="medium" />
            <UserDetails>
              <NavigationOptionName>
                <Typography variant="n-text2" color="grey3">
                  {`${String(fullUser?.firstName)} ${String(fullUser?.lastName)}`}
                </Typography>
              </NavigationOptionName>
              <NavigationOptionName>
                <Typography variant="n-text2" color="grey3">
                  {fullUser?.email}
                </Typography>
              </NavigationOptionName>
            </UserDetails>
          </UserSection>
        </NavigationFooterWrapper>
      </Wrapper>
    </NavigationContext.Provider>
  );
};

export { Navigation };
