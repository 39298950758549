import { CreateMenu, CreateMenuResponse } from '../../../types/menu/menuList';
import axios from '../../../utils/merchantAcqServiceHttp';

const createMenu = async (payload: CreateMenu) => {
  const { outletId } = payload;
  try {
    const result: CreateMenuResponse = await axios.post(`/webbe/v1/menus`, payload, {
      headers: { ...(outletId && { outletId }) },
    });

    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default createMenu;
