import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  showAddModal: boolean;
  showWithdrawModal: boolean;
}

const initialState: InitialState = {
  showAddModal: false,
  showWithdrawModal: false,
};

export const bankingModalControlSlice = createSlice({
  name: 'bankingModalControl',
  initialState,
  reducers: {
    setShowAddModal: (state, action: PayloadAction<boolean>) => {
      state.showAddModal = action.payload;
    },
    setShowWithdrawModal: (state, action: PayloadAction<boolean>) => {
      state.showWithdrawModal = action.payload;
    },
  },
});

export const { setShowAddModal, setShowWithdrawModal } = bankingModalControlSlice.actions;
export default bankingModalControlSlice.reducer;
