import { Button, Heading, SqaureRadioGroup, SquareRadio } from 'kudi-component-library';
import styled, { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Plus, ComingSoonIcon, Payment } from '../../assets/svg';
import { ContentWrapper, FullBox } from '../../styles/generic';

const ComingSoonWrappper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ComingSoonTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  /* border-bottom: 1px solid #efefef; */
  margin-bottom: 4.8rem;
`;
const ComingSoonTopButton = styled.div`
  margin-top: 2.4rem;
  width: 243px;
  height: 49px;
  background: ${({ theme }) => theme.nColors['n-yellow1']};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #121212;
  font-size: 21px;
`;
const ComingSoonButtom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const ComingSoonButtomTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #121212;
  margin-bottom: 3rem;
`;
const ComingSoonButtomItemWrapper = styled.div`
  display: flex;
  max-width: 52.8rem;
  gap: 4rem;
`;
const ComingSoonButtomItem = styled.div``;
const ComingSoonButtomItemText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  text-align: center;
  color: #868686;
`;

export default function ViewTerminals() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [status, setStatus] = useState('activated');

  return (
    <ContentWrapper
      bg="white"
      p="36px 30px"
      borderRadius="12px"
      border="1px solid #EDF1FC"
      ml="50px"
      mr="50px"
      mb="20px"
      mt="50px"
    >
      <ComingSoonWrappper>
        <ComingSoonTop>
          <ComingSoonIcon />
          <ComingSoonTopButton>Coming soon</ComingSoonTopButton>
        </ComingSoonTop>
        {/* <ComingSoonButtom>
          <ComingSoonButtomTitle>WHAT ARE QR CODES?</ComingSoonButtomTitle>
          <ComingSoonButtomItemWrapper>
            <ComingSoonButtomItem>
              <Payment />
              <ComingSoonButtomItemText>
                Recieve payments directly from your customers when they scan your payment code at your stores
              </ComingSoonButtomItemText>
            </ComingSoonButtomItem>
            <ComingSoonButtomItem>
              <Payment />
              <ComingSoonButtomItemText>
                Manage transactions going on in your QR code linked to all your outlets
              </ComingSoonButtomItemText>
            </ComingSoonButtomItem>
          </ComingSoonButtomItemWrapper>
        </ComingSoonButtom> */}
      </ComingSoonWrappper>
    </ContentWrapper>
  );
}
