import { IconsEnum, NIcons } from 'kudi-component-library';
import { Clickable, SectionHeader } from '../../styles';
import IssueOptionList from '../base/issue_option_list';
import { OptionItemProp } from '../base/issue_option';
import { ReactComponent as PhoneCall } from '../../assets/phone_call.svg';
import useRedux from '../../../../hooks/useRedux';
import { updateCurrentPage } from '../../reducer/slice';
import Skeleton from '../../../../components/skeleton';

const Others = () => {
  const {
    dispatch,
    state: {
      support: {
        contacts: { isLoading },
      },
    },
  } = useRedux();
  const OthersOptionList: OptionItemProp[] = [
    {
      Icon: <NIcons icon={IconsEnum.OUTLINE_BUSINESS} />,
      badge: true,
      text: 'Office Address',
      description: 'Average Response time: 10 mins',
      action: () => dispatch(updateCurrentPage('office-adrress')),
    },
    {
      Icon: <PhoneCall />,
      badge: true,
      text: 'Call',
      description: 'Average Response time: 2 mins',
      action: () => dispatch(updateCurrentPage('call-line')),
    },
    {
      Icon: <NIcons icon={IconsEnum.OUTLINE_MAIL} />,
      badge: true,
      text: 'Email address',
      description: 'Average Response time: 12 hours',
      extra: 'support@nomba.com',
      action: () => {},
      href: 'mailto:support@nomba.com',
    },
  ];

  return (
    <div>
      <SectionHeader>
        <p>Others</p>
      </SectionHeader>
      <IssueOptionList loading={isLoading} list={OthersOptionList} />
    </div>
  );
};

export default Others;
