import { Button, IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { CoverImageDescriptionBox, HeaderBox, ButtonBox } from '../styles';
import { UploadCloudIcon } from '../../../assets/svg';
import Modal from '../../../components/Modal';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { useAppSelector } from '../../../app/hooks';
import { getUserBrowser } from '../../../utils';
import CSVImage from './CSVimage';
import useBulkUploadMeals from '../models/useBulkUploadMeals';

const UploadPreview = styled(Flex)`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
`;
const data: any[][] = [
  [
    'name',
    'description',
    'price',
    'categoryName',
    'quantity',
    'depletable',
    'autoGenerateSKU',
    'sku',
    'discountName',
    'storeId',
    'imageUrl',
  ],
  ['Chicken Pizz', 'Chicken Pizza with cheese', '5000', 'UNCATEGORIZED', '10', 'FALSE', 'TRUE', '', '', '', ''],
];

const convertToCSV = (arr: any[][]) => {
  const csvContent = arr.map((row) => row.join(',')).join('\n');
  return csvContent;
};

const downloadCSV = (csvContent: string, fileName: string) => {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();
};

const convertFileToFormData = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

const BulkUploadModal = ({
  showModal,
  onClose,
  refetch,
}: {
  showModal: boolean;
  onClose: () => void;
  refetch: () => void;
}) => {
  const { fullUser } = useAppSelector((state) => state.auth);
  const [fileName, setFileName] = useState<string>('');
  const [fileSize, setFileSize] = useState<number>();
  const [uploadFile, setUploadFile] = useState<FormData>();

  const { isLoading, handleUploadItems, errors, setErrors } = useBulkUploadMeals();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      setFileSize(file.size);
      const convertedFile = convertFileToFormData(file);
      setUploadFile(convertedFile);
      setErrors([]);
    }
  };

  useEffect(() => {
    Mixpanel.track('MENU_BULK_UPLOAD_VIEWED', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
    });
  }, []);

  const handleDownload = () => {
    const csvContent = convertToCSV(data);
    downloadCSV(csvContent, 'example.csv');
  };

  return (
    <Modal
      showModal={showModal}
      onClose={() => {
        onClose();
      }}
      customDesktopWidth="500px"
      customTabletWidth="65rem"
      padding="0"
      padCloseButton
      modalContentStyles={{ borderRadius: '5px' }}
    >
      <Box minHeight="700px" mb="200px">
        <Flex flexDirection="column" justifyContent="center">
          <HeaderBox py="20px" px="30px">
            <Typography variant="n-text5">Bulk Upload</Typography>
          </HeaderBox>
          <Box mt="20px" mx="30px">
            <Box mb="5px">
              <Typography fontWeight={500}>How to Upload</Typography>
            </Box>
            <Box mb="20px">
              <Typography color="n-grey5" variant="n-text1">
                1. Don’t have a template, click{' '}
                <Typography
                  color="n-dark"
                  variant="n-text1"
                  style={{ borderBottom: 'solid 1px #FC0', display: 'inline' }}
                  onClick={handleDownload}
                >
                  here
                </Typography>{' '}
                to download
              </Typography>
              <Box my="5px">
                <Typography color="n-grey5" variant="n-text1">
                  2. Add your data to the template file
                </Typography>
              </Box>
              <Box mb="5px" ml="10px">
                <Typography color="n-grey2a" variant="n-text1">
                  (and make sure to export or save as a .csv)
                </Typography>
              </Box>
              <Typography color="n-grey5" variant="n-text1">
                3. Add your file below for processing
              </Typography>
            </Box>

            <Typography color="n-grey5" variant="n-text1">
              Need a hand?{' '}
              <Typography
                color="n-dark"
                variant="n-text1"
                style={{ borderBottom: 'solid 1px #FC0', display: 'inline' }}
              >
                Send an email
              </Typography>{' '}
              to our operations team
            </Typography>
          </Box>

          <CoverImageDescriptionBox p="20px" mt="32px" flexDirection="column" mx="30px" width="inherit">
            <input
              type="file"
              id="sample-doc"
              accept=".csv"
              hidden
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
            />
            <label htmlFor="sample-doc" style={{ cursor: 'pointer' }}>
              <Flex flexDirection="column" justifyContent="center" alignItems="center">
                <UploadCloudIcon height="45px" width="45px" />
                <Typography variant="n-text1" style={{ marginBottom: '5px' }}>
                  Add your .csv file here
                </Typography>
                <Typography color="n-grey2a" variant="n-text0">
                  Click here to add, or drag and drop
                </Typography>
              </Flex>
            </label>
          </CoverImageDescriptionBox>

          {fileName && fileSize && (
            <UploadPreview alignItems="center" justifyContent="space-between" p="20px" m="30px">
              <Flex alignItems="center" style={{ gap: '10px' }}>
                <CSVImage />
                <Box>
                  <Typography variant="n-text1" style={{ marginTop: '10px' }}>
                    {fileName}
                  </Typography>
                  <Typography variant="n-text1" color="n-grey3a" style={{ marginTop: '10px' }}>
                    {(fileSize / 1024).toFixed(2)} KB
                  </Typography>
                </Box>
              </Flex>
              <Box
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setFileName('');
                  setFileSize(undefined);
                  setUploadFile(undefined);
                }}
              >
                <NIcons icon={IconsEnum.OUTLINE_X} color="n-grey4" />
              </Box>
            </UploadPreview>
          )}

          {errors && uploadFile && errors.length > 0 && (
            <Box px="30px">
              <Box mb="10px">
                <Typography variant="n-text1" color="n-red">
                  {errors.length} errors found
                </Typography>
              </Box>
              {errors.map((item) => (
                <Typography variant="n-text1" color="n-red">
                  {item.name}
                </Typography>
              ))}
              <Box mt="10px">
                <Typography variant="n-text1" color="n-red">
                  Kindly edit your file to fix the numbers mentioned and re-upload. Thank you
                </Typography>
              </Box>
            </Box>
          )}

          <ButtonBox py="30px" px="30px">
            <Button
              disabled={!fileName}
              loading={isLoading}
              onClick={() => {
                if (uploadFile) {
                  handleUploadItems(uploadFile, () => {
                    onClose();
                    refetch();
                  });
                }
              }}
              width="100%"
              height="45px"
              variant="small"
              id="meni-add-new-meal-submit-button"
            >
              Upload file
            </Button>
          </ButtonBox>
        </Flex>
      </Box>
    </Modal>
  );
};

export default BulkUploadModal;
