import { useEffect } from 'react';
import { useHeaderContext } from '../utils/headerContext';

const useSetPageTitle = (title: string) => {
  const { setPageTitle } = useHeaderContext();
  useEffect(() => {
    setPageTitle(title);
  }, [setPageTitle, title]);
};

export default useSetPageTitle;
