import styled from 'styled-components';
import { convertHexToRGBA } from '../../../utils';

export const ChartWrapper = styled.div`
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
`;

export const ChartLedger = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ChartLedgerLabel = styled.h4`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-feature-settings: 'cv11' on;
  color: #717171;
`;

export const ChartLedgerValue = styled.h3`
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: #121212;
`;

// charts

export const TransactionchartsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

// line chart

export const TransactionLineChartWrapper = styled.div`
  padding: 3.3rem 2.2rem 2.4rem 0;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  width: 100%;
  @media ${({ theme }) => theme.media.lg} {
    width: 65%;
  }
`;

export const TransactionLineChartTop = styled.div`
  display: flex;
  padding-left: 2.2rem;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.media.lg} {
    gap: 4rem;
    margin-bottom: 7rem;
  }
`;

export const TransactionLineChartLegend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const TransactionLineChartLegendTop = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const TransactionLineChartLegendTopText = styled.span`
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-feature-settings: 'tnum' on, 'lnum' on, 'cv11' on;
  color: #717171;
`;

export const TransactionLineChartLegendValue = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};
  margin-left: 3rem;
`;

export const TransactionLineChartLegendColour = styled.div<{ backgroundColour?: string }>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${({ backgroundColour, theme }) => backgroundColour ?? theme.colors.primary.black1};
`;

// pie chart

export const TransactionPieChartWrapper = styled.div`
  padding: 8.9rem 2.2rem 2.4rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  width: 100%;
  @media ${({ theme }) => theme.media.lg} {
    width: 35%;
  }
`;

export const TransactionPieChartBottom = styled.div`
  display: flex;
  padding-left: 2.2rem;
  gap: 2rem;
  margin-top: 4.7rem;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const TransactionPieChartLegend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 45%;
`;

export const TransactionPieChartLegendTop = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export const TransactionPieChartLegendTopText = styled.span`
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-feature-settings: 'tnum' on, 'lnum' on, 'cv11' on;
  color: #717171;
`;

export const TransactionPieChartLegendValue = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 30px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};
  margin-left: 1.6rem;
`;

export const TransactionPieChartLegendColour = styled.div<{ backgroundColour?: string }>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${({ backgroundColour, theme }) => backgroundColour ?? theme.colors.primary.black1};
`;

export const LineChartEmptyWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 4rem 0 8rem;
  p {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-feature-settings: 'cv11' on;
    color: #c6c6c6;
  }
  @media ${({ theme }) => theme.media.lg} {
    padding: 8rem 0 16rem;
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const PieChartEmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PieChartEmptyOuter = styled.div`
  background-color: #fff1b3;
  width: 21.5rem;
  height: 21.5rem;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const PieChartEmptyInner = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.white};
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
`;

export const ToolTipWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 17.5625rem;
  padding: 1.0625rem;
`;

export const ToolTipText = styled.h3`
  font: 400;
  font-size: 1rem;
  color: ${(props) => props.color};
`;

export const ToolTipFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

export const ToolTipBorder = styled.div`
  width: 0.875rem;
  height: 0.1875rem;
  background: ${(props) => props.color};
  margin-top: 0.6rem;
  border-radius: 8px;
`;

export const CapitalcaseText = styled.span`
  text-transform: capitalize;
`;

const colourMap: Record<string, string> = {
  success: '#11BB00',
  new: '#121212',
  failed: '#FF2200',
  payment_failed: '#FF2200',
};

export const TransactionStatus = styled.span<{ type: string }>`
  color: ${({ type }) => (colourMap?.[type.toLowerCase()] ? colourMap?.[type.toLowerCase()] : '#121212')};
  text-transform: capitalize;
`;

export const HeaderButtonTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ExportButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.1rem;
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
    /* n-grey4 */
    color: #717171;
    display: block;
    margin-right: 1rem;
  }
`;

export const TrasanctionOverviewFilterWrapper = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  height: 6rem;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const TransactionDetailsOuter = styled.div`
  background: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
`;
export const TransactionDetails = styled.div`
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 20px;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.md} {
    width: 500px;
  }
`;

export const EmptyTransactionDetails = styled(TransactionDetailsOuter)`
  height: 600px;
  display: flex;
  justify-content: center;
`;

export const TransactionDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: 0;
  }
`;

export const LeftTransactionContent = styled.div``;
export const RightTransactionContent = styled.div`
  button {
    background-color: transparent;
    border: none !important;
    outline: none !important;
  }
  button > span {
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TransactionAmountWrapper = styled.div<{ isCredit?: boolean }>`
  p {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ isCredit, theme }) => (isCredit ? theme.nColors['n-green'] : theme.nColors['n-red'])};
  }
`;

// -------------------------------- TRANSACTION ISSUE TYPE MODAL --------------------------//
export const IssueTypeModalContainer = styled.div`
  height: 100svh;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 540px;
  background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'].trim(), 0.25)};
  display: flex;
  align-items: flex-end;
`;

export const IssueTypeModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 60px;
  position: relative;
  border-radius: 20px 20px 0 0;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  width: 100%;
  align-items: flex-start;
`;

export const IssueTypeModalContentClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  margin-left: auto;
  margin-bottom: 10px;
  cursor: pointer;

  svg {
    scale: 0.75;
  }
`;

export const IssueTypeModalContentTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 60px;
  text-align: left;

  > div {
    align-items: flex-start;
    gap: 10px;
  }
`;
