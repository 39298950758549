import { useCallback, useRef } from 'react';
import useRedux from '../../../hooks/useRedux';
import {
  clearIssueListFilter,
  clearIssueListPagination,
  updateIssueListFilter,
  updateShowIssueListFilter,
} from '../reducer/slice';
import { IssueListFilterStatus } from '../defs';
import { GetAllIssues } from '../reducer/thunk_actions';

const useMyIssueModel = () => {
  const {
    dispatch,
    state: {
      support: {
        issueList: { pagination },
      },
    },
  } = useRedux();

  const onRefetch = () => {
    dispatch(clearIssueListPagination());
    dispatch(GetAllIssues({}));
  };

  const toggleShow = (value: boolean) => {
    dispatch(updateShowIssueListFilter(value));
  };

  const onClearAll = () => {
    dispatch(clearIssueListFilter());
  };

  const onApply = (filter: Partial<Record<string, string | string[]>>) => {
    dispatch(updateIssueListFilter(filter));
  };

  const FetchAllIssues = useCallback(() => {
    if (pagination.lastPage) return;
    dispatch(GetAllIssues({}));
  }, [dispatch, pagination.lastPage]);

  return { onRefetch, toggleShow, onClearAll, onApply, FetchAllIssues };
};

export default useMyIssueModel;
