import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

interface InitialValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

type VerifiableInfo = Pick<InitialValues, 'phoneNumber' | 'email'>;

const useCreateAccount = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [verifyStep, setVerifyStep] = useState<'phone' | 'email'>('phone');
  const [verificationInfo, setVerificationInfo] = useState<VerifiableInfo>({
    phoneNumber: '',
    email: '',
  });

  const InitialFormValues: InitialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .matches(/^[A-Za-z]+$/, 'enter a valid name')
      .min(2, 'Kindly check your first name')
      .trim(),
    lastName: Yup.string()
      .required('Last name is required')
      .matches(/^[A-Za-z]+$/, 'enter a valid name')
      .min(2, 'Kindly check your last name')
      .trim(),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .min(10, 'Enter a valid phone number')
      .max(11, 'Enter a valid phone number')
      .trim(),
    email: Yup.string().required('Email is required').email('Enter a valid email').trim(),
  });

  return {
    InitialFormValues,
    validationSchema,
    verifyStep,
    setVerifyStep,
    showModal,
    setShowModal,
    accountType: location.pathname?.split('/auth/onboardingv3')?.[1]?.split('/')?.[1] || 'account-type',
    setVerificationInfo,
    verificationInfo,
  };
};

export default useCreateAccount;
