import { useState } from 'react';
import Table from '../../../components/newTable';
import { TransferRequestSchema } from './schema';
import { TransferRequestData, TransferRequestResponse } from './utils';
import TransferRequestDetailsModal from './transferRequestDetails';
import UpdateTransferStatusModal from './updateRequestModal';

export default function TransferRequest({
  data,
  loading,
  currentPage,
  handlePaginationChange,
  setReload,
}: {
  data: TransferRequestResponse | null;
  loading: boolean;
  currentPage: number;
  handlePaginationChange: (page: number) => void;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [transferRequestDetails, setTransferRequestDetails] = useState<TransferRequestData | null>(null);
  const [showTransferRequestDetails, setShowTransferRequestDetails] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');

  const handleCloseTransferDetails = () => setShowTransferRequestDetails(false);
  const handleRowClick = (requestData: TransferRequestData) => {
    setTransferRequestDetails(requestData);
    setShowTransferRequestDetails(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };
  const handleUpdateModal = (val: string) => {
    setShowUpdateModal(true);
    setAction(val);
    handleCloseTransferDetails();
  };

  return (
    <>
      <Table
        schema={TransferRequestSchema}
        data={data?.data?.content || []}
        emptyText="Sorry there are no transfer requests"
        isLoading={loading}
        flatBottom
        isPaginated={false}
        onRowCLick={handleRowClick}
        paginationProps={{
          handlePaginationChange,
          shouldShowSelect: false,
          isLastPage: currentPage === data?.data?.totalPages,
        }}
      />
      {showTransferRequestDetails ? (
        <TransferRequestDetailsModal
          showModal={showTransferRequestDetails}
          onClose={handleCloseTransferDetails}
          handleUpdateModal={handleUpdateModal}
          transferRequestDetails={transferRequestDetails}
        />
      ) : null}

      {showUpdateModal && transferRequestDetails !== null && (
        <UpdateTransferStatusModal
          showModal={showUpdateModal}
          handleCloseModal={handleCloseUpdateModal}
          transferRequestDetails={transferRequestDetails}
          modalAction={action}
          setReload={setReload}
        />
      )}
    </>
  );
}
