import { useEffect } from 'react';
import Header from '../base/header';
import { HomeWrapper, SupportPageDetailsWrapper } from '../../styles';
import ActiveQequest from './active_request';
// import SelfService from './self_service';
import Others from './others';
import Hero from './hero';
import useRedux from '../../../../hooks/useRedux';
import { toggleShow } from '../../reducer/slice';
import SocialMedia from './social_media';
import { GetContactDetails } from '../../reducer/thunk_actions';

const Home = () => {
  const { dispatch } = useRedux();

  useEffect(() => {
    dispatch(GetContactDetails({}));
  }, [dispatch]);

  return (
    <>
      <Header
        desktopTitle="Support"
        desktopDescription="Select how you would want us to help you"
        mobileBackAction={() => dispatch(toggleShow())}
        desktopCloseAction={() => dispatch(toggleShow())}
      />
      <Hero />
      <SupportPageDetailsWrapper mobilePadding="0 20px">
        <HomeWrapper>
          <ActiveQequest />
          {/* <SelfService /> */}
          <Others />
          <SocialMedia />
        </HomeWrapper>
      </SupportPageDetailsWrapper>
    </>
  );
};

export default Home;
