import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { useToast } from '../../../../hooks/useToast';
import addDiscount from '../../../../queries/inventory/discounts/addDiscount';
import fetchDiscounts from '../../../../queries/inventory/discounts/fetchDiscounts';
import { Discount, HandleAddNewDiscount } from '../../../../types/inventory/discounts';
import { TOAST_TIME_LIMIT } from '../../../inventory/constants';
import { Mixpanel } from '../../../../hooks/useMixpanel';
import { getUserBrowser } from '../../../../utils';

const useAddNewMenuDiscounts = () => {
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [showNewDiscountModal, setShowNewDiscountModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const { inventoryStore: store } = useAppSelector((state) => state.dashboard);
  const { fullUser } = useAppSelector((state) => state.auth);
  const toast = useToast(TOAST_TIME_LIMIT);

  const handleCloseNewDiscountModal = () => setShowNewDiscountModal(false);

  const handleOpenNewDiscountModal = () => setShowNewDiscountModal(true);

  const handleRefetchDiscounts = () => setRefetch(!refetch);

  const handleAddNewDiscount: HandleAddNewDiscount = async (values, setSubmitting) => {
    const { name, discountPercent } = values;

    const { error: addError, result: addResult } = await addDiscount({
      name,
      discountPercent,
      storeId: store?.storeId || '',
    });
    setSubmitting(false);
    setShowNewDiscountModal(false);
    if (addResult) {
      toast('success', 'You have successfully added a new discount');
      Mixpanel.track('MENU_ADD_NEW_DISCOUNT', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
        discount: name,
      });
      handleRefetchDiscounts();
    }

    if (addError) {
      Mixpanel.track('MENU_ERRROR_ADDING_NEW_DISCOUNT', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
        discount: name,
      });
      toast('error', 'An error occured while trying to add a new discount');
    }
  };

  useEffect(() => {
    const request = new AbortController();

    async function handleFetchDiscounts() {
      setIsLoading(true);
      const { result, error: fetchError } = await fetchDiscounts({});
      setIsLoading(false);

      if (result?.data) {
        setDiscounts(result.data.list);
      }
      if (fetchError instanceof Error) toast('error', 'Error occured while fetching discounts');
    }

    handleFetchDiscounts();
    return () => request.abort();
  }, [refetch]);

  return {
    discounts,
    handleAddNewDiscount,
    showNewDiscountModal,
    handleOpenNewDiscountModal,
    handleCloseNewDiscountModal,
    isLoading,
    handleRefetchDiscounts,
  };
};

export default useAddNewMenuDiscounts;
