import { ActionReducerMapBuilder, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { DownlineModal, ReferralInitialState } from './slice';
import { ReferralDownlines } from '../../types/referrals';
import { MapTerminal } from '../../pages/referral/reducer/thunk_actions/map_terminal';
import {
  DownlineTransactions,
  NextDownlineTransactions,
} from '../../pages/referral/reducer/thunk_actions/downline_transactions';
import { DownlineTerminals } from '../../pages/referral/reducer/thunk_actions/downline_terminals';
import { TerminalArrayListItemMap } from '../../pages/referral/defs';
import { UnmapTerminal } from '../../pages/referral/reducer/thunk_actions/unmap_terminal';

export const addExtraReducers = (builder: ActionReducerMapBuilder<ReferralInitialState>) => {
  // Map terminal
  builder
    .addCase(MapTerminal.pending, (state) => {
      state.downline.isLoading = 'MapTerminal';
    })
    .addCase(MapTerminal.rejected, (state) => {
      state.downline.isLoading = null;
    })
    .addCase(MapTerminal.fulfilled, (state) => {
      state.downline.isLoading = null;
    });

  // Unmap terminal
  builder
    .addCase(UnmapTerminal.pending, (state) => {
      state.downline.isLoading = 'UnmapTerminal';
    })
    .addCase(UnmapTerminal.rejected, (state) => {
      state.downline.isLoading = null;
    })
    .addCase(UnmapTerminal.fulfilled, (state) => {
      state.downline.isLoading = null;
    });

  // Downline transactions
  builder
    .addCase(DownlineTransactions.pending, (state) => {
      state.downline.transactions.loading = true;
    })
    .addCase(DownlineTransactions.rejected, (state) => {
      state.downline.transactions.loading = false;
    })
    .addCase(DownlineTransactions.fulfilled, (state, action) => {
      state.downline.transactions.loading = false;
      state.downline.transactions.data = action.payload.data;
      state.downline.transactions.list[0] = action.payload.data;
      state.downline.transactions.cursor = action.payload.next;
    });

  // Next downline transctions
  builder
    .addCase(NextDownlineTransactions.pending, (state) => {
      state.downline.transactions.loading = true;
    })
    .addCase(NextDownlineTransactions.rejected, (state) => {
      state.downline.transactions.loading = false;
    })
    .addCase(NextDownlineTransactions.fulfilled, (state, action) => {
      state.downline.transactions.loading = false;
      state.downline.transactions.data = action.payload.data;
      state.downline.transactions.list[action.meta.arg.page] = action.payload.data;
      state.downline.transactions.cursor = action.payload.next;
    });

  // Downline terminals
  builder
    .addCase(DownlineTerminals.pending, (state) => {
      state.downline.terminals.loading = true;
    })
    .addCase(DownlineTerminals.rejected, (state) => {
      state.downline.terminals.loading = false;
    })
    .addCase(DownlineTerminals.fulfilled, (state, action) => {
      state.downline.terminals.loading = false;
      state.downline.terminals.data = action?.payload?.data?.map?.data?.myArrayList?.map((item) => item?.map) || [];
    });
};

export const addReducer: ValidateSliceCaseReducers<ReferralInitialState, SliceCaseReducers<ReferralInitialState>> = {
  selectDownline: (state, action: PayloadAction<ReferralDownlines | null>) => {
    state.downline.selectedDownline = action.payload;
  },

  toggleShowModal: (state, action: PayloadAction<DownlineModal>) => {
    state.downline.showModal = action.payload;
  },

  selectTerminal: (state, action: PayloadAction<TerminalArrayListItemMap | null>) => {
    state.downline.selectedTerminal = action.payload;
  },

  selectTransactionData: (state, action) => {
    state.downline.transactions.data = state.downline.transactions.list[action.payload];
  },

  resetTransactionData: (state) => {
    state.downline.transactions.data = [];
    state.downline.transactions.list = {};
  },
};
