/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
import React from 'react';
import { Text } from 'kudi-component-library';
import moment from 'moment';
import { FullBox, FullFlex } from '../../../styles/generic';
import { DesktopWrapper } from '../../loan-provider/components/filter';
import { MobileWrapper } from '../../loan-provider/components/table';
import { BeneficiariesData } from '../../../types/transactions';
import { AddBeneficiaryIcon } from '../../../assets/svg';
import EditBeneficiaryModal from './editbeneficiarymodal';
import DeleteBeneficiaryModal from './deletebeneficiarymodal';
import SingleTransfer from '../singleandbulktransfer/singletransfermodal';
import Beneficiarymodal from './beneficiarymodal';
import Modal from '../../../components/Modal';
import Table from '../../../components/newTable';
import { BeneficiariesSchema } from './schema';

function ResponseModal({
  showResponseModal,
  setShowResponseModal,
}: {
  showResponseModal: boolean;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal showModal={showResponseModal} onClose={() => setShowResponseModal(false)}>
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="90px">
        <AddBeneficiaryIcon />

        <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
          Successfully updated beneficiary
        </Text>
        <Text color="#717171" fontSize="14px" textAlign="center">
          You can now transfer money to the updated beneficiary.
        </Text>
      </FullFlex>
    </Modal>
  );
}

export default function BeneficiariesTable({
  beneficiariesData,
  setReload,
  reload,
  loading,
  handlePaginationChange,
}: {
  beneficiariesData?: Array<BeneficiariesData>;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  reload?: boolean;
  loading: boolean;
  handlePaginationChange: (page: number) => void;
}) {
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [showEditBeneficiaryModal, setShowEditBeneficiaryModal] = React.useState(false);
  const [showDeleteBeneficiaryModal, setShowDeleteBeneficiaryModal] = React.useState(false);
  const [showSingleTransfer, setShowSingleTransfer] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<BeneficiariesData | null>(null);
  const [showResponseModal, setShowResponseModal] = React.useState(false);

  const editRowHandler = (rowData: BeneficiariesData) => {
    setSelectedData(rowData);
    setShowEditBeneficiaryModal(true);
  };
  const deleteRowHandler = (rowData: BeneficiariesData) => {
    setSelectedData(rowData);
    setShowDeleteBeneficiaryModal(true);
  };
  const transferRowHandler = (rowData: BeneficiariesData) => {
    setSelectedData(rowData);
    setShowSingleTransfer(true);
  };

  return (
    <FullBox mt="20px" fontWeight="regular" width="100%">
      <DesktopWrapper>
        <Table
          schema={BeneficiariesSchema}
          data={beneficiariesData || []}
          gridActions
          isLoading={loading}
          actions={[
            {
              text: 'Transfer',
              value: 'transfer',
              mobileButtonVariant: 'medium',
              onClickAction: (rowData: BeneficiariesData) => transferRowHandler(rowData),
            },
            {
              text: 'Edit',
              value: 'edit',
              mobileButtonVariant: 'medium',
              onClickAction: (rowData: BeneficiariesData) => editRowHandler(rowData),
            },

            {
              text: 'Delete',
              value: 'delete',
              mobileButtonVariant: 'medium',
              customButtonType: { type: 'outlined' },
              onClickAction: (rowData: BeneficiariesData) => deleteRowHandler(rowData),
            },
          ]}
          paginationProps={{
            handlePaginationChange,
            shouldShowSelect: false,
            isLastPage: beneficiariesData && beneficiariesData.length < 10,
          }}
        />
        {selectedData !== null && (
          <SingleTransfer
            selectedData={selectedData}
            showSingleTransfer={showSingleTransfer}
            setShowSingleTransfer={setShowSingleTransfer}
            setSelectedData={setSelectedData}
          />
        )}
        {showEditBeneficiaryModal && selectedData !== null && setReload && (
          <EditBeneficiaryModal
            reload={reload}
            setReload={setReload}
            showEditBeneficiaryModal={showEditBeneficiaryModal}
            setShowEditBeneficiaryModal={setShowEditBeneficiaryModal}
            beneficiary={selectedData}
            showResponseModal={showResponseModal}
            setShowResponseModal={setShowResponseModal}
          />
        )}
        {showDeleteBeneficiaryModal && selectedData !== null && (
          <DeleteBeneficiaryModal
            reload={reload}
            setReload={setReload}
            beneficiary={selectedData}
            showDeleteBeneficiaryModal={showDeleteBeneficiaryModal}
            setShowDeleteBeneficiaryModal={setShowDeleteBeneficiaryModal}
          />
        )}
        {showResponseModal && (
          <ResponseModal showResponseModal={showResponseModal} setShowResponseModal={setShowResponseModal} />
        )}
      </DesktopWrapper>

      <MobileWrapper>
        {beneficiariesData?.map(
          (
            data: {
              name: string;
              bankName: string;
              accountNo: string;
              nickName: string;
              businessId: string;
              bankCode: string;
              remark: string;
              id: string;
              createdAt: string;
            },
            index
          ) => (
            <>
              <FullFlex
                onClick={() => {
                  setSelectedData(data);
                  setShowDetailsModal(true);
                }}
                key={index}
                style={{ padding: '20px' }}
                justifyContent="space-between"
              >
                <FullBox>
                  <Text style={{ fontWeight: 'normal', fontSize: '14px', color: '#717171' }}>{data?.name}</Text>
                  <Text style={{ fontWeight: 'normal', fontSize: '10px', color: '#717171' }}>
                    {moment(data.createdAt).date()} {moment(data.createdAt).format('MMM')},{' '}
                    {moment(data.createdAt).year()}
                  </Text>
                </FullBox>
                <FullBox>
                  <Text style={{ fontWeight: 'normal', fontSize: '14px', color: '#717171' }}>{data?.accountNo}</Text>
                  <Text style={{ fontWeight: 'normal', fontSize: '10px', color: '#717171' }}>{data?.bankName}</Text>
                </FullBox>
              </FullFlex>
              <hr style={{ height: '0px', borderWidth: '0.4px', color: '#EEEEEE' }} />
              {selectedData !== null && (
                <Beneficiarymodal
                  showResponseModal={showResponseModal}
                  setShowResponseModal={setShowResponseModal}
                  reload={reload}
                  setReload={setReload}
                  showDetailsModal={showDetailsModal}
                  setShowDetailsModal={setShowDetailsModal}
                  beneficiary={selectedData}
                />
              )}
            </>
          )
        )}
      </MobileWrapper>
    </FullBox>
  );
}
