/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, OtpInput, Text, TextInput } from 'kudi-component-library';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Modal from '../../../components/Modal';
import { FullBox, FullFlex } from '../../../styles/generic';
import CalendarFilter from '../../../components/Calendar';
import { NewInfoIcon, ErrorIcon, WithdrawSuccessIcon } from '../../../assets/svg';
import {
  KycAccountLookUpResponse,
  TransferToNombaResponse,
  nombaAccountLookUp,
  transferToNombaAccount,
} from '../../../features/transfers/thunkActions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PayloadData } from '../../../types/transactions';
import { formatCurrency } from '../../../utils';
import TimePicker from '../../../components/TimePicker';
import { timeFormatter, getScheduleDate } from '../../../utils/dateFormatter';
import { fetchBalance } from '../../../features/banking/balance/slice';
import ConfirmOtp from '../otp';
import { setOnboardingStage } from '../../../features/auth/slice';
import { ResultStatus } from '../../../utils/constants';

interface InitialValues {
  email: string;
  name: string;
  schedule: string;
  frequency: string;
  token: string;
}
const InitialFormValues: InitialValues = {
  email: '',
  name: '',
  schedule: '',
  frequency: '',
  token: '',
};

function ErrorResponseModal({
  error,
  showErrorResponseModal,
  setShowErrorResponseModal,
  setAmount,
  setNombaEmail,
  setName,
  setOtpError,
  setTransactionPin,
}: {
  error: string;
  showErrorResponseModal: boolean;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setNombaEmail: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setOtpError: React.Dispatch<React.SetStateAction<string>>;
  setTransactionPin: React.Dispatch<React.SetStateAction<string>>;
  setShowErrorResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal
      showModal={showErrorResponseModal}
      onClose={() => {
        setAmount(0);
        setNombaEmail('');
        setName('');
        setOtpError('');
        setTransactionPin('');
        setShowErrorResponseModal(false);
      }}
    >
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="10px">
        <ErrorIcon />

        <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
          Transaction failed
        </Text>
        <Text color="#717171" fontSize="14px" textAlign="center">
          {error}
        </Text>

        <Button
          id="close-nomba-transfer-error"
          onClick={() => {
            setAmount(0);
            setNombaEmail('');
            setName('');
            setOtpError('');
            setTransactionPin('');
            setShowErrorResponseModal(false);
          }}
          width="80%"
          variant="xlarge"
          style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '90px' }}
        >
          Close
        </Button>
      </FullFlex>
    </Modal>
  );
}

function ResponseModal({
  amount,
  beneficiary,
  showResponseModal,
  transferType,
  scheduledDate,
  setShowResponseModal,
}: {
  amount?: number;
  beneficiary?: string;
  transferType?: string;
  scheduledDate?: string | null;
  showResponseModal: boolean;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal showModal={showResponseModal} onClose={() => setShowResponseModal(false)}>
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="20px">
        <WithdrawSuccessIcon />
        <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
          Payment Processing
        </Text>
        {amount && transferType === 'Immediately' && (
          <Text color="#717171" fontSize="14px" textAlign="center">
            Your transfer of {formatCurrency(amount)} to {beneficiary} is being processed.
          </Text>
        )}
        {amount && transferType === 'Scheduled' && scheduledDate && (
          <Text color="#717171" fontSize="14px" textAlign="center">
            Your payment of {formatCurrency(amount)} to {beneficiary} has been scheduled for{' '}
            {new Date(scheduledDate).toLocaleString([], {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
            at {timeFormatter(scheduledDate)}
          </Text>
        )}

        <Button
          id="close-nomba-transfer-success"
          onClick={() => {
            setShowResponseModal(false);
            window.location.reload();
          }}
          width="80%"
          variant="xlarge"
          style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '90px' }}
        >
          Done
        </Button>
      </FullFlex>
    </Modal>
  );
}

function ConfirmTransactionModal({
  showConfirmModal,
  setShowConfirmModal,
  transferPayload,
  setAmount,
  setNombaEmail,
  setName,
  setShowPopup,
  setShowTransferToNombaModal,
}: {
  showConfirmModal: boolean;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setNombaEmail: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTransferToNombaModal: React.Dispatch<React.SetStateAction<boolean>>;
  transferPayload: {
    email: string;
    beneficiaryName: string;
    amount: number;
    frequency: string;
    transferType: string;
    scheduledDate: string | null;
    repeatPayment: boolean;
    userId: string;
    userPin: string;
  } | null;
}) {
  const [transactionPin, setTransactionPin] = React.useState<string>('');
  const [otpError, setOtpError] = React.useState<string>('');
  const [errMsg, setErrMsg] = React.useState<string>('');
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [showErrorResponseModal, setShowErrorResponseModal] = React.useState(false);
  const [showOtpModal, setShowOtpModal] = React.useState<boolean>(false);
  const [transRef, setTransRef] = React.useState<string>('');
  const [otpDest, setOtpDest] = React.useState<string>('');

  const confirmTokenSchema = Yup.object().shape({
    token: Yup.string().length(4).required(),
  });

  const dispatch = useAppDispatch();
  const { isSubmitting } = useAppSelector((state) => state.transfers);

  function handleOtpChange(val: string) {
    setOtpError('');
    setTransactionPin(val);
  }

  function confirmTransfer() {
    if (transferPayload !== null) {
      dispatch(
        transferToNombaAccount({
          email: transferPayload.email,
          beneficiaryName: transferPayload.beneficiaryName,
          amount: transferPayload.amount,
          transferType: transferPayload.transferType.toUpperCase(),
          frequency: transferPayload?.frequency?.toUpperCase(),
          scheduledDate: transferPayload?.scheduledDate,
          repeatPayment: transferPayload.repeatPayment,
          userPin: transactionPin,
          onFailure: (error: { data: { message: string; msg: string } }) => {
            if (error?.data?.message === 'Incorrect Pin') {
              setOtpError(error?.data?.message);
            } else {
              setErrMsg(error?.data?.message || error?.data?.msg);
              setShowConfirmModal(false);
              setShowErrorResponseModal(true);
            }
          },

          onSuccess: (data: TransferToNombaResponse) => {
            // nomba p2p otp trigger
            if (data.data.status === 'PENDING_OTP_VALIDATION') {
              setShowOtpModal(true);
              setOtpDest(data.data.otpDest);
              setTransRef(data.data.coreRef);
              setShowConfirmModal(false);
            } else {
              setShowConfirmModal(false);
              setShowResponseModal(true);
            }
          },
        })
      );
    }
  }

  return (
    <>
      <Modal
        showModal={showConfirmModal}
        onClose={() => {
          setAmount(0);
          setNombaEmail('');
          setName('');
          setOtpError('');
          setTransactionPin('');
          setShowConfirmModal(false);
        }}
      >
        <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pl="20px" pr="20px" pb="10px">
          <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
            Confirm this transaction
          </Text>

          <FullBox style={{ border: '1px dashed #FFCC00', borderRadius: '5px' }} width="100%" p="20px">
            <FullFlex justifyContent="space-between" mb="14px">
              <Text fontSize="14px">Email</Text>
              <Text fontSize="14px">{transferPayload?.email}</Text>
            </FullFlex>
            <FullFlex justifyContent="space-between" mb="14px">
              <Text fontSize="14px">Amount</Text>
              <Text color="#121212" fontSize="16px" fontWeight="bold">
                {transferPayload !== null && formatCurrency(transferPayload.amount)}
              </Text>
            </FullFlex>
            <FullFlex justifyContent="space-between">
              <Text fontSize="14px">Beneficiary</Text>
              <Text fontSize="14px">{transferPayload?.beneficiaryName}</Text>
            </FullFlex>
            {transferPayload?.scheduledDate && !transferPayload?.repeatPayment && (
              <FullFlex justifyContent="space-between" mt="14px">
                <Text fontSize="14px">Scheduled Payment Date</Text>
                <Text fontSize="14px">
                  {getScheduleDate(transferPayload?.scheduledDate)},
                  {new Date(transferPayload?.scheduledDate).toLocaleString([], {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                  ; {timeFormatter(transferPayload?.scheduledDate)}
                </Text>
              </FullFlex>
            )}

            {transferPayload?.repeatPayment && transferPayload.transferType === 'Immediately' && (
              <>
                <FullFlex justifyContent="space-between" mt="14px">
                  <Text fontSize="14px">First Payment Date</Text>
                  <Text fontSize="14px">
                    {getScheduleDate(new Date())},
                    {new Date().toLocaleString([], {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                    ; {timeFormatter(new Date())}
                  </Text>
                </FullFlex>
                <FullFlex justifyContent="space-between" mt="14px">
                  <Text fontSize="14px">Payment Repeat</Text>
                  <Text fontSize="14px">{transferPayload?.frequency}</Text>
                </FullFlex>
              </>
            )}
            {transferPayload?.repeatPayment &&
              transferPayload.transferType === 'Scheduled' &&
              transferPayload?.scheduledDate && (
                <>
                  <FullFlex justifyContent="space-between" mt="14px">
                    <Text fontSize="14px">Payment Date</Text>
                    <Text fontSize="14px">
                      {getScheduleDate(transferPayload?.scheduledDate)},
                      {new Date(transferPayload?.scheduledDate).toLocaleString([], {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                      ; {timeFormatter(transferPayload?.scheduledDate)}
                    </Text>
                  </FullFlex>
                  <FullFlex justifyContent="space-between" mt="14px">
                    <Text fontSize="14px">Payment Repeat</Text>
                    <Text fontSize="14px">{transferPayload?.frequency}</Text>
                  </FullFlex>
                </>
              )}
          </FullBox>

          <FullBox mt="40px" mb="30px" width="100%">
            <Formik initialValues={InitialFormValues} onSubmit={() => {}} validationSchema={confirmTokenSchema}>
              <OtpInput
                id="nomba-transfer-otp"
                inputsNumber={4}
                name="transactionPin"
                label="Enter transaction PIN"
                value={transactionPin}
                onChange={(e) => handleOtpChange(e.target.value)}
                error={otpError}
                type="password"
              />
            </Formik>
          </FullBox>

          <FullBox width="100%">
            <FullFlex alignItems="center" mb="10px">
              <NewInfoIcon fontSize="12px" />
              <Text color="#121212" fontSize="12px" ml="10px">
                Immediate transfers cannot be recalled
              </Text>
            </FullFlex>
          </FullBox>

          <FullBox width="100%" mt="10px">
            <Button
              id="nomba-transfer-confirm"
              onClick={() => {
                confirmTransfer();
              }}
              width="100%"
              variant="xlarge"
              style={{ backgroundColor: '#FFCC00', color: '#121212' }}
              disabled={transactionPin === '' || transactionPin.length < 4 || otpError || isSubmitting ? true : false}
            >
              Proceed
            </Button>
          </FullBox>
        </FullFlex>
      </Modal>
      <ResponseModal
        amount={transferPayload?.amount}
        beneficiary={transferPayload?.beneficiaryName}
        transferType={transferPayload?.transferType}
        scheduledDate={transferPayload?.scheduledDate}
        showResponseModal={showResponseModal}
        setShowResponseModal={setShowResponseModal}
      />
      <ErrorResponseModal
        error={errMsg}
        showErrorResponseModal={showErrorResponseModal}
        setShowErrorResponseModal={setShowErrorResponseModal}
        setAmount={setAmount}
        setNombaEmail={setNombaEmail}
        setName={setName}
        setOtpError={setOtpError}
        setTransactionPin={setTransactionPin}
      />
      <ConfirmOtp
        showOtpModal={showOtpModal}
        setShowOtpModal={setShowOtpModal}
        transRef={transRef}
        setShowResponseModal={setShowResponseModal}
        setShowPopup={setShowPopup}
        setShowTransferToNombaModal={setShowTransferToNombaModal}
        setAmount={setAmount}
        setNombaEmail={setNombaEmail}
        setName={setName}
        setUserPin={setTransactionPin}
        otpDest={otpDest}
      />
    </>
  );
}

export default function TransferToNomba({
  showTransferToNombaModal,
  setShowTransferToNombaModal,
  setShowPopup,
}: {
  showTransferToNombaModal: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTransferToNombaModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [amount, setAmount] = React.useState<number>(0);
  const [fee, setFee] = React.useState<number>(0);
  const [nombaEmail, setNombaEmail] = React.useState('');
  const [date, setDate] = React.useState<string>(moment().format('MM/DD/yyyy'));
  const [timeVal, setTimeVal] = React.useState('');
  const [inputError, setInputError] = React.useState('');
  const [lookupError, setLookupError] = React.useState('');
  const [name, setName] = React.useState('');
  const [schedule, setSchedule] = React.useState<string>('Immediately');
  const [frequency, setFrequency] = React.useState('Daily');
  const [repeat, setRepeat] = React.useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = React.useState<boolean>(true);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showTimes, setShowTimes] = React.useState(false);
  const [transferPayload, setPayload] = React.useState<PayloadData | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { inProgress } = useAppSelector((state) => state.transfers);
  const { parentBusinessId } = useAppSelector((state) => state.auth);
  const { availableBalance, isLoading } = useAppSelector((state) => state.banking.balance);
  const { fullUser } = useAppSelector((state) => state.auth);

  const minTransferableAmount = 1;

  const FetchBalances = useCallback(() => {
    if (!parentBusinessId) return;

    const onSuccess = () => {};
    const onFailure = () => {};

    dispatch(fetchBalance({ onSuccess, onFailure }));
  }, [dispatch]);

  useEffect(() => {
    FetchBalances();
  }, [FetchBalances]);

  const AccountLookUp = useCallback(() => {
    if (nombaEmail === '' || amount < minTransferableAmount || !amount) {
      setName('');
      return;
    }
    // new lookup endpoint
    dispatch(
      nombaAccountLookUp({
        userId: fullUser?.connectedBusinesses[0].coreUserId,
        accountNumber: nombaEmail,
        amount: `${amount}`,
        type: 'p2p',
        onFailure: (result) => {
          if (result.data.data === null) {
            setInputError(result.data.message);
            setLookupError('');
            setName('');
            setBtnDisabled(true);
            return;
          }
          if (result.data.data.kycLimitExceeded || result.data.data.insufficientFunds) {
            setInputError(result.data.message);
            setLookupError('');
            setName('');
            setBtnDisabled(true);
          } else {
            setLookupError(result.data.message);
            setName('');
            setBtnDisabled(true);
          }
        },
        onSuccess: (result: KycAccountLookUpResponse) => {
          setInputError('');
          setLookupError('');
          setFee(result.data.transactionCharge);
          setName(result.data.accountName);
          setBtnDisabled(false);
        },
      })
    );
  }, [dispatch, amount, nombaEmail]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      AccountLookUp();
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [AccountLookUp]);

  function handleTransfer() {
    const datestring = `${date} ${timeVal}`;
    const dateObj = new Date(datestring);
    const userdate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    const usertime = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
    const finalDate = `${userdate} ${usertime}`;

    const data = {
      email: nombaEmail,
      beneficiaryName: name,
      amount,
      frequency,
      transferType: schedule,
      scheduledDate: schedule === 'Immediately' ? null : finalDate,
      repeatPayment: repeat,
      userId: '1234',
      userPin: '1234',
    };

    setPayload(data);
  }

  const goToKycLevel = () => {
    dispatch(setOnboardingStage('upgrade-kyc-level'));
    navigate('/auth/sign-up-new');
  };

  return (
    <>
      <Modal
        showModal={showTransferToNombaModal}
        onClose={() => {
          setAmount(0);
          setNombaEmail('');
          setName('');
          setShowPopup(false);
          setShowTransferToNombaModal(false);
        }}
      >
        <Text
          textAlign="center"
          color="#121212"
          fontSize="24px"
          fontWeight="bold"
          mb="10px"
          style={{ paddingTop: '20px' }}
        >
          Transfer to Nomba Account
        </Text>
        <Text textAlign="center" color="#717171" fontSize="14px" fontWeight="regular" mb="10px">
          Make a transfer to another Nomba acount
        </Text>

        <FullBox bg="#FAFAFA" p="15px">
          <Text textAlign="center" color="#8C8C8C" fontSize="12px">
            {isLoading ? 'Fetching balance...' : `Wallet balance: ${formatCurrency(availableBalance)}`}
          </Text>
        </FullBox>

        <FullBox mt="10px" p="10px">
          <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
            <FullBox width="100%" mb="20px">
              <Text fontSize="12px" color="#444444" mb="4px" style={{ width: '100%' }}>
                Amount
              </Text>
              <CurrencyInput
                id="nomba-transfer-amount"
                name="input-amount"
                placeholder={!amount ? `${formatCurrency(0)}` : `${formatCurrency(amount)}`}
                prefix="₦"
                decimalsLimit={2}
                onValueChange={(value) => {
                  if (Number(value) < 1) {
                    setInputError('Minimum amount to transfer is ₦1');
                  } else {
                    setInputError('');
                    setAmount(Number(value));
                  }
                }}
                width="100%"
                style={{
                  width: '100%',
                  height: '45px',
                  border: '1px solid #EFEFEF',
                  borderRadius: '4px',
                  outline: 'none',
                  fontSize: '14px',
                  padding: '0 12px',
                }}
              />
              <FullBox
                onClick={() => {
                  return false;
                }}
              >
                {inputError !== '' && (
                  <Text id="input-amount-error" textAlign="left" color="#FF2200" fontSize="12px">
                    {inputError}{' '}
                    {inputError.toLowerCase().includes('kyc') && (
                      <p>
                        Please{' '}
                        <u style={{ color: 'blue', cursor: 'pointer' }} onClick={() => goToKycLevel()}>
                          click here
                        </u>{' '}
                        to upgrade
                      </p>
                    )}
                  </Text>
                )}
              </FullBox>
            </FullBox>

            <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
              <FullBox style={{ width: '100%', marginBottom: '20px' }}>
                <TextInput
                  id="nomba-user-email"
                  label="Phone number or email address"
                  placeholder="Enter phone number or email address"
                  name="nomba_email"
                  value={nombaEmail}
                  width="100%"
                  onChange={(e) => {
                    setLookupError('');
                    setName('');
                    setNombaEmail(e.target.value);
                  }}
                  style={{ border: lookupError !== '' ? '1px solid red' : '' }}
                  error={lookupError}
                />
              </FullBox>
            </Formik>

            <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
              <TextInput
                id="nomba-beneficiary-name"
                label="Beneficiary name"
                placeholder=""
                name="name"
                value={inProgress ? 'Please wait...' : name}
                readOnly
                onChange={() => {}}
                width="100%"
                style={{ marginBottom: '20px' }}
              />
            </Formik>
            <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
              <TextInput
                id="nomba-beneficiary-schedule"
                label=""
                placeholder=""
                name="schedule"
                value={schedule}
                disabled
                readOnly
                onChange={(e) => {
                  setSchedule(e.target.value);
                }}
                width="100%"
                style={{ marginBottom: '20px' }}
              />
            </Formik>

            {/* <FullBox width="100%" mb="20px">
              <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                <Select
                  disabled
                  name="schedule"
                  // label="When do you want to send it?"
                  placeholder=""
                  options={[
                    { text: 'Immediately', value: 'Immediately' },
                    // { text: 'Scheduled', value: 'Scheduled' },
                  ]}
                  width="100%"
                  value={schedule}
                  onChange={(e) => {
                    setSchedule(e.target.value);
                  }}
                />
              </Formik>
            </FullBox> */}
            {schedule === 'Scheduled' && (
              <FullBox mb="20px" width="100%">
                <FullFlex justifyContent="space-between">
                  <FullBox id="nomba-transfer-time" display="flex" flexDirection="column" width="50%" mr="5%">
                    <TimePicker
                      selectedTime={timeVal}
                      setTimeVal={setTimeVal}
                      showTimes={showTimes}
                      setShowTimes={setShowTimes}
                    />
                  </FullBox>

                  <CalendarFilter
                    name="date"
                    label="Date"
                    placeholder="Select date"
                    value={date}
                    onChange={(value) => {
                      setDate(value);
                    }}
                    dateFormat="MM/DD/yyyy"
                    width="50%"
                  />
                </FullFlex>
              </FullBox>
            )}

            <FullBox width="100%" mt="20px">
              <FullFlex alignItems="center" mb="10px">
                <NewInfoIcon fontSize="12px" />
                <Text color="#121212" fontSize="12px" ml="10px">
                  You will be charged {fee ? formatCurrency(fee) : formatCurrency(0)} for this transaction
                </Text>
              </FullFlex>

              {amount < minTransferableAmount ||
              Number.isNaN(amount) ||
              inputError !== '' ||
              nombaEmail === '' ||
              name === '' ||
              btnDisabled ? (
                <Button
                  id="nomba-transfer-next-step-disabled"
                  width="100%"
                  variant="xlarge"
                  style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                  disabled
                >
                  Next
                </Button>
              ) : (
                <Button
                  id="nomba-transfer-next-step"
                  width="100%"
                  variant="xlarge"
                  style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                  onClick={() => {
                    handleTransfer();
                    setShowPopup(false);
                    setShowConfirmModal(!showConfirmModal);
                    setShowTransferToNombaModal(false);
                  }}
                >
                  Next
                </Button>
              )}
            </FullBox>
          </FullFlex>
        </FullBox>
      </Modal>
      {transferPayload !== null && (
        <ConfirmTransactionModal
          transferPayload={transferPayload}
          showConfirmModal={showConfirmModal}
          setShowConfirmModal={setShowConfirmModal}
          setAmount={setAmount}
          setNombaEmail={setNombaEmail}
          setName={setName}
          setShowPopup={setShowPopup}
          setShowTransferToNombaModal={setShowTransferToNombaModal}
        />
      )}
    </>
  );
}
