import { Formik } from 'formik';
import Onboardingv3 from '../index';
import FormHeader from './form-header';
import OnboardingStepCounter from './step-counter';
import { ChooseAccountTypeWrapper, FormBodyWrapper } from './styles';
import TextInputV2 from '../../../../components/inputs';
import useCreateAccount from '../models/useCreateAccount';
import useIsMobile from '../../../../hooks/useIsMobile';
import { Button } from 'kudi-component-library';
import VerifyPhoneNumber from './modals/verify-phone';
import VerifyEmail from './modals/verify-email';
import { useNavigate } from 'react-router-dom';

const CreateAnAccount = () => {
  const {
    InitialFormValues,
    validationSchema,
    verifyStep,
    showModal,
    setShowModal,
    accountType,
    setVerifyStep,
    setVerificationInfo,
    verificationInfo,
  } = useCreateAccount();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  return (
    <>
      <Onboardingv3>
        <ChooseAccountTypeWrapper>
          <OnboardingStepCounter step={1} />
          <FormHeader
            title="Create an account"
            description={
              <>
                You are about to open an <span>{accountType} business account</span>. {!isMobile && <br />}
                Now, enter your personal information to get started.
              </>
            }
          />

          <Formik
            initialValues={InitialFormValues}
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              // eslint-disable-next-line no-console
              console.log(values);
              setSubmitting(true);
              setShowModal(true);
            }}
          >
            {({ values, handleChange, handleSubmit, touched, errors, isValid, isSubmitting, handleBlur }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormBodyWrapper>
                    <TextInputV2
                      label="First Name"
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && errors.firstName}
                    />
                    <TextInputV2
                      label="Last name"
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && errors.lastName}
                    />
                    <TextInputV2
                      label="Phone number"
                      type="number"
                      isPhone
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setVerificationInfo({ ...verificationInfo, phoneNumber: e.target.value });
                      }}
                      error={touched.phoneNumber && errors.phoneNumber}
                    />
                    <TextInputV2
                      label="Email address"
                      type="email"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setVerificationInfo({ ...verificationInfo, email: e.target.value });
                      }}
                      error={touched.email && errors.email}
                    />
                  </FormBodyWrapper>
                  <Button type="submit" disabled={!isValid || isSubmitting} variant="medium" width="100%">
                    Continue
                  </Button>
                </form>
              );
            }}
          </Formik>
        </ChooseAccountTypeWrapper>
      </Onboardingv3>
      {verifyStep === 'phone' && (
        <VerifyPhoneNumber
          showModal={showModal}
          onClose={() => setShowModal(false)}
          onSuccess={() => setVerifyStep('email')}
          phoneNumber={verificationInfo.phoneNumber}
        />
      )}
      {verifyStep === 'email' && (
        <VerifyEmail
          showModal={showModal}
          onClose={() => setShowModal(false)}
          email={verificationInfo.email}
          onSuccess={() => {
            navigate(`/auth/onboardingv3/${accountType}/create-password`);
          }}
        />
      )}
    </>
  );
};

export default CreateAnAccount;
