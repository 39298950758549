import mixpanel from 'mixpanel-browser';
import appConfig from '../utils/config';

mixpanel.init(appConfig.mixpanelToken);

const tokenAvailable = !!appConfig.mixpanelToken;

const actions = {
  identify: () => {
    if (tokenAvailable) mixpanel.identify();
  },
  alias: (id: string) => {
    if (tokenAvailable) mixpanel.alias(id);
  },
  track: (name: string, object?: Record<string, string | number | boolean>) => {
    if (tokenAvailable) mixpanel.track(name, object);
  },
  people: {
    set: (props: Record<string, string | number | boolean | null>) => {
      if (tokenAvailable) mixpanel.people?.set(props);
    },
  },
};

export const Mixpanel = actions;
