import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { ReactComponent as LogoMobile } from '../../assets/logo_mobile.svg';
import {
  BackAction,
  HeaderDesktop,
  HeaderDesktopBackWrapper,
  HeaderDesktopClose,
  HeaderDesktopExtra,
  HeaderDesktopMain,
  HeaderWrapper,
} from '../../styles';
import { Separator } from '../../../overview/styles';

const Header = ({
  mobileTitle,
  desktopTitle,
  mobileBackAction,
  desktopDescription,
  desktopCloseAction,
  desktopBackAction,
  desktopExtaView,
}: {
  mobileTitle?: string;
  mobileBackAction: () => void;
  desktopTitle: string;
  desktopDescription?: string;
  desktopCloseAction?: () => void;
  desktopBackAction?: () => void;
  desktopExtaView?: JSX.Element;
}) => (
  <>
    <HeaderWrapper>
      <BackAction onClick={mobileBackAction}>
        <NIcons icon={IconsEnum.OUTLINE_CHEVRON_LEFT} />
      </BackAction>
      {mobileTitle ? <h4>{mobileTitle}</h4> : <LogoMobile />}
    </HeaderWrapper>
    <HeaderDesktop>
      {desktopExtaView || desktopBackAction ? (
        <HeaderDesktopExtra>
          <HeaderDesktopBackWrapper onClick={desktopBackAction}>
            <NIcons icon={IconsEnum.OUTLINE_CHEVRON_LEFT} />
            <Typography>Back</Typography>
          </HeaderDesktopBackWrapper>
          {desktopExtaView}
        </HeaderDesktopExtra>
      ) : null}
      <HeaderDesktopMain>
        <div>
          <Typography fontSize="24px" lineHeight="32px" fontWeight={700}>
            {desktopTitle}
          </Typography>
          {desktopDescription ? (
            <>
              <Separator height="10px" />
              <Typography>{desktopDescription}</Typography>
            </>
          ) : null}
        </div>
        {desktopCloseAction ? (
          <HeaderDesktopClose onClick={desktopCloseAction}>
            <NIcons icon={IconsEnum.OUTLINE_X} />
          </HeaderDesktopClose>
        ) : null}
      </HeaderDesktopMain>
    </HeaderDesktop>
  </>
);

export default Header;
