import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Referral = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M8.158 3.737a1.474 1.474 0 0 0 0 2.947h1.474V5.211c0-.814-.66-1.474-1.474-1.474Zm2.007.546A2.211 2.211 0 1 0 6.51 6.684H4.842a1.842 1.842 0 0 0-.368 3.648v4.826c0 1.017.824 1.842 1.842 1.842h7.368a1.842 1.842 0 0 0 1.842-1.842v-4.826a1.843 1.843 0 0 0-.368-3.648h-2.21a1.842 1.842 0 0 0-2.783-2.402Zm.203 1.296v1.105h1.106a1.105 1.105 0 1 0-1.106-1.105ZM9.632 7.42h-4.79a1.105 1.105 0 0 0 0 2.21h4.79v-2.21Zm0 2.947H5.21v4.79c0 .61.494 1.105 1.105 1.105h3.316v-5.895Zm.736 5.895v-5.895h4.421v4.79c0 .61-.494 1.105-1.105 1.105h-3.316Zm0-6.631v-2.21h4.79a1.105 1.105 0 0 1 0 2.21h-4.79Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Referral;
