import React, { useState, createRef, Dispatch, useEffect, ChangeEvent } from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import { Checkbox } from 'kudi-component-library';
import SearchInput from '../../components/SearchInput';
import {
  FilterItemWrapper,
  FilterItemMain,
  FilterDropDown,
  FilterDropDownItem,
  FilterHiddenInput,
  CalendarFilterWrapper,
} from './styles';
import { ChevronDown, FilterIcon } from '../../assets/svg';

const FilterItem = ({
  query,
  name,
  type,
  options,
  defaultValue = '',
  onChange,
  itemKey,
  onRemove,
  dateFilterType,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  disableDatesAfterToday,
  required,
  onChangeCustomField,
  inputPlaceholder,
  length,
}: {
  name: string;
  type: 'select' | 'date' | 'custom' | 'searchInput';
  options: { text: string; value: string; checked?: boolean }[];
  query: string;
  itemKey?: string | number;
  defaultValue?: string | Date;
  inputPlaceholder?: string;
  onChange: (value: { query: string; value: string | Date }) => void;
  onRemove: (value: string) => void;
  dateFilterType?: 'from' | 'to';
  dateFrom?: Date;
  setDateFrom?: Dispatch<React.SetStateAction<Date>>;
  dateTo?: Date;
  setDateTo?: Dispatch<React.SetStateAction<Date>>;
  disableDatesAfterToday?: boolean;
  required?: boolean;
  length?: number;
  onChangeCustomField?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(type === 'select' ? (defaultValue as string) : '');
  const [showCustomFields, setShowCustomFields] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const inputRef = createRef<HTMLButtonElement>();

  // eslint-disable-next-line no-undef
  let closeDropdownTimeout: NodeJS.Timeout;

  const onDropDownBlur = () => {
    closeDropdownTimeout = setTimeout(() => {
      setShow(false);
    }, 300);
  };

  const onDropDownFocus = () => {
    setShow(true);
    setShowCustomFields(true);
  };

  const handleSearchValue = (value: string) => {
    setInputValue(value);

    onChange({ query, value });
  };

  const handleClick = () => {
    if (show) {
      clearTimeout(closeDropdownTimeout);

      setShow(false);
    } else {
      inputRef.current?.focus();
    }

    setShow(true);
  };

  const handleSelect = (value: string) => {
    setSelected(value);
    setShow(false);
    onChange({ query, value });
  };

  const toggleShowCustomFields = () => setShowCustomFields((showFields) => !showFields);

  const date = dateFilterType === 'from' ? dateFrom ?? new Date() : dateTo ?? new Date();

  const setDate = (value: Date) => {
    if (dateFilterType === 'from') {
      setDateFrom?.(value);
    }
    if (dateFilterType === 'to') {
      setDateTo?.(value);
    }
    onChange({ query, value });
  };

  useEffect(() => {
    if (dateFilterType && dateFilterType === 'from' && defaultValue) {
      setDateFrom?.(moment(defaultValue).toDate());
    }

    if (dateFilterType && dateFilterType === 'to' && defaultValue) {
      setDateTo?.(moment(defaultValue).toDate());
    }
  }, []);

  const showValue = options?.find((item) => item.value === selected)?.text;

  const removeFilter = () => {
    setSelected('');
    onRemove(query);
  };

  return (
    <FilterItemWrapper>
      {type === 'custom' ? (
        <FilterItemMain onClick={toggleShowCustomFields} show={showCustomFields}>
          <span>
            <FilterIcon />
          </span>
          <div>Custom fields</div>
          <ChevronDown />
        </FilterItemMain>
      ) : type === 'searchInput' ? null : (
        <FilterItemMain onClick={handleClick} show={show}>
          <span>{name}</span>
          {type === 'select' ? <div>{showValue ?? 'Select'}</div> : null}
          {type === 'date' && dateFilterType === 'from' ? (
            <div>{moment(dateFrom || new Date()).format('DD / MM / YYYY')}</div>
          ) : null}
          {type === 'date' && dateFilterType === 'to' ? (
            <div>{moment(dateTo || new Date()).format('DD / MM / YYYY')}</div>
          ) : null}
          {}
          <ChevronDown />
        </FilterItemMain>
      )}

      {type === 'custom' && (
        <FilterDropDown onFocus={onDropDownFocus} hide={!showCustomFields}>
          {options.map((item) => (
            <FilterDropDownItem>
              <span>
                <Checkbox name={item.text} onChange={onChangeCustomField} checked={item.checked} value={item.value}>
                  {null}
                </Checkbox>
              </span>{' '}
              <span> {item.text}</span>
            </FilterDropDownItem>
          ))}
        </FilterDropDown>
      )}

      {type === 'select' ? (
        <FilterDropDown onFocus={onDropDownFocus} onBlur={onDropDownBlur} hide={!show}>
          {/* {showValue && !required ? <FilterDropDownItem onClick={removeFilter}>All</FilterDropDownItem> : null} */}
          {options?.map((item) => (
            <FilterDropDownItem onClick={() => handleSelect(item.value)} active={selected === item.value}>
              {item.text}
            </FilterDropDownItem>
          ))}
          <FilterHiddenInput ref={inputRef} />
        </FilterDropDown>
      ) : null}

      {type === 'date' ? <FilterHiddenInput onFocus={onDropDownFocus} ref={inputRef} /> : null}

      {show && type === 'date' ? (
        <CalendarFilterWrapper>
          <DatePicker
            onChange={(e: Date) => setDate(e)}
            value={date}
            format="YYYY-MM-DD"
            isOpen={show}
            onCalendarOpen={() => setShow(true)}
            onCalendarClose={() => setShow(false)}
            minDate={dateFilterType === 'to' ? dateFrom : undefined}
            maxDate={
              disableDatesAfterToday ? moment().endOf('day').toDate() : dateFilterType === 'from' ? dateTo : undefined
            }
          />
        </CalendarFilterWrapper>
      ) : null}

      {type === 'searchInput' ? (
        <SearchInput
          id="search-terminal"
          onSearch={(val) => handleSearchValue(val)}
          placeholder={inputPlaceholder!}
          type="text"
          length={length || 64}
          query={query}
          onRemove={onRemove}
        />
      ) : null}
    </FilterItemWrapper>
  );
};

export default FilterItem;
