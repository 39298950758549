import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import PageLoad from '../../../components/PageLoad';
import { setCallbackUrlCode, setOnboardingStage } from '../../../features/auth/slice';

const CallbackUrlRedirect = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resolveParams = useCallback(() => {
    const localParamsCopy = [...search];
    delete localParamsCopy[0];

    const split = localParamsCopy
      .join('')
      .split('&')
      .map((item) => {
        const objectSplit = item.split('=');
        return { [objectSplit[0]]: objectSplit[1] };
      });

    const codeObject = split.find((item) => !!item.code);

    return codeObject;
  }, []);

  useEffect(() => {
    const codeObject = resolveParams();

    dispatch(setCallbackUrlCode(codeObject?.code || ''));
    dispatch(setOnboardingStage('your-identity'));
    navigate('/auth/sign-up-new');
  }, []);

  return <PageLoad />;
};

export default CallbackUrlRedirect;
