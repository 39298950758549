/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import { ReduxAction, ApiResponse } from '../../types/redux';
import {
  ProviderProfile,
  ProviderConfig,
  Summary,
  InterestConfig,
  WalletTransaction,
  LoanHistory,
  WithdrawalItem,
  InterestRate,
} from '../../types/loanProvider';

// ---------- MOCKS ----------//

// ---------------------------   CHECK LOAN PROVIDER START   ---------------------------//

export const checkLoanProvider = createAsyncThunk(
  '/loanProvider/check',
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      await axios.get('kshock/loan-provider/check');
      localStorage.setItem('isLoanProvider', 'true');
      window.location.reload();
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   CHECK LOAN PROVIDER END   ---------------------------//

// ---------------------------   FETCH PROVIDER PROFILE START   ---------------------------//

export interface FetchProviderProfileResponse extends ApiResponse {
  data: ProviderProfile;
}

export const fetchProviderProfile = createAsyncThunk(
  '/loanProvider/fetch-profile',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const response: FetchProviderProfileResponse = await axios.get('kshock/loan-provider/profile');
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH PROVIDER PROFILE END   ---------------------------//

// ---------------------------   FETCH PROVIDER CONFIG START   ---------------------------//

export interface FetchProviderConfigResponse extends ApiResponse {
  data: ProviderConfig;
}

export const fetchProviderConfig = createAsyncThunk(
  '/loanProvider/fetch-config',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const response: FetchProviderConfigResponse = await axios.get('kshock/loan-provider/config');
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH PROVIDER CONFIG END   ---------------------------//

// ---------------------------   FETCH SUMMARY START   ---------------------------//
interface FetchSummaryArgs extends ReduxAction {
  coreUserId?: string | null;
}

export interface FetchSummaryResponse extends ApiResponse {
  data: Summary;
}

export const fetchSummary = createAsyncThunk(
  '/loanProvider/fetch-summary',
  async ({ coreUserId, onSuccess, onFailure }: FetchSummaryArgs, { rejectWithValue }) => {
    try {
      const response: FetchSummaryResponse = await axios.get(`kshock/loan-provider/summary`);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);
// ---------------------------   FETCH SUMMARY END   ---------------------------//

// ---------------------------   FETCH INTEREST CONFIG START   ---------------------------//

export interface FetchInterestConfigResponse extends ApiResponse {
  data: InterestConfig[];
}

export const fetchInterestConfig = createAsyncThunk(
  '/loanProvider/fetch-interest-config',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const response: FetchInterestConfigResponse = await axios.get('kshock/loan-provider/interest-config');
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH PROVIDER PROFILE END   ---------------------------//

// ---------------------------   FETCH WALLET BALANCE START   ---------------------------//

interface FetchWalletBalanceArgs extends ReduxAction {
  walletId: string;
}

export interface FetchWalletBalanceResponse extends ApiResponse {
  data: {
    walletBalance: number;
  };
}

export const fetchWalletBalance = createAsyncThunk(
  '/loanProvider/fetch-wallet-balance',
  async ({ walletId, onSuccess, onFailure }: FetchWalletBalanceArgs, { rejectWithValue }) => {
    try {
      const response: FetchWalletBalanceResponse = await axios.get(`kshock/loan-provider/wallet-balance/${walletId}`);

      if (onSuccess) onSuccess(response.data);
      return response.data.walletBalance;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH WALLET BALANCE END   ---------------------------//

// ---------------------------   FETCH WALLET TRANSACTIONS START   ---------------------------//

interface FetchWalletTransactionsArgs extends ReduxAction {
  from: string;
  to: string;
  type: string;
  productType: string;
  page: number;
  pageSize: number;
  walletId?: string | null;
  transactionRef?: string;
}

export interface FetchWalletTransactionsResponse extends ApiResponse {
  data: WalletTransaction[];
}

export const fetchWalletTransactions = createAsyncThunk(
  '/loanProvider/fetch-wallet-transactions',
  async (
    {
      from,
      to,
      type,
      page,
      pageSize,
      productType,
      walletId,
      transactionRef,
      onSuccess,
      onFailure,
    }: FetchWalletTransactionsArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: FetchWalletTransactionsResponse = await axios.get(
        `kshock/loan-provider/wallet-transactions/${walletId}?from=${from}&to=${to}&productType=${productType}&type=${type}&page=${page}&size=${pageSize}&transactionRef=${transactionRef}`
      );

      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH WALLET TRANSACTIONS END   ---------------------------//

// ---------------------------   FETCH LOAN HIISTORY START   ---------------------------//

interface FetchHistoryArgs extends ReduxAction {
  from: string;
  to: string;
  type: string;
  status: string;
  page: number;
  pageSize: number;
  phoneNumber: string;
}

export interface FetchLoanHistoryResponse extends ApiResponse {
  data: LoanHistory[];
  meta: {
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalRows: number;
  };
}

export const fetchLoanHistory = createAsyncThunk(
  '/loanProvider/fetch-loan-history',
  async (
    { from, to, page, pageSize, status, phoneNumber, onSuccess, onFailure }: FetchHistoryArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: FetchLoanHistoryResponse = await axios.get(
        `/kshock/loan/provider/history?status=${status}&from=${from}&to=${to}&pageSize=${pageSize}&page=${page}&phoneNumber=${phoneNumber}`
      );

      if (onSuccess) onSuccess(response.data);
      return {
        data: response.data,
        meta: response.meta,
      };
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH LOAN HISTORY END   ---------------------------//

// ---------------------------   UPDATE PROVIDER CONFIG START   ---------------------------//

interface UpdateProviderConfigArgs extends ReduxAction {
  balance: number;
}

export const updateProviderConfig = createAsyncThunk(
  '/loanProvider/update-config',
  async ({ balance, onSuccess, onFailure }: UpdateProviderConfigArgs, { rejectWithValue }) => {
    try {
      const response: FetchProviderConfigResponse = await axios.put('kshock/loan-provider/config', {
        channel: 'EMAIL',
        thresholdBalance: balance,
        contactEmail: '',
      });
      if (onSuccess) onSuccess(response.message);
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   UPDATE PROVIDER CONFIG END   ---------------------------//

// ---------------------------   WITHDRAW FROM WALLET START   ---------------------------//

interface WithdrawalArgs extends ReduxAction {
  values: { walletType: string; recipientWalletType: string; amount: number };
}

export interface WithdrawalResponse extends ApiResponse {
  data: WithdrawalItem;
}

export const withdrawFromWallet = createAsyncThunk(
  '/loanProvider/withdrawal',
  async ({ values, onSuccess, onFailure }: WithdrawalArgs, { rejectWithValue }) => {
    try {
      const { data }: WithdrawalResponse = await axios.post(`/kshock/loan-provider/withdraw-from-wallet`, values);
      if (onSuccess) onSuccess(data);
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   WITHDRAW FROM WALLET END   ---------------------------//

// ---------------------------   FUND WALLET START   ---------------------------//

interface FundWalletArgs extends ReduxAction {
  amount: number;
}

export const fundWallet = createAsyncThunk(
  '/loanProvider/fund',
  async ({ amount, onSuccess, onFailure }: FundWalletArgs, { rejectWithValue }) => {
    try {
      const { data }: WithdrawalResponse = await axios.post(`/kshock/loan-provider/topup-loan-wallet`, { amount });
      if (onSuccess) onSuccess(data);
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   FUNDM WALLET END   ---------------------------//

// ---------------------------   FETCH INTEREST RATES  START ---------------------------//

export interface FetchInterestRatesResponse extends ApiResponse {
  data: InterestRate[];
}

export const fetchInterestRates = createAsyncThunk(
  '/loanProvider/fetch-interest-rates',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const response: FetchInterestRatesResponse = await axios.get('kshock/loan-provider/interests');
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH INTEREST RATES  END ---------------------------//

// ---------------------------   FETCH  CSV DATA  START ---------------------------//

interface ExportValues extends ReduxAction {
  dataEndpoint: string;
}

export const exportData = createAsyncThunk(
  '/loan-provider/export.csv',
  async ({ dataEndpoint, onSuccess, onFailure }: ExportValues, { rejectWithValue }) => {
    try {
      const response: FetchInterestRatesResponse = await axios.get(`kshock/${dataEndpoint}`);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH CSV DATA  END ---------------------------//
