/* tslint:disable */
/* eslint-disable */
/**
 * Business Banking API Spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ArchiveNotificationsResponse
 */
export interface ArchiveNotificationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveNotificationsResponse
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArchiveNotificationsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArchiveNotificationsResponse
     */
    'meta'?: string;
    /**
     * 
     * @type {string}
     * @memberof ArchiveNotificationsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {NotificationRespBody}
     * @memberof ArchiveNotificationsResponse
     */
    'data'?: NotificationRespBody;
}
/**
 * 
 * @export
 * @interface BusinessMigrationData
 */
export interface BusinessMigrationData {
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationData
     */
    'emailVerificationId': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationData
     */
    'phoneVerificationId': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationData
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface BusinessMigrationRequest
 */
export interface BusinessMigrationRequest {
    /**
     * 
     * @type {BusinessMigrationData}
     * @memberof BusinessMigrationRequest
     */
    'businessMigrationData': BusinessMigrationData;
    /**
     * 
     * @type {Device}
     * @memberof BusinessMigrationRequest
     */
    'device'?: Device;
}
/**
 * 
 * @export
 * @interface BusinessMigrationRequirementRequest
 */
export interface BusinessMigrationRequirementRequest {
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationRequirementRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationRequirementRequest
     */
    'pin'?: string;
}
/**
 * 
 * @export
 * @interface BusinessMigrationRequirementResponse
 */
export interface BusinessMigrationRequirementResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BusinessMigrationRequirementResponse
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationRequirementResponse
     */
    'message': string;
    /**
     * 
     * @type {BusinessMigrationRequirementResponseData}
     * @memberof BusinessMigrationRequirementResponse
     */
    'data': BusinessMigrationRequirementResponseData;
}
/**
 * 
 * @export
 * @interface BusinessMigrationRequirementResponseData
 */
export interface BusinessMigrationRequirementResponseData {
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessMigrationRequirementResponseData
     */
    'requirements'?: Array<BusinessMigrationRequirementResponseDataRequirementsEnum>;
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationRequirementResponseData
     */
    'currentEmail'?: string;
}

export const BusinessMigrationRequirementResponseDataRequirementsEnum = {
    Password: 'PASSWORD',
    Email: 'EMAIL',
    Bvn: 'BVN'
} as const;

export type BusinessMigrationRequirementResponseDataRequirementsEnum = typeof BusinessMigrationRequirementResponseDataRequirementsEnum[keyof typeof BusinessMigrationRequirementResponseDataRequirementsEnum];

/**
 * 
 * @export
 * @interface BusinessMigrationResponse
 */
export interface BusinessMigrationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BusinessMigrationResponse
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof BusinessMigrationResponse
     */
    'message': string;
    /**
     * 
     * @type {UserPrincipal}
     * @memberof BusinessMigrationResponse
     */
    'data': UserPrincipal;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Channel = {
    Android: 'ANDROID',
    Ios: 'IOS',
    Web: 'WEB',
    Watchtower: 'WATCHTOWER'
} as const;

export type Channel = typeof Channel[keyof typeof Channel];


/**
 * 
 * @export
 * @interface CreateNotificationsResponse
 */
export interface CreateNotificationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateNotificationsResponse
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationsResponse
     */
    'meta'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {NotificationRespBody}
     * @memberof CreateNotificationsResponse
     */
    'data'?: NotificationRespBody;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'accessType'?: string;
    /**
     * 
     * @type {Channel}
     * @memberof Device
     */
    'channel'?: Channel;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'os'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'deviceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'browser'?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'ipAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'ipCountry': string;
}


/**
 * Requests the backend to send OTP to the user\'s email address. Either UserId or Phone must be provided.
 * @export
 * @interface EmailOTPDispatchRequest
 */
export interface EmailOTPDispatchRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailOTPDispatchRequest
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmailOTPDispatchRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {OtpVerificationPurpose}
     * @memberof EmailOTPDispatchRequest
     */
    'purpose': OtpVerificationPurpose;
}


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ErrorResponse
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
    /**
     * 
     * @type {object}
     * @memberof ErrorResponse
     */
    'error': object;
}
/**
 * 
 * @export
 * @interface GetAllNotificationsResponse
 */
export interface GetAllNotificationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAllNotificationsResponse
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAllNotificationsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllNotificationsResponse
     */
    'meta'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllNotificationsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {NotificationRespBodyContent}
     * @memberof GetAllNotificationsResponse
     */
    'data'?: NotificationRespBodyContent;
}
/**
 * 
 * @export
 * @interface JwtToken
 */
export interface JwtToken {
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtToken
     */
    'expiresAt'?: string;
}
/**
 * 
 * @export
 * @interface NotificationCreationRequest
 */
export interface NotificationCreationRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationCreationRequest
     */
    'userIdOrCoreuserId': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreationRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreationRequest
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreationRequest
     */
    'notificationType'?: NotificationCreationRequestNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreationRequest
     */
    'notificationCategory': NotificationCreationRequestNotificationCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreationRequest
     */
    'followUpActionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreationRequest
     */
    'followUpActionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCreationRequest
     */
    'imageLinks'?: string;
}

export const NotificationCreationRequestNotificationTypeEnum = {
    Ticket: 'TICKET',
    WalletCreditedTopUp: 'WALLET_CREDITED_TOP_UP',
    Notification: 'NOTIFICATION',
    OutletLinking: 'OUTLET_LINKING',
    OutletLinkAccepted: 'OUTLET_LINK_ACCEPTED',
    KycUpgrade: 'KYC_UPGRADE',
    MonthlyAccountStatement: 'MONTHLY_ACCOUNT_STATEMENT',
    AgentAccountPending: 'AGENT_ACCOUNT_PENDING',
    TerminalDelivered: 'TERMINAL_DELIVERED',
    PosRequest: 'POS_REQUEST',
    RegistrationFailed: 'REGISTRATION_FAILED'
} as const;

export type NotificationCreationRequestNotificationTypeEnum = typeof NotificationCreationRequestNotificationTypeEnum[keyof typeof NotificationCreationRequestNotificationTypeEnum];
export const NotificationCreationRequestNotificationCategoryEnum = {
    System: 'SYSTEM',
    Campaign: 'CAMPAIGN',
    Announcement: 'ANNOUNCEMENT'
} as const;

export type NotificationCreationRequestNotificationCategoryEnum = typeof NotificationCreationRequestNotificationCategoryEnum[keyof typeof NotificationCreationRequestNotificationCategoryEnum];

/**
 * 
 * @export
 * @interface NotificationRespBody
 */
export interface NotificationRespBody {
    /**
     * 
     * @type {number}
     * @memberof NotificationRespBody
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'followUpActionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'followUpActionValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationRespBody
     */
    'imageLinks'?: string;
}
/**
 * 
 * @export
 * @interface NotificationRespBodyContent
 */
export interface NotificationRespBodyContent {
    /**
     * 
     * @type {number}
     * @memberof NotificationRespBodyContent
     */
    'currentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationRespBodyContent
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationRespBodyContent
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationRespBodyContent
     */
    'totalRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationRespBodyContent
     */
    'totalElements'?: number;
    /**
     * 
     * @type {PageMeta}
     * @memberof NotificationRespBodyContent
     */
    'pageMeta'?: PageMeta;
    /**
     * 
     * @type {Array<NotificationRespBody>}
     * @memberof NotificationRespBodyContent
     */
    'content'?: Array<NotificationRespBody>;
}
/**
 * 
 * @export
 * @interface OTPDispatchResponse
 */
export interface OTPDispatchResponse {
    /**
     * 
     * @type {boolean}
     * @memberof OTPDispatchResponse
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof OTPDispatchResponse
     */
    'message': string;
    /**
     * 
     * @type {OTPDispatchResponseData}
     * @memberof OTPDispatchResponse
     */
    'data': OTPDispatchResponseData;
}
/**
 * 
 * @export
 * @interface OTPDispatchResponseData
 */
export interface OTPDispatchResponseData {
    /**
     * 
     * @type {string}
     * @memberof OTPDispatchResponseData
     */
    'verificationId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OtpVerificationPurpose = {
    Login: 'LOGIN',
    Onboarding: 'ONBOARDING',
    PinAuthentication: 'PIN_AUTHENTICATION',
    PasswordChange: 'PASSWORD_CHANGE',
    Migration: 'MIGRATION'
} as const;

export type OtpVerificationPurpose = typeof OtpVerificationPurpose[keyof typeof OtpVerificationPurpose];


/**
 * 
 * @export
 * @interface PageMeta
 */
export interface PageMeta {
    /**
     * 
     * @type {number}
     * @memberof PageMeta
     */
    'currentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMeta
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMeta
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMeta
     */
    'totalRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMeta
     */
    'totalElements'?: number;
}
/**
 * Requests the backend to send OTP to the user\'s phone number via SMS. Either UserId or Phone must be provided.
 * @export
 * @interface PhoneOTPDispatchRequest
 */
export interface PhoneOTPDispatchRequest {
    /**
     * 
     * @type {number}
     * @memberof PhoneOTPDispatchRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhoneOTPDispatchRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {OtpVerificationPurpose}
     * @memberof PhoneOTPDispatchRequest
     */
    'purpose': OtpVerificationPurpose;
}


/**
 * 
 * @export
 * @interface SuccessfulNotificationsResponse
 */
export interface SuccessfulNotificationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessfulNotificationsResponse
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulNotificationsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulNotificationsResponse
     */
    'meta'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessfulNotificationsResponse
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ToggleNotificationsResponse
 */
export interface ToggleNotificationsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ToggleNotificationsResponse
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ToggleNotificationsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ToggleNotificationsResponse
     */
    'meta'?: string;
    /**
     * 
     * @type {string}
     * @memberof ToggleNotificationsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {NotificationRespBody}
     * @memberof ToggleNotificationsResponse
     */
    'data'?: NotificationRespBody;
}
/**
 * 
 * @export
 * @interface TokenVerificationRequest
 */
export interface TokenVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenVerificationRequest
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof TokenVerificationRequest
     */
    'verificationId': string;
    /**
     * 
     * @type {OtpVerificationPurpose}
     * @memberof TokenVerificationRequest
     */
    'purpose': OtpVerificationPurpose;
}


/**
 * 
 * @export
 * @interface UserPrincipal
 */
export interface UserPrincipal {
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPrincipal
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPrincipal
     */
    'parentBusinessId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'deviceUniqueId'?: string;
    /**
     * 
     * @type {JwtToken}
     * @memberof UserPrincipal
     */
    'payload'?: JwtToken;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UserPrincipal
     */
    'iamTokenData'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'role'?: UserPrincipalRoleEnum;
}

export const UserPrincipalRoleEnum = {
    Guest: 'GUEST',
    Admin: 'ADMIN',
    User: 'USER'
} as const;

export type UserPrincipalRoleEnum = typeof UserPrincipalRoleEnum[keyof typeof UserPrincipalRoleEnum];

/**
 * 
 * @export
 * @interface WrappedResponse
 */
export interface WrappedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof WrappedResponse
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof WrappedResponse
     */
    'message': string;
    /**
     * 
     * @type {object}
     * @memberof WrappedResponse
     */
    'data': object;
}

/**
 * BusinessMigrationResourceApi - axios parameter creator
 * @export
 */
export const BusinessMigrationResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BusinessMigrationRequirementRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessMigrationRequirements: async (body: BusinessMigrationRequirementRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getBusinessMigrationRequirements', 'body', body)
            const localVarPath = `/business-migration/requirements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BusinessMigrationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performBusinessMigration: async (body: BusinessMigrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('performBusinessMigration', 'body', body)
            const localVarPath = `/business-migration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessMigrationResourceApi - functional programming interface
 * @export
 */
export const BusinessMigrationResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessMigrationResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BusinessMigrationRequirementRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessMigrationRequirements(body: BusinessMigrationRequirementRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessMigrationRequirementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessMigrationRequirements(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BusinessMigrationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async performBusinessMigration(body: BusinessMigrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessMigrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.performBusinessMigration(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessMigrationResourceApi - factory interface
 * @export
 */
export const BusinessMigrationResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessMigrationResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {BusinessMigrationRequirementRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessMigrationRequirements(body: BusinessMigrationRequirementRequest, options?: any): AxiosPromise<BusinessMigrationRequirementResponse> {
            return localVarFp.getBusinessMigrationRequirements(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BusinessMigrationRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performBusinessMigration(body: BusinessMigrationRequest, options?: any): AxiosPromise<BusinessMigrationResponse> {
            return localVarFp.performBusinessMigration(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessMigrationResourceApi - object-oriented interface
 * @export
 * @class BusinessMigrationResourceApi
 * @extends {BaseAPI}
 */
export class BusinessMigrationResourceApi extends BaseAPI {
    /**
     * 
     * @param {BusinessMigrationRequirementRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessMigrationResourceApi
     */
    public getBusinessMigrationRequirements(body: BusinessMigrationRequirementRequest, options?: AxiosRequestConfig) {
        return BusinessMigrationResourceApiFp(this.configuration).getBusinessMigrationRequirements(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BusinessMigrationRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessMigrationResourceApi
     */
    public performBusinessMigration(body: BusinessMigrationRequest, options?: AxiosRequestConfig) {
        return BusinessMigrationResourceApiFp(this.configuration).performBusinessMigration(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MFAResourceApi - axios parameter creator
 * @export
 */
export const MFAResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PhoneOTPDispatchRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneRequestOTP: async (request?: PhoneOTPDispatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mfa/otp/phone/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailOTPDispatchRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailOTP: async (request?: EmailOTPDispatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mfa/otp/email/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenVerificationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOTP: async (body?: TokenVerificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mfa/otp/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MFAResourceApi - functional programming interface
 * @export
 */
export const MFAResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MFAResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PhoneOTPDispatchRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phoneRequestOTP(request?: PhoneOTPDispatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPDispatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phoneRequestOTP(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailOTPDispatchRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestEmailOTP(request?: EmailOTPDispatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPDispatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestEmailOTP(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TokenVerificationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyOTP(body?: TokenVerificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OTPDispatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyOTP(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MFAResourceApi - factory interface
 * @export
 */
export const MFAResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MFAResourceApiFp(configuration)
    return {
        /**
         * 
         * @param {PhoneOTPDispatchRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneRequestOTP(request?: PhoneOTPDispatchRequest, options?: any): AxiosPromise<OTPDispatchResponse> {
            return localVarFp.phoneRequestOTP(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailOTPDispatchRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestEmailOTP(request?: EmailOTPDispatchRequest, options?: any): AxiosPromise<OTPDispatchResponse> {
            return localVarFp.requestEmailOTP(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenVerificationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOTP(body?: TokenVerificationRequest, options?: any): AxiosPromise<OTPDispatchResponse> {
            return localVarFp.verifyOTP(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MFAResourceApi - object-oriented interface
 * @export
 * @class MFAResourceApi
 * @extends {BaseAPI}
 */
export class MFAResourceApi extends BaseAPI {
    /**
     * 
     * @param {PhoneOTPDispatchRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MFAResourceApi
     */
    public phoneRequestOTP(request?: PhoneOTPDispatchRequest, options?: AxiosRequestConfig) {
        return MFAResourceApiFp(this.configuration).phoneRequestOTP(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailOTPDispatchRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MFAResourceApi
     */
    public requestEmailOTP(request?: EmailOTPDispatchRequest, options?: AxiosRequestConfig) {
        return MFAResourceApiFp(this.configuration).requestEmailOTP(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenVerificationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MFAResourceApi
     */
    public verifyOTP(body?: TokenVerificationRequest, options?: AxiosRequestConfig) {
        return MFAResourceApiFp(this.configuration).verifyOTP(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive a notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNotification: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('archiveNotification', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create new notification
         * @param {NotificationCreationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (body?: NotificationCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all notifications
         * @param {number} page 
         * @param {number} pageSize 
         * @param {GetAllNotificationsStatusEnum} [status] 
         * @param {GetAllNotificationsCategoryEnum} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotifications: async (page: number, pageSize: number, status?: GetAllNotificationsStatusEnum, category?: GetAllNotificationsCategoryEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getAllNotifications', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllNotifications', 'pageSize', pageSize)
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark all notifications as read
         * @param {MarkAllAsReadCategoryEnum} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllAsRead: async (category?: MarkAllAsReadCategoryEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/mark-all-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark many notifications as archived
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markManyAsArchived: async (notificationIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/mark-many-as-archived`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark many notifications as read
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markManyAsRead: async (notificationIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/mark-many-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle notification from read to unread and vice-versa
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleNotification: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('toggleNotification', 'notificationId', notificationId)
            const localVarPath = `/notifications/toggle/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive a notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveNotification(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArchiveNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create new notification
         * @param {NotificationCreationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(body?: NotificationCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotification(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all notifications
         * @param {number} page 
         * @param {number} pageSize 
         * @param {GetAllNotificationsStatusEnum} [status] 
         * @param {GetAllNotificationsCategoryEnum} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNotifications(page: number, pageSize: number, status?: GetAllNotificationsStatusEnum, category?: GetAllNotificationsCategoryEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNotifications(page, pageSize, status, category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark all notifications as read
         * @param {MarkAllAsReadCategoryEnum} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAllAsRead(category?: MarkAllAsReadCategoryEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAllAsRead(category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark many notifications as archived
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markManyAsArchived(notificationIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markManyAsArchived(notificationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark many notifications as read
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markManyAsRead(notificationIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markManyAsRead(notificationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Toggle notification from read to unread and vice-versa
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleNotification(notificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ToggleNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleNotification(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive a notification
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNotification(notificationId: number, options?: any): AxiosPromise<ArchiveNotificationsResponse> {
            return localVarFp.archiveNotification(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create new notification
         * @param {NotificationCreationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(body?: NotificationCreationRequest, options?: any): AxiosPromise<CreateNotificationsResponse> {
            return localVarFp.createNotification(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all notifications
         * @param {number} page 
         * @param {number} pageSize 
         * @param {GetAllNotificationsStatusEnum} [status] 
         * @param {GetAllNotificationsCategoryEnum} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotifications(page: number, pageSize: number, status?: GetAllNotificationsStatusEnum, category?: GetAllNotificationsCategoryEnum, options?: any): AxiosPromise<GetAllNotificationsResponse> {
            return localVarFp.getAllNotifications(page, pageSize, status, category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark all notifications as read
         * @param {MarkAllAsReadCategoryEnum} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllAsRead(category?: MarkAllAsReadCategoryEnum, options?: any): AxiosPromise<SuccessfulNotificationsResponse> {
            return localVarFp.markAllAsRead(category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark many notifications as archived
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markManyAsArchived(notificationIds?: Array<number>, options?: any): AxiosPromise<SuccessfulNotificationsResponse> {
            return localVarFp.markManyAsArchived(notificationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark many notifications as read
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markManyAsRead(notificationIds?: Array<number>, options?: any): AxiosPromise<SuccessfulNotificationsResponse> {
            return localVarFp.markManyAsRead(notificationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle notification from read to unread and vice-versa
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleNotification(notificationId: number, options?: any): AxiosPromise<ToggleNotificationsResponse> {
            return localVarFp.toggleNotification(notificationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Archive a notification
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public archiveNotification(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).archiveNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create new notification
     * @param {NotificationCreationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createNotification(body?: NotificationCreationRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createNotification(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all notifications
     * @param {number} page 
     * @param {number} pageSize 
     * @param {GetAllNotificationsStatusEnum} [status] 
     * @param {GetAllNotificationsCategoryEnum} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getAllNotifications(page: number, pageSize: number, status?: GetAllNotificationsStatusEnum, category?: GetAllNotificationsCategoryEnum, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getAllNotifications(page, pageSize, status, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark all notifications as read
     * @param {MarkAllAsReadCategoryEnum} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public markAllAsRead(category?: MarkAllAsReadCategoryEnum, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).markAllAsRead(category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark many notifications as archived
     * @param {Array<number>} [notificationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public markManyAsArchived(notificationIds?: Array<number>, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).markManyAsArchived(notificationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark many notifications as read
     * @param {Array<number>} [notificationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public markManyAsRead(notificationIds?: Array<number>, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).markManyAsRead(notificationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Toggle notification from read to unread and vice-versa
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public toggleNotification(notificationId: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).toggleNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAllNotificationsStatusEnum = {
    Read: 'READ',
    Unread: 'UNREAD',
    Archived: 'ARCHIVED'
} as const;
export type GetAllNotificationsStatusEnum = typeof GetAllNotificationsStatusEnum[keyof typeof GetAllNotificationsStatusEnum];
/**
 * @export
 */
export const GetAllNotificationsCategoryEnum = {
    System: 'SYSTEM',
    Campaign: 'CAMPAIGN',
    Announcement: 'ANNOUNCEMENT'
} as const;
export type GetAllNotificationsCategoryEnum = typeof GetAllNotificationsCategoryEnum[keyof typeof GetAllNotificationsCategoryEnum];
/**
 * @export
 */
export const MarkAllAsReadCategoryEnum = {
    System: 'SYSTEM',
    Campaign: 'CAMPAIGN',
    Announcement: 'ANNOUNCEMENT'
} as const;
export type MarkAllAsReadCategoryEnum = typeof MarkAllAsReadCategoryEnum[keyof typeof MarkAllAsReadCategoryEnum];


