import { IconsEnum, NIcons } from 'kudi-component-library';
import { SectionHeader } from '../../styles';
import IssueOptionList from '../base/issue_option_list';
import { OptionItemProp } from '../base/issue_option';
import useRedux from '../../../../hooks/useRedux';
import { updateCurrentPage, updateIssueCreationStage } from '../../reducer/slice';

const ActiveQequest = () => {
  const { dispatch } = useRedux();
  const ActiveRequestOptionList: OptionItemProp[] = [
    {
      Icon: <NIcons icon={IconsEnum.OUTLINE_GLOBE} />,
      badge: true,
      text: 'Report an issue',
      description: 'Average Response time: 10 mins',
      action: () => {
        dispatch(updateCurrentPage('my-issues'));
        dispatch(updateIssueCreationStage('category'));
      },
    },
    {
      Icon: <NIcons icon={IconsEnum.OUTLINE_MAIL} />,
      badge: true,
      text: 'Chat with us',
      description: 'Average Response time: 1.2 mins',
      action: () => {},
    },
    {
      Icon: <NIcons icon={IconsEnum.OUTLINE_PAGES} />,
      badge: true,
      text: 'FAQs',
      description: 'Average Response time: 10 mins',
      action: () => {},
    },
  ];

  return (
    <div>
      <SectionHeader>
        <p>Active request</p>
      </SectionHeader>
      <IssueOptionList list={ActiveRequestOptionList} />
    </div>
  );
};

export default ActiveQequest;
