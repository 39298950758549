import appConfig from '../../config';

export const menuRoutes = {
  menuList: 'menu-list',
  menuOrders: 'order-list',
  menuSettings: 'settings',
  menuAnalytics: 'analytics',
};

export const IS_MENU_ON = appConfig.menuFlipper === 'ON';
