import React from 'react';
import { useFlags } from 'flagsmith/react';
import QR from '../../assets/svg/qr';
import { QRHeaderButtonWrapper } from './styles';
import { toggleQRModal } from './reducer/slice';
import useRedux from '../../hooks/useRedux';
import { FLAGSMITH_FEATURES } from '../../utils/constants';

const QRHeaderButton = () => {
  const { bb_web_dasboard_qr_display: qrDisplay } = useFlags([FLAGSMITH_FEATURES.WEB_DASHBOARD_QR_DISPLAY]);
  const { dispatch } = useRedux();
  const handleclick = () => {
    dispatch(toggleQRModal(true));
  };

  if (!qrDisplay.enabled) return null;

  return (
    <QRHeaderButtonWrapper onClick={handleclick}>
      <QR />
    </QRHeaderButtonWrapper>
  );
};
export default QRHeaderButton;
