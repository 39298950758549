import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setOnboardingComplete } from '../../../features/auth/slice';
import { BusinessRegistrationState } from '../../../types/auth';
import { IS_ONBOARDING_V2_ON } from '../../../utils/pages/auth';

const useOnboaarding2ConditionsModel = (triggerOnboardingComplete = true) => {
  const { business } = useAppSelector((state) => state.business);

  const dispatch = useAppDispatch();

  // extraction of transaction pin stte
  const paymentSetupStates: BusinessRegistrationState[] =
    business?.businessRegistrationState?.filter((item) => item?.businessSetupState === 'PAYMENT_SETUP') || [];

  // base checkers
  const isCooporate = business?.merchantType === 'CORPORATE';
  const isIndividual = !isCooporate;
  const individualAccountupgraded = !isCooporate && business?.kycLevel === 'LEVEL_TWO';
  const hasTransactionPin = !!paymentSetupStates.find((item) => item.currentStep === 'Transaction Pin');
  const cooporateDocumentSubmitted = business?.businessProfile?.documentsReviewStatus !== null;
  const cooporateDocumentApproved = business?.businessProfile?.documentsReviewStatus === 'ACCEPTED';
  const cooporateDocumentRejected = business?.businessProfile?.documentsReviewStatus === 'REJECTED';

  const hasCashout = business?.cashoutAccountCreated as boolean;

  // cashout checkers
  const cashoutNotSet = !hasCashout || !hasTransactionPin;
  const cashoutIncomplete = hasCashout && !hasTransactionPin;
  const cashoutComplete = hasCashout && hasTransactionPin;

  // individual compound checkers
  const individualNotSet = isIndividual && cashoutNotSet && !individualAccountupgraded;
  const individualPartialCashoutOnly = isIndividual && cashoutIncomplete && !individualAccountupgraded;
  const individualCompleteCashout = isIndividual && cashoutComplete;
  const individualCompleteCashoutOnly = isIndividual && cashoutComplete && !individualAccountupgraded;
  const individualAccountUpgradeOnly = isIndividual && cashoutNotSet && individualAccountupgraded;
  const individualAccountUpgradeWithPartialCashout = isIndividual && cashoutIncomplete && individualAccountupgraded;
  const individualComplete = isIndividual && cashoutComplete && individualAccountupgraded;

  // cooporate compound checkers
  const cooporateNotSet = isCooporate && cashoutNotSet && !cooporateDocumentSubmitted;
  const cooporateSubmittedDocumentsOnly =
    isCooporate &&
    cashoutNotSet &&
    cooporateDocumentSubmitted &&
    !cooporateDocumentApproved &&
    !cooporateDocumentRejected;
  const cooporateApprovedDocumentOnly = isCooporate && cashoutNotSet && cooporateDocumentApproved;
  const cooporateApprovedDocumentWithPartialCashout = isCooporate && cashoutIncomplete && cooporateDocumentApproved;
  const cooporateComplete = isCooporate && cashoutComplete && cooporateDocumentApproved;
  const cooporateRejectedDocumentOnly = isCooporate && cashoutNotSet && cooporateDocumentRejected;

  // already existing user
  const existingUserComplete = business?.onboardingCompleteV1 || false;

  // complete task state
  const setTaskCompleted = individualComplete || cooporateComplete || existingUserComplete;

  // allowing api calls
  const individualApiCallAllow = individualCompleteCashoutOnly || setTaskCompleted;

  const allowAPICall = isCooporate ? setTaskCompleted : individualApiCallAllow;

  // activate withdrawal and add money button
  const canAddAndWithdraw = isIndividual ? individualCompleteCashout : cooporateComplete;

  useEffect(() => {
    if (!IS_ONBOARDING_V2_ON) {
      return;
    }

    if (!triggerOnboardingComplete) return;
    if (!business) return;
    if (isCooporate) dispatch(setOnboardingComplete(cooporateComplete));
    if (isIndividual) dispatch(setOnboardingComplete(individualComplete));
  }, [business, cooporateComplete, dispatch, individualComplete, isCooporate, isIndividual, triggerOnboardingComplete]);
  return {
    cooporateRejectedDocumentOnly,
    cooporateApprovedDocumentOnly,
    cooporateApprovedDocumentWithPartialCashout,
    cooporateDocumentApproved,
    cooporateDocumentSubmitted,
    cooporateNotSet,
    cooporateSubmittedDocumentsOnly,
    individualAccountUpgradeOnly,
    individualAccountupgraded,
    individualCompleteCashoutOnly,
    individualNotSet,
    individualPartialCashoutOnly,
    individualAccountUpgradeWithPartialCashout,
    individualComplete,
    setTaskCompleted,
    isCooporate,
    allowAPICall,
    canAddAndWithdraw,
    existingUserComplete,
  };
};

export default useOnboaarding2ConditionsModel;
