import styled from 'styled-components';
import { FilterConfig } from './defs';

export const FilterMobileWrapper = styled.div<{
  showMobile?: boolean;
  marginTop?: number;
  boxShadow?: boolean;
  border?: boolean;
}>`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding: 1.4rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  color: #121212;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: ${({ marginTop }) => marginTop || 0}rem;
  border: ${({ border }) => (border ? '1px solid #eeeeee' : 'none')};
  box-shadow: ${({ boxShadow }) => (boxShadow ? '0px 4px 20px rgba(227, 227, 227, 0.25)' : 'none')};

  @media ${({ theme }) => theme.media.md} {
    display: none;
  }

  svg {
    transform: ${({ showMobile }) => (showMobile ? 'rotate(0deg)' : 'rotate(-90deg)')};
  }
`;

export const FilterWrapper = styled.div<{
  show?: boolean;
  config?: FilterConfig[];
  width?: string;
  filterWrapperMarginTop?: string;
  columnWidth?: string;
}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  margin-top: 2rem;
  @media ${({ theme }) => theme.media.md} {
    display: grid;
    margin-top: ${({ filterWrapperMarginTop }) => filterWrapperMarginTop || '2rem'};
    grid-template-columns: ${({ config }) =>
      config && config.length > 2 ? `repeat(3, minmax(0, 1fr))` : config && `repeat(${config.length}, minmax(0, 1fr))`};
  }

  @media ${({ theme }) => theme.media.lg} {
    display: grid;
    grid-template-columns: ${({ config, columnWidth = '230px' }) =>
      config && config.length > 2
        ? `repeat(${config.length}, minmax(0, 1fr))`
        : `repeat(auto-fill, minmax(${columnWidth}, 1fr))`};

    width: ${({ width }) => width || '100%'};
  }
`;

export const FilterItemWrapper = styled.div`
  width: 100%;
  position: relative;

  @media ${({ theme }) => theme.media.md} {
  }
`;

export const FilterItemMain = styled.div<{ show?: boolean }>`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding: 1.4rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  color: #121212;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: space-between;
  cursor: pointer;

  div {
    margin-right: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
    color: #999999;
  }

  svg:not(.filter-icon-svg) {
    transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const FilterDropDown = styled.div<{ hide: boolean }>`
  position: absolute;
  max-height: ${({ hide }) => (hide ? '0' : '20rem')};
  top: 6rem;
  left: 0;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  z-index: 3;
`;

export const FilterDropDownItem = styled.div<{ active?: boolean }>`
  padding: 1.4rem 2rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.green1};
  background-color: ${({ active, theme }) => (active ? theme.nColors['n-grey2'] : 'white')};
  cursor: pointer;

  :hover {
    background-color: ${({ active, theme }) => (active ? theme.nColors['n-grey2'] : theme.nColors['n-grey1'])};
  }
`;

export const FilterHiddenInput = styled.button`
  height: 0;
  width: 0;
  opacity: 0;
  display: flex;
`;

export const CalendarFilterWrapper = styled.div`
  position: relative;
  /* default styling */
  .react-date-picker {
    display: inline-flex;
    position: relative;
  }
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid gray;
  }
  .react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }
  .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-date-picker__button {
    border: 0;
    /* background: transparent; */
    padding: 4px 6px;
  }
  .react-date-picker__button:enabled {
    cursor: pointer;
  }
  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
  }
  .react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-date-picker__button svg {
    display: inherit;
  }

  .react-calendar__navigation__label {
    grid-column: 1;
    grid-row: 1;
  }

  .react-calendar__navigation {
    display: grid;
    grid-template-columns: 5fr repeat(4, 1fr);
  }
  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-date-picker__calendar--closed {
    display: none;
  }
  .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }

  /* custom css */
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__wrapper {
    border: none;
  }
  .react-date-picker__calendar {
    top: -4px !important;
  }
  .react-date-picker__calendar .react-calendar {
    border: 1px solid #eaedf3;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }

  .react-calendar__month-view__weekdays {
    margin: 0 20px;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 12px 0 7px;
    color: #121212;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days {
    margin: 0 20px 30px 20px;
  }

  .react-calendar__tile--now {
    background: #eeeeee;
    border-radius: 2px;
    color: white;
  }

  .react-calendar__tile .react-calendar__month-view__days__day {
    color: #717171 !important;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #717171 !important;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd
    .react-calendar__tile--rangeBothEnds
    .react-calendar__month-view__days__day
    .react-calendar__month-view__days__day--weekend {
    color: #ffffff !important;
  }

  .react-calendar__tile {
    color: #717171;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .react-calendar__tile .react-calendar__tile--active {
    color: white !important;
  }

  .react-calendar__tile--active {
    background: #212121;
    border-radius: 2px;
    color: white !important;
  }

  .react-calendar__tile--active .react-calendar__month-view__days__day--weekend {
    color: white;
  }

  .react-calendar__tile .react-calendar__month-view__days__day {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'cv11' on;
    color: #717171 !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #aeacac !important;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #18379a;
  }

  .react-date-picker__inputGroup {
    height: 0;
    width: 0;
    opacity: 0;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #ffcc00;
    border-radius: 2px;
  }

  .react-calendar__tile:disabled {
    background: #ffcc00 !important;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .react-date-picker {
    position: absolute;
  }

  .react-date-picker .react-date-picker--closed .react-date-picker--enabled {
    position: absolute;
  }

  .react-date-picker__button {
    height: 0;
    width: 0;
    opacity: 0;
  }
`;

export const CalenderHelper = styled.div<{ show?: boolean }>`
  display: flex;
  height: ${({ show }) => (show ? '100%' : '0')};
`;
