import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Pos = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M5.586 2.941c-.343 0-.62.271-.62.605v12.908c0 .334.277.605.62.605h8.828c.343 0 .62-.271.62-.605V3.546a.613.613 0 0 0-.62-.605H5.586ZM4 3.546C4 2.692 4.71 2 5.586 2h8.828C15.29 2 16 2.692 16 3.546v12.908c0 .854-.71 1.546-1.586 1.546H5.586C4.71 18 4 17.308 4 16.454V3.546Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M11.846 11.43c0-.26.216-.47.483-.47h.56c.268 0 .484.21.484.47s-.216.471-.483.471h-.56a.477.477 0 0 1-.484-.47ZM11.879 13.313c0-.26.216-.47.482-.47h.561c.267 0 .483.21.483.47s-.216.47-.483.47h-.56a.477.477 0 0 1-.483-.47ZM11.879 15.27c0-.26.216-.47.482-.47h.561c.267 0 .483.21.483.47s-.216.471-.483.471h-.56a.477.477 0 0 1-.483-.47ZM9.252 13.313c0-.26.217-.47.483-.47h.56c.268 0 .484.21.484.47s-.216.47-.483.47h-.56a.477.477 0 0 1-.484-.47ZM9.22 11.43c0-.26.216-.47.483-.47h.56c.267 0 .483.21.483.47s-.216.471-.482.471h-.561a.477.477 0 0 1-.483-.47ZM6.626 13.313c0-.26.216-.47.483-.47h.56c.267 0 .484.21.484.47s-.217.47-.483.47h-.561a.477.477 0 0 1-.483-.47ZM9.252 15.27c0-.26.217-.47.483-.47h.56c.268 0 .484.21.484.47s-.216.471-.483.471h-.56a.477.477 0 0 1-.484-.47ZM6.594 11.43c0-.26.216-.47.483-.47h.56c.267 0 .483.21.483.47s-.216.471-.482.471h-.561a.477.477 0 0 1-.483-.47ZM6.626 15.27c0-.26.216-.47.483-.47h.56c.267 0 .484.21.484.47s-.217.471-.483.471h-.561a.477.477 0 0 1-.483-.47Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Pos;
