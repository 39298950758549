import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  showModal: boolean;
}

const initialState: InitialState = {
  showModal: false,
};

export const QRSlice = createSlice({
  name: 'QR',
  initialState,
  reducers: {
    toggleQRModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

export const { toggleQRModal } = QRSlice.actions;

export default QRSlice.reducer;
