import { ApiResponse } from '../../../types/redux';
import { TransactionDataPoint } from '../../../types/transactions';
import axios from '../../../utils/merchantAcqServiceHttp';

interface FetchTransactionResponse extends ApiResponse {
  data: TransactionDataPoint[];
}

const fetchTransaction = async ({
  transactionRef,
  outletId,
  isOutletSettlement,
}: {
  transactionRef: string;
  outletId?: string;
  isOutletSettlement?: boolean;
}) => {
  const url =
    isOutletSettlement || outletId ? '/webbe/v1/accounts/outlet/transactions/single' : '/webbe/v1/transactions/single';

  try {
    const result: FetchTransactionResponse = await axios.get(`${url}/${transactionRef}`, {
      ...(outletId && { headers: { subAccountId: outletId } }),
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchTransaction;
