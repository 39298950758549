import { createAsyncThunk } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../../app/store';
import { ReduxAction } from '../../../../types/redux';
import axios from '../../../../utils/http';
import { issueContactsURL } from '../../constants';
import { Contact } from '../../defs';

interface GetContactDetailsResponse {
  message: string;
  data: Contact[];
}

export const GetContactDetails = createAsyncThunk<GetContactDetailsResponse, ReduxAction, { state: RootState }>(
  '/support/get_contact_details',
  async ({ onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const data: GetContactDetailsResponse = await axios.get(`/${issueContactsURL}`, {});

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
