import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { STATES } from '../../../../utils/constants';
import { ObjectKeyValuePair, OptionType } from '../../../../components/DropdownInput/types';
import { useAppSelector } from '../../../../app/hooks';

interface InitialValues {
  businessName: string;
  businesCategory: string;
  state: string;
  lga: string;
}
const validationSchema = Yup.object().shape({
  businessName: Yup.string().required('Business name is required').min(2, 'Kindly check your business name').trim(),
  businesCategory: Yup.string()
    .required('Business category is required')
    .min(2, 'Kindly check your business category')
    .trim(),
  state: Yup.string().required('State is required').min(2, 'Kindly check your state').trim(),
  lga: Yup.string().required('LGA is required').min(2, 'Kindly check your LGA').trim(),
});

const useBusinessInformation = () => {
  const InitialFormValues: InitialValues = {
    businessName: '',
    businesCategory: '',
    state: '',
    lga: '',
  };
  const { appEnumList } = useAppSelector((state) => state.settings);

  const [selectedState, setSelectedState] = useState<OptionType | ObjectKeyValuePair>({
    label: '',
    value: '',
  });
  const [lgas, setLgas] = useState<OptionType[] | ObjectKeyValuePair[]>([]);

  const categories = appEnumList.businessType.map((item) => ({
    label: item,
    value: item,
  }));
  const states = Object.keys(STATES).map((key) => ({ label: key, value: key }));

  useEffect(() => {
    if (selectedState) {
      const lgamap = STATES[selectedState.value]?.map((item) => ({ label: item, value: item }));
      setLgas(lgamap);
    } else {
      setLgas([]);
    }
  }, [selectedState]);

  return {
    InitialFormValues,
    validationSchema,
    states,
    setSelectedState,
    lgas,
    categories,
  };
};

export default useBusinessInformation;
