import styled from 'styled-components';
import './style.css';

const SkeletonMain = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '1rem'};
  border-radius: 0.5rem;
`;

const Skeleton = ({ width, height }: { width?: string; height?: string }) => (
  <SkeletonMain width={width} height={height} className="skeleton" />
);
export default Skeleton;
