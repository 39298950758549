import React from 'react';
import useRedux from '../../../../hooks/useRedux';
import { DocumentWrapper } from '../../styles';
import DocumentUpload from '../../../../components/DocumentUpload';
import { updateDocumentList } from '../../reducer/slice';

const DocumentsSelect = () => {
  const {
    state: {
      support: {
        issueCreation: { issueDocumentList, isLoading },
      },
    },
    dispatch,
  } = useRedux();

  return (
    <DocumentWrapper
      onClick={(e) => {
        if (isLoading) e.stopPropagation();
      }}
    >
      <DocumentUpload
        key="1"
        name={`support${1}`}
        label="Upload photo (Max. of 3)"
        value={issueDocumentList[1]}
        onUpload={(val: string) => {
          dispatch(updateDocumentList({ key: '1', value: val }));
        }}
        base64
      />

      {issueDocumentList['1'] ? (
        <DocumentUpload
          key="2"
          name={`support${'2'}`}
          label=""
          value={issueDocumentList['2']}
          onUpload={(val: string) => {
            dispatch(updateDocumentList({ key: '2', value: val }));
          }}
          base64
        />
      ) : null}
      {issueDocumentList['2'] ? (
        <DocumentUpload
          key="3"
          name={`support${'3'}`}
          label=""
          value={issueDocumentList['3']}
          onUpload={(val: string) => {
            dispatch(updateDocumentList({ key: '3', value: val }));
          }}
          base64
        />
      ) : null}
    </DocumentWrapper>
  );
};

export default DocumentsSelect;
