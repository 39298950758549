import styled, { DefaultTheme } from 'styled-components';
import { Typography, AltButton } from 'kudi-component-library';
import { Flex } from 'rebass';
import { transactionTypes } from '../../utils/filterTypes';
import { TerminalStatusEnums } from './terminalEnums';
import { TransactionInfoContentWrapper, TransactionInfoWrapper } from '../../components/TransactionInfo/styles';
import { DisputeMerchantStatus, DisputeVerdict } from '../../utils/transactionEnums';

export const PageWrapper = styled.div`
  padding: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    padding: 40px 50px;
  }
`;

export const HeaderText = styled.h3`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};

  @media ${({ theme }) => theme.media.lg} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const HeaderTopFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const HeaderTopButtonWrapper = styled.div<{ canActivateTerminal?: boolean }>`
  position: relative;
  button {
    height: fit-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ canActivateTerminal }) => (canActivateTerminal ? '100%' : 'fit-content')};
  }

  margin-top: 2rem;

  margin-bottom: 4rem;

  display: flex;
  gap: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    button {
      padding: 1rem 2rem;
      width: fit-content;
    }
  }

  @media ${({ theme }) => theme.media.md} {
    margin-bottom: 0;
    width: fit-content;
  }
`;

// terminal card

export const TerminalCardsWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const TerminalCardWrapper = styled.div<{ active?: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.primary.blue1 : theme.nColors['n-grey1'])};
  background-color: ${({ theme, active }) => (active ? theme.colors.primary.blue5 : theme.colors.primary.white)};
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const TerminalCardWrapper2 = styled.div<{ active?: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.primary.blue1 : theme.nColors['n-grey1'])};
  background-color: ${({ theme, active }) => (active ? theme.colors.primary.blue5 : theme.colors.primary.white)};
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
`;

export const TerminalCardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.media.md} {
    justify-content: flex-start;
    gap: 0.5rem;
  }
`;

export const TerminalCardImageWraper = styled.div`
  width: 3.5rem;
  margin-right: 2rem;
`;

export const TerminalCardTextWrapper = styled.div<{ justifyCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyCenter }) => (justifyCenter ? 'center' : 'start')};
  width: 100%;
`;

export const TypographyWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const TerminalCardTitle = styled(Typography)<{ weightShouldBeNormal?: boolean }>`
  font-weight: ${({ weightShouldBeNormal }) => (weightShouldBeNormal ? '500' : '600')};
  display: flex;
  align-items: center;
  /* justify-content: center; */
`;

export const TextWrapper = styled.div`
  margin-left: 7px;
  margin-top: 1.5px;
`;

export const PaymentType = styled.div`
  border-radius: 50px;
  background-color: ${({ theme }) => theme.nColors['n-yellow1']};
  color: ${({ theme }) => theme.nColors['n-yellow7']};
  width: fit-content;
  padding: 3px 8px;
  margin-top: 3px;
  font-weight: 500;
`;

export const MainPriceWrapper = styled.div`
  display: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px;
  padding-top: 10px;
`;

export const MainPriceWrapper2 = styled.div`
  display: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px;
  padding-top: 10px;
  margin-bottom: 30px;
`;

export const PriceWrapper1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Price = styled.div`
  margin-left: 10px;
  font-size: 12px;
  margin-bottom: 12px;
`;

export const LeftWrapper = styled.div`
  margin-left: 10px;
  font-size: 12px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.nColors['n-grey4']};
`;

export const LeftWrapper2 = styled.div`
  margin-left: 10px;
  font-size: 12px;
  margin-bottom: 12px;
`;

export const RightWrapper = styled.div`
  margin-right: 10px;
  font-size: 12px;
  margin-bottom: 12px;
`;

export const RightWrapper2 = styled.div`
  margin-right: 10px;
  font-size: 12px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.nColors['n-grey4']};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditWrapper = styled.span`
  cursor: pointer;
`;

export const IconWrapper = styled.span`
  margin-right: 5px;
`;

export const TerminalCardTitle2 = styled(Typography)<{ weightShouldBeNormal?: boolean }>`
  font-weight: ${({ weightShouldBeNormal }) => (weightShouldBeNormal ? '500' : '600')};
  display: flex;
  align-items: center;
  /* margin-right: 50px; */
`;

export const NIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Recommended = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.nColors['n-yellow']};
  border-radius: 20px;
  padding: 3px 9px;
  color: ${({ theme }) => theme.nColors['n-dark']};
  font-weight: 500;
`;

export const Starts = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 7px;
`;

export const TerminalActionsButton = styled(AltButton)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.nColors['n-dark']};
  color: ${({ theme }) => theme.nColors['n-dark']};
`;

export const TerminalCardPrice = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on, 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const TerminalCardSmallText = styled.p<{ size?: number }>`
  font-weight: 400;
  font-size: ${({ size }) => size}px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on, 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};

  span {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.primary.green2};
  }
`;

export const TerminalCardFeaturesWrapper = styled.ol`
  margin-top: 0.5rem;
`;

export const TerminalCardFeaturesWrapper2 = styled.ol`
  margin: 12px 0;
  line-height: 17px;
  font-size: 12px;
  color: ${({ theme }) => theme.nColors['n-grey4']};
`;

export const TerminalCardFeature = styled.li`
  display flex;
  align-items: center;
  max-width: 26.1rem;

  svg {
    margin-right: 1rem;
  }
`;

export const CheckboxWrapper = styled.div<{ active?: boolean; size?: string }>`
  width: ${({ size }) => size || '2rem'};
  height: ${({ size }) => size || '2rem'};
  border-radius: 3px;
  background-color: ${({ theme, active }) => (active ? theme.colors.primary.blue1 : theme.colors.primary.black6)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const TerminalQuantityWrapper = styled.div`
  margin-top: 5rem;
  width: 100%;
`;

export const TerminalHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px;
  padding: 10px 15px;
`;

export const TerminalQuantityMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CirculrTaerminalImageWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2rem;
  position: relative;

  svg {
    position: absolute;
    top: 0.7rem;
  }
`;

export const FormWrapper = styled.form``;

export const InputsItemWrapper = styled.div<{ pb?: string }>`
  padding-bottom: ${({ pb }) => pb || '2.5rem'};
`;

export const InputItemsWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
`;

export const LeftInputWrapper = styled.div`
  width: 50%;
  margin-right: 1rem;
`;

export const RightInputWrapper = styled.div`
  width: 50%;
  margin-left: 1rem;
`;

export const FlexInputsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    align-items: end;
    div > input {
      height: 40px !important;
    }
  }
`;

export const ButtonInputWrapper = styled.div`
  margin-top: 1rem;

  @media ${({ theme }) => theme.media.lg} {
    margin-left: 1rem;
  }
`;

export const TerminalRequestTitle = styled.h4`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.nColors['n-dark']};
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
`;

export const TerminalActivationIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const TerminalModalContentWrapper = styled.div`
  width: 100%;
`;

export const TerminalCardLeftWrapper = styled.div`
  display: flex;
  width: 90%;
`;

// empty table active

export const EmptyTableWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  svg {
    width: 200px !important;
    height: auto;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
    color: #717171;
  }

  @media ${({ theme }) => theme.media.lg} {
    svg {
      width: 260px !important;
      height: auto;
    }

    p {
      margin-bottom: 7.5rem;
      font-size: 16px;
      line-height: 24px;
      max-width: 38rem;
    }
  }
`;

export const ModalBackButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  color: #121212;
  outline: none;
  height: fit-content;
  padding-bottom: 0.5rem;
  width: fit-content;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-bottom: none;
  border-bottom: 1px solid #ffcc00;
  margin: 0 auto;
  // margin-left: 0px;
  // margin-top: 12px;
`;
export const OrderSummaryContent = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.media.lg} {
    padding: 4rem;
  }
`;

export const OrderPinSummaryContent = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.media.lg} {
    padding: 4rem;
  }
`;

export const OrderSummarryTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: #121212;
  padding-top: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    padding-top: 0;
  }
`;

export const TransactionPinSummaryTitle = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: #121212;
  padding-top: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    padding-top: 0;
  }
`;

export const OrderSummarrySubTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  color: #121212;
`;

export const OrderSummarrySubContentWrappper = styled.div`
  display: flex;
`;

export const OrderSummarrySubContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const OrderSummarrySmallText = styled.p`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  font-feature-settings: 'cv11' on;
  color: #121212;
  font-weight: 500;
`;

export const OrderSummarrySmallTextAlt = styled.p`
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  font-feature-settings: 'cv11' on;
  font-weight: 500;
  color: #999999;
`;

export const OrderSummarryMainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

const weightMap = {
  thick: 400,
  dark: 600,
};

export const OrderSummarryText = styled.span<{ weight?: 'thick' | 'dark'; align?: 'left' | 'right' }>`
  font-weight: ${({ weight }) => (weight ? weightMap?.[weight] : 300)};
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  color: #555555;
  text-align: ${({ align }) => align};
`;

export const ErrorText = styled.div`
  font-size: 12px;
  color: #ff2200;
  text-align: center;
  margin-bottom: 1rem;
`;

export const SmBoldText = styled.span`
  color: #121212;
  font-weight: 400;
  font-size: 14px;
`;

export const TerminalNavigationTabWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 0.4rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  height: 50px;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
  @media ${({ theme }) => theme.media.lg} {
    justify-content: flex-start;
  }
`;

export const TerminalNavigationItem = styled(Typography)<{ active?: boolean }>`
  padding: 0 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  text-transform: capitalize;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media ${({ theme }) => theme.media.lg} {
    padding: 0 3rem;
    margin-right: 2rem;
  }

  ${({ active, theme }) =>
    active
      ? `::after {
    content: '';
    height: 3px;
    width: 100%;
    background-color: ${theme.colors.primary.blue1};
    position: absolute;
    bottom: -0.7rem;
    left: 0;
  }`
      : ''}
`;

export const ActivateTerminalDropDownHiddenButton = styled.button`
  height: 0;
  width: 0;
  opacity: 0;
`;

export const ActivateTerminalDropDownWrapper = styled.div<{ hide?: boolean }>`
  width: ${({ hide }) => (hide ? '0' : 'calc(100% - 4rem)')};
  height: ${({ hide }) => (hide ? '0' : 'fit-content')};
  background-color: ${({ theme }) => theme.colors.primary.white};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  right: 0;
  top: 5.2rem;
  border-radius: 0.5rem;
  z-index: 999;
  overflow: hidden;
  opacity: ${({ hide }) => (hide ? '0' : '1')};
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  @media ${({ theme }) => theme.media.lg} {
    width: ${({ hide }) => (hide ? '0' : '23rem')};
    height: ${({ hide }) => (hide ? '0' : 'fit-content')};
    opacity: ${({ hide }) => (hide ? '0' : '1')};
  }
`;

export const ActivateTerminalDropdownItem = styled.div<{ active?: boolean }>`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 2rem;
  cursor: pointer;
  :hover {
    background-color: #f4f2f2;
  }
  span {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: ${({ theme }) => theme.colors.primary.green1};
  }
`;

export const handleStateColor = (state: any, theme: DefaultTheme | null) => {
  switch (state) {
    case transactionTypes.DELIVERED:
    case transactionTypes.SUCCESS:
    case transactionTypes.PAYMENT_SUCCESSFUL:
    case TerminalStatusEnums.assigned:
    case TerminalStatusEnums.activated:
    case DisputeMerchantStatus.APPROVED:
    case DisputeVerdict.MANUAL_APPROVED:
      return theme ? theme.nColors['n-green'] : 'n-green';
    case transactionTypes.PAYMENT_FAILED:
    case transactionTypes.CANCELLED:
    case transactionTypes.REVERSED_BY_VENDOR:
    case TerminalStatusEnums.decommissioned:
    case DisputeMerchantStatus.DECLINED:
    case DisputeVerdict.MANUAL_DECLINED:
      return theme ? theme.nColors['n-red'] : 'n-red';
    case DisputeMerchantStatus.AWAITING_FEEDBACK:
      return theme ? theme.nColors['n-yellow8'] : 'n-yellow8';
    default:
      return theme ? theme.nColors['n-grey5'] : 'n-grey5';
  }
};

export const handleBackgroundStateColor = (state: any, theme: DefaultTheme | null) => {
  switch (state) {
    case transactionTypes.DELIVERED:
    case transactionTypes.SUCCESS:
    case transactionTypes.PAYMENT_SUCCESSFUL:
    case TerminalStatusEnums.assigned:
    case TerminalStatusEnums.activated:
    case DisputeMerchantStatus.APPROVED:
    case DisputeVerdict.MANUAL_APPROVED:
      return theme ? theme.nColors['n-green1'] : 'n-green1';
    case transactionTypes.PAYMENT_FAILED:
    case transactionTypes.CANCELLED:
    case transactionTypes.REVERSED_BY_VENDOR:
    case TerminalStatusEnums.decommissioned:
    case DisputeMerchantStatus.DECLINED:
    case DisputeVerdict.MANUAL_DECLINED:
      return theme ? theme.nColors['n-red1'] : 'n-red1';
    case DisputeMerchantStatus.AWAITING_FEEDBACK:
      return theme ? theme.nColors['n-yellow2'] : 'n-yellow2';
    default:
      return theme ? theme.nColors['n-grey1'] : 'n-grey1';
  }
};

export const TerminalStatusText = styled.div<{
  state?: string;
  useHeadingFontSize?: boolean;
  fontWeight?: string | number;
}>`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.2rem 1rem;
  background-color: ${({ theme, state }) => handleBackgroundStateColor(state, theme)};

  p {
    color: ${({ theme, state }) => handleStateColor(state, theme)};
    font-style: normal;
    font-weight: ${({ theme, fontWeight }) => fontWeight || theme.fontWeights.semiBold};
    font-size: ${({ useHeadingFontSize }) => (useHeadingFontSize ? '1.8rem' : '12px')};
    line-height: 18px;
    letter-spacing: -0.02em;
    font-feature-settings: 'cv11' on;
  }
`;
export const TerminalStatusText2 = styled.div<{
  state?: string;
  useHeadingFontSize?: boolean;
  fontWeight?: string | number;
}>`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.2rem 1rem;
  background-color: ${({ theme, state }) => handleBackgroundStateColor(state, theme)};

  p {
    color: ${({ theme, state }) => handleStateColor(state, theme)};
    font-style: normal;
    font-weight: ${({ theme, fontWeight }) => fontWeight || theme.fontWeights.semiBold};
    font-size: ${({ useHeadingFontSize }) => (useHeadingFontSize ? '1.8rem' : '12px')};
    line-height: 18px;
    letter-spacing: -0.02em;
    font-feature-settings: 'cv11' on;
  }
`;

export const TerminalStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 25px;
`;

export const SuccessText = styled.div`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.2rem 1rem;
  background-color: ${({ theme }) => theme.nColors['n-green1']};

  p {
    color: ${({ theme }) => theme.nColors['n-green']};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    font-feature-settings: 'cv11' on;
  }
`;

export const SmallText = styled.p`
  color: ${({ theme }) => theme.nColors['n-dark']};
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: 'cv11' on;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
    cursor: pointer;
    display: inline;
    position: relative;
  }

  svg:hover:after {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    background: #444;
    border-radius: 8px;
    color: #fff;
    content: attr(title);
    margin: -82px auto 0;
    font-size: 16px;
    padding: 13px;
    width: 220px;
  }

  svg:hover:before {
    border: solid;
    border-color: #444 transparent;
    border-width: 12px 6px 0 6px;
    content: '';
    left: 45%;
    bottom: 30px;
    position: absolute;
  }
`;

export const BatchUploadTerminalActivationContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.nColors['n-light']};
  margin-top: 10rem;
  padding: 20px;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(209, 209, 209, 0.24);
`;

export const BatchUploadTitle = styled.h4`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.nColors['n-dark']};
  margin-bottom: 20px;
  width: 100%;
`;

export const BatchUploadInputWrapper = styled.label`
  height: 110px;
  border: 1px dashed ${({ theme }) => theme.nColors['n-grey2']};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
`;

export const BatchUploadSubmitWrapper = styled.div`
  border: 1px dashed ${({ theme }) => theme.nColors['n-grey2']};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
`;

export const BatchUploadLoadingWrapper = styled.div`
  border: 1px dashed ${({ theme }) => theme.nColors['n-grey2']};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
`;

export const BatchUploadInputTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.nColors['n-dark']};
`;

export const BatchUploadInfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.nColors['n-grey4']};
  margin-top: 2px;
`;

export const XLsxIconWrapper = styled.div``;

export const BatchUploadInnerInputWrapper = styled.div`
  margin-top: 10px;
`;

export const BatchUploadInput = styled.input`
  display: none;
`;

export const DownloadSampleText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  text-align: center;
  text-decoration-line: underline;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.nColors['n-grey4']};
  cursor: pointer;
`;

export const UploadedInfoWrapper = styled.div`
  margin-left: 10px;
`;

export const DeleteFileUploadedWrapper = styled.div`
  display: flex;
  justfify-content: end;
  width: 10%;

  svg {
    cursor: pointer;
  }
`;

export const LeftContentWrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
`;

export const TerminalSearchAndFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;

export const HeaderLeftWrapper = styled.div``;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  padding: 0;
  margin-bottom: 1.5rem;

  svg {
    margin-right: 1rem;
  }
`;

export const HeaderTextAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 1.5rem;
    cursor: pointer;
  }
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  button > span {
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
    gap: 10px;
  }
`;

export const SummaryCardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 24px 30px;

  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    height: 100px;
  }
`;

export const SummaryCard = styled.div`
  @media ${({ theme }) => theme.media.md} {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
  }
`;

export const SummaryCardSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media ${({ theme }) => theme.media.md} {
    width: 50%;
  }
  @media ${({ theme }) => theme.media.lg} {
    width: 50%;
  }
`;

export const SummaryCardText = styled.h4`
  color: ${({ theme }) => theme.nColors['n-grey4']};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  @media ${({ theme }) => theme.media.lg} {
    font-size: 1.4rem;
    margin-bottom: 0.6rem;
  }
`;

export const SummaryCardValue = styled.span`
  display: block;
  color: ${({ theme }) => theme.nColors['n-dark']};
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
`;

export const TitleText = styled.h5`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: ${({ theme }) => theme.nColors['n-dark']};
  margin-bottom: 20px;

  @media ${({ theme }) => theme.media.lg} {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 40px;
  }
`;

export const OverViewCardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 5px;
  display: block;
  padding: 20px 20px 0;
  border: ${({ theme }) => `1px solid ${theme.nColors['n-grey1']}`};

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 100px;
    padding: 0 30px;
    border: none;
  }
`;

export const OverViewCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  @media ${({ theme }) => theme.media.lg} {
    display: block;
    padding-bottom: 0;
  }

  & .value {
    font-weight: 500;

    @media ${({ theme }) => theme.media.lg} {
      font-weight: 700;
    }
  }
`;

export const CheckToggleWrapper = styled(Flex)`
  gap: 10px;
  align-items: center;
`;

// Flyout styles
export const Wrapper = styled(TransactionInfoWrapper)`
  align-items: flex-start;
  flex-direction: column;
`;

export const MainWrapper = styled(TransactionInfoContentWrapper)`
  max-width: 503px;
  padding: 2rem;
  align-items: flex-start;
  height: 100%;

  label {
    text-align: start;
  }
`;

export const HeaderWrapper = styled(MainWrapper)`
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px 5px 0 0;
  height: auto;
`;

export const SubHeaderWrapper = styled.div`
  padding: 2rem 2rem 0 2rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  height: 95vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled(MainWrapper)<{ removePadding: boolean }>`
  border-radius: unset;
  padding: ${({ removePadding }) => (removePadding ? '0' : '2rem')};
`;

export const TopFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InfoTab = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.lg} {
    display: block;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.colors.primary.white};
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
`;

export const SubscriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`;

export const SubscriptionLeftContent = styled.div``;

export const SubscriptionRightContent = styled.div``;

export const SingleLeftItem = styled.div`
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.nColors['n-grey3']};
  font-size: 12px;
`;

export const SingleRightItem = styled.div`
  margin-right: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  text-align: right;
`;

export const ButtonWraper = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WebTableWrapper = styled.div`
  display: none;
  @media ${({ theme }) => theme.media.lg} {
    display: block;
  }
`;

export const MobileTableWrapper = styled.div`
  display: block;
  @media ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;
