const AllNotifications = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.75 7.5H2.25" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.75 4.5H2.25" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.75 10.5H2.25" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.75 13.5H2.25" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default AllNotifications;
