import { Typography } from 'kudi-component-library';
import { ReactNode } from 'react';
import { ComingSoonRightArrow } from '../../assets/svg';
import {
  DetailSection,
  DiscoverSection,
  DiscoverText,
  HeaderSection,
  ProductComingSoonWrapper,
  ProductDescription,
  ProductTitle,
  TextSection,
  SvgHolder,
} from './style';

interface ProductComingSoonProps {
  image?: ReactNode;
  alternate?: boolean;
  productTitle?: string;
  productDescription?: string | ReactNode;
}

export default function ProductComingSoon({
  image,
  alternate = false,
  productTitle,
  productDescription,
}: ProductComingSoonProps) {
  return (
    <ProductComingSoonWrapper>
      <HeaderSection>
        <Typography variant="n-text8">Start today, grow your business faster and smarter</Typography>
        <Typography variant="n-text3" color="grey5">
          Track growth effortlessly. Make or receive money transfers easily via the Nomba Pro terminal
        </Typography>
      </HeaderSection>
      <DetailSection alternate={alternate}>
        <SvgHolder>{image}</SvgHolder>
        <TextSection>
          <ProductTitle>
            <Typography variant="n-text8">{productTitle}</Typography>
          </ProductTitle>
          <ProductDescription variant="n-text4" color="grey5">
            {productDescription}
          </ProductDescription>
          <DiscoverSection>
            <DiscoverText variant="n-text3">coming soon</DiscoverText>
            <ComingSoonRightArrow />
          </DiscoverSection>
        </TextSection>
      </DetailSection>
    </ProductComingSoonWrapper>
  );
}
