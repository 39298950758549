import { useState } from 'react';
import { TextArea, Button } from 'kudi-component-library';
import moment from 'moment';

import Select from '../../../../components/Select';
import {
  SummaryDetailsInfoWrapper,
  InnerWrapper,
  LeftText,
  RightText,
  SelectWrapper,
  TextWrapper,
  ButtonWrapper,
} from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import { formatCurrency } from '../../../../utils';
import DocumentsSelect from './documents_select';
import { CreateAnIssue } from '../../reducer/thunk_actions';
import { updateIssueDescription } from '../../reducer/slice';

const ActiveSummary = () => {
  const {
    dispatch,
    state: {
      support: {
        issueCreation: { selectedTransaction, selectedType, isLoading },
      },
    },
  } = useRedux();

  const [description, setDescription] = useState<string>('');

  const issueTypes = [
    {
      text: 'Transaction ID',
      value: selectedTransaction?.id,
    },
    {
      text: 'Type',
      value: selectedTransaction?.type,
    },
    {
      text: 'Amount',
      value: formatCurrency(selectedTransaction?.amount || '0', true),
    },
    {
      text: 'Status',
      value: selectedTransaction?.status,
    },
    {
      text: 'Date',
      value: moment(selectedTransaction?.timeCreated).format('DD/MM/YY'),
    },
  ];

  const createIssue = () => {
    dispatch(updateIssueDescription(description));
    dispatch(CreateAnIssue({}));
  };

  return (
    <>
      <SummaryDetailsInfoWrapper>
        {issueTypes.map((item) => (
          <InnerWrapper>
            <LeftText>{item.text}</LeftText>
            <RightText>{item.value}</RightText>
          </InnerWrapper>
        ))}
      </SummaryDetailsInfoWrapper>

      <SelectWrapper>
        <Select
          label="Issue Type"
          name="issueType"
          options={[{ text: selectedType?.prettyName || '', value: selectedType?.id || '' }]}
          value={selectedType?.id || ''}
          onChange={() => {}}
          width="100%"
          disabled
        />
      </SelectWrapper>
      <TextWrapper>
        <TextArea
          label="Description"
          name="description"
          placeholder="Please enter description"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          width="100%"
          disabled={isLoading}
        />
      </TextWrapper>
      <DocumentsSelect />

      <ButtonWrapper>
        <Button loading={isLoading} disabled={isLoading} variant="large" width="100%" onClick={createIssue}>
          Submit Issue
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ActiveSummary;
