import { Typography } from 'kudi-component-library';
import React from 'react';
import { LogoSm } from '../../../../assets/svg';
import { TeamLoadingWrapper } from '../team-up/styles';
import { LoadingProps } from '../team-up/types';

const Loading = ({ message, isDefault = true }: LoadingProps) => (
  <TeamLoadingWrapper isDefault={isDefault}>
    <LogoSm />
    <Typography variant="n-text2" color="grey4">
      {message}...
    </Typography>
  </TeamLoadingWrapper>
);

export default Loading;
