import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput, Text, Checkbox, Button, OtpInput } from 'kudi-component-library';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/Modal';
import { FullFlex, FullBox } from '../../../styles/generic';
import { NewInfoIcon, ErrorIcon, WithdrawSuccessIcon } from '../../../assets/svg';
import CalendarFilter from '../../../components/Calendar';
import { formatCurrency } from '../../../utils';
import { BulkTransferPayloadData, BulkTransferUsersData } from '../../../types/transactions';
import { bankCodes } from '../../../data';
import { bulkTransferToBank, getServiceCharge } from '../../../features/transfers/thunkActions';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import TimePicker from '../../../components/TimePicker';
import Select from '../../../components/Select';
import { timeFormatter } from '../../../utils/dateFormatter';

interface InitialValues {
  accountNumber: string;
}
const InitialFormValues: InitialValues = {
  accountNumber: '',
};

function ErrorResponseModal({
  errorMsg,
  showErrorResponseModal,
  setShowErrorResponseModal,
}: {
  errorMsg: string;
  showErrorResponseModal: boolean;
  setShowErrorResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal showModal={showErrorResponseModal} onClose={() => setShowErrorResponseModal(false)}>
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="10px">
        <ErrorIcon />

        <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
          Something went wrong
        </Text>
        <Text color="#717171" fontSize="14px" textAlign="center">
          {errorMsg}
        </Text>

        <Button
          onClick={() => {
            setShowErrorResponseModal(false);
          }}
          width="80%"
          variant="xlarge"
          style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '90px' }}
        >
          Close
        </Button>
      </FullFlex>
    </Modal>
  );
}

function ResponseModal({
  showResponseModal,
  setShowResponseModal,
  amount,
  transferType,
  scheduledDate,
  frequency,
  totalBeneficiaries,
}: {
  showResponseModal: boolean;
  amount: number;
  totalBeneficiaries: number;
  transferType?: string;
  frequency?: string;
  scheduledDate?: string | null;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  return (
    <Modal showModal={showResponseModal} onClose={() => setShowResponseModal(false)}>
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="20px" mt="20px">
        <WithdrawSuccessIcon />
        <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
          Bulk payment processing
        </Text>

        {amount && transferType === 'IMMEDIATELY' && (
          <Text color="#717171" fontSize="14px" textAlign="center">
            Your bulk payment of {formatCurrency(amount)} for Bulk Beneficiaries is processing.
          </Text>
        )}

        {amount && transferType === 'SCHEDULED' && scheduledDate && frequency && (
          <Text color="#717171" fontSize="14px" textAlign="center">
            You have successfully set up a repeating scheduled payment of {formatCurrency(amount)} for{' '}
            {totalBeneficiaries} beneficiaries. The first payment will be made on the{' '}
            {new Date(scheduledDate).toLocaleString([], {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}{' '}
            at {timeFormatter(scheduledDate)}, and will repeat {frequency} until it is cancelled.
          </Text>
        )}

        <Button
          onClick={() => {
            setShowResponseModal(false);
            navigate('/dashboard/payments/transfer');
          }}
          width="80%"
          variant="xlarge"
          style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '90px' }}
        >
          Done
        </Button>
      </FullFlex>
    </Modal>
  );
}

function ConfirmTransactionModal({
  showConfirmModal,
  setShowConfirmModal,
  bulkTransferPayload,
  paymentRequestList,
}: {
  showConfirmModal: boolean;
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  paymentRequestList: Array<BulkTransferUsersData>;
  bulkTransferPayload: {
    memo: string;
    saveBeneficiaries: boolean;
    transferType: string;
    scheduledDate: string;
    frequency: string;
    repeatPayment: boolean;
    amount: number;
  } | null;
}) {
  const [transactionPin, setTransactionPin] = React.useState<string>('');
  const [otpError, setOtpError] = React.useState<string>('');
  const [errMsg, setErrMsg] = React.useState<string>('');
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [showErrorResponseModal, setShowErrorResponseModal] = React.useState(false);

  const { fullUser } = useAppSelector((state) => state.auth);

  const confirmTokenSchema = Yup.object().shape({
    token: Yup.string().length(4).required(),
  });

  const dispatch = useAppDispatch();
  const { isSubmitting } = useAppSelector((state) => state.transfers);

  function handleOtpChange(val: string) {
    setTransactionPin(val);
    setOtpError('');
  }

  function confirmTransfer() {
    if (bulkTransferPayload !== null) {
      Mixpanel.track('Enter Transaction Pin', {
        first_name: String(fullUser?.firstName),
        last_name: String(fullUser?.lastName),
        phone_number: String(fullUser?.phoneNumber),
        email: String(fullUser?.email),
        transaction_pin: transactionPin,
      });
      dispatch(
        bulkTransferToBank({
          userPin: transactionPin,
          memo: bulkTransferPayload?.memo,
          saveBeneficiaries: bulkTransferPayload?.saveBeneficiaries,
          transferType: bulkTransferPayload?.transferType,
          scheduledDate: bulkTransferPayload?.scheduledDate,
          paymentRequestList,
          onFailure: (error: { data: { message: string; error: string } }) => {
            if (error?.data?.message === 'Incorrect Pin') {
              setOtpError('Incorrect Pin');
            } else {
              setShowConfirmModal(false);
              setShowErrorResponseModal(true);
              setErrMsg(error?.data?.message);
            }
          },
          onSuccess: () => {
            setShowResponseModal(true);
            setShowConfirmModal(false);
          },
        })
      );
    }
  }

  return (
    <>
      <Modal showModal={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pl="30px" pr="30px" pb="10px">
          <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
            Confirm this transaction
          </Text>

          <FullBox style={{ border: '1px dashed #FFCC00', borderRadius: '5px' }} width="100%" p="20px">
            <FullFlex justifyContent="space-between" mb="20px">
              <Text>Bank account</Text>
              <Text>Bulk payments</Text>
            </FullFlex>
            <FullFlex justifyContent="space-between" mb="20px">
              <Text>Amount</Text>
              <Text color="#121212" fontSize="20px" fontWeight="bold">
                {bulkTransferPayload !== null && formatCurrency(bulkTransferPayload?.amount)}
              </Text>
            </FullFlex>
            <FullFlex justifyContent="space-between">
              <Text>Beneficiary</Text>
              <Text>Bulk beneficiaries</Text>
            </FullFlex>
          </FullBox>
          <FullBox mt="40px" mb="30px" width="100%">
            <Formik initialValues={InitialFormValues} onSubmit={() => {}} validationSchema={confirmTokenSchema}>
              <OtpInput
                inputsNumber={4}
                name="transactionPin"
                label="Enter transaction PIN"
                value={transactionPin}
                onChange={(e) => handleOtpChange(e.target.value)}
                error={otpError}
              />
            </Formik>
          </FullBox>

          <FullBox mt="30px" width="100%">
            <FullFlex alignItems="center" mb="10px">
              <NewInfoIcon fontSize="12px" />
              <Text color="#121212" fontSize="12px" ml="10px">
                Submitted payments cannot be recalled
              </Text>
            </FullFlex>
          </FullBox>

          <FullBox width="100%">
            <Button
              onClick={() => {
                confirmTransfer();
              }}
              width="100%"
              variant="xlarge"
              style={{ backgroundColor: '#FFCC00', color: '#121212' }}
              disabled={!!isSubmitting}
            >
              Proceed
            </Button>
          </FullBox>
        </FullFlex>
      </Modal>
      <ResponseModal
        amount={bulkTransferPayload !== null ? bulkTransferPayload?.amount : 0}
        transferType={bulkTransferPayload?.transferType}
        scheduledDate={bulkTransferPayload?.scheduledDate}
        frequency={bulkTransferPayload?.frequency}
        totalBeneficiaries={paymentRequestList.length}
        showResponseModal={showResponseModal}
        setShowResponseModal={setShowResponseModal}
      />
      <ErrorResponseModal
        errorMsg={errMsg}
        showErrorResponseModal={showErrorResponseModal}
        setShowErrorResponseModal={setShowErrorResponseModal}
      />
    </>
  );
}

export default function BulkTransferModal({
  showBulkTransferModal,
  setShowBulkTransferModal,
  amount,
  tableData,
}: {
  showBulkTransferModal: boolean;
  amount: number;
  tableData: Array<BulkTransferUsersData>;
  setShowBulkTransferModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [fee, setFee] = React.useState<number>(0);
  const [memo, setMemo] = React.useState<string>('');
  const [schedule, setSchedule] = React.useState('Immediately');
  const [frequency, setFrequency] = React.useState('Daily');
  const [repeat, setRepeat] = React.useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [date, setDate] = React.useState<string>(moment().format('yyyy-MM-DD'));
  const [timeVal, setTimeVal] = React.useState('');
  const [bulkTransferPayload, setBulkTransferPayload] = React.useState<BulkTransferPayloadData | null>(null);
  const [paymentRequestList, setPaymentRequestList] = React.useState<BulkTransferUsersData[]>([]);
  const [showTimes, setShowTimes] = React.useState(false);

  const dispatch = useAppDispatch();

  const GetServiceCharge = useCallback(() => {
    if (amount < 500) return;

    dispatch(
      getServiceCharge({
        amount,
        isNomba: false,
        onFailure: () => {},
        onSuccess: (value: { data: number; msg: string }) => {
          setFee(value?.data);
        },
      })
    );
  }, [dispatch, amount]);

  useEffect(() => {
    GetServiceCharge();
  }, [GetServiceCharge]);

  function handleTransfer() {
    const datestring = `${date} ${timeVal}`;
    const dateObj = new Date(datestring);
    const userdate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    const usertime = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
    const finalDate = `${userdate} ${usertime}`;

    tableData.forEach((user) => {
      const selectedBank = bankCodes.find((bank) => bank.name === user.bankName);
      user.bankCode = selectedBank?.bankCode;
    });

    const data = {
      amount,
      memo,
      saveBeneficiaries: true,
      transferType: schedule.toUpperCase(),
      scheduledDate: finalDate,
      repeatPayment: repeat,
      frequency,
    };

    setBulkTransferPayload(data);
    setPaymentRequestList(tableData);
  }

  return (
    <>
      <Modal showModal={showBulkTransferModal} onClose={() => setShowBulkTransferModal(false)}>
        <Text textAlign="center" color="#121212" fontSize="24px" fontWeight="bold" mb="10px">
          Bulk Transfer
        </Text>
        <Text textAlign="center" color="#717171" fontSize="14px" fontWeight="regular" mb="30px">
          Send money the way you want
        </Text>

        <FullBox mt="10px" p="10px">
          <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
            <FullBox width="100%" mb="20px">
              <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                <TextInput
                  label="Memo"
                  placeholder="Title for bulk payment"
                  name="memo"
                  value={memo}
                  width="100%"
                  onChange={(e) => {
                    setMemo(e.target.value);
                  }}
                />
              </Formik>

              <FullFlex alignItems="center" mt="10px" mb="10px">
                <NewInfoIcon fontSize="12px" />
                <Text color="#121212" fontSize="12px" ml="10px">
                  This memo is not visible to beneficiary
                </Text>
              </FullFlex>
            </FullBox>

            <FullBox mb="20px" width="100%">
              <Select
                name="schedule"
                label="When do you want to send it?"
                placeholder=""
                options={[
                  { text: 'Immediately', value: 'Immediately' },
                  { text: 'Scheduled', value: 'Scheduled' },
                ]}
                width="100%"
                value={schedule}
                disabled
                onChange={(e) => {
                  e.target.value && setSchedule(e.target.value);
                }}
              />
            </FullBox>

            {schedule === 'Scheduled' && (
              <FullBox mb="20px" width="100%">
                <FullFlex justifyContent="space-between">
                  <FullBox display="flex" flexDirection="column" width="50%" mr="5%">
                    <TimePicker
                      selectedTime={timeVal}
                      setTimeVal={setTimeVal}
                      showTimes={showTimes}
                      setShowTimes={setShowTimes}
                    />
                  </FullBox>

                  <CalendarFilter
                    name="date"
                    label="Date"
                    placeholder="Select date"
                    value={date}
                    onChange={(value) => {
                      setDate(value);
                    }}
                    dateFormat="yyyy-MM-DD"
                    width="50%"
                  />
                </FullFlex>
              </FullBox>
            )}

            <FullBox mb="20px" width="100%" style={{ display: 'none' }}>
              <Checkbox name="bulkRepeat" checked={repeat} onChange={() => setRepeat(!repeat)}>
                Repeat payment
              </Checkbox>
            </FullBox>
            {repeat && (
              <FullBox mb="20px" width="100%">
                <Select
                  name="frequency"
                  label="Frequency"
                  placeholder=""
                  options={[
                    { text: 'Daily', value: 'Daily' },
                    { text: 'Weekly', value: 'Weekly' },
                    { text: 'Monthly', value: 'Monthly' },
                  ]}
                  width="100%"
                  value={frequency}
                  onChange={(e) => {
                    e.target.value && setFrequency(e.target.value);
                  }}
                />
              </FullBox>
            )}

            <FullBox width="100%" mt="30px">
              <FullFlex alignItems="center" mb="10px">
                <NewInfoIcon fontSize="12px" />
                <Text color="#121212" fontSize="12px" ml="10px">
                  You will be charged {formatCurrency(fee)} for this transaction
                </Text>
              </FullFlex>

              <Button
                width="100%"
                variant="xlarge"
                style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                onClick={() => {
                  handleTransfer();
                  setShowConfirmModal(!showConfirmModal);
                  setShowBulkTransferModal(false);
                }}
              >
                Next
              </Button>
            </FullBox>
          </FullFlex>
        </FullBox>
      </Modal>
      {bulkTransferPayload !== null && (
        <ConfirmTransactionModal
          bulkTransferPayload={bulkTransferPayload}
          paymentRequestList={paymentRequestList}
          showConfirmModal={showConfirmModal}
          setShowConfirmModal={setShowConfirmModal}
        />
      )}
    </>
  );
}
