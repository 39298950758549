import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Pos = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M10 5.246a7.6 7.6 0 0 0-7.6 7.6.83.83 0 0 0 .83.83h13.54a.83.83 0 0 0 .83-.83 7.6 7.6 0 0 0-7.6-7.6Zm-5.94 1.66a8.4 8.4 0 0 1 14.34 5.94 1.63 1.63 0 0 1-1.63 1.63H3.23a1.63 1.63 0 0 1-1.63-1.63 8.4 8.4 0 0 1 2.46-5.94Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M10 2.6c.22 0 .4.18.4.4v1.846a.4.4 0 0 1-.8 0V3c0-.22.18-.4.4-.4ZM1.6 16.538a.4.4 0 0 1 .4-.4h16a.4.4 0 1 1 0 .8H2a.4.4 0 0 1-.4-.4Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Pos;
