import { Button } from 'kudi-component-library';
import { useRef } from 'react';
import useRedux from '../../../../hooks/useRedux';
import Header from '../base/header';
import { IssueListButtonWrapper, IssueListWrapper, SupportPageDetailsWrapper } from '../../styles';
import { updateCurrentPage } from '../../reducer/slice';
import useMyIssueModel from '../../models/myIssueModel';
import Filter from '../base/filter';
import { issueFilterConfig } from '../../constants';
import IssueListItem from '../base/issue_list_item';
import EmptyIssueList from './empty_issue_list';
import PageLoad from '../../../../components/PageLoad';
import { GetAllCategories } from '../../reducer/thunk_actions';
import { useToast } from '../../../../hooks/useToast';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';

const MyIssues = () => {
  const ref = useRef(null);

  const toast = useToast(7000);

  const {
    dispatch,
    state: {
      support: {
        issueCreation: { isFetching },
        issueList: {
          showFilter,
          isLoading,
          issues,

          filters,
        },
      },
    },
  } = useRedux();

  const { onRefetch, toggleShow, onClearAll, onApply, FetchAllIssues } = useMyIssueModel();

  const createIssue = () => {
    dispatch(
      GetAllCategories({
        onSuccess: () => {
          onClearAll();
          dispatch(updateCurrentPage('issue-creation'));
        },
        onFailure: (err: { response: { message: string } }) => {
          toast('error', err?.response?.message || 'Something went wrong');
        },
      })
    );
  };

  const goBack = () => {
    onClearAll();
    dispatch(updateCurrentPage('home'));
  };

  useInfiniteScroll(ref, () => FetchAllIssues());

  return (
    <>
      <Header
        desktopTitle="Report an issue"
        desktopDescription="Select how you would want us to help you"
        desktopCloseAction={goBack}
        mobileBackAction={goBack}
      />

      <SupportPageDetailsWrapper>
        <IssueListWrapper>
          <Filter
            showFilter={showFilter}
            onRefetch={onRefetch}
            toggleShow={toggleShow}
            onClearAll={onClearAll}
            onApply={onApply}
            config={issueFilterConfig}
            filterCount={Object.keys(filters).length}
            disabled={isLoading}
          />
          {isLoading ? null : issues?.map((item) => <IssueListItem key={`issuee completed ${item.id}`} data={item} />)}
          {!isLoading && issues.length === 0 ? (
            <EmptyIssueList action={createIssue} loading={isFetching} title="completed" />
          ) : null}
          {isLoading ? <PageLoad /> : <div ref={ref} />}
        </IssueListWrapper>
        {isLoading ? null : (
          <IssueListButtonWrapper>
            <Button loading={isFetching} disabled={isFetching} onClick={createIssue} width="100%" variant="medium">
              Submit Issue
            </Button>
          </IssueListButtonWrapper>
        )}
      </SupportPageDetailsWrapper>
    </>
  );
};

export default MyIssues;
