import { Product } from '../../../types/inventory/products';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface ProductsResponse extends ApiResponse {
  data: {
    list: Product[];
    pageToken: string;
  };
}

const fetchProducts = async ({ params }: { params?: URLSearchParams }) => {
  const url = `/webbe/v1/storehouse/items/filter?${params ? params.toString() : ''}`;
  try {
    const result: ProductsResponse = await axios.get(url);
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchProducts;
