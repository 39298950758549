import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { BusinessTransactionData, newTransactionDataExample } from '../../types/transactions';
import { PendingTransactionData } from '../../types/pendingTransactions';

// ---------------------------   GET ALL BUSINESS TRANSACTIONS START   ---------------------------//
// export interface BusinessTransactionData {
//   type: string;
//   amount: number;
//   time: Date;
// }

export interface BusinessTransactionResponse extends ApiResponse {
  data?: { data: Array<BusinessTransactionData> };
  dataList?: Array<BusinessTransactionData>;
  meta?: null;
}

interface BusinessTransactionArgs extends ReduxAction {
  id: number | string;
  filters: {
    startDate: Date;
    endDate: Date;
    limit?: number;
  };
}

export const businessTransaction = createAsyncThunk(
  '/balance/business-transaction',
  async (
    { id, filters: { startDate, endDate }, onSuccess, onFailure }: BusinessTransactionArgs,
    { rejectWithValue }
  ) => {
    try {
      const { data }: BusinessTransactionResponse = await axios.post(`/business/get-new-transactions`, {
        businessId: id,
        startDate,
        endDate,
      });
      if (onSuccess) onSuccess({});

      return data?.data || [];
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   GET ALL BUSINESS TRANSACTIONS END   ---------------------------//

// ---------------------------   GET REPOPRT START   ---------------------------//

export interface GetReportResponse extends ApiResponse {
  data?: string;
  dataList: null;
  meta: null;
}

export const getReport = createAsyncThunk(
  '/balance/initiate-withdrawal',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: GetReportResponse = await axios.get(`/business/export-transaction-csv`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   GET REPOPRT END   ---------------------------//

// ---------------------------   PLACE ACCOUNT ON PND START   ---------------------------//

export interface PlaceAccountOnPNDArgs extends ReduxAction {
  email: string;
  token: string;
}

export const placeAccountOnPND = createAsyncThunk(
  '/user/place-on-pnd',
  async ({ email, token, onSuccess, onFailure }: PlaceAccountOnPNDArgs, { rejectWithValue }) => {
    try {
      const response: ApiResponse = await axios.put(`/user/place-on-pnd?token=${token}&email=${email}`);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message || error?.message);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   PLACE ACCOUNT ON PND END   ---------------------------//
