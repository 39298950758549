import moment from 'moment';
import { Badge } from 'kudi-component-library';
import { IssueListItemButtom, IssueListItemTop, IssueListItemWrapper } from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import { selectIssueItem, updateCurrentPage } from '../../reducer/slice';
import { FetchedIssue, IssueListFilterStatus } from '../../defs';

const IssueListItem = ({ data }: { data?: FetchedIssue }) => {
  const { dispatch } = useRedux();

  const handleClick = () => {
    dispatch(selectIssueItem(data || ({} as FetchedIssue)));
    dispatch(updateCurrentPage('details'));
  };

  const variant = data?.status === IssueListFilterStatus.RESOLVED ? 'default' : 'success';

  return (
    <IssueListItemWrapper onClick={handleClick}>
      <IssueListItemTop>
        <p>{data?.description}</p>
        <Badge variant={variant}>{data?.status === IssueListFilterStatus.RESOLVED ? 'Resolved' : 'Active'}</Badge>
      </IssueListItemTop>
      <IssueListItemButtom>
        <p>{moment(data?.timeCreated).format('MMM DD,YYYY')}</p> <p>{data?.meta.type}</p>
      </IssueListItemButtom>
    </IssueListItemWrapper>
  );
};

export default IssueListItem;
