/* eslint-disable no-restricted-globals */
import useIssueDetailsFromNotification from '../pages/support/models/issueDetailsFromNotification';
import { nmPostLoginAction } from '../utils/constants';

export type NotifiicationAction = 'issue' | 'transaction';

const actions: NotifiicationAction[] = ['issue', 'transaction'];

export const ExtractActionFromNotification = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const found: string[] = [];
  let value = '';

  actions.map((item) => {
    if (urlParams.get(item)) {
      found.push(item);
      value = urlParams.get(item) || '';
    }

    return item;
  });

  if (found.length === 1) {
    localStorage.setItem(
      nmPostLoginAction,
      JSON.stringify({
        action: found[0],
        value,
      })
    );
  }

  if (found.length > 0) {
    const currentUrl = window.location.href;

    // Remove the query string from the URL
    const newUrl = currentUrl.split('?')[0];

    // Change the URL without triggering a page refresh
    history.pushState({}, null, newUrl);
  }
};

export const usePerformActionFromQuery = () => {
  const action = localStorage.getItem(nmPostLoginAction);

  const { SelectIssue } = useIssueDetailsFromNotification();

  const triggerAction = () => {
    if (!action) return;

    const actionObject = JSON.parse(action) as { action: NotifiicationAction; value: string };

    switch (true) {
      case actionObject.action === 'issue':
        SelectIssue(actionObject.value);
        break;

      default:
        break;
    }

    localStorage.removeItem(nmPostLoginAction);
  };

  return { triggerAction };
};
