import React, { FunctionComponent } from 'react';
import { TopRightWrapper, TopRigtSmWrapper } from './components';

interface RenderTopRightProps {
  Component?: FunctionComponent;
  ComponentSm?: FunctionComponent;
}

const RenderTopRight = ({ Component, ComponentSm }: RenderTopRightProps) => (
  <div>
    <TopRightWrapper>{Component && <Component />}</TopRightWrapper>
    <TopRigtSmWrapper>{ComponentSm && <ComponentSm />}</TopRigtSmWrapper>
  </div>
);

export default RenderTopRight;
