import React, { FunctionComponent, ReactElement, useState, useRef, ReactNode } from 'react';
import { Pagination as ComponentLibraryPagination, NIcons, IconsEnum } from 'kudi-component-library';
import { PaginationProps } from 'kudi-component-library/dist/components/Pagination/type';
import ShortText from '../ShortText';
import { formatCurrency, formatFixedCharge, formatTableDateValue } from '../../utils';
import useIsMobile from '../../hooks/useIsMobile';

import {
  TableWrapper,
  Table,
  MobileTableList,
  MobileTableItemCover,
  MobileTableLoadingWrapper,
  Td,
  Th,
  IconsWrapper,
  SortColumnWrapper,
} from './styles';
import Pagination from './components/pagination';
import TransactionInfo from '../TransactionInfo';

// eslint-disable-next-line import/no-cycle
import MobileTableItem from './components/mobileTableItem';
import Skeleton from '../skeleton';
import { BusinessTransactionData } from '../../types/transactions';
import { LoanHistory } from '../../types/loanProvider';
import MoreActions from './components/MoreActions';
import { ActionsProp, SortOrder } from './defs';
import { NetworkErrorState, NoDataSwitcher } from './state';
import { Separator } from '../../styles/generic';

export interface Data {
  [x: string]: string | number | boolean | null;
}

export interface Functions {
  [x: string]: (value: Data) => void;
}

export interface Schema {
  visible?: boolean;
  accessor?: string | null;
  text?: string | null;
  render?: (value: any, functions?: Functions) => FunctionComponent | ReactElement | string | null;
  isDate?: boolean;
  isCurrency?: boolean;
  isFixedCharge?: boolean;
  isCheckbox?: boolean;
  isSerialNumber?: boolean;
  cell?: (id: number | Record<string, any> | any) => FunctionComponent | ReactElement | null;
  header?: () => FunctionComponent | ReactElement | ReactNode | null;
  hasCopyIcon?: boolean;
  entryPadding?: string;
  headerPadding?: string;
  verticalAlign?: 'inherit' | 'top';
  sortable?: boolean;
  sortValue?: string;
  id?: string;
}

interface TableMobileOptions {
  accessor?: string;
  render?: (value: any, functions?: Functions) => FunctionComponent | ReactElement | ReactNode | null;
  isDate?: boolean;
}

export interface MobileSchema {
  lt?: TableMobileOptions;
  rt?: TableMobileOptions;
  lb?: TableMobileOptions;
  rb?: TableMobileOptions;
  mb?: TableMobileOptions;
}

interface TableProps {
  onPaginationChange?: (value: { page?: number | null; pageSize?: number | null }) => void;
  page?: number;
  pageSize?: number;
  total?: number;
  schema?: Schema[];
  mobileSchema?: MobileSchema;
  data?: Record<string, any>[] | null;
  onRowCLick?: (value: any) => void;
  handleSort?: (sortType: SortOrder, sortValue: string) => void;
  functions?: Functions;
  isPaginated?: boolean; // oldPaginationComponent
  isLoading?: boolean;
  emptyText?: string;
  displayViewIcon?: boolean;
  EmptyState?: ReactNode;
  ErrorState?: boolean;
  isTransaction?: boolean;
  isHistory?: boolean;
  flatBottom?: boolean;
  isTerminalTransaction?: boolean;
  isNewTransaction?: boolean;
  actions?: ActionsProp[];
  mobileDetailsSchema?: Schema[];
  gridActions?: boolean;
  paginationProps?: PaginationProps; // from component lib
  viewMore?: boolean;
  darkBackgroundHeader?: boolean;
  grayBackgroundHeader?: boolean;
  component?: ReactNode;
}

// Table wrapper to fix table min width issue

const TableGen = ({
  onPaginationChange,
  page,
  pageSize = 10,
  total,
  data,
  schema,
  onRowCLick,
  functions = {},
  mobileSchema,
  isPaginated,
  isLoading,
  emptyText,
  EmptyState,
  ErrorState = false,
  isTransaction,
  isTerminalTransaction,
  isHistory,
  flatBottom,
  isNewTransaction,
  actions,
  mobileDetailsSchema,
  paginationProps,
  viewMore,
  gridActions,
  darkBackgroundHeader,
  grayBackgroundHeader,
  handleSort,
  component,
}: TableProps) => {
  const [selectedTransaction, setSelectedTransaction] = useState<BusinessTransactionData | null>(null);
  const [sortOrderType, setSortOrderType] = useState<SortOrder>('desc');

  const myRef = useRef(null) as React.RefObject<HTMLDivElement>;
  const isMobile = useIsMobile(1363);

  const scrollToRef = () => {
    myRef.current?.scrollIntoView({
      block: 'start',
      inline: 'start',
      behavior: 'smooth',
    });
  };

  const sortHandler = (sortValue: string) => {
    const order = sortOrderType === 'asc' ? 'desc' : 'asc';
    setSortOrderType(order);
    handleSort?.(order, sortValue);
  };

  const tableSchema = Array.isArray(schema) && actions ? [...schema, { text: 'More', accessor: 'more' }] : schema;

  return (
    <>
      <TableWrapper
        ref={myRef}
        className="table-layout-content"
        flatBottom={flatBottom}
        hasActions={!!actions}
        darkBackgroundHeader={darkBackgroundHeader}
      >
        <Table
          hasActions={!!actions}
          darkBackgroundHeader={darkBackgroundHeader}
          grayBackgroundHeader={grayBackgroundHeader}
        >
          <thead>
            <tr>
              {data &&
                data.length > 0 &&
                !isLoading &&
                tableSchema &&
                tableSchema.map((item) => {
                  if (item.isCheckbox && item.header) {
                    return (
                      <Th padding={item?.headerPadding} key={`heading-${Math.random()}`} id={item.id}>
                        {item.header() as ReactNode}
                      </Th>
                    );
                  }
                  if (item.isSerialNumber) {
                    return (
                      <Th padding={item?.headerPadding} key={`heading-sn-${Math.random()}`} id={item.id}>
                        S/N
                      </Th>
                    );
                  }
                  if (item.sortable) {
                    return (
                      <Th padding={item?.headerPadding} key={`heading-${Math.random()}`} id={item.id}>
                        <SortColumnWrapper>
                          <span> {item.text}</span>
                          <IconsWrapper role="button" onClick={() => sortHandler(item?.sortValue as string)}>
                            <NIcons
                              icon={sortOrderType === 'asc' ? IconsEnum.OUTLINE_SORT_UP : IconsEnum.OUTLINE_SORT_DOWN}
                            />
                          </IconsWrapper>
                        </SortColumnWrapper>
                      </Th>
                    );
                  }
                  return (
                    <Th padding={item?.headerPadding} key={`heading-${Math.random()}`} id={item.id}>
                      {item.text}
                    </Th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              !isLoading &&
              data.map((rowData: Record<string, any>, rowIndex) => (
                <tr
                  style={{
                    cursor:
                      !!onRowCLick || isTransaction || isTerminalTransaction || isNewTransaction
                        ? 'pointer'
                        : 'default',
                  }}
                  key={`table-row-${rowIndex}`}
                  onClick={() => {
                    if (isTransaction) {
                      setSelectedTransaction(rowData as BusinessTransactionData);
                    }
                  }}
                >
                  {tableSchema &&
                    tableSchema.map((item, index) => {
                      if (item.isCheckbox && item.cell) {
                        return (
                          <Td
                            padding={item?.entryPadding}
                            verticalAlign={item.verticalAlign || 'inherit'}
                            key={`table-row-${rowIndex}-data-${index}`}
                          >
                            {item.cell(rowData) as ReactNode}
                          </Td>
                        );
                      }
                      if (item.isSerialNumber) {
                        return (
                          <Td
                            padding={item?.entryPadding}
                            verticalAlign={item.verticalAlign || 'inherit'}
                            key={`table-row-${rowIndex}-data-${Math.random()}`}
                          >
                            {rowIndex + 1}
                          </Td>
                        );
                      }
                      return (
                        <Td
                          key={`table-row-${rowIndex}-data-${index}`}
                          role="gridcell"
                          onClick={() => {
                            if (onRowCLick && !item.hasCopyIcon) {
                              onRowCLick(rowData);
                            }
                          }}
                          padding={item?.entryPadding}
                          verticalAlign={item.verticalAlign || 'inherit'}
                        >
                          {!item.render && !item.isDate && !item.isCurrency && !item.isFixedCharge && item?.accessor ? (
                            <ShortText
                              text={rowData[item?.accessor] as string}
                              hasToolTip={isMobile}
                              withCopy={false}
                            />
                          ) : null}
                          {!item.render && item.isDate && !item.isCurrency && !item.isFixedCharge && item?.accessor ? (
                            <ShortText
                              text={formatTableDateValue(rowData[item?.accessor] as Date)}
                              hasToolTip={isMobile}
                              length={22}
                              withCopy={false}
                            />
                          ) : null}
                          {!item.render && !item.isDate && !item.isFixedCharge && item.isCurrency && item?.accessor
                            ? formatCurrency((rowData[item?.accessor] as string) || '0')
                            : null}
                          {!item.render &&
                            !item.isDate &&
                            item.isFixedCharge &&
                            item?.accessor &&
                            formatFixedCharge((rowData[item?.accessor] as string) || '0')}
                          {item.render ? (item.render(rowData, functions) as ReactNode) : null}
                          {item?.accessor === 'more' && actions ? (
                            <MoreActions actions={actions} rowData={rowData} viewMore={viewMore} />
                          ) : null}
                        </Td>
                      );
                    })}
                </tr>
              ))}
            {isLoading
              ? Array(pageSize)
                  .fill({})
                  .map(() => (
                    <tr key={`wide_table_skeleton_row_${Math.random()}`}>
                      {tableSchema &&
                        tableSchema.map(() => (
                          <td key={`wide_table_skeleton_rowItem_${Math.random()}`} aria-label="table-data">
                            <Skeleton key={`mobile-skeleton-${Math.random()}`} width="100%" height="3rem" />
                          </td>
                        ))}
                    </tr>
                  ))
              : null}
          </tbody>
        </Table>
        <MobileTableList flatBottom={flatBottom}>
          {data &&
            data.length > 0 &&
            !isLoading &&
            data.map((rowData, rowIndex) => (
              <MobileTableItemCover
                key={`table-row-${rowIndex}`}
                onClick={() => {
                  if (onRowCLick && isHistory) {
                    onRowCLick(rowData as LoanHistory);
                  }
                  if (onRowCLick) {
                    onRowCLick(rowData);
                  }
                  if (isTransaction) {
                    setSelectedTransaction(rowData as BusinessTransactionData);
                  }
                }}
              >
                <MobileTableItem
                  {...{ onRowCLick, functions, rowData }}
                  schema={mobileSchema}
                  mobileDetailsSchema={mobileDetailsSchema}
                  actions={actions}
                  gridActions={gridActions}
                  key={`mobile-table-item-${Math.random()}`}
                />
              </MobileTableItemCover>
            ))}
          {isLoading ? (
            <MobileTableLoadingWrapper>
              {Array(pageSize)
                .fill({})
                .map(() => (
                  <Skeleton key={`skeleton-${Math.random()}`} width="100%" height="3rem" />
                ))}
            </MobileTableLoadingWrapper>
          ) : null}
        </MobileTableList>
        {data?.length === 0 && !isLoading && ErrorState === false
          ? EmptyState || <NoDataSwitcher page={page} message={emptyText} />
          : null}

        {ErrorState && !isLoading ? <NetworkErrorState /> : null}

        {component || null}

        {isPaginated ? (
          <Pagination
            onPaginationChange={(value) => {
              scrollToRef();
              onPaginationChange?.(value);
            }}
            page={page}
            pageSize={pageSize}
            total={total}
          />
        ) : null}
        {selectedTransaction ? (
          <TransactionInfo onClose={() => setSelectedTransaction(null)} data={selectedTransaction} />
        ) : null}
      </TableWrapper>
      {paginationProps && (
        <>
          <Separator height="2rem" />
          <ComponentLibraryPagination isLoading={paginationProps?.isLoading || isLoading} {...paginationProps} />
        </>
      )}
    </>
  );
};

export default TableGen;
