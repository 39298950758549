import { ReactNode } from 'react';
import { ActionSectionWrapper, ComponentWrapper, StateMessage, TableStateWrapper } from './styles';

interface TableStateProps {
  icon: ReactNode | JSX.Element;
  message: string;
  actionSection?: ReactNode | JSX.Element;
  messageWidth?: string;
}
export default function TableStateComponent({ icon, message, actionSection, messageWidth }: TableStateProps) {
  return (
    <ComponentWrapper>
      <TableStateWrapper>
        {icon}
        <StateMessage variant="n-text3" width={messageWidth}>
          {message}
        </StateMessage>
        {actionSection ? <ActionSectionWrapper>{actionSection}</ActionSectionWrapper> : null}
      </TableStateWrapper>
    </ComponentWrapper>
  );
}
