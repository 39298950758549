/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import loader from './loader';
import { LoginUser } from '../types/auth';
import { ErrorResponseType } from '../types/error';

// eslint-disable-next-line no-shadow
enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  ResourceNotFound = 417,
}
const headers: Readonly<Record<string, string | boolean>> = {
  Accept: '*/*',
};
// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    loader.show();
    const token: string | null = localStorage.getItem('token');
    const IAMtoken: string | null = localStorage.getItem('IAMtoken');
    const parsedIAMtoken: string | null = IAMtoken === '' ? '' : JSON.parse(IAMtoken as string);
    const appData: string | null = localStorage.getItem('appData');
    const parsedAppData: LoginUser | null = appData ? JSON.parse(appData) : null;
    const deviceInfoString: string | null = localStorage.getItem('deviceInfoString');

    if (token != null && parsedAppData) {
      const parsedToken: string = JSON.parse(token);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      config.headers = {
        Authorization: `Bearer ${parsedToken}`,
        businessId: String(parsedAppData.parentBusinessId), // added for web transfers
        ...config.headers,
      };
    }

    if (parsedIAMtoken !== '' && parsedIAMtoken !== null) {
      config.headers = {
        ...config.headers,
        'IAM-Authorization': `Bearer ${parsedIAMtoken}`,
      };
    }

    if (deviceInfoString) {
      config.headers = {
        ...config.headers,
        deviceInfo: deviceInfoString, // added for reset password
      };
    }

    return config;
  } catch (error) {
    throw new Error(error as string);
  }
};

const injectWebbeHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const appData: string | null = localStorage.getItem('appData');
    const parsedAppData: LoginUser | null = appData ? JSON.parse(appData) : null;
    if (config?.headers) {
      const hasBusinessId = 'businessId' in config.headers;
      if (!hasBusinessId && parsedAppData) {
        config.headers = {
          ...config.headers,
          businessId: String(parsedAppData.parentBusinessId),
        };
      }
    }
    return config;
  } catch (error) {
    throw new Error(error as string);
  }
};

class HttpConfig {
  private serviceBaseUrl: string;

  private useWebbeHeaders?: boolean;

  constructor(url: string, useWebbeHeaders?: boolean) {
    this.serviceBaseUrl = url;
    this.useWebbeHeaders = useWebbeHeaders;
  }

  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const localInstance = axios.create({
      baseURL: this.serviceBaseUrl,
      headers,
    });
    localInstance.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    if (this.useWebbeHeaders) {
      localInstance.interceptors.request.use(injectWebbeHeaders, (error) => Promise.reject(error));
    }

    localInstance.interceptors.response.use(
      (response) => {
        loader.hide();
        if (response.headers['content-type'] === 'text/csv') {
          return response.data as AxiosResponse;
        }
        const data = { ...response.data, headers: response.headers, status: response.status } as AxiosResponse;
        return data;
      },
      (error: AxiosError) => {
        const responseData = error;
        loader.hide();
        return this.handleError(responseData);
      }
    );
    this.instance = localInstance;
    return localInstance;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }
  // Handle global app errors
  // We can handle generic app errors depending on the status code
  // eslint-disable-next-line class-methods-use-this

  // eslint-disable-next-line class-methods-use-this
  private handleError(error: AxiosError) {
    switch (error?.response?.status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }

      case StatusCode.Unauthorized: {
        // Handle Unauthorized / timed-out users
        if ((error?.response as ErrorResponseType).data.message?.toLowerCase() === 'session timeout') {
          window.location.href = '/auth/login';
        }
        break;
      }

      case StatusCode.ResourceNotFound: {
        window.location.href = '/auth/login';
        // Handle Unauthorized
        break;
      }

      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      default:
        break;
    }
    return Promise.reject(error.response);
  }
}

export default HttpConfig;
