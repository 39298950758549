import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppEnumList, currentEnumList } from '../../types/enumTypes';
import {
  verifyCredentials,
  resetPassword,
  resetPin,
  validateToken,
  uploadBusinessLogo,
  fecthBusinessLogo,
  fetcAppEnumList,
} from './thunkActions';

interface InitialState {
  isLoading: boolean;
  inProgress: boolean;
  businessImageUrl: string;
  appEnumList: AppEnumList;
}

const initialState: InitialState = {
  isLoading: false,
  inProgress: false,
  businessImageUrl: '',
  appEnumList: currentEnumList,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBusinessImageUrl: (state, action: PayloadAction<{ businesImageUrl: string | '' }>) => {
      state.businessImageUrl = action.payload.businesImageUrl;
    },
  },
  extraReducers: (builder) => {
    // // verify user credentials (password and PIN)
    builder
      .addCase(verifyCredentials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyCredentials.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(verifyCredentials.fulfilled, (state) => {
        state.isLoading = false;
      });

    // reset password
    builder
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoading = false;
      });

    // reset pin
    builder
      .addCase(resetPin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPin.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(resetPin.fulfilled, (state) => {
        state.isLoading = false;
      });

    // validate token
    builder
      .addCase(validateToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateToken.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(validateToken.fulfilled, (state) => {
        state.isLoading = false;
      });

    // upload business logo
    builder
      .addCase(uploadBusinessLogo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadBusinessLogo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadBusinessLogo.fulfilled, (state) => {
        state.isLoading = false;
      });

    // fetch business logo
    builder
      .addCase(fecthBusinessLogo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fecthBusinessLogo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fecthBusinessLogo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.businessImageUrl = action.payload || '';
      });

    // fetch app enum list

    builder
      .addCase(fetcAppEnumList.pending, () => {})
      .addCase(fetcAppEnumList.rejected, () => {})
      .addCase(fetcAppEnumList.fulfilled, (state, action) => {
        state.appEnumList = action.payload?.data as typeof currentEnumList;
      });
  },
});

export const { setBusinessImageUrl } = settingsSlice.actions;
export default settingsSlice.reducer;
export * from './thunkActions';
