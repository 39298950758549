import styled, { css } from 'styled-components';
import { convertHexToRGBA } from '../../utils/styles';

// ========================     MOBILE SECTION    ========================//
export const SupportPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100vw;
  height: 100svh;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  overflow-y: auto;
  padding-top: 60px;
  @media ${({ theme }) => theme.media.md} {
    padding-top: 0;
    background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'], 0.25)};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }

  @media ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

export const BackAction = styled.div`
  position: absolute;
  left: 22px;
`;

export const MobileHero = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.nColors['n-light']};
  margin-bottom: 40px;
  padding: 46px;
  @media ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

export const MobileHeroWelcomeText = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: center;
`;

export const MobileHeroWelcomeQuestion = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.nColors['n-yellow']};
`;

export const SupportPageContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 5px 5px 0 0;
  height: fit-content;
  position: relative;

  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  @media ${({ theme }) => theme.media.md} {
    opacity: 1;
    width: 75%;
    max-width: 540px;
    margin: 0 0 0 auto;
    border-radius: 0;
    background-color: ${({ theme }) => theme.nColors['n-light']};

    animation-name: slideIn;
    -webkit-animation-name: slideIn;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  @media ${({ theme }) => theme.media.lg} {
    width: 50%;
  }
`;

export const SupportPageDetailsWrapper = styled.div<{ top?: string; mobilePadding?: string; mobileBg?: string }>`
  width: 100%;
  padding: ${({ mobilePadding }) => mobilePadding || '0'};
  background-color: ${({ mobileBg }) => mobileBg || 'transparent'};
  overflow-y: auto;
  @media ${({ theme }) => theme.media.md} {
    display: flex;
    flex-direction: column;
    height: ${({ top }) => ` calc(100svh - ${top || '118px'})`};
    overflow-y: auto;
    width: 100%;
    padding: 0;
    background-color: transparent;
  }
`;

export const MobileSectionTitle = styled.div`
  display: flex;
`;

export const SectionHeader = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.media.md} {
    margin-left: 30px;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const IssueOptionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  @media ${({ theme }) => theme.media.md} {
    padding: 20px 30px;
  }
`;

export const IssueOptionBodyWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  :hover {
    padding: 0 1px;
  }
`;

export const IssueOption = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const IssueOptionArrowWrapper = styled.div<{ open?: boolean }>`
  svg {
    transform: rotate(${({ open }) => (open ? '90' : '0')}deg);
  }
`;

export const IssueOptionLeft = styled.div`
  display: flex;
  align-items: center;

  p {
    display: flex;
    flex-direction: column;
    margin-left: 12.5px;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: ${({ theme }) => theme.nColors['n-dark']};
    }

    .details {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: ${({ theme }) => theme.nColors['n-grey2a']};
      margin-top: 6px;
    }
  }
`;

export const IssueOptionInconWrapper = styled.div<{ iconBg?: boolean }>`
  position: relative;

  ${({ iconBg }) =>
    iconBg
      ? css`
          background-color: ${({ theme }) => theme.nColors['n-yellow1']};
          width: 35px;
          height: 35px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            scale: 0.8;
          }
        `
      : ''}

  div {
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: ${({ theme }) => theme.nColors['n-yellow']};
    border-radius: 50%;
    top: -4px;
    right: -4px;
  }
`;

export const IssueOptionExtra = styled.p`
  margin-left: 34px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 6px;
  color: ${({ theme }) => theme.nColors['n-grey4']};
`;

export const OfficeItemWrapper = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  background-color: ${({ theme }) => theme.nColors['n-grey0']};
  border-radius: 5px;
  text-decoration: none;
`;

export const SupportIconButtonWrapper = styled.div`
  cursor: pointer;
`;

export const HeaderDesktop = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.md} {
    padding: 40px 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const HeaderDesktopClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 50%;
  cursor: pointer;

  svg {
    transform: scale(0.8);
  }

  :hover {
    transform: scale(1.1);
  }
`;

export const HeaderDesktopMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderDesktopExtra = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderDesktopBackWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Clickable = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  gap: 20px;
  @media ${({ theme }) => theme.media.md} {
    justify-content: flex-start;
  }
`;

export const SocialMediaIconWrapper = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.nColors['n-yellow1']};

  :hover {
    svg {
      transform: scale(1.1);
    }
  }
`;

// ----------------------- HOME -----------------------------//
export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
  @media ${({ theme }) => theme.media.md} {
    margin-top: 20px;
  }
`;

// ----------------------- OFFICE ADDRESS ---------------------- //

export const OfficeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  padding: 20px;
`;

// --------------------------- ISSUES TAB -----------------------//

export const IssuesTabWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  padding: 0 20px;
  border-top: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
`;

export const IssuesTabItem = styled.div`
  width: 50%;
  padding: 14px;
  display: flex;
  justify-content: center;

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.nColors['n-grey3']};
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.nColors['n-dark']};
    border-bottom: 3px solid ${({ theme }) => theme.nColors['n-yellow']};
  }
`;

export const EmptyIssueListWraper = styled.div`
  margin: 20px;
  height: calc(100svh - 154px);
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  padding: 160px 33px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: ${({ theme }) => theme.nColors['n-grey4']};
  }
`;

export const IssueListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 20px 150px;
  height: calc(100svh - 154px);
  gap: 10px;
  overflow-y: auto;

  position: relative;
`;

export const IssueListButtonWrapper = styled.div`
  position: absolute;
  padding: 20px 0 40px;
  bottom: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(8px);
  button {
    margin-left: 30px;
    width: calc(100% - 60px);
  }
`;

// ----------------------- ISSUE LIST ITEM -----------------------//

export const IssueListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  padding: 14px 21px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-grey1']};

  :hover {
    background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'].trim(), 0.03)};
    border-radius: 5px;
  }
`;

export const IssueListItemTop = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
  }
`;

export const IssueListItemButtom = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.nColors['n-grey4']};
  }
`;

// --------------------- ISSUE DETAILS -------------------------//
export const IssueDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  padding: 20px;
  gap: 20px;
`;

export const IssueDetailsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const IssueDetailsInfoWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 20px 20px 30px;
  background-color: ${({ theme }) => theme.nColors['n-grey0']};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const IssueDetailsInfoCompletedItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.nColors['n-grey5']};
  }
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const IssueDetailsInfoActiveItem = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 71px;
  position: relative;
  border-left: 1px dashed ${({ theme }) => theme.nColors['n-grey3a']};
  margin-left: 8px;

  .title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 24px;
    margin-bottom: 5px;
  }

  .description {
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.nColors['n-grey3a']};
    margin-left: 24px;
  }
  label {
    width: fit-content;
  }
  .checkWrapper {
    position: absolute;
    top: -5px;
    left: -10px;
    padding: 5px 0 5px;
    background-color: ${({ theme }) => theme.nColors['n-grey0']};
  }
`;

// Active comments

export const ActiveComments = styled.ul`
  list-style-type: disc;
  padding-left: 20px;

  .bulletPoints {
    color: ${({ theme }) => theme.nColors['n-dark']};
  }

  li {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.nColors['n-grey3a']};
    font-size: 12px;
    margin-bottom: 5px;
  }

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
    font-size: 14px;
  }
`;

// --------------------- SUMMARY -------------------------//
export const SummaryDetailsInfoWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 20px 30px 30px;
  background-color: ${({ theme }) => theme.nColors['n-grey0']};
  display: flex;
  flex-direction: column;
`;

export const MainDetailsInfoWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 20px 30px 30px;
  display: flex;
  flex-direction: column;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LeftText = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin: 10px 30px 10px 0;
  white-space: nowrap;
`;

export const RightText = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0;
  max-width: 250px;
  line-break: anywhere;
`;

export const SelectWrapper = styled.div`
  margin-top: 20px;
`;

export const TextWrapper = styled.div`
  margin-top: 20px;
`;

export const DocumentWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 60px;
`;

// ------------------------- ISSUE CREATION ------------------------------//

export const IssueCreationWrapper = styled.div`
  height: calc(100svh - 103px);
  @media ${({ theme }) => theme.media.md} {
    padding: 0 30px;
  }
`;

export const IssueItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const IssueTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SubIssuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  gap: 20px;
`;
// -------------------------- STEPS -------------------------------------//

export const StepsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 7.5px;
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  width: fit-content;

  span {
    margin-right: 10px;
    width: 20px;
  }

  svg {
    path {
      stroke: ${({ theme }) => theme.nColors['n-yellow']};
    }
  }
`;

// --------------------------- FILTER -----------------------------//
export const FilterWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  padding: 14px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme, disabled }) => theme.nColors[disabled ? 'n-grey1' : 'n-light']};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const FilterLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterIconWrapper = styled.div<{ open?: boolean }>`
  transform: rotate(${({ open }) => (open ? '90' : '0')}deg);
`;

export const FilterCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.nColors['n-yellow']};
  width: 20px;
  height: 20px;
`;

export const FilterDropDownClose = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    transform: scale(0.8);
  }

  @media ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

export const FilterDropDownTitle = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  @media ${({ theme }) => theme.media.md} {
    display: none;
    margin-bottom: 0;
  }
`;

export const FilterDropDownBg = styled.div`
  height: fit-content;
  border-radius: 20px 20px 0 0;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 60px 20px 45px;

  @media ${({ theme }) => theme.media.md} {
    background-color: transparent;
    position: relative;
    padding: 0;
  }
`;

export const AppliedFilterWrapper = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-grey1'], 0.3)};
`;

export const FilterDropDownWrapper = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100svh;
  background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'], 0.2)};
  top: 0;
  left: 0;
  @media ${({ theme }) => theme.media.md} {
    position: absolute;
    left: 0;
    top: 70px;
    box-shadow: 0px 8px 20px 0px rgba(196, 196, 196, 0.3);
    background-color: ${({ theme }) => theme.nColors['n-light']};
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    z-index: 70;
    height: fit-content;
  }
`;

export const FilterCombineDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  padding: 14px;
  border-radius: 5px;
`;

export const FilterCombineDateItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    width: 45%;
    gap: 20px;
    margin: 20px 20px 0 auto;
  }
`;

export const DropDownFilterListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  gap: 20px;
`;

// --------------------------- TRANSACTION -----------------------------//

export const TransactionItemWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;

  :hover {
    padding: 5px;
    background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'].trim(), 0.03)};
    border-radius: 5px;
  }

  .endText {
    text-align: end;
  }
`;

export const TransactionItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.nColors['n-yellow1']};
    width: 41px;
    height: 41px;

    svg {
      transform: scale(0.8);
    }
  }
`;

export const TransactionItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100svh - 286px);
  overflow-y: auto;
  @media ${({ theme }) => theme.media.md} {
    height: calc(100svh - 271px);
  }
`;

// --------------------- ISSUE FILTER -------------------------//

export const IssueFilterWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

// ----------------------- SUBMITTED ISSUE ----------------------//
export const SubmittedIssueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100svh;
  width: 100%;
  position: fixed;
  @media ${({ theme }) => theme.media.md} {
    position: absolute;
  }
  z-index: 120;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  padding: 40px 20px;
  top: 0;
  left: 0;
`;

export const SubmittedIssueCloseWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    transform: scale(0.8);
  }
`;

export const SubmittedIssueContentWrapper = styled.div`
  max-width: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  margin: 0 20px;
`;

export const SubmittedIssueFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  position: absolute;
  bottom: 91px;
  left: 20px;
  gap: 30px;
  text-align: center;

  p {
    cursor: pointer;
  }
`;

// ----------------------- DOCUMENT VIEWER ----------------------//
export const DocumentViewerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DocumentViewerMiniItemList = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

export const DocumentViewerMiniItem = styled.img`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 58px;
  height: 62px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 10px 5px 5px ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'].trim(), 0.02)};
`;

export const DocumentViewerExpanded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  @media ${({ theme }) => theme.media.md} {
    width: 75%;
    max-width: 540px;
  }
  @media ${({ theme }) => theme.media.lg} {
    width: 50%;
  }

  height: 100svh;
  background-color: ${({ theme }) => convertHexToRGBA(theme.nColors['n-dark'].trim(), 0.75)};
`;

export const DocumentViewerExpandedClose = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  position: absolute;
  top: 66px;
  margin-bottom: 33px;
  cursor: pointer;
`;

export const DocumentViewerExpandedItem = styled.img`
  width: auto;
  max-width: 80vw;
  height: auto;
  max-height: 70svh;

  @media ${({ theme }) => theme.media.md} {
    max-width: 450px;
  }
`;

export const DocumentViewerExpandedToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  position: absolute;
  bottom: 69px;
`;

export const DocumentViewerExpandedToggleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    path {
      stroke: ${({ theme }) => theme.nColors['n-light']};
    }
  }
`;
