import { IconsEnum, NIcons } from 'kudi-component-library';
import useRedux from '../../../../hooks/useRedux';
import { SupportIconButtonWrapper } from '../../styles';
import { toggleShow, updateCurrentPage } from '../../reducer/slice';

const SupportIconButton = () => {
  const { dispatch } = useRedux();

  const handleClick = () => {
    dispatch(updateCurrentPage('home'));
    dispatch(toggleShow());
  };

  return (
    <SupportIconButtonWrapper onClick={handleClick}>
      <NIcons icon={IconsEnum.OUTLINE_HELP} />
    </SupportIconButtonWrapper>
  );
};

export default SupportIconButton;
