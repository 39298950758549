/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import { DashboardLayout } from '../components/layout/dashboard';
import { SettingsLayout } from '../components/layout/settings';
import { base } from '../components/navigation/data';
import {
  fromLocationQueryConstant,
  OnboardingRoutes,
  FLAGSMITH_FEATURES,
  OnboardingV3Routes,
} from '../utils/constants';
import { HeaderContextWrapper } from '../utils/headerContext';
import ComingSoon from '../components/ComingSoon';
import PageLoad from '../components/PageLoad';
import ViewTransfers from '../pages/transfers';
import BulkTransfer from '../pages/transfers/singleandbulktransfer/bulktransfer';
import ProductComingSoon from '../components/ProductComingSoon';
import {
  InventoryComingSoonImage,
  InventoryDescription,
  TeamsComingSoonImage,
  TeamsDescription,
} from '../components/ProductComingSoon/comingSoonData';
import { GuestDashboardLayout } from '../components/layout/guestDashboard';
import { IS_SERVICE_STATUS_ON } from '../utils/pages/serviceStatus';
import { inventoryRoutes } from '../utils/pages/inventory';
import { IS_TEAMS_ON, teamsRoutes } from '../utils/pages/teams';
import ErrorBoundary from '../utils/errorBoundary';
import { menuRoutes } from '../utils/pages/menu';
import { notifcationRoutes } from '../utils/notification';
import CallbackUrlRedirect from '../pages/auth/sign-up/callbackUrlRedirect';
import { SETTINGS_HEIGHT } from '../pages/settings/constants';
// import { IS_ONBOARDING_V2_ON } from '../utils/pages/auth';
import { outletRoutes } from '../utils/pages/outlets';
import { useAppSelector } from '../app/hooks';
import { CrossPlatfromLayout } from '../components/layout/cross-platform/service-status';
import { MenuLayout } from '../pages/menu';
import { NotificationLayout } from '../pages/notification';
import MenuAnalytics from '../pages/menu/menu-analytics';
import { ExtractActionFromNotification } from '../hooks/useActionFromNotification';
import OutletSettlementsTab from '../pages/outlets/outlet/tabs/settlements';
import isRoleAllowed from '../utils/isRoleAllowed';
import CreateAnAccount from '../pages/auth/onboardingv3/components/create-an-account';
import ChooseAccountType from '../pages/auth/onboardingv3/components/choose-account-type';
import CreatePassword from '../pages/auth/onboardingv3/components/create-password';
import Verification from '../pages/auth/onboardingv3/components/verification';
import BusinessInformation from '../pages/auth/onboardingv3/components/business-information';

// Banking
const Transactions = React.lazy(() => import('../pages/banking/transactions/transactionsOverview'));
const Transaction = React.lazy(() => import('../pages/banking/transactions/transaction'));
const Disputes = React.lazy(() => import('../pages/banking/disputes/disputes'));
const PaymentLinks = React.lazy(() => import('../pages/banking/paymentlinks/paymentLinks'));
const Settlements = React.lazy(() => import('../pages/banking/settlements'));
const Settlement = React.lazy(() => import('../pages/banking/settlements/settlement'));
// const SettlementSinglePaymentTransactionTab = React.lazy(
//   () => import('../pages/banking/settlements/tabs/lump-sum-transaction')
// );
const SettlementSplitSettlemenTab = React.lazy(() => import('../pages/banking/settlements/tabs/split-settlement'));

const Overview = React.lazy(() => import('../pages/overview'));
const OutletAnalytics = React.lazy(() => import('../pages/overview/outlet-analytics'));

const BusinessSetup = React.lazy(() => import('../pages/overview/businessSetup'));
const Businessinfo = React.lazy(() => import('../pages/setup/business/businessinfo'));
const Businessdetails = React.lazy(() => import('../pages/setup/business/businessdetails'));

// Outlets
const OutletHome = React.lazy(() => import('../pages/outlets/home/home'));
const OutletDetail = React.lazy(() => import('../pages/outlets/outlet'));
const OutletDetailOverview = React.lazy(() => import('../pages/outlets/outlet/tabs/overview'));
const OutletTransactionsTab = React.lazy(() => import('../pages/outlets/outlet/tabs/transactions'));
const OutletTerminalsTab = React.lazy(() => import('../pages/outlets/outlet/tabs/terminals'));
const OutletTeamMembersTab = React.lazy(() => import('../pages/outlets/outlet/tabs/team-members'));
const OutletMenu = React.lazy(() => import('../pages/outlets/outlet/sections/outlet-menu'));
const OutletMenuListTab = React.lazy(() => import('../pages/outlets/outlet/sections/outlet-menu/menu/tabs/menu-list'));
const OutletOrderListTab = React.lazy(
  () => import('../pages/outlets/outlet/sections/outlet-menu/menu/tabs/order-list')
);
const OutletMenuSettingsTab = React.lazy(
  () => import('../pages/outlets/outlet/sections/outlet-menu/menu/tabs/settings')
);

// Terminals
const ViewAllTerminals = React.lazy(() => import('../pages/terminals/terminalOverview'));
// const TerminalDetails = React.lazy(() => import('../pages/terminals/terminaldetails'));
const ActivateTerminals = React.lazy(() => import('../pages/terminals/activateterminal'));
const CreateTerminalOutlet = React.lazy(() => import('../pages/terminals/terminaloutlet'));
const AssignedTerminalDetails = React.lazy(() => import('../pages/terminals/terminalTransactionDetails'));
const SubscriptionTerminalDetails = React.lazy(() => import('../pages/terminals/subscriptionTerminalDetails'));

// QR Code
// const QRPayment = React.lazy(() => import('../pages/qr-code/qrPayment'));

// Teams
const TeamsOverview = React.lazy(() => import('../pages/teams/overview'));
const ViewPermissions = React.lazy(() => import('../pages/teams/roles/manage-roles'));
const TeamSignUp = React.lazy(() => import('../pages/teams/auth/team-up'));
const TeamAccountCreated = React.lazy(() => import('../pages/teams/auth/components/account-created'));
const TeamUserFound = React.lazy(() => import('../pages/teams/auth/components/found-user'));
const TeamInvalidToken = React.lazy(() => import('../pages/teams/auth/components/invalid-token'));
const SelectBusinessLogin = React.lazy(() => import('../pages/teams/auth/select-business-login'));

// Referrals
const ReferralOverview = React.lazy(() => import('../pages/referral/referralOverview'));
const ReferralProfile = React.lazy(() => import('../pages/referral/referralProfile'));
const InidividualReferral = React.lazy(() => import('../pages/referral/[id]'));

// onboarding /auth
const AuthLogin = React.lazy(() => import('../pages/auth/login'));
const AuthForgotPassword = React.lazy(() => import('../pages/auth/forgot-password'));
const AuthVerification = React.lazy(() => import('../pages/auth/ResetVerification'));
const AuthResetPassword = React.lazy(() => import('../pages/auth/reset-password'));
const MigratedUsers = React.lazy(() => import('../pages/auth/migrated-users'));
const DeviceManagement = React.lazy(() => import('../pages/auth/device-management'));
// new onboarding
const SignupNew = React.lazy(() => import('../pages/auth/sign-up'));
const SessionTimedOut = React.lazy(() => import('../pages/auth/timed-out'));

// Business tools
// const BusinessToolsOutlets = React.lazy(() => import('../pages/business-tools/outlets/index'));
// const BusinessToolsCreateOutlets = React.lazy(() => import('../pages/business-tools/outlets/createOutlet'));
// const BusinessToolsOutletOveview = React.lazy(() => import('../pages/business-tools/outlets/outletOverview'));

// control - settings
const SettingsMyAccount = React.lazy(() => import('../pages/settings/MyAccount'));
const SettingsSettlement = React.lazy(() => import('../pages/settings/Settlemement'));
const SettingsAccountStatement = React.lazy(() => import('../pages/settings/AccountStatement'));
const SettingsTerminalURL = React.lazy(() => import('../pages/settings/TerminalUrl'));
const SettingsTransactionAlert = React.lazy(() => import('../pages/settings/TransactionAlert'));
const SettingsWebhooknApiKeys = React.lazy(() => import('../pages/settings/webhookNApiKeys'));

// const SettingsSecurityChangePassword = React.lazy(() => import('../pages/settings/Security/ChangePassword'));
// const SettingsSecurityChangeTransactionPin = React.lazy(
//   () => import('../pages/settings/Security/ChangeTransactionPin')
// );
// const SettingsSecuritySetTransactionPin = React.lazy(() => import('../pages/settings/Security/SetTransactionPin'));
// const SettingsSecurityVerify = React.lazy(() => import('../pages/settings/Security/Verify'));
const SettingsSettlementAddBank = React.lazy(() => import('../pages/settings/Settlemement/AddNewBank'));

// Invoicing
const Customers = React.lazy(() => import('../pages/invoicing/customers'));
const Outgoing = React.lazy(() => import('../pages/invoicing/invoices'));
const CreateInvoice = React.lazy(() => import('../pages/invoicing/invoices/createInvoice'));
const ViewInvoice = React.lazy(() => import('../pages/invoicing/invoices/viewInvoice'));

// Inventory
const InventoryOverview = React.lazy(() => import('../pages/inventory/overview/Overview'));
const InventoryProducts = React.lazy(() => import('../pages/inventory/products'));
const InventoryOrders = React.lazy(() => import('../pages/inventory/orders/Orders'));
const InventoryCategories = React.lazy(() => import('../pages/inventory/categories'));
const InventoryDiscounts = React.lazy(() => import('../pages/inventory/discounts'));
const InventoryLayout = React.lazy(() => import('../pages/inventory'));

// Menu
const MenuList = React.lazy(() => import('../pages/menu/menu-list'));
const MenuOrders = React.lazy(() => import('../pages/menu/menu-orders'));
const MenuSettings = React.lazy(() => import('../pages/menu/menu-settings'));

// Service Status
const CardBankPerformance = React.lazy(() => import('../pages/card-bank-performance/cardBankPerformance'));

// Place account on PND
const PlaceAccountOnPND = React.lazy(() => import('../pages/pnd'));

// Notifications
const NotificationAll = React.lazy(() => import('../pages/notification/components/view-all'));
const NotificationSystem = React.lazy(() => import('../pages/notification/components/view-system'));
const NotificationCampaign = React.lazy(() => import('../pages/notification/components/view-campaign'));
const NotificationAnnoucements = React.lazy(() => import('../pages/notification/components/view-announcements'));

// Preferences
const Preferences = React.lazy(() => import('../pages/settings/Preferences'));

ExtractActionFromNotification();

const PickEntryParam = () => {
  const [searchParams] = useSearchParams();
  const queryObject = Object.fromEntries([...searchParams]);
  localStorage.setItem(fromLocationQueryConstant, JSON.stringify(queryObject));
  return <Navigate replace to="/dashboard/overview" />;
};

const RouteWrapper = (children: React.ReactNode | null, height?: string) => (
  <ErrorBoundary>
    <React.Suspense fallback={<PageLoad height={height} />}>{children}</React.Suspense>
  </ErrorBoundary>
);

export default function AppRouter() {
  // const { initV2 } = useAppSelector((state) => state.auth);

  // const showOnboardingV2 = initV2 || IS_ONBOARDING_V2_ON;
  const { parentBusinessId } = useAppSelector((state) => state.auth);

  const flagsmith = useFlagsmith();

  const identifyUser = useCallback(() => {
    if (parentBusinessId) flagsmith.identify(`business_banking_user_${parentBusinessId}`);
  }, [flagsmith, parentBusinessId]);

  const {
    web_storehouse: webStoreHouse,
    bb_dashboard_notification: webNotification,
    web_outlets_data: webOutletsData,
    web_terminal_actions: webTerminalAction,
    web_transaction_alert: webTransactionAlert,
    web_outlet_menu: webOutletMenu,
  } = useFlags([
    FLAGSMITH_FEATURES.WEB_STOREHOUSE,
    FLAGSMITH_FEATURES.BB_DASHBOARD_NOTIFICATION,
    FLAGSMITH_FEATURES.WEB_OUTLETS_DATA,
    FLAGSMITH_FEATURES.WEB_TERMINAL_ACTION,
    FLAGSMITH_FEATURES.WEB_TRANSACTION_ALERT,
    FLAGSMITH_FEATURES.WEB_OUTLET_MENU,
  ]);

  const SettingsRoutes = {
    account: 'account',
    settlements: 'settlements',
    accountStatement: 'account-statement',
    ...(webTerminalAction.enabled && { terminalURL: 'terminalurl' }),
    ...(webNotification.enabled && { preferences: 'preferences' }),
    ...(webTransactionAlert.enabled && isRoleAllowed(['OWNER', 'ADMIN']) && { transactionAlert: 'transaction-alert' }),
    ...(isRoleAllowed(['OWNER', 'ADMIN', 'IT_DEVELOPER']) && { webhooknapikeys: 'webhooks-apikeys' }),
  };

  useEffect(() => {
    identifyUser();
  }, [identifyUser]);

  return (
    <BrowserRouter>
      <HeaderContextWrapper>
        <Routes>
          {/* Routes outside dashbord goes here */}
          <Route path="/service-status" element={RouteWrapper(<CrossPlatfromLayout />)}>
            <Route path="card-bank-performance" element={RouteWrapper(<CardBankPerformance />)} />
          </Route>
          <Route path="/dashboard/setup/business" element={<CallbackUrlRedirect />} />
          <Route path="/dashboard" element={RouteWrapper(<DashboardLayout />)}>
            {/* overview routes */}
            <Route path={`${base.overview.value}`}>
              <Route index element={RouteWrapper(<Overview />)} />
              {webOutletsData.enabled ? (
                <Route path="outlet-analytics" element={RouteWrapper(<OutletAnalytics />)} />
              ) : null}
            </Route>

            {/* payments routes */}
            <Route path={`${base.payments.value}`}>
              <Route path={`${base.payments.routes.terminals}`}>
                <Route index element={RouteWrapper(<ViewAllTerminals />)} />
                <Route path="activate" element={RouteWrapper(<ActivateTerminals />)} />
                <Route path="create-outlet" element={RouteWrapper(<CreateTerminalOutlet />)} />
                <Route path=":id" element={RouteWrapper(<AssignedTerminalDetails />)} />
                <Route path="subscriptions/:id" element={RouteWrapper(<SubscriptionTerminalDetails />)} />
              </Route>
              <Route path={`${base.payments.routes.payment_links}`}>
                <Route index element={RouteWrapper(<PaymentLinks />)} />
                <Route path="virtual-accounts" element={RouteWrapper(<ComingSoon />)} />
              </Route>
              <Route path={`${base.payments.routes.transfer}`}>
                <Route index element={RouteWrapper(<ViewTransfers />)} />
                <Route path="bulk" element={RouteWrapper(<BulkTransfer />)} />
              </Route>
              <Route path={`${base.payments.routes.settlements}`}>
                <Route index element={RouteWrapper(<Settlements />)} />
                <Route path="split-settlement" element={<SettlementSplitSettlemenTab />} />
                <Route path={':id'} element={RouteWrapper(<Settlement />)} />
              </Route>
              <Route path={`${base.payments.routes.transactions}`}>
                <Route index element={RouteWrapper(<Transactions />)} />
                <Route path={':id'} element={RouteWrapper(<Transaction />)} />
              </Route>
              <Route path={`${base.payments.routes.disputes}`}>
                <Route index element={RouteWrapper(<Disputes />)} />
              </Route>
            </Route>

            <Route path="setup">
              <Route path="business-setup">
                <Route index element={RouteWrapper(<BusinessSetup />)} />
                <Route path="information" element={RouteWrapper(<Businessinfo />)} />
                <Route path="details" element={RouteWrapper(<Businessdetails />)} />
              </Route>
            </Route>

            {/* Invoicing routes */}
            <Route path={base.invoicing.value}>
              <Route path={base.invoicing.routes.customers} element={RouteWrapper(<Customers />)} />
              <Route path={base.invoicing.routes.invoices}>
                <Route index element={RouteWrapper(<Outgoing />)} />
                <Route path="create-invoice" element={RouteWrapper(<CreateInvoice />)} />
                <Route path="create-invoice/:id" element={RouteWrapper(<CreateInvoice />)} />
              </Route>
            </Route>

            <Route path={base.business.value}>
              <Route path={base.business.routes.outlets}>
                <>
                  <Route path={`${outletRoutes.home}`} element={RouteWrapper(<OutletHome />)} />

                  <Route path=":id" element={RouteWrapper(<OutletDetail />)}>
                    {webOutletMenu.enabled ? (
                      <Route path="menu" element={<OutletMenu />}>
                        <Route path="menu-list" element={<OutletMenuListTab />} />
                        <Route path="order-list" element={<OutletOrderListTab />} />
                        <Route path="settings" element={<OutletMenuSettingsTab />} />
                      </Route>
                    ) : null}
                    <Route path="overview" element={<OutletDetailOverview />} />
                    <Route path="transactions" element={<OutletTransactionsTab />} />
                    <Route path="terminals" element={<OutletTerminalsTab />} />
                    <Route path="settlements" element={<OutletSettlementsTab />} />
                    <Route path="team" element={<OutletTeamMembersTab />} />
                  </Route>
                  <Route path=":id/settlements/:outletSettlementId" element={RouteWrapper(<Settlement />)} />
                </>
              </Route>
              {webStoreHouse.enabled ? (
                <Route path="inventory" element={<InventoryLayout routes={inventoryRoutes} />}>
                  <Route index element={<Navigate replace to={inventoryRoutes.overview} />} />
                  <Route path={inventoryRoutes.overview} element={RouteWrapper(<InventoryOverview />)} />
                  <Route path={inventoryRoutes.products} element={RouteWrapper(<InventoryProducts />)} />
                  <Route path={inventoryRoutes.orders} element={RouteWrapper(<InventoryOrders />)} />
                  <Route path={inventoryRoutes.categories} element={<InventoryCategories />} />
                  <Route path={inventoryRoutes.discounts} element={<InventoryDiscounts />} />
                </Route>
              ) : (
                <Route
                  path={`${base.business.routes.inventory}`}
                  element={
                    <ProductComingSoon
                      productTitle="Inventory"
                      image={<InventoryComingSoonImage />}
                      productDescription={<InventoryDescription />}
                    />
                  }
                />
              )}

              <Route path={base.business.routes.menu} element={<MenuLayout routes={menuRoutes} />}>
                <Route index element={<Navigate replace to={`${menuRoutes.menuList}`} />} />
                <Route path={menuRoutes.menuList} element={<MenuList />} />
                <Route path={menuRoutes.menuOrders} element={<MenuOrders />} />
                <Route path={menuRoutes.menuSettings} element={<MenuSettings />} />
                <Route path={menuRoutes.menuAnalytics} element={<MenuAnalytics />} />
              </Route>

              {IS_SERVICE_STATUS_ON ? (
                <Route path={base.business.routes.card_performance} element={<CardBankPerformance />} />
              ) : null}
            </Route>

            {/* teams routes */}
            {IS_TEAMS_ON ? (
              <Route path={`${base.business.value}`}>
                <Route path="teams">
                  <Route index element={RouteWrapper(<TeamsOverview />)} />
                  <Route path={teamsRoutes.viewPermissions} element={RouteWrapper(<ViewPermissions />)} />
                </Route>
              </Route>
            ) : (
              <Route path={`${base.business.value}`}>
                {/* <Route path={`${base.business.routes.teams}/new-team-member`} element={<TeamMember />} />
                <Route path={`${base.business.routes.teams}/edit-team-member`} element={<EditTeamMember />} /> */}
                <Route
                  path={`${base.business.routes.teams}`}
                  element={RouteWrapper(
                    <ProductComingSoon
                      image={<TeamsComingSoonImage />}
                      productTitle="Teams"
                      productDescription={<TeamsDescription />}
                      alternate
                    />
                  )}
                />
              </Route>
            )}

            <Route path={`${base.business.value}`}>
              {webNotification.enabled ? (
                <Route
                  path={base.business.routes.notifications}
                  element={<NotificationLayout routes={notifcationRoutes} />}
                >
                  <Route index element={<Navigate replace to={`${notifcationRoutes.all}`} />} />
                  <Route path={notifcationRoutes.all} element={<NotificationAll />} />
                  <Route path={notifcationRoutes.system} element={<NotificationSystem />} />
                  <Route path={notifcationRoutes.campaign} element={<NotificationCampaign />} />
                  <Route path={notifcationRoutes.announcement} element={<NotificationAnnoucements />} />
                </Route>
              ) : (
                <Route />
              )}
              {/* REFERRAL */}
              <Route path={base.business.routes.referral}>
                <Route index element={RouteWrapper(<ReferralOverview />)} />
                <Route path="referral-profile" element={RouteWrapper(<ReferralProfile />)} />
                <Route path="referral-profile/:id" element={RouteWrapper(<InidividualReferral />)} />
              </Route>
            </Route>

            {/* control */}

            <Route path={`${base.control.value}`}>
              {/* settings */}
              <Route path={`${base.control.routes.settings}`} element={<SettingsLayout routes={SettingsRoutes} />}>
                <Route index element={<Navigate replace to={`${SettingsRoutes.account}`} />} />
                <Route
                  path={`${SettingsRoutes.account}`}
                  element={RouteWrapper(<SettingsMyAccount />, SETTINGS_HEIGHT)}
                />
                <Route
                  path={`${SettingsRoutes.settlements}`}
                  element={RouteWrapper(<SettingsSettlement />, SETTINGS_HEIGHT)}
                />
                <Route
                  path={`${SettingsRoutes.terminalURL as string}`}
                  element={RouteWrapper(<SettingsTerminalURL />, SETTINGS_HEIGHT)}
                />
                <Route
                  path={`${SettingsRoutes.transactionAlert as string}`}
                  element={RouteWrapper(<SettingsTransactionAlert />, SETTINGS_HEIGHT)}
                />
                <Route
                  path={`${SettingsRoutes?.preferences as string}`}
                  element={RouteWrapper(<Preferences />, SETTINGS_HEIGHT)}
                />
                <Route
                  path={`${SettingsRoutes?.accountStatement as string}`}
                  element={RouteWrapper(<SettingsAccountStatement />, SETTINGS_HEIGHT)}
                />
                <Route
                  path={`${SettingsRoutes?.webhooknapikeys as string}`}
                  element={RouteWrapper(<SettingsWebhooknApiKeys />, SETTINGS_HEIGHT)}
                />
              </Route>

              {/* breaking out of setting layout */}
              <Route path={`${base.control.routes.settings}`}>
                <Route path={`${SettingsRoutes.settlements}`}>
                  <Route path="add-bank" element={RouteWrapper(<SettingsSettlementAddBank />)} />
                </Route>
              </Route>
            </Route>

            {/* wildcard */}
            <Route path="*" element={RouteWrapper(<Overview />)} />
          </Route>

          {/* Onboading login & signup */}
          {/* <Route path="https://business-banking-frontend-dev.kudi.ng/dashboard/setup/business?code=38373837837" /> */}

          <Route path="/auth">
            <Route path={OnboardingRoutes.login} element={RouteWrapper(<AuthLogin />)} />
            <Route path={OnboardingRoutes.forgot_password} element={RouteWrapper(<AuthForgotPassword />)} />
            <Route path={OnboardingRoutes.verification} element={RouteWrapper(<AuthVerification />)} />
            <Route path={OnboardingRoutes.reset_password} element={RouteWrapper(<AuthResetPassword />)} />
            <Route path="existing-user" element={RouteWrapper(<MigratedUsers />)} />
            <Route path="device-management" element={RouteWrapper(<DeviceManagement />)} />

            <Route path="sign-up-new" element={RouteWrapper(<SignupNew />)} />
            <Route path="onboardingv3">
              <Route index element={RouteWrapper(<ChooseAccountType />)} />
              <Route
                path={`:account-type/${OnboardingV3Routes.create_account}`}
                element={RouteWrapper(<CreateAnAccount />)}
              />
              <Route
                path={`:account-type/${OnboardingV3Routes.create_password}`}
                element={RouteWrapper(<CreatePassword />)}
              />
              <Route
                path={`:account-type/${OnboardingV3Routes.verification}`}
                element={RouteWrapper(<Verification />)}
              />
              <Route
                path={`:account-type/${OnboardingV3Routes.business_information}`}
                element={RouteWrapper(<BusinessInformation />)}
              />
            </Route>
            <Route path="select-business" element={RouteWrapper(<SelectBusinessLogin />)} />
            <Route path="timed-out" element={RouteWrapper(<SessionTimedOut />)} />
          </Route>

          {/* teams open route */}
          <Route path={teamsRoutes.auth} element={RouteWrapper(<TeamSignUp />)} />
          <Route path={teamsRoutes.accountCreated} element={RouteWrapper(<TeamAccountCreated />)} />
          <Route path={teamsRoutes.foundUser} element={RouteWrapper(<TeamUserFound />)} />
          <Route path={teamsRoutes.invalidToken} element={RouteWrapper(<TeamInvalidToken />)} />
          {/* end of teams open route */}

          {/* Guest Dashboard routes */}
          <Route path="guest/" element={RouteWrapper(<GuestDashboardLayout />)}>
            {IS_SERVICE_STATUS_ON ? (
              <Route path="card-bank-performance" element={RouteWrapper(<CardBankPerformance />)} />
            ) : null}
          </Route>
          {/* End of Guest Dashboard routes */}

          <Route path="view-invoice/:id" element={RouteWrapper(<ViewInvoice />)} />
          <Route path="/place-on-pnd" element={RouteWrapper(<PlaceAccountOnPND />)} />
          <Route path="*" element={<Navigate replace to="/dashboard/overview" />} />
          <Route path="*" element={<PickEntryParam />} />
        </Routes>
      </HeaderContextWrapper>
    </BrowserRouter>
  );
}
