import { Button, IconsEnum, NIcons, TextArea, TextInput, Typography } from 'kudi-component-library';
import { useEffect } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import { Box } from 'rebass';
import Modal from '../../../../components/Modal';
import { Separator } from '../../../../styles/generic';
import { FormInputFlexWrapper, FormInputWrapper, FormWrapper, PageBadgeCounter } from '../../styles';
import useCreateStoreModel from '../models/useCreateStoreModel';
import { Mixpanel } from '../../../../hooks/useMixpanel';
import { getUserBrowser } from '../../../../utils';
import Select from '../../../../components/Select';

export const CreateStoreModalWrapper = styled.div`
  padding: 50px 10px;
`;
export const CreateStoreTitleBox = styled(Box)`
  padding-bottom: 20px;
  border-bottom: solid 1px ${({ theme }) => theme.nColors['n-grey1']};
`;

const CreateStoreModal = ({
  showModal,
  newMenu,
  onClose,
  onContinue,
}: {
  showModal: boolean;
  newMenu: boolean;
  onClose: () => void;
  onContinue: () => void;
}) => {
  const onCloseContinue = () => {
    onClose();
    onContinue();
  };
  const {
    areCustomFieldsSet,
    handleCreateNewStore,
    validationSchema,
    initialValues,
    states,
    setSelectedState,
    lgas,
    selectedState,
  } = useCreateStoreModel({
    onClose: onCloseContinue,
  });

  useEffect(() => {
    Mixpanel.track('MENU_STORE_SETUP_VIEWED', {
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
    });
  }, []);

  return (
    <Modal customDesktopWidth="500px" customTabletWidth="65rem" showModal={showModal} onClose={() => onClose()}>
      {newMenu && (
        <PageBadgeCounter>
          <>
            <NIcons icon={IconsEnum.OUTLINE_CHECK} color="n-yellow" height="12" width="12" />
            <Typography style={{ fontSize: '12px', marginLeft: '5px' }}>Step 1 of 3</Typography>
          </>
        </PageBadgeCounter>
      )}
      <CreateStoreModalWrapper>
        <CreateStoreTitleBox>
          <Typography variant="n-text5" color="dark">
            Setup your store
          </Typography>
          <Typography variant="n-text2" color="n-grey4">
            Enter the correct detail in the following fields
          </Typography>
        </CreateStoreTitleBox>
        <Separator height="2rem" />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleCreateNewStore(values, setSubmitting);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, isValid, dirty }) => (
            <FormWrapper>
              <FormInputFlexWrapper>
                <FormInputWrapper>
                  <TextInput
                    width="100%"
                    name="storeName"
                    label="Store name"
                    placeholder="Enter Name"
                    value={values.storeName || ''}
                    onChange={handleChange}
                    error={touched.storeName && errors.storeName ? errors.storeName : undefined}
                    disabled={isSubmitting}
                    id="menu-add-new-store-name-input"
                  />
                </FormInputWrapper>
              </FormInputFlexWrapper>
              <FormInputWrapper>
                <TextArea
                  width="100%"
                  name="description"
                  label="Description"
                  placeholder="Describe your store"
                  value={values.description || ''}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  maxLength={200}
                  error={touched.description && errors.description ? errors.description : undefined}
                  id="menu-add-new-store-description-input"
                  style={{ height: '50px' }}
                />
              </FormInputWrapper>

              <FormInputFlexWrapper>
                <FormInputWrapper>
                  <Select
                    label="State"
                    name="state"
                    options={states}
                    value={values.state || ''}
                    error={touched.state && errors.state ? errors.state : undefined}
                    disabled={isSubmitting}
                    onChange={(e) => {
                      handleChange(e);
                      setSelectedState(e?.target?.value || '');
                    }}
                    width="100%"
                  />
                </FormInputWrapper>
                <FormInputWrapper>
                  <Select
                    label="Local Government Area"
                    name="lga"
                    options={lgas}
                    value={values.lga || ''}
                    error={touched.lga && errors.lga ? errors.lga : undefined}
                    disabled={isSubmitting || !selectedState}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    width="100%"
                  />
                </FormInputWrapper>
              </FormInputFlexWrapper>
              <Separator height="2rem" />
              <Button
                disabled={isSubmitting || !(isValid && dirty)}
                onClick={() => {
                  if (areCustomFieldsSet(values)) handleSubmit();
                  Mixpanel.track('User views the store setup page', {
                    timeStamp: new Date().toString(),
                    userBrowser: getUserBrowser(),
                  });
                }}
                width="100%"
                height="45px"
                variant="small"
                id="inventory-add-new-product-submit-button"
              >
                Continue
              </Button>
            </FormWrapper>
          )}
        </Formik>
      </CreateStoreModalWrapper>
    </Modal>
  );
};

export default CreateStoreModal;
