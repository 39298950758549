/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

declare global {
  interface Window {
    _env_: {
      REACT_APP_BASEURL: string;
      REACT_APP_FLAGSMITH_API_KEY: string;
      REACT_APP_MEDIA_SERVICE: string;
      REACT_APP_MERCHANT_ACQ_SERVICE: string;
      REACT_APP_SERVICE_STATUS_FEATURE: string;
      REACT_APP_TEAMS_FEATURE: string;
      REACT_APP_WEBHOOKS_FEATURE: string;
      REACT_APP_SETTLEMENTS_FEATURE: string;
      REACT_APP_MENU_FEATURE: string;
      REACT_APP_MENU_APP_URL: string;
      REACT_APP_ONBOARDING_V2: string;
      REACT_APP_MENU_ACTORS: string;
      REACT_APP_SETTLEMENTS_FEATURE_ACTORS: string;
      REACT_APP_ONBOARDING_V2_PARAM: string;
      REACT_APP_NOTIFICATION_APP_IDENTIFIER: string;
      REACT_APP_NOTIFICATION_SUBSCRIBER_ID: string;
      REACT_APP_MIXPANEL: string;
      REACT_APP_FIREBASE_APIKEY: string;
      REACT_APP_FIREBASE_AUTH_DOMAIN: string;
      REACT_APP_FIREBASE_PROJECT_ID: string;
      REACT_APP_FIREBASE_STORAGE_BUCKET: string;
      REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
      REACT_APP_FIREBASE_APP_ID: string;
      REACT_APP_FIREBASE_MEASUREMENT_ID: string;
      REACT_APP_FIREBASE_VAPID_KEY: string;
      REACT_APP_THANKUCASH_URL: string;
      REACT_APP_GRPC_ENVOY: string;
      REACT_APP_QR_URL: string;
    };
  }
}

interface AppConfig {
  baseUrl: string;
  mediaServiceUrl: string;
  merchantAcqServiceUrl: string;
  serviceStatusUrl: string;
  teamsFlipper: string;
  menuFlipper: string;
  menuAppUrl: string;
  onboardingV2Flipper: string;
  menuActors: string;
  settlementActors: string;
  flagsmith_api_key: string;
  onbaordingV2Param: string;
  notificationAppIdentifier: string;
  notificationSubscriberId: string;
  mixpanelToken: string;
  firebaseApiKey: string;
  firebaseAuthDomain: string;
  firebaseProjectId: string;
  firebaseStorageBucket: string;
  firebaseMessagingSenderID: string;
  firebaseAppID: string;
  firebaseMeasurementID: string;
  firebaseVapidKey: string;
  thankUCashUrl: string;
  grpcEnvoy: string;
  qrUrl: string;
}

const config: AppConfig = {
  baseUrl: window._env_.REACT_APP_BASEURL,
  mediaServiceUrl: window._env_.REACT_APP_MEDIA_SERVICE,
  merchantAcqServiceUrl: window._env_.REACT_APP_MERCHANT_ACQ_SERVICE,
  serviceStatusUrl: window._env_.REACT_APP_SERVICE_STATUS_FEATURE,
  teamsFlipper: window._env_.REACT_APP_TEAMS_FEATURE,
  menuFlipper: window._env_.REACT_APP_MENU_FEATURE,
  menuAppUrl: window._env_.REACT_APP_MENU_APP_URL,
  onboardingV2Flipper: window._env_.REACT_APP_ONBOARDING_V2,
  onbaordingV2Param: window._env_.REACT_APP_ONBOARDING_V2_PARAM,
  menuActors: window._env_.REACT_APP_MENU_ACTORS,
  settlementActors: window._env_.REACT_APP_SETTLEMENTS_FEATURE_ACTORS,
  flagsmith_api_key: window._env_.REACT_APP_FLAGSMITH_API_KEY,
  notificationAppIdentifier: window._env_.REACT_APP_NOTIFICATION_APP_IDENTIFIER,
  notificationSubscriberId: window._env_.REACT_APP_NOTIFICATION_SUBSCRIBER_ID,
  mixpanelToken: window._env_.REACT_APP_MIXPANEL,
  firebaseApiKey: window._env_.REACT_APP_FIREBASE_APIKEY,
  firebaseAuthDomain: window._env_.REACT_APP_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: window._env_.REACT_APP_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: window._env_.REACT_APP_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderID: window._env_.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppID: window._env_.REACT_APP_FIREBASE_APP_ID,
  firebaseMeasurementID: window._env_.REACT_APP_FIREBASE_MEASUREMENT_ID,
  firebaseVapidKey: window._env_.REACT_APP_FIREBASE_VAPID_KEY,
  thankUCashUrl: window._env_.REACT_APP_THANKUCASH_URL,
  grpcEnvoy: window._env_.REACT_APP_GRPC_ENVOY,
  qrUrl: window._env_.REACT_APP_QR_URL,
};

export default config;
