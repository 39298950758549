import React from 'react';
import { ReactComponent as NetworkErrorSvg } from './network-error.svg';

const NetworkError: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> = (props) => <NetworkErrorSvg {...props} />;

export default NetworkError;
