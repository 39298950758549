import { Formik } from 'formik';
import { Button, TextInput, Typography } from 'kudi-component-library';
import styled from 'styled-components';
import Modal from '../../../../components/Modal';
import { HandleAddNewCategory, NewCategoryValues } from '../../../../types/inventory/category';
import { HeaderWrapper, Wrapper } from '../../styles';

const FormInputWrapper = styled.div`
  margin-bottom: 20px;

  div {
    margin-bottom: 20px;
  }
`;

const CancelTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const CancelText = styled(Typography)`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  padding-bottom: 5px;
  margin: 20px 0;
  width: fit-content;
`;

const initialValues: NewCategoryValues = {
  name: '',
  description: '',
};

const NewMenuCategoryModal = ({
  handleAddNewCategory,
  showNewCategoryModal,
  handleCloseNewCategoryModal,
}: {
  showNewCategoryModal: boolean;
  handleAddNewCategory: HandleAddNewCategory;
  handleCloseNewCategoryModal: () => void;
}) => (
  <Modal showModal={showNewCategoryModal} onClose={handleCloseNewCategoryModal}>
    <Wrapper>
      <HeaderWrapper>
        <Typography variant="n-text5" color="dark">
          Create Category
        </Typography>
        <Typography variant="n-text2" color="grey4">
          Enter the new category details to get started
        </Typography>
      </HeaderWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => handleAddNewCategory(values, setSubmitting)}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, isValid, dirty }) => (
          <>
            <FormInputWrapper>
              <TextInput
                width="100%"
                label="Name"
                name="name"
                value={values.name}
                placeholder="e.g Beverages"
                disabled={isSubmitting}
                onChange={handleChange}
                required
                error={errors.name && touched.name ? errors.name : undefined}
              />
              <TextInput
                width="100%"
                label="Description"
                name="description"
                value={values.description}
                placeholder="Enter a description for the category"
                disabled={isSubmitting}
                error={errors.description && touched.description ? errors.description : undefined}
                onChange={handleChange}
              />
            </FormInputWrapper>
            <Button
              variant="medium"
              width="100%"
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
            <CancelTextWrapper>
              <CancelText variant="n-text2" color="dark" onClick={handleCloseNewCategoryModal}>
                Cancel
              </CancelText>
            </CancelTextWrapper>
          </>
        )}
      </Formik>
    </Wrapper>
  </Modal>
);

export default NewMenuCategoryModal;
