import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchTransactionAlerts, updateTransactionAlerts } from './thunkActions';

export type TransferAlertsPhoneObject = {
  isSMS: boolean;
  isWhatsApp: boolean;
  phoneNumber: string;
};

interface InitialState {
  isLoading: boolean;
  emails: string[];
  emailAlertOn: boolean;
  phoneNumberAlertOn: boolean;
  phoneNumbers: TransferAlertsPhoneObject[];
}

const initialState: InitialState = {
  isLoading: false,
  emails: [],
  emailAlertOn: false,
  phoneNumberAlertOn: false,
  phoneNumbers: [],
};

export const transferAlertsSlice = createSlice({
  name: 'transferAlerts',
  initialState,
  reducers: {
    setEmails: (state, action: PayloadAction<string[]>) => {
      state.emails = action.payload;
    },
    setPhoneNumbers: (state, action: PayloadAction<TransferAlertsPhoneObject[]>) => {
      state.phoneNumbers = action.payload;
    },

    setEmailAlertOn: (state, action: PayloadAction<boolean>) => {
      state.emailAlertOn = action.payload;
    },

    setPhoneNumberAlertOn: (state, action: PayloadAction<boolean>) => {
      state.phoneNumberAlertOn = action.payload;
    },
  },
  extraReducers: (builder) => {
    //   fetch transaction alerts
    builder
      .addCase(fetchTransactionAlerts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTransactionAlerts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchTransactionAlerts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.emails = payload?.transactionAlert.alertEmails || [];
        state.phoneNumbers = payload?.transactionAlert.alertPhoneNumbers || [];
        state.emailAlertOn = payload?.transactionAlert.emailAlertOn || false;
        state.phoneNumberAlertOn = payload?.transactionAlert.phoneNumberAlertOn || false;
      });

    //   update transaction alerts
    builder
      .addCase(updateTransactionAlerts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTransactionAlerts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTransactionAlerts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.emails = payload?.transactionAlert.alertEmails || [];
        state.phoneNumbers = payload?.transactionAlert.alertPhoneNumbers || [];
        state.emailAlertOn = payload?.transactionAlert.emailAlertOn || false;
        state.phoneNumberAlertOn = payload?.transactionAlert.phoneNumberAlertOn || false;
      });
  },
});
export const { setEmails, setPhoneNumbers, setEmailAlertOn, setPhoneNumberAlertOn } = transferAlertsSlice.actions;
export default transferAlertsSlice.reducer;
export * from './thunkActions';
