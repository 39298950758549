import styled, { css } from 'styled-components';

export const ToastWrapper = styled.div`
  box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.3);
  border-radius: 5px;
  padding: 20px;
  background: #ffffff;
`;

export const ToastHolder = styled.div`
  position: absolute;
  z-index: 20000;
  @media only screen and (max-width: 600px) {
    width: 98%;
    top: 1%;
    left: 1%;
  }
  @media only screen and (min-width: 601px) {
    right: 0px;
    width: 100%;
    max-width: 388px;
  }
`;

export const ToastContentWrapper = styled.div<{ isTitled: boolean }>`
  display: flex;
  position: relative;
  gap: 20px;
  justify-content: space-between;
  svg.close-icon-svg {
    stroke-width: 1.2px;
    width: 20px;
    height: 20px;
    ${({ isTitled }) =>
      isTitled &&
      css`
        stroke-width: 1.5px;
        height: 30px;
        width: 30px;
      `};
  }
  ${(props) =>
    !props.isTitled &&
    css`
      align-items: center;
    `}
  div#notification-icon-container {
    ${(props) =>
      props.isTitled
        ? css`
            padding: 15px;
          `
        : css`
            padding: 10px;
          `}
  }
`;

export const ToastContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const NotificationIconContainer = styled.div<{
  state: 'success' | 'info' | 'error';
}>`
  border-radius: 100px;
  background-color: ${({ state }) => {
    let colour = '';
    switch (state) {
      case 'success':
        colour = '#CFF1CC';
        break;
      case 'info':
        colour = '#EEEEEE';
        break;
      case 'error':
        colour = '#FFBDB2';
        break;

      default:
        break;
    }
    return colour;
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const ToastMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: #383838;
`;
export const Message = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #999999;
`;
