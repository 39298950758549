import { Typography } from 'kudi-component-library';
import { AuthFooterBox } from '../../../pages/auth/styles';

const AuthFooter = () => (
  <AuthFooterBox>
    <Typography variant="n-text2" fontWeight="heavy" color="n-grey3">
      Nomba Financial Services Limited
    </Typography>
    <Typography variant="n-text2" color="n-grey3">
      Licensed by the Central Bank of Nigeria (CBN)
    </Typography>
  </AuthFooterBox>
);

export default AuthFooter;
