import { Button, Typography } from 'kudi-component-library';
import { Box, Flex } from 'rebass';
import { FileIcon } from '../../../../assets/svg';
import { EmptyTableWrapper } from '../../styles';

const EmptyMenuState = ({ onGetStarted }: { onGetStarted: () => void }) => (
  <EmptyTableWrapper>
    <FileIcon />
    <p>
      Adding a product to your menu tab is a great way to help customers quickly navigate and find what they're looking
      for.
    </p>
    <Box>
      <Button
        variant="small"
        onClick={() => {
          onGetStarted();
        }}
      >
        Get Started
      </Button>
    </Box>
  </EmptyTableWrapper>
);

const EmptyPageState = ({ onGetStarted }: { onGetStarted: () => void }) => (
  <EmptyTableWrapper>
    <Flex alignItems="center" flexDirection="column">
      <FileIcon />
      <Box mt="20px">
        <Typography color="n-grey5">Introducing the Menu feature on Nomba</Typography>
      </Box>
      <Box my="10px">
        <Typography color="n-grey3a" variant="n-text2">
          A hassle-free way for restaurant and hotel owners to add their menu and make it easily accessible to their
          customers.
        </Typography>
      </Box>
      <Box mt="20px">
        <Button
          variant="small"
          onClick={() => {
            onGetStarted();
          }}
        >
          Get Started
        </Button>
      </Box>
    </Flex>
  </EmptyTableWrapper>
);

const EmptyMenuTableState = () => (
  <EmptyTableWrapper>
    <FileIcon />
    <p>
      Adding a product to your menu tab is a great way to help customers quickly navigate and find what they're looking
      for.
    </p>
  </EmptyTableWrapper>
);

export { EmptyMenuState, EmptyMenuTableState, EmptyPageState };
