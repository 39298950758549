import { CreateMenuListItemResponse, EditCoverImage } from '../../../types/menu/menuList';
import axios from '../../../utils/merchantAcqServiceHttp';

const editCoverImage = async ({ menuId, payload }: { menuId: string; payload: EditCoverImage }) => {
  try {
    const result: CreateMenuListItemResponse = await axios.put(`/webbe/v1/menu-stores`, payload);

    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default editCoverImage;
