import { useEffect, useState } from 'react';
import { AnalyticsResponse } from '../../../../types/menu/menuList';
import { useToast } from '../../../../hooks/useToast';
import fetchMenuAnalytics from '../../../../queries/menu/menu-analytics/fetchMenuAnalytics';

const useGetAnalytics = () => {
  const [analyticsBody, setAnalyticsBody] = useState<AnalyticsResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const handleRefetch = () => setRefetch(!refetch);

  const toast = useToast(3000);

  const handleFetchAnalytics = async () => {
    setIsLoading(true);
    const { result, error: fetchError } = await fetchMenuAnalytics();
    setIsLoading(false);
    if (result) {
      setAnalyticsBody(result);
    }
    if (fetchError) {
      toast('error', 'An error occured while fetching menu');
    }
  };

  useEffect(() => {
    const request = new AbortController();
    handleFetchAnalytics();
    return () => request.abort();
  }, []);

  return {
    analyticsBody,
    isLoading,
    handleRefetch,
  };
};

export default useGetAnalytics;
