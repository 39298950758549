import { WithDrawalAccount } from '../../../types/banking/settlements';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface WithdrawalAccountsResponse extends ApiResponse {
  data: WithDrawalAccount;
}

const fetchWithdrawalAccount = async ({ businessId }: { businessId: number | string }) => {
  try {
    const result: WithdrawalAccountsResponse = await axios.get(`/webbe/v1/wallet/cashout-account`, {
      headers: { businessId },
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchWithdrawalAccount;
