import styled from 'styled-components';
import { SETTINGS_HEIGHT } from './constants';
import { Button } from 'kudi-component-library';

export const Separator = styled.div<{ height?: string }>`
  background-color: transparent;
  opacity: 0;
  height: ${({ height }) => height || '0px'};
`;

export const SettingsWrapper = styled.div<{ pT?: string }>`
  width: 100%;
  padding-top: ${({ pT }) => pT || '7rem'};
  max-width: 125rem;
`;

// settings section
export const SettingsSectionWrapper = styled.div<{ mB?: string }>`
  display: flex;
  padding-left: 4.3rem;
  padding-right: 12rem;
  width: 100%;
  margin-bottom: ${({ mB }) => mB || '5.7rem'};
`;

export const SettingsSectionLeft = styled.div`
  width: 50rem;
`;

export const SettingsSectionRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  width: 100%;
`;

export const SettingsSectionTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 2.1rem;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const SettingsSectionSubText = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.black3};
`;

export const SectionSeparator = styled.div`
  height: 0.05rem;
  background-color: ${({ theme }) => theme.colors.primary.black5};
  width: 29.2rem;
  margin-bottom: 7rem;
  margin-left: 4.3rem;
`;

export const LogoUploadWrapper = styled.div`
  width: 23.2rem;
  border-radius: 0.5rem;
  border: 1px dashed ${({ theme }) => theme.colors.primary.blue1};
  background-color: ${({ theme }) => theme.colors.primary.blue5};
  padding: 3.6rem 2rem 2.6rem;
  cursor: pointer;
`;

export const LogoUploadTitle = styled.h2`
  margin-top: 1.7rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 180%;
`;

export const LogoUploadSubText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 180%;
`;

export const SettlementWrapper = styled.div`
  margin-top: 6rem;
  padding: 0 4rem 0 0;
`;

export const SettlementTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
`;
export const SettlementTopLeft = styled.div``;

export const SettlementTopLeftTitle = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 2.1rem;
  line-height: 140%;
  /* or 29px */

  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const SettlementTopLeftSub = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 180%;
  /* or 25px */

  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.black3};
`;

export const SettlementTopRight = styled.div``;

export const EmptyStateWrapper = styled.div<{ width?: string; mT?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: ${({ mT }) => mT || '0'};
  width: ${({ width }) => width || '100%'};
`;

export const EmptyStateText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 180%;
  /* or 25px */

  text-align: center;
  color: ${({ theme }) => theme.colors.primary.black3};

  span {
    color: ${({ theme }) => theme.colors.primary.black1};
  }
`;

export const More = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primary.black5};
  margin: 0 auto;
  display: flex;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  div {
    width: 0.33rem;
    height: 0.33rem;
    background-color: ${({ theme }) => theme.colors.primary.blue7};
    border-radius: 50%;
  }
`;

// forms
export const SettingsExtraWrapper = styled.div`
  width: 100%;
  padding: 7rem 0 0 32.7rem;
`;

export const SettingsExtraTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const SettingsExtraSub = styled.p<{ width?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.black3};
  width: ${({ width }) => width || '100%'};
`;

export const SettingsExtraInputExtra = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.black1};
  cursor: pointer;
  width: fit-content;

  span {
    color: ${({ theme }) => theme.colors.primary.black3};
  }
`;
export const UploadHiddenInput = styled.input`
  display: none;
`;

export const LogoActiveHolder = styled.div`
  display: flex;
  align-items: flex-start;

  svg {
    margin-left: 1.3rem;
    cursor: pointer;
  }
`;

export const LogoActiveIconWrapper = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 0.5rem;
  border: 3px solid #efefef;
`;

// new UI

export const SplitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row-reverse;
    gap: 2rem;
  }
`;

export const MainSection = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  @media ${({ theme }) => theme.media.lg} {
    width: 65%;
    min-height: 63.7rem;
    display: flex;
    flex-direction: column;
  }
`;

export const InfoSection = styled.div`
  padding: 4rem 6rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    height: fit-content;
    width: 35%;
    padding: 6rem 6.4rem;
  }

  svg {
    margin-bottom: 2rem;
  }

  /* prettier-ignore */
  span {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #717171;
  }
`;

export const LogoSectionWrapper = styled.div`
  display: flex;
  // align-items: center;
`;

export const LogoHolder = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  font-feature-settings: 'cv11' on;
  color: #383838;
  font-weight: 700;
  margin-right: 2rem;
`;

export const LetterAvatarHolder = styled.div`
  margin-right: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
`;
export const AvatarHolder = styled.img`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin-right: 2rem;
`;

export const LogoButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  margin: 0;
  width: fit-content;
  flex-direction: column;
  p {
    color: #808080;
    line-height: 14px;
  }
`;

export const UploadButton = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'cv11' on;
  color: #0d0d0d;
  width: fit-content;
  max-width: 100px;
  background: ${({ theme }) => theme.nColors['n-yellow2']};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.nColors['n-yellow3']};
  display: flex;
  align-items: center;
  padding: 8px 4px;
  gap: 4px;
  cursor: pointer;
  white-space: nowrap;
  svg path {
    stroke-width: 0.5;
  }
`;

export const DeleteButton = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  font-feature-settings: 'cv11' on;
  color: #121212;
  background: #eeeeee;
  border-radius: 5px;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  cursor: pointer;
`;

export const SectionInputWrapper = styled.div<{ mt?: string; pb?: string }>`
  display: grid;
  width: 100%;
  margin-top: ${({ mt }) => mt ?? '3.5rem'};
  gap: 2rem;
  padding-bottom: ${({ pb }) => pb ?? '5rem'};
  @media ${({ theme }) => theme.media.xl} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const BusinessSectionButtonWrapper = styled.div`
  margin-top: 4.2rem;

  @media ${({ theme }) => theme.media.lg} {
    margin-top: auto;
  }
`;

// settlement new

export const SettlementTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  padding: 2.4rem 2rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  align-items: center;
  gap: 3rem;
`;

export const SettlementTopWrapperText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const SettlementTopWrapperTextTop = styled.span`
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

/* prettier-ignore */
export const SettlementTopWrapperTextBottom = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #999999;
`;

export const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`;

// security section

export const SecuritySectionButtonsWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.black6};
  padding: 4rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
`;

export const HiddenImageUpload = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
`;

export const SecurityButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-direction: column;
  // margin-top: 2rem;
  // margin-bottom: 4rem;
  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;

export const SecurityButtonItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

export const SecurityButtonLabel = styled.p`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-feature-settings: 'cv11' on;
  color: #121212;
  font-weight: 500;
  font-family: 'Inter';
`;

export const SecurityButton = styled(Button)`
  border-radius: 8px;
  span {
    align-items: center;
    display: flex;
    gap: 4px;
  }
  svg path {
    stroke-width: 0.5;
    display: flex;
    height: 16px;
    width: 16px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
`;

export const SwitchLabel = styled.label``;

export const AddIconWrapper = styled.div<{ useWhiteBg?: boolean }>`
  cursor: pointer;
  background: ${({ theme, useWhiteBg }) => (useWhiteBg ? theme.nColors['n-light'] : theme.nColors['n-grey1'])};
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  display: flex;
  svg {
    stroke-width: 2;
  }
`;

export const LoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.nColors['n-light']};
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 3rem;
`;

export const PreferencePageWrapper = styled.div`
  @media ${({ theme }) => theme.media.lg} {
    position: relative;
    width: 100%;
    padding: 40px;

    height: ${SETTINGS_HEIGHT};
  }
  top: 0;
  z-index: 70;
  display: flex;
  width: 100%;
  left: 0;
  height: 100%;
  flex: 1;
  flex-direction: column;
  padding: 33px 20px 0;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
`;

export const PreferencePageMobileHeader = styled.div`
  @media ${({ theme }) => theme.media.lg} {
    display: none;
    margin-bottom: 0;
  }
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  span {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const PreferenceWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
`;

export const PreferenceTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const PreferenceItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const PreferenceTitleWrapper = styled.div`
  @media ${({ theme }) => theme.media.lg} {
    display: flex;
  }

  width: fit-content;
  display: none;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;
`;
export const TwoFactorModalWrapper = styled.div`
  padding: 30px;
`;

export const BodyWrapper = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

export const InfoIconWrapper = styled.div<{ width?: string; height?: string }>`
  cursor: pointer;
  background: ${({ theme }) => theme.nColors['n-light']};
  width: ${({ width }) => width || '18px'};
  height: ${({ height }) => height || '18px'};
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  display: flex;
  svg {
    stroke-width: 1;
  }
`;

export const CheckoutPreferenceWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CheckoutPreferenceWrapper2 = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CheckoutPreferenceInnerWrapper = styled.div`
  width: 100%;
  margin-top: 0.2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const EnableTransferWrapper = styled.div``;

export const TitleHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PreferenceSplitWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  margin-bottom: 2rem;
`;

export const MethodPreferenceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MethodPreference = styled.div`
  margin-left: 0;

  @media ${({ theme }) => theme.media.lg} {
    margin-left: 2rem;
  }
`;

export const MethodPreference1 = styled.div`
  margin-right: 0;
  @media ${({ theme }) => theme.media.lg} {
    margin-right: 10rem;
  }
`;

// new settings layout
export const SettingsAccordionWrapper = styled.div<{ width?: string }>`
  width: 100%;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  @media ${({ theme }) => theme.media.lg} {
    width: ${({ width }) => width || '65%'};
    // min-height: 63.7rem;
    display: flex;
    flex-direction: column;
  }
`;

export const AccordionItem = styled.div<{ borderBottom?: string }>`
  cursor: pointer;
  border-bottom: ${({ borderBottom, theme }) => borderBottom || `1px solid ${theme.colors.primary.black6}`};
`;

export const AccordionItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;

  div {
    display: flex;
    flex-direction: column;
  }
`;

export const AccordionItemBody = styled.div`
  padding: 0px 20px 20px;
`;
