import { useEffect, useState } from 'react';
import useRedux from '../../../hooks/useRedux';
import { GetAllTypes } from '../reducer/thunk_actions';
import { IssueType } from '../defs';
import {
  selectIssueType,
  selectTransaction,
  setIssueDetailsCloseAll,
  toggleShow,
  updateCurrentPage,
} from '../reducer/slice';
import { NewTransactions } from '../../../types/transactions';
import { GetIssueByTransactionId } from '../reducer/thunk_actions/get_issue_by_transaction_ref';

const useIssueFromTrasaction = ({ transactionInfo }: { transactionInfo: NewTransactions | null }) => {
  const {
    dispatch,
    state: {
      support: {
        issueCreation: { selectedCategoryTypeList, isFetching, selectedType },
      },
    },
  } = useRedux();

  const [showIssuetypesModal, setShow] = useState<boolean>(false);

  const handleIssueTypeSelection = (type: IssueType) => {
    dispatch(selectIssueType(type));
  };

  const onContinue = () => {
    dispatch(selectTransaction(transactionInfo));
    dispatch(toggleShow());
    dispatch(updateCurrentPage('summary'));
    dispatch(setIssueDetailsCloseAll(true));
    setShow(false);
  };

  const handleTransactionIssueButtonCLick = () => {
    dispatch(
      GetAllTypes({
        type: transactionInfo?.type,
        onSuccess: (data) => {
          if (data.data.length === 1) {
            handleIssueTypeSelection(data.data[0]);
            dispatch(setIssueDetailsCloseAll(true));
            onContinue();
          } else {
            setShow(true);
          }
        },
      })
    );
  };

  const closeModal = () => {
    dispatch(selectIssueType(null));
    setShow(false);
  };

  useEffect(() => {
    dispatch(GetIssueByTransactionId({ transactionId: transactionInfo?.id || '' }));
  }, [dispatch, transactionInfo?.id]);

  return {
    handleTransactionIssueButtonCLick,
    handleIssueTypeSelection,
    issues: selectedCategoryTypeList,
    isFetching,
    selectedType,
    onContinue,
    showIssuetypesModal,
    closeModal,
  };
};
export default useIssueFromTrasaction;
