import { useCallback, useRef } from 'react';
import { TransactionItemList } from '../../styles';
import { Separator } from '../../../overview/styles';
import TransactionItem from '../base/transaction_item';
import Filter from '../base/filter';
import useRedux from '../../../../hooks/useRedux';
import { FetchTransactions } from '../../reducer/thunk_actions';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import PageLoad from '../../../../components/PageLoad';
import { transactionFilterConfig } from '../../constants';
import {
  clearTransactionFilters,
  clearTransactionPagination,
  updateShowTransactionFilter,
  updateTransactionFilters,
} from '../../reducer/slice';

const Transactions = () => {
  const ref = useRef(null);
  const {
    dispatch,
    state: {
      support: {
        issueCreation: { transactions, isLoading, pagination, showTransactionFilter, filters },
      },
    },
  } = useRedux();
  const fetcTransactions = useCallback(() => {
    if (pagination.lastPage) return;
    dispatch(FetchTransactions({}));
  }, [dispatch, pagination.lastPage]);

  useInfiniteScroll(ref, fetcTransactions);

  const onRefetch = () => {
    dispatch(clearTransactionPagination());
    dispatch(FetchTransactions({}));
    dispatch(updateShowTransactionFilter(false));
  };

  const toggleShow = (value: boolean) => {
    dispatch(updateShowTransactionFilter(value));
  };

  const onClearAll = () => {
    dispatch(clearTransactionFilters());
  };

  const onApply = (filter: Partial<Record<string, string | string[]>>) => {
    dispatch(updateTransactionFilters(filter));
  };

  return (
    <div>
      <Filter
        filterCount={Object.keys(filters).length}
        showFilter={showTransactionFilter}
        toggleShow={toggleShow}
        config={transactionFilterConfig}
        onRefetch={onRefetch}
        onClearAll={onClearAll}
        onApply={onApply}
        disabled={isLoading}
      />
      <Separator height="20px" />
      <TransactionItemList>
        {transactions.map((item) => (
          <TransactionItem item={item} />
        ))}
        {isLoading ? <PageLoad height="70px" /> : <div ref={ref} />}
      </TransactionItemList>
    </div>
  );
};

export default Transactions;
