import * as React from 'react';

const Calendar: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.62598 5.98504C8.21598 5.98504 7.87598 5.66226 7.87598 5.27302V2.42492C7.87598 2.03568 8.21598 1.71289 8.62598 1.71289C9.03598 1.71289 9.37598 2.03568 9.37598 2.42492V5.27302C9.37598 5.66226 9.03598 5.98504 8.62598 5.98504Z"
      fill="#BBBBBB"
    />
    <path
      d="M16.626 5.98504C16.216 5.98504 15.876 5.66226 15.876 5.27302V2.42492C15.876 2.03568 16.216 1.71289 16.626 1.71289C17.036 1.71289 17.376 2.03568 17.376 2.42492V5.27302C17.376 5.66226 17.036 5.98504 16.626 5.98504Z"
      fill="#BBBBBB"
    />
    <path
      d="M9.12598 14.2924C8.99598 14.2924 8.86598 14.2639 8.74598 14.2165C8.61598 14.169 8.51598 14.1025 8.41598 14.0171C8.23598 13.8367 8.12598 13.5994 8.12598 13.343C8.12598 13.2196 8.15598 13.0962 8.20598 12.9823C8.25598 12.8683 8.32598 12.7639 8.41598 12.669C8.51598 12.5835 8.61598 12.5171 8.74598 12.4696C9.10598 12.3272 9.55598 12.4032 9.83598 12.669C10.016 12.8494 10.126 13.0962 10.126 13.343C10.126 13.4 10.116 13.4665 10.106 13.5329C10.096 13.5899 10.076 13.6468 10.046 13.7038C10.026 13.7608 9.99598 13.8177 9.95598 13.8747C9.92598 13.9221 9.87598 13.9696 9.83598 14.0171C9.64598 14.188 9.38598 14.2924 9.12598 14.2924Z"
      fill="#BBBBBB"
    />
    <path
      d="M12.626 14.2922C12.496 14.2922 12.366 14.2638 12.246 14.2163C12.116 14.1688 12.016 14.1023 11.916 14.0169C11.736 13.8365 11.626 13.5992 11.626 13.3429C11.626 13.2194 11.656 13.096 11.706 12.9821C11.756 12.8682 11.826 12.7637 11.916 12.6688C12.016 12.5834 12.116 12.5169 12.246 12.4694C12.606 12.3175 13.056 12.403 13.336 12.6688C13.516 12.8492 13.626 13.096 13.626 13.3429C13.626 13.3998 13.616 13.4663 13.606 13.5327C13.596 13.5897 13.576 13.6467 13.546 13.7036C13.526 13.7606 13.496 13.8175 13.456 13.8745C13.426 13.922 13.376 13.9694 13.336 14.0169C13.146 14.1878 12.886 14.2922 12.626 14.2922Z"
      fill="#BBBBBB"
    />
    <path
      d="M16.126 14.2922C15.996 14.2922 15.866 14.2638 15.746 14.2163C15.616 14.1688 15.516 14.1023 15.416 14.0169C15.376 13.9694 15.336 13.922 15.296 13.8745C15.256 13.8175 15.226 13.7606 15.206 13.7036C15.176 13.6467 15.156 13.5897 15.146 13.5327C15.136 13.4663 15.126 13.3998 15.126 13.3429C15.126 13.096 15.236 12.8492 15.416 12.6688C15.516 12.5834 15.616 12.5169 15.746 12.4694C16.116 12.3175 16.556 12.403 16.836 12.6688C17.016 12.8492 17.126 13.096 17.126 13.3429C17.126 13.3998 17.116 13.4663 17.106 13.5327C17.096 13.5897 17.076 13.6467 17.046 13.7036C17.026 13.7606 16.996 13.8175 16.956 13.8745C16.926 13.922 16.876 13.9694 16.836 14.0169C16.646 14.1878 16.386 14.2922 16.126 14.2922Z"
      fill="#BBBBBB"
    />
    <path
      d="M9.12598 17.6149C8.99598 17.6149 8.86598 17.5864 8.74598 17.5389C8.62598 17.4915 8.51598 17.425 8.41598 17.3396C8.23598 17.1592 8.12598 16.9124 8.12598 16.6655C8.12598 16.5421 8.15598 16.4187 8.20598 16.3048C8.25598 16.1813 8.32598 16.0769 8.41598 15.9915C8.78598 15.6402 9.46598 15.6402 9.83598 15.9915C10.016 16.1719 10.126 16.4187 10.126 16.6655C10.126 16.9124 10.016 17.1592 9.83598 17.3396C9.64598 17.5104 9.38598 17.6149 9.12598 17.6149Z"
      fill="#BBBBBB"
    />
    <path
      d="M12.626 17.6149C12.366 17.6149 12.106 17.5104 11.916 17.3396C11.736 17.1592 11.626 16.9124 11.626 16.6655C11.626 16.5421 11.656 16.4187 11.706 16.3048C11.756 16.1813 11.826 16.0769 11.916 15.9915C12.286 15.6402 12.966 15.6402 13.336 15.9915C13.426 16.0769 13.496 16.1813 13.546 16.3048C13.596 16.4187 13.626 16.5421 13.626 16.6655C13.626 16.9124 13.516 17.1592 13.336 17.3396C13.146 17.5104 12.886 17.6149 12.626 17.6149Z"
      fill="#BBBBBB"
    />
    <path
      d="M16.126 17.6149C15.866 17.6149 15.606 17.5105 15.416 17.3396C15.326 17.2541 15.256 17.1497 15.206 17.0263C15.156 16.9124 15.126 16.789 15.126 16.6655C15.126 16.5421 15.156 16.4187 15.206 16.3048C15.256 16.1814 15.326 16.0769 15.416 15.9915C15.646 15.7731 15.996 15.6687 16.316 15.7352C16.386 15.7446 16.446 15.7636 16.506 15.7921C16.566 15.8111 16.626 15.8396 16.686 15.8776C16.736 15.9061 16.786 15.9535 16.836 15.9915C17.016 16.1719 17.126 16.4187 17.126 16.6655C17.126 16.9124 17.016 17.1592 16.836 17.3396C16.646 17.5105 16.386 17.6149 16.126 17.6149Z"
      fill="#BBBBBB"
    />
    <path
      d="M21.126 9.8679H4.12598C3.71598 9.8679 3.37598 9.54511 3.37598 9.15587C3.37598 8.76663 3.71598 8.44385 4.12598 8.44385H21.126C21.536 8.44385 21.876 8.76663 21.876 9.15587C21.876 9.54511 21.536 9.8679 21.126 9.8679Z"
      fill="#BBBBBB"
    />
    <path
      d="M16.626 22.1245H8.62598C4.97598 22.1245 2.87598 20.1309 2.87598 16.6657V8.59607C2.87598 5.13088 4.97598 3.13721 8.62598 3.13721H16.626C20.276 3.13721 22.376 5.13088 22.376 8.59607V16.6657C22.376 20.1309 20.276 22.1245 16.626 22.1245ZM8.62598 4.56126C5.76598 4.56126 4.37598 5.88088 4.37598 8.59607V16.6657C4.37598 19.3809 5.76598 20.7005 8.62598 20.7005H16.626C19.486 20.7005 20.876 19.3809 20.876 16.6657V8.59607C20.876 5.88088 19.486 4.56126 16.626 4.56126H8.62598Z"
      fill="#BBBBBB"
    />
  </svg>
);

export default Calendar;
