import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { useToast } from '../../../hooks/useToast';
import fetchSettlements from '../../../queries/banking/settlements/fetchSettlements';
import fetchWithdrawalAccount from '../../../queries/banking/settlements/fetchWithdrawalAccount';
import { Settlement, WithDrawalAccount } from '../../../types/banking/settlements';
import { WebBeError } from '../../../types/error';
import { TOAST_TIMER, SETTLEMENTS_PAGE_SIZE } from '../../../utils/constants';

const useSettlementsModel = ({ outletId }: { outletId?: string }) => {
  const [settlements, setSettlements] = useState<Settlement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cursors, setCusors] = useState<string[]>(['']);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [settlementAccount, setSettlementAccount] = useState<WithDrawalAccount | null>(null);
  const [errorFetchingSettlements, setErrorFetchingSettlements] = useState<WebBeError | null>(null);
  const [errorFetchingSettlementAccount, setErrorFetchingSettlementAccount] = useState<WebBeError | null>(null);
  const toast = useToast(TOAST_TIMER);
  const navigate = useNavigate();

  const { parentBusinessId } = useAppSelector((state) => state.auth);

  const handleClickSettlement = (settlement: Settlement) => {
    if (outletId) {
      navigate(`/dashboard/business/outlets/${outletId}/settlements/${settlement.settlementId}`, {
        state: { settlement },
      });
    } else {
      navigate(`/dashboard/payments/settlements/${settlement.settlementId}`, { state: { settlement } });
    }
  };

  const handlePaginationChange = (page: number) => {
    if (cursors[page] !== undefined) {
      const params = new URLSearchParams(searchParams.toString());
      params.set('cursor', cursors[page]);
      setSearchParams(params.toString());
    }
  };

  const handleFilterSettlements = (param: { query: string; value: string | Date }) => {
    const { query, value } = param;

    const newParams = new URLSearchParams(searchParams.toString());
    let paramValue = value;
    if (query === 'dateFrom') {
      paramValue = dayjs(value).format('YYYY-MM-DD');
      newParams.set('dateTo', dayjs().format('YYYY-MM-DD'));
    }
    if (query === 'dateTo') paramValue = dayjs(value).format('YYYY-MM-DD');
    newParams.set(query, String(paramValue));
    setSearchParams(newParams.toString());
  };

  const handleClearSearch = (query: string) => {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.delete(query);
    setSearchParams(newParams.toString());
  };

  useEffect(() => {
    const requestController = new AbortController();

    async function handleFetchSettlements() {
      const params = new URLSearchParams(searchParams.toString());
      params.append('limit', String(SETTLEMENTS_PAGE_SIZE));
      setIsLoading(true);
      const { result, error } = await fetchSettlements({
        businessId: parentBusinessId as number,
        outletId,
        params,
      });
      setIsLoading(false);
      if (result?.data) {
        setSettlements(result.data.list || []);
        if (result.data.pageToken) {
          setCusors((prev) => [...prev, result.data.pageToken]);
          setIsLastPage(false);
        } else {
          setIsLastPage(true);
        }
      }

      if (error) {
        setErrorFetchingSettlements(error as WebBeError);
        setIsLastPage(true);
      }
    }

    handleFetchSettlements();

    return () => requestController.abort();
  }, [parentBusinessId, searchParams, outletId]);

  useEffect(() => {
    const requestController = new AbortController();

    async function handleFetchSettlementAccount() {
      const { result, error } = await fetchWithdrawalAccount({
        businessId: parentBusinessId as number,
      });
      if (result?.data) setSettlementAccount(result.data);
      if (error) setErrorFetchingSettlementAccount(error as WebBeError);
    }

    handleFetchSettlementAccount();

    return () => requestController.abort();
  }, [parentBusinessId]);

  if (errorFetchingSettlements)
    toast(
      'error',
      (errorFetchingSettlements.data.message as string) || 'An error occured while fetching settlements data'
    );

  if (errorFetchingSettlementAccount)
    toast(
      'error',
      (errorFetchingSettlementAccount.data.message as string) || 'An error occured while fetching settlement account'
    );

  return {
    settlements,
    isLoading,
    handleClickSettlement,
    settlementAccount,
    handlePaginationChange,
    isLastPage,
    handleFilterSettlements,
    handleClearSearch,
  };
};

export default useSettlementsModel;
