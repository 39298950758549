import { useState, useRef, ChangeEvent } from 'react';
import { CloseIcon, InfoCircleIcon, Pages, PlusAddIcon } from '../../assets/svg';
import { truncate, uploadToDrake } from '../../utils';
import { InputBox, InputWrapper, Label } from './styles';
import { useToast } from '../../hooks/useToast';
import Spinner from '../../assets/svg/spinner';

interface DocumentUploadProps {
  name: string;
  label?: string;
  value: string;
  onUpload: (val: string) => void;
  base64?: boolean;
  accept?: string;
}

function base64Convert(file: File, callback: (value: string) => void) {
  const reader = new FileReader();
  reader.onload = function (event) {
    const res = event?.target?.result || '';
    callback(res as string);
  };
  reader.readAsDataURL(file);
}

const DocumentUpload = ({ name, label, value, onUpload, base64, accept }: DocumentUploadProps) => {
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState('');

  const toast = useToast(3000);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setUploading(true);
    setFileName(e.target.files[0]?.name);

    if (base64) {
      base64Convert(e.target.files[0], (response) => {
        onUpload(response);
        setUploading(false);
      });
      return;
    }

    await uploadToDrake(
      [e.target.files[0]],
      (response) => {
        onUpload(response[0]);
        setUploading(false);
      },
      () => {
        toast('error', 'Document upload error');
        setUploading(false);
        throw new Error('Document upload error');
      }
    );
  };

  return (
    <InputWrapper>
      {label ? (
        <Label>
          <p>{label}</p> <InfoCircleIcon />
        </Label>
      ) : null}
      <InputBox onClick={() => inputRef?.current?.click()}>
        {value ? (
          <div className="uploaded-state">
            <div className="file">
              <Pages />
              <p>{truncate(fileName || label)}</p>
            </div>
            <CloseIcon />
          </div>
        ) : (
          <div className="empty-state">
            {uploading ? (
              <Spinner style={{ marginBottom: '12px' }} />
            ) : (
              <>
                <PlusAddIcon />
                <p> Choose Files</p>
              </>
            )}
          </div>
        )}
      </InputBox>
      <input
        type="file"
        name={name}
        accept={accept || 'image/png, image/jpeg, application/pdf'}
        onChange={handleUpload}
        ref={inputRef}
      />
    </InputWrapper>
  );
};

export default DocumentUpload;
