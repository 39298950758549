import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  commissionBalance: number;
}

const initialState: InitialState = {
  commissionBalance: 0,
};

export const commissionBalanceSlice = createSlice({
  name: 'commissionBalance',
  initialState,
  reducers: {
    updateCommissionBalance: (state, action: PayloadAction<number>) => {
      state.commissionBalance = action.payload;
    },
  },
});

export const { updateCommissionBalance } = commissionBalanceSlice.actions;
export default commissionBalanceSlice.reducer;
