import {
  GetAllNotificationsCategoryEnum,
  GetAllNotificationsStatusEnum,
  NotificationRespBody,
} from '../../api_spec_client';
import { Notification } from './types';

export const inputAdapter = (data: NotificationRespBody): Notification => ({
  ...data,
  __access_key: String(Math.random()),
  __open: false,
  __checked: false,
  status: data.status as GetAllNotificationsStatusEnum,
  category: data.category as GetAllNotificationsCategoryEnum,
});
