import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/http';
import merchantAxios from '../../../utils/merchantAcqServiceHttp';
import { ReduxAction, ApiResponse } from '../../../types/redux';
import { WithDrawalAccount, KTA } from '../../../types/balance';
import { IPndResponse } from '../../../types/pndCheck';

// ---------- MOCKS ----------//

// ---------------------------   FETCH BALANCE START   ---------------------------//

export interface FetchBalanceResponseData {
  id: string;
  amount: number;
  totalOutletsBalance: number | null;
  consolidatedBalance: number | null;
  lien_amount: number | null;
  creditLimit: number;
  currency: string;
  timeCreated: string;
}

// checkout balance
export interface FetchCheckoutBalanceResponseData {
  id: string;
  amount: number;
  totalOutletsBalance: number | null;
  credit_limit: number;
  currency: string;
  time_created: string;
}
export interface FetchBalanceResponse extends ApiResponse {
  data: FetchBalanceResponseData;
  dataList?: null;
  meta?: null;
}
export interface FetchCheckoutBalanceResponse extends ApiResponse {
  data: FetchCheckoutBalanceResponseData;
  success: boolean;
  message: string;
}

interface FetchBalanceArgs extends ReduxAction {
  id?: number | string;
}
interface FetchBalanceMaskPreferenceArgs extends ReduxAction {
  userEmail: string;
}
export interface FetchBalanceMaskResponse extends ApiResponse {
  message: string;
  sucess: boolean;
  data?: {
    userEmail: string;
    metadata: [
      {
        view: boolean;
        preferenceType: 'BALANCE';
      },
    ];
  };
}
interface SetBalanceMaskPreferenceArgs extends ReduxAction {
  maskBalance: boolean;
  userEmail: string;
}
export interface SetBalanceMaskPreferenceResponse extends ApiResponse {
  data: {
    metadata: [
      {
        view: boolean;
        recipient?: string;
      },
    ];
    balance: {
      userEmail: string;
    };
  };
}

export const fetchBalance = createAsyncThunk(
  '/balance/fetch-balance',
  async ({ onSuccess, onFailure }: FetchBalanceArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchBalanceResponse = await merchantAxios.get(`/webbe/v1/wallet-balance/wallet`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

export const fetchBalanceMaskPreference = createAsyncThunk(
  '/account-preference/balance-mask',
  async ({ userEmail, onSuccess, onFailure }: FetchBalanceMaskPreferenceArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchBalanceMaskResponse = await merchantAxios.get(
        `/webbe/v1/account-preference?userEmail=${userEmail}&preferenceType=BALANCE`
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);
export const setBalanceMaskPreference = createAsyncThunk(
  '/account-preference/set-balance-mask',
  async ({ maskBalance, userEmail, onSuccess, onFailure }: SetBalanceMaskPreferenceArgs, { rejectWithValue }) => {
    try {
      const { data }: SetBalanceMaskPreferenceResponse = await merchantAxios.post(`/webbe/v1/account-preference`, {
        metadata: [
          {
            view: maskBalance,
          },
        ],
        balance: {
          userEmail,
        },
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);
export const fetchCheckoutBalance = createAsyncThunk(
  '/wallet-balance/online-checkout',
  async ({ onSuccess, onFailure }: FetchBalanceArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchCheckoutBalanceResponse = await merchantAxios.get(
        `/webbe/v1/wallet-balance/online-checkout/NGN`
      );

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH BALANCE END   ---------------------------//

// ---------------------------   FETCH WITHDRAWAL ACCOUNTS START   ---------------------------//

export interface FetchWithdrawalAccountsResponse extends ApiResponse {
  data?: WithDrawalAccount;
  dataList: null;
  meta: null;
}

interface FetchWithdrawalAccountsArgs extends ReduxAction {
  businessId: string | number | null;
}

export const fetchWithdrawalAccounts = createAsyncThunk(
  '/balance/fetch-withdrawal-account',
  async ({ businessId, onSuccess, onFailure }: FetchWithdrawalAccountsArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchWithdrawalAccountsResponse = await merchantAxios.get(
        `webbe/v1/wallet/cashout-account
      `,
        {
          headers: {
            businessId: String(businessId),
          },
        }
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure({});
      rejectWithValue({ error });
    }
  }
);
// `/v2/${businessId}/cashout-accounts`

// ---------------------------   FETCH WITHDRAWAL ACCOUNTS END   ---------------------------//

// ---------------------------   INITIATE WITHDRAWAL START   ---------------------------//

export interface InitiateWithdrawalResponse extends ApiResponse {
  data?: {
    agentId: number | string;
    amount: number;
    accountName: string;
    accountNumber: string;
    bankCode: string;
    status: string;
    transactionReference: null;
    timeCreated: string;
  };
  dataList: null;
  meta: null;
}

interface InitiateWithdrawalArgs extends ReduxAction {
  values: {
    amount: number;
    transactionPin: string;
  };
}

export const initiateWithdrawal = createAsyncThunk(
  '/balance/initiate-withdrawal',
  async ({ values, onSuccess, onFailure }: InitiateWithdrawalArgs, { rejectWithValue }) => {
    try {
      const { data }: InitiateWithdrawalResponse = await axios.post(`/cashouts/handle-cashout-request`, {
        ...values,
        transactionSource: 'web',
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   INITIATE WITHDRAWAL END   ---------------------------//

// ---------------------------   FETCH KTA START   ---------------------------//

export interface FetchKTAResponse extends ApiResponse {
  data?: KTA[];
  dataList: null;
  meta: null;
}

interface FetchKTAArgs extends ReduxAction {
  values?: {
    id: number | string;
  };
}

export const fetchKTA = createAsyncThunk(
  '/balance/fetch-kta',
  async ({ values, onSuccess, onFailure }: FetchKTAArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchKTAResponse = await merchantAxios.get(
        `webbe/v1/wallet/kta-accounts
      `,
        {
          headers: {
            businessId: values?.id || '', // businessId is now automatically added to requests on the interceptor. Not removing the implementation to allow backward compatibility
          },
        }
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH KTA END   ---------------------------//

// ---------------------------   PND check   ---------------------------//

interface GetPNDArgs extends ReduxAction {
  businessID: string | number | null;
}

export const getPND = createAsyncThunk(
  '/cashout/business-id',
  async ({ businessID, onSuccess, onFailure }: GetPNDArgs, { rejectWithValue }) => {
    try {
      const data: IPndResponse = await axios.get(`/cashouts/check-pnd?businessId=${businessID as string}`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure({});
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   PND check end   ---------------------------//
