import React from 'react';
import { Typography, Avatar } from 'kudi-component-library';
import { Logo, CloseModal } from '../../assets/svg';
import {
  Wrapper,
  NavTop,
  MobileNavTop,
  MobileNavTopClose,
  NavigationContentHolder,
  NavigationOptionName,
  UserSection,
  UserDetails,
} from './style';
import NavigationSection from './navigationSection';
import { navigationConfig, NavigationContext, NavigationProps } from './data';
import { FullFlex } from '../../styles/generic';
import { useAppSelector } from '../../app/hooks';

const GuestNavigation = ({ open, setOpen }: { open: boolean } & NavigationProps) => {
  const { businessImageUrl } = useAppSelector((state) => state.settings);

  return (
    <NavigationContext.Provider value={React.useMemo(() => ({ setOpen }), [setOpen])}>
      <Wrapper id="guest-navigation-wrapper" onClick={(e) => e.stopPropagation()} open={open}>
        {/* Logo top */}

        <FullFlex flex={1} flexDirection="column">
          <NavTop id="guest-navigation-top">
            <Logo id="guest-navigation-logo" />
          </NavTop>
          <MobileNavTop id="guest-navigation-mobile-top">
            <MobileNavTopClose id="guest-navigation-mobile-top-close" onClick={() => setOpen(false)}>
              <CloseModal id="guest-navigation-mobile-top-close-icon" />
            </MobileNavTopClose>
          </MobileNavTop>

          {/* content map */}
          <NavigationContentHolder id="guest-navigation-content-list-wrapper">
            {navigationConfig.map(({ title, data, baseRoute }, index) => (
              <NavigationSection data={data} title={title} baseRoute={baseRoute} key={`navigation-section-${index}`} />
            ))}
          </NavigationContentHolder>
        </FullFlex>

        {/* footer */}
        <UserSection>
          <Avatar initials="GU" imgUrl={businessImageUrl} size="medium" />
          <UserDetails>
            <NavigationOptionName>
              <Typography variant="n-text2" color="grey3">
                Guest User
              </Typography>
            </NavigationOptionName>
          </UserDetails>
        </UserSection>
      </Wrapper>
    </NavigationContext.Provider>
  );
};
export { GuestNavigation };
