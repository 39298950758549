import { ReactNode } from 'react';
import { IconsEnum, NIcons } from 'kudi-component-library';
import { TransactionTypes } from '../../../../utils/transactionEnums';
import BillPayment from '../../../../assets/svg/bill-payment';
import { TransactionDataPoint } from '../../../../types/transactions';
import { capitalizeFirstLetter } from '../../../../utils';

type TransactionDetailsHeader = {
  icon: IconsEnum | ReactNode;
  headerText: string;
};

export const getDetailbyKey = (transaction: TransactionDataPoint[] | null, key: string) => {
  return transaction?.filter((item) => item?.title?.toLowerCase() === key?.toLowerCase())[0];
};

export const formatDetailTitle = ({ str, separator }: { str: string | null; separator?: string }) => {
  if (str) {
    return str
      .split(separator || '_')
      ?.map((word) =>
        word.length > 2 && word !== 'RRN' ? capitalizeFirstLetter(word.toLowerCase()) : word.toUpperCase()
      )
      ?.join(' ');
  }
  return str;
};

export const getTransactionDetailsHeader = (
  transactionType: keyof typeof TransactionTypes
): TransactionDetailsHeader => {
  switch (transactionType) {
    case 'transfer':
    case 'qrt_debit':
    case 'pos_purchase':
    case 'gotv':
    case 'saving_withdrawal':
      return {
        icon: <NIcons icon={IconsEnum.OUTLINE_UP} />,
        headerText: 'Money out',
      };

    case 'pay_by_transfer':
    case 'qrt_credit':
    case 'saving_deposit':
    case 'withdrawal':
    case 'purchase':
    case 'referral_earning':
    case 'collections':
    case 'wallet_topup':
    case 'cash_in':
    case 'kshock_disbursement':
    case 'pos_refund':
    case 'pay_by_qr':
    case 'online_checkout':
      return {
        icon: <NIcons icon={IconsEnum.OUTLINE_DOWN} />,
        headerText: 'Money in',
      };

    case 'p2p':
      return {
        icon: <NIcons icon={IconsEnum.OUTLINE_UP} />,
        headerText: 'P2P Transfer',
      };

    case 'topup':
      return {
        icon: <BillPayment />,
        headerText: 'Airtime purchase',
      };

    case 'phcn':
    case 'startimes':
    case 'dstv':
      return {
        icon: <BillPayment />,
        headerText: 'Utility Purchase',
      };

    case 'data': {
      return {
        icon: <BillPayment />,
        headerText: 'Data Purchase',
      };
    }

    default:
      return {
        icon: <NIcons icon={IconsEnum.OUTLINE_DOWN} />,
        headerText: 'Money in',
      };
  }
};

export const transactionDetailSchema: TransactionDataPoint[] = [];
