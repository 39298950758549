import appConfig from '../../config';

export const teamsRoutes = {
  overview: '',
  viewPermissions: 'view-permissions',
  auth: 'accept-teams-invite',
  accountCreated: 'teams/account-created',
  foundUser: 'teams/user-found',
  invalidToken: 'teams/invalid-token',
};

export const DefaultRole = 'Owner';

export const IS_TEAMS_ON = appConfig.teamsFlipper === 'ON';
