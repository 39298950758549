import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Transactions = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M15.556 6.728a.45.45 0 0 1 0 .636L11.96 10.96a.45.45 0 0 1-.636-.636l2.83-2.829H4.45a.45.45 0 0 1 0-.899h9.703l-2.829-2.829a.45.45 0 0 1 .636-.635l3.596 3.596Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      d="M15.688 12.954a.45.45 0 0 1-.45.45H5.536l2.829 2.829a.45.45 0 1 1-.636.635l-3.596-3.596a.45.45 0 0 1 0-.636L7.728 9.04a.45.45 0 1 1 .636.636l-2.83 2.829h9.704a.45.45 0 0 1 .45.45Z"
    />
  </svg>
);

export default Transactions;
