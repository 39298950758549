/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
import { Button, Text, TextInput } from 'kudi-component-library';
import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { AddBeneficiaryIcon } from '../../../assets/svg';
import Modal from '../../../components/Modal';
import { FullBox, FullFlex } from '../../../styles/generic';
import { bankOptions, bankCodes } from '../../../data';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addBeneficiary, kycAccountLookUp } from '../../../features/transfers/thunkActions';
import { Mixpanel } from '../../../hooks/useMixpanel';
import Select from '../../../components/Select';

interface InitialValues {
  accountNumber: string;
}
const InitialFormValues: InitialValues = {
  accountNumber: '',
};

function ResponseModal({
  showResponseModal,
  setShowResponseModal,
}: {
  showResponseModal: boolean;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal showModal={showResponseModal} onClose={() => setShowResponseModal(false)}>
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="90px">
        <AddBeneficiaryIcon />

        <Text
          id="add-beneficiary-success-text"
          color="#121212"
          fontSize="24px"
          fontWeight="bold"
          textAlign="center"
          mt="30px"
        >
          Successfully added beneficiary
        </Text>
        <Text color="#717171" fontSize="14px" textAlign="center">
          You can now transfer money to the added beneficiary.
        </Text>
      </FullFlex>
    </Modal>
  );
}

export default function AddBeneficiaryModal({
  showAddBeneficiaryModal,
  setShowAddBeneficiaryModal,
  setReload,
  reload,
}: {
  showAddBeneficiaryModal: boolean;
  setShowAddBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
}) {
  const [bankName, setBankName] = React.useState<string>('');
  const [bankCode, setBankCode] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState('');
  const [beneficiaryName, setBeneficiaryName] = React.useState('');
  const [beneficiaryNickName, setBeneficiaryNickName] = React.useState('');
  const [accountLookupError, setAccountLookupError] = React.useState('');
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false);

  const dispatch = useAppDispatch();
  const { isSubmitting, inProgress } = useAppSelector((state) => state.transfers);
  const { fullUser } = useAppSelector((state) => state.auth);

  const AccountLookUp = useCallback(() => {
    if (bankName === '' || accountNumber === '') return;
    if (accountNumber.length < 10) {
      setBeneficiaryName('');
      return;
    }
    const selectedBank = bankCodes.find((bank) => bank.name === bankName);
    if (!selectedBank) {
      return;
    }
    if (btnDisabled) {
      return;
    }

    dispatch(
      kycAccountLookUp({
        userId: fullUser?.connectedBusinesses[0].coreUserId,
        bankCode: selectedBank?.bankCode,
        accountNumber,
        amount: '0',
        type: 'transfer',
        onFailure: () => {
          setAccountLookupError('Account not found');
        },
        onSuccess: (result: {
          data: { accountName: string };
          message: string;
          status: string;
          transactionCharge: number;
        }) => {
          setBeneficiaryName(result.data.accountName);
          setBankCode(selectedBank?.bankCode);
        },
      })
    );
  }, [dispatch, accountNumber, bankName]);

  useEffect(() => {
    AccountLookUp();
  }, [AccountLookUp]);

  function handleAddBeneficiary() {
    dispatch(
      addBeneficiary({
        accountNumber,
        beneficiaryName,
        bankName,
        bankCode,
        nickName: beneficiaryNickName,
        onFailure: (error: { data: { msg: string } }) => {
          setShowAddBeneficiaryModal(false);
          setAccountLookupError(error?.data?.msg);
          setBankName('');
          setAccountNumber('');
          setBeneficiaryName('');
          setBeneficiaryNickName('');
        },
        onSuccess: () => {
          setShowAddBeneficiaryModal(false);
          setShowResponseModal(true);
          setReload(!reload);
          setBankName('');
          setAccountNumber('');
          setBeneficiaryName('');
          setBeneficiaryNickName('');
        },
      })
    );
  }

  function validateAccountNumber(val: string) {
    // check length
    if (val.length < 10 && val.length !== 0) {
      setAccountLookupError('Account number must be at least 10 digits');
      setBtnDisabled(true);
      return;
    }
    if (val.length > 10) {
      setAccountLookupError('Account number must not exceed 10 digits');
      setBtnDisabled(true);
      return;
    }
    // verify only digits
    const isnum = /^\d+$/.test(val);
    if (!isnum) {
      setAccountLookupError('Account number must contain only numbers');
      setBtnDisabled(true);
      return;
    }
    setAccountLookupError('');
    setBtnDisabled(false);
  }

  return (
    <FullBox>
      <Modal showModal={showAddBeneficiaryModal} onClose={() => setShowAddBeneficiaryModal(false)}>
        <Text id="add-beneficiary-text" textAlign="center" color="#121212" fontSize="24px" fontWeight="bold">
          Add Beneficiary
        </Text>
        <Text color="#717171" fontSize="14px" fontWeight="regular" textAlign="center">
          Save a beneficiary for easy transfer
        </Text>
        <FullBox mt="30px" p="20px">
          <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
            <FullBox mb="20px" width="100%">
              <Select
                name="bankname"
                label="Select bank"
                placeholder="Select bank"
                options={bankOptions[0]}
                width="100%"
                value={bankName}
                onChange={(e) => {
                  Mixpanel.track('Select Bank', {
                    first_name: String(fullUser?.firstName),
                    last_name: String(fullUser?.lastName),
                    phone_number: String(fullUser?.phoneNumber),
                    email: String(fullUser?.email),
                    bank: String(e.target.value),
                  });
                  e.target.value && setBankName(e.target.value);
                }}
              />
            </FullBox>
            <Formik initialValues={InitialFormValues} onSubmit={() => { }}>
              <FullBox mb="20px" width="100%">
                <TextInput
                  label="Account number"
                  placeholder="Enter your account"
                  name="account_number"
                  value={accountNumber}
                  onChange={(e) => {
                    validateAccountNumber(e.target.value);
                    setAccountNumber(e.target.value);
                  }}
                  width="100%"
                  error={accountLookupError}
                />
              </FullBox>
            </Formik>
            <Formik initialValues={InitialFormValues} onSubmit={() => { }}>
              <TextInput
                label="Beneficiary name"
                placeholder=""
                name="beneficiary_name"
                value={inProgress ? 'Please wait...' : beneficiaryName}
                readOnly
                onChange={() => { }}
                width="100%"
                style={{ marginBottom: '20px' }}
              />
            </Formik>
            <Formik initialValues={InitialFormValues} onSubmit={() => { }}>
              <TextInput
                label="Beneficiary nickname (Optional)"
                placeholder="Enter nickname"
                name="beneficiary_name"
                value={beneficiaryNickName}
                onChange={(e) => {
                  setBeneficiaryNickName(e.target.value);
                }}
                width="100%"
                style={{ marginBottom: '50px' }}
              />
            </Formik>

            {bankName === '' || accountNumber === '' || beneficiaryName === '' || btnDisabled ? (
              <Button
                id="add-beneficiary-btn=disabled"
                disabled
                width="100%"
                variant="xlarge"
                style={{ backgroundColor: '#FFCC00', color: '#121212' }}
              >
                Add beneficiary
              </Button>
            ) : (
              <Button
                id="add-beneficiary-btn"
                onClick={() => {
                  handleAddBeneficiary();
                }}
                width="100%"
                variant="xlarge"
                style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                disabled={!!isSubmitting}
              >
                Add beneficiary
              </Button>
            )}
          </FullFlex>
        </FullBox>
      </Modal>
      {showResponseModal && (
        <ResponseModal showResponseModal={showResponseModal} setShowResponseModal={setShowResponseModal} />
      )}
    </FullBox>
  );
}
