import moment from 'moment';
import useRedux from '../../../../hooks/useRedux';
import { IssueDetailsInfoCompletedItem, IssueDetailsInfoWrapper } from '../../styles';

const CompletedInfo = () => {
  const {
    state: {
      support: {
        issueList: { selectedIssue },
      },
    },
  } = useRedux();
  const list = [
    { title: 'Issue Category:', description: selectedIssue?.id },
    { title: 'Issue Type:', description: selectedIssue?.meta?.type },
    { title: 'Date and time:', description: moment(selectedIssue?.timeCreated).format('MMM DD, YYYY') },
    {
      title: 'Description',
      description: selectedIssue?.description,
    },
  ];

  return (
    <IssueDetailsInfoWrapper>
      {list.map((item) => (
        <IssueDetailsInfoCompletedItem key={Math.random()}>
          <span>{item?.title}</span>
          <p>{item?.description}</p>
        </IssueDetailsInfoCompletedItem>
      ))}
    </IssueDetailsInfoWrapper>
  );
};

export default CompletedInfo;
