import { Button, IconsEnum, NIcons, Typography, VerticalCard } from 'kudi-component-library';
import { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { useTheme } from 'styled-components';
import Modal from '../../../../components/Modal';
import { FullBox } from '../../../../styles/generic';
import { UnderLinedText } from '../../../invoicing/styles';
import { PageBadgeCounter } from '../../styles';
import { MenuListProduct } from '../../../../types/menu/menuList';

interface Steps {
  id: string;
  title: string;
  description: string;
  path: 'inventory' | 'new';
  Icon: JSX.Element;
  isActive: boolean;
}

interface Steps {
  id: string;
  title: string;
  description: string;
  path: 'inventory' | 'new';
  Icon: JSX.Element;
  isActive: boolean;
}

export default function SelectMealTypeModal({
  showModal,
  newMenu,
  onClose,
  onSelectMealType,
  products,
  handleFetchProducts,
}: {
  showModal: boolean;
  newMenu: boolean;
  onClose: () => void;
  products: MenuListProduct[];
  onSelectMealType: (mealType?: 'inventory' | 'new') => void;
  handleFetchProducts: () => Promise<void>;
}) {
  const [activeStep, setActiveStep] = useState<'inventory' | 'new'>('new');

  const showSteps: Steps[] = [
    {
      id: '20',
      title: 'Create new items',
      description:
        'Start creating your menu by adding your preferred meals with their category, price, and description.',
      path: 'new',
      Icon: <NIcons icon={IconsEnum.OUTLINE_PAGES} />,
      isActive: activeStep === 'new',
    },
    {
      id: '21',
      title: 'Choose from inventory',
      description: 'Save ample amount of time by picking from existing items in your  inventory. ',
      path: 'inventory',
      Icon: <NIcons icon={IconsEnum.OUTLINE_STATS} />,
      isActive: activeStep === 'inventory',
    },
  ];

  useEffect(() => {
    handleFetchProducts();
  }, []);

  const theme = useTheme();
  return (
    <Modal customDesktopWidth="985px" customTabletWidth="65rem" showModal={showModal} onClose={() => onClose()}>
      <FullBox>
        {newMenu && (
          <PageBadgeCounter>
            <>
              <NIcons icon={IconsEnum.OUTLINE_CHECK} color="n-yellow" height="12" width="12" />
              <Typography style={{ fontSize: '12px', marginLeft: '5px' }}>Step 3 of 3</Typography>
            </>
          </PageBadgeCounter>
        )}
        <Flex justifyContent="center">
          <Flex justifyContent="center" flexDirection="column" maxWidth="70%" my="200px">
            <Flex justifyContent="center" flexWrap="wrap" style={{ gap: '20px' }}>
              {showSteps.map((step: Steps, index: number) => {
                // If inventory does not have products, then we do not show the option of inventory.
                if (products.length === 0 && index === 1) {
                  return;
                }
                return (
                  <FullBox onClick={() => setActiveStep(step.path)} key={step.id}>
                    <VerticalCard
                      checked={false}
                      {...step}
                      Icon={step.Icon}
                      maxWidth="300px"
                      titleProps={{
                        color: theme.colors.primary.green1,
                        fontWeight: 'medium',
                      }}
                      onChange={() => {}}
                      descriptionProps={{
                        color: theme.colors.primary.black3,
                        fontSize: '12px',
                      }}
                    />
                  </FullBox>
                );
              })}
            </Flex>
            <FullBox mt="24px">
              <Button
                disabled={!activeStep}
                onClick={() => {
                  onSelectMealType(activeStep);
                  onClose();
                }}
                variant="small"
                width="100%"
              >
                Continue
              </Button>
              <UnderLinedText top={20} onClick={() => onClose()}>
                <span> Go Back </span>
              </UnderLinedText>
            </FullBox>
          </Flex>
        </Flex>
      </FullBox>
    </Modal>
  );
}
