import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Text } from 'kudi-component-library';
import Modal from '../../../components/Modal';
import { FullBox, FullFlex } from '../../../styles/generic';
import {
  BulkTransferIcon,
  SingleTransferIcon,
  SingleTransferInactiveIcon,
  BulkTransferActiveIcon,
} from '../../../assets/svg';
import SingleTransfer from './singletransfermodal';
import { isTransfersComplete } from '../../../utils';

export default function TransferToBank({
  showTransferToBankModal,
  setShowTransferToBankModal,
  setShowPopup,
}: {
  showTransferToBankModal: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTransferToBankModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [single, setSingle] = React.useState<boolean>(true);
  const [bulk, setBulk] = React.useState<boolean>(false);
  const [showSingleTransfer, setShowSingleTransfer] = React.useState<boolean>(false);

  const navigate = useNavigate();

  function handleContinue() {
    if (single) {
      setShowTransferToBankModal(false);
      setShowSingleTransfer(true);
      setShowPopup(false);
    } else {
      navigate('/dashboard/payments/transfer/bulk');
    }
  }

  return (
    <>
      <Modal
        showModal={showTransferToBankModal}
        onClose={() => {
          setShowPopup(false);
          setShowTransferToBankModal(false);
        }}
      >
        <Text
          id="new-transfer"
          textAlign="center"
          color="#121212"
          fontSize="24px"
          fontWeight="bold"
          mb="10px"
          style={{ paddingTop: '20px' }}
        >
          New Transfer
        </Text>
        <Text textAlign="center" color="#717171" fontSize="14px" fontWeight="regular" mb="30px">
          Send money the way you want
        </Text>
        {/* Single Transfer Starts */}
        <FullBox p="20px">
          <FullBox style={{ border: single ? '1px solid #FFCC00' : '1px solid #EEEEEE', borderRadius: '5px' }} p="20px">
            <FullFlex justifyContent="space-between">
              <FullBox>
                <FullFlex alignItems="flex-start">
                  {single ? <SingleTransferIcon /> : <SingleTransferInactiveIcon />}
                  <FullBox ml="20px">
                    <Text id="single-transfer-text" color="#121212" fontSize="18px" fontWeight="semiBold">
                      Single transfer
                    </Text>
                    <Text color="#717171" fontSize="14px">
                      Make a single bank transfer to any bank account
                    </Text>
                  </FullBox>
                </FullFlex>
              </FullBox>

              <FullBox>
                <Checkbox
                  id="bank-transfer-single"
                  name="single"
                  checked={single}
                  onClick={() => {
                    setSingle(true);
                    setBulk(false);
                  }}
                >
                  {' '}
                </Checkbox>
              </FullBox>
            </FullFlex>
          </FullBox>
        </FullBox>
        {/* Single Transfer Ends */}
        {
          isTransfersComplete() && (
            // Bulk Transfer Starts
            <FullBox p="20px">
              <FullBox
                style={{ border: !bulk ? '1px solid #EEEEEE' : '1px solid #FFCC00', borderRadius: '5px' }}
                p="20px"
              >
                <FullFlex justifyContent="space-between">
                  <FullBox>
                    <FullFlex alignItems="flex-start">
                      {!bulk ? <BulkTransferIcon /> : <BulkTransferActiveIcon />}
                      <FullBox ml="20px">
                        <Text color="#121212" fontSize="18px" fontWeight="semiBold">
                          Bulk transfer
                        </Text>
                        <Text color="#717171" fontSize="14px">
                          Make a bulk bank transfer to any bank account
                        </Text>
                      </FullBox>
                    </FullFlex>
                  </FullBox>

                  <FullBox>
                    <Checkbox
                      id="bank-transfer-bulk"
                      name="bulk"
                      checked={bulk}
                      onClick={() => {
                        setBulk(true);
                        setSingle(false);
                      }}
                    >
                      {' '}
                    </Checkbox>
                  </FullBox>
                </FullFlex>
              </FullBox>
            </FullBox>
          )
          // Bulk Transfer Ends
        }

        <FullBox p="20px">
          <Button
            id="bank-transfer-continue"
            onClick={() => handleContinue()}
            width="100%"
            variant="large"
            style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '20px' }}
          >
            Continue
          </Button>
        </FullBox>
      </Modal>
      <SingleTransfer showSingleTransfer={showSingleTransfer} setShowSingleTransfer={setShowSingleTransfer} />
    </>
  );
}
