import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { TransactionItemLeft, TransactionItemWraper } from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import { selectTransaction, updateCurrentPage } from '../../reducer/slice';
import { NewTransactions } from '../../../../types/transactions';
import { formatCurrency } from '../../../../utils';
import { getResultStatusColor } from '../../../../components/header/components/GlobalSearch/utils';

const TransactionItem = ({ item }: { item: NewTransactions }) => {
  const { dispatch } = useRedux();
  const theme = useTheme();

  const color = getResultStatusColor(item.status, theme);

  const handleClick = () => {
    dispatch(selectTransaction(item));
    dispatch(updateCurrentPage('summary'));
  };

  return (
    <TransactionItemWraper onClick={handleClick}>
      <TransactionItemLeft>
        <div>
          <NIcons icon={IconsEnum.OUTLINE_DESKTOP} />
        </div>
        <p>
          <Typography fontWeight={500} fontSize="14px" lineHeight="22px">
            <Typography textTransform="uppercase" as="span" fontWeight={500} fontSize="14px" lineHeight="22px">
              {item.type}
            </Typography>
            {item.recipientName || item.ktaSenderName ? ' - ' : ''}
            {item.recipientName || item.ktaSenderName}
          </Typography>

          <Typography fontWeight={400} fontSize="12px" lineHeight="16px">
            {moment(item.timeCreated).format('MMM Do YYYY, h:mm a')}
          </Typography>
        </p>
      </TransactionItemLeft>
      <p className="endText">
        <Typography fontWeight={500} fontSize="14px" lineHeight="22px">
          {formatCurrency(item.amount, true)}
        </Typography>

        <Typography fontWeight={400} fontSize="12px" lineHeight="16px" style={{ color }}>
          {item.status}
        </Typography>
      </p>
    </TransactionItemWraper>
  );
};

export default TransactionItem;
