import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  activeWallet: string;
}

const initialState: InitialState = {
  activeWallet: 'primary',
};

export const activeWalletSlice = createSlice({
  name: 'activeWallet',
  initialState,
  reducers: {
    setActiveWallet: (state, action: PayloadAction<string>) => {
      state.activeWallet = action.payload;
    },
  },
});

export const { setActiveWallet } = activeWalletSlice.actions;
export default activeWalletSlice.reducer;
