import { Typography, NTheme as theme } from 'kudi-component-library';
import styled from 'styled-components';
import { convertHexToRGBA } from '../../utils/styles';

export const TableNotificationItemWrapper = styled.div<{ open: boolean }>`
  cursor: ${({ open }) => (open ? '' : 'pointer')};
  padding: 14px 0 12px;
`;

export const TableNotificationItemMetaWrapper = styled.div`
  margin-top: 21px;
`;

export const TableNotificationItemDescrition = styled(Typography)<{ open: boolean }>`
  overflow: ${({ open }) => (open ? 'auto' : 'hidden')};
  text-overflow: ${({ open }) => (open ? 'inherit' : 'ellipsis')};
  white-space: ${({ open }) => (open ? 'inherit' : 'nowrap')};

  max-width: 500px;
`;

export const TableNotificationActionsItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 24px;
`;

export const TableNotificationActionsContentViewWrapper = styled.div<{ open?: boolean; read: boolean }>`
  cursor: pointer;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  height: 24px;
  svg {
    path {
      stroke: ${({ read }) => (read ? theme.nColors['n-grey3'] : theme.nColors['n-dark'])};
    }
  }
`;

export const TableNotificationActionsContentReadWrapper = styled.div<{ read: boolean }>`
  height: 24px;
  position: relative;
  svg {
    path {
      stroke: ${({ read }) => (read ? theme.nColors['n-grey3'] : theme.nColors['n-dark'])};
    }
  }
`;

export const TableNotificationItenRefInput = styled.input`
  outline: none;
  border: none;
  height: 0;
  width: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  background: transparent;
`;

export const NotificationHideOnMobile = styled.div`
  display: none;

  @media ${theme.media.lg} {
    display: block;
  }
`;

export const NotificationMobileHeaderWrapper = styled.div`
  @media ${theme.media.lg} {
    display: none;
  }

  display: flex;
  flex-direction: column;
  height: 130px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: ${theme.colors.primary.white};
`;

export const NotificationMobileHeaderTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: auto;
  padding: 30px 20px 0;
`;

export const NotificationMobileHeaderTopSettings = styled.div`
  display: flex;
`;

export const NotificationMobileClose = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.nColors['n-grey1']};

  svg {
    scale: 0.75;
  }
`;

export const MobileTableViewWrapper = styled.div`
  @media ${theme.media.lg} {
    display: none;
    position: none;
  }

  display: block;
  position: fixed;
  top: 130px;
  width: 100%;
  left: 0;
  height: calc(100svh - 130px);
  overflow-y: scroll;
`;

export const MobileTableViewStatusWrapper = styled.div`
  @media ${theme.media.lg} {
    display: none;
  }

  display: block;
`;

export const MobileTableViewStatusInitWrapper = styled.div<{ state: 'init' | 'loading' | 'complete' }>`
  display: ${({ state }) => (state === 'init' ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 46px;
  background-color: ${theme.nColors['n-grey0']};

  div {
    background-color: ${theme.nColors['n-red']};
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  p {
    span {
      font-weight: 500;
    }
  }
`;

export const MobileTableViewStatusLoadingtWrapper = styled.div<{ state: 'init' | 'loading' | 'complete' }>`
  display: ${({ state }) => (state === 'loading' ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  height: 46px;
  padding-top: 220px;
  margin-top: -220px;
  padding-bottom: 25px;
  background-color: ${theme.nColors['n-light']};
`;

export const MobileTableContentWrapper = styled.div<{ totalUnread: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 23px 0;
  background-color: ${theme.nColors['n-light']};
`;

export const MobileTableListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const MobileTableItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 13px;
  cursor: pointer;
`;

export const MobileTableItemTextrapper = styled.div<{ textWidth?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ textWidth }) => textWidth || 'calc(100% - 83px)'};

  @media (max-width: 600px) {
    width: calc(100vw - 123px);
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const MobileTableItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.nColors['n-grey1']};
  svg {
    scale: 0.75;
  }
`;

export const MobileTableItemReadStatus = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ open }) => (open ? theme.nColors['n-grey1'] : theme.nColors['n-yellow'])};
  }
`;

export const MobileExpandedViewWrapper = styled.div`
  @media ${theme.media.lg} {
    display: none;
    position: none;
  }

  display: block;
  position: fixed;
  z-index: 90;
  top: 0;
  width: 100%;
  left: 0;
  height: 100svh;
  background-color: ${theme.nColors['n-grey1']};
  overflow-y: scroll;
`;

export const MobileExpandedViewContentWrapper = styled.div`
  position: relative;
  padding: 20px 20px 23px;
  height: fit-content;
  width: calc(100% - 40px);
  margin: 20px auto;
  overflow-y: scroll;
  background-color: ${theme.nColors['n-light']};
`;

export const MobileExpandedViewHeaderWrapper = styled.div`
  display: flex;
  height: 82px;
  padding: 33px 23px;
  border-bottom: 1px solid ${theme.nColors['n-grey1']};
  background-color: ${theme.nColors['n-light']};

  p {
    margin-left: 112px;
  }

  svg {
    margin-top: 2px;
  }
`;

export const MobileExpandedViewHeaderDateTextWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

// ------------------ EXPANDED VIEW -----------------

export const MobileExpandedViewHeaderDateText = styled(Typography)`
  background-color: ${theme.nColors['n-light']};
  width: fit-content;
  margin: 0 auto;
  padding: 0 11px;

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 100%;
    height: 1px;
    background-color: ${theme.nColors['n-grey1']};
    z-index: -1;
  }
`;

export const GeneralViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SystemViewSupportButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const NotificationViewImageWrapper = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

// ------------------ EXPANDED VIEW END -----------------

export const CampaignMobileItemWrapper = styled.div`
  display: flex;
  width: 100;
  flex-direction: column;
`;

export const CampaignMobileItemImage = styled.img`
  height: 145px;
`;

export const CampaignMobileItemButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CampaignMobileItemTextWrapper = styled.div`
  display: flex;
  width: 100;
  flex-direction: column;
  padding: 10px;

  P {
    span {
      font-weight: 600;
    }
  }
`;

export const DestopCampaignModalWrapper = styled.div`
  display: none;
  position: none;

  @media ${theme.media.lg} {
    position: fixed;
    display: flex;
  }

  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: ${convertHexToRGBA(theme.nColors['n-dark'], 0.25)};
  z-index: 90;
  top: 0;
  left: 0;
`;

export const DestopCampaignModalContentWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: fit-content;
  overflow-y: scroll;
  padding: 15px 15px 0;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 52;
  background-color: ${theme.nColors['n-light']};
`;

export const DestopCampaignModalTop = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const DestopCampaignModalTopCloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 40px;
  height: 40px;
  background-color: ${theme.nColors['n-grey0']};
  border-radius: 50%;

  position: sticky;
  top: 0;

  span {
    height: 20px;
    width: 20px;
  }
`;

export const DestopCampaignModalCampaignViewWrapper = styled.div`
  padding: 0 57px;
  height: fit-content;
  margin-top: 3px;
  padding-bottom: 60px;
`;

export const MobileActionsModalWrapper = styled.div`
  @media ${theme.media.lg} {
    display: none;
  }

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  z-index: 90;
  background-color: ${convertHexToRGBA(theme.nColors['n-dark'], 0.25)};
`;

export const MobileActionsContentWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => height || ' 347px'};
  width: 100%;
  background-color: ${theme.nColors['n-light']};
  border-radius: 20px 20px 0 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileActionsContentItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobileActionsContentItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const HeaderTopFlexActionsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: 30px;
  align-items: center;
  span {
    height: 20px;
    width: 20px;
  }
`;

export const TableCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  position: relative;

  > div {
    height: 24px;
  }
`;

export const TableCheckBoxToggleWrapper = styled.div<{ open: boolean }>`
  cursor: pointer;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export const TableCheckBoxOptions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 27px;
  left: 30px;
  z-index: 50;
  padding: 14px 15px;
  background-color: ${theme.nColors['n-light']};
  box-shadow: 0px 4px 10px 0px ${convertHexToRGBA(theme.nColors['n-dark'], 0.05)};
  gap: 16px;
  height: fit-content !important;

  p {
    cursor: pointer;
  }
`;

export const TootTipIconWrapper = styled.div<{ read?: boolean }>`
  position: relative;
  cursor: pointer;

  > div {
    max-width: fit-content;
    width: fit-content !important;
    min-height: fit-content;
    text-overflow: nowrap;

    svg {
      path {
        stroke: ${theme.nColors['n-dark']} !important;
      }
    }
  }

  svg {
    path {
      stroke: ${({ read = false }) => (read ? theme.nColors['n-grey3'] : theme.nColors['n-dark'])};
    }
  }
`;

export const UnreadFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;
  background-color: ${theme.nColors['n-light']};
`;

export const ClearFilterWrapper = styled.div`
  position: relative;
  width: fit-content;
  cursor: pointer;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${theme.nColors['n-yellow']};
  }
`;

export const RemoveSvgStroke = styled.div`
  svg {
    stroke: none;
  }
`;

export const ExpandedBottomText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 30px;
  width: 100%;

  p {
    position: relative;
    cursor: pointer;
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: ${theme.nColors['n-yellow']};
      bottom: -3px;
      left: 0;
    }
  }
`;
