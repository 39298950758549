/* eslint-disable no-shadow */
import { IdType } from '../types/dashboard';

import {
  MerchantType,
  BusinessCategory,
  BusinessType,
  BusinessCategoryReturned,
  BusinessCategories,
  ReviewConfig,
} from '../types/auth';

export const OnboardingRoutes = {
  login: 'login',
  forgot_password: 'forgot-password',
  verification: 'verification',
  reset_password: 'reset-password',
  two_factor: 'two-factor',
  signup: 'signup',
  merchant_type: 'merchant-type',
  business_type: 'business-type',
};

export const OnboardingV3Routes = {
  create_account: 'create-account',
  create_password: 'create-password',
  verification: 'verification',
  business_information: 'business-information',
};

export const inventoryProductErrors = {
  SKUERROR: 'SKU is required',
  QUANTITYERROR: 'Quantity is required',
};

export const FEATUREDEVURL = {
  LINK: window.location.origin,
};

export const BUSINESS_CATEGORIES: Record<string, BusinessCategory> = {
  SOLE_PROPRIETOR: 'Sole Proprietor',
  PRIVATE_LIMITED_COMPANY: 'Private Limited Company',
  REGISTERED_PARTNERSHIP: 'Registered Partnership',
  INCORPORATED_TRUSTIES: 'Incorporated Trusties',
  UNKNOWN: 'Unknown',
  UNREGISTERED: 'Unregistered',
};

export const BUSINESS_CATEGORIES_RETURNED: Record<string, BusinessCategoryReturned> = {
  SOLE_PROPRIETOR: 'SOLE_PROPRIETOR',
  PRIVATE_LIMITED_COMPANY: 'PRIVATE_LIMITED_COMPANY',
  REGISTERED_PARTNERSHIP: 'REGISTERED_PARTNERSHIP',
  INCORPORATED_TRUSTIES: 'INCORPORATED_TRUSTIES',
  UNKNOWN: 'UNKNOWN',
  UNREGISTERED: 'UNREGISTERED',
};

export const BUSINESS_CATEGORIES_DOCUMENTS_REQUIRED: Record<BusinessCategory, string[]> = {
  'Sole Proprietor': [
    'Certificate of registration of business name',
    'Certified true copy of the form CAC/BN/1 (Application for registration of business name)',
    'Proof of address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  'Private Limited Company': [
    'Certificate of Incorporation',
    'Memorandum and Articles of Association',
    'Form CAC 7 certified by Registrar of Companies',
    'Form CAC 2 certified by Registrar of Companies',
    'Proof of address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  'Registered Partnership': [
    'Certificate of Partnership/Business Registration/ Certified CAC',
    'Executed Partnerships Agreement/Partnerships Deed',
    'Proof of address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  'Incorporated Trusties': [
    'Certificate of Incorporation/Registration - CAC-IT01',
    'Copy of Constitution (If registered under part C)',
    'Proof of Address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  Unknown: [],
  Unregistered: [],
};

export const BUSINESS_CATEGORIES_DOCUMENTS_REQUIRED_2: Record<BusinessCategoryReturned, string[]> = {
  SOLE_PROPRIETOR: [
    'Certificate of registration of business name',
    'Certified true copy of the form CAC/BN/1 (Application for registration of business name)',
    'Proof of address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  PRIVATE_LIMITED_COMPANY: [
    'Certificate of Incorporation',
    'Memorandum and Articles of Association',
    'Form CAC 7 certified by Registrar of Companies',
    'Form CAC 2 certified by Registrar of Companies',
    'Proof of address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  REGISTERED_PARTNERSHIP: [
    'Certificate of Partnership/Business Registration/ Certified CAC',
    'Executed Partnerships Agreement/Partnerships Deed',
    'Proof of address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  INCORPORATED_TRUSTIES: [
    'Certificate of Incorporation/Registration - CAC-IT01',
    'Copy of Constitution (If registered under part C)',
    'Proof of Address: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
  ],
  UNKNOWN: [],
  UNREGISTERED: [],
};

export const ID_TYPES: Record<string, IdType> = {
  VOTERS_CARD: "Voter's card",
  NIMC: 'NIMC Slip',
  DRIVERS_LISCENCE: 'Drivers License',
  INTERNATIONAL_PASSPORT: 'International passport',
  NATIONAL_ID_CARD: 'National ID card',
  BVN: 'BVN',
  UNKNOWN: 'Unknown',
};

export const GENDER: Record<string, string> = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const MERCHANT_TYPES: Record<string, MerchantType> = {
  INDIVIDUAL: 'Individual Business',
  CORPORATE: 'Corporate Business',
};

export const CALENDER_MAP: string[] = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const BUSINESS_TYPES_INDIVIDUAL: Record<string, BusinessType> = {
  AGENCY_BANKING: 'Agency Banking',
  FAST_FOOD_RESTAURANTS: 'Fast Food Restaurants',
  PHARMACIES: 'Pharmacies',
  HEALTH_AND_BEAUTY_SPAS: 'Health and Beauty Spas',
  RETAIL_MERCHANTS: 'Retail Merchants',
  BARBER_AND_BEAUTY_SHOPS: 'Barber and Beauty Shops',
  ELECTRICAL_PARTS_AND_EQUIPMENTS: 'Electrical Parts and Equipments',
  DRY_CLEANERS: 'Dry Cleaners',
  STATIONARY_AND_OFFICE_SUPPLIES: 'Stationary/Office Supplies',
  PAINTS_VARNISHES_AND_SUPPLIES: 'Paints | Varnishes and Supplies',
  CLEANING_SERVICES: 'Cleaning Services',
  FOOD_DELIVERY_BUSINESSES: 'Food Delivery Businesses',
  CAR_WASHES: 'Car Washes',
  OTHERS: 'Others',
};

export const BUSINESS_TYPES_CORPORATE: Record<string, BusinessType> = {
  LEGAL_SERVICES: 'Legal Services',
  CHARITIES_AND_SOCIAL_SERVICES: 'Charities and Social Services',
  FUEL_STATION: 'Fuel Station',
  WHOLESALE_MERCHANTS: 'Wholesale Merchants',
  HOTELS_MOTELS_AND_RESORTS: 'Hotels/Motels and Resorts',
  PHARMACIES: 'Pharmacies',
  MEDIA_AND_ADVERTISING: 'Media and Advertising',
  HOSPITALS: 'Hospitals',
  SCHOOLS: 'Schools',
  TRAVEL_AGENCY: 'Travel Agency',
  BEAUTY_SPAS: 'Beauty Spas',
  BARBER_AND_HAIR_SALOON: 'Barber and Hair Saloon',
  ENTERTAINMENT: 'Entertainment',
  RELIGIOUS_ORGANIZATIONS: 'Religious Organizations',
  OTHERS: 'Others',
};

export const fromLocationQueryConstant = 'nm-ob-query';

export const migratedUserLocalConstant = 'nm-migrated-existing';

export const deviceId = 'nm-device';

export const notifictionId = 'nm-notification';

export const deviceTemporaryUserLocalCostant = 'nm-device-temp';

export const nmPostLoginAction = 'nm-login-action';

export const STATES: Record<string, string[]> = {
  Abia: [
    'Aba South',
    'Arochukwu',
    'Bende',
    'Ikwuano',
    'Isiala Ngwa North',
    'Isiala Ngwa South',
    'Isuikwuato',
    'Obi Ngwa',
    'Ohafia',
    'Osisioma',
    'Ugwunagbo',
    'Ukwa East',
    'Ukwa West',
    'Umuahia North',
    'Umuahia South',
    'Umu Nneochi',
  ],
  Adamawa: [
    'Fufure',
    'Ganye',
    'Gayuk',
    'Gombi',
    'Grie',
    'Hong',
    'Jada',
    'Lamurde',
    'Madagali',
    'Maiha',
    'Mayo Belwa',
    'Michika',
    'Mubi North',
    'Mubi South',
    'Numan',
    'Shelleng',
    'Song',
    'Toungo',
    'Yola North',
    'Yola South',
  ],
  'Akwa Ibom': [
    'Eastern Obolo',
    'Eket',
    'Esit Eket',
    'Essien Udim',
    'Etim Ekpo',
    'Etinan',
    'Ibeno',
    'Ibesikpo Asutan',
    'Ibiono-Ibom',
    'Ika',
    'Ikono',
    'Ikot Abasi',
    'Ikot Ekpene',
    'Ini',
    'Itu',
    'Mbo',
    'Mkpat-Enin',
    'Nsit-Atai',
    'Nsit-Ibom',
    'Nsit-Ubium',
    'Obot Akara',
    'Okobo',
    'Onna',
    'Oron',
    'Oruk Anam',
    'Udung-Uko',
    'Ukanafun',
    'Uruan',
    'Urue-Offong/Oruko',
    'Uyo',
  ],
  Anambra: [
    'Anambra East',
    'Anambra West',
    'Anaocha',
    'Awka North',
    'Awka South',
    'Ayamelum',
    'Dunukofia',
    'Ekwusigo',
    'Idemili North',
    'Idemili South',
    'Ihiala',
    'Njikoka',
    'Nnewi North',
    'Nnewi South',
    'Ogbaru',
    'Onitsha North',
    'Onitsha South',
    'Orumba North',
    'Orumba South',
    'Oyi',
  ],
  Bauchi: [
    'Bauchi',
    'Bogoro',
    'Damban',
    'Darazo',
    'Dass',
    'Gamawa',
    'Ganjuwa',
    'Giade',
    'Itas/Gadau',
    "Jama'are",
    'Katagum',
    'Kirfi',
    'Misau',
    'Ningi',
    'Shira',
    'Tafawa Balewa',
    'Toro',
    'Warji',
    'Zaki',
  ],
  Bayelsa: ['Ekeremor', 'Kolokuma/Opokuma', 'Nembe', 'Ogbia', 'Sagbama', 'Southern Ijaw', 'Yenagoa'],
  Benue: [
    'Apa',
    'Ado',
    'Buruku',
    'Gboko',
    'Guma',
    'Gwer East',
    'Gwer West',
    'Katsina-Ala',
    'Konshisha',
    'Kwande',
    'Logo',
    'Makurdi',
    'Obi',
    'Ogbadibo',
    'Ohimini',
    'Oju',
    'Okpokwu',
    'Oturkpo',
    'Tarka',
    'Ukum',
    'Ushongo',
    'Vandeikya',
  ],
  Borno: [
    'Askira/Uba',
    'Bama',
    'Bayo',
    'Biu',
    'Chibok',
    'Damboa',
    'Dikwa',
    'Gubio',
    'Guzamala',
    'Gwoza',
    'Hawul',
    'Jere',
    'Kaga',
    'Kala/Balge',
    'Konduga',
    'Kukawa',
    'Kwaya Kusar',
    'Mafa',
    'Magumeri',
    'Maiduguri',
    'Marte',
    'Mobbar',
    'Monguno',
    'Ngala',
    'Nganzai',
    'Shani',
  ],
  'Cross River': [
    'Akamkpa',
    'Akpabuyo',
    'Bakassi',
    'Bekwarra',
    'Biase',
    'Boki',
    'Calabar Municipal',
    'Calabar South',
    'Etung',
    'Ikom',
    'Obanliku',
    'Obubra',
    'Obudu',
    'Odukpani',
    'Ogoja',
    'Yakuur',
    'Yala',
  ],
  Delta: [
    'Aniocha South',
    'Bomadi',
    'Burutu',
    'Ethiope East',
    'Ethiope West',
    'Ika North East',
    'Ika South',
    'Isoko North',
    'Isoko South',
    'Ndokwa East',
    'Ndokwa West',
    'Okpe',
    'Oshimili North',
    'Oshimili South',
    'Patani',
    'Sapele',
    'Udu',
    'Ughelli North',
    'Ughelli South',
    'Ukwuani',
    'Uvwie',
    'Warri North',
    'Warri South',
    'Warri South West',
  ],
  Ebonyi: [
    'Afikpo North',
    'Afikpo South',
    'Ebonyi',
    'Ezza North',
    'Ezza South',
    'Ikwo',
    'Ishielu',
    'Ivo',
    'Izzi',
    'Ohaozara',
    'Ohaukwu',
    'Onicha',
  ],
  Edo: [
    'Egor',
    'Esan Central',
    'Esan North-East',
    'Esan South-East',
    'Esan West',
    'Etsako Central',
    'Etsako East',
    'Etsako West',
    'Igueben',
    'Ikpoba Okha',
    'Orhionmwon',
    'Oredo',
    'Ovia North-East',
    'Ovia South-West',
    'Owan East',
    'Owan West',
    'Uhunmwonde',
  ],
  Ekiti: [
    'Efon',
    'Ekiti East',
    'Ekiti South-West',
    'Ekiti West',
    'Emure',
    'Gbonyin',
    'Gbonyin',
    'Ijero',
    'Ikere',
    'Ikole',
    'Ilejemeje',
    'Irepodun/Ifelodun',
    'Ise/Orun',
    'Moba',
    'Oye',
  ],
  Enugu: [
    'Awgu',
    'Enugu East',
    'Enugu North',
    'Enugu South',
    'Ezeagu',
    'Igbo Etiti',
    'Igbo Eze North',
    'Igbo Eze South',
    'Isi Uzo',
    'Nkanu East',
    'Nkanu West',
    'Nsukka',
    'Oji River',
    'Udenu',
    'Udi',
    'Uzo Uwani',
  ],
  FCT: ['Bwari', 'Gwagwalada', 'Kuje', 'Kwali', 'Municipal Area Council'],
  Gombe: ['Balanga', 'Billiri', 'Dukku', 'Funakaye', 'Gombe', 'Kaltungo', 'Kwami', 'Nafada', 'Shongom', 'Yamaltu/Deba'],
  Imo: [
    'Ahiazu Mbaise',
    'Ehime Mbano',
    'Ezinihitte',
    'Ihitte/Uboma',
    'Ikeduru',
    'Isiala Mbano',
    'Isu',
    'Mbaitoli',
    'Ngor Okpala',
    'Njaba',
    'Nkwerre',
    'Nwangele',
    'Obowo',
    'Oguta',
    'Ohaji/Egbema',
    'Okigwe',
    'Orlu',
    'Orsu',
    'Oru East',
    'Oru West',
    'Owerri Municipal',
    'Owerri North',
    'Owerri West',
    'Unuimo',
  ],
  Jigawa: [
    'Babura',
    'Biriniwa',
    'Birnin Kudu',
    'Buji',
    'Dutse',
    'Gagarawa',
    'Garki',
    'Gumel',
    'Guri',
    'Gwaram',
    'Gwiwa',
    'Hadejia',
    'Jahun',
    'Kafin Hausa',
    'Kazaure',
    'Kiri Kasama',
    'Kiyawa',
    'Kaugama',
    'Maigatari',
    'Malam Madori',
    'Miga',
    'Ringim',
    'Roni',
    'Sule Tankarkar',
    'Taura',
    'Yankwashi',
  ],
  Kaduna: [
    'Chikun',
    'Giwa',
    'Igabi',
    'Ikara',
    'Jaba',
    "Jema'a",
    'Kachia',
    'Kaduna North',
    'Kaduna South',
    'Kagarko',
    'Kajuru',
    'Kaura',
    'Kauru',
    'Kubau',
    'Kudan',
    'Lere',
    'Makarfi',
    'Sabon Gari',
    'Sanga',
    'Soba',
    'Zangon Kataf',
    'Zaria',
  ],
  Kano: [
    'Albasu',
    'Bagwai',
    'Bebeji',
    'Bichi',
    'Bunkure',
    'Dala',
    'Dambatta',
    'Dawakin Kudu',
    'Dawakin Tofa',
    'Doguwa',
    'Fagge',
    'Gabasawa',
    'Garko',
    'Garun Mallam',
    'Gaya',
    'Gezawa',
    'Gwale',
    'Gwarzo',
    'Kabo',
    'Kano Municipal',
    'Karaye',
    'Kibiya',
    'Kiru',
    'Kumbotso',
    'Kunchi',
    'Kura',
    'Madobi',
    'Makoda',
    'Minjibir',
    'Nasarawa',
    'Rano',
    'Rimin Gado',
    'Rogo',
    'Shanono',
    'Sumaila',
    'Takai',
    'Tarauni',
    'Tofa',
    'Tsanyawa',
    'Tudun Wada',
    'Ungogo',
    'Warawa',
    'Wudil',
  ],
  Katsina: [
    'Batagarawa',
    'Batsari',
    'Baure',
    'Bindawa',
    'Charanchi',
    'Dandume',
    'Danja',
    'Dan Musa',
    'Daura',
    'Dutsi',
    'Dutsin Ma',
    'Faskari',
    'Funtua',
    'Ingawa',
    'Jibia',
    'Kafur',
    'Kaita',
    'Kankara',
    'Kankia',
    'Katsina',
    'Kurfi',
    'Kusada',
    "Mai'Adua",
    'Malumfashi',
    'Mani',
    'Mashi',
    'Matazu',
    'Musawa',
    'Rimi',
    'Sabuwa',
    'Safana',
    'Sandamu',
    'Zango',
  ],
  Kebbi: [
    'Arewa Dandi',
    'Argungu',
    'Augie',
    'Bagudo',
    'Birnin Kebbi',
    'Bunza',
    'Dandi',
    'Fakai',
    'Gwandu',
    'Jega',
    'Kalgo',
    'Koko/Besse',
    'Maiyama',
    'Ngaski',
    'Sakaba',
    'Shanga',
    'Suru',
    'Wasagu/Danko',
    'Yauri',
    'Zuru',
  ],
  Kogi: [
    'Ajaokuta',
    'Ankpa',
    'Bassa',
    'Dekina',
    'Ibaji',
    'Idah',
    'Igalamela Odolu',
    'Ijumu',
    'Kabba/Bunu',
    'Kogi',
    'Lokoja',
    'Mopa Muro',
    'Ofu',
    'Ogori/Magongo',
    'Okehi',
    'Okene',
    'Olamaboro',
    'Omala',
    'Yagba East',
    'Yagba West',
  ],
  Kwara: [
    'Baruten',
    'Edu',
    'Ekiti',
    'Ifelodun',
    'Ilorin East',
    'Ilorin South',
    'Ilorin West',
    'Irepodun',
    'Isin',
    'Kaiama',
    'Moro',
    'Offa',
    'Oke Ero',
    'Oyun',
    'Pategi',
  ],
  Lagos: [
    'Ajeromi-Ifelodun',
    'Alimosho',
    'Amuwo-Odofin',
    'Apapa',
    'Badagry',
    'Epe',
    'Eti Osa',
    'Ibeju-Lekki',
    'Ifako-Ijaiye',
    'Ikeja',
    'Ikorodu',
    'Kosofe',
    'Lagos Island',
    'Lagos Mainland',
    'Mushin',
    'Ojo',
    'Oshodi-Isolo',
    'Shomolu',
    'Surulere',
  ],
  Nasarawa: [
    'Awe',
    'Doma',
    'Karu',
    'Keana',
    'Keffi',
    'Kokona',
    'Lafia',
    'Nasarawa',
    'Nasarawa Egon',
    'Obi',
    'Toto',
    'Wamba',
  ],
  Niger: [
    'Agwara',
    'Bida',
    'Borgu',
    'Bosso',
    'Chanchaga',
    'Edati',
    'Gbako',
    'Gurara',
    'Katcha',
    'Kontagora',
    'Lapai',
    'Lavun',
    'Magama',
    'Mariga',
    'Mashegu',
    'Mokwa',
    'Moya',
    'Paikoro',
    'Rafi',
    'Rijau',
    'Shiroro',
    'Suleja',
    'Tafa',
    'Wushishi',
  ],
  Ogun: [
    'Abeokuta South',
    'Ado-Odo/Ota',
    'Egbado North',
    'Egbado South',
    'Ewekoro',
    'Ifo',
    'Ijebu East',
    'Ijebu North',
    'Ijebu North East',
    'Ijebu Ode',
    'Ikenne',
    'Imeko Afon',
    'Ipokia',
    'Obafemi Owode',
    'Odeda',
    'Odogbolu',
    'Ogun Waterside',
    'Remo North',
    'Shagamu',
  ],
  Ondo: [
    'Akoko North-West',
    'Akoko South-West',
    'Akoko South-East',
    'Akure North',
    'Akure South',
    'Ese Odo',
    'Idanre',
    'Ifedore',
    'Ilaje',
    'Ile Oluji/Okeigbo',
    'Irele',
    'Odigbo',
    'Okitipupa',
    'Ondo East',
    'Ondo West',
    'Ose',
    'Owo',
  ],
  Osun: [
    'Atakunmosa West',
    'Aiyedaade',
    'Aiyedire',
    'Boluwaduro',
    'Boripe',
    'Ede North',
    'Ede South',
    'Ife Central',
    'Ife East',
    'Ife North',
    'Ife South',
    'Egbedore',
    'Ejigbo',
    'Ifedayo',
    'Ifelodun',
    'Ila',
    'Ilesa East',
    'Ilesa West',
    'Irepodun',
    'Irewole',
    'Isokan',
    'Iwo',
    'Obokun',
    'Odo Otin',
    'Ola Oluwa',
    'Olorunda',
    'Oriade',
    'Orolu',
    'Osogbo',
  ],
  Oyo: [
    'Akinyele',
    'Atiba',
    'Atisbo',
    'Egbeda',
    'Ibadan North',
    'Ibadan North-East',
    'Ibadan North-West',
    'Ibadan South-East',
    'Ibadan South-West',
    'Ibarapa Central',
    'Ibarapa East',
    'Ibarapa North',
    'Ido',
    'Irepo',
    'Iseyin',
    'Itesiwaju',
    'Iwajowa',
    'Kajola',
    'Lagelu',
    'Ogbomosho North',
    'Ogbomosho South',
    'Ogo Oluwa',
    'Olorunsogo',
    'Oluyole',
    'Ona Ara',
    'Orelope',
    'Ori Ire',
    'Oyo',
    'Oyo East',
    'Saki East',
    'Saki West',
    'Surulere',
  ],
  Plateau: [
    'Barkin Ladi',
    'Bassa',
    'Jos East',
    'Jos North',
    'Jos South',
    'Kanam',
    'Kanke',
    'Langtang South',
    'Langtang North',
    'Mangu',
    'Mikang',
    'Pankshin',
    "Qua'an Pan",
    'Riyom',
    'Shendam',
    'Wase',
  ],
  Rivers: [
    'Ahoada East',
    'Ahoada West',
    'Akuku-Toru',
    'Andoni',
    'Asari-Toru',
    'Bonny',
    'Degema',
    'Eleme',
    'Emuoha',
    'Etche',
    'Gokana',
    'Ikwerre',
    'Khana',
    'Obio/Akpor',
    'Ogba/Egbema/Ndoni',
    'Ogu/Bolo',
    'Okrika',
    'Omuma',
    'Opobo/Nkoro',
    'Oyigbo',
    'Port Harcourt',
    'Tai',
  ],
  Sokoto: [
    'Bodinga',
    'Dange Shuni',
    'Gada',
    'Goronyo',
    'Gudu',
    'Gwadabawa',
    'Illela',
    'Isa',
    'Kebbe',
    'Kware',
    'Rabah',
    'Sabon Birni',
    'Shagari',
    'Silame',
    'Sokoto North',
    'Sokoto South',
    'Tambuwal',
    'Tangaza',
    'Tureta',
    'Wamako',
    'Wurno',
    'Yabo',
  ],
  Taraba: [
    'Bali',
    'Donga',
    'Gashaka',
    'Gassol',
    'Ibi',
    'Jalingo',
    'Karim Lamido',
    'Kumi',
    'Lau',
    'Sardauna',
    'Takum',
    'Ussa',
    'Wukari',
    'Yorro',
    'Zing',
  ],
  Yobe: [
    'Bursari',
    'Damaturu',
    'Fika',
    'Fune',
    'Geidam',
    'Gujba',
    'Gulani',
    'Jakusko',
    'Karasuwa',
    'Machina',
    'Nangere',
    'Nguru',
    'Potiskum',
    'Tarmuwa',
    'Yunusari',
    'Yusufari',
  ],
  Zamfara: [
    'Bakura',
    'Birnin Magaji/Kiyaw',
    'Bukkuyum',
    'Bungudu',
    'Gummi',
    'Gusau',
    'Kaura Namoda',
    'Maradun',
    'Maru',
    'Shinkafi',
    'Talata Mafara',
    'Chafe',
    'Zurmi',
  ],
};

export const signupStageText: Record<string, string> = {
  'your-business': 'Your Business',
  'about-you': 'About you',
  'your-identity': 'Your Identity',
  'upgrade-kyc-level': 'Upgrade KYC Level',
  'business-review': 'Business Review',
};

export type CustomerKYCLevel = 'LEVEL_ONE' | 'LEVEL_TWO';

export const signupStageDescription: Record<string, string> = {
  'your-business': 'Your business matters. We want to know about it.',
  'about-you': 'Help us get to know you better, tell us about yourself',
  'your-identity': 'Verify your identity to ensure security of your account',
  'upgrade-kyc-level': 'Upgrade your business account to increase your transaction limits.',
};
export const TOAST_TIMER = 4000;

export const BUSINESS_DOCUMENTS_LABELS: Record<string, string> = {
  proofOfOperatingAddressDocUrl: 'Proof of Business Operating Address',
  otherSupportingDocUrls: 'Any other supporting document',
  businessRegistrationCertificateDocUrl: 'Certificate of business registration',
  certOfIncorporationDocUrl: 'Certificate of Incorporation',
  memorandumDocUrl: 'Memorandum of Association',
  cac7FormDocUrl: 'Form CAC 7',
  cacLLP01OrCacLP01DocUrl: 'Form CAC/LLP 01 or CAC/LP O1',
  cac2FormDocUrl: 'Form CAC 2',
  cacStatusReportDocUrl: 'Company Status Report',
  businessLicenseDocUrl: 'Business Operating license (If Applicable)',
  applicationForBusinessNameRegDocUrl: 'Copy of form CAC/BN/1',
  boardResolutionDocUrl: 'Board resolution',
  partnersResolutionDocUrl: "Partners' Resolution",
};

export const BUSINESS_DOCUMENTS_FILES_CONFIG: Record<BusinessCategories, ReviewConfig> = {
  SOLE_PROPRIETOR: {
    firstPageCount: 5,
    partners: false,
    documentList: [
      { name: 'businessRegistrationCertificateDocUrl', required: true },
      { name: 'applicationForBusinessNameRegDocUrl', required: true },
      { name: 'proofOfOperatingAddressDocUrl', required: true },
      { name: 'businessLicenseDocUrl', required: false },
    ],
  },
  PRIVATE_LIMITED_COMPANY: {
    firstPageCount: 7,
    partners: true,
    partnerType: 'director',
    documentList: [
      { name: 'certOfIncorporationDocUrl', required: true },
      { name: 'memorandumDocUrl', required: true },
      { name: 'cac7FormDocUrl', required: true },
      { name: 'cac2FormDocUrl', required: true },
      { name: 'cacStatusReportDocUrl', required: true },
      { name: 'proofOfOperatingAddressDocUrl', required: true },
      { name: 'businessLicenseDocUrl', required: false },
      { name: 'boardResolutionDocUrl', required: true },
    ],
  },
  REGISTERED_PARTNERSHIP: {
    firstPageCount: 6,
    partners: true,
    partnerType: 'partner',
    documentList: [
      { name: 'certOfIncorporationDocUrl', required: true },
      { name: 'memorandumDocUrl', required: true },
      { name: 'cacLLP01OrCacLP01DocUrl', required: true },
      { name: 'cacStatusReportDocUrl', required: true },
      { name: 'proofOfOperatingAddressDocUrl', required: true },
      { name: 'businessLicenseDocUrl', required: false },
      { name: 'partnersResolutionDocUrl', required: true },
    ],
  },
  INCORPORATED_TRUSTEES: {
    firstPageCount: 7,
    partners: true,
    partnerType: 'director',
    documentList: [
      { name: 'certOfIncorporationDocUrl', required: true },
      { name: 'memorandumDocUrl', required: true },
      { name: 'cac7FormDocUrl', required: true },
      { name: 'cac2FormDocUrl', required: true },
      { name: 'cacStatusReportDocUrl', required: true },
      { name: 'proofOfOperatingAddressDocUrl', required: true },
      { name: 'businessLicenseDocUrl', required: false },
      { name: 'boardResolutionDocUrl', required: true },
    ],
  },

  UNKNOWN: {
    firstPageCount: 0,
    partners: false,
    documentList: [],
  },
};

export const BUSINESS_DOCUMENTS_LIST: {
  [key: string]: { item: string; subItems?: string[] }[];
} = {
  SOLE_PROPRIETOR: [
    {
      item: 'Certificate of registration of business name',
    },
    {
      item: 'Certified copy of CAC/BN/1 form (Registration of business name)',
    },
    {
      item: "Valid means of identification (Travel Passport, Driver's License, National Identity Card, Permanent Voter's Card)",
    },
    {
      item: 'Proof of addres: Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
    },
  ],

  PRIVATE_LIMITED_COMPANY: [
    {
      item: 'Certificate of registration of Incorporation',
    },
    {
      item: 'Memorandum and articles of Association (Certified as true copy by the Registrar of Companies) ',
    },
    {
      item: 'CAC forms',
      subItems: [
        'Form CAC 7 (formerly CO7 - Particulars of Directors) certified as a true copy by the Registrar of Companies',
        'Form CAC 2 (formerly CO2-statement of return on allotment of shares) certified as a true copy by the Registrar of Companies',
        'Company Status Report ',
      ],
    },
    {
      item: 'Proof of business operating address i.e Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
    },
    {
      item: 'Primary Identification Documents of Partners with 5% ownership and above',
    },
    {
      item: 'Business Operating License',
    },
    {
      item: "Partners' resolution (appointing signatory/user and account mandate)",
    },
  ],

  REGISTERED_PARTNERSHIP: [
    {
      item: 'Certificate of registration of Incorporation',
    },
    {
      item: 'Memorandum and articles of Association (Certified as true copy by the Registrar of Companies) ',
    },
    {
      item: 'CAC forms',
      subItems: [
        'Form CAC/LLP 01 or CAC/LP O1 (Limited Liability Partnership Limited Partnership application form)',
        'Company Status Report',
      ],
    },
    {
      item: 'Proof of business operating address i.e Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
    },
    {
      item: 'Primary Identification Documents of Partners with 5% ownership and above',
    },
    {
      item: 'Business Operating License',
    },
    {
      item: "Partners' resolution (appointing signatory/user and account mandate)",
    },
  ],

  INCORPORATED_TRUSTEES: [
    {
      item: 'Certificate of registration of Incorporation',
    },
    {
      item: 'Memorandum and articles of Association (Certified as true copy by the Registrar of Companies) ',
    },
    {
      item: 'CAC forms',
      subItems: [
        'Form CAC 7 (formerly CO7 - Particulars of Directors) certified as a true copy by the Registrar of Companies',
        'Form CAC 2 (formerly CO2-statement of return on allotment of shares) certified as a true copy by the Registrar of Companies',
        'Company Status Report ',
      ],
    },

    {
      item: 'Proof of business operating address i.e Utility Bill, Bank Statement, Letter from Government Authority (e.g. Tax Assesment)',
    },
    {
      item: 'Primary Identification Documents of Partners with 5% ownership and above',
    },
    {
      item: 'Business Operating License',
    },
    {
      item: "Partners' resolution (appointing signatory/user and account mandate)",
    },
  ],
};

export const numericAlphabet: {
  [key: number]: string;
} = {
  0: 'a',
  1: 'b',
  2: 'c',
  3: 'd',
};

export const NmMode = 'nm_mode';

export type TerminalKeyTypes = 'LITE' | 'MAX' | 'MINI' | 'PRO';

export type TerminalRequestKeyTypes =
  | 'MONTHLY_SUBSCRIPTION'
  | 'DAILY_SUBSCRIPTION'
  | 'PAID_FREEMIUM'
  | 'FREE_TRIAL'
  | 'PAID';

export type IPriceList = Record<
  TerminalKeyTypes,
  { type?: string; price?: number; id?: string; posRequestType?: string }
>;

export type PreferredPaymentList = Record<
  TerminalRequestKeyTypes,
  { type?: string; description?: string; price?: number; id?: string; posRequestType?: string }
>;

export enum ResultStatus {
  Limit = 'kyc_limit_exceeded',
  Insufficient = 'insufficient_funds',
  Bank = 'bank_unavailable',
  Failed = 'failed',
}

export const FLAGSMITH_FEATURES = {
  WEB_NOTIFICATION_PANEL: 'web_notification_panel',
  WEB_TRANSFER: 'web_transfers',
  WEB_OUTLETS: 'web_outlets',
  WEB_STOREHOUSE: 'web_storehouse',
  WEB_API_KEYS: 'web_api_keys',
  WEB_TERMINAL_ACTION: 'web_terminal_actions',
  WEB_SPLIT_SETTLEMENT: 'web_split_settlement',
  BB_DASHBOARD_NOTIFICATION: 'bb_dashboard_notification',
  WEB_OUTLETS_DATA: 'web_outlets_data',
  WEB_OUTLETS_GROUP_PND: 'web_outlets_group_pnd',
  WEB_SPLIT_TABS: 'web_split_settlement_lump_sum_transaction',
  WEB_SUBSCRIPTION_TERMINALS: 'bb_dashboard_terminal_subscription',
  NOMBA_CHECKOUT: 'nomba_checkout_dispute',
  BB_ISSUE_RESOLUTION: 'bb_issue_resolution',
  NOMBA_TOUR_GUIDE: 'web_tour_guide',
  AUTOMATED_DISPUTE_DECLINER: 'automated_dispute_decliner',
  PAYMENT_LINKS: 'online_checkout_payment_link',
  WEB_DASHBOARD_QR_DISPLAY: 'bb_web_dasboard_qr_display',
  WEB_OUTLETS_SETTLEMENT: 'web_outlets_settlement',
  PARTNERS_TERMINAL_TO_WALLET: 'map_terminal_to_wallet_on_referral_dashboard_fe',
  TWO_STEP_VERIFICATION: 'two-set-transfer-verification',
  CHECKOUT_REFUND: 'online_checkout_refund',
  CHECKOUT_CUSTOMER_FEE: 'nomba_checkout_customer_pay_transaction_charge',
  MANUAL_SWEEP_INTL_CARD: 'manual_sweep_international_transaction_wallet',
  MANUAL_SWEEP_NOMBA_CHECKOUT: 'nomba_checkout_manual_sweep',
  WEB_TERMINAL_PERFORMANCE: 'web_terminal_performance',
  WEB_DASHBOARD_KDS: 'web_kds_bb_frontend',
  WEB_ONBOARDING_V3: 'web_onboarding_v3',
  WEB_TEAM_MEMBER_ROLE_CHANGE: 'web_team_member_role_change',
  CHECKOUT_SELECT_PAYMENT_MODES: 'checkout-select-payment-modes',
  WEB_TRANSACTION_ALERT: 'web_transaction_alert',
  WEB_TERMINAL_MENU_CUSTOMIZATION: 'web_terminal_menu_customization',
  WEB_OUTLET_MENU: 'web_outlet_menu',
  WEB_REGENERATE_API_CLIENT: 'web_regenerate_api_client',
};

export enum NavigationTabItemsEnum {
  Transfer = 'Transfer history',
  Beneficiaries = 'Manage Beneficiaries',
  terminalurl = 'Terminal URL',
  apikeys = 'API Keys',
  'transaction-alert' = 'Transaction Alert',
  'account' = 'My Account',
  'webhooks-apikeys' = 'Webhook & API Keys',
}

export const SETTLEMENTS_PAGE_SIZE = 10;
