/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useFlags } from 'flagsmith/react';

import { Navigation } from '../../navigation';
import { Layout, NavigationWrapper, ContentWrapper, PageLoadWrapper, MainPageContentHolder } from './components';
import { Header } from '../../header';
import ErrorBoundary from '../../../utils/errorBoundary';
import { FileHolderContextWrapper } from '../../../utils/filesHolderContext';
import { clearLocalStorage, handleFetchUser, logout } from '../../../utils';
import { useToast } from '../../../hooks/useToast';
import PageLoad from '../../PageLoad';

// redux
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import AddMoneyModal from '../../../pages/overview/components/addMoneyModal';
import { setShowAddModal, setShowWithdrawModal } from '../../../features/banking/bankingModalControl/slice';
import { fetchBalance, fetchCheckoutBalance } from '../../../features/banking/balance/thunkActions';
import WithdrawalModal from '../../../pages/overview/components/withdrawalModal';
import { fetchBusinessDetails } from '../../../features/business/thunkActions';
import useIsOnboardingAllowed from '../../../hooks/useIsOnboardingAllowed';
import useOnboaarding2ConditionsModel from '../../../pages/auth/models/onboarding-2-conditions';
import { IS_ONBOARDING_V2_ON } from '../../../utils/pages/auth';
import { FLAGSMITH_FEATURES } from '../../../utils/constants';
import { fetchAllNotifications } from '../../../pages/notification/reducer/thunkActions';
import SupportView from '../../../pages/support';
import { firebaseMessaging } from '../../../utils/firebase';
import { usePerformActionFromQuery } from '../../../hooks/useActionFromNotification';
import QRModal from '../../../pages/qr/modal';
import useSessionTimeout from '../../../pages/session-timeout/useSessionTimeout';
import SessionWarningModal from '../../../pages/session-timeout/warning-modal';
import { useIdleTimer } from 'react-idle-timer';

export function DashboardLayout() {
  useIsOnboardingAllowed();
  useOnboaarding2ConditionsModel();
  const { openSessionWarningModal, handleCloseSessionWarningModal, handleOpenSessionWarningModal } =
    useSessionTimeout();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast(3000);

  const appData = localStorage.getItem('appData');
  const token = localStorage.getItem('token');

  const { fullUser, parentBusinessId } = useAppSelector((state) => state.auth);

  const { showAddModal, showWithdrawModal } = useAppSelector((state) => state.bankingModalControl);
  const { business } = useAppSelector((state) => state.business);

  const FetchBalances = useCallback(() => {
    if (!parentBusinessId) return;

    const onSuccess = () => {};
    const onFailure = () => {};

    dispatch(fetchBalance({ onSuccess, onFailure }));
    dispatch(fetchCheckoutBalance({ onSuccess, onFailure }));
  }, [parentBusinessId, dispatch]);

  useEffect(() => {
    FetchBalances();
  }, [FetchBalances]);

  useEffect(() => {
    handleFetchUser({ fullUser, dispatch, navigate, toast, onboardingv2: IS_ONBOARDING_V2_ON });
  }, [dispatch, fullUser, navigate]);

  useEffect(() => {
    if (!parentBusinessId) return;

    const onFailure = (error: { data: { message: string } }) => {
      toast('error', error?.data?.message || 'Something went wrong');
    };

    dispatch(
      fetchBusinessDetails({
        onFailure,
      })
    );
  }, [dispatch, parentBusinessId]);

  if (!appData || !token) {
    clearLocalStorage();
    navigate('/auth/login');
  }

  if (fullUser && fullUser?.connectedBusinesses?.length < 1) {
    navigate('/auth/merchant-type');
  }

  const {
    // bb_dashboard_notification: webNotification,
    bb_issue_resolution: BBIssueResolution,
    bb_web_dasboard_qr_display: qrDisplay,
  } = useFlags([
    // FLAGSMITH_FEATURES.BB_DASHBOARD_NOTIFICATION,
    FLAGSMITH_FEATURES.BB_ISSUE_RESOLUTION,
    FLAGSMITH_FEATURES.WEB_DASHBOARD_QR_DISPLAY,
  ]);

  const isCoporate = business?.merchantType === 'CORPORATE';
  const isVerified = business?.verified;

  const cooporateReject = isCoporate && !isVerified;

  const unAthorized = cooporateReject || business?.verified === false;

  // If a corporate business is not verified restrict all other pages
  if (
    unAthorized &&
    !['overview', 'setup', 'control'].includes(location.pathname.split('/')[2]) &&
    !['service-status'].includes(location.pathname.split('/')[3])
  ) {
    <Navigate to="/dashboard/overview/" replace />;
  }

  // timeout
  const handleLogout = () => {
    logout({ dispatch, navigate, userId: String(fullUser?.id) });
  };

  const onIdle = () => {
    logout({ dispatch, navigate, userId: String(fullUser?.id), timedOut: true });
  };

  const { triggerAction } = usePerformActionFromQuery();

  useEffect(() => {
    triggerAction();
  }, []);

  firebaseMessaging.onMessage(() => {
    dispatch(fetchAllNotifications({}));
  });

  const { reset, getRemainingTime } = useIdleTimer({
    timeout: 5 * 60 * 1000,
    startOnMount: true,
    onIdle,
    promptBeforeIdle: 1.5 * 60 * 1000,
    onPrompt: handleOpenSessionWarningModal,
    crossTab: true,
  });

  const handleContinueSession = () => {
    reset();
    handleCloseSessionWarningModal();
  };

  return fullUser ? (
    <Layout>
      <NavigationWrapper
        open={open}
        onClick={() => {
          setOpen(false);
        }}
      >
        <Navigation open={open} setOpen={setOpen} />
      </NavigationWrapper>
      <ContentWrapper>
        <Header setOpen={setOpen} />
        <MainPageContentHolder>
          <ErrorBoundary>
            <FileHolderContextWrapper>
              <Outlet />
            </FileHolderContextWrapper>
          </ErrorBoundary>
        </MainPageContentHolder>
      </ContentWrapper>
      {/* add and withdraw modal */}
      <AddMoneyModal onClose={() => dispatch(setShowAddModal(false))} showModal={showAddModal} />
      <WithdrawalModal
        refresh={FetchBalances}
        onClose={() => dispatch(setShowWithdrawModal(false))}
        showModal={showWithdrawModal}
      />
      {BBIssueResolution.enabled ? <SupportView /> : null}
      {qrDisplay.enabled ? <QRModal /> : null}
      {openSessionWarningModal ? (
        <SessionWarningModal
          showModal={openSessionWarningModal}
          onClose={handleCloseSessionWarningModal}
          logOutFn={handleLogout}
          getRemainingTime={getRemainingTime}
          handleContinueSession={handleContinueSession}
        />
      ) : null}
    </Layout>
  ) : (
    <PageLoadWrapper>
      <PageLoad />
    </PageLoadWrapper>
  );
}
