import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Settlement = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M6.266 5.647a.619.619 0 0 0-.619.62v6.254c0 .342.277.62.619.62h11.468a.619.619 0 0 0 .619-.62V6.266a.619.619 0 0 0-.62-.619H6.267Zm-1.466.62c0-.81.656-1.467 1.466-1.467h11.468c.81 0 1.466.656 1.466 1.466v6.255c0 .81-.656 1.466-1.466 1.466H6.266c-.81 0-1.466-.656-1.466-1.466V6.266Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M12.717 8.253a1.14 1.14 0 1 0 0 2.281 1.14 1.14 0 0 0 0-2.28Zm-1.988 1.14a1.987 1.987 0 1 1 3.975 0 1.987 1.987 0 0 1-3.975 0ZM5.944 16.17c0-.234.19-.423.423-.423h11.171a.423.423 0 1 1 0 .847H6.368a.424.424 0 0 1-.424-.424ZM6.918 18.777c0-.234.19-.424.423-.424h9.318a.424.424 0 0 1 0 .847H7.34a.424.424 0 0 1-.423-.423Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Settlement;
