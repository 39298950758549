/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { NTheme as theme, Typography } from 'kudi-component-library';
import styled from 'styled-components';

export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: state.isDisabled ? 'rgb(238, 238, 238)' : theme.nColors['n-light'],
    borderColor: '#9e9e9e',
    minHeight: '30px',
    height: '50px',
    border: '1px solid #EFEFEF',
    '&:hover': {
      color: '#EFEFEF',
      border: `1px solid ${theme.nColors['n-grey2']}`,
      outlineColor: theme.nColors['n-grey2'],
      background: theme.nColors['n-grey1'],
    },
    boxShadow: '0 0 1px #EFEFEF',
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#000' : 'inherit',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  }),

  indicatorsContainer: (provided: any) => ({
    ...provided,
    border: 'none',
  }),

  indicatorSeparator: (provided: any) => ({
    ...provided,
    width: '0',
  }),

  indicatorContainer: (provided: any) => ({
    ...provided,
    '&:hover': {
      color: '#EFEFEF',
    },
  }),

  menu: (provided: any) => ({
    ...provided,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2) !important',
    border: 'none',
    zIndex: 9999,
  }),

  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '16px',
    fontFamily: 'Inter',
    color: state.isDisabled ? 'rgb(134, 134, 134)' : theme.nColors['n-dark'],
    fontWeight: '400',
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    fontFeatureSettings: 'pnum 1,lnum 1,cv11 1',
    fontfamily: 'Inter,sans-serif !important',
    fontWeight: 400,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    fontFamily: 'Inter',
    color: theme.nColors['n-grey3'],
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '16px',
    fontFamily: 'Inter',
    color: theme.nColors['n-dark'],
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

export const InputWrapper = styled.div<{
  displayProp?: string;
  width?: string;
  fromSelect?: boolean;
  marginTop?: string;
}>`
  display: ${({ displayProp }) => displayProp || 'block'};
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '20px')};

  @media ${theme.media.md} {
    width: ${({ width }) => width || '40%'};
  }

  @media ${theme.media.lg} {
    width: ${({ width }) => width || '30%'};
  }

  textarea {
    font-family: 'Inter';
    font-weight: ${theme.fontWeights.regular};
  }

  input {
    font-family: 'Inter';
  }
`;

export const LabelText = styled(Typography)`
  margin-bottom: 5px !important;
`;

// v2
export const dropdownThemeStyles = (defaultTheme: any) => ({
  ...defaultTheme,
  borderRadius: 5,
  colors: {
    ...defaultTheme.colors,
    text: '#000',
    primary25: '#f4f2f2',
    primary: '#d3d3d3',
  },
});

export const dropdownV2Styles = {
  ...customStyles,
  control: (provided: any) => ({
    ...provided,
    ...customStyles.control,
    minHeight: 0,
    border: 'none',
    background: 'none',
    boxShadow: 'none',
    width: '100%',
    '&:hover': {
      border: 'none ',
      background: 'none',
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0px 16px 10px',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0px 16px 10px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '180px',
  }),
};
