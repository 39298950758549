import { useCallback, useEffect } from 'react';
import { useToast } from '../../../hooks/useToast';
import useRedux from '../../../hooks/useRedux';
import { clearTransactions, selectIssueSubCategory, selectIssueType } from '../reducer/slice';
import { GetAllTypes } from '../reducer/thunk_actions';
import { IssueCategory } from '../defs';

const useSubCategorySelection = () => {
  const toast = useToast(5000);

  const {
    dispatch,
    state: {
      support: {
        issueCreation: { categories, subCategories, selectedCategory },
      },
    },
  } = useRedux();

  // fetch type and auto select if length is 1
  const fetchTypes = useCallback(() => {
    dispatch(
      GetAllTypes({
        onFailure: () => {
          toast('error', 'Something went wrong');
        },
        onSuccess: ({ data }) => {
          if (data?.length === 1) {
            dispatch(selectIssueType(data?.[0]));
          }
        },
      })
    );
  }, [dispatch]);

  // Sub-category click
  const handleSubCategoryClick = (sub: IssueCategory) => {
    dispatch(selectIssueType(null));
    dispatch(selectIssueSubCategory(sub));
    fetchTypes();
  };

  // List of sub-category
  const List = subCategories?.[selectedCategory?.index || 0]?.map((item) => ({
    action: () => handleSubCategoryClick(item),
    ...item,
  }));

  useEffect(() => {
    dispatch(clearTransactions());
  }, [dispatch]);

  return { dispatch, handleSubCategoryClick, categories, subCategories, List };
};

export default useSubCategorySelection;
