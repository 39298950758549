/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect } from 'react';
import { Button, Text, TextInput } from 'kudi-component-library';
import { Formik } from 'formik';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { AddBeneficiaryIcon } from '../../../assets/svg';
import Modal from '../../../components/Modal';
import { FullBox, FullFlex } from '../../../styles/generic';
import { bankOptions, bankCodes } from '../../../data';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { accountLookUp, editBeneficiary } from '../../../features/transfers/thunkActions';
import Select from '../../../components/Select';

interface InitialValues {
  accountNumber: string;
  beneficiaryNickName: string;
}

function ResponseModal({
  showResponseModal,
  setShowResponseModal,
}: {
  showResponseModal: boolean;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal showModal={showResponseModal} onClose={() => setShowResponseModal(false)}>
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="90px">
        <AddBeneficiaryIcon />

        <Text
          id="beneficiary-updated-text"
          color="#121212"
          fontSize="24px"
          fontWeight="bold"
          textAlign="center"
          mt="30px"
        >
          Successfully updated beneficiary
        </Text>
        <Text color="#717171" fontSize="14px" textAlign="center">
          You can now transfer money to the updated beneficiary.
        </Text>
      </FullFlex>
    </Modal>
  );
}

export default function EditBeneficiaryModal({
  showResponseModal,
  showEditBeneficiaryModal,
  setShowEditBeneficiaryModal,
  setShowResponseModal,
  beneficiary,
  reload,
  setReload,
}: {
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  reload?: boolean;
  showResponseModal: boolean;
  showEditBeneficiaryModal: boolean;
  setShowEditBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  beneficiary: { id: string; bankName: string; accountNo: string; name: string; nickName: string };
}) {
  const InitialFormValues: InitialValues = {
    accountNumber: beneficiary?.accountNo,
    beneficiaryNickName: beneficiary?.nickName,
  };

  const [bankName, setBankName] = React.useState(beneficiary?.bankName || '');
  const [bankCode, setBankCode] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState(beneficiary?.accountNo);
  const [beneficiaryName, setBeneficiaryName] = React.useState(beneficiary?.name);
  const [beneficiaryNickName, setBeneficiaryNickName] = React.useState(beneficiary?.nickName);
  // const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [accountLookupError, setAccountLookupError] = React.useState('');

  const { fullUser } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const { isSubmitting } = useAppSelector((state) => state.transfers);

  const AccountLookUp = useCallback(() => {
    if (bankName === '' || accountNumber === '') return;
    if (accountNumber.length < 10) {
      setBeneficiaryNickName('');
      return;
    }
    const selectedBank = bankCodes.find((bank) => bank.name === bankName);
    if (!selectedBank) {
      return;
    }
    dispatch(
      accountLookUp({
        bankCode: selectedBank?.bankCode,
        accountNumber,
        onFailure: () => {
          setAccountLookupError('Account not found');
        },
        onSuccess: (value: {
          data: {
            accountName: string;
          };
          status: string;
          successful: boolean;
        }) => {
          setBeneficiaryName(value.data.accountName);
          setBankCode(selectedBank?.bankCode);
        },
      })
    );
  }, [dispatch, accountNumber, bankName]);

  useEffect(() => {
    AccountLookUp();
  }, [AccountLookUp]);

  function handleEditBeneficiary() {
    dispatch(
      editBeneficiary({
        beneficiaryId: beneficiary?.id,
        accountNumber,
        beneficiaryName,
        bankName,
        bankCode,
        nickName: beneficiaryNickName,
        onFailure: (error: { data: { msg: string } }) => {
          setAccountLookupError(error?.data?.msg);
        },

        onSuccess: () => {
          if (setReload) {
            setReload(!reload);
          }

          setShowEditBeneficiaryModal(false);
          setShowResponseModal(true);
        },
      })
    );
  }

  return (
    <FullBox>
      <Modal showModal={showEditBeneficiaryModal} onClose={() => setShowEditBeneficiaryModal(false)}>
        <Text id="edit-beneficiary-modal-text" textAlign="center" color="#121212" fontSize="24px" fontWeight="bold">
          Edit Beneficiary
        </Text>
        <Text color="#717171" fontSize="14px" fontWeight="regular" textAlign="center">
          Save a beneficiary for easy transfer
        </Text>
        <FullBox mt="30px" p="20px">
          <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
            <FullBox mb="20px" width="100%" id="beneficiary-bank">
              <Select
                name="bankname"
                label="Select bank"
                placeholder="Select bank"
                options={bankOptions[0]}
                width="100%"
                value={bankName}
                onChange={(e) => {
                  Mixpanel.track('Select Bank', {
                    first_name: String(fullUser?.firstName),
                    last_name: String(fullUser?.lastName),
                    phone_number: String(fullUser?.phoneNumber),
                    email: String(fullUser?.email),
                    bank: String(e.target.value),
                  });
                  e.target.value && setBankName(e.target.value);
                }}
              />
            </FullBox>

            <FullBox width="100%" mb="20px">
              <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                <TextInput
                  id="beneficiary-account-number"
                  label="Account number"
                  placeholder="Enter your account"
                  name="account_number"
                  value={accountNumber || ''}
                  onChange={(e) => {
                    setAccountLookupError('');
                    setAccountNumber(e.target.value);
                  }}
                  width="100%"
                  error={accountLookupError}
                />
              </Formik>
            </FullBox>
            <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
              <TextInput
                id="beneficiary-name"
                label="Beneficiary name"
                placeholder=""
                name="beneficiary_name"
                value={beneficiaryName || ''}
                readOnly
                onChange={(e) => {
                  setBeneficiaryName(e.target.value);
                }}
                width="100%"
                style={{ marginBottom: '20px' }}
              />
            </Formik>
            <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
              <TextInput
                id="beneficiary-nick-name"
                label="Beneficiary nickname (Optional)"
                placeholder="Enter nickname"
                name="beneficiary_name"
                value={beneficiaryNickName}
                onChange={(e) => {
                  setBeneficiaryNickName(e.target.value);
                }}
                width="100%"
                style={{ marginBottom: '50px' }}
              />
            </Formik>
            {bankName === '' || accountNumber === '' || beneficiaryName === '' ? (
              <Button
                id="update-beneficiary-btn-disabled"
                width="100%"
                variant="xlarge"
                style={{ backgroundColor: '#FFCC00', color: '#121212', opacity: 0.3 }}
              >
                Update beneficiary
              </Button>
            ) : (
              <Button
                id="update-beneficiary-btn-enabled"
                onClick={() => {
                  handleEditBeneficiary();
                }}
                width="100%"
                variant="xlarge"
                style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                disabled={!!isSubmitting}
              >
                Update beneficiary
              </Button>
            )}
          </FullFlex>
        </FullBox>
      </Modal>
    </FullBox>
  );
}
