/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchAllNotifications,
  markAllNotifications,
  ArchiveNotifications,
  toggleNotification,
  MarkNotificationsRead,
} from './thunkActions';
import { Notification } from '../types';
import { GetAllNotificationsCategoryEnum, GetAllNotificationsStatusEnum } from '../../../api_spec_client';
import { inputAdapter } from '../functions';

export interface Pagination {
  page: number;
  pageSize: number;
}

interface NotificationsInitialState {
  notifications: Notification[];
  mobileNotification: Notification[];
  totalNotifications: number;
  mobileOpenItem: Notification | null;
  allChecked: boolean;
  totalUnread: number;
  checkedList: string[];
  pagination: Pagination;
  desktopModalData: Notification | null;
  mobileAction: boolean;
  filter: {
    status?: GetAllNotificationsStatusEnum;
    category?: GetAllNotificationsCategoryEnum;
  };
  isLoading: boolean;
  inProgress: boolean;
  resetTable: boolean;
}

const findIndex = (value: Notification, state: NotificationsInitialState) => {
  const currIndex = state.notifications?.findIndex((val) => val.__access_key === value.__access_key);
  const mobileCurrIndex = state.mobileNotification?.findIndex((val) => val.__access_key === value.__access_key);
  return { currIndex, mobileCurrIndex };
};

const initialState: NotificationsInitialState = {
  notifications: [],
  mobileNotification: [],
  totalNotifications: 0,
  mobileOpenItem: null,
  allChecked: false,
  totalUnread: 0,
  checkedList: [],
  filter: {
    status: 'UNREAD',
    category: undefined,
  },
  pagination: {
    page: 0,
    pageSize: 5,
  },
  desktopModalData: null,
  mobileAction: false,
  isLoading: false,
  inProgress: false,
  resetTable: false,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleOpen: (state, action: PayloadAction<Notification>) => {
      const { currIndex, mobileCurrIndex } = findIndex(action.payload, state);
      const curr = { ...state.notifications[currIndex] };
      const mCurr = { ...state.mobileNotification[currIndex] };
      if (currIndex > -1) state.notifications[currIndex].__open = !curr.__open;
      if (mobileCurrIndex > -1) {
        state.mobileNotification[mobileCurrIndex].__open = !mCurr.__open;
      }
    },
    toggleCheckAll: (state) => {
      if (!state.allChecked) {
        state.checkedList = [];
        state.notifications = state.notifications.map((item) => ({ ...item, __checked: false }));
      }
      state.allChecked = !state.allChecked;
    },
    toggleCheckItem: (state, action: PayloadAction<Notification>) => {
      const currIndex = state.notifications?.findIndex((val) => val.__access_key === action.payload.__access_key);
      state.notifications[currIndex].__checked = !state.notifications[currIndex].__checked;
      if (state.checkedList.includes(action.payload.__access_key)) {
        const copy = [...state.checkedList].filter((item) => item !== action.payload.__access_key);
        state.checkedList = copy;
      } else {
        state.checkedList.push(action.payload.__access_key);
      }
    },
    addMobileSelected: (state, action: PayloadAction<Notification>) => {
      const { currIndex, mobileCurrIndex } = findIndex(action.payload, state);

      if (currIndex > -1) {
        state.notifications[currIndex].status = 'READ';
      }
      if (mobileCurrIndex > -1) {
        state.mobileNotification[mobileCurrIndex].status = 'READ';
      }
      state.mobileOpenItem = action.payload;
    },
    removeMobileSelected: (state) => {
      state.mobileOpenItem = null;
    },
    addModalSelected: (state, action: PayloadAction<Notification>) => {
      state.desktopModalData = action.payload;
    },
    removeModalSelected: (state) => {
      const { currIndex, mobileCurrIndex } = findIndex(state.desktopModalData as Notification, state);

      if (currIndex > -1) {
        state.notifications[currIndex].__open = true;
        state.desktopModalData = null;
      }

      state.mobileNotification[mobileCurrIndex].__open = true;
    },
    toggleMobileActions: (state) => {
      state.mobileAction = !state.mobileAction;
    },
    setCategory: (state, action: PayloadAction<GetAllNotificationsCategoryEnum | undefined>) => {
      state.filter.category = action.payload;
      state.pagination.page = 0;
      state.checkedList = [];
      state.allChecked = false;
      state.notifications = state.notifications.map((item) => ({ ...item, __checked: false }));
      state.resetTable = true;
    },
    setStatus: (state, action: PayloadAction<GetAllNotificationsStatusEnum | undefined>) => {
      state.filter.status = action?.payload || undefined;
      state.pagination.page = 0;
      state.checkedList = [];
      state.allChecked = false;
      state.notifications = state.notifications.map((item) => ({ ...item, __checked: false }));
      state.resetTable = true;
    },
    updatePagination: (state, action: PayloadAction<{ page: number; pageSize: number }>) => {
      state.pagination = action.payload;
    },
    resetPaginationAndFilter: (state) => {
      state.notifications = [];
      state.pagination = { page: 0, pageSize: 5 };
      state.filter = { status: 'UNREAD', category: undefined };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllNotifications.fulfilled, (state, action) => {
        const { data, meta } = action.payload;

        state.isLoading = false;
        state.totalNotifications = data?.totalRows || 0;
        state.totalUnread = meta?.unreadCount || 0;
        const cleanData = data?.content?.map((item) => inputAdapter(item)) || [];
        state.notifications = cleanData;
        if (state.pagination.page === 0) {
          state.mobileNotification = cleanData;
        } else {
          state.mobileNotification = [...state.mobileNotification, ...cleanData];
        }
        state.resetTable = false;
      });

    builder
      .addCase(toggleNotification.pending, (state, action) => {
        const { currIndex, mobileCurrIndex } = findIndex(action.meta.arg.notification, state);
        if (currIndex > -1) {
          const currStatus = state.notifications[currIndex].status;
          state.notifications[currIndex].status = currStatus === 'READ' ? 'UNREAD' : 'READ';
        }
        if (mobileCurrIndex > -1 && state.filter.status === 'UNREAD') {
          const copy = state.mobileNotification.filter((q, i) => i !== mobileCurrIndex);

          state.mobileNotification = copy;
        }
        state.inProgress = true;
      })
      .addCase(toggleNotification.rejected, (state, action) => {
        const { currIndex, mobileCurrIndex } = findIndex(action.meta.arg.notification, state);

        if (currIndex > -1) {
          state.notifications[currIndex].status = action.meta.arg.notification.status;
        }
        if (mobileCurrIndex > -1) {
          state.mobileNotification[mobileCurrIndex].status = action.meta.arg.notification.status;
        }
        state.inProgress = false;
      })
      .addCase(toggleNotification.fulfilled, (state, action) => {
        state.inProgress = false;
        if (action.meta.arg.notification.status === 'UNREAD') {
          state.totalUnread -= 1;
        }
      });

    builder
      .addCase(markAllNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markAllNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(markAllNotifications.fulfilled, (state) => {
        state.isLoading = false;
        state.allChecked = false;
      });

    builder
      .addCase(MarkNotificationsRead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(MarkNotificationsRead.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(MarkNotificationsRead.fulfilled, (state) => {
        state.allChecked = false;
        state.checkedList = [];
      });

    builder
      .addCase(ArchiveNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ArchiveNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(ArchiveNotifications.fulfilled, (state) => {
        state.allChecked = false;
        state.checkedList = [];
      });
  },
});

export const {
  toggleOpen,
  toggleCheckAll,
  toggleCheckItem,
  addMobileSelected,
  removeMobileSelected,
  addModalSelected,
  removeModalSelected,
  toggleMobileActions,
  setStatus,
  setCategory,
  resetPaginationAndFilter,
  updatePagination,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
