import React, { createContext, ReactNode, useContext, useState } from 'react';

export const FileHolderContext = createContext<{
  files: Record<string, File[] | Record<number, File[]>>;
  setFiles: (value: Record<string, File[] | Record<number, File[]>>) => void;
}>({ files: {}, setFiles: () => {} });

export const useGetFileFromFileHolder = () => {
  const { files } = useContext(FileHolderContext);

  return (name: string) => {
    const returnFiles = files?.[name] || null;

    return returnFiles;
  };
};

export const useAddFileToFileHolder = () => {
  const { files, setFiles } = useContext(FileHolderContext);

  return (name: string, newFiles: File[] | Record<number, File[]>) => {
    setFiles({ ...files, [name]: newFiles });
  };
};

export const useRemoveFileFromFileHolder = () => {
  const { files, setFiles } = useContext(FileHolderContext);

  return (name: string) => {
    if (name === 'all') {
      setFiles({});
      return;
    }
    const filesCopy = { ...files };

    delete filesCopy[name];
    setFiles(filesCopy);
  };
};

export const useSetFilesFileHolder = () => {
  const { files, setFiles } = useContext(FileHolderContext);

  return (name: string) => {
    const filesCopy = { ...files, [name]: {} };

    setFiles(filesCopy);
  };
};

interface Props {
  children: ReactNode;
}

export const FileHolderContextWrapper = ({ children }: Props) => {
  const [files, setFiles] = useState<Record<string, File[] | Record<number, File[]>>>({});

  return (
    <FileHolderContext.Provider value={React.useMemo(() => ({ files, setFiles }), [files])}>
      {children}
    </FileHolderContext.Provider>
  );
};
