/* eslint-disable no-nested-ternary */
import { Typography } from 'kudi-component-library';
import { Schema } from '../../../components/newTable';
import { formatDateWithTime } from '../../../utils';
import { BeneficiariesData } from '../../../types/transactions';

export const BeneficiariesSchema: Schema[] = [
  {
    text: 'Date',
    accessor: 'createdAt',
    render: (data: BeneficiariesData) => (
      <Typography style={{ cursor: 'pointer' }} color="n-dark" fontSize="14px">
        {formatDateWithTime(new Date(data?.createdAt))}
      </Typography>
    ),
  },
  {
    text: 'Account name',
    accessor: 'name',
    isCurrency: true,
    render: (data: BeneficiariesData) => (
      <Typography color="n-dark" fontSize="14px">
        {data?.name}
      </Typography>
    ),
  },
  {
    text: 'Bank',
    accessor: 'bankName',
    isCurrency: true,
    render: (data: BeneficiariesData) => (
      <Typography color="n-dark" fontSize="14px">
        {data?.bankName}
      </Typography>
    ),
  },
  {
    text: 'Account number',
    accessor: 'accountNo',
    render: (data: BeneficiariesData) => (
      <Typography color="n-dark" fontSize="14px">
        {data?.accountNo}
      </Typography>
    ),
  },
  {
    text: 'Beneficiary nickname',
    accessor: 'nickName',
    render: (data: BeneficiariesData) => (
      <Typography color="n-dark" fontSize="14px">
        {data?.nickName}
      </Typography>
    ),
  },
];
