import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { ChangeEvent, useEffect } from 'react';
import { Box, Flex } from 'rebass';
import {
  MenuGettingStartedButton,
  MenuAddImageCoverWrapper,
  PageBadgeCounter,
  CoverUploadedImageBox,
  CoverUploadedImage,
  CoverImageDescriptionBox,
  UploadIconText,
} from '../styles';
import { UploadCloudIcon } from '../../../assets/svg';
import Modal from '../../../components/Modal';
import CoverImageSample from './CoverImageSample';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { useAppSelector } from '../../../app/hooks';
import { getUserBrowser } from '../../../utils';

const AddCoverImage = ({
  showModal,
  onClose,
  newMenu,
  onContinue,
  currentImage,
  imageUrl,
  handleUploadImage,
  isProductBodyLoading: isLoading,
  setImageUrl,
}: {
  showModal: boolean;
  newMenu?: boolean;
  onClose: () => void;
  currentImage?: string;
  onContinue: (imageEditUrl: string) => void;
  handleUploadImage: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  imageUrl: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  isProductBodyLoading: boolean;
}) => {
  const { fullUser } = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (currentImage && !imageUrl) {
      setImageUrl(currentImage);
    }
  }, [currentImage, imageUrl]);

  useEffect(() => {
    Mixpanel.track('MENU_COVER_IMAGE_UPLOAD_VIEWED', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
    });
  }, []);

  return (
    <Modal
      showModal={showModal}
      onClose={() => {
        onClose();
      }}
      customDesktopWidth="500px"
      customTabletWidth="65rem"
      modalContentStyles={{ padding: '30px' }}
    >
      <MenuAddImageCoverWrapper>
        <Flex flexDirection="column" alignItems="center">
          <Box width="100%">
            {newMenu && (
              <PageBadgeCounter>
                <>
                  <NIcons icon={IconsEnum.OUTLINE_CHECK} color="n-yellow" height="12" width="12" />
                  <Typography style={{ fontSize: '12px', marginLeft: '5px' }}>Step 2 of 3</Typography>
                </>
              </PageBadgeCounter>
            )}
          </Box>
          <Box mb="20px" pb="20px" mt="40px" width="100%" style={{ borderBottom: 'solid 1px #eee' }}>
            <Typography variant="n-text5" color="dark">
              {newMenu ? 'Add' : 'Edit'} cover image
            </Typography>
          </Box>
          <CoverUploadedImageBox>
            {imageUrl && <CoverUploadedImage src={imageUrl} alt="cover" />}
            <CoverImageSample />
          </CoverUploadedImageBox>
          <CoverImageDescriptionBox p="20px" my="20px" flexDirection="column">
            <Flex flexDirection="column">
              <Typography variant="n-text3" style={{ marginBottom: '5px' }}>
                {currentImage ? '' : 'You have no cover photo.'} The cover image will be displayed at the top of your
                menu to give a custom feel of your business to your customers.
              </Typography>
              <Typography color="n-grey2a" variant="n-text2">
                800x400px (jpeg, png or svg) – Max. file size is 4MB
              </Typography>
            </Flex>
            <Box mt="20px">
              <input
                type="file"
                id="sample-doc"
                hidden
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleUploadImage(event)}
              />
              <label htmlFor="sample-doc" style={{ cursor: 'pointer' }}>
                <Flex style={{ gap: '10px' }} alignItems="center">
                  <UploadCloudIcon />
                  <UploadIconText variant="n-text2">Upload cover photo</UploadIconText>
                </Flex>
              </label>
            </Box>
          </CoverImageDescriptionBox>
          <MenuGettingStartedButton
            variant="large"
            loading={isLoading}
            disabled={!imageUrl}
            onClick={() => {
              onContinue(imageUrl);
              onClose();
            }}
          >
            {!newMenu ? 'Change Image' : 'Continue'}
          </MenuGettingStartedButton>
        </Flex>
      </MenuAddImageCoverWrapper>
    </Modal>
  );
};

export default AddCoverImage;
