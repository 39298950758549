import { ApiResponse, ReduxAction } from '../../../../types/redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../utils/merchantAcqServiceHttp';
import { RootState } from '../../../../app/store';
import { TerminalArrayListItemMap } from '../../defs';

const base = window._env_.REACT_APP_BASEURL;

interface DownlineTerminalsResponse extends ApiResponse {
  data: {
    map: {
      data: {
        myArrayList: {
          map: TerminalArrayListItemMap;
          empty: false;
        }[];
        empty: false;
      };
      message: 'success';
      status: true;
      successful: true;
    };
    empty: false;
  };
}

export const DownlineTerminals = createAsyncThunk<DownlineTerminalsResponse, ReduxAction, { state: RootState }>(
  '/referral/downline-terminals',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    const {
      referral: {
        downline: { selectedDownline },
      },
    } = getState();
    try {
      const data: DownlineTerminalsResponse = await axios.get(`${base}/terminal/get-terminals`, {
        params: {
          agentId: selectedDownline?.phoneNumber,
        },
      });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
