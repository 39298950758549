import styled, { css, DefaultTheme } from 'styled-components';

// common input styles
const genaralItemStyles = ({
  theme,
  error,
  isFocus,
  disabled,
  dashed,
}: {
  theme: DefaultTheme;
  error?: string;
  isFocus?: boolean;
  disabled?: boolean;
  dashed?: boolean;
}) => css`
  height: 100%;
  width: 100%;
  background-color: ${error && theme.colors.secondary.danger2};

  border: ${`1px ${dashed ? 'dashed' : 'solid'} ${
    // eslint-disable-next-line no-nested-ternary
    error ? theme.colors.secondary.danger1 : isFocus ? theme.nColors['n-grey2'] : theme.nColors['n-grey1']
  }`};

  border-radius: 4px;
  outline: none;

  ${disabled
    ? `
    background-color: ${theme.colors.primary.black6}};
    border: 1px solid ${theme.colors.primary.black6};
    color: ${theme.colors.primary.black3};
    cursor: not-allowed
  `
    : `:disabled {
    background-color: ${theme.colors.primary.black6};
    border: 1px solid ${theme.colors.primary.black6};
    color: ${theme.colors.primary.black3};
    cursor: not-allowed;
  }`}
`;

// General wrappers
export const InputWrapper = styled.div<{ disabled?: boolean; width?: string; height?: string }>`
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 4px;
  width: ${({ width }) => width || '420px'};
  height: ${({ height }) => height || 'auto'};
  position: relative;

  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
  }

  .react-tel-input .form-control {
    border: none;
    background-color: transparent !important;
    width: 100%;
    height: 46px;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent !important;
  }

  .react-tel-input .selected-flag {
    padding: 0;
  }

  .react-tel-input .selected-flag .flag {
    transform: scale(1.2);
  }

  .react-tel-input .selected-flag .arrow {
    border-top: 5px solid #bbbbbb;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-bottom: 5px solid #bbbbbb;
  }
  input#phoneNumberInput::placeholder {
    letter-spacing: 0;
  }
`;

export const Label = styled.label<{ isForm?: boolean; mb?: string }>`
  color: ${({ theme }) => theme.colors.primary.black2};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #121212;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: ${({ mb }) => mb || '5px'};
  font-style: normal;
  font-weight: 400;
  ${({ isForm }) =>
    isForm &&
    `
        color: #121212;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        `}
`;

export const InputItemErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 12px; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.secondary.danger1};

  span {
    display: block;
    margin-left: 4px;
  }
`;

// Input Item
export const InputItem = styled.input<{
  error?: string;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus }) => genaralItemStyles({ theme, error, isFocus })};

  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 12px;
  font-weight: 600;

  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary.black2};
  }
`;

// Select Item
export const SelectItem = styled.div<{
  error?: string;
  open?: boolean;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus, disabled }) => genaralItemStyles({ theme, error, isFocus, disabled })};

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }

  svg {
    transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;

export const SelectOptionsWrapper = styled.div<{
  open?: boolean;
  isFocus?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.primary.black7};
  border-radius: 4px;
  position: absolute;
  top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 11px 0;
  z-index: 5;

  ${({ open }) =>
    !open
      ? `
    height: 0;
    width: 0;
    outline: none;
    background-color: transparent;
    opacity: 0;
    padding: 0;
    margin: 0;
  `
      : ''};
`;

export const SelectOption = styled.div<{ active?: boolean }>`
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding: 5px 15px;
  margin-bottom: 6px;
  cursor: pointer;
  background-color: ${({ theme, active }) => (active ? theme.colors.primary.black6 : 'transparent')};
`;

export const HiddenInput = styled.input`
  height: 0;
  width: 0;
  outline: none;
  background-color: transparent;
  opacity: 0;
  padding: 0;
  margin: 0;
`;

// Phone number Item
export const PhoneNumberItem = styled.div<{
  error?: string;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus }) => genaralItemStyles({ theme, error, isFocus })};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  input {
    font-size: 16px;
    line-height: 24px;
    height: 46px;
    font-weight: 400;
    letter-spacing: 0.2em;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;

    :disabled {
      background-color: transparent;
      color: #868686;
    }
  }

  ${({ disabled, theme }) =>
    disabled
      ? `
    background-color: ${theme.colors.primary.black6}};
    border: 1px solid ${theme.colors.primary.black6};
    color: ${theme.colors.primary.black3};
    cursor: not-allowed
  `
      : ''};
`;

export const NewPhoneFlagSection = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid #eeeeee;
  height: 4.8rem;
  cursor: default;

  svg {
    margin-right: 1rem;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
`;

// Otp Input Item
export const OtpWrapper = styled.div`
  display: flex;
  max-width: 420px;
  justify-content: space-between;

  input {
    min-width: 40px;
    text-align: center;
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

// Text Area Item

export const TextAreaItem = styled.textarea<{
  error?: string;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus }) => genaralItemStyles({ theme, error, isFocus })};

  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 10px 12px;
  font-weight: 600;
  height: 120px;
  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary.black2};
  }
`;

// Counter
export const CounterWrapper = styled.div<{
  width?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || `fit-content`};
`;

export const CounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary.green1};
  height: 19px;
  width: 19px;
  border-radius: 4px;
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;
`;

export const CounterItem = styled.input<{
  error?: string;
  value: string;
}>`
  ${({ theme, error }) => genaralItemStyles({ theme, error })};

  font-size: 14px;
  line-height: 150%;
  height: 46px;
  padding: 0 12px;
  font-weight: 600;
  margin: 0 7px;
  min-width: 1px;
  cursor: pointer;
  text-align: center;

  :active,
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary.black2};
  }
`;

// Document select
export const HiddenDocumentSelect = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  outline: none;
  border: none;
`;

export const DocumentSelectItem = styled.div<{
  error?: string;
  disabled?: boolean;
  active?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, disabled, active, isFocus }) =>
    genaralItemStyles({ theme, error, disabled, dashed: !active, isFocus })};
  display: flex;
  padding: 12px 11px;
  min-height: 46px;
  margin-right: 10px;
  align-items: flex-start;
  cursor: ${({ active }) => (active ? '' : 'pointer')};
`;

export const DocumentListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
`;

export const Document = styled.div`
  /* height: 22px; */
  height: fit-content;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary.green9};
  display: flex;
  align-items: center;
  padding: 0 10px;
  max-width: 174px;
  margin-right: auto;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 180%;
    display: block;
    color: ${({ theme }) => theme.colors.primary.green8};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
  }

  svg {
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const DocumentIconWrapper = styled.div<{ active?: boolean }>`
  margin-left: auto;
  cursor: pointer;

  svg {
    path {
      ${({ active, theme }) => (active ? `fill: ${theme.colors.primary.green6};` : '')}
    }
  }
`;

// Calendar

export const CalendarItemWrapper = styled.div`
  position: relative;
  /* default styling */

  .react-date-picker {
    display: inline-flex;
    position: relative;
    margin-top: 6rem;
  }
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid gray;
  }
  .react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }
  .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-date-picker__button {
    border: 0;
    /* background: transparent; */
    padding: 4px 6px;
  }
  .react-date-picker__button:enabled {
    cursor: pointer;
  }
  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
  }
  .react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-date-picker__button svg {
    display: inherit;
  }

  .react-calendar__navigation__label {
    grid-column: 1;
    grid-row: 1;
  }

  .react-calendar__navigation {
    display: grid;
    grid-template-columns: 5fr repeat(4, 1fr);
  }
  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-date-picker__calendar--closed {
    display: none;
  }
  .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }

  /* custom css */
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__wrapper {
    border: none;
  }
  .react-date-picker__calendar {
    top: -4px !important;
  }
  .react-date-picker__calendar .react-calendar {
    border: 1px solid #eaedf3;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }

  .react-calendar__month-view__weekdays {
    margin: 0 20px;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 12px 0 7px;
    color: #121212;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days {
    margin: 0 20px 30px 20px;
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.nColors['n-yellow1']};
    border-radius: 2px;
    color: black;
  }

  .react-calendar__tile .react-calendar__month-view__days__day {
    color: #717171 !important;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #717171 !important;
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd
    .react-calendar__tile--rangeBothEnds
    .react-calendar__month-view__days__day
    .react-calendar__month-view__days__day--weekend {
    color: #ffffff !important;
  }

  .react-calendar__tile {
    color: #717171;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .react-calendar__tile .react-calendar__tile--active {
    color: white !important;
  }

  .react-calendar__tile--active {
    background: #ffcc00;
    border-radius: 2px;
    color: white !important;
  }

  .react-calendar__tile--active .react-calendar__month-view__days__day--weekend {
    color: white;
  }

  .react-calendar__tile .react-calendar__month-view__days__day {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'cv11' on;
    color: #717171 !important;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #aeacac !important;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${({ theme }) => theme.nColors['n-yellow1']};
  }

  .react-date-picker__inputGroup {
    height: 0;
    width: 0;
    opacity: 0;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #ffcc00;
    border-radius: 2px;
  }

  .react-calendar__tile:disabled {
    background: #ffcc00 !important;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .react-date-picker {
    position: absolute;
  }

  .react-date-picker .react-date-picker--closed .react-date-picker--enabled {
    position: absolute;
  }

  .react-date-picker__button {
    height: 0;
    width: 0;
    opacity: 0;
  }
`;

export const CalendarItem = styled.div<{
  error?: string;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, disabled, isFocus }) => genaralItemStyles({ theme, error, disabled, isFocus })};
  display: flex;
  // padding: 12px 11px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  // height: 46px;
  position: relaavtive;
  align-items: center;
  cursor: pointer;
  height: 22px;
  border: none;
`;

export const CalendarIconWrapper = styled.div<{ active?: boolean }>`
  margin-left: auto;

  ${({ active, theme }) =>
    active
      ? `svg {
      width:20px;
      height:20px;
    path {
      fill: ${theme.colors.primary.green6};
    }
  }`
      : ''};
`;

// Downshift
export const DownShiftOptionsWrapper = styled.ul<{
  open?: boolean;
  isFocus?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.primary.black7};
  border-radius: 4px;
  position: absolute;
  top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 11px 0;
  z-index: 5;
  list-style-type: none;
  max-height: 400px;
  overflow-y: auto;

  ${({ open }) =>
    !open
      ? `
    height: 0;
    width: 0;
    outline: none;
    background-color: transparent;
    opacity: 0;
    padding: 0;
    margin: 0;
  `
      : ''};
`;

export const DownShiftOption = styled.li<{ active?: boolean }>`
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding: 10px 15px;
  margin-bottom: 6px;
  cursor: pointer;
  background-color: ${({ theme, active }) => (active ? theme.colors.primary.black6 : 'transparent')};
`;

export const DownShiftItem = styled.button<{
  error?: string;
  open?: boolean;
  disabled?: boolean;
  isFocus?: boolean;
}>`
  ${({ theme, error, isFocus, disabled }) => genaralItemStyles({ theme, error, isFocus, disabled })};

  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  height: 50px;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: #fff;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  svg {
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;
