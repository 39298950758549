import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { Business } from '../../types/business';

// ---------------------------   FETCH BUSINESS START   ---------------------------//

export interface FetchBusinessResponse extends ApiResponse {
  data: Business;
  dataList?: null;
  meta?: null;
}

export const fetchBusinessDetails = createAsyncThunk(
  'business/business-details',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const response: FetchBusinessResponse = await axios.get(`business`);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH BUSINESS END   ---------------------------//
