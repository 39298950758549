import { useState } from 'react';
import { AppliedFilter } from './defs';

const useFilterModel = ({ onApplyFilters }: { onApplyFilters: (filters: AppliedFilter[]) => void }) => {
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);

  function getOtherFilters(query: string) {
    return appliedFilters.filter((item) => item.query !== query);
  }

  const handleApplyFilter = ({ query, value }: AppliedFilter) => {
    const newFilters = getOtherFilters(query);

    setAppliedFilters([...newFilters, { query, value }]);
  };
  const handleRemoveFilter = (query: string) => {
    const newFilters = getOtherFilters(query);
    setAppliedFilters(newFilters);

    onApplyFilters(newFilters);
  };
  const handleClearFilters = () => setAppliedFilters([]);

  return { appliedFilters, handleApplyFilter, handleRemoveFilter, handleClearFilters };
};

export default useFilterModel;
