import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';
import { ChevronDown } from '../../assets/svg';
import { FilterMobileWrapper, FilterWrapper } from './styles';
import FilterItem from './filterItem';

export interface FilterConfig {
  name: string;
  type: 'select' | 'date' | 'custom' | 'searchInput';
  options: { text: string; value: string; checked?: boolean }[];
  query: string;
  defaultValue?: string | Date;
  dateFilterType?: 'from' | 'to';
  required?: boolean;
  inputPlaceholder?: string;
  length?: number;
}

export interface FilterProps {
  config: FilterConfig[];
  disableDatesAfterToday?: boolean;
  onChange: (value: { query: string; value: string | Date }) => void;
  onRemove: (value: string) => void;
  mobileMarginTop?: number;
  onChangeCustomField?: (e: ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  boxShadow?: boolean;
  border?: boolean;
  filterWrapperMarginTop?: string;
}

const Filter = ({
  config,
  onChange,
  onRemove,
  mobileMarginTop,
  onChangeCustomField,
  width,
  border,
  boxShadow,
  filterWrapperMarginTop,
}: FilterProps) => {
  const [showMobile, setShowMobile] = useState<boolean>(false);

  const [dateFrom, setDateFrom] = useState<Date>(moment(new Date()).toDate());
  const [dateTo, setDateTo] = useState<Date>(moment(new Date()).toDate());

  return (
    <>
      <FilterMobileWrapper
        marginTop={mobileMarginTop}
        showMobile={showMobile}
        border={border}
        boxShadow={boxShadow}
        onClick={() => setShowMobile(!showMobile)}
      >
        <span>Filter</span> <ChevronDown />
      </FilterMobileWrapper>
      <FilterWrapper show={showMobile} config={config} width={width} filterWrapperMarginTop={filterWrapperMarginTop}>
        {config.map((item) => (
          <FilterItem
            key={item?.name}
            {...item}
            onChange={onChange}
            disableDatesAfterToday
            {...{ dateFrom, setDateFrom, dateTo, setDateTo }}
            onRemove={onRemove}
            onChangeCustomField={onChangeCustomField}
          />
        ))}
      </FilterWrapper>
    </>
  );
};

export default Filter;
