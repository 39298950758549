import styled from 'styled-components';

export const PageLoaderWrapper = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height || 'calc(100vh - 60px)'};
  display: flex;
  align-items: center;
  justify-content: center;
  svg.page-wrapper-loader {
    width: 75px;
    height: 75px;
    path {
      animation-name: changeSvgFill;
      animation-duration: 1.5s;
      animation-timing-function: ease-out;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }
  ${({ theme }) => `
  @keyframes changeSvgFill {
    0% {
      fill: ${theme.nColors['n-dark']};
      border-radius: 100%;
    }

    50% {
      fill: ${theme.nColors['n-grey2']};
    }

    100% {
      fill: ${theme.nColors['n-dark']};
    }
  }
  `}
`;
