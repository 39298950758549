/* eslint-disable new-cap */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import { useAppSelector } from '../../../app/hooks';
import { AppliedFilter } from '../../../components/newFilter/defs';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { useToast } from '../../../hooks/useToast';
import fetchProducts from '../../../queries/inventory/products/fetchProducts';
import createMenu from '../../../queries/menu/menu-list/createMenu';
import createMenuListProducts from '../../../queries/menu/menu-list/createMenuListProducts';
import editCoverImage from '../../../queries/menu/menu-list/editCoverImage';
import fetchMenuListProducts from '../../../queries/menu/menu-list/fetchMenuListProducts';
import { WebBeError } from '../../../types/error';
import { NewProduct, Product } from '../../../types/inventory/products';
import { CreateMenuItems, MenuListProduct, MenuListResponse } from '../../../types/menu/menuList';
import { getParentBusinessId, getUserBrowser, uploadToDrake } from '../../../utils';
import { PAGE_SIZE } from '../../inventory/constants';
import config from '../../../utils/config';
import putUpdateMenuListItem from '../../../queries/menu/putUpdateMenuListItem';
import fetchMenuListAliasDetails from '../../../queries/menu/menu-list/fetchMenuAliasDetails';

const useMenuListProductsModel = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [productBody, setProductBody] = useState<MenuListResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [pageTokens, setPageTokens] = useState<string[]>(['']);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [showAddMenuProductSuccessModal, setShowAddMenuProductSuccessModal] = useState<boolean>(false);
  const [hasEdit, setHasEdit] = useState<boolean>(false);
  const [showGettingStarted, setShowGettingStarted] = useState(false);

  const { fullUser } = useAppSelector((state) => state.auth);
  const { inventoryStore } = useAppSelector((state) => state.dashboard);
  const hasStore = !!inventoryStore?.storeId;
  const toast = useToast(3000);

  const SIZE = '500x500';
  const doc = new jsPDF('p', 'em');

  const getImageSrc = (tagId: string) => {
    const content = `${config.menuAppUrl}/${tagId}`;
    const URL = `https://chart.googleapis.com/chart?chs=${SIZE}&cht=qr&chl=${content}&choe=UTF-8`;
    return URL;
  };

  const renderImagesPDF = () => {
    const marginX = 0;
    const marginY = 0;
    const qrSize = 0;

    const imageData = new Image(300, 300);
    imageData.src = getImageSrc(
      productBody && productBody.data && productBody.data.alias ? productBody.data.alias : ''
    );
    doc.addImage(imageData, 'PNG', marginX, marginY, qrSize, qrSize);
  };

  const handleDownload = () => {
    renderImagesPDF();
    doc.save('QrMenu.pdf');
  };

  const handleFetchMenuListProducts = async (alias: string) => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('limit', String(PAGE_SIZE));
    setProductBody(undefined);
    setIsLoading(true);
    const { result, error: fetchError } = await fetchMenuListAliasDetails({ alias });
    setIsLoading(false);
    if (result?.data) {
      setProductBody(result);
      setPageTokens((pageResetTokens) => [...pageResetTokens, result?.data?.pageToken as string]);
      setIsLastPage(!result.data.pageToken);
    }
    if (fetchError && !hasStore) {
      setShowGettingStarted(true);
    }
  };

  const handleFetchMenus = async () => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('limit', String(PAGE_SIZE));
    setProductBody(undefined);
    setIsLoading(true);
    const { result, error: fetchError } = await fetchMenuListProducts({ params });
    setIsLoading(false);
    if (result?.data) {
      handleFetchMenuListProducts(result?.data?.[0]?.alias);
    }
    if (fetchError && !hasStore) {
      setShowGettingStarted(true);
    }
  };

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (!file) return;
    setIsLoading(true);

    await uploadToDrake(
      [file],
      (value) => {
        Mixpanel.track('MENU_UPLOAD_COVER_IMAGE_TO_DRAKE', {
          timeStamp: new Date().toString(),
          userBrowser: getUserBrowser(),
        });
        setImageUrl(value[0]);
      },
      () => {
        Mixpanel.track('MENU_ERROR_UPLOADING_TO_DRAKE', {
          timeStamp: new Date().toString(),
          userBrowser: getUserBrowser(),
          error: 'Error Uploading Cover Image to drake',
        });
        toast('error', "Sorry we couldn't upload the image");
      }
    );

    setIsLoading(false);
  };

  const handleEditCoverImage = async ({ menuId, coverImageUrl }: { menuId: string; coverImageUrl: string }) => {
    const payload = {
      businessId: getParentBusinessId(),
      coverImageUrl,
    };
    setIsLoading(true);

    Mixpanel.track('MENU_INITIATES_COVER_IMAGE_UPLOAD', {
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
      error: 'Something went wrong editing menu image',
    });

    const { error } = await editCoverImage({ menuId, payload });

    if (error) {
      toast('error', "Sorry we couldn't edit the image");
      Mixpanel.track('MENU_ERROR_EDITING_COVER_IMAGE', {
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
        error: 'Something went wrong editing menu image',
      });
      setIsLoading(false);
    } else {
      toast('success', 'Cover image edited successfully');
      handleFetchMenus();
      Mixpanel.track('MENU_EDIT_COVER_IMAGE', {
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
      });
    }
    setIsLoading(false);
  };

  const handleCreateMenu = async (product: CreateMenuItems, createMenuImageUrl: string) => {
    const payload = {
      name: inventoryStore?.storeName as string,
      coverImageUrl: createMenuImageUrl,
      items: [product],
    };
    const { error, result } = await createMenu(payload);

    if (error) {
      toast('error', "Sorry we couldn't create the menu");
    } else {
      setShowAddMenuProductSuccessModal(true);
      Mixpanel.track('MENU_CREATED', {
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
      });
      return result;
    }
  };

  const handleSearchMenuList = (name: string) => {
    Mixpanel.track('MENU_ERROR_CREATING', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      searchItem: name,
    });
    searchParams.set('name', name);
    setSearchParams(searchParams);
  };

  const handleClearSearch = () => {
    if (searchParams.has('name')) {
      searchParams.delete('name');
      setSearchParams(searchParams);
    }
  };

  const handleRefetch = () => setRefetch(!refetch);

  const handleFilterMenuList = (filterItems: AppliedFilter[]) => {
    const params = new URLSearchParams();
    filterItems.forEach((item) => {
      params.set(item.query, item.value as string);
    });

    setSearchParams(params);
    Mixpanel.track('MENU_FILTER_MENU_ITEMS', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      filter: filterItems.toString(),
    });
  };

  const removeMenuListItem = async ({ menuId, itemId }: { menuId: string; itemId: number }) => {
    const payload = {
      option: 'REMOVE',
      itemIds: [itemId],
    };
    setIsLoading(true);
    const { result, error: fetchError } = await createMenuListProducts(payload, menuId);
    setIsLoading(false);
    if (result?.success) {
      toast('success', 'Item Removed Successfully');
      handleFetchMenus();
    }
    if (fetchError) toast('error', 'An error occured while removing menu Item');
  };

  const toggleMenuListItemStatus = async (product: MenuListProduct) => {
    const enabledProduct: boolean = product.enabled;
    const { id, category, discount, timeCreated, ...remainingProducts } = product;
    const payload = {
      ...remainingProducts,
      categoryId: category.id,
      discountId: discount.id,
      autoGenerateSKU: true,
      enabled: !enabledProduct,
    };

    setIsLoading(true);
    const { result, error: fetchError } = await putUpdateMenuListItem(payload, id as string);
    setIsLoading(false);
    if (result?.data) {
      toast('success', 'Item Updated Successfully');
      handleFetchMenus();
    }
    if (fetchError) toast('error', 'An error occured while disabling menu Item');
  };

  const createMenuListItem = async ({ menuId, itemId }: { menuId: string; itemId: number[] }) => {
    const payload = {
      option: 'ADD',
      itemIds: itemId,
    };

    setIsLoading(true);
    const { result, error: fetchError } = await createMenuListProducts(payload, menuId);
    setIsLoading(false);
    if (result?.data) {
      toast('success', 'Item added to menu Successfully');
      setShowAddMenuProductSuccessModal(true);
      setHasEdit(false);
      handleFetchMenus();
      Mixpanel.track('MENU_ADD_NEW_ITEM', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
      });
    }
    if (fetchError) {
      Mixpanel.track('MENU_ERROR_ADDING_NEW_ITEM', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        error: 'An error occured while adding Item to menu',
      });
      toast('error', 'An error occured while adding Item to menu');
    }
  };

  const handleFetchProducts = async () => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('limit', String(PAGE_SIZE));
    setIsLoading(true);
    const { result, error: fetchError } = await fetchProducts({ params });
    setIsLoading(false);
    if (result?.data) {
      setProducts(result.data.list);
      setPageTokens((pageResetTokens) => [...pageResetTokens, result.data.pageToken]);
      setIsLastPage(!result.data.pageToken);
    }
    if (fetchError)
      toast('error', (fetchError as WebBeError)?.data.message || 'An error occured while fetching products');
  };

  const handleCloseAddMenuSuccessModal = () => {
    setShowAddMenuProductSuccessModal(false);
  };

  const handleOpenAddMenuSuccessModal = () => {
    setShowAddMenuProductSuccessModal(true);
  };

  const handleOnchangePagination = (page: number) => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('cursor', pageTokens[page]);
    setSearchParams(params.toString());
  };

  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams());
  };

  useEffect(() => {
    const request = new AbortController();
    handleFetchMenus();
    return () => request.abort();
  }, [refetch, searchParams]);

  return {
    productBody,
    products,
    handleFetchProducts,
    isLoading,
    handleFilterMenuList,
    handleSearchMenuList,
    handleClearSearch,
    handleRefetch,
    removeMenuListItem,
    createMenuListItem,
    imageUrl,
    handleUploadImage,
    handleEditCoverImage,
    handleCreateMenu,
    showAddMenuProductSuccessModal,
    showGettingStarted,
    setShowGettingStarted,
    setImageUrl,
    handleCloseAddMenuSuccessModal,
    handleOpenAddMenuSuccessModal,
    hasEdit,
    isLastPage,
    handleOnchangePagination,
    handleClearFilters,
    handleDownload,
    toggleMenuListItemStatus,
  };
};

export default useMenuListProductsModel;
