import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

interface InitialValues {
  password: string;
  confirmPassword: string;
  referralCode?: string;
}

const useCreatePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const accountType = location.pathname?.split('/auth/onboardingv3')?.[1]?.split('/')?.[1] || 'account-type';

  const [agree, setAgree] = useState<boolean>(false);

  const initialValues: InitialValues = {
    password: '',
    confirmPassword: '',
    referralCode: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Please enter a password')
      .min(8, 'Minimum of 8 chracters')
      .matches(/^(?=.*[a-z])/, 'at least 1 lower case letter')
      .matches(/^(?=.*[A-Z])/, 'at least 1 upper case letter')
      .matches(/^(?=.*[0-9])/, 'at least 1 number')
      .matches(/^(?=.*[!@#$%^&*])/, 'at least 1 special character'),
    confirmPassword: Yup.string()
      .required('Please confirm your password')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const onSuccess = () => {
    navigate(`/auth/onboardingv3/${accountType}/verification`);
  };

  return {
    initialValues,
    validationSchema,
    agree,
    toggleAgree: () => setAgree(!agree),
    onSuccess,
  };
};

export default useCreatePassword;
