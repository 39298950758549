import { createSlice } from '@reduxjs/toolkit';
import {
  fetchBeneficiaries,
  accountLookUp,
  addBeneficiary,
  deleteBeneficiary,
  editBeneficiary,
  nombaAccountLookUp,
  transferToNombaAccount,
  transferToBankAccount,
  uploadSampleDocument,
  fetchAllScheduledPayments,
  editScheduledPayment,
  cancelScheduledPayment,
  bulkTransferToBank,
  getServiceCharge,
  fetchTransfers,
  sendReceiptsToEmails,
  filterBeneficiariesBackend,
  filterTransfersBackend,
  downloadTransfersCsv,
  verifyOtp,
  resendOtp,
  kycAccountLookUp,
} from './thunkActions';
import { BeneficiariesData, SchedulePaymentData, TransferList } from '../../types/transactions';

interface InitialState {
  beneficiariesData: BeneficiariesData[] | null;
  scheduledData: {
    paymentRequestList: SchedulePaymentData[];
  };
  transfersData: {
    transfers: TransferList[] | null;
  };
  message: string | null;
  inProgress: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  isUploading: boolean;
}

const initialState: InitialState = {
  beneficiariesData: [],
  scheduledData: {
    paymentRequestList: [],
  },
  transfersData: {
    transfers: [],
  },
  message: '',
  inProgress: false,
  isSubmitting: false,
  isDeleting: false,
  isUploading: false,
};

export const beneficiariesListSlice = createSlice({
  name: 'beneficiaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch beneficiaries list
    builder.addCase(fetchBeneficiaries.fulfilled, (state, { payload }) => {
      state.beneficiariesData = payload?.data || null;
      state.message = payload?.msg || null;
    });
    // fetch transfers list
    builder.addCase(fetchTransfers.fulfilled, (state, { payload }) => {
      state.transfersData.transfers = payload?.data?.transfers || [];
      state.message = payload?.msg || null;
    });
    // account look up
    builder
      .addCase(accountLookUp.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(accountLookUp.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(accountLookUp.fulfilled, (state) => {
        state.inProgress = false;
      });
    // kyc account look up
    builder
      .addCase(kycAccountLookUp.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(kycAccountLookUp.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(kycAccountLookUp.fulfilled, (state) => {
        state.inProgress = false;
      });
    // add beneficiary
    builder
      .addCase(addBeneficiary.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(addBeneficiary.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(addBeneficiary.fulfilled, (state) => {
        state.isSubmitting = false;
      });
    // delete beneficiary
    builder
      .addCase(deleteBeneficiary.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteBeneficiary.rejected, (state) => {
        state.isDeleting = false;
      })
      .addCase(deleteBeneficiary.fulfilled, (state) => {
        state.isDeleting = false;
      });
    // edit beneficiary
    builder
      .addCase(editBeneficiary.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(editBeneficiary.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(editBeneficiary.fulfilled, (state) => {
        state.isSubmitting = false;
      });
    // nomba account look up
    builder
      .addCase(nombaAccountLookUp.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(nombaAccountLookUp.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(nombaAccountLookUp.fulfilled, (state) => {
        state.inProgress = false;
      });
    // single tranfer to nomba
    builder
      .addCase(transferToNombaAccount.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(transferToNombaAccount.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(transferToNombaAccount.fulfilled, (state) => {
        state.isSubmitting = false;
      });
    // single tranfer to bank
    builder
      .addCase(transferToBankAccount.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(transferToBankAccount.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(transferToBankAccount.fulfilled, (state) => {
        state.isSubmitting = false;
      });
    // upload sample doc
    builder
      .addCase(uploadSampleDocument.pending, (state) => {
        state.isUploading = true;
      })
      .addCase(uploadSampleDocument.rejected, (state) => {
        state.isUploading = false;
      })
      .addCase(uploadSampleDocument.fulfilled, (state) => {
        state.isUploading = false;
      });

    // bulk transfer to bank
    builder
      .addCase(bulkTransferToBank.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(bulkTransferToBank.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(bulkTransferToBank.fulfilled, (state) => {
        state.isSubmitting = false;
      });
    // fetch all scheduled payments
    builder
      .addCase(fetchAllScheduledPayments.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(fetchAllScheduledPayments.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(fetchAllScheduledPayments.fulfilled, (state, { payload }) => {
        state.scheduledData.paymentRequestList = payload?.data?.paymentRequestList || [];
        state.message = payload?.msg || null;
      });
    // edit scheduledpayment
    builder
      .addCase(editScheduledPayment.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(editScheduledPayment.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(editScheduledPayment.fulfilled, (state) => {
        state.isSubmitting = false;
      });
    // cance scheduled payment
    builder
      .addCase(cancelScheduledPayment.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(cancelScheduledPayment.rejected, (state) => {
        state.isDeleting = false;
      })
      .addCase(cancelScheduledPayment.fulfilled, (state) => {
        state.isDeleting = false;
      });
    // get service charge
    builder
      .addCase(getServiceCharge.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(getServiceCharge.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(getServiceCharge.fulfilled, (state) => {
        state.isSubmitting = false;
      });
    // send receipts
    builder
      .addCase(sendReceiptsToEmails.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(sendReceiptsToEmails.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(sendReceiptsToEmails.fulfilled, (state) => {
        state.inProgress = false;
      });

    // fetch beneficiaries list
    builder.addCase(filterBeneficiariesBackend.fulfilled, (state, { payload }) => {
      state.beneficiariesData = payload?.data || null;
      state.message = payload?.msg || null;
    });
    // fetch transfers list
    builder.addCase(filterTransfersBackend.fulfilled, (state, { payload }) => {
      state.transfersData.transfers = payload?.data || null;
      state.message = payload?.msg || null;
    });
    // download transfer csv
    builder
      .addCase(downloadTransfersCsv.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(downloadTransfersCsv.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(downloadTransfersCsv.fulfilled, (state) => {
        state.inProgress = false;
      });
    // verify otp
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(verifyOtp.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.inProgress = false;
      });
    // resend otp
    builder
      .addCase(resendOtp.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(resendOtp.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.inProgress = false;
      });
  },
});

export default beneficiariesListSlice.reducer;
export * from './thunkActions';
