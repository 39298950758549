import { Typography, NTheme as theme } from 'kudi-component-library';
import styled, { css } from 'styled-components';
import { PositionStyle } from './type';

export const TooltipWrapper = styled.div<{ containerStyle: PositionStyle }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 13px;
  background: ${theme.nColors['n-dark']};
  border-radius: 5px;
  position: absolute;
  ${({ containerStyle }) => {
    const { bottom, top, left, right, maxWidth } = containerStyle;
    return css`
      bottom: ${bottom || 'initial'};
      top: ${top || 'inherit'};
      left: ${left || 'inherit'};
      right: ${right || 'inherit'};
      max-width: ${maxWidth || 'unset'};
    `;
  }}
`;

export const TooltipText = styled(Typography)<{ textWrap?: string }>`
  line-height: 22px;
  text-wrap: ${({ textWrap }) => textWrap || 'nowrap'};
  width: ${({ textWrap }) => (textWrap ? 'max-content' : 'initial')};
`;

export const TooltipArrowWrapper = styled.div<{ arrowStyle: PositionStyle }>`
  position: absolute;
  ${({ arrowStyle }) => {
    const { bottom, top, left, right } = arrowStyle;
    return css`
      bottom: ${bottom || 'initial'};
      top: ${top || 'inherit'};
      left: ${left || 'inherit'};
      right: ${right || 'inherit'};
    `;
  }}
`;
