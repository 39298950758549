import { FullUser } from '../../types/auth';
import { NewTransactions } from '../../types/transactions';
import { Contact, IssueCategory, IssueRequestBody, IssueType } from './defs';

export const groupIssueCategoriesAndSub = (list: IssueCategory[]) => {
  const sub: Record<number, IssueCategory[]> = {};

  const main: IssueCategory[] = [];

  list?.map((item) => {
    if (item.parentIndex < 0) {
      main.push(item);
    }

    if (!sub[item.parentIndex] && item.parentIndex >= 0) {
      sub[item.parentIndex] = [];
    }

    if (item.parentIndex >= 0) {
      sub[item.parentIndex].push(item);
    }

    return item;
  });

  return { main, sub };
};

export const extractContactInfo = (list: Contact[]) => {
  const socialList = list.filter((item) => item.contactType === 'SOCIAL_MEDIA');
  const addresses = list.filter((item) => item.contactType === 'ADDRESS');
  const phones = list.filter((item) => item.contactType === 'PHONE');

  return {
    social: {
      instagram: socialList.find((item) => item.description.match(/instagram/i))?.contactValue || '',
      facebook: socialList.find((item) => item.description.match(/facebook/i))?.contactValue || '',
      twitter: socialList.find((item) => item.description.match(/twitter/i || /x/i))?.contactValue || '',
    },
    addresses,
    phones,
    email: list.filter((item) => item.contactType === 'EMAIL')?.[0]?.contactValue || '',
  };
};

export const extractIssueIdFromJSONData = (data: string) => {
  const parsed = JSON.parse(data) as Record<string, string>;

  return parsed.issueTicketId;
};

export const generateIssueRequestBody = ({
  selectedTransaction,
  selectedType,
  issueDecription,
  issueDocumentList,
  fullUser,
}: {
  selectedTransaction: NewTransactions | null;
  selectedType: IssueType | null;
  issueDecription: string;
  issueDocumentList: Record<string, string>;
  fullUser: FullUser | null | undefined;
}): IssueRequestBody => ({
  title: `${selectedType?.prettyName || ''} - ${selectedTransaction?.id || ''}`,
  description: issueDecription,
  transactionId: selectedTransaction?.id || '',
  amount: selectedTransaction?.amount || '',
  type: selectedTransaction?.type || '',
  posTransactionData: {
    transactionDate: selectedTransaction?.timeCreated || '',
    firstSixDigit: '',
    lastFourDigit: '',
    rrn: '',
    terminalId: '',
  },
  transactionTime: selectedTransaction?.timeCreated || '',
  issueId: selectedType?.id || '',
  contactReasonId: '',
  channel: 'WEB',
  senderBank: selectedTransaction?.ktaSenderName || '',
  transferRoute: selectedTransaction?.source || '',
  depositorName: selectedTransaction?.senderName || '',
  pickUpAddress: '',
  comment: '',
  ownerId: '',
  createdBy: '',
  customerData: {
    customerName: `${fullUser?.firstName || ''} ${fullUser?.lastName || ''}`,
    customerAddress: '',
    customerBank: '',
    customerAccountNumber: '',
    customerPhoneNumber: fullUser?.phoneNumber || '',
  },
  tier: '',
  accountType: '',
  transactionDateObject: selectedTransaction?.timeCreated || '',
  imageFiles: Object.values(issueDocumentList).map((item) =>
    item.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
  ),
});
