import { Button, IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { ReactComponent as Created } from '../../assets/created.svg';
import {
  SubmittedIssueCloseWrapper,
  SubmittedIssueContentWrapper,
  SubmittedIssueFooterWrapper,
  SubmittedIssueWrapper,
} from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import { removeCreatedId, updateCurrentPage } from '../../reducer/slice';

const SubmittedSummary = () => {
  const {
    state: {
      support: { issueCreatedId },
    },
    dispatch,
  } = useRedux();

  const track = () => {
    dispatch(removeCreatedId());
    dispatch(updateCurrentPage('my-issues'));
  };

  const goHome = () => {
    dispatch(removeCreatedId());
    dispatch(updateCurrentPage('home'));
  };

  return (
    <SubmittedIssueWrapper>
      <SubmittedIssueCloseWrapper>
        <NIcons icon={IconsEnum.OUTLINE_X} />
      </SubmittedIssueCloseWrapper>
      <SubmittedIssueContentWrapper>
        <Created />
        <Typography fontWeight={700} fontSize="20px" lineHeight="30px">
          Issue submitted successfully
        </Typography>
        <Typography fontWeight={400} fontSize="14px" lineHeight="22px">
          Your issue has been successfully submitted with id #{issueCreatedId}. You can track this issue by clicking the
          button below
        </Typography>
      </SubmittedIssueContentWrapper>
      <SubmittedIssueFooterWrapper>
        <Button onClick={track} variant="medium" width="100%">
          Track Issue
        </Button>
        <Typography onClick={goHome}>Go Home</Typography>
      </SubmittedIssueFooterWrapper>
    </SubmittedIssueWrapper>
  );
};

export default SubmittedSummary;
