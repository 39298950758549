import moment from 'moment';
import React from 'react';
import { CloseModal, WithdrawSuccessIcon, TransactionIconSm } from '../../assets/svg';
import { BusinessTransactionData } from '../../types/transactions';
import { formatCurrency, formatTableDateValue } from '../../utils';
import {
  TransactionInfoWrapper,
  TransactionInfoContentWrapper,
  TransactionInfoBacground,
  TransactionInfoClose,
  TransactionInfoTitle,
  TransactionInfoAmount,
  TransactionInfoContentIconWrapper,
  TransactionInfoContentMain,
  TransactionInfoContentMainItem,
  TransactionInfoContentMainItemTitle,
  TransactionInfoContentMainItemValue,
  TransactionInfoBottom,
  TransactionInfoContentIconWrapperSm,
  TransactionStatus,
} from './styles';

const TransactionInfo = ({ data, onClose }: { data: BusinessTransactionData; onClose: () => void }) => {
  const fields = [
    { text: 'Transaction date', value: formatTableDateValue(moment(data?.['entry.timeCreated'])?.toDate()) },
    { text: 'Transaction type', value: data?.['entry.type'] },
    { text: 'Source', value: data?.['entry.source'] },
    { text: 'Transaction reference', value: data?.['entry._id'] },
  ];
  return (
    <TransactionInfoWrapper>
      <TransactionInfoBacground onClick={onClose} />
      <TransactionInfoContentWrapper>
        <TransactionInfoClose onClick={onClose}>
          <CloseModal />
        </TransactionInfoClose>
        <TransactionInfoContentIconWrapper>
          <WithdrawSuccessIcon />
        </TransactionInfoContentIconWrapper>
        <TransactionInfoContentIconWrapperSm>
          <TransactionIconSm />
        </TransactionInfoContentIconWrapperSm>
        <TransactionInfoTitle>Transaction Amount</TransactionInfoTitle>
        <TransactionInfoAmount>{formatCurrency(data?.['entry.amount'])}</TransactionInfoAmount>
        <TransactionInfoContentMain>
          <TransactionInfoContentMainItem>
            <TransactionInfoContentMainItemTitle>Status</TransactionInfoContentMainItemTitle>
            <TransactionInfoContentMainItemValue>
              <TransactionStatus type={data?.['entry.status']}>{data?.['entry.status']} </TransactionStatus>
            </TransactionInfoContentMainItemValue>
          </TransactionInfoContentMainItem>
          {fields.map((item, key) => (
            <TransactionInfoContentMainItem key={`transaction-info-item-${key}`}>
              <TransactionInfoContentMainItemTitle>{item.text}</TransactionInfoContentMainItemTitle>
              <TransactionInfoContentMainItemValue>{item.value}</TransactionInfoContentMainItemValue>
            </TransactionInfoContentMainItem>
          ))}
        </TransactionInfoContentMain>
        <TransactionInfoBottom>
          <span>Having trouble with this transaction?</span>
          <p>Contact support</p>
        </TransactionInfoBottom>
      </TransactionInfoContentWrapper>
    </TransactionInfoWrapper>
  );
};

export default TransactionInfo;
