import { DropdownIndicatorProps, components } from 'react-select';
import Search2 from '../../assets/svg/search-2';
import { OptionType, ObjectKeyValuePair } from './types';

export const DropdownIndicator = (props: DropdownIndicatorProps<OptionType | ObjectKeyValuePair>) => {
  const { selectProps } = props;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {selectProps.isSearchable && selectProps.menuIsOpen && <Search2 width={20} height={20} />}
      </components.DropdownIndicator>
    )
  );
};
