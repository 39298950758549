import { SectionHeader, SocialMediaIconWrapper, SocialMediaWrapper } from '../../styles';
import { ReactComponent as Facebook } from '../../assets/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/twitter.svg';
import useRedux from '../../../../hooks/useRedux';

const SocialMedia = () => {
  const {
    state: {
      support: {
        contacts: {
          info: {
            social: { instagram, facebook, twitter },
          },
        },
      },
    },
  } = useRedux();
  const list = [
    { link: instagram, Icon: <Instagram /> },
    { link: facebook, Icon: <Facebook /> },
    { link: twitter, Icon: <Twitter /> },
  ];

  return (
    <div>
      <SectionHeader>
        <p>Social Media</p>
      </SectionHeader>
      <SocialMediaWrapper>
        {list.map(({ Icon, link }) => (
          <SocialMediaIconWrapper target="_blank" href={link} key={Math.random()}>
            {Icon}
          </SocialMediaIconWrapper>
        ))}
      </SocialMediaWrapper>
    </div>
  );
};

export default SocialMedia;
