/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { ReferralDownlines, ReferralDownlinesMock } from '../../types/referrals';
import { RootState } from '../../app/store';
// import { RequestedReferralDownlinesMock } from './mocks';

// -------------------- Create Referral ---------------------- //
export interface CreateReferralResponseData {
  data?: {
    id: number | string;
    userId: number | string;
    boltUserId: number | string;
    coreUserId: number | string;
    name: string;
    email: string;
    phone: string;
    businessAddress: string;
    ambassadorLevelTAndCAccepted: boolean;
    partnerLevelTAndCAccepted: boolean;
    referralCode: number | string;
    referralLevel: string;
    referralDownLines: string;
  };
}

export interface CreateReferralResponse extends ApiResponse {
  data?: CreateReferralResponseData;
  dataList: null;
  meta: null;
}

interface CreateReferralArgs extends ReduxAction {
  values: {
    userId: number | string;
    boltUserId: null | number | string;
    coreUserId: null | number | string;
    phoneNumber: null | string;
    ambassadorLevelTAndCAccepted: boolean;
    partnerLevelTAndCAccepted: boolean;
  };
}

export const createReferral = createAsyncThunk(
  '/referral/create-referral',
  async ({ values, onSuccess, onFailure }: CreateReferralArgs, { rejectWithValue }) => {
    try {
      const data: CreateReferralResponse = await axios.post(`/referral/create`, values);
      if (onSuccess) onSuccess(data);

      localStorage.setItem('ReferralAccepted', 'true');
      localStorage.setItem('ReferralDetail', JSON.stringify(data));

      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Create Referral ---------------------- //'

// -------------------- Referral Upgrade Eligibility Check ---------------------- //
export interface ReferralUpgradeEligibilityData {
  data?: {
    isEligible: boolean;
    currentLevel: string;
    nextLevel: null;
    notificationSent: boolean;
    eligible: false;
  };
}

export interface ReferralUpgradeEligibilityResponse extends ApiResponse {
  status: boolean;
  message: string;
  data?: ReferralUpgradeEligibilityData;
  dataList: null;
  meta: null;
}

export const ReferralUpgradeEligibility = createAsyncThunk(
  '/referral/referral-upgrade-elgibility',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const data: ReferralUpgradeEligibilityResponse = await axios.get(`/referral/check-upgrade-eligibility`);
      if (onSuccess) onSuccess(data);

      if (data.data?.data?.isEligible === true && data.data?.data?.nextLevel === 'LEVEL_TWO') {
        localStorage.setItem('ReferralUpgradeEligibility', 'true');
        localStorage.setItem('ReferralUpgradeNextLevel', 'Next Partner Level Two');
      } else if (data.data?.data?.isEligible === true && data.data?.data?.nextLevel === 'LEVEL_THREE') {
        localStorage.setItem('ReferralUpgradeEligibility', 'true');
        localStorage.setItem('ReferralUpgradeNextLevel', 'Next Partner Level Three');
      } else if (data.data?.data?.isEligible === true && data.data?.data?.nextLevel === 'LEVEL_FOUR') {
        localStorage.setItem('ReferralUpgradeEligibility', 'true');
        localStorage.setItem('ReferralUpgradeNextLevel', 'Next Partner Level Four');
      } else {
        localStorage.setItem('ReferralUpgradeEligibility', 'false');
      }

      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- End Referral Upgrade Eligibility Check ---------------------- //

// -------------------- Upgrade referral to partner ---------------------- //
export interface UpgradeReferralResponseData {
  data?: {
    status: boolean;
    message: string;
    data: string;
  };
}

export interface UpgradeReferralResponse extends ApiResponse {
  data?: UpgradeReferralResponseData;
  dataList: null;
  meta: null;
}

interface UpgradeReferralArgs extends ReduxAction {
  values: {
    userId: string | number | undefined;
    boltUserId: string | null | undefined;
    coreUserId: string | null | undefined;
    phoneNumber: string | null | undefined;
    // noOfUsers: number | string;
    ambassadorLevelTAndCAccepted: boolean;
    partnerLevelTAndCAccepted: boolean;
    name: string;
    email: string | null | undefined;
    businessAddress: string | null | undefined;
  };
}

export const UpgradeReferral = createAsyncThunk(
  '/referral/upgrade-to-partner',
  async ({ values, onSuccess, onFailure }: UpgradeReferralArgs, { rejectWithValue }) => {
    try {
      const data: UpgradeReferralResponse = await axios.put(`/referral/upgrade-to-partner`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- End Upgrade referral to partner ---------------------- //

// -------------------- Send Referral Invite ---------------------- //
export interface CreateInviteResponseData {
  data?: {
    status: boolean;
    message: string;
  };
}

export interface CreateInviteResponse extends ApiResponse {
  data?: CreateInviteResponseData;
  dataList: null;
  meta: null;
}

interface CreateInviteArgs extends ReduxAction {
  values: {
    name: string;
    phone: string;
    email: string;
    referralCode: number | string | undefined;
    baseUrl: string;
    signupPath: string;
  };
}

export const createInvite = createAsyncThunk(
  '/referral/send-referral-invite',
  async ({ values, onSuccess, onFailure }: CreateInviteArgs, { rejectWithValue }) => {
    try {
      const data: CreateInviteResponse = await axios.post(`/referral/send-invite?byEmail=true&byPhone=true`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Send Referral Invite ---------------------- //

// -------------------- Get Referral By User ID ---------------------- //
export interface GetReferralByUserIDData {
  data?: {
    id: number | string;
    name: string | null;
    email: string | null;
    businessAddress: string | null;
    userId: number | string;
    boltUserId: number | string;
    coreUserId: string | null | undefined;
    phone: string;
    ambassadorLevelTAndCAccepted: boolean;
    partnerLevelTAndCAccepted: boolean;
    referralCode: string | number;
    referralLevel: 'string';
    referralDownLines: Array<ReferralDownlines>;
  };
}

export interface GetReferralByUserIDDataResponse extends ApiResponse {
  status: boolean;
  message: string;
  data?: GetReferralByUserIDData;
  dataList: null;
  meta: null;
}

interface GetReferralByUserIDDataArgs extends ReduxAction {
  id: number | string | undefined;
}

export const GetReferralByUserID = createAsyncThunk(
  '/referral/get-referral-by-id',
  async ({ id, onSuccess, onFailure }: GetReferralByUserIDDataArgs, { rejectWithValue }) => {
    try {
      const data: GetReferralByUserIDDataResponse = await axios.get(`/referral/${id}`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Get Referral By User ID ----------------------- //

// -------------------- Get Referral By User Primary ID ---------------------- //
export interface GetReferralByUserPrimaryIDData {
  data?: {
    id: number | string;
    name: string | null;
    email: string | null;
    businessAddress: string | null;
    userId: number | string;
    boltUserId: number | string;
    coreUserId: string | null | undefined;
    phone: string;
    ambassadorLevelTAndCAccepted: boolean;
    partnerLevelTAndCAccepted: boolean;
    referralCode: string | number;
    referralLevel: 'string';
    referralDownLines: Array<ReferralDownlines>;
  };
}

export interface GetReferralByUserPrimaryIDDataResponse extends ApiResponse {
  status: boolean;
  message: string;
  data?: GetReferralByUserPrimaryIDData;
  dataList: null;
  meta: null;
}

interface GetReferralByUserPrimaryIDDataArgs extends ReduxAction {
  id: number | string | undefined;
}

export const GetReferralByUserPrimaryID = createAsyncThunk(
  '/referral/get-referral-by-primary-id',
  async ({ id, onSuccess, onFailure }: GetReferralByUserPrimaryIDDataArgs, { rejectWithValue }) => {
    try {
      const data: GetReferralByUserPrimaryIDDataResponse = await axios.get(`/referral/${id}?primary=true`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Get Referral By User Primary ID ----------------------- //

// -------------------- Assign Referrered User To Referrer ---------------------- //

export interface AssignReferreredUserToReferralData {
  data?: {
    id: number | string;
    createdAt: Date | null;
    updatedAt: Date | null;
    deletedAt: Date | null;
    referralType: string;
    referrer?: {
      id: number | string;
      createdAt: Date | null;
      updatedAt: Date | null;
      deletedAt: Date | null;
      name: string | null;
      email: string | null;
      businessAddress: string | null;
      userId: string;
      boltUserId: string;
      coreUserId: string;
      phone: string;
      ambassadorLevelTAndCAccepted: boolean;
      partnerLevelTAndCAccepted: boolean;
      referralCode: string;
      referralLevel: string;
      referralDownLines: Array<ReferralDownlines>;
    };
    name: string;
    phoneNumber: string;
  };
}

export interface AssignReferreredUserToReferralDataResponse extends ApiResponse {
  status: boolean;
  message: string;
  data?: AssignReferreredUserToReferralData;
  dataList: null;
  meta: null;
}

interface AssignReferreredUserToReferralDataArgs extends ReduxAction {
  values: {
    referralType: string;
    referrerCode: string | null;
    name: string;
    phoneNumber: string;
  };
}

export const AssignReferreredUserToReferral = createAsyncThunk(
  '/referral/assign-referred-user-to-referral',
  async ({ values, onSuccess, onFailure }: AssignReferreredUserToReferralDataArgs, { rejectWithValue }) => {
    try {
      const data: AssignReferreredUserToReferralDataResponse = await axios.post(`/referral/assign-downline`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Assign Referrered User To Referrer ---------------------- //

// -------------------- Get Referrer Overview ---------------------- //

export interface GetReferralOverviewResponse extends ApiResponse {
  data: {
    status: string;
    balance: number;
    totalReferrals: number;
    totalActiveReferrals: number;
    totalPendingReferrals: number;
    totalInActiveReferrals: number;

    totalActiveTerminals: number;
    totalAssignedTerminals: number;
    totalTerminals: number;
    totalUnassignedTerminals: number;
  };
  dataList: null;
  meta: null;
}

export const GetReferralOverview = createAsyncThunk(
  '/referral/get-referral-overview',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const data: GetReferralOverviewResponse = await axios.get(`/referral/overview`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Get Referrer Overview ---------------------- //

// -------------------- Upgrade partner referral ---------------------- //
export interface UpgradePartnerReferralData {
  data?: {
    id: number | string;
    createdAt: Date | null;
    updatedAt: Date | null;
    deletedAt: Date | null;
    name: string;
    email: string;
    businessAddress: string;
    userId: string;
    boltUserId: string;
    coreUserId: string;
    phone: string;
    ambassadorLevelTAndCAccepted: boolean;
    partnerLevelTAndCAccepted: boolean;
    referralCode: string;
    referralLevel: string;
    referralDownLines: Array<ReferralDownlines>;
  };
}

export interface UpgradePartnerReferralDataResponse extends ApiResponse {
  status: boolean;
  message: string;
  data?: UpgradePartnerReferralData;
  dataList: null;
  meta: null;
}

interface UpgradePartnerReferralDataArgs extends ReduxAction {
  id: string | number | undefined;
}

export const UpgradePartnerReferral = createAsyncThunk(
  'referral/upgrade-partner-referral',
  async ({ id, onSuccess, onFailure }: UpgradePartnerReferralDataArgs, { rejectWithValue }) => {
    try {
      const data: UpgradePartnerReferralDataResponse = await axios.put(
        `/referral/${id}/upgrade-partner?next_level=Super Aggregator`
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Upgrade partner referral ---------------------- //

// -------------------- Get Referral Downlines ---------------------- //
export interface GetReferralDownlinesResponse extends ApiResponse {
  // status: boolean;
  // message: string;
  // data: null;
  // dataList: null;
  // meta: null;
  status: boolean;
  message: string;
  data: null;
  dataList: Array<ReferralDownlinesMock>;
  meta: null;
}

interface GetReferralDownlinesDataArgs extends ReduxAction {
  filters: {
    status?: string | null;
  };
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
}

interface SearchReferralDownlinesDataArgs extends ReduxAction {
  searchText: string;
}

export const GetReferralDownlines = createAsyncThunk(
  '/referral/get-referral-downlines',
  async (
    { filters, page, limit, sortBy, sortOrder, onSuccess, onFailure }: GetReferralDownlinesDataArgs,
    { rejectWithValue }
  ) => {
    try {
      const quaryLink = `/referral/get-downlines?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
      const data: Record<string, string> = await axios.get(quaryLink, {
        params: { ...filters, pageSize: 20 },
      });

      // const data: any = RequestedReferralDownlinesMock;
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const SearchReferralDownlines = createAsyncThunk(
  '/referral/get-referral-downlines',
  async ({ searchText, onSuccess, onFailure }: SearchReferralDownlinesDataArgs, { rejectWithValue }) => {
    try {
      const data: Record<string, string> = await axios.get(`/referral/search-downlines?searchText=${searchText}`);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- End Get Referral Downlines ----------------------- //

// -------------------- Assign Existing User to Referrer ---------------------- //
export interface AssignExistingUsertoReferrerResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: null;
  dataList: null;
  meta: null;
}

interface AssignExistingUsertoReferrerDataArgs extends ReduxAction {
  referralCode: string;
}

export const AssignExistingUsertoReferrer = createAsyncThunk(
  '/referral/assign-existing-user-to-referrer',
  async ({ referralCode, onSuccess, onFailure }: AssignExistingUsertoReferrerDataArgs, { rejectWithValue }) => {
    try {
      const data: AssignExistingUsertoReferrerResponse = await axios.put(
        `/referral/assign-existin?referral_code=${referralCode}`
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Assign Existing User to Referrer ---------------------- //

// -------------------- Complete Bolt Referral Creation ---------------------- //
export interface CompleteBoltReferralCreationResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: null;
  dataList: null;
  meta: null;
}

interface CompleteBoltReferralCreationDataArgs extends ReduxAction {
  coreUserId: string | null | undefined;
  referredPhone: string | null | undefined;
}

export const CompleteBoltReferralCreation = createAsyncThunk(
  '/referral/complete-bolt-referral-creation',
  async (
    { coreUserId, referredPhone, onSuccess, onFailure }: CompleteBoltReferralCreationDataArgs,
    { rejectWithValue }
  ) => {
    try {
      const data: AssignExistingUsertoReferrerResponse = await axios.put(
        `/referral/${coreUserId}/create/complete?referred_phone=${referredPhone}`
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Complete Bolt Referral Creation ---------------------- //

// -------------------- Move Referral Commission to Primary Wallet ---------------------- //
export interface MoveReferralCommissionToPrimaryWalletResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: null;
  dataList: null;
  meta: null;
}

interface MoveReferralCommissionToPrimaryWalletArgs extends ReduxAction {
  values: {
    userId: number | string | undefined;
    amount: number | string;
    boltUserId: string | null | undefined;
    transactionPin: string;
  };
}

export const MoveReferralCommissionToPrimaryWallet = createAsyncThunk(
  '/referral/move-referral-commission-to-primary-wallet',
  async ({ values, onSuccess, onFailure }: MoveReferralCommissionToPrimaryWalletArgs, { rejectWithValue }) => {
    try {
      const data: MoveReferralCommissionToPrimaryWalletResponse = await axios.post(
        `/referral/withdraw-commission`,
        values
      );
      if (onSuccess) onSuccess(data);

      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
// -------------------- End Move Referral Commission to Primary Wallet ---------------------- //

// -------------------- Place PND on a User ---------------------- //
export interface AddPNDToUserResponseData {
  data?: {
    status: boolean;
    message: string;
    data: string;
  };
}

export interface AddPNDToUserResponse extends ApiResponse {
  data?: AddPNDToUserResponseData;
  dataList: null;
  meta: null;
}

interface AddPNDToUserArgs extends ReduxAction {
  phoneNumber: string | number | undefined;
  updateReason: string;
}

export const AddPNDToUser = createAsyncThunk(
  'referral/add-pnd-to-user',
  async ({ phoneNumber, updateReason, onSuccess, onFailure }: AddPNDToUserArgs, { rejectWithValue }) => {
    try {
      const data: AddPNDToUserResponse = await axios.put(`referral/update/${phoneNumber}/PND`, { updateReason });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- End Place PND on a User ---------------------- //

// -------------------- Get referred downline detail ---------------------- //
export interface GetReferedDownlineResponseData {
  id: number | string;
  name: string | null;
  email: string | null;
  businessAddress: string | null;
  userId: number | string;
  boltUserId: number | string;
  coreUserId: string | null | undefined;
  phone: string;
  ambassadorLevelTAndCAccepted: boolean;
  partnerLevelTAndCAccepted: boolean;
  referralCode: string | number;
  referralLevel: 'string';
  referralDownLines: Array<ReferralDownlines>;
}

export interface GetReferedDownlineResponse extends ApiResponse {
  data: GetReferedDownlineResponseData;
  dataList: null;
  meta: null;
}

interface GetReferedDownlineArgs extends ReduxAction {
  downlinePhone: string | null | undefined;
}

export const GetReferedDownlineDetail = createAsyncThunk<
  GetReferedDownlineResponse,
  GetReferedDownlineArgs,
  { state: RootState }
>(
  '/referral/downline',
  async ({ downlinePhone, onSuccess, onFailure }: GetReferedDownlineArgs, { rejectWithValue }) => {
    try {
      const data: GetReferedDownlineResponse = await axios.get(`/referral/downline/${downlinePhone}`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- End Get referred downline detail ---------------------- //

// -------------------- Upgrade KYC level on a User ---------------------- //
export interface UpgradeKYCLevelResponseData {
  data?: {
    status: boolean;
    message: string;
    data: string;
  };
}

export interface UpgradeKYCLevelResponse extends ApiResponse {
  data?: UpgradeKYCLevelResponseData;
  dataList: null;
  meta: null;
}

interface UpgradeKYCLevelArgs extends ReduxAction {
  values: {
    idType: string | null | undefined;
    idNumber: string | number | null | undefined;
    idUploadUrl: string | null | undefined;
    nextOfKinDetails: {
      fullName: string | null | undefined;
      phoneNumber: string | null | undefined;
    };
    agentId: string | null | undefined;
  };
}

export const UpgradeKYCLevelForUser = createAsyncThunk(
  '/referral/upgrade-downline-kyc',
  async ({ values, onSuccess, onFailure }: UpgradeKYCLevelArgs, { rejectWithValue }) => {
    try {
      const data: UpgradeKYCLevelResponse = await axios.post(`/referral/upgrade-downline-kyc`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- End Upgrade KYC level on a User ---------------------- //
