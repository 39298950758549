import { Badge, Button, TextInput, Typography } from 'kudi-component-library';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

export const Layout = styled.div`
  width: 100%;
  padding-left: 5.8rem;
  padding-top: 8.2rem;
`;

export const HeaderTopFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};

  @media ${({ theme }) => theme.media.lg} {
    margin-top: 0;
    margin-bottom: 0;
    margin-bottom: 4rem;
  }
`;

export const EmptyTableWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};
  padding: 6rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  svg {
    width: 100px !important;
    height: auto;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
    color: #717171;
  }

  @media ${({ theme }) => theme.media.lg} {
    p {
      font-size: 16px;
      line-height: 24px;
      max-width: 38rem;
    }

    button {
      margin-bottom: 7.5rem;
    }
  }
`;

export const SubProductWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0 0 4rem 0;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const ProductModalTitle = styled(Typography)`
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.media.md} {
    text-align: left;
  }
`;

export const ProductActionWrapper = styled.div`
  display: flex;
  justify-content: end;

  button {
    height: fit-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:first-child {
    margin-right: 10px;
  }
`;

export const ProductModalDescription = styled(Typography)`
  text-align: center;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.media.md} {
    text-align: left;
  }
  @media ${({ theme }) => theme.media.lg} {
    margin-bottom: 2rem;
  }
`;

export const LeftColumnWrapper = styled.div`
  padding-right: 3rem;
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    border-right: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
    width: 65%;
  }
`;

export const RightColumnWrapper = styled.div`
  padding: 0 0 2rem;
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    padding: 0 2rem 2rem;
    width: 35%;
  }
`;

export const FormInputWrapper = styled.div<{ pb?: string; width?: string }>`
  padding-bottom: ${({ pb }) => pb || '2.2rem'};
  width: 100%;
  position: relative;
  input {
    font-family: 'Inter';
  }

  @media ${({ theme }) => theme.media.md} {
    width: ${({ width }) => width || '100%'};
  }
`;

export const SkuTextInput = styled(TextInput)`
  :disabled::placeholder {
    color: ${({ theme }) => theme.nColors['n-grey3']};
    opacity: 1; /* Firefox */
    webkit-text-fill-color: none;
  }

  :disabled:-ms-input-placeholder {
    color: ${({ theme }) => theme.nColors['n-grey3']};
    webkit-text-fill-color: none;
  }

  :disabled::-ms-input-placeholder {
    color: ${({ theme }) => theme.nColors['n-grey3']};
    webkit-text-fill-color: none;
  }
`;

export const FormWrapper = styled.div``;

export const FormInputFlexWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }
`;

export const SelectLabelText = styled.p`
  line-height: 150%;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
`;

export const ImageWrapper = styled.div`
  background-color: #fafafa;
  padding: 4rem;
  border-radius: 5px;

  p {
    font-weight: 500;
    text-align: center;
  }

  @media ${({ theme }) => theme.media.md} {
    padding: 2rem;
  }

  @media ${({ theme }) => theme.media.lg} {
    padding: 5rem 3rem;
  }
`;

export const ImagePlaceholderFrame = styled.div`
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  width: 140px;
  margin: 0 auto;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ImageUploadIconWrapper = styled.label`
  position: absolute;
  right: -10px;
  bottom: -8px;
  cursor: pointer;
`;

export const ImageUploadInput = styled.input`
  display: none;
`;

export const ProductImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  border-radius: 5px;
  min-height: 137px;
`;

export const CoverImage = styled.img`
  height: inherit;
  border-radius: 5px;
  width: 100%;
  max-height: 254px;
  object-fit: fill;
  height: 254px;
`;
export const CoverImageOverlay = styled.div`
  height: inherit;
  border-radius: 5px;
  width: 100%;
  max-height: 254px;
  object-fit: fill;
  height: 254px;
  background: #121212bf;
  position: absolute;
`;

export const ModalUnderLinedText = styled.div<{ top?: number }>`
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.green8};
  margin-top: ${({ top }) => (top ? `${top}px` : '8px')};
  cursor: pointer;
  display: flex;
  justify-content: center;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 180%;
    color: ${({ theme }) => theme.colors.primary.green1};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue1};
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 10px;
  }
`;

export const MenuListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: stretch;
  min-height: 60%;
  gap: 20px;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    flex-wrap: wrap-reverse;
  }
`;

export const MenuListTable = styled.div`
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const MenuListQrSection = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 0.5rem;
  width: 30%;
  max-height: 448px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const SettingsCardSection = styled.div`
  width: 30%;
  max-height: 448px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SettingsCard = styled(Box)`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 0.5rem;
`;

export const MenuListQrCopyButton = styled(Button)`
  color: ${({ theme }) => theme.nColors['n-light']};
  min-width: 184px;
`;

export const MenuGettingStartedTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 50px;
`;
export const MenuGettingStartedImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.nColors['n-grey2']};
  position: relative;
`;
export const MenuGettingStartedTextHeader = styled(Typography)`
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 700;
`;
export const MenuGettingStartedTextBody = styled(Typography)`
  text-align: center;
  margin-bottom: 40px;
`;
export const MenuGettingStartedButton = styled(Button)`
  width: 100%;
  margin: 50px 0;
`;
export const MenuAddImageCoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 700px;
`;
export const MenuAddImageCoverImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.nColors['n-grey1']};
  padding: 27px;
  width: 50%;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const SelectMealsToAddWrapper = styled.div`
  background: ${({ theme }) => theme.nColors['n-grey1']};
  padding: 44px 48px;
`;
export const MenuSettingsButton = styled(Button)`
  background: none;
  color: ${({ theme }) => theme.nColors['n-grey5']};
`;

export const EditMenuButton = styled(Button)`
  background: none;
  span {
    display: flex;
    justify-content: center;
  }
`;
export const CreateNewMealButton = styled(Button)`
  color: ${({ theme }) => theme.nColors['n-light']};
  margin-right: 20px;
`;

export const CancelTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CancelText = styled(Typography)`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  padding-bottom: 5px;
  margin: 20px 0;
  width: fit-content;
`;

export const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const HeaderWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;

  p {
    margin: 20px 0;
  }
`;

export const SubCreateStoreWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0 0 4rem 0;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const LeftStoreColumnWrapper = styled.div`
  padding-right: 3rem;
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    border-right: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
    width: 65%;
  }
`;

export const PageBadgeCounter = styled.span`
  border: solid 1px ${({ theme }) => theme.nColors['n-yellow']};
  border-radius: 100px;
  background: ${({ theme }) => theme.nColors['n-light']};
  display: flex;
  padding: 4px 21px;
  justify-content: center;
  align-items: center;
  width: 130px;
`;
export const CoverUploadedImage = styled.img`
  width: 211px;
  height: 100px;
  border-top-right-radius: 20px;
  position: absolute;
  border-top-left-radius: 20px;
  left: 179px;
  top: 37px;
`;
export const CoverUploadedImageBox = styled(Box)`
  height: 160px;
  position: relative;
`;
export const CoverImageDescriptionBox = styled(Flex)`
  border: dashed 2px ${({ theme }) => theme.nColors['n-grey1']};
  background: ${({ theme }) => theme.nColors['n-grey0']};
  width: 100%;
  border-radius: 5px;
  position: relative;
  min-height: 137px;
`;
export const UploadIconText = styled(Typography)`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.nColors['n-yellow']};
`;

export const MenuModalSubHeader = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.nColors['n-grey4']};
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
`;

export const HeaderBox = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
`;

export const DetailsModalBottomBox = styled(Flex)`
  background: ${({ theme }) => theme.nColors['n-grey0']};
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  position: fixed;
  width: 100%;
  bottom: 0px;
`;

export const ButtonBox = styled(Box)`
  position: fixed;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  left: 0;
  padding: 10px 30px;
  z-index: 2;
`;
const colors = ['#ECF', '#FFF5CC', '#CDF', '#FFE4CC'];

export const WaiterNameCircle = styled(Box)`
  background-color: ${() => colors[Math.floor(Math.random() * colors.length)]};
  padding: 12px 14px;
  border-radius: 100%;
  border: solid 1px white;
`;

export const CategoryDropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const CategorySelectorLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 50px;
  white-space: nowrap;
  outline: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.nColors['n-grey5']};
  padding: 0px 12px;
  border: 1px solid rgb(238, 238, 238);
  &:hover {
    border: 1px solid rgb(198, 198, 198);
    outline-color: rgb(198, 198, 198);
    background: rgb(238, 238, 238);
  }
`;

export const CategoryListContainer = styled.div`
  position: absolute;
  margin-top: 58px;
  border-radius: 4px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  z-index: 2;
  flex-direction: column;
  height: 240px;
  box-shadow: 0px 2px 5px 0px #0000001a;
  background: ${({ theme }) => theme.nColors['n-light']};
`;

export const CategoryItem = styled.div`
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsList = styled.div`
  position: absolute;
  margin-top: 8px;
  right: 0px;
  border-radius: 4px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 5px 0px;
  box-shadow: 0px 2px 5px 0px #0000001a;
  background: ${({ theme }) => theme.nColors['n-light']};
`;

export const ActionItem = styled.div<{ isDelete?: boolean }>`
  display: flex;
  gap: 10px;
  height: 32px;
  padding: 20px;
  align-items: center;
  background: ${({ isDelete }) => (isDelete ? '#FFF8F7' : 'inital')};
  p {
    white-space: nowrap;
  }
  &:hover {
    background: rgb(238, 238, 238);
  }
`;
