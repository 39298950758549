import { useState } from 'react';
import { useSelect } from 'downshift';
import { Typography } from 'kudi-component-library';
import {
  InputWrapper,
  Label,
  DownShiftItem,
  InputItemErrorWrapper,
  DownShiftOptionsWrapper,
  DownShiftOption,
} from '../component';
import ErrorIcon from '../ErrorIcon';
import DropDownIcon from '../DropDownIcon';

interface SelectProps {
  label?: string;
  name: string;
  value: string;
  options: {
    text: string;
    value: string;
  }[];
  onChange: (e: { target: { name: string; value: string | null } }) => void;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  width?: string;
  height?: string;
  prefix?: string;
}

function DropdownSelect({
  label,
  name,
  onChange,
  options,
  disabled,
  error,
  placeholder,
  width,
  value,
  height,
  prefix,
}: SelectProps) {
  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: options.map((item) => item.value),
    onSelectedItemChange: (e) => onChange({ target: { name, value: e.selectedItem || '' } }),
  });

  const [open, setOpen] = useState<boolean>(false);
  const [isFocus, setIsfocus] = useState<boolean>(false);

  return (
    <InputWrapper
      width={width}
      height={height}
      onFocus={() => {
        setOpen(!open);
        setIsfocus(true);
      }}
      disabled={disabled}
      onBlur={() => setIsfocus(false)}
    >
      {label && (
        <Label htmlFor="hello" {...getLabelProps()}>
          {label}
        </Label>
      )}
      <DownShiftItem
        open={isOpen}
        isFocus={isFocus}
        type="button"
        {...getToggleButtonProps()}
        disabled={disabled}
        error={error}
        value={value}
      >
        <div>
          {options.find((item) => item.value === value)?.text ? (
            `${prefix || ''} ${options.find((item) => item.value === value)?.text as string}`
          ) : (
            <Typography fontSize="16px" fontWeight={400} lineHeight="24px" color="grey5">
              {placeholder}
            </Typography>
          )}
        </div>
        <DropDownIcon />
      </DownShiftItem>
      <DownShiftOptionsWrapper open={isOpen} {...getMenuProps()}>
        {isOpen &&
          options.map((item, index) => (
            <DownShiftOption
              style={highlightedIndex === index ? { backgroundColor: '#C6C6C6' } : {}}
              key={`${item.value}${index}`}
              {...getItemProps({ item: item.value, index })}
            >
              {item.text}
            </DownShiftOption>
          ))}
      </DownShiftOptionsWrapper>

      {error ? (
        <InputItemErrorWrapper>
          <ErrorIcon /> <span> {error} </span>
        </InputItemErrorWrapper>
      ) : null}
    </InputWrapper>
  );
}

export default DropdownSelect;
