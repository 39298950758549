import useRedux from '../../../hooks/useRedux';
import { GetAnIssue } from '../reducer/thunk_actions';
import { extractIssueIdFromJSONData } from '../functions';
import { setIssueDetailsCloseAll, toggleShow, updateCurrentPage } from '../reducer/slice';

const useIssueDetailsFromNotification = () => {
  const { dispatch } = useRedux();
  const SelectIssue = (ticketJSON: string) => {
    dispatch(toggleShow());
    dispatch(updateCurrentPage('details'));
    dispatch(setIssueDetailsCloseAll(true));
    const ticketId = extractIssueIdFromJSONData(ticketJSON);
    dispatch(GetAnIssue({ ticketId }));
  };

  return { SelectIssue };
};

export default useIssueDetailsFromNotification;
