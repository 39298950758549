import styled from 'styled-components';
import { NTheme as theme } from 'kudi-component-library';

export const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 2rem;
  background-color: ${theme.nColors['n-yellow1']};
`;

export const NIconWrapper = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.nColors['n-light']};
  border: 1px solid ${theme.nColors['n-yellow']};
`;

export const NIconWrapperv2 = styled.div<{ status: string }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) => {
    switch (status) {
      case 'APPROVED':
        return theme.nColors['n-green'];
      case 'PENDING_APPROVAL':
        return theme.nColors['n-grey4'];
      default:
        return theme.nColors['n-red'];
    }
  }};
`;

export const ArrowSummary = styled.div`
  background-color: ${theme.nColors['n-grey1']};
  padding: 1.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContentFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
`;
export const ContentFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0 2rem 2rem;
`;
