import { useTheme } from 'styled-components';
import { Typography } from 'kudi-component-library';
import Header from '../base/header';
import { IssueCreationWrapper, SupportPageDetailsWrapper } from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import {
  clearTransactionFilters,
  clearTransactionPagination,
  selectIssueCategory,
  selectIssueSubCategory,
  selectIssueType,
  updateCurrentPage,
  updateIssueCreationStage,
} from '../../reducer/slice';
import Steps from '../base/steps';
import Category from './category';
import Type from './sub_type';
import { NoShowOnDesktop, Separator } from '../../../overview/styles';
import Transactions from './transactions';
import { CreationStage } from '../../defs';

const IssueCreation = () => {
  const {
    dispatch,
    state: {
      support: {
        issueCreation: { currentCreationStage, selectedCategory, selectedSubCategory },
      },
    },
  } = useRedux();
  const theme = useTheme();

  const sectionMap: Record<CreationStage, JSX.Element> = {
    category: <Category />,
    type: <Type />,
    transactions: <Transactions />,
  };

  const titleMap: Record<CreationStage, string> = {
    category: 'Select Issue Category',
    type: 'Select Issue type',
    transactions: 'Select affected transaction',
  };

  const resetTransactions = () => {
    dispatch(clearTransactionFilters());
    dispatch(clearTransactionPagination());
    dispatch(selectIssueType(null));
  };

  const handleBack = () => {
    switch (true) {
      case currentCreationStage === 'category':
        dispatch(updateCurrentPage('my-issues'));
        break;

      case currentCreationStage === 'type':
        dispatch(selectIssueSubCategory(null));
        dispatch(selectIssueCategory(null));
        dispatch(updateIssueCreationStage('category'));
        break;

      case currentCreationStage === 'transactions' && selectedSubCategory?.id === selectedCategory?.id:
        resetTransactions();
        dispatch(selectIssueSubCategory(null));
        dispatch(selectIssueCategory(null));
        dispatch(updateIssueCreationStage('category'));
        break;
      case currentCreationStage === 'transactions' && selectedSubCategory?.id !== selectedCategory?.id:
        resetTransactions();
        dispatch(selectIssueSubCategory(null));
        dispatch(updateIssueCreationStage('type'));
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Header
        desktopTitle={titleMap[currentCreationStage]}
        desktopDescription="Select the issue type that most described your issue "
        desktopBackAction={handleBack}
        mobileBackAction={handleBack}
        desktopExtaView={<Steps />}
      />
      <SupportPageDetailsWrapper top="177px" mobileBg={theme.nColors['n-light']} mobilePadding="40px 20px 0">
        <IssueCreationWrapper>
          <NoShowOnDesktop>
            <Steps />
          </NoShowOnDesktop>
          <Separator height="20px" />
          <NoShowOnDesktop>
            <Typography fontSize="20px" lineHeight="30px" fontWeight={400}>
              {titleMap[currentCreationStage]}
            </Typography>
            <Separator height="20px" />
          </NoShowOnDesktop>
          {currentCreationStage ? sectionMap[currentCreationStage] : null}
        </IssueCreationWrapper>
      </SupportPageDetailsWrapper>
    </>
  );
};

export default IssueCreation;
