/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent } from 'react';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import DeviceParser from 'ua-parser-js';
import { deviceId, fromLocationQueryConstant, notifictionId } from './constants';

import {
  LoginUser,
  SignUpInitialValues,
  CreateRegistrationReq,
  LoginOptions,
  IAMObjectType,
  FullUser,
} from '../types/auth';
import {
  signup,
  login,
  LoginResponseData,
  createRegistration,
  CreateRegistrationResponse,
  setDeviceId,
  clearAuth,
  cancelAuthSession,
  setParentBusinessId,
  setOnboardingComplete,
  fetchUser,
  getUserRegistration,
  GetUserRegistrationResponse,
  setIamTokenRole,
} from '../features/auth/slice';

import appConfig from './config';
import { GetReferralByUserID } from '../features/referral/thunkActions';
import { Mixpanel } from '../hooks/useMixpanel';
import { creditTransactionType, TransactionTypes } from './transactionEnums';
import { fetchBusinessDetails } from '../features/business/thunkActions';
import { AppDispatch } from '../app/store';
import { BBErrorType } from '../types/error';
/**
 * Converts a color's hex code to rgba, opacity can be added as second parameter, if opacity isn't supplied it defaults to 1
 * @param hexCode
 * @param opacity
 * @returns rgba string
 */

export const onLoginSuccess = ({
  value,
  dispatch,
  navigate,
  elseMerchantType = true,
  redirect = true,
}: {
  value: LoginResponseData;
  dispatch: Dispatch<any>;
  navigate: NavigateFunction;
  elseMerchantType?: boolean;
  redirect?: boolean;
}) => {
  const userObject: LoginUser = {
    name: value.name,
    role: value.role,
    userId: value.userId,
    parentBusinessId: value.parentBusinessId,
  };

  const IAMObject: IAMObjectType | null | undefined = value.iamTokenData;

  dispatch(setParentBusinessId({ parentBusinessId: userObject.parentBusinessId }));
  dispatch(setIamTokenRole(IAMObject?.role as string));

  localStorage.setItem('appData', JSON.stringify(userObject));
  localStorage.setItem('token', JSON.stringify(value?.payload.token) || '');
  localStorage.setItem('expiresAt', JSON.stringify(value.payload.expiresAt) || '');
  // IAM token
  localStorage.setItem('IAMtoken', JSON.stringify(IAMObject?.token) || '');
  localStorage.setItem('IAMtokenExpiresAt', JSON.stringify(IAMObject?.expiresAt) || '');
  localStorage.setItem('IAMuserRole', JSON.stringify(IAMObject?.role) || '');
  localStorage.setItem('showTour', 'true');
  localStorage.removeItem(fromLocationQueryConstant);

  dispatch(setDeviceId(value?.deviceUniqueId));

  if (value.parentBusinessId !== null && elseMerchantType && redirect) {
    navigate('../../dashboard/overview', { replace: true });
  }

  if (value.parentBusinessId === null && elseMerchantType && redirect) {
    navigate('../merchant-type');
  }

  if (!elseMerchantType && redirect) {
    navigate('../../dashboard/overview', { replace: true });
  }

  // Get referral by user id and save it in local storage
  const onSuccessInner = () => {
    localStorage.setItem('ReferralAccepted', JSON.stringify('true'));
  };
  dispatch(GetReferralByUserID({ id: value.userId, onSuccess: onSuccessInner }));
};

export const storeDeviceId = ({ phoneNumber, email, id }: { phoneNumber: string; email: string; id: string }) => {
  if (!id || id === '') {
    const dataString = localStorage.getItem(deviceId);
    if (dataString) {
      const oldData = JSON.parse(dataString) as Array<string>;
      id = oldData[2] || id;
    }
  }
  const data = JSON.stringify([phoneNumber, email, id]);
  localStorage.setItem(deviceId, data);
};

export const removeDeviceId = () => {
  localStorage.removeItem(deviceId);
};

export const getDeviceInfo = () => {
  const dataString = localStorage.getItem(deviceId);

  if (dataString) {
    const data = JSON.parse(dataString) as [phoneNumber: string, email: string, id: string];

    return data;
  }

  return null;
};

const getIPAddress = async () => {
  try {
    const { data }: { data: { ip?: string; country_name?: string } } = await axios.get('https://ipapi.co/json/');
    return [data?.ip, data?.country_name] || ['unknown', 'unknown'];
  } catch (err) {
    return ['unknown', 'unknown'];
  }
};

const extractDetailsFromUserAgent = () => {
  const ua = DeviceParser(window.navigator.userAgent);

  const data: Partial<LoginOptions> = {
    accessType: 'unknown', // ua.device.type || 'unknown',
    channel: 'WEB',
    os: ua.os.name ? `${ua.os.name}` : 'unknown',
    imei: null,
    deviceName: ua.device.vendor ? `${ua.device.vendor}-${ua.device.model || ''}` : 'unknown',
    browser: ua.browser.name ? `${ua.browser.name}-${ua.browser.version || ''}` : 'unknown',
  };
  return data;
};

export const gatherDeviceInfo = async (deviceUniqueId: string | null) => {
  const deviceDetails = extractDetailsFromUserAgent();
  const retries = 3;

  let ipAddress = 'unknown';
  let ipCountry = 'unknown';

  for (let i = 0; i < retries; i++) {
    const prevIp = await getIPAddress?.();

    if (prevIp?.[0] !== 'unknown') {
      ipAddress = prevIp?.[0] || '';
      ipCountry = prevIp?.[1] || '';
      break;
    }
  }

  const data: LoginOptions = {
    deviceUniqueId,
    accessType: '',
    channel: 'WEB',
    os: '',
    imei: null,
    deviceName: '',
    browser: '',
    ipAddress,
    ipCountry,
    ...deviceDetails,
  };

  return data;
};

export const convertHexToRGBA = (hexCode: string, opacity?: number) => {
  let hex = hexCode.replace('#', '');
  let localOpacity = opacity || 1;

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (localOpacity > 1 && localOpacity <= 100) {
    localOpacity /= 100;
  }

  return `rgba(${r},${g},${b},${localOpacity})`;
};

export const formatAmount = (num: number | string) => {
  const localNumber = typeof num === 'string' ? parseInt(num) : num;

  return Math.sign(localNumber || 0) === -1
    ? `${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        .slice(1)}`
    : `${localNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const uploadToDrake = async (
  files: File[],
  onSuccess: (value: string[]) => void,
  onFailure: (error: BBErrorType) => void
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const List: Function[] = [];

    const upload = (file: File) => async () => {
      const formData = new FormData();
      formData.append('file', file);
      const response: AxiosResponse<FormData, object> = await axios.post(
        // `${appConfig.mediaServiceUrl}/images`,
        `${appConfig.mediaServiceUrl}/media`,
        formData
      );
      return response.data;
    };

    files.map((file) => {
      List.push(upload(file));
      return file;
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const values: {
      id: string;
      uri: string;
    }[] =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      await Promise.all(List.map((item) => item()));
    onSuccess(values.map((item) => item.uri));
  } catch (err) {
    onFailure(err as BBErrorType);
  }
};

export const phoneNumberConvert2 = (phone: string, type: '080') => {
  const numberSegments = phone.split('');

  if (numberSegments[0] === '0') {
    delete numberSegments[0];
  }

  if (type === '080') {
    return `234${numberSegments.join('')}`;
  }
};

export const phoneNumberConvert = (phone: string, type: '+234' | '080') => {
  const numberSegments = phone.split('');

  if (numberSegments[0] === '0') {
    delete numberSegments[0];
  }

  if (
    numberSegments[0] === '2' &&
    numberSegments[1] === '3' &&
    numberSegments[2] === '4' &&
    numberSegments[3] !== '0'
  ) {
    delete numberSegments[0];
    delete numberSegments[1];
    delete numberSegments[2];
  }

  if (
    numberSegments[0] === '2' &&
    numberSegments[1] === '3' &&
    numberSegments[2] === '4' &&
    numberSegments[3] === '0'
  ) {
    delete numberSegments[0];
    delete numberSegments[1];
    delete numberSegments[2];
    delete numberSegments[3];
  }

  if (type === '080') {
    return `0${numberSegments.join('')}`;
  }

  if (type === '+234') {
    return `234${numberSegments.join('')}`;
  }
};

export const formatDate = (date: Date) => dayjs(new Date(date)).format('D MMM YYYY');

export const formatDateWithTime = (date: Date) => {
  return dayjs(new Date(date)).format('D MMM YYYY,  h:mma');
};

export const getParentBusinessId = () => {
  const userObject = localStorage.getItem('appData');
  if (userObject) {
    const parsedUserObject = JSON.parse(userObject) as LoginUser;
    return String(parsedUserObject.parentBusinessId) || '';
  }
  return '';
};

const handleLogin = async ({
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  phoneNumber,
  email,
  password,
  navigate,
  toast,
  dispatch,
  verificationIds,
}: {
  phoneNumber: string;
  email: string;
  password: string;
  navigate: NavigateFunction;
  toast: (type: 'success' | 'error' | 'info', message: string) => void;
  dispatch: Dispatch<any>;
  verificationIds: [string, string];
}) => {
  const onSuccess = (value: LoginResponseData) => {
    onLoginSuccess({ value, navigate, dispatch });
  };

  const onFailure = (error: { data: { message: string } }) => {
    toast('error', error?.data?.message || 'Something went wrong');
  };

  const options = await gatherDeviceInfo(null);

  dispatch(
    login({
      values: {
        username: email,
        password,
        loginMode: 'EMAIL',
      },
      options,
      authInfo: {
        verificationIds,
      },
      onSuccess,
      onFailure,
      deviceFCMToken: localStorage?.getItem(notifictionId) || '',
    })
  );
};

export const handleSignup = (
  { email, password, phoneNumber, ...rest }: SignUpInitialValues,
  setSubmitting: (data: boolean) => void,
  toast: (type: 'success' | 'error' | 'info', message: string) => void,
  dispatch: Dispatch<any>,
  navigate: NavigateFunction,
  verificationIds: [string, string]
) => {
  // checker against "0" being added after 234

  const onSuccess = () => {
    Mixpanel.track('go_to_dashboard');
    handleLogin({ email, password, navigate, dispatch, toast, phoneNumber, verificationIds });
  };

  const onFailure = (error: { data: { message: string } }) => {
    setSubmitting(false);
    toast('error', error?.data?.message || 'Something went wrong');
  };

  dispatch(
    signup({
      values: {
        email,
        password,
        phoneNumber: phoneNumberConvert(phoneNumber, '+234') || '',
        ...rest,
        verificationIds,
      },
      onSuccess,
      onFailure,
    })
  );
};

// verification

const handleAccountRegistration = (
  {
    values,
    userInfo,
    navigate,
    toast,
    dispatch,
    isLogin,
    cb,
  }: {
    values: CreateRegistrationReq;
    userInfo: object;
    navigate: NavigateFunction;
    dispatch: Dispatch<any>;
    toast: (type: 'success' | 'error' | 'info', message: string) => void;
    cb?: () => void;
    isLogin: boolean;
  },
  setSubmitting: (data: boolean) => void
) => {
  const onSuccess = (data: CreateRegistrationResponse) => {
    const OTPInfo: string | number = data?.data?.id || '';

    // localStorage.setItem('OTPInfo', JSON.stringify(OTPInfo));

    if (cb) {
      cb();
    } else {
      navigate('../verify-account', { state: { OTPInfo, userInfo, isLogin } });
    }
  };

  const onFailure = (error: { data: { message: string } }) => {
    toast('error', error?.data?.message || 'Something went wrong');
    setSubmitting(false);
  };

  dispatch(createRegistration({ values, onFailure, onSuccess }));
};

export const handlGetUserRegistration = (
  {
    values,
    navigate,
    dispatch,
    userInfo,
    toast,
    isLogin,
    cb,
  }: {
    values: CreateRegistrationReq;
    navigate: NavigateFunction;
    userInfo: object;
    dispatch: Dispatch<any>;
    toast: (type: 'success' | 'error' | 'info', message: string) => void;
    isLogin: boolean;
    cb?: () => void;
  },
  setSubmitting: (data: boolean) => void
) => {
  const onSuccess = (data: GetUserRegistrationResponse) => {
    navigate('/auth/verify-account', {
      state: {
        OTPInfo: data.data?.id,
        userInfo,
        isLogin,
      },
    });
  };
  const onFailure = () => {
    handleAccountRegistration(
      {
        values,
        userInfo,
        navigate,
        dispatch,
        toast,
        cb,
        isLogin,
      },
      setSubmitting
    );
  };

  dispatch(getUserRegistration({ params: { email: values.email, phone: values.phoneNumber }, onSuccess, onFailure }));
};

export const formatTableDateValue = (date: Date) => dayjs(new Date(date)).format('MMM DD, YYYY  hh:mm a');

export const formatTableDateOnlyValue = (date: Date | string) => dayjs(new Date(date)).format('MMM DD, YYYY');

export const formatTableDateToOnly = (date: Date) => dayjs(new Date(date)).format('DD MMM, YYYY');

export const customFilterDateFormatter = (date: Date | string) => dayjs(new Date(date)).format('YYYY-MM-DDTHH:mm:ss');

export const validateEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(emailRegex)) {
    return true;
  }
  return false;
};

const getMenuPopupStateStoredInLocalStorage = () => {
  const triedMenuState = localStorage.getItem('triedMenu');
  const lastShownTimestampState = localStorage.getItem('lastShownTimestamp');

  return { lastShownTimestampState, triedMenuState };
};

const getNotificationInfo = () => {
  return localStorage.getItem(notifictionId);
};

export const clearLocalStorage = () => {
  const deviceInfo = getDeviceInfo();
  const menuInfo = getMenuPopupStateStoredInLocalStorage();
  const notificationInfo = getNotificationInfo();
  localStorage.clear();
  if (deviceInfo) {
    storeDeviceId({ phoneNumber: deviceInfo[0], email: deviceInfo[1], id: deviceInfo[2] });
  }

  // Keeps back data for menu popup
  if (menuInfo.lastShownTimestampState) {
    localStorage.setItem('lastShownTimestamp', menuInfo.lastShownTimestampState);
  }
  if (menuInfo.triedMenuState) {
    localStorage.setItem('triedMenu', menuInfo.triedMenuState);
  }

  if (notificationInfo) {
    localStorage.setItem(notifictionId, notificationInfo);
  }
};

export const logout = ({
  dispatch,
  navigate,
  userId,
  timedOut = false,
}: {
  dispatch: AppDispatch;
  navigate: NavigateFunction;
  userId: string;
  timedOut?: boolean;
}) => {
  const token = localStorage.getItem('token');
  const handleContinue = () => {
    if (timedOut) {
      navigate('/auth/timed-out');
    } else {
      navigate('/auth/login', { replace: true });
    }
    clearLocalStorage();
    dispatch(clearAuth());
  };

  dispatch(cancelAuthSession({ userId, onSuccess: handleContinue, onFailure: handleContinue, token }));
};

export const capitalizeFirstLetter = (value: string) => {
  const lowercaseWord = value.toLowerCase();

  return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
};

export const removeUnderscores = (text: string) => {
  return text.replace(/_/g, ' ');
};

export const downloadCSV = (data: any, custonName: string, extraInformation?: string | Date | number) => {
  const link = document.createElement('a');
  link.href = `data:text/csv;charset=utf-8,${escape(data)}`;
  if (extraInformation) {
    link.setAttribute('download', `${custonName}${extraInformation}.csv`);
  } else {
    link.setAttribute('download', `${custonName}.csv`);
  }
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

export const generateBusinessLogo = (businessId: string | number | null) => {
  if (businessId) {
    return `${appConfig.merchantAcqServiceUrl}/webbe/v1/media/profile-image/${businessId}`;
  }
  return '';
};

export const transformTransactionSummaryByType = (transactionType: string) => {
  const transactionTypeKeys: string[] = Object.keys(TransactionTypes);
  if (transactionTypeKeys.includes(transactionType)) {
    if (creditTransactionType.includes(transactionType.toLowerCase())) {
      return 'Inward payment from';
    }
    return 'Outward payment to';
  }
  return `${capitalizeFirstLetter(transactionType)} |`;
};

export const handleNumberInputValidation = (event: KeyboardEvent<HTMLInputElement>, type: string) => {
  if (type === 'number') {
    if (event.key !== 'Backspace') {
      if (!/^\d*(\.)?(\d{0,2})?$/.test(event.key)) {
        event.preventDefault();
      }
    }
  }
};

export const generateInvoiceDownloadLink = (id: string) => {
  return `${appConfig.merchantAcqServiceUrl}/webbe/v1/invoice/download/${id}`;
};
export const isTransfersComplete = () => {
  return false;
};

export const getInitials = (name: string) => {
  if (name === '') return '';
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const handleFetchUser = ({
  fullUser,
  dispatch,
  navigate,
  onboardingv2 = false,
}: {
  fullUser: FullUser | null | undefined;
  dispatch: Dispatch<any>;
  navigate: NavigateFunction;
  toast?: (type: 'success' | 'error' | 'info', message: string) => void;
  onboardingv2?: boolean;
}) => {
  const onFailure = () => {
    logout({ dispatch, navigate, userId: String(fullUser?.id) });
  };

  if (fullUser) return;
  const appData = localStorage.getItem('appData');
  if (!appData) return;

  const parsedAppData = JSON.parse(appData) as LoginUser;

  const onSuccess = (data: FullUser) => {
    dispatch(
      setParentBusinessId({
        parentBusinessId:
          parsedAppData?.parentBusinessId !== null ? parsedAppData?.parentBusinessId : (data?.parentBusiness as string),
      })
    );

    if (!onboardingv2) {
      const completedOnboarding = data?.connectedBusinesses?.[0]?.onboardingCompleteV1;
      dispatch(setOnboardingComplete(completedOnboarding));
    }
  };

  dispatch(fetchUser({ onFailure, onSuccess, values: { id: parsedAppData.userId } }));
};

export const handleFetchBusiness = ({ dispatch, onFailure }: { dispatch: Dispatch<any>; onFailure: () => void }) => {
  dispatch(
    fetchBusinessDetails({
      onFailure,
    })
  );
};

export const getUserBrowser = () => {
  const ua = DeviceParser(window.navigator.userAgent);

  return ua.browser.name ? `${ua.browser.name}-${ua.browser.version || ''}` : 'unknown';
};

export const getDeviceInfoString = () => {
  const data = extractDetailsFromUserAgent();

  return `${data.channel}:${data.os}:${data.deviceName}:${data.accessType}`;
};

export const truncate = (val: string) => (val?.length > 21 ? `${val?.substring(0, 18)}...` : val);

export const formatNumWithCommas = (numberValue: string) => {
  if (!numberValue) return '';
  let str = parseFloat(numberValue).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });
  str = str.replace(/\./, '.');
  str = str.replace(/,/g, ',');
  return str;
};

export * from './plainFunctions';

export const bytesToSize = ({ bytes, decimals = 2 }: { bytes: number; decimals?: number }) => {
  if (!Number(bytes)) {
    return '0 Bytes';
  }
  const kbToBytes = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));

  return `${parseFloat((bytes / kbToBytes ** index).toFixed(dm))} ${sizes[index]}`;
};

export const convertToInternationalCurrencySystem = (labelValue: number) => {
  if (Math.abs(Number(labelValue)) >= 1.0e9) {
    return `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(1)}B`;
  }

  if (Math.abs(Number(labelValue)) >= 1.0e6) {
    return `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(2)}M`;
  }

  if (Math.abs(Number(labelValue)) >= 1.0e3) {
    return `${Math.abs(Number(labelValue)) / 1.0e3}K`;
  }

  return Math.abs(Number(labelValue));
};

export const removeUnderScores = (text: string) => {
  return text.replace(/_/g, ' ');
};

export const concealText = (string: string) => {
  if (!string) return '';
  if (string.length < 5) return string;

  const concealLength = Math.floor(string.length / 5);

  return [...string].map((item, index) => (index > concealLength && index < concealLength * 4 ? '*' : item)).join('');
};
