import React, { useRef, RefObject } from 'react';
import { SearchWrapper, ClearIconWrapper } from './style';
import { SearchIcon, CloseIcon } from '../../assets/svg';

interface SearchProps {
  id: string;
  onSearch: (val: string) => void;
  placeholder: string;
  type: string;
  length?: number;
  query?: string;
  onRemove?: (value: string) => void;
  extInputRef?: RefObject<HTMLInputElement>;
}
interface FormElements extends HTMLFormControlsCollection {
  searchInput: HTMLInputElement;
}
interface SearchFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}
const SearchInput = ({ id, onSearch, placeholder, type, length, query, onRemove, extInputRef }: SearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSearch = (event: React.FormEvent<SearchFormElement>) => {
    event.preventDefault();
    onSearch(event.currentTarget.elements.searchInput.value);
  };
  const handleClearInput = (ref: RefObject<HTMLInputElement>) => {
    if (ref?.current?.value) {
      ref.current.value = '';
      if (onRemove) onRemove?.(query as string);
    }
  };
  return (
    <SearchWrapper id={id} onSubmit={handleSearch} role="searchbox" name="searchInputContainer">
      <SearchIcon />
      <input ref={extInputRef ?? inputRef} id="searchInput" placeholder={placeholder} type={type} maxLength={length} />
      <ClearIconWrapper>
        <CloseIcon onClick={() => handleClearInput(extInputRef ?? inputRef)} />
      </ClearIconWrapper>
    </SearchWrapper>
  );
};
export default SearchInput;
