import dayjs from 'dayjs';
import { useState } from 'react';
import * as Yup from 'yup';
import { useAppSelector } from '../../../../app/hooks';
import { useToast } from '../../../../hooks/useToast';
import downloadSettlementReport from '../../../../queries/banking/settlements/downloadSettlementReport';
import { DownloadReportValues } from '../../../../types/banking/settlements';
import { WebBeError } from '../../../../types/error';
import { downloadCSV } from '../../../../utils';
import { TOAST_TIMER } from '../../../../utils/constants';
import pollDownloadCsv from './poll-download-csv';

const initialValues: DownloadReportValues = {
  startDate: dayjs().subtract(1, 'month').format('MM/DD/YYYY'),
  endDate: dayjs().format('MM/DD/YYYY'),
};

const validationSchema = Yup.object({
  startDate: Yup.string().required('Start date is required'),
  endDate: Yup.string().required('End date is required'),
});

interface DownloadParams {
  settlementId?: string;
  startDate?: string;
  endDate?: string;
  outletId?: string;
}

const useDownLoadReportModel = ({ outletId }: { outletId?: string }) => {
  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);

  const { parentBusinessId } = useAppSelector((state) => state.auth);
  const toast = useToast(TOAST_TIMER);

  const handleCloseDownloadReportModal = () => setShowDownloadReportModal(false);

  const handleShowDownloadReportModal = () => setShowDownloadReportModal(true);

  const handleDownloadReport = ({
    settlementId,
    values,
    setSubmitting,
  }: {
    settlementId?: string;
    values?: DownloadReportValues;
    setSubmitting?: (isSubmitting: boolean) => void;
  }) => {
    const businessId = parentBusinessId as number;
    async function downloadReport() {
      let downloadParams: DownloadParams = { outletId };
      if (settlementId) downloadParams = { ...downloadParams, settlementId };
      if (values)
        downloadParams = {
          ...downloadParams,
          startDate: dayjs(values.startDate).startOf('day').format('YYYY-MM-DD'),
          endDate: dayjs(values.endDate).endOf('day').format('YYYY-MM-DD'),
        };
      if (setSubmitting) setSubmitting(true);
      const { result, error } = await downloadSettlementReport(downloadParams);
      if (setSubmitting) setSubmitting(false);
      if (result?.data.downloadId) {
        toast('info', 'Your report is downloading and will be available in a short while');
        const { response } = await pollDownloadCsv({ downloadId: result.data.downloadId });
        if (response) {
          downloadCSV(response, 'Settlements_Report');
        }
      }
      if (error)
        toast(
          'error',
          ((error as WebBeError)?.data.message as string) ||
            'An error occured while trying to download settlement report'
        );
    }

    downloadReport();
  };

  return {
    showDownloadReportModal,
    handleCloseDownloadReportModal,
    initialValues,
    validationSchema,
    handleShowDownloadReportModal,
    handleDownloadReport,
  };
};

export default useDownLoadReportModel;
