/**
 * @fileoverview gRPC-Web generated client stub for com.nomba.business_banking.qrcodeservice
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: src/proto/qr.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.com = {};
proto.com.nomba = {};
proto.com.nomba.business_banking = {};
proto.com.nomba.business_banking.qrcodeservice = require('./qr_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_LinkQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/LinkQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.linkQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/LinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_LinkQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.linkQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/LinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_LinkQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_QueryQrCodeTransactionStatus = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/QueryQrCodeTransactionStatus',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest,
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.queryQrCodeTransactionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/QueryQrCodeTransactionStatus',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_QueryQrCodeTransactionStatus,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.queryQrCodeTransactionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/QueryQrCodeTransactionStatus',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_QueryQrCodeTransactionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_ProcessQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/ProcessQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest,
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.processQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/ProcessQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_ProcessQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.processQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/ProcessQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_ProcessQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_InitStaticQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/InitStaticQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.initStaticQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/InitStaticQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_InitStaticQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.initStaticQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/InitStaticQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_InitStaticQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_InitDynamicQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/InitDynamicQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.initDynamicQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/InitDynamicQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_InitDynamicQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.initDynamicQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/InitDynamicQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_InitDynamicQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_LookUpWithQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/LookUpWithQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.lookUpWithQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/LookUpWithQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_LookUpWithQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.lookUpWithQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/LookUpWithQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_LookUpWithQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_CreateDynamicQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/CreateDynamicQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.createDynamicQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/CreateDynamicQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_CreateDynamicQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.createDynamicQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/CreateDynamicQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_CreateDynamicQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_SignQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/SignQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.signQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/SignQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_SignQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.signQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/SignQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_SignQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_GenerateQrCodes = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/GenerateQrCodes',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest,
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.generateQrCodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/GenerateQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_GenerateQrCodes,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.generateQrCodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/GenerateQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_GenerateQrCodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_FetchQrCodes = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/FetchQrCodes',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.fetchQrCodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/FetchQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_FetchQrCodes,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.fetchQrCodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/FetchQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_FetchQrCodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_FetchQrCodesTransactions = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/FetchQrCodesTransactions',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.fetchQrCodesTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/FetchQrCodesTransactions',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_FetchQrCodesTransactions);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.fetchQrCodesTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/FetchQrCodesTransactions',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_FetchQrCodesTransactions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_GetVirtualQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/GetVirtualQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.getVirtualQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/GetVirtualQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_GetVirtualQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.getVirtualQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/GetVirtualQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_GetVirtualQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse>}
 */
const methodDescriptor_QrCodeServiceExternal_UnLinkQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/UnLinkQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest,
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalClient.prototype.unLinkQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/UnLinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_UnLinkQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceExternalPromiseClient.prototype.unLinkQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceExternal/UnLinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceExternal_UnLinkQrCode);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_LinkQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/LinkQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.linkQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/LinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_LinkQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.linkQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/LinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_LinkQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_QueryQrCodeTransactionStatus = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/QueryQrCodeTransactionStatus',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest,
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.queryQrCodeTransactionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/QueryQrCodeTransactionStatus',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_QueryQrCodeTransactionStatus,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.queryQrCodeTransactionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/QueryQrCodeTransactionStatus',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_QueryQrCodeTransactionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_ProcessQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/ProcessQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest,
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.processQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/ProcessQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_ProcessQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.processQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/ProcessQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_ProcessQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_InitStaticQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/InitStaticQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest,
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.initStaticQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/InitStaticQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_InitStaticQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.initStaticQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/InitStaticQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_InitStaticQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_InitDynamicQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/InitDynamicQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest,
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.initDynamicQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/InitDynamicQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_InitDynamicQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.initDynamicQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/InitDynamicQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_InitDynamicQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_LookUpWithQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/LookUpWithQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.lookUpWithQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/LookUpWithQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_LookUpWithQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.lookUpWithQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/LookUpWithQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_LookUpWithQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_CreateDynamicQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/CreateDynamicQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest,
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.createDynamicQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/CreateDynamicQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_CreateDynamicQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.createDynamicQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/CreateDynamicQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_CreateDynamicQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_SignQrCodeTransaction = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/SignQrCodeTransaction',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest,
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.signQrCodeTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/SignQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_SignQrCodeTransaction,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.signQrCodeTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/SignQrCodeTransaction',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_SignQrCodeTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_GenerateQrCodes = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/GenerateQrCodes',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest,
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.generateQrCodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/GenerateQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_GenerateQrCodes,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.generateQrCodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/GenerateQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_GenerateQrCodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_FetchQrCodes = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/FetchQrCodes',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest,
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.fetchQrCodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/FetchQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_FetchQrCodes,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.fetchQrCodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/FetchQrCodes',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_FetchQrCodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_FetchQrCodesTransactions = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/FetchQrCodesTransactions',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest,
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.fetchQrCodesTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/FetchQrCodesTransactions',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_FetchQrCodesTransactions);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.fetchQrCodesTransactions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/FetchQrCodesTransactions',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_FetchQrCodesTransactions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_GetVirtualQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/GetVirtualQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest,
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.getVirtualQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/GetVirtualQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_GetVirtualQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.getVirtualQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/GetVirtualQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_GetVirtualQrCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest,
 *   !proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse>}
 */
const methodDescriptor_QrCodeServiceInternal_UnLinkQrCode = new grpc.web.MethodDescriptor(
  '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/UnLinkQrCode',
  grpc.web.MethodType.UNARY,
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest,
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse,
  /**
   * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.deserializeBinary
);


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalClient.prototype.unLinkQrCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/UnLinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_UnLinkQrCode,
      callback);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse>}
 *     Promise that resolves to the response
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeServiceInternalPromiseClient.prototype.unLinkQrCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.nomba.business_banking.qrcodeservice.QrCodeServiceInternal/UnLinkQrCode',
      request,
      metadata || {},
      methodDescriptor_QrCodeServiceInternal_UnLinkQrCode);
};


module.exports = proto.com.nomba.business_banking.qrcodeservice;

