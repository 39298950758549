import styled from 'styled-components';
// import { convertHexToRGBA } from '../../../utils';

export const Layout = styled.div`
  height: 100svh;
  width: 100%;

  @media ${({ theme }) => theme.media.lg} {
    height: 100vh;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 0 2rem;

  @media ${({ theme }) => theme.media.lg} {
    padding: 2rem;
    border-bottom: 1px solid #eeeeee;
  }
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  svg {
    transform: scale(0.8);
  }

  @media ${({ theme }) => theme.media.lg} {
    svg {
      transform: scale(1);
    }
  }
`;

export const TopRigtSmWrapper = styled.div`
  height: fit-content;
  display: flex;

  @media ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const TopRightWrapper = styled.div`
  display: none;
  height: fit-content;

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
  }
`;
