import { ReactNode } from 'react';
import styled from 'styled-components';

const FormHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
  font: inherit;
  p.title {
    color: #1a1a1a;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.8px;
  }
  p.description {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    span {
      color: #4d4d4d;
      font-weight: 500;
    }
  }
  @media ${({ theme }) => theme.media.md} {
    margin-bottom: 20px;
  }
`;

const FormHeader = ({ title, description }: { title: string; description: string | ReactNode }) => {
  return (
    <FormHeaderWrapper>
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </FormHeaderWrapper>
  );
};

export default FormHeader;
