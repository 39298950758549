import { Settlement } from '../../../types/banking/settlements';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface SettlementsResponse extends ApiResponse {
  data: {
    list: Settlement[];
    pageToken: string;
    previous: string;
  };
}

const fetchSettlements = async ({
  businessId,
  params,
  outletId,
}: {
  businessId: number | string;
  params?: URLSearchParams;
  outletId?: string;
}) => {
  const url = outletId
    ? `/webbe/v1/accounts/outlet/settlement-list?${params ? params.toString() : ''}`
    : `/webbe/v1/autosettlement/settlement-list?${params ? params.toString() : ''}`;
  try {
    const result: SettlementsResponse = await axios.get(url, {
      headers: {
        businessId,
        ...(outletId && { subAccountId: outletId }),
      },
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchSettlements;
