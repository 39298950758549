import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchDashboardSummary,
  fetchAllBanks,
  accountLookUp,
  bvnVerification,
  paymentSetup,
  paymentMethodUpdate,
  onbaordingWrapUp,
  bvnVerificationMigrated,
  settlementAcountSetup,
  settlementAcountPINSetup,
} from './thunkActions';
import { DashboardSummary, BasicBusinessInformation, BusinessDetails, Bank, PaymentSetup } from '../../types/dashboard';
import { Store as InventoryStore } from '../../types/inventory/store';

interface InitialState {
  isLoading: boolean;
  inProgress: boolean;
  summary?: DashboardSummary | undefined | null;
  bankList: Bank[];
  paymentSetup: PaymentSetup;
  basicBusinessInformation: BasicBusinessInformation;
  businessDetails: BusinessDetails;
  bvnVerified: 'active' | 'pending' | 'error';
  guestMenuOption?: string;
  inventoryStore?: InventoryStore;
}

const paymentSetupInitialState: PaymentSetup = {
  businessPaymentOptions: '',
  transactionPin: '',
  bankDetails: {
    bankInfo: {
      name: '',
      code: '',
    },
    accountNumber: '',
    accountName: '',
  },
};

const basicBusinessInformationInitialState: BasicBusinessInformation = {
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  dateOfBirth: '',
  bvn: '',
  idType: 'Unknown',
  idDocumentUrl: '',
  directors: [],
  profilePictureUrl: '',
};

const businessDetailsInitialState: BusinessDetails = {
  businessName: '',
  businessCategory: '',
  businessType: '',
  businessAddress: {
    areaInfo: '',
    state: '',
    city: '',
    lga: '',
  },
  taxIdNumber: '',
  memorandumDocUrl: '',
  proofOfAddressDocUrl: '',
  certOfIncorporationDocUrl: '',
  shareHoldingDocUrl: '',
  cacStatusReportDocUrl: '',
  businessLicenseDocUrls: '',
  governmentContractDocUrls: '',
  cooperativeLicenseDocUrl: '',
  constitutionDocUrl: '',
  listOfExecutivesUrl: '',
  listOfMembersUrl: '',
};

const inventoryStoreInitialState: InventoryStore = {
  storeId: '',
  storeName: '',
  description: '',
  storeLocation: '',
  businessId: '',
  taxOnOrder: 0,
  discounts: [],
  categories: [],
};

const initialState: InitialState = {
  isLoading: false,
  inProgress: false,
  summary: {
    commissionBalance: 0,
    ledgerBalance: null,
    pendingTransaction: null,
    availableBalance: null,
    trxValue: 0,
    trxVolume: 0,
    recentTransactions: [],
    grossTotal: {
      transfers: 0,
      card: 0,
    },
    monthlyGross: {},
  },
  bankList: [],
  paymentSetup: paymentSetupInitialState,
  basicBusinessInformation: basicBusinessInformationInitialState,
  businessDetails: businessDetailsInitialState,
  bvnVerified: 'pending',
  guestMenuOption: '',
  inventoryStore: inventoryStoreInitialState,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetSetup: (state) => {
      state.paymentSetup = paymentSetupInitialState;
      state.basicBusinessInformation = basicBusinessInformationInitialState;
      state.businessDetails = businessDetailsInitialState;
    },
    setBusinessPaymentOptions: (state, action: PayloadAction<string>) => {
      state.paymentSetup = { ...state.paymentSetup, businessPaymentOptions: action.payload };
    },
    setTransactionPin: (state, action: PayloadAction<string>) => {
      state.paymentSetup = { ...state.paymentSetup, transactionPin: action.payload };
    },
    setBankDetails: (
      state,
      action: PayloadAction<{
        bankDetails: {
          bankInfo: {
            name: string;
            code: string;
          };
          accountNumber: string;
          accountName: string;
        };
      }>
    ) => {
      state.paymentSetup = { ...state.paymentSetup, bankDetails: action.payload.bankDetails };
    },
    prefillBasicBusinessInfo: (state, action: PayloadAction<Partial<BasicBusinessInformation>>) => {
      state.basicBusinessInformation = { ...state.basicBusinessInformation, ...action.payload };
    },
    setBasicBusinessInfo: (state, action: PayloadAction<BasicBusinessInformation>) => {
      state.basicBusinessInformation = action.payload;
    },
    prefillBusinessDetails: (state, action: PayloadAction<Partial<BusinessDetails>>) => {
      state.businessDetails = { ...state.businessDetails, ...action.payload };
    },
    setBusinessDetails: (state, action: PayloadAction<Partial<BusinessDetails>>) => {
      state.businessDetails = { ...state.businessDetails, ...action.payload };
    },
    setBvnVerified: (state, action: PayloadAction<'active' | 'pending' | 'error'>) => {
      state.bvnVerified = action.payload;
    },
    setGuestMenuOption: (state, action: PayloadAction<string>) => {
      state.guestMenuOption = action.payload;
    },
    setInventoryStore: (state, action: PayloadAction<InventoryStore>) => {
      state.inventoryStore = action.payload;
    },
  },
  extraReducers: (builder) => {
    //   fetch dashboard summary
    builder
      .addCase(fetchDashboardSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDashboardSummary.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchDashboardSummary.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.summary = payload;
      });

    // fetch all banks
    builder
      .addCase(fetchAllBanks.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(fetchAllBanks.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(fetchAllBanks.fulfilled, (state, action) => {
        state.inProgress = false;
        state.bankList = action?.payload || [];
      });

    // account look up

    builder
      .addCase(accountLookUp.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(accountLookUp.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(accountLookUp.fulfilled, (state) => {
        state.inProgress = false;
      });

    // set up cash out account

    builder
      .addCase(paymentSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paymentSetup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(paymentSetup.fulfilled, (state) => {
        state.isLoading = false;
      });

    // update cash outv account

    builder
      .addCase(paymentMethodUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(paymentMethodUpdate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(paymentMethodUpdate.fulfilled, (state) => {
        state.isLoading = false;
      });

    // bvn vrification
    builder
      .addCase(bvnVerification.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(bvnVerification.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(bvnVerification.fulfilled, (state) => {
        state.inProgress = false;
      });

    // bvn verification migrated users
    builder
      .addCase(bvnVerificationMigrated.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(bvnVerificationMigrated.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(bvnVerificationMigrated.fulfilled, (state) => {
        state.inProgress = false;
      });

    // onboarding 2 vrification
    builder
      .addCase(onbaordingWrapUp.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(onbaordingWrapUp.rejected, (state) => {
        state.inProgress = false;
      })
      .addCase(onbaordingWrapUp.fulfilled, (state) => {
        state.inProgress = false;
      });

    // Add Settlement account V2
    builder
      .addCase(settlementAcountSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(settlementAcountSetup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(settlementAcountSetup.fulfilled, (state) => {
        state.isLoading = false;
      });

    // Add Settlement PIN V2
    builder
      .addCase(settlementAcountPINSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(settlementAcountPINSetup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(settlementAcountPINSetup.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resetSetup,
  setBusinessPaymentOptions,
  setTransactionPin,
  setBankDetails,
  setBasicBusinessInfo,
  setBusinessDetails,
  prefillBasicBusinessInfo,
  prefillBusinessDetails,
  setBvnVerified,
  setGuestMenuOption,
  setInventoryStore,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
export * from './thunkActions';
