import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CaretLeft } from '../../assets/svg';
import { Mixpanel } from '../../hooks/useMixpanel';

import { NavigationOptionInterface } from '../../types/navigation';

import {
  NavigationOptionHolder,
  NavigationOptionContent,
  NavigationOptionName,
  NavigationOptionCaretHolder,
  NavigationSubSectionHolder,
} from './components';
import NavigationSubRoute from './navigationSubRoute';

import { useNavigationContext } from './data';
import { useAppSelector } from '../../app/hooks';
import { getUserBrowser } from '../../utils';
import config from '../../utils/config';

// baseRoute

interface NavigationOptionProps extends NavigationOptionInterface {
  baseRoute: string;
}

const NavigationOption = ({ name, route, subRoute, Icon, baseRoute }: NavigationOptionProps) => {
  const navigate = useNavigate();
  const match = useLocation();

  const [show, setShow] = useState<boolean>(false);

  const { setOpen } = useNavigationContext();

  const { fullUser } = useAppSelector((state) => state.auth);

  const base = match.pathname.split('/')[1];
  const prevPath = `/${base}/${baseRoute}/${route}`;

  const handleClick = () => {
    if (route === 'referral') {
      Mixpanel.track('Referral Dashboard', {
        'Platform Used': 'WEB',
      });
    }

    if (route === 'menu') {
      Mixpanel.track('MENU_CLICKED_FROM_DASHBOARD_SIDEBAR', {
        userBrowser: getUserBrowser(),
        userId: fullUser?.id as string,
        name: `${fullUser?.firstName || ' '} ${fullUser?.lastName || ' '}`,
        phoneNumber: fullUser?.phoneNumber || '',
        email: fullUser?.email || '',
      });
    }

    if (route === 'externalInventory') {
      window.open(config.thankUCashUrl, '_blank');
      return;
    }

    if (route === 'outlets') Mixpanel.track('Outlets Side Nav Click');

    if (subRoute) {
      setShow(!show);
      return;
    }
    navigate(prevPath);
    setOpen(false);
  };

  const baseRouteCheck = match.pathname.split('/')[2];
  const routeCheck = match.pathname.split('/')[3];

  const active: boolean = baseRouteCheck === baseRoute && routeCheck === route;

  return (
    <>
      <NavigationOptionHolder id="navigation-content-options-wrapper" active={active} onClick={handleClick}>
        <NavigationOptionContent id="navigation-content-options-main" active={active}>
          <Icon id="navigation-content-options-main-icon" />
          <NavigationOptionName id="navigation-content-options-main-name" active={active}>
            {name}
          </NavigationOptionName>
          {/* {extra ? <NavigationFooterExtra>{extra}</NavigationFooterExtra> : null} */}
        </NavigationOptionContent>
        {subRoute ? (
          <NavigationOptionCaretHolder id="navigation-content-options-main-caret" active={active} show={show}>
            <CaretLeft id="navigation-content-options-main-caret-icon" />
          </NavigationOptionCaretHolder>
        ) : null}
      </NavigationOptionHolder>
      {subRoute && show ? (
        <NavigationSubSectionHolder id="navigation-content-options-sub-wrapper">
          {subRoute.map((item, index) => (
            <NavigationSubRoute {...item} active={active} prevPath={prevPath} key={`sub-route-${index}`} />
          ))}
        </NavigationSubSectionHolder>
      ) : null}
    </>
  );
};
export default NavigationOption;
