import { CheckMark } from '../../../assets/svg';
import { CopiedIconWrapper } from '../styles';

export default function Copied() {
  return (
    <CopiedIconWrapper>
      <CheckMark className="copied" />
    </CopiedIconWrapper>
  );
}
