/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../../hooks/useToast';
import { HandleCreateNewProduct, NewProduct, Product } from '../../../../types/inventory/products';
import createStorehouseProduct from '../../../../queries/inventory/products/createProduct';
import updateStorehouseProduct from '../../../../queries/inventory/products/updateProduct';
import useAddNewMenuCategories from './useAddNewMenuCategories';
import useAddNewMenuDiscounts from './useAddNewMenuDiscount';
import { getUserBrowser, uploadToDrake } from '../../../../utils';
import { Mixpanel } from '../../../../hooks/useMixpanel';
import { useAppSelector } from '../../../../app/hooks';
import { Discount } from '../../../../types/inventory/discounts';
import { CreateMenuItems, CreateMenuResponse, MenuListResponse } from '../../../../types/menu/menuList';
import createMenuStore from '../../../../queries/menu/menu-list/createMenuStore';

const newMenuValues: NewProduct = {
  name: '',
  description: '',
  categoryId: '',
  discountId: '',
  price: '',
  quantity: '1',
  storeId: '',
  sku: '',
  imageUrl: '',
  autoGenerateSKU: true,
  depletable: false,
  enabled: true,
  published: true,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  price: Yup.number().required('Price is required'),
});

const useAddNewMenuItem = ({
  productBody,
  createMenuListItem,
  handleCreateMenu,
}: {
  productBody: MenuListResponse | undefined;
  createMenuListItem: ({ menuId, itemId }: { menuId: string; itemId: number[] }) => Promise<void>;
  handleCreateMenu: (
    product: CreateMenuItems,
    createMenuImageUrl: string
  ) => Promise<CreateMenuResponse | null | undefined>;
}) => {
  const { categories, handleRefetchCategories } = useAddNewMenuCategories();
  const { discounts, handleRefetchDiscounts } = useAddNewMenuDiscounts();
  const toast = useToast(3000);
  const { fullUser } = useAppSelector((state) => state.auth);

  const [showAddNewProductsModal, setShowAddNewProductsModal] = useState<boolean>(false);
  const [isLoadingAddMeal, setIsLoadingAddMeal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [showAddMenuItemSuccessModal, setShowAddMenuItemSuccessModal] = useState<boolean>(false);
  const [productValues, setProductValues] = useState<NewProduct>(newMenuValues);

  const handleCloseAddNewProductsModal = () => {
    setProductValues(newMenuValues);
    setShowAddNewProductsModal(false);
  };

  const handleOpenAddNewProductsModal = () => setShowAddNewProductsModal(true);

  const getDiscountId = (newMealdiscounts: Discount[]) => {
    const filteredDiscount = newMealdiscounts?.filter((item) => item.discountPercent === 0);
    return filteredDiscount[0]?.id;
  };

  const handleCreateMenuItem: HandleCreateNewProduct = async (values, setSubmitting, imageCreateUrl, newMenu) => {
    setIsLoadingAddMeal(true);
    const { id, ...formValues } = values;
    setSubmitting(false);
    handleRefetchCategories();
    handleRefetchDiscounts();
    let response;
    const matchingCategory = categories.find((item) => item.id.toString() === values.categoryId);
    const selectedDiscount = getDiscountId(discounts);
    if (newMenu) {
      const { result, error } = await createMenuStore({
        showCustomerPhoneNumber: false,
        phoneNumber: fullUser?.phoneNumber as string,
        taxOnOrder: 0,
        inHouseService: false,
        deliveryService: true,
        coverImageUrl: imageCreateUrl as string,
      });
      if (error && (error as any).data && (error as any).data.message === 'Menu Record Already Exists') {
        response = await handleCreateMenu(
          {
            ...values,
            categoryName: matchingCategory ? matchingCategory?.name : 'UNCATEGORIZED',
            discountId: selectedDiscount !== undefined ? selectedDiscount.toString() : 'UNDISCOUNTED',
          },
          imageCreateUrl as string
        );
        if (response?.data) {
          setShowAddNewProductsModal(false);
          setShowAddMenuItemSuccessModal(true);
          setIsLoadingAddMeal(false);
          Mixpanel.track('MENU_CREATE_NEW_ITEM', {
            userID: fullUser?.id as string,
            timeStamp: new Date().toString(),
            userBrowser: getUserBrowser(),
          });
        } else {
          setIsLoadingAddMeal(false);
        }
        return;
      }
      if (result?.data) {
        response = await handleCreateMenu(
          {
            ...values,
            categoryName: matchingCategory ? matchingCategory?.name : 'UNCATEGORIZED',
            discountId: selectedDiscount !== undefined ? selectedDiscount.toString() : 'UNDISCOUNTED',
          },
          imageCreateUrl as string
        );
        if (response?.data) {
          setShowAddNewProductsModal(false);
          setShowAddMenuItemSuccessModal(true);
          setIsLoadingAddMeal(false);
          Mixpanel.track('MENU_CREATE_NEW_ITEM', {
            userID: fullUser?.id as string,
            timeStamp: new Date().toString(),
            userBrowser: getUserBrowser(),
          });
        } else {
          setIsLoadingAddMeal(false);
        }
      } else {
        toast('error', error as string);
        setIsLoadingAddMeal(false);
      }

      return;
    }

    if (!values.id) {
      response = await createStorehouseProduct({ ...formValues, discountId: getDiscountId(discounts).toString() });
      Mixpanel.track('MENU_CREATE_STORE_PRODUCT', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
      });
      if (response.data) {
        await createMenuListItem({
          menuId: productBody?.data?.menuId as string,
          itemId: [Number(response.data?.id)],
        });
        Mixpanel.track('MENU_CREATE_MEAL_ITEM', {
          userID: fullUser?.id as string,
          timeStamp: new Date().toString(),
          userBrowser: getUserBrowser(),
        });
      }
    } else {
      response = await updateStorehouseProduct({ ...formValues, discountId: getDiscountId(discounts) }, values.id);
      if (response.status === true) {
        toast('success', 'Menu edited successfully');
        Mixpanel.track('MENU_EDIT_MEAL_ITEM', {
          userID: fullUser?.id as string,
          timeStamp: new Date().toString(),
          userBrowser: getUserBrowser(),
        });
      } else {
        toast('error', response.error as string);
        Mixpanel.track('MENU_ERROR_EDITING_MEAL_ITEM', {
          userID: fullUser?.id as string,
          timeStamp: new Date().toString(),
          userBrowser: getUserBrowser(),
        });
      }
    }

    if (response?.data) {
      setShowAddNewProductsModal(false);
      setShowAddMenuItemSuccessModal(true);
      setIsLoadingAddMeal(false);
    } else {
      setIsLoadingAddMeal(false);
    }
  };

  const handleUploadImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const file = event?.target?.files?.[0];
    if (!file) return;

    await uploadToDrake(
      [file],
      (value) => {
        setFieldValue('imageUrl', value[0]);
        setImageUrl(value[0]);
      },
      () => {
        toast('error', "Sorry we couldn't upload the image");
        Mixpanel.track('MENU_ERROR_UPLOADING_IMAGE_TO_DRAKE', {
          userID: fullUser?.id as string,
          timeStamp: new Date().toString(),
          userBrowser: getUserBrowser(),
        });
      }
    );
  };

  const areCustomFieldsSet = (values: NewProduct) => {
    if (!values.imageUrl) {
      toast('error', 'Please upload an image');
      return false;
    }
    if (!values.categoryId) {
      toast('error', 'Please select a category');
      return false;
    }
    return true;
  };

  const handleAddAnotherMenuItem = () => {
    setShowAddMenuItemSuccessModal(false);
    setShowAddNewProductsModal(true);
  };

  const handleCloseAddMenuSuccessModal = () => {
    setProductValues(newMenuValues);
    setShowAddMenuItemSuccessModal(false);
  };

  const handleSetMenuValues = (product: Product) => {
    setProductValues({
      id: product.id,
      name: product.name,
      description: product.description,
      categoryId: String(product.category.id),
      discountId: String(product.discount.id),
      price: String(product.price),
      quantity: String(product.quantity),
      storeId: product.storeId,
      sku: product.sku,
      imageUrl: product.imageUrl,
      autoGenerateSKU: false,
      depletable: false,
      enabled: true,
      published: true,
    });
  };

  return {
    categories,
    showAddNewProductsModal,
    handleCloseAddNewProductsModal,
    handleOpenAddNewProductsModal,
    productValues,
    validationSchema,
    handleCreateMenuItem,
    discounts,
    handleUploadImage,
    areCustomFieldsSet,
    showAddMenuItemSuccessModal,
    handleAddAnotherMenuItem,
    handleCloseAddMenuSuccessModal,
    imageUrl,
    handleSetMenuValues,
    isLoadingAddMeal,
  };
};

export default useAddNewMenuItem;
