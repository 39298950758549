import { Button, IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { useRef } from 'react';
import {
  FilterButtonsWrapper,
  FilterDropDownBg,
  FilterDropDownClose,
  FilterDropDownTitle,
  FilterDropDownWrapper,
  FilterCount,
  FilterLeft,
  FilterWrapper,
  FilterIconWrapper,
} from '../../styles';
import { AppliedFilter, FilterConfig } from '../../../../components/newFilter/defs';
import useFilterModel from '../../../../components/newFilter/useFilter';
import FilterItem from '../../../../components/newFilter/filter-item';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const Filter = ({
  config,
  showFilter,
  toggleShow,
  filterCount,
  onRefetch,
  onClearAll,
  onApply,
  disabled,
}: {
  config: FilterConfig[];
  showFilter: boolean;
  toggleShow: (value: boolean) => void;
  filterCount: number;
  onRefetch: () => void;
  onClearAll: () => void;
  onApply: (filter: Partial<Record<string, string | string[]>>) => void;
  disabled?: boolean;
}) => {
  const ref = useRef(null);

  const { appliedFilters, handleApplyFilter, handleClearFilters } = useFilterModel({
    onApplyFilters: () => {},
  });

  const clearAll = () => {
    onClearAll();
    handleClearFilters();
    onRefetch();
    toggleShow(false);
  };
  const applyFilters = () => {
    const filterObject: Partial<Record<string, string | string[]>> = {};
    const copy = appliedFilters as unknown as AppliedFilter<string>[];

    copy?.map((item) => {
      filterObject[item.query] = item.value;
      return item;
    });

    onApply(filterObject);
    toggleShow(false);

    onRefetch();
  };

  useOnClickOutside(ref, () => {
    toggleShow(false);
  });

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <FilterWrapper
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          toggleShow(!showFilter);
        }}
      >
        <FilterLeft>
          <Typography fontWeight={500} fontSize="12px" lineHeight="18px">
            Filter Applied
          </Typography>
          {filterCount ? (
            <FilterCount>
              <Typography fontWeight={500} fontSize="12px" lineHeight="18px">
                {filterCount}
              </Typography>
            </FilterCount>
          ) : null}
        </FilterLeft>
        <FilterIconWrapper open={showFilter}>
          <NIcons icon={IconsEnum.OUTLINE_CHEVRON_RIGHT} />
        </FilterIconWrapper>
      </FilterWrapper>

      {showFilter ? (
        <FilterDropDownWrapper onClick={() => toggleShow(false)}>
          <FilterDropDownBg onClick={(e) => e.stopPropagation()}>
            <FilterDropDownClose onClick={() => toggleShow(false)}>
              <NIcons icon={IconsEnum.OUTLINE_X} />
            </FilterDropDownClose>
            <FilterDropDownTitle>
              <Typography fontWeight={700} fontSize="20px" lineHeight="30px">
                Add filter
              </Typography>
            </FilterDropDownTitle>
            {config.map((item) => (
              <FilterItem
                configItem={item}
                onChange={() => {}}
                key={`filter_item_${item.query}`}
                handleApplyFilter={handleApplyFilter}
                appliedFilters={appliedFilters}
              />
            ))}
            <FilterButtonsWrapper>
              <Button
                disabled={appliedFilters.length < 1}
                onClick={clearAll}
                width="100%"
                variant="medium"
                color="tertiary"
              >
                Clear
              </Button>
              <Button disabled={appliedFilters.length < 1} onClick={applyFilters} width="100%" variant="medium">
                Apply filter
              </Button>
            </FilterButtonsWrapper>
          </FilterDropDownBg>
        </FilterDropDownWrapper>
      ) : null}
    </div>
  );
};

export default Filter;
