import LogoSm from '../../assets/svg/logo-sm';
import { PageLoaderWrapper } from './styles';

const PageLoad = ({ height }: { height?: string }) => (
  <PageLoaderWrapper height={height}>
    <LogoSm className="page-wrapper-loader" />
  </PageLoaderWrapper>
);

export default PageLoad;
