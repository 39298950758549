import { InventoryMobile, MyTeamsMobile } from '../../assets/svg';

export const TeamsDescription = () => (
  <>
    Easily create user profiles for members of your team and assign roles to lock actions team members can perform on
    the Nomba account.
    <br /> <br />
    Teams are the perfect solution for businesses that manage multiple people and roles.
    <br /> <br />
    Teams let you keep track of who is doing what and ensure everyone is on the same page.
  </>
);

export const TeamsComingSoonImage = () => <MyTeamsMobile />;

export const InventoryDescription = () => (
  <>
    Storehouse is the perfect solution for inventory and invoicing needs. With a storefront for products, customers can
    make orders and pay directly on the POS. It makes it easy to keep track of products available on POS, Stock levels
    and reorder when needed.
  </>
);

export const InventoryComingSoonImage = () => <InventoryMobile />;
