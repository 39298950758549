import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { convertHexToRGBA } from '../../utils';

export const NavigationTabWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0.4rem 0;
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: scroll;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;

  @media ${({ theme }) => theme.media.md} {
    overflow: hidden;
  }

  @media ${({ theme }) => theme.media.lg} {
    justify-content: flex-start;
  }
`;

export const NavigationItem = styled(Link)<{ isactive?: boolean; itemMr?: string }>`
  padding: 0 1.5rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-wrap: nowrap;
  text-transform: capitalize;
  text-decoration: none;
  color: ${({ isactive, theme }) => (isactive ? theme.nColors['n-dark'] : theme.nColors['n-grey3'])};
  height: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;

  svg {
    width: 18px;
    height: 18px;

    path {
      stroke: ${({ isactive, theme }) => (isactive ? theme.nColors['n-dark'] : theme.nColors['n-grey3'])};
    }
  }

  span {
    width: 18px;
    height: 18px;
  }
  padding-bottom: 10px;

  margin-right: ${({ itemMr }) => itemMr || '30px'};

  ${({ isactive, theme }) =>
    isactive
      ? `::after {
    content: '';
    height: 3px;
    width: 100%;
    background-color: ${theme.nColors['n-yellow']};
    position: absolute;
    bottom: -0.4rem;
    left: 0;
   transition: background-color 0.2s ease-in-out;
  }`
      : `::after {
    content: '';
    height: 3px;
    width: 100%;
    background-color: transparent;
    position: absolute;
    bottom: -0.4rem;
    left: 0;
   transition: background-color 0.4s ease-out;
  }`}
`;

/** font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 180%;
  color: ${({ isactive, theme }) => (isactive ? theme.colors.primary.green1 : theme.colors.primary.green2)};
  position: relative;
  padding: 0 0.3rem;
  margin-right: 5.2rem;
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: none;

  ${({ isactive, theme }) =>
    isactive
      ? `::after {
    content: '';
    height: 5px;
    width: 100%;
    background-color: ${theme.colors.primary.green1};
    position: absolute;
    bottom: -0.7rem;
    left: 0;
    border-radius: 0.25rem;
  }`
      : ''} */
