import { Button, AltButton, Typography } from 'kudi-component-library';
import { Flex } from 'rebass';
import styled from 'styled-components';

export const SecondaryButton = styled(AltButton)`
  color: ${({ theme }) => theme.nColors['n-light']};

  svg {
    stroke-width: 1;
    color: ${({ theme }) => theme.nColors['n-light']};
    width: 80%;
  }
`;
export const PrimaryButton = styled(Button)`
  svg {
    stroke-width: 1;
    width: 80%;
  }
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
`;

export const ButtonWrapper = styled.div`
  position: relative;
`;

export const OptionItemsWrapper = styled(Flex)`
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.nColors['n-light']};
  border: 1px solid #edeff1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1;
  margin-top: 48px !important;
`;

export const OptionItem = styled(Typography)`
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.nColors['n-grey1']};
  }
`;

export const InnerBtnText = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;
