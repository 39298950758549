import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { businessTransaction } from './thunkActions';
import { BusinessTransactionData } from '../../types/transactions';

interface Pagination {
  limit?: number;
  currentPage?: number;
  totalPages?: number;
  total?: number;
}

interface InitialState {
  totalTransactions: Array<BusinessTransactionData>;
  totalTransactionsChunked: Array<Array<BusinessTransactionData>>;
  transactions: Array<BusinessTransactionData>;
  teriminalIdList: Array<string>;
  filters: Record<string, string | Date>;
  pagination: Pagination;
  isLoading: boolean;
}

const initialState: InitialState = {
  totalTransactions: [],
  totalTransactionsChunked: [],
  transactions: [],
  teriminalIdList: [],
  filters: {},
  isLoading: false,
  pagination: {
    currentPage: 0,
    limit: 10,
    totalPages: 0,
    total: 0,
  },
};

const initializePagination = (
  state: InitialState,
  transactions: Array<BusinessTransactionData>,
  filters?: Record<string, string | Date>,
  limit?: number
) => {
  const filtered = _.filter(transactions, filters ?? state.filters);
  const chunked = _.chunk(filtered, limit ?? state.pagination.limit) as Array<Array<BusinessTransactionData>>;

  state.totalTransactionsChunked = chunked;
  state.pagination.currentPage = 0;
  state.pagination.totalPages = chunked.length ?? 1;
  state.pagination.total = filtered.length;
  state.transactions = chunked[0] ?? [];
};

const TransformTransactions = (transactions: Array<BusinessTransactionData>) => {
  const transformed = transactions.map((item) => {
    const newItem = { ...item };

    if (item?.['entry.type'] === 'wallet_topup') {
      newItem['entry.type'] = 'wallet top up';
    }

    if (item?.['entry.type'] === 'transfer') {
      newItem['entry.type'] = 'cash out';
    }

    if (item?.['entry.status'] === 'SUCCESS') {
      newItem['entry.status'] = 'success';
    }

    if (item?.['entry.status'] === 'NEW') {
      newItem['entry.status'] = 'pending';
    }

    if (item?.['entry.status'] === 'PAYMENT_FAILED') {
      newItem['entry.status'] = 'failed';
    }

    return newItem;
  });

  return transformed;
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<Record<string, string | Date>>) => {
      state.filters = action.payload;
      initializePagination(state, state.totalTransactions, action.payload);
    },

    updatePagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      if (state.pagination.limit === action?.payload?.limit) {
        state.pagination.currentPage = action.payload.currentPage;
        state.transactions = state.totalTransactionsChunked[action.payload.currentPage || 0];
      } else {
        state.pagination.limit = action.payload.limit;
        initializePagination(state, state.totalTransactions, undefined, action.payload.limit);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(businessTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(businessTransaction.rejected, (state) => {
        state.isLoading = false;
      });
    builder.addCase(businessTransaction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalTransactions = TransformTransactions(action?.payload ?? []);
      initializePagination(state, TransformTransactions(action?.payload ?? []));
    });
  },
});

export const { updateFilters, updatePagination } = transactionSlice.actions;
export default transactionSlice.reducer;
export * from './thunkActions';
