import { CreateMenuStore, CreateMenuStoreResponse } from '../../../types/menu/menuList';
import axios from '../../../utils/merchantAcqServiceHttp';

const createMenuStore = async (payload: CreateMenuStore) => {
  try {
    const result: CreateMenuStoreResponse = await axios.post(`/webbe/v1/menu-stores`, payload);

    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default createMenuStore;
