import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/merchantAcqServiceHttp';
import { ReduxAction } from '../../../types/redux';
import { IInvoice } from '../../../types/invoicing';
import { getParentBusinessId } from '../../../utils';

// type InvoiceStatus = 'DRAFT' | 'PAID' | 'UNPAID' | 'OVERDUE';
interface FetchInvoiceByStatusArgs extends ReduxAction {
  state: string | null;
  page: number;
  size: number;
}

interface CreateInvoiceArrgs extends ReduxAction {
  action?: string | null;
  payload: any;
}

interface UpdateInvoiceArgs extends ReduxAction {
  id: string;
  action?: string | null;
  payload: any;
}

interface GetSingleInvoiceArgs extends ReduxAction {
  id: string;
}
interface MarkInvoiceAsPaidArgs extends ReduxAction {
  id: string;
}

interface SendRinderArgs extends ReduxAction {
  id: string;
}

interface DeleteInvoiceArgs extends ReduxAction {
  id: string | null;
}

interface FetchInvoiceByStatus extends ReduxAction {
  data: Array<IInvoice>;
}

export interface SearchInvoiceArgs extends ReduxAction {
  searchQuery: number | string | null;
}

export const deleteInvoice = createAsyncThunk(
  '/invoice/delete',
  async ({ id, onSuccess, onFailure }: DeleteInvoiceArgs, { rejectWithValue }) => {
    try {
      const data = await axios.delete(`/webbe/v1/invoice/${id as string}`, {
        headers: {
          businessId: getParentBusinessId(),
        },
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// done (change businessId)
export const fetchInvoiceByStatus = createAsyncThunk(
  '/invoice/getByStatus',
  async ({ state, onSuccess, onFailure, page, size }: FetchInvoiceByStatusArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchInvoiceByStatus = await axios.get(
        `/webbe/v1/invoice/filter?state=${state as string}&page=${page}&size=${size}`,
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) {
        onSuccess(data);
      }
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const createInvoice = createAsyncThunk(
  '/invoice/create',
  async ({ payload, onSuccess, onFailure }: CreateInvoiceArrgs, { rejectWithValue }) => {
    try {
      const data = await axios.post(`/webbe/v1/invoice/create-invoice`, payload, {
        headers: {
          businessId: getParentBusinessId(),
        },
      });
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const updateInvoice = createAsyncThunk(
  '/invoice/update',
  async ({ id, payload, onSuccess, onFailure }: UpdateInvoiceArgs, { rejectWithValue }) => {
    try {
      const data = await axios.put(
        `/webbe/v1/invoice/update-invoice`,
        { invoiceId: id, ...payload },
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const getSingleInvoice = createAsyncThunk(
  '/invoice/getInvoice',
  async ({ id, onSuccess, onFailure }: GetSingleInvoiceArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/webbe/v1/view-invoice?invoiceId=${id}`);
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const getSummary = createAsyncThunk(
  '/invoice/getSummary',
  async ({ onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/webbe/v1/invoice/states/summary', {
        headers: {
          businessId: getParentBusinessId(),
        },
      });
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const markInvoiceAsPaid = createAsyncThunk(
  '/invoice/mark-as-paid',
  async ({ id, onSuccess, onFailure }: MarkInvoiceAsPaidArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `/webbe/v1/invoice/update-status`,
        {
          invoiceId: id,
          newStatus: 'PAID',
        },
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const sendRiminder = createAsyncThunk(
  '/invoice/send-reminder',
  async ({ id, onSuccess, onFailure }: SendRinderArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/webbe/v1/invoice/send-reminder`,
        {
          invoiceId: id,
        },
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const getPaymentInformation = createAsyncThunk(
  '/invoice/get-payment-information',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/webbe/v1/invoice/payment-options', {
        headers: {
          businessId: getParentBusinessId(),
        },
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// not done
export const searchInvoice = createAsyncThunk(
  '/invoice/search',
  async ({ searchQuery, onSuccess, onFailure }: SearchInvoiceArgs, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/webbe/v1/invoice/search?invoiceNumber=${searchQuery}`, {
        headers: {
          businessId: getParentBusinessId(),
        },
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
