import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiResponse, ReduxAction } from '../../../../types/redux';
import { RootState } from '../../../../app/store';
import axios from '../../../../utils/merchantAcqServiceHttp';

interface UnmapTerminalResponse extends ApiResponse {
  data: null;
}

const base = window._env_.REACT_APP_BASEURL;

export const UnmapTerminal = createAsyncThunk<UnmapTerminalResponse, ReduxAction, { state: RootState }>(
  '/referral/unmap-terminal',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    const {
      referral: {
        downline: { selectedDownline, selectedTerminal },
      },
    } = getState();
    const params: { agentPhone?: string; terminalId?: string } = {};

    if (selectedDownline?.phoneNumber) {
      params.agentPhone = selectedDownline?.phoneNumber;
    }

    if (selectedTerminal?.terminalId) {
      params.terminalId = selectedTerminal?.terminalId;
    }
    try {
      const data: UnmapTerminalResponse = await axios.post(`${base}/terminal/unassign-terminal`, null, { params });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
