import styled from 'styled-components';

export const MobileWrapper = styled.div`
  display: none;
  min-height: 50vh;
  @media (max-width: 600px) {
    display: block;
    background: #ffffff;
    border-radius: 5px;
  }
`;

export const DesktopWrapper = styled.div`
  min-height: 60vh;
  background: #ffffff;
  border-radius: 5px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  background: white;
  border-radius: 5px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Tr = styled.tr<{ cursor?: string }>`
  cursor: ${({ cursor }) => cursor};
`;

export const Td = styled.td<{ color?: string; textTransform?: string }>`
  color: ${({ color }) => color || '#121212'};
  font-size: 14px;
  font-weight: 400;
  padding: 16px 25px;
  background-color: white;
  vertical-align: middle;
  border-top: 1px solid #eeeeee;
  text-transform: ${({ textTransform }) => textTransform};
`;

export const Th = styled.th`
  font-size: 14px;
  color: #121212;
  padding: 16px 25px;
  font-weight: 400;
  text-align: left;
  vertical-align: middle;
`;

export const EmptyWrapper = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px;
`;
