import { MenuListAliasResponse } from '../../../types/menu/menuList';
import axios from '../../../utils/merchantAcqServiceHttp';

const fetchMenuListProducts = async ({ params, outletId }: { params?: URLSearchParams; outletId?: string }) => {
  const url = `/webbe/v1/menus?${params ? params.toString() : ''}`;
  try {
    const result: MenuListAliasResponse = await axios.get(url, { headers: { ...(outletId && { outletId }) } });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchMenuListProducts;
