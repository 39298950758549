/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
import { Button, Text } from 'kudi-component-library';
import React from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { CautionIcon, DeleteBeneficiaryIcon } from '../../../assets/svg';
import Modal from '../../../components/Modal';
import { deleteBeneficiary } from '../../../features/transfers/thunkActions';
import { FullFlex } from '../../../styles/generic';

function ResponseModal({
  showResponseModal,
  setShowResponseModal,
}: {
  showResponseModal: boolean;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal showModal={showResponseModal} onClose={() => setShowResponseModal(false)}>
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="20px">
        <DeleteBeneficiaryIcon />

        <Text
          id="beneficiary-deleted-text"
          color="#121212"
          fontSize="24px"
          fontWeight="bold"
          textAlign="center"
          mt="30px"
          mb="120px"
        >
          Beneficiary deleted successfully
        </Text>
        <Button
          id="close-delete-beneficiary-modal"
          onClick={() => {
            setShowResponseModal(false);
          }}
          width="80%"
          variant="xlarge"
          style={{ backgroundColor: '#FFCC00', color: '#121212' }}
        >
          Close
        </Button>
      </FullFlex>
    </Modal>
  );
}

export default function DeleteBeneficiaryModal({
  showDeleteBeneficiaryModal,
  setShowDeleteBeneficiaryModal,
  reload,
  setReload,
  beneficiary,
}: {
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  reload?: boolean;
  showDeleteBeneficiaryModal: boolean;
  setShowDeleteBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  beneficiary: { id: string; bankName: string; accountNo: string; name: string; nickName: string };
}) {
  const [showResponseModal, setShowResponseModal] = React.useState(false);

  const dispatch = useAppDispatch();

  function handleDeleteBeneficiary() {
    dispatch(
      deleteBeneficiary({
        id: beneficiary.id,
        onFailure: () => {},
        onSuccess: () => {
          if (setReload) {
            setReload(!reload);
          }
          setShowDeleteBeneficiaryModal(false);
          setShowResponseModal(true);
        },
      })
    );
  }
  return (
    <>
      <Modal showModal={showDeleteBeneficiaryModal} onClose={() => setShowDeleteBeneficiaryModal(false)}>
        <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="10px">
          <CautionIcon />

          <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
            Are you sure you want to delete beneficiary?
          </Text>
          <Text color="#717171" fontSize="14px" textAlign="center" mb="110px">
            Pleaase be aware that this action is irreversible
          </Text>

          <Button
            id="delete-beneficiary-btn"
            onClick={() => {
              handleDeleteBeneficiary();
            }}
            width="100%"
            variant="xlarge"
            style={{ backgroundColor: '#FFCC00', color: '#121212', marginBottom: '20px' }}
          >
            Yes, Delete
          </Button>
          <Text
            id="close-delete-beneficiary"
            fontSize="14px"
            color="#717171"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowDeleteBeneficiaryModal(false)}
          >
            <u> No, close modal</u>
          </Text>
        </FullFlex>
      </Modal>

      {/* Response Modal */}
      <ResponseModal showResponseModal={showResponseModal} setShowResponseModal={setShowResponseModal} />
    </>
  );
}
