import styled from 'styled-components';
import { convertHexToRGBA } from '../../../utils';

export const Layout = styled.div`
  display: flex;
  height: 100vh;
`;

export const NavigationWrapper = styled.div<{ open: boolean }>`
  width: 100%;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  position: fixed;
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => convertHexToRGBA(theme.colors.primary.blue1, 0.2)};

  @media ${({ theme }) => `${theme.media.xl}`} {
    position: relative;
    background-color: ${({ theme }) => theme.colors.primary.white};
    width: fit-content;
    visibility: visible;
    z-index: 50;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: #eeeeee;
  height: 100%;

  @media ${({ theme }) => `${theme.media.xl}`} {
    width: calc(100% - 25.5rem);
  }
  overflow-y: auto;
`;

export const PageLoadWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};
  align-items: center;
  justify-content: center;
`;

export const MainPageContentHolder = styled.div`
  height: calc(100vh - 6rem);
  width: 100%;
  overflow-y: scroll;
`;
