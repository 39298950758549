import { Button, Typography } from 'kudi-component-library';
import styled from 'styled-components';
import { MobileButtonTextColorType } from './defs';

const setBackground = (
  condition1: boolean,
  background1: string,
  condition2: boolean,
  background2: string,
  otherwise: null
) => {
  if (condition1) {
    return background1;
  }

  if (condition2) {
    return background2;
  }
  return otherwise;
};

export const TableWrapper = styled.div<{
  flatBottom?: boolean;
  hasActions?: boolean;
  darkBackgroundHeader?: boolean;
}>`
  width: 100%;
  border-radius: ${({ flatBottom }) => (flatBottom ? '5px 5px 0 0' : '5px')};
  overflow-x: ${({ hasActions }) => (hasActions ? 'visible' : 'hidden')};
  ${({ darkBackgroundHeader, theme }) =>
    darkBackgroundHeader
      ? null
      : `
  border: 1px solid ${theme.nColors['n-grey1']};
  `}
`;

export const Table = styled.table<{
  hasActions?: boolean;
  darkBackgroundHeader?: boolean;
  grayBackgroundHeader?: boolean;
}>`
  width: 100%;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-collapse: collapse;
  overflow-y: ${({ hasActions }) => (hasActions ? 'auto' : 'hidden')};
  overflow-x: ${({ hasActions }) => (hasActions ? 'visible' : 'hidden')};
  display: none;

  th {
    text-align: start;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  thead tr th {
    background-color: ${({ theme, darkBackgroundHeader, grayBackgroundHeader }) =>
      setBackground(
        darkBackgroundHeader as boolean,
        theme.nColors['n-dark'],
        grayBackgroundHeader as boolean,
        '#f8f8f8',
        null
      )};
    color: ${({ theme, darkBackgroundHeader, grayBackgroundHeader }) =>
      setBackground(
        darkBackgroundHeader as boolean,
        theme.nColors['n-light'],
        grayBackgroundHeader as boolean,
        '#f000',
        null
      )};
  }
  ${({ darkBackgroundHeader, theme }) =>
    !darkBackgroundHeader
      ? `
      tr {
        border-top: 1px solid ${theme.nColors['n-grey1']};
      }`
      : null}

  @media ${({ theme }) => theme.media.lg} {
    display: table;
  }
`;

export const Td = styled.td<{ padding?: string; verticalAlign?: string }>`
  padding: ${({ padding }) => padding || '1.4rem 2rem'};
  font-size: 1.4rem;
  line-height: 2.2rem;
  vertical-align: ${({ verticalAlign }) => verticalAlign};
`;

export const Th = styled.th<{ padding?: string }>`
  padding: ${({ padding }) => padding || '1.4rem 2rem'};
`;

export const PaginationWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PaginationList = styled.ol`
  display: flex;
  gap: 0.4rem;
`;

export const PaginationItem = styled.li<{ active?: boolean }>`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary.white};
  align-items: center;
  justify-content: center;
  width: 2.7rem;
  height: 4.2rem;
  border-radius: 0.2rem;
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${({ theme, active }) => (active ? theme.colors.primary.green1 : theme.colors.primary.green2)};
  cursor: pointer;
`;

export const PaginationConfig = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const PaginationConfigText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
`;

export const PaginationConfigDropDownWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.white};
  width: 5.6rem;
  height: 4.2rem;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 1rem;
  position: relative;
  z-index: 10;
  display: flex;
`;

export const PaginationConfigDropDownContent = styled.div<{ isOpen?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const PaginationConfigDropDownMenuWrapper = styled.ol`
  position: absolute;
  top: -12rem;
  left: 0;
  width: 4rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  display: flex;
  padding: 1rem;
  border-radius: 0.2rem;
  flex-direction: column;

  li:first-child {
    border: none;
  }

  li {
    border-top: 1px solid ${({ theme }) => theme.colors.primary.black6};
  }
`;

export const PaginationConfigDropDownMenuItem = styled.li`
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileTableItemWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 13px 15px 16px;
  width: 100%;
  cursor: pointer;
`;

export const MobileTableItemMain = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const MobileTableItemCover = styled.div`
  border-bottom: 1px solid #eeeeee;
`;

export const MobileTableList = styled.ol<{ flatBottom?: boolean }>`
  border-radius: ${({ flatBottom }) => (flatBottom ? '5px 5px 0 0' : '5px')};
  background-color: ${({ theme }) => theme.colors.primary.white};

  @media ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const MobileTableItemTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6rem;
  width: 100%;
`;

export const MobileTableItemBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MobileActionsIconContainer = styled.div`
  margin-left: 10px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const MobileTableActionDropdown = styled.div<{ gridActions?: boolean }>`
  ${({ gridActions }) =>
    gridActions
      ? `
display: grid;
grid-template-columns: repeat(2, 1fr);
padding: 13px 15px 16px;
grid-gap: 10px;
  div {
    align-items: center;
  }
  div:nth-child(even) {
    display: flex; 
    justify-content: end;
    
  }
`
      : `  display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
padding: 13px 15px 16px;
gap: 20px;
flex-wrap: wrap;
div {
  width: 100%;
}
button {
  width: 100%;
}`}
`;

export const MobileActionButtonWrapper = styled.div<{
  mobileButtonTextColor?: MobileButtonTextColorType;
}>`
  ${({ mobileButtonTextColor, theme }) =>
    mobileButtonTextColor &&
    `button {
      span{
        color: ${theme.nColors[mobileButtonTextColor as unknown as MobileButtonTextColorType]}
      }
  }`}
`;

// default empty state
export const EmptyStateWrapper = styled.div`
  width: 100%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 400;
  color: #999999;
  align-items: center;
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
`;

export const MobileTableLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const MobileDetailsWrapper = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.nColors['n-grey1']}`};
`;

export const MobileDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 15px 15px 0px;
`;

export const MobileDetailText = styled(Typography)`
  font-size: 12px;
`;

export const OutlinedButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.nColors['n-dark']} !important;
  background: ${({ theme }) => theme.nColors['n-light']};
`;

export const UnderLinedButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #121212;
    }
  }
  &:hover {
    span {
      color: ${({ theme }) => theme.nColors['n-grey4']};
    }
  }
`;
export const UnderlinedButtonText = styled(Typography)`
  line-height: 24px;
`;

export const IconsWrapper = styled.div`
  span {
    cursor: pointer;
  }
`;

export const SortColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
