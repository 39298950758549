import dayjs from 'dayjs';
import { TransactionStatus } from '../../../types/transactions';
import { customFilterDateFormatter } from '../../functions';

export const transformTransactionStatus = (status: TransactionStatus) => {
  switch (status) {
    case 'NEW':
    case 'PENDING_PAYMENT':
    case 'PENDING_BILLING':
    case 'PENDING_FRAUD_CHECK':
    case 'BILLER_PURCHASE_FAILED':
      return 'Pending';
    case 'SUCCESS':
    case 'PAYMENT_SUCCESSFUL':
      return 'Success';
    case 'PAYMENT_FAILED':
    case 'CANCELLED':
    case 'REVERSED_BY_VENDOR':
      return 'Failed';
    default:
      return 'Failed';
  }
};

export const PAGE_SIZE = 50;

export const formatTransactionDates = (startDate: Date, endDate: Date) => ({
  startDate: dayjs(startDate).format('YYYY-MM-DD[T00:00:00]'),
  endDate: dayjs(endDate).format('YYYY-MM-DD[T23:59:59]'),
});

export const transactionsFilterInitalDates: {
  startDate: Date;
  endDate: Date;
} = {
  startDate: dayjs().subtract(1, 'month').toDate(),
  endDate: dayjs().toDate(),
};

export const transactionsDefaultFilters = {
  limit: String(PAGE_SIZE),
  dateFrom: customFilterDateFormatter(transactionsFilterInitalDates.startDate),
  dateTo: customFilterDateFormatter(transactionsFilterInitalDates.endDate),
};

// settlements
export const settlementTransactionStatus = ['PAID', 'PENDING', 'REVERSED', 'CREDIT', 'DEBIT', 'REFUND'];

export const creditSettlementTransactionType = ['credit', 'paid', 'refund'];
