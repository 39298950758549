import { useRef, useState } from 'react';
import { IconsEnum, NIcons, Radio } from 'kudi-component-library';
import IssueOptionItem from './issue_option';
import { IssueItemWrapper, SubIssuesWrapper } from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import { selectIssueType, updateIssueCreationStage } from '../../reducer/slice';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { IssueCategory, IssueType } from '../../defs';

export interface IssueCreationItemProps extends IssueCategory {
  action?: () => void;
}

const IssueCreationItem = ({ action, id, category }: IssueCreationItemProps) => {
  const ref = useRef(null);
  const {
    state: {
      support: {
        issueCreation: { selectedType, selectedSubCategory, selectedCategoryTypeList, isFetching },
      },
    },
    dispatch,
  } = useRedux();
  const [show, setShow] = useState<boolean>(false);

  useOnClickOutside(ref, () => {
    setTimeout(() => {
      setShow(false);
    }, 300);
  });

  const handleClick = () => {
    setShow((prev) => !prev);
    action?.();
  };

  const handleSubClick = (type: IssueType) => {
    action?.();
    dispatch(selectIssueType(type));
    dispatch(updateIssueCreationStage('transactions'));
  };

  const subSet = selectedSubCategory?.id === id;

  return (
    <IssueItemWrapper ref={ref}>
      <IssueOptionItem
        iconBg
        open={show}
        Icon={<NIcons icon={IconsEnum.OUTLINE_RECEIPT} />}
        text={category}
        action={handleClick}
        loading={subSet && isFetching}
      />
      {subSet && show && !isFetching ? (
        <SubIssuesWrapper>
          {selectedCategoryTypeList.map((item) => (
            <Radio
              onChange={() => handleSubClick(item)}
              defaultChecked={selectedType?.id === item.id}
              name={item.description}
              value={item.id}
            >
              {item.description}
            </Radio>
          ))}
        </SubIssuesWrapper>
      ) : null}
    </IssueItemWrapper>
  );
};

export default IssueCreationItem;
