/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import appConfig from '../utils/config';
import { NmMode } from '../utils/constants';
import { useAppDispatch } from '../app/hooks';
import { setInitV2 } from '../features/auth/slice';

const TransformToSearchParamObject = (params: string) => {
  const cleaned = params
    .replace('?', '')
    .split('&')
    .map((item) => {
      const pair = item.split('=');
      return { [pair?.[0]]: pair?.[1] };
    });

  return cleaned;
};

const TransformToSearchParams = (params: Record<string, string>[]) => {
  let value;

  if (params.length > 0) {
    value = `?${params.map((item) => `${Object.keys(item)?.[0]}=${Object.values(item)?.[0]}`).join('&')}`;
  }

  return value;
};

const useIsOnboardingAllowed = () => {
  const matchParam = appConfig.onbaordingV2Param;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { search, pathname } = useLocation();

  const paramsArray = TransformToSearchParamObject(search);

  const isMode = paramsArray.find((item) => item?.mode);

  const isMatch = paramsArray.find((item) => item?.mode === matchParam);

  const finalParams = search ? TransformToSearchParams(paramsArray.filter((item) => !item?.mode)) : '';

  useLayoutEffect(() => {
    if (matchParam && isMatch) {
      dispatch(setInitV2(true));
      localStorage.setItem(NmMode, JSON.stringify(matchParam));
    }

    if (isMode && !isMatch) {
      dispatch(setInitV2(false));
      localStorage.removeItem(NmMode);
    }
  }, []);

  useEffect(() => {
    navigate(`${pathname}${finalParams || ''}`);
  }, []);
};

export default useIsOnboardingAllowed;
