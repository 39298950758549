import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { IInvoice, InvoiceSummaryType, Pagination, PaymentOptionType } from '../../../types/invoicing';
import {
  getSummary,
  fetchInvoiceByStatus,
  createInvoice,
  updateInvoice,
  getSingleInvoice,
  deleteInvoice,
  markInvoiceAsPaid,
  getPaymentInformation,
  sendRiminder,
  searchInvoice,
} from './thunkActions';

interface InitialState {
  invoices: Array<IInvoice>;
  totalInvoicesChunked: Array<Array<IInvoice>>;
  totalInvoices: Array<IInvoice>;
  summary: InvoiceSummaryType[];
  paymentInformations: PaymentOptionType[];
  isLoading: boolean;
  showInvoiceSuccessModal: boolean;
  showSendInvoicePrompt: boolean;
  showDeletePrompt: boolean;
  isSummaryLoading: boolean;
  showMoreActions: boolean;
  showMarkAsPaidPrompt: boolean;
  pagination: Pagination;
  isSendingReminder: boolean;
}

const initialState: InitialState = {
  invoices: [],
  totalInvoicesChunked: [],
  totalInvoices: [],
  summary: [],
  paymentInformations: [],
  isLoading: false,
  showInvoiceSuccessModal: false,
  showDeletePrompt: false,
  isSummaryLoading: false,
  showSendInvoicePrompt: false,
  showMoreActions: false,
  showMarkAsPaidPrompt: false,
  isSendingReminder: false,
  pagination: {
    currentPage: 0,
    limit: 10,
    totalPages: 0,
    total: 0,
  },
};

const initializePagination = (state: InitialState, invoices: Array<IInvoice>, limit?: number) => {
  const filtered = _.filter(invoices);
  const chunked = _.chunk(filtered, limit ?? state.pagination.limit);
  state.totalInvoicesChunked = chunked;
  state.pagination.currentPage = 0;
  state.pagination.totalPages = chunked.length ?? 1;
  state.pagination.total = filtered.length;
  state.invoices = chunked[0] ?? [];
};

export const outgoingSlice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    setShowConfirmationModal: (state, action: PayloadAction<boolean>) => {},
    setShowInvoiceSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.showInvoiceSuccessModal = action.payload;
    },
    setShowDeletePrompt: (state, action: PayloadAction<boolean>) => {
      state.showDeletePrompt = action.payload;
    },
    setShowMarkAsPaidPrompt: (state, action: PayloadAction<boolean>) => {
      state.showMarkAsPaidPrompt = action.payload;
    },
    setShowMoreActionsModal: (state, action: PayloadAction<boolean>) => {
      state.showMoreActions = action.payload;
    },
    setShowSendInvoicePrompt: (state, action: PayloadAction<boolean>) => {
      state.showSendInvoicePrompt = action.payload;
    },
    updatePagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      if (state.pagination.limit === action?.payload?.limit) {
        state.pagination.currentPage = action.payload.currentPage;
        state.invoices = state.totalInvoicesChunked[action.payload.currentPage || 0];
      } else {
        state.pagination.limit = action.payload.limit;
        initializePagination(state, state.totalInvoices, action.payload.limit);
      }
    },
  },
  extraReducers(builder) {
    // Fetch Invoices
    builder
      .addCase(fetchInvoiceByStatus.pending, (state) => {
        state.isLoading = true;
        state.showDeletePrompt = false;
        state.showMarkAsPaidPrompt = false;
      })
      .addCase(fetchInvoiceByStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchInvoiceByStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        payload.forEach((item: any) => {
          item.total = item?.entries?.total;
          item.name = item?.customerDescription?.name;
        });
        state.totalInvoices = payload;
        initializePagination(state, payload);
      });

    // Search for customers
    builder
      .addCase(searchInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(searchInvoice.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const copyOfPayload = [{ ...payload }];
        copyOfPayload.forEach((item: any) => {
          item.total = item?.entries?.total;
          item.name = item?.customerDescription?.name;
        });
        state.totalInvoices = copyOfPayload;
        initializePagination(state, copyOfPayload);
      });

    // Get single Invoice
    builder
      .addCase(getSingleInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSingleInvoice.fulfilled, (state) => {
        state.isLoading = false;
      });

    // Get payment information
    builder
      .addCase(getPaymentInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPaymentInformation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.paymentInformations = payload as PaymentOptionType[];
      });

    // Create Invoice
    builder
      .addCase(createInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createInvoice.fulfilled, (state) => {
        state.isLoading = false;
        state.showSendInvoicePrompt = false;
        state.showInvoiceSuccessModal = true;
      });

    // Update Invoice
    builder
      .addCase(updateInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateInvoice.fulfilled, (state) => {
        state.isLoading = false;
        state.showSendInvoicePrompt = false;
        state.showInvoiceSuccessModal = true;
      });

    // Delete invoice
    builder
      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteInvoice.fulfilled, (state) => {
        state.isLoading = false;
      });

    // Get Summary
    builder
      .addCase(getSummary.pending, (state) => {
        state.isSummaryLoading = true;
      })
      .addCase(getSummary.rejected, (state) => {
        state.isSummaryLoading = false;
      })
      .addCase(getSummary.fulfilled, (state, { payload }) => {
        state.isSummaryLoading = false;
        state.summary = payload as InvoiceSummaryType[];
      });

    // Mark invoice as paid
    builder
      .addCase(markInvoiceAsPaid.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(markInvoiceAsPaid.rejected, (state) => {
        state.isLoading = false;
        state.showMoreActions = false;
      })
      .addCase(markInvoiceAsPaid.fulfilled, (state) => {
        state.isLoading = false;
        state.showMoreActions = false;
      });

    // Send reminder notification
    builder
      .addCase(sendRiminder.pending, (state) => {
        state.isSendingReminder = true;
      })
      .addCase(sendRiminder.rejected, (state) => {
        state.isSendingReminder = false;
        state.showMoreActions = false;
      })
      .addCase(sendRiminder.fulfilled, (state) => {
        state.isSendingReminder = false;
        state.showMoreActions = false;
      });
  },
});

export const {
  setShowConfirmationModal,
  setShowInvoiceSuccessModal,
  setShowDeletePrompt,
  setShowMoreActionsModal,
  setShowSendInvoicePrompt,
  updatePagination,
  setShowMarkAsPaidPrompt,
} = outgoingSlice.actions;

export default outgoingSlice.reducer;
export * from './thunkActions';
