/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Text } from 'kudi-component-library';
import moment from 'moment';
import { FullBox, FullFlex } from '../../../styles/generic';
import { DesktopWrapper } from '../../loan-provider/components/filter';
import { MobileWrapper } from '../../loan-provider/components/table';
import { TransferList } from '../../../types/transactions';
import { formatCurrency } from '../../../utils';
import Table from '../../../components/newTable';
import { TransferSchema } from './schema';
import TransactionDetailsModal from './transaction-details/transaction-details';

export default function Transferstable({
  loading,
  transfersData,
  selectedData,
  showDetailsModal,
  setSelectedData,
  setShowDetailsModal,
  handlePaginationChange,
}: {
  transfersData: Array<TransferList>;
  selectedData: any | null;
  showDetailsModal: boolean;
  loading: boolean;
  setSelectedData: React.Dispatch<React.SetStateAction<TransferList | null>>;
  setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  handlePaginationChange: (page: number) => void;
}) {
  const handleRowClick = (requestData: TransferList) => {
    setSelectedData(requestData);
    setShowDetailsModal(true);
  };

  const handleCloseTransactionDetails = () => setShowDetailsModal(false);

  return (
    <FullBox mt="20px" fontWeight="regular" width="100%">
      <DesktopWrapper>
        <Table
          schema={TransferSchema}
          data={transfersData || []}
          emptyText="Sorry there are no transfers"
          isLoading={loading}
          flatBottom
          isPaginated={false}
          onRowCLick={handleRowClick}
          paginationProps={{
            handlePaginationChange,
            shouldShowSelect: false,
            isLastPage: transfersData.length < 10,
          }}
        />

        {selectedData !== null && (
          <TransactionDetailsModal
            showModal={showDetailsModal}
            onClose={handleCloseTransactionDetails}
            transactionRef={selectedData.reference}
            fullTransaction={selectedData}
          />
        )}
      </DesktopWrapper>

      <MobileWrapper>
        {transfersData.map((data: TransferList, index) => (
          <>
            <FullFlex
              id="transfers-table-mobile"
              onClick={() => {
                setSelectedData(data);
                setShowDetailsModal(true);
              }}
              key={index}
              style={{ padding: '20px' }}
              justifyContent="space-between"
            >
              <FullBox>
                <Text style={{ fontWeight: 'normal', fontSize: '14px', color: '#717171' }}>{data?.beneficiary}</Text>
                <Text style={{ fontWeight: 'normal', fontSize: '10px', color: '#717171' }}>
                  {moment(data.time).date()} {moment(data.time).format('MMM')}, {moment(data.time).year()}
                </Text>
              </FullBox>
              <FullBox>
                <Text style={{ fontWeight: 'normal', fontSize: '14px', color: '#717171' }}>
                  {formatCurrency(data?.amount)}
                </Text>
                {data?.status === 'Success' && (
                  <Text style={{ fontWeight: 'normal', fontSize: '10px', color: '#11BB00' }}>{data.status}</Text>
                )}
                {data?.status === 'Refund' && (
                  <Text style={{ fontWeight: 'normal', fontSize: '10px', color: '#121212' }}>{data.status}</Text>
                )}
                {data?.status === 'Pending' && (
                  <Text style={{ fontWeight: 'normal', fontSize: '10px', color: '#C6C6C6' }}>{data.status}</Text>
                )}
              </FullBox>
            </FullFlex>
            <hr style={{ height: '0px', borderWidth: '0.4px', color: '#EEEEEE' }} />

            {selectedData !== null && (
              <TransactionDetailsModal
                showModal={showDetailsModal}
                onClose={handleCloseTransactionDetails}
                transactionRef={selectedData.reference}
                fullTransaction={selectedData}
              />
            )}
          </>
        ))}
      </MobileWrapper>
    </FullBox>
  );
}
