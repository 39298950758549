import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { Button, Typography } from 'kudi-component-library';

export const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
`;
export const LogoWrapper = styled.div`
  cursor: pointer;
  svg {
    transform: scale(0.8);
  }

  @media ${({ theme }) => theme.media.lg} {
    svg {
      transform: scale(1);
    }
  }
`;
export const TopRightWrapper = styled(Flex)`
  gap: 10px;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    stroke: #b3b3b3;
  }
`;

export const TopRightText = styled(Typography)`
  font-weight: 500;
  color: #4d4d4d;
  cursor: pointer;
  span {
    font: unset;
    border-bottom: 1px solid #4d4d4d;
    padding-bottom: 1px;
  }
  @media ${({ theme }) => theme.media.md} {
    line-height: 16px;
    span {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }
`;

// account type page styles

export const ChooseAccountTypeWrapper = styled.div`
  @media ${({ theme }) => theme.media.md} {
    padding-top: 5%;
    max-width: 446px;
  }
`;
export const AccountTypeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const AccountTypeIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #ffeb99;
  border-radius: 4px;
`;

export const AccountTypeItem = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  background: rgba(242, 242, 242, 0.4);
  &:hover {
    border: 1px solid #ffd633;
    background: #fffcf2;
    ${AccountTypeIconWrapper} {
      background: ${({ theme }) => theme.nColors['n-yellow']};
    }
    svg {
      stroke: #0d0d0d;
    }
  }
  section.right-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #0d0d0d;
      :last-of-type {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #808080;
      }
    }
    svg {
      stroke: #808080;
    }
  }
  @media ${({ theme }) => theme.media.md} {
    width: 446px;
  }
`;

// form styling

export const FormBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
  @media ${({ theme }) => theme.media.md} {
    margin-bottom: 50px;
  }
`;

// create password
export const ConsentWrapper = styled.div`
  gap: 8px;
  display: flex;
  margin: 20px 0px;
  label {
    width: fit-content;
    height: fit-content;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: #717171;
  }
  strong {
    color: ${({ theme }) => theme.nColors['n-dark']};
  }
  @media ${({ theme }) => theme.media.md} {
    margin: 40px 0px 20px;
  }
`;

// verfication
export const VerificationInfoWrapper = styled.div<{ validated: boolean }>`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  color: #4d4d4d;
  border-radius: 24px;
  padding: 4px 10px;
  gap: 8px;
  strong {
    font-weight: 700;
  }

  #icon-wrapper {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    span {
      display: contents;
    }
    svg {
      width: 16px;
      height: 16px;
      display: flex;
    }
    &.success-icon {
      svg path {
        stroke: white;
      }
    }
    border-radius: 100px;
    background: ${({ validated }) =>
      validated
        ? 'linear-gradient(180deg, #1E8E3E 0%, rgba(30, 142, 62, 0.5) 100%)'
        : 'linear-gradient(180deg, #ffcc00 0%, #ffeeaa 100%)'};
  }

  ${({ validated }) =>
    validated
      ? css`
          font-size: 14px;
          line-height: 20px;
          background: #f5fff2;
        `
      : css`
          font-size: 12px;
          line-height: 16px;
          background: #fffcf2;
        `}
`;

// business information
export const FormRow = styled.div`
  display: flex;
  gap: 10px;
`;
export const FormActionContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const TransparentButton = styled(Button)`
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #1a1a1a;
`;
