import { Pagination, Typography } from 'kudi-component-library';
import { useParams } from 'react-router-dom';
import { Separator } from '../../../../../styles/generic';
import {
  FilterContainer,
  SettlementsDownloadButton,
  SettlementsFilterSectionWrapper,
} from '../../../../banking/settlements/styles';
import Filter from '../../../../../components/Filter/filter';
import { DownloadIcon } from '../../../../../assets/svg';
import {
  MobileSettlementsSchema,
  SettlementsFilterConfig,
  SettlementsSchema,
} from '../../../../banking/settlements/schema';
import useIsMobile from '../../../../../hooks/useIsMobile';
import useSettlementsModel from '../../../../banking/settlements/useSettlements';
import useDownLoadReportModel from '../../../../banking/settlements/modals/useDownloadReport';
import DownloadReportModal from '../../../../banking/settlements/modals/download-report';
import Table from '../../../../../components/newTable';

export default function OutletSettlementsTab() {
  const { id } = useParams();

  const isMobile = useIsMobile(992);
  const {
    handleFilterSettlements,
    handleClearSearch,
    settlements,
    isLoading,
    handleClickSettlement,
    handlePaginationChange,
    isLastPage,
  } = useSettlementsModel({ outletId: id });

  const { showDownloadReportModal, handleCloseDownloadReportModal, handleShowDownloadReportModal } =
    useDownLoadReportModel({});

  return (
    <>
      <SettlementsFilterSectionWrapper>
        <FilterContainer width={isMobile ? '100%' : '80%'}>
          <Filter
            mobileMarginTop={0}
            config={SettlementsFilterConfig}
            filterWrapperMarginTop="0rem"
            onChange={handleFilterSettlements}
            onRemove={handleClearSearch}
          />
        </FilterContainer>
        <SettlementsDownloadButton
          onClick={handleShowDownloadReportModal}
          width={isMobile ? '100%' : '20%'}
          id="settelements-download-settlements-report"
        >
          <DownloadIcon />
          <Typography variant="n-text1">Download Report</Typography>
        </SettlementsDownloadButton>
      </SettlementsFilterSectionWrapper>
      <Separator height="2rem" />
      <Table
        schema={SettlementsSchema}
        mobileSchema={MobileSettlementsSchema}
        data={settlements}
        isLoading={isLoading}
        onRowCLick={handleClickSettlement}
      />
      <Pagination handlePaginationChange={handlePaginationChange} isLastPage={isLastPage} />
      <DownloadReportModal
        showDownloadReportModal={showDownloadReportModal}
        handleCloseDownloadReportModal={handleCloseDownloadReportModal}
        outletId={id}
      />
    </>
  );
}
