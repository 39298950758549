import styled from 'styled-components';

export const SearchWrapper = styled.form`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 21.33px;
  svg {
    margin-right: 22.86px;
  }
  input {
    border: none;
    outline: none;
    width: 100%;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const ClearIconWrapper = styled.div`
  svg {
    margin-right: 0;
    cursor: pointer;
  }
`;
