import { Button } from 'kudi-component-library';
import Filter from '../../../../Filter/filter';
import {
  FilterLabel,
  FilterTextInput,
  FilterWrapper,
  FilterItem,
  FilterAction,
  FilterActionWrapper,
  ClearActionWrapper,
} from '../styles';
import { formatRequestDate } from '../utils';
import { SearchFilterProps } from './defs';

interface GlobalSearchFilterProps {
  searchText: string;
  setSearchText: (search: string) => void;
  handleSubmitFilterSearch: () => void;
  handleClearFilterSearch: () => void;
  setFilter: (filter: SearchFilterProps) => void;
  filter: SearchFilterProps;
}

const GlobalSearchFilter = ({
  searchText,
  setSearchText,
  handleSubmitFilterSearch,
  handleClearFilterSearch,
  setFilter,
  filter,
}: GlobalSearchFilterProps) => {
  const onFilterChange = ({ query, value }: { query: string; value: string | Date }): void => {
    setFilter({ ...filter, [query]: formatRequestDate(value) });
  };

  const onFilterRemove = (): void => {};

  return (
    <FilterWrapper>
      <FilterItem>
        <FilterLabel>Has the words...</FilterLabel>
        <FilterTextInput
          type="text"
          placeholder="e.g transfers, withdrawal, terminal id"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          id="global_search_filter_text_input"
        />
      </FilterItem>

      <FilterItem id="global_search_filter_date">
        <FilterLabel>Date</FilterLabel>
        <Filter
          config={[
            {
              name: 'From',
              type: 'date',
              query: 'dateFrom',
              options: [],
              dateFilterType: 'from',
            },
          ]}
          onChange={onFilterChange}
          onRemove={onFilterRemove}
        />
        <Filter
          config={[
            {
              name: 'To',
              type: 'date',
              query: 'dateTo',
              options: [],
              dateFilterType: 'from',
            },
          ]}
          onChange={onFilterChange}
          onRemove={onFilterRemove}
        />
      </FilterItem>
      <FilterActionWrapper>
        <ClearActionWrapper>
          <Button variant="small" onClick={handleClearFilterSearch} id="global_search_filter_clear_filter">
            Clear filter
          </Button>
        </ClearActionWrapper>
        <FilterAction>
          <Button variant="small" onClick={handleSubmitFilterSearch} id="global_search_filter_submit_search">
            Search
          </Button>
        </FilterAction>
      </FilterActionWrapper>
    </FilterWrapper>
  );
};

export default GlobalSearchFilter;
