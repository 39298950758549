/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { PaymentSetup, DashboardSummary, BasicBusinessInformation, BusinessDetails } from '../../types/dashboard';
// ---------- MOCKS ----------//

// ---------------------------   FETCH DASHBOARD SUMMARY START   ---------------------------//

export interface FetchDashboardSummaryResponse extends ApiResponse {
  data?: DashboardSummary;
  dataList?: null;
  meta?: null;
}

export const fetchDashboardSummary = createAsyncThunk(
  '/dashboard/dashboard-summary',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: FetchDashboardSummaryResponse = await axios.get(`/business/v2/dashboard-summary`);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH DASHBOARD SUMMARY END   ---------------------------//

// ---------------------------   FETCH ALL BANKS START   ---------------------------//

export interface FetchAllBanksResponse extends ApiResponse {
  data?: { name: string; code: string }[];
  dataList?: null;
  meta?: null;
}

export const fetchAllBanks = createAsyncThunk(
  '/dashboard/setup/fetch-all-banks',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: FetchAllBanksResponse = await axios.get(`/business/banks`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH ALL BANKS END   ---------------------------//

// ---------------------------   ACCOUNT LOOK UP START   ---------------------------//

export interface AccountLookUpResponse extends ApiResponse {
  data: {
    data: {
      accountName: string;
    };
    status: string;
    successful: boolean;
  };
  dataList?: null;
  meta?: null;
}

export interface AccountLookUpArgs extends ReduxAction {
  accountNumber: string;
  bankCode: string;
}

export const accountLookUp = createAsyncThunk(
  '/dashboard/setup/account-lookup',
  async ({ bankCode, accountNumber, onSuccess, onFailure }: AccountLookUpArgs, { rejectWithValue }) => {
    try {
      const { data }: AccountLookUpResponse = await axios.post(`/business/lookup-account`, { bankCode, accountNumber });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   ACCOUNT LOOK UP END   ---------------------------//

// ---------------------------   BVN VERIFICATION START   ---------------------------//

export interface BvnVerificationResponse extends ApiResponse {
  data?: null;
  message?: string;
  dataList?: null;
  meta?: null;
}

export interface BvnVerificationArgs extends ReduxAction {
  values: {
    firstName: string;
    lastName: string;
    dob: string;
    phone: string;
    bvn: string;
  };
}

export const bvnVerification = createAsyncThunk(
  '/dashboard/setup/bvn-verification',
  async ({ values, onSuccess, onFailure }: BvnVerificationArgs, { rejectWithValue }) => {
    try {
      await axios.post(`/business/check-bvn?bvn=${values?.bvn}`);
      const { data }: BvnVerificationResponse = await axios.post('/business/verify-bvn', values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   BVN VERIFICATION END   ---------------------------//

// ---------------------------   BVN VERIFICATION MIGRATED START   ---------------------------//

export interface BvnVerificationMigratedResponse extends ApiResponse {
  data?: null;
  message?: string;
  dataList?: null;
  meta?: null;
}

export interface BvnVerificationMigratedArgs extends ReduxAction {
  values: {
    firstName: string;
    lastName: string;
    dob: string;
    phone: string;
    bvn: string;
  };
}

export const bvnVerificationMigrated = createAsyncThunk(
  '/dashboard/setup/bvn-verification-migrated',
  async ({ values, onSuccess, onFailure }: BvnVerificationMigratedArgs, { rejectWithValue }) => {
    try {
      await axios.post(`/business/check-bvn?bvn=${values?.bvn}`);
      const { data }: BvnVerificationMigratedResponse = await axios.post('/business/uncreated-user/verify-bvn', values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   BVN VERIFICATION MIGRATED END   ---------------------------//

// ---------------------------   PAYMENT SETUP START   ---------------------------//

export interface PaymentSetupResponse extends ApiResponse {
  data?: Record<string | number, string | number | [] | object | null>;
  dataList?: null;
  meta?: null;
}
export interface PaymentSetupArgs extends ReduxAction {
  values: {
    businessId: string | number;
    paymentSetup: PaymentSetup;
  };
}

export const paymentSetup = createAsyncThunk(
  '/dashboard/update-payment-setup',
  async ({ values, onSuccess, onFailure }: PaymentSetupArgs, { rejectWithValue }) => {
    try {
      const { data }: PaymentSetupResponse = await axios.put(`/business/complete-registration`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   PAYMENT SETUP END   ---------------------------//

// ---------------------------   PAYMENT METHOD UPDATE  START   ---------------------------//

export interface PaymentMethodUpdateResponse extends ApiResponse {
  data?: Record<string | number, string | number | [] | object | null>;
  dataList?: null;
  meta?: null;
}
export interface PaymentMethodUpdateArgs extends ReduxAction {
  values: { paymentSetup: PaymentSetup };
}

export const paymentMethodUpdate = createAsyncThunk(
  '/dashboard/setup/payment-method-update',
  async ({ values, onSuccess, onFailure }: PaymentMethodUpdateArgs, { rejectWithValue }) => {
    try {
      const { data }: PaymentMethodUpdateResponse = await axios.put(
        `/business/setup-settlement-acct`,
        values.paymentSetup.bankDetails
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   PAYMENT METHOD UPDATE END   ---------------------------//

// ---------------------------   ONBOARDING WRAP UP START   ---------------------------//

export interface OnbaordingWrapUpResponse extends ApiResponse {
  data?: Record<string | number, string | number | [] | object | null>;
  dataList?: null;
  meta?: null;
}

export interface OnbaordingWrapUpArgs extends ReduxAction {
  values: {
    basicBusinessInformation: BasicBusinessInformation;
    paymentSetup: {
      bankDetails: {
        bankInfo: {
          code: null;
          name: null;
        };
        accountName: null;
        accountNumber: null;
      };
      businessPaymentOptions: null;
      transactionPin: null;
    };
    businessDetails: BusinessDetails;
    businessId: string | number;
  };
}

export const onbaordingWrapUp = createAsyncThunk(
  '/dashboard/setup/payment-wrapup',
  async ({ values, onSuccess, onFailure }: OnbaordingWrapUpArgs, { rejectWithValue }) => {
    try {
      const { data }: OnbaordingWrapUpResponse = await axios.put(`/business/complete-registration`, values);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   ONBOARDING WRAP UP END   ---------------------------//

// ---------------------------   SETTLEMENT ACCOUNT V2 START   ---------------------------//

export interface SettlementAcountSetupResponse extends ApiResponse {
  data?: Record<string | number, string | number | [] | object | null>;
  dataList?: null;
  meta?: null;
}

export interface SettlementAcountSetupArgs extends ReduxAction {
  values: {
    bankInfo: {
      name: string;
      code: string;
    };
    accountNumber: string;
    accountName: string;
  };
}

export const settlementAcountSetup = createAsyncThunk(
  '/business/settlement-acct-setup',
  async ({ values, onSuccess, onFailure }: SettlementAcountSetupArgs, { rejectWithValue }) => {
    try {
      const { data }: SettlementAcountSetupResponse = await axios.put(`/business/setup-settlement-acct`, values);
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SETTLEMENT ACCOUNT V2 END   ---------------------------//

// ---------------------------   SETTLEMENT ACCOUNT PIN V2 START   ---------------------------//

export interface SettlementAcountPINSetupResponse extends ApiResponse {
  data?: Record<string | number, string | number | [] | object | null>;
  dataList?: null;
  meta?: null;
}

export interface SettlementAcountPINSetupArgs extends ReduxAction {
  values: {
    businessId: string | number | null | undefined;
    newPin: string;
  };
}

export const settlementAcountPINSetup = createAsyncThunk(
  'business/settlement-account-pin',
  async ({ values, onSuccess, onFailure }: SettlementAcountPINSetupArgs, { rejectWithValue }) => {
    try {
      const { data }: SettlementAcountPINSetupResponse = await axios.post(`/business/reset-pin`, values);
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SETTLEMENT ACCOUNT PIN V2 END   ---------------------------//
