export type SupportPages =
  | 'home'
  | 'office-adrress'
  | 'call-line'
  | 'my-issues'
  | 'details'
  | 'summary'
  | 'issue-creation';

export type CreationStage = 'category' | 'type' | 'transactions';

export type ContactType = 'EMAIL' | 'ADDRESS' | 'SOCIAL_MEDIA' | 'PHONE';

export type Contact = {
  contactType: ContactType;
  contactValue: string;
  createdAt: string;
  deletedAt: null | string;
  description: string;
  id: 1;
  updatedAt: null | string;
};

const IssueCategoryExample = {
  category: 'WITHDRAWAL',
  categoryName: 'Withdrawal',
  firstResponseSlaInHours: 0,
  hasChildren: true,
  id: '600abb1d79709c4a46dbca14',
  index: 1,
  noIssues: 1,
  ownerId: null,
  parentIndex: -1,
  resolutionSlaInHours: 24,
  timeCreated: '2021-01-22 11:46:37',
  timeUpdated: '2021-01-22 11:46:37',
  transactionCentric: false,
  transactionTypeKey: 'withdrawal',
  validCustomerTypes: ['USER'],
};

export type IssueCategory = typeof IssueCategoryExample;

const IssueTypeExample = {
  id: '5fb27cd47a113b4230631d15',
  index: 3,
  description: 'Testing the miscellaneous issue creation',
  type: 'CASH_WITHDRAWAL',
  timeCreated: '2020-11-16 13:21:24',
  timeUpdated: '2020-11-16 13:21:24',
  label: 'miscellaneous',
  resolutionSlaInHours: 24,
  firstResponseSlaInHours: 1,
  body: null,
  externalResolverId: null,
  externalResolverName: null,
  prettyName: 'Card Withdrawal',
  typeSlug: 'withdrawal',
  globalIndex: 25,
};

export type IssueType = typeof IssueTypeExample;

export type TransactionFilters = 'date' | 'source' | 'status';

export type TransactionPagination = 'limit' | 'nextCursor';

export type IssueRequestBody = {
  title: string;
  description: string;
  transactionId: string;
  amount: string;
  type: string;
  posTransactionData: {
    transactionDate: string;
    firstSixDigit: string;
    lastFourDigit: string;
    rrn: string;
    terminalId: string;
  };
  transactionTime: string;
  issueId: string;
  contactReasonId: string;
  channel: string;
  senderBank: string;
  transferRoute: string;
  depositorName: string;
  pickUpAddress: string;
  comment: string;
  ownerId: string;
  createdBy: string;
  customerData: {
    customerName: string;
    customerAddress: string;
    customerBank: string;
    customerAccountNumber: string;
    customerPhoneNumber: string;
  };
  tier: string;
  accountType: string;
  transactionDateObject: string;
  imageFiles: string[];
};

export type Issue = {
  id: string;
  agentId: string;
  boxOfficeTicketId: string;
  type: string;
  timeCreated: string;
  transactionId: string;
  issueId: string;
  createdBy: string;
  ticket: {
    id: string;
    title: string;
    description: string;
    status: string;
    comments: [
      {
        author: string;
        text: string;
        isPublic: boolean;
        date: string;
      }
    ];
    images: [
      {
        id: string;
      }
    ];
    meta: Record<string, string>;
    timeCreated: string;
    labels: [string];
    timeUpdated: string;
    rating: {
      point: number;
      comment: string;
      ratingDate: string;
    };
    isTicketRated: boolean;
    ownerId: string;
  };
};

export type FetchedIssue = {
  id: string;
  title: string;
  description: string;
  status: string;
  comments: {
    author: string;
    text: string;
    isPublic: boolean;
    timeCreated: string;
    public: boolean;
  }[];
  images: { id: string }[];
  meta: {
    lastName: string;
    amount: string;
    zohodeskContactId: null;
    agentId: string;
    account_type: string;
    issueId: string;
    lga: string;
    channel: string;
    tx_id: string;
    type: string;
    firstName: string;
    secondaryUid: string;
    tier: string;
    phone: string;
    should_settle_customer: string;
    transfer_route: string;
    state: string;
    email: string;
  };
  timeCreated: string;
  labels: string[];
  timeUpdated: string;
  rating: {
    point: 0;
    comment: null;
    ratingDate: null;
  };
  isTicketRated: false;
  ownerId: string;
  ticketRated: false;
};

// eslint-disable-next-line no-shadow
export enum IssueListFilterStatus {
  RESOLVED = 'RESOLVED',
  UNRESOLVED = 'UNRESOLVED',
}
