import styled from 'styled-components';

export const Subheader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 20px 0px;
  width: 100%;
  margin-bottom: 10px;
`;

export const TransactionDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: ${({ theme }) => theme.nColors['n-yellow1']};
  gap: 10px;
  text-align: center;
`;

export const HeaderIconWrapper = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border: 1px solid ${({ theme }) => theme.nColors['n-yellow5']};
  justify-content: center;
  align-items: center;
`;
export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ContentItem = styled.div<{ direction?: 'row' | 'column'; width?: string; mb?: string }>`
  display: flex;
  flex-direction: ${({ direction = 'column' }) => direction};
  width: ${({ width = '100%' }) => width};
  align-items: flex-start;
  margin-bottom: ${({ mb }) => mb || '10px'};

  p {
    text-align: left;
    margin-bottom: 5px;
  }
`;

export const ContentItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  column-gap: 10px;
  padding: 0 20px;
  @media ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    & > ${ContentItem} {
      :nth-child(odd) {
        width: 48%;
      }
      :nth-child(even) {
        width: 50%;
      }
    }
  }
`;

export const TransactionFooter = styled.div`
  background: ${({ theme }) => theme.colors.primary.white};
  box-shadow: 0px 18px 40px 0px rgba(0, 0, 0, 0.15);
`;

export const IssueBox = styled.div`
  width: 100%;
  padding: 20px 26px;
  border-radius: 5px;
  border: 1px solid #eee;
  display: flex;
  text-align: left;
  align-items: center;
  cursor: pointer;

  .content {
    margin: 0 auto 0 8px;
  }

  p {
    color: #a8a8a8;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .header-text {
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 4px;
  }
`;

export const MainContentWrapper = styled.div<{ withButtons?: boolean; showIssueResolution?: boolean }>`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  margin-bottom: ${({ withButtons = false, showIssueResolution = false }) =>
    withButtons ? '15rem' : showIssueResolution ? '10rem' : '1rem'};
`;

export const FloatingItemsContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: ${({ theme }) => theme.nColors['n-light']};
`;
