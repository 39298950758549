import styled from 'styled-components';

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
    background: #ffffff;
    border-radius: 5px;
    margin: 20px 0;
    padding: 0 14px;
  }
`;

export const DesktopWrapper = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const FilterWrapper = styled.div<{ rows?: number }>`
  display: grid;
  grid-template-columns: ${({ rows }) => (rows ? `repeat(${rows}, 1fr)` : 'repeat(4, 1fr)')};
  grid-gap: 20px;
  margin: 20px 0;
`;

export const FilterHeader = styled.div<{ open?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  svg {
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(-90deg)')};
  }
`;
