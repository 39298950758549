import { Formik } from 'formik';
import TextInputV2 from '../../../../components/inputs';
import Onboardingv3 from '../index';
import FormHeader from './form-header';
import OnboardingStepCounter from './step-counter';
import { ChooseAccountTypeWrapper, ConsentWrapper, FormBodyWrapper } from './styles';
import useCreatePassword from '../models/useCreatePassword';
import { Checkbox, Typography, Button } from 'kudi-component-library';

const CreatePassword = () => {
  const { initialValues, validationSchema, agree, toggleAgree, onSuccess } = useCreatePassword();
  return (
    <Onboardingv3>
      <ChooseAccountTypeWrapper>
        <OnboardingStepCounter step={2} />
        <FormHeader
          title="Create password"
          description={
            <>
              You have successfully verified your phone number. Now, enter your password; and add referral code, if any.
            </>
          }
        />
        <Formik
          initialValues={initialValues}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // eslint-disable-next-line no-console
            console.log(values);
            setSubmitting(true);
            onSuccess();
          }}
        >
          {({ values, handleBlur, handleChange, handleSubmit, isValid, isSubmitting, touched, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormBodyWrapper>
                  <TextInputV2
                    label="Password"
                    placeholder="Password (Min. of 8 characters)"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && errors.password}
                  />
                  <TextInputV2
                    label="Confirm password"
                    type="password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={touched.confirmPassword && errors.confirmPassword}
                  />
                  <TextInputV2
                    label="Referral code (optional)"
                    type="text"
                    name="referralCode"
                    value={values.referralCode}
                    onChange={handleChange}
                  />
                  <ConsentWrapper>
                    <Checkbox name="agree" checked={agree} onChange={toggleAgree} />
                    <Typography>
                      I have read, understood and I agreed to Nomba’s <strong>Privacy policy</strong> and{' '}
                      <strong>Terms and Conditions</strong>.
                    </Typography>
                  </ConsentWrapper>
                  <Button disabled={!isValid || !agree || isSubmitting} width="100%" variant="medium" type="submit">
                    Continue
                  </Button>
                </FormBodyWrapper>
              </form>
            );
          }}
        </Formik>
      </ChooseAccountTypeWrapper>
    </Onboardingv3>
  );
};

export default CreatePassword;
