import { useState, useEffect } from 'react';

const useCountdown = (initialTime: number) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime: number) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  return { timeRemaining, hours, minutes, seconds };
};

export default useCountdown;
