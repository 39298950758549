import { Button, Typography } from 'kudi-component-library';
import { ChevronRight, ChevronUp } from '../../../assets/svg';
import { formatTableDateValue, formatCurrency } from '../../../utils';
// eslint-disable-next-line import/no-cycle
import { Functions, Data, MobileSchema, Schema } from '../index';
import {
  MobileTableItemWrapper,
  MobileTableItemTop,
  MobileTableItemBottom,
  MobileActionsIconContainer,
  MobileTableItemMain,
  MobileTableActionDropdown,
  MobileActionButtonWrapper,
  MobileDetailsWrapper,
  MobileDetailItem,
  UnderLinedButton,
  UnderlinedButtonText,
  OutlinedButton,
} from '../styles';
import useDropdown from '../../../hooks/useDropdown';
import { ActionsProp } from '../defs';

const MobileTableItem = ({
  functions,
  onRowCLick,
  rowData,
  schema,
  actions,
  gridActions,
  mobileDetailsSchema,
}: {
  functions: Functions;
  onRowCLick?: (value: Data) => void;
  rowData: Record<string, string>;
  schema?: MobileSchema;
  actions?: ActionsProp[];
  gridActions?: boolean;
  mobileDetailsSchema?: Schema[];
}) => {
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();
  const generateValue = (section: 'lt' | 'lb' | 'rb' | 'rt' | 'mb') => {
    switch (true) {
      case schema && schema?.[section] && !!schema?.[section]?.render:
        return schema?.[section]?.render?.(rowData, functions);
      case schema &&
        schema?.[section] &&
        !schema?.[section]?.render &&
        !schema?.[section]?.isDate &&
        !!schema?.[section]?.accessor:
        return rowData?.[schema?.[section]?.accessor as string];
      case schema &&
        schema?.[section] &&
        !schema?.[section]?.render &&
        !!schema?.[section]?.isDate &&
        !!schema?.[section]?.accessor:
        return formatTableDateValue(rowData?.[schema?.[section]?.accessor as string] as unknown as Date);

      default:
        return '';
    }
  };
  return (
    <>
      <MobileTableItemWrapper
        onClick={() => {
          if (onRowCLick) {
            onRowCLick(rowData);
          }
          if (actions) {
            setShowDropdown((state) => !state);
          }
        }}
        ref={dropdownButtonRef}
        id="newtable_mobile_item"
      >
        <MobileTableItemMain>
          <MobileTableItemTop>
            <Typography variant="n-text2" color={!generateValue('mb') ? 'grey4' : 'dark'}>
              {generateValue('lt')}
            </Typography>
            <Typography variant="n-text2">{generateValue('rt')}</Typography>
          </MobileTableItemTop>
          <MobileTableItemBottom>
            <Typography variant="n-text0" color={generateValue('mb') ? 'grey4' : 'dark'}>
              {generateValue('lb')}
            </Typography>
            <Typography variant="n-text0" color={generateValue('mb') ? 'grey4' : 'dark'}>
              {generateValue('mb')}
            </Typography>
            <Typography variant="n-text0" color={generateValue('mb') ? 'grey4' : 'dark'}>
              {generateValue('rb')}
            </Typography>
          </MobileTableItemBottom>
        </MobileTableItemMain>
        {actions ? (
          <MobileActionsIconContainer>{showDropdown ? <ChevronUp /> : <ChevronRight />}</MobileActionsIconContainer>
        ) : null}
      </MobileTableItemWrapper>
      {showDropdown ? (
        <>
          <MobileDetailsWrapper>
            {mobileDetailsSchema &&
              mobileDetailsSchema.map((item) => (
                <MobileDetailItem id="mobile-detail-item" key={`mobile-details-${Math.random()}`}>
                  <Typography color="grey3" variant="n-text2">
                    {item.text}
                  </Typography>
                  <Typography variant="n-text2">
                    {!item.render && !item.isDate && !item.isCurrency && item?.accessor
                      ? rowData[item?.accessor]
                      : null}
                    {!item.render && item.isDate && !item.isCurrency && item?.accessor
                      ? formatTableDateValue(rowData[item?.accessor] as unknown as Date)
                      : null}
                    {!item.render && !item.isDate && item.isCurrency && item?.accessor
                      ? formatCurrency(rowData[item?.accessor] || '0')
                      : null}
                    {item.render ? item.render(rowData, functions) : null}
                  </Typography>
                </MobileDetailItem>
              ))}
          </MobileDetailsWrapper>
          <MobileTableActionDropdown ref={dropdownRef} gridActions={gridActions} id="newtable_mobile_actions_dropdown">
            {actions &&
              actions.map((action: ActionsProp) =>
                action.restrict && action.restrict.values?.includes(rowData[action.restrict.property]) ? null : (
                  <MobileActionButtonWrapper
                    key={`mobile_table_action_custom_button_${Math.random()}`}
                    mobileButtonTextColor={action?.mobileButtonTextColor}
                  >
                    {action.customButtonType ? (
                      <>
                        {action.customButtonType.type === 'underlined' ? (
                          <UnderLinedButton>
                            {action.customButtonType?.icon ? action.customButtonType?.icon : null}
                            <UnderlinedButtonText variant="n-text2" onClick={() => action.onClickAction(rowData)}>
                              {action.text}
                            </UnderlinedButtonText>
                          </UnderLinedButton>
                        ) : null}
                        {action.customButtonType.type === 'outlined' ? (
                          <OutlinedButton
                            variant="medium"
                            color="primary"
                            onClick={() => action.onClickAction(rowData)}
                          >
                            {action.text}
                          </OutlinedButton>
                        ) : null}
                      </>
                    ) : (
                      <Button
                        variant={action.mobileButtonVariant ? action.mobileButtonVariant : 'small'}
                        color={action.mobileButtonColor ? action.mobileButtonColor : 'primary'}
                        onClick={() => action.onClickAction(rowData)}
                        key={`mobile_table_action_button_${Math.random()}`}
                      >
                        {action.text}
                      </Button>
                    )}
                  </MobileActionButtonWrapper>
                )
              )}
          </MobileTableActionDropdown>
        </>
      ) : null}
    </>
  );
};

export default MobileTableItem;
