/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, CSSProperties } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import CurrencyInput from 'react-currency-input-field';
import { Button, OtpInput, Text, NTheme as theme } from 'kudi-component-library';
import Modal from '../../../components/Modal';
import {
  ActionModalDescription,
  ActionModalTitle,
  ActionModalWrapper,
  TransferWrapper,
  TransferValue,
  ModalBackButton,
  Separator,
  InfoModalWrapper,
  InfoModalTitle,
  InfoModalDescription,
} from '../styles';
import { WithdrawalIcon } from '../../../assets/svg';
import { initiateWithdrawal } from '../../../features/banking/balance/slice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { formatCurrency } from '../../../utils';
import { useToast } from '../../../hooks/useToast';
import { ErrorText } from '../../terminals/styles';

interface InitialValues {
  amount: string;
  transactionPin: string;
}

const amountFieldStyles: CSSProperties = {
  width: '100%',
  height: '45px',
  border: `1px solid ${theme.colors.primary.green5}`,
  borderRadius: '4px',
  outline: 'none',
  fontSize: '14px',
  padding: '0 12px',
};

const WithdrawalModal = ({
  onClose,
  showModal,
  refresh,
}: {
  onClose: () => void;
  showModal: boolean;
  refresh: () => void;
}) => {
  const dispatch = useAppDispatch();
  const toast = useToast(3000);

  const [step, setStep] = useState<number>(1);
  const [withdrawalAmount, setWithdrawalAmount] = useState<number>(0);
  const WITHDRAWAL_FEE = 50;

  const { withdrawalAccounts, availableBalance, PND } = useAppSelector((state) => state.banking.balance);

  const MAX_WITHDRAWABLE_SUM = availableBalance - WITHDRAWAL_FEE;

  const initialValues: InitialValues = {
    amount: '',
    transactionPin: '',
  };

  const withdrawalSchema = Yup.object().shape({
    transactionPin: Yup.string().length(4, 'check pin').required('Pin is required'),
  });

  const handleInitaiateWithdrawal = ({ transactionPin }: InitialValues, setSubmitting: (data: boolean) => void) => {
    if (!withdrawalAccounts?.accountName || !withdrawalAccounts.accountNumber) {
      return;
    }
    const onSuccess = () => {
      setSubmitting(false);
      refresh();
      setStep(3);
    };

    const onFailure = (error: { data: { message: string } }) => {
      setSubmitting(false);
      toast('error', error?.data?.message || 'Something went wrong');
    };

    // Check PND for user
    if (!PND?.data) {
      dispatch(
        initiateWithdrawal({
          values: {
            amount: withdrawalAmount,
            transactionPin,
          },
          onSuccess,
          onFailure,
        })
      );
    } else {
      toast('error', 'There is a debit restriction on your account. Please contact support.');
    }
  };

  const closeFunction = () => {
    setStep(1);
    setWithdrawalAmount(0);
    onClose();
  };

  return (
    <Modal showModal={showModal} onClose={closeFunction}>
      {step < 3 ? (
        <ActionModalWrapper>
          <ActionModalTitle variant="n-text5" color="dark">
            Withdraw from account
          </ActionModalTitle>
          <ActionModalDescription variant="n-text2" color="grey4">
            Withdraw from your account into a settlement account
          </ActionModalDescription>

          {step === 1 ? (
            <>
              <TransferWrapper>
                <span>Bank</span>
                <TransferValue>{withdrawalAccounts?.bankName}</TransferValue>
                <span>Account number</span>
                <TransferValue>
                  <span>{withdrawalAccounts?.accountNumber} </span>
                </TransferValue>
                <span>Account name</span>
                <TransferValue>{withdrawalAccounts?.accountName}</TransferValue>
              </TransferWrapper>

              <Button
                variant="medium"
                width="100%"
                onClick={() => {
                  setStep(2);
                  setWithdrawalAmount(0);
                }}
              >
                Next
              </Button>
            </>
          ) : null}

          {step === 2 ? (
            <>
              <TransferWrapper mb="1">
                <span>Amount</span>
                <TransferValue>{formatCurrency(withdrawalAmount || 0)}</TransferValue>
                <span>Charge</span>
                <TransferValue>{formatCurrency(WITHDRAWAL_FEE)}</TransferValue>
              </TransferWrapper>

              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => handleInitaiateWithdrawal(values, setSubmitting)}
                validationSchema={withdrawalSchema}
              >
                {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                  <>
                    <Text fontSize="12px" color={theme.colors.primary.black2} mb="4px" style={{ width: '100%' }}>
                      Amount
                    </Text>
                    <CurrencyInput
                      id="modal-amount-input"
                      name="input-amount"
                      placeholder={!values.amount ? `${formatCurrency(0)}` : `${formatCurrency(values.amount)}`}
                      prefix="₦"
                      decimalsLimit={2}
                      onValueChange={(value) => {
                        setWithdrawalAmount(Number(value));
                      }}
                      width="100%"
                      style={amountFieldStyles}
                    />

                    <Separator height="2rem" />

                    <OtpInput
                      inputsNumber={4}
                      name="transactionPin"
                      label="Enter your PIN"
                      value={values.transactionPin}
                      onChange={handleChange}
                      error={touched.transactionPin && errors.transactionPin ? errors.transactionPin : undefined}
                      disabled={isSubmitting}
                      width="100%"
                    />
                    <Separator height="4rem" />

                    {withdrawalAmount > 0 && withdrawalAmount > MAX_WITHDRAWABLE_SUM ? (
                      <ErrorText>insufficient balance</ErrorText>
                    ) : null}
                    {withdrawalAmount > 0 && withdrawalAmount < 100 ? (
                      <ErrorText>Minimum amount to transfer is ₦100</ErrorText>
                    ) : null}

                    <Button
                      loading={isSubmitting}
                      disabled={
                        isSubmitting ||
                        !withdrawalAmount ||
                        withdrawalAmount > MAX_WITHDRAWABLE_SUM ||
                        withdrawalAmount < 100
                      }
                      variant="small"
                      width="100%"
                      onClick={() => handleSubmit()}
                    >
                      Confirm
                    </Button>
                  </>
                )}
              </Formik>

              <Separator height="1.7rem" />
              <ModalBackButton onClick={() => setStep(1)}>← Go Back</ModalBackButton>
            </>
          ) : null}
        </ActionModalWrapper>
      ) : null}

      {step === 3 ? (
        <InfoModalWrapper>
          <WithdrawalIcon />
          <InfoModalTitle>Withdraw successful</InfoModalTitle>
          <InfoModalDescription>You have successfully made a withdrawal from your account </InfoModalDescription>

          <Button
            width="100%"
            onClick={() => {
              closeFunction();
            }}
            variant="large"
            height="5rem"
          >
            Continue
          </Button>
          <Separator height="2rem" />
        </InfoModalWrapper>
      ) : null}
    </Modal>
  );
};

export default WithdrawalModal;
