/* eslint-disable no-nested-ternary */
import { Typography } from 'kudi-component-library';
import { Schema } from '../../../components/newTable';
import { formatCurrency, formatDateWithTime } from '../../../utils';
import { TransferList } from '../../../types/transactions';
import ShortText from '../../../components/ShortText';

export const TransferSchema: Schema[] = [
  {
    text: 'Date',
    accessor: 'time',
    render: (data: TransferList) => (
      <Typography style={{ cursor: 'pointer' }} color="n-dark" fontSize="14px">
        {formatDateWithTime(new Date(data?.time))}
      </Typography>
    ),
  },
  {
    text: 'Account name',
    accessor: 'account',
    render: (data: TransferList) => (
      <Typography color="n-dark" fontSize="14px">
        {data?.beneficiary}
      </Typography>
    ),
  },
  {
    text: 'Account number',
    accessor: 'accountNumber',
    render: (data: TransferList) => (
      <Typography color="n-dark" fontSize="14px">
        {data?.accountNumber}
      </Typography>
    ),
  },
  {
    text: 'Reference',
    accessor: 'reference',
    render: (data: TransferList) => (data?.reference ? <ShortText text={String(data?.reference)} withCopy /> : null),
    hasCopyIcon: true,
  },
  {
    text: 'Amount',
    accessor: 'amount',
    isCurrency: true,
    render: (data: TransferList) => (
      <Typography color="n-dark" fontSize="14px">
        {formatCurrency(Number(data?.amount))}
      </Typography>
    ),
  },
  {
    text: 'Status',
    accessor: 'status',
    render: (data: TransferList) => {
      const textColor = data?.status === 'SUCCESS' ? 'n-green' : 'n-red';
      return (
        <Typography color={textColor} fontSize="14px">
          {data?.status}
        </Typography>
      );
    },
  },
];
