import { createContext, useContext } from 'react';
import { NIcons, IconsEnum } from 'kudi-component-library';
import { NavigationSectionInterface } from '../../types/navigation';
import {
  ModalCustomersIcon,
  ModalInventoryIcon,
  ModalInvoiceIcon,
  ModalOutletsIcon,
  ModalOverviewIcon,
  ModalPaymentIcon,
  ModalReferralsIcon,
  ModalTeamsIcon,
  ModalTransactionIcon,
  ModalMobileOverviewIcon,
  ModalMobilePaymentIcon,
  ModalMobileReferralsIcon,
  ModalMobileInventoryIcon,
  ModalMobileOutletsIcon,
  ModalMobileTeamsIcon,
  ModalMobileCustomersIcon,
  ModalMobileInvoiceIcon,
  ModalMobileTransactionIcon,
} from '../../assets/svg';
import { GuestNavigationMenuOptionGroup } from '../../types/guestNavigation';

const returnNavigationIcon = (iconName: IconsEnum) => () => NIcons({ icon: iconName, height: '20', width: '20' });

export const overviewRoutes = {
  home: 'overview',
};

export const paymentRoutes = {
  accept: 'accept',
  make: 'make',
};

// Banking routes
export const bankingRoutes = {
  transactions: 'transactions',
};

export const businessRoutes = {
  outlets: 'outlets',
  teams: 'teams',
  inventory: 'inventory',
  referral: 'referral',
};

export const controlRoutes = {
  referral: 'referral',
};

export const invoicingRoutes = {
  customers: 'customers',
  invoices: 'invoices',
};

export const base: Record<string, { name: string; value: string; routes: Record<string, string> }> = {
  overview: { name: 'Overview', value: 'overview', routes: overviewRoutes },
  payments: { name: 'Payments', value: 'payments', routes: paymentRoutes },
  banking: { name: 'Banking', value: 'banking', routes: bankingRoutes },
  invoicing: { name: 'Invoicing', value: 'invoicing', routes: invoicingRoutes },
  business: { name: 'Tools', value: 'business', routes: businessRoutes },
  control: { name: 'Control', value: 'control', routes: controlRoutes },
};

export const navigationConfig: NavigationSectionInterface[] = [
  { baseRoute: base.overview.value, 
    data: [{ name: 'Overview', route: overviewRoutes.home, Icon: returnNavigationIcon(IconsEnum.OUTLINE_HOME)}] 
  },

  {
    title: base.payments.name,
    baseRoute: base.payments.value,
    data: [
      {
        name: 'Accept Payments',
        route: paymentRoutes.accept,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_DOWN),
      },
    ],
  },

  {
    title: 'Banking',
    baseRoute: base.banking.value,
    data: [
      {
        name: 'Transactions',
        route: bankingRoutes.transactions,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_PAGES),
      },
    ],
  },

  {
    title: base.invoicing.name,
    baseRoute: base.invoicing.value,
    data: [
      {
        name: 'Customers',
        route: invoicingRoutes.customers,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_AUDIENCE_2),
      },
      {
        name: 'Invoices',
        route: invoicingRoutes.invoices,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_AUDIENCE_2),
      },
    ],
  },

  {
    title: base.business.name,
    baseRoute: base.business.value,
    data: [
      {
        name: 'Outlets',
        route: businessRoutes.outlets,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_SHOP_1),
      },
      {
        name: 'Teams',
        route: businessRoutes.teams,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_AUDIENCE_2),
      },
      {
        name: 'Inventory',
        route: businessRoutes.inventory,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_STATS),
      },
      {
        name: 'Referrals',
        route: businessRoutes.referral,
        Icon: returnNavigationIcon(IconsEnum.OUTLINE_GIFT),
      },
    ],
  },
];

export const MODAL_DATA: GuestNavigationMenuOptionGroup = {
  accept: {
    title: 'Accept payments',
    subText:
      'Get paid faster and more securely with our Terminal and QR code payment options. Sign up now to start accepting payments!',
    image: <ModalPaymentIcon />,
    mobileImage: <ModalMobilePaymentIcon />,
  },
  referral: {
    title: 'Refferals',
    subText:
      'Help others discover our platform and earn rewards for doing so with our Referrals feature. Sign up today to start referring your friends and colleagues!',
    image: <ModalReferralsIcon />,
    mobileImage: <ModalMobileReferralsIcon />,
  },
  inventory: {
    title: 'Inventory',
    subText:
      'Optimize your supply chain and reduce costs with our easy-to-use Inventory feature. Sign up today to start managing your inventory more effectively!',
    image: <ModalInventoryIcon />,
    mobileImage: <ModalMobileInventoryIcon />,
  },
  outlets: {
    title: 'My Outlets',
    subText:
      'Streamline your business operations and manage all your branches in one place with our powerful My Outlet feature. Sign up now to get started!',
    image: <ModalOutletsIcon />,
    mobileImage: <ModalMobileOutletsIcon />,
  },
  teams: {
    title: 'My teams',
    subText:
      'Collaborate more efficiently and improve team productivity with our My Teams feature. Sign up now to start managing your teams!',
    image: <ModalTeamsIcon />,
    mobileImage: <ModalMobileTeamsIcon />,
  },
  customers: {
    title: 'Customers',
    subText:
      'Create your customers, provide personalised support, and gain valuable insights into their needs and preferences.',
    image: <ModalCustomersIcon />,
    mobileImage: <ModalMobileCustomersIcon />,
  },
  invoices: {
    title: 'Invoices',
    subText:
      'Streamline your billing process and get paid faster with our invoice feature. Sign up now to start creating invoices!',
    image: <ModalInvoiceIcon />,
    mobileImage: <ModalMobileInvoiceIcon />,
  },
  transactions: {
    title: 'Transaction',
    subText:
      'Manage your transactions with ease and accuracy - sign up now to see how our transaction feature can help you optimize your financial operations.',
    image: <ModalTransactionIcon />,
    mobileImage: <ModalMobileTransactionIcon />,
  },
  overview: {
    title: 'Overview',
    subText:
      "Get a comprehensive look at your business performance with Nomba's overview page feature. Sign up now and start using it to drive growth!",
    image: <ModalOverviewIcon />,
    mobileImage: <ModalMobileOverviewIcon />,
  },
};

// passing setOpen around navigation component
export interface NavigationProps {
  setOpen: (value: boolean) => void;
}
export const NavigationContext = createContext<NavigationProps>({ setOpen: () => {} });

export const useNavigationContext = () => useContext(NavigationContext);
