import { createContext, useContext } from 'react';

import { NavigationSectionInterface } from '../../types/navigation';
import { IS_SERVICE_STATUS_ON } from '../../utils/pages/serviceStatus';

import Pos from './inproduct-icons/pos';
import PaymentLinks from './inproduct-icons/payment-links';
import Transactions from './inproduct-icons/transactions';
import MakePaymentTransfer from './inproduct-icons/make-payment-transfer';
import Disputes from './inproduct-icons/disputes';
import Settlement from './inproduct-icons/settlement';
import Outlets from './inproduct-icons/outlets';
import Customers from './inproduct-icons/customers';
import Menu from './inproduct-icons/menu';
import AtmCard from './inproduct-icons/atm-card';
import Settings from './inproduct-icons/settings';
import Referral from './inproduct-icons/referral';
import Overview from './inproduct-icons/overview';

const isActiveRoute = (route: string) => {
  const paths = window.location.pathname.split('/');
  if (route === 'overview') {
    return paths[2] === route;
  }
  return paths[3] === route;
};

export const overviewRoutes = {
  home: '',
};

export const paymentRoutes = {
  terminals: 'terminals',
  payment_links: 'payment-links',
  transfer: 'transfer',
  settlements: 'settlements',
  transactions: 'transactions',
  disputes: 'disputes',
};

// Banking routes
export const bankingRoutes = {
  transactions: 'transactions',
  settlements: 'settlements',
  disputes: 'disputes',
  payment_links: 'payment-links',
  savings: 'savings',
  loans: 'loans',
};

export const businessRoutes = {
  outlets: 'outlets',
  teams: 'teams',
  menu: 'menu',
  inventory: 'inventory',
  referral: 'referral',
  card_performance: 'card-bank-performance',
};

export const loanProviderRoutes = {
  overview: 'overview',
  transactions: 'transactions',
  history: 'history',
};

export const controlRoutes = {
  settings: 'settings',
  referral: 'referral',
  notifications: 'notifications',
};

export const invoicingRoutes = {
  customers: 'customers',
  invoices: 'invoices',
};

export const base: Record<string, { name: string; value: string; routes: Record<string, string> }> = {
  overview: { name: 'Overview', value: 'overview', routes: overviewRoutes },
  payments: { name: 'Payments', value: 'payments', routes: paymentRoutes },
  banking: { name: 'Banking', value: 'banking', routes: bankingRoutes },
  invoicing: { name: 'Invoicing', value: 'invoicing', routes: invoicingRoutes },
  business: { name: 'Tools', value: 'business', routes: businessRoutes },
  loanProvider: {
    name: 'LOAN PROVIDER',
    value: 'loan-provider',
    routes: loanProviderRoutes,
  },
  control: { name: 'Control', value: 'control', routes: controlRoutes },
};

export const navigationConfig: NavigationSectionInterface[] = [
  {
    baseRoute: base.overview.value,
    data: [
      {
        name: 'Overview',
        route: overviewRoutes.home,
        Icon: () => Overview({ active: isActiveRoute('overview') }),
      },
    ],
  },

  {
    title: base.payments.name,
    baseRoute: base.payments.value,
    data: [
      {
        name: 'Terminals',
        route: paymentRoutes.terminals,
        Icon: () => Pos({ active: isActiveRoute(paymentRoutes.terminals) }),
      },
      {
        name: 'Payment links',
        route: paymentRoutes.payment_links,
        Icon: () => PaymentLinks({ active: isActiveRoute(paymentRoutes.payment_links) }),
      },
      {
        name: 'Transfer',
        route: paymentRoutes.transfer,
        Icon: () => MakePaymentTransfer({ active: isActiveRoute(paymentRoutes.transfer) }),
      },
      {
        name: 'Settlements',
        route: paymentRoutes.settlements,
        Icon: () => Settlement({ active: isActiveRoute(paymentRoutes.settlements) }),
      },
      {
        name: 'Transactions history',
        route: paymentRoutes.transactions,
        Icon: () => Transactions({ active: isActiveRoute(paymentRoutes.transactions) }),
      },
      {
        name: 'Disputes',
        route: paymentRoutes.disputes,
        Icon: () => Disputes({ active: isActiveRoute(paymentRoutes.disputes) }),
      },
    ],
  },

  {
    title: base.business.name,
    baseRoute: base.business.value,
    data: [
      {
        name: 'Outlets',
        route: businessRoutes.outlets,
        Icon: () => Outlets({ active: isActiveRoute(businessRoutes.outlets) }),
      },
      {
        name: 'Team',
        route: businessRoutes.teams,
        Icon: () => Customers({ active: isActiveRoute(businessRoutes.teams) }),
      },
      { name: 'Menu', route: businessRoutes.menu, Icon: () => Menu({ active: isActiveRoute(businessRoutes.menu) }) },
      ...(IS_SERVICE_STATUS_ON
        ? [
            {
              name: 'Card Performance',
              route: businessRoutes.card_performance,
              Icon: () => AtmCard({ active: isActiveRoute(businessRoutes.card_performance) }),
            },
          ]
        : []),
      {
        name: 'Referrals',
        route: businessRoutes.referral,
        Icon: () => Referral({ active: isActiveRoute(businessRoutes.referral) }),
      },
    ],
  },
];

export const navigationFooterConfig: NavigationSectionInterface = {
  baseRoute: base.control.value,
  data: [
    {
      name: 'Settings',
      route: controlRoutes.settings,
      Icon: () => Settings({ active: isActiveRoute(controlRoutes.settings) }),
    },
  ],
};

// passing setOpen around navigation component
export interface NavigationProps {
  setOpen: (value: boolean) => void;
}
export const NavigationContext = createContext<NavigationProps>({ setOpen: () => {} });

export const useNavigationContext = () => useContext(NavigationContext);
