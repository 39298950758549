import { Category } from '../../../types/inventory/category';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface AddCategoryResponse extends ApiResponse {
  data: Record<string, Array<Category>>;
}

const addCategory = async ({
  name,
  description,
  storeId,
  outletId,
}: {
  name: string;
  description: string;
  storeId: string;
  outletId?: string;
}) => {
  try {
    const result: AddCategoryResponse = await axios.post(
      '/webbe/v1/storehouse/stores/categories',
      {
        name,
        description,
        storeId,
      },
      { headers: { ...(outletId && { outletId }) } }
    );
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default addCategory;
