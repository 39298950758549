import { Button, Pagination, Typography } from 'kudi-component-library';
import { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';
import Modal from '../../../../components/Modal';
import { FullBox } from '../../../../styles/generic';
import { CreateNewMealButton, SelectMealsToAddWrapper } from '../../styles';
import Table from '../../../../components/newTable';
import { MenuTableMobileSchema, SelectMenuTableSchema } from '../schema';
import { EmptyMenuTableState } from './EmptyMenuState';
import { Mixpanel } from '../../../../hooks/useMixpanel';
import { getUserBrowser } from '../../../../utils';
import { useAppSelector } from '../../../../app/hooks';
import { MenuListProduct } from '../../../../types/menu/menuList';

interface SelectItemsToAddModalInterface {
  showModal: boolean;
  onClose: () => void;
  createNewMeal: () => void;
  addListToMenu: (list: number[]) => void;
  products: MenuListProduct[] | undefined;
  handleFetchProducts: () => Promise<void>;
  isLoading: boolean;
}

const SelectedItemsCount = styled.span`
  color: ${({ theme }) => theme.nColors['n-light']};
  padding: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  background: ${({ theme }) => theme.nColors['n-dark']};
  border-radius: 50%;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function SelectItemsToAddModal({
  showModal,
  onClose,
  createNewMeal,
  addListToMenu,
  products,
  handleFetchProducts,
  isLoading,
}: SelectItemsToAddModalInterface) {
  const [selectedList, setSelectedList] = useState<number[]>([]);

  const { fullUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    handleFetchProducts();
    Mixpanel.track('MENU_OPEN_ADD_ITEMS_FROM_INVENTORY_POPUP', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
    });
  }, []);

  return (
    <Modal
      customDesktopWidth="90%"
      customTabletWidth="65rem"
      modalContentStyles={{ padding: 0 }}
      showModal={showModal}
      onClose={onClose}
    >
      <SelectMealsToAddWrapper>
        <Flex justifyContent="space-between" mb="30px">
          <Typography color="n-dark" variant="n-text5" fontWeight={600} fontSize="14px">
            Select items to add
          </Typography>
          <Flex>
            <CreateNewMealButton onClick={() => createNewMeal()} variant="small" color="secondary">
              Create New meal
            </CreateNewMealButton>
            <Button
              disabled={selectedList.length === 0}
              loading={isLoading}
              onClick={() => {
                addListToMenu(selectedList);
                onClose();
              }}
              variant="small"
            >
              <Flex>
                Add list to menu{' '}
                {selectedList.length > 0 ? <SelectedItemsCount>{selectedList.length}</SelectedItemsCount> : null}
              </Flex>
            </Button>
          </Flex>
        </Flex>
        <FullBox>
          <Flex mb="20px">
            <Table
              schema={SelectMenuTableSchema({ data: products, selected: selectedList, setSelected: setSelectedList })}
              mobileSchema={MenuTableMobileSchema}
              data={products}
              isLoading={isLoading}
              EmptyState={<EmptyMenuTableState />}
            />
          </Flex>
          <Pagination handlePaginationChange={() => {}} isLastPage={false} />
        </FullBox>
      </SelectMealsToAddWrapper>
    </Modal>
  );
}
