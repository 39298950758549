/* eslint-disable no-nested-ternary */
import { Typography } from 'kudi-component-library';
import { Schema } from '../../../components/newTable';
import { formatCurrency, formatDateWithTime } from '../../../utils';
import { TransferRequestData } from './utils';
import ShortText from '../../../components/ShortText';

export const TransferRequestSchema: Schema[] = [
  {
    text: 'Date',
    accessor: 'createdAt',
    render: (data: TransferRequestData) => (
      <Typography style={{ cursor: 'pointer' }} color="n-dark" fontSize="14px">
        {formatDateWithTime(new Date(data?.createdAt))}
      </Typography>
    ),
  },
  {
    text: 'Account number',
    accessor: 'account',
    isCurrency: true,
    render: ({ account }) => (
      <Typography color="n-dark" fontSize="14px">
        {account}
      </Typography>
    ),
  },
  {
    text: 'Bank',
    accessor: 'bankName',
    render: ({ bankName }) => (
      <Typography color="n-dark" fontSize="14px">
        {bankName}
      </Typography>
    ),
  },
  {
    text: 'Account name',
    accessor: 'beneficiaryName',
    render: ({ beneficiaryName }) => (
      <Typography color="n-dark" fontSize="14px">
        {beneficiaryName}
      </Typography>
    ),
  },
  {
    text: 'Reference ID',
    accessor: 'id',
    hasCopyIcon: true,
    render: (data: TransferRequestData) => (data?.id ? <ShortText text={String(data?.id)} withCopy /> : null),
  },
  {
    text: 'Amount',
    accessor: 'amount',
    render: ({ amount }) => (
      <Typography color="n-dark" fontSize="14px">
        {formatCurrency(Number(amount))}
      </Typography>
    ),
  },
  {
    text: 'Initiator',
    accessor: 'submittedBy',
    render: (data: TransferRequestData) => {
      const fullName = `${data?.submittedBy?.firstName} ${data?.submittedBy?.lastName}` || `N/A`;
      return (
        <Typography color="n-dark" fontSize="14px">
          {fullName}
        </Typography>
      );
    },
  },
  {
    text: 'Status',
    accessor: 'paymentRequestStatus',
    render: ({ paymentRequestStatus }) => {
      const textColor =
        paymentRequestStatus === 'APPROVED'
          ? 'n-green'
          : paymentRequestStatus === 'REJECTED' || paymentRequestStatus === 'EXPIRED'
          ? 'n-red'
          : 'n-grey3';
      const statusText =
        paymentRequestStatus === 'APPROVED'
          ? 'Approved'
          : paymentRequestStatus === 'REJECTED'
          ? 'Rejected'
          : paymentRequestStatus === 'EXPIRED'
          ? 'Request Expired'
          : 'Pending Approval';
      return (
        <Typography color={textColor} fontSize="14px">
          {statusText}
        </Typography>
      );
    },
  },
];
