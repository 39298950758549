import { Viewer, Admin, Upload, OnboardingPerson, OnboardingBank, OnboardingBusiness } from '../assets/svg';
import { DASHBOARD_ROUTE, SETUP_BUSINESS_ROUTE, SETUP_PAYMENT_ROUTE } from '../router/routes';

export const dashboardstepsOld = [
  {
    id: 1,
    title: 'Activate your account',
    description: 'Your Nomba account is active and your business has been onboarded',
    Icon: <OnboardingPerson />,
    isActive: true,
    path: DASHBOARD_ROUTE,
  },

  {
    id: 2,
    title: 'Set payment details',
    description: 'Decide how you will receive payments with Nomba',
    Icon: <OnboardingBank />,
    path: SETUP_PAYMENT_ROUTE,
  },
  {
    id: 3,
    title: 'Complete your business set-up',
    description: 'Tell us more about your business so we can get you up and running',
    Icon: <OnboardingBusiness />,
    path: SETUP_BUSINESS_ROUTE,
  },
];

export const dashboardsteps = [
  {
    id: 2,
    title: 'Complete your business set-up',
    description: 'Tell us more about your business so we can get you up and running',
    Icon: <OnboardingBusiness />,
    path: SETUP_BUSINESS_ROUTE,
  },
  {
    id: 1,
    title: 'Set payment details',
    description: 'Decide how you will receive payments with Nomba',
    Icon: <OnboardingBank />,
    path: SETUP_PAYMENT_ROUTE,
  },
  {
    id: 3,
    title: 'Upgrade KYC Level',
    description: 'Tell us more about your business to increase your transaction limits',
    Icon: <OnboardingBusiness />,
    path: SETUP_PAYMENT_ROUTE,
  },
];

export const dashboardPermission = [
  {
    id: 1,
    title: 'Viewer',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit',
    isActive: true,
    Icon: <Viewer />,
  },
  {
    id: 2,
    title: 'Admin',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit',
    Icon: <Admin />,
  },
  {
    id: 3,
    title: 'Create a new permission',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit',
    Icon: <Upload />,
  },
];

export const businessCategoryTags = [
  {
    id: 1,
    title: 'Sole Proprietor',
    value: 'SOLE_PROPRIETOR',
  },
  {
    id: 2,
    title: 'Private Limited Liability Company',
    value: 'PRIVATE_LIMITED_COMPANY',
  },
  {
    id: 3,
    title: 'Registered Partnerships',
    value: 'REGISTERED_PARTNERSHIP',
  },
  {
    id: 4,
    title: 'Incorporated Trustees',
    value: 'INCORPORATED_TRUSTEES',
  },
];

export const businessTypeTags = [
  {
    id: 1,
    title: 'Agents',
  },
  {
    id: 2,
    title: 'Fast Food Restaurants',
  },
  {
    id: 3,
    title: 'Pharmacies',
  },
  {
    id: 4,
    title: 'Health and Beauty Spas',
  },
  {
    id: 5,
    title: 'Retail Merchants',
  },
  {
    id: 6,
    title: 'Barber and Beauty Shops',
  },
  {
    id: 7,
    title: 'Electrical Parts and Equipment',
  },
  {
    id: 8,
    title: 'Dry Cleaners',
  },
  {
    id: 9,
    title: 'Stationary/Office Supplies',
  },
  {
    id: 10,
    title: 'Paints, Varnishes, and Supplies',
  },
  {
    id: 11,
    title: 'Cleaning Services',
  },
  {
    id: 12,
    title: 'Food Delivery Businesses',
  },
];

export const businessTypeTags2 = [
  {
    id: 1,
    title: 'Legal Services',
  },
  {
    id: 2,
    title: 'Charities and Social Service',
  },
  {
    id: 3,
    title: 'Fuel Station',
  },
  {
    id: 4,
    title: 'Wholesale Merchants',
  },
  {
    id: 5,
    title: 'Hotels, Motels, and Resorts',
  },
  {
    id: 6,
    title: 'Pharmacies',
  },
  {
    id: 7,
    title: 'Media & Advertising',
  },
  {
    id: 8,
    title: 'Hospitals',
  },
  {
    id: 9,
    title: 'School',
  },
  {
    id: 10,
    title: 'Travel Agency',
  },
  {
    id: 11,
    title: 'Beauty Spas',
  },
  {
    id: 12,
    title: 'Barber and Hair Saloon',
  },
  {
    id: 13,
    title: 'Entertainment',
  },
  {
    id: 14,
    title: 'Religious Organizations',
  },
];

export const pieData = [
  { name: 'POS terminals', value: 2400, color: '#CAD4F7' },
  { name: 'Group B', value: 4567, color: '#18379A' },
  { name: 'Group C', value: 1398, color: '#00CC8B' },
];

export const barChartData = [
  {
    name: 'Jan',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Jul',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Aug',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Sep',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Oct',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Nov',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Dec',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
];

export const bankCodes = [
  {
    name: 'Access Bank',
    bankCode: '044',
  },
  {
    name: 'Addosser Microfinance Bank',
    bankCode: '90160',
  },
  {
    name: 'Citibank Nigeria Limited',
    bankCode: '023',
  },
  {
    name: 'Diamond Bank',
    bankCode: '063',
  },
  {
    name: 'Ecobank Nigeria',
    bankCode: '050',
  },
  {
    name: 'Enterprise Bank',
    bankCode: '084',
  },
  {
    name: 'eTranzact',
    bankCode: '306',
  },
  {
    name: 'Fidelity Bank',
    bankCode: '070',
  },
  {
    name: 'First Bank of Nigeria',
    bankCode: '011',
  },
  {
    name: 'First City Monument Bank',
    bankCode: '214',
  },
  {
    name: 'First Monnie Wallet',
    bankCode: '309',
  },
  {
    name: 'Globus Bank',
    bankCode: '000027',
  },
  {
    name: 'Guaranty Trust Bank',
    bankCode: '058',
  },
  {
    name: 'Heritage Bank',
    bankCode: '030',
  },
  {
    name: 'Hackman Microfinance Bank',
    bankCode: '90147',
  },
  {
    name: 'Ibile Microfinance Bank',
    bankCode: '090118',
  },
  {
    name: 'Jaiz Bank',
    bankCode: '301',
  },
  {
    name: 'Key Stone Bank',
    bankCode: '082',
  },
  {
    name: 'Kuda Microfinance Bank',
    bankCode: '090267',
  },
  {
    name: 'Paycom (Opay)',
    bankCode: '305',
  },
  {
    name: 'Paga',
    bankCode: '327',
  },
  {
    name: 'PayAttitude Online',
    bankCode: '329',
  },
  {
    name: 'Providus Bank',
    bankCode: '101',
  },
  {
    name: 'RenMoney Microfinance Bank',
    bankCode: '090198',
  },
  {
    name: 'Royal Exchange Microfinance Bank',
    bankCode: '90138',
  },
  {
    name: 'Skye Bank',
    bankCode: '076',
  },
  {
    name: 'Stanbic IBTC Bank',
    bankCode: '039',
  },
  {
    name: 'Standard Chartered Bank Nigeria',
    bankCode: '068',
  },
  {
    name: 'Sterling Bank Plc',
    bankCode: '232',
  },
  {
    name: 'SunTrust Bank Nigeria Limited',
    bankCode: '100',
  },
  {
    name: 'Taj Bank',
    bankCode: '000026',
  },
  {
    name: 'Titan Trust Bank',
    bankCode: '000025',
  },
  {
    name: 'Union Bank of Nigeria',
    bankCode: '032',
  },
  {
    name: 'United Bank for Africa',
    bankCode: '033',
  },
  {
    name: 'Unity Bank',
    bankCode: '215',
  },
  {
    name: 'Wema Bank',
    bankCode: '035',
  },
  {
    name: 'Zenith Bank',
    bankCode: '057',
  },
  {
    name: 'Parallex MF Bank',
    bankCode: '526',
  },
  {
    name: 'Nirsal MFB',
    bankCode: '090194',
  },
  {
    name: 'Moniepoint Microfinance Bank',
    bankCode: '090405',
  },
  {
    name: 'VFD Microfinance Bank Limited',
    bankCode: '566',
  },
  {
    name: 'Paystack Test Bank',
    bankCode: 'TEST BANK',
  },
];

export const bankOptions = [
  bankCodes.sort((a, b) => a.name.localeCompare(b.name)).map((bank) => ({ text: bank.name, value: bank.name })),
];

export const bulkTransferData = {
  Transfer: [
    {
      date: '02/03/2022, 15:30:00',
      account_number: '2209068957',
      bank: 'Zenith bank',
      account_name: 'Victor Onazi',
      reference: '6o232081929290ji12....',
      fee: '₦215.00',
      amount: '₦235,000.00',
      status: 'Success',
    },
    {
      date: '03/08/2022, 15:30:00',
      account_number: '3090008419',
      bank: 'First bank',
      account_name: 'Daniel Onazi',
      reference: '6o232081929290ji12....',
      fee: '₦215.00',
      amount: '₦245,000.00',
      status: 'Success',
    },
    {
      date: '03/10/2022, 15:30:00',
      account_number: '1219068957',
      bank: 'Union bank',
      account_name: 'Ayanfeoluwa K..',
      reference: '6o232081929290ji12....',
      fee: '₦215.00',
      amount: '₦135,000.00',
      status: 'Success',
    },
    {
      date: '03/11/2022, 15:30:00',
      account_number: '2109068311',
      bank: 'Kuda bank',
      account_name: 'Kingsley Paul',
      reference: '6o232081929290ji12....',
      fee: '₦215.00',
      amount: '₦335,000.00',
      status: 'Refund',
    },
    {
      date: '03/19/2022, 15:30:00',
      account_number: '2209068957',
      bank: 'Zenith bank',
      account_name: 'Victor Onazi',
      reference: '6o232081929290ji12....',
      fee: '₦215.00',
      amount: '₦235,000.00',
      status: 'Pending',
    },
    {
      date: '03/23/2022, 11:11:00',
      account_number: '2209068957',
      bank: 'Zenith bank',
      account_name: 'Victor Onazi',
      reference: '6o232081929290ji12....',
      fee: '₦215.00',
      amount: '₦535,000.00',
      status: 'Success',
    },
    {
      date: '03/24/2022, 12:30:00',
      account_number: '2209068957',
      bank: 'Zenith bank',
      account_name: 'Victor Onazi',
      reference: '6o232081929290ji12....',
      fee: '₦215.00',
      amount: '₦635,000.00',
      status: 'Success',
    },
  ],
  Beneficiaries: [
    {
      date: '02/03/2022',
      account_name: 'Victor Onazi',
      bank: 'Zenith Bank',
      account_number: '2209068957',
      nickname: 'veekwin',
    },
    {
      date: '03/08/2022',
      account_name: 'Agbo Obute',
      bank: 'Zenith Bank',
      account_number: '2209068957',
      nickname: 'Mosiac',
    },
    {
      date: '03/10/2022',
      account_name: 'Ilorin Emmanuel',
      bank: 'United Bank For Africa PLC',
      account_number: '2209068957',
      nickname: 'tonu1',
    },
    {
      date: '03/11/2022',
      account_name: 'Damilare James',
      bank: 'First Bank',
      account_number: '2209068957',
      nickname: 'Machine store 1',
    },
    {
      date: '03/19/2022',
      account_name: 'Seun Fabs',
      bank: 'First Bank',
      account_number: '2209068957',
      nickname: 'Comic intel',
    },
    {
      date: '03/23/2022',
      account_name: 'James Isreal',
      bank: 'First Bank Nigeria',
      account_number: '2209068957',
      nickname: 'Managment store',
    },
    {
      date: '03/24/2022',
      account_name: 'Debs Okafor',
      bank: 'First Bank Nigeria',
      account_number: '2209068957',
      nickname: 'Pay roll out',
    },
  ],
  Pending: [
    {
      date: '02/03/2022',
      details: 'Transfer to Adewale Lawal (GTB)',
      amount: '₦ 235,000',
      status: 'Pending',
    },
    {
      date: '03/08/2022',
      details: 'Transfer to Victor Onazi (GTB)',
      amount: '₦ 235,000',
      status: 'Decline',
    },
    {
      date: '03/10/2022',
      details: 'Transfer to Dami Onakoya (Zenith bank)',
      amount: '₦ 235,000',
      status: 'Decline',
    },
    {
      date: '03/11/2022',
      details: 'September monthly salary for Stafff (FBN)',
      amount: '₦ 235,000',
      status: 'Pending',
    },
    {
      date: '03/19/2022',
      details: 'Transfer to Funny Bone (GTB)',
      amount: '₦ 235,000',
      status: 'Decline',
    },
    {
      date: '03/23/2022',
      details: 'Trasfer to Ayokulne Ibrahim (GTB)',
      amount: '₦ 235,000',
      status: 'Decline',
    },
    {
      date: '03/24/2022',
      details: 'Transfer to Chinedu Okafor(GTB)',
      amount: '₦ 235,000',
      status: 'Pending',
    },
  ],
  ScheduledPayment: [
    {
      date: '02/03/2022, 15:30:00',
      details: 'Transfer to Moyin Irene (Zenith bank)',
      scheduledTime: '10:00AM, 15/08/2022',
      frequency: 'Monthly',
      amount: '₦235,000.00',
      status: 'Success',
    },
    {
      date: '02/03/2022, 15:30:00',
      details: 'Transfer to Moyin Irene (Zenith bank)',
      scheduledTime: '',
      frequency: 'Daily',
      amount: '₦235,000.00',
      status: 'Success',
    },
    {
      date: '02/03/2022, 15:30:00',
      details: 'Transfer to Moyin Irene (Zenith bank)',
      scheduledTime: '10:00AM, 15/08/2022',
      frequency: 'Weekly',
      amount: '₦235,000.00',
      status: 'Success',
    },
    {
      date: '02/03/2022, 15:30:00',
      details: 'Transfer to Moyin Irene (Zenith bank)',
      scheduledTime: '',
      frequency: 'Monthly',
      amount: '₦235,000.00',
      status: 'Success',
    },
    {
      date: '02/03/2022, 15:30:00',
      details: 'Transfer to Moyin Irene (Zenith bank)',
      scheduledTime: '',
      frequency: 'Weekly',
      amount: '₦235,000.00',
      status: 'Success',
    },
    {
      date: '02/03/2022, 15:30:00',
      details: 'Transfer to Moyin Irene (Zenith bank)',
      scheduledTime: '',
      frequency: 'Daily',
      amount: '₦235,000.00',
      status: 'Success',
    },
    {
      date: '02/03/2022, 15:30:00',
      details: 'Transfer to Moyin Irene (Zenith bank)',
      scheduledTime: '',
      frequency: 'Daily',
      amount: '₦235,000.00',
      status: 'Success',
    },
  ],
};

export const bulkTransferUsers = [];

export const timeData = [
  { text: '00:00', value: '00:00' },
  { text: '00:30', value: '00:30' },
  { text: '01:00', value: '01:00' },
  { text: '01:30', value: '01:30' },
  { text: '02:00', value: '02:00' },
  { text: '02:30', value: '02:30' },
  { text: '03:00', value: '03:00' },
  { text: '03:30', value: '03:30' },
  { text: '04:00', value: '04:00' },
  { text: '04:30', value: '04:30' },
  { text: '05:00', value: '05:00' },
  { text: '05:30', value: '05:30' },
  { text: '06:00', value: '06:00' },
  { text: '06:30', value: '06:30' },
  { text: '07:00', value: '07:00' },
  { text: '07:30', value: '07:30' },
  { text: '08:00', value: '08:00' },
  { text: '08:30', value: '08:30' },
  { text: '09:00', value: '09:00' },
  { text: '09:30', value: '09:30' },
  { text: '10:00', value: '10:00' },
  { text: '10:30', value: '10:30' },
  { text: '11:00', value: '11:00' },
  { text: '11:30', value: '11:30' },
  { text: '12:00', value: '12:00' },
  { text: '12:30', value: '12:30' },
  { text: '13:00', value: '13:00' },
  { text: '13:30', value: '13:30' },
  { text: '14:00', value: '14:00' },
  { text: '14:30', value: '14:30' },
  { text: '15:00', value: '15:00' },
  { text: '15:30', value: '15:30' },
  { text: '16:00', value: '16:00' },
  { text: '16:30', value: '16:30' },
  { text: '17:00', value: '17:00' },
  { text: '17:30', value: '17:30' },
  { text: '18:00', value: '18:00' },
  { text: '18:30', value: '18:30' },
  { text: '19:00', value: '19:00' },
  { text: '19:30', value: '19:30' },
  { text: '20:00', value: '20:00' },
  { text: '20:30', value: '20:30' },
  { text: '21:00', value: '21:00' },
  { text: '21:30', value: '21:30' },
  { text: '22:00', value: '22:00' },
  { text: '22:30', value: '22:30' },
  { text: '23:00', value: '23:00' },
  { text: '23:30', value: '23:30' },
];
