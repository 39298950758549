/* eslint-disable arrow-body-style */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-const-assign */
// // Navigation Icons

import React from 'react';

const AcceptPayment = React.lazy(() => import('./accept-payment'));
const AccountCreation = React.lazy(() => import('./account-creation'));

const Logo = React.lazy(() => import('./logo'));
const LogoWhite = React.lazy(() => import('./logo-white'));
const CaretLeft = React.lazy(() => import('./caret-left'));
const Category = React.lazy(() => import('./category'));
const Discount = React.lazy(() => import('./discount'));
const Send = React.lazy(() => import('./send'));
const Users3 = React.lazy(() => import('./users3'));
const Users2 = React.lazy(() => import('./users2'));
const Work = React.lazy(() => import('./work'));
const Menu = React.lazy(() => import('./menu'));
const Avatar = React.lazy(() => import('./avatar'));
const TinyCaret = React.lazy(() => import('./tiny-caret'));
const WalletMinus = React.lazy(() => import('./walletminus'));
const UserOctagon = React.lazy(() => import('./useroctagon'));
const Bag = React.lazy(() => import('./bag'));
const Plus = React.lazy(() => import('./plus'));
const TopUp = React.lazy(() => import('./topup'));
const Withdraw = React.lazy(() => import('./withdraw'));
const Profile = React.lazy(() => import('./profile'));
const Basket = React.lazy(() => import('./basket'));
const Payment = React.lazy(() => import('./payment'));
const Pin = React.lazy(() => import('./pin'));
const Settlement = React.lazy(() => import('./settlement'));
const CloseModal = React.lazy(() => import('./closemodal'));
const WelcomeImage = React.lazy(() => import('./welcome'));
const StoreFront = React.lazy(() => import('./storefront'));
const Business = React.lazy(() => import('./business'));
const EmptyIcon = React.lazy(() => import('./empty-icon'));
const Money = React.lazy(() => import('./money'));
const Money4 = React.lazy(() => import('./money-4'));
const ShopPlus = React.lazy(() => import('./shopplus'));
const CardReceive = React.lazy(() => import('./card-receive'));
const ReceiptItem = React.lazy(() => import('./receiptitem'));
const Discover = React.lazy(() => import('./discover'));
const DocumentDownload = React.lazy(() => import('./document-download'));
const Calendar = React.lazy(() => import('./calendar'));
const CirclePlus = React.lazy(() => import('./circle-plus'));
const CaretRight = React.lazy(() => import('./caret-right'));
const ExportIcon = React.lazy(() => import('./export'));
const CheckIcon = React.lazy(() => import('./check'));
const AddIcon = React.lazy(() => import('./add'));
const MoneyIcon = React.lazy(() => import('./money-icon'));
const BriefcaseIcon = React.lazy(() => import('./briefcase-icon'));
const Tip = React.lazy(() => import('./tip'));
const Card = React.lazy(() => import('./card'));
const Transfer = React.lazy(() => import('./transfer'));
const Info = React.lazy(() => import('./info'));
const AddCard = React.lazy(() => import('./add-card'));
const AddLogo = React.lazy(() => import('./add-logo'));
const CheckCircle = React.lazy(() => import('./check-circle'));
const Home1 = React.lazy(() => import('./home1'));
const MakePayment = React.lazy(() => import('./make-payment'));
const Balance = React.lazy(() => import('./balance'));
const Settlement2 = React.lazy(() => import('./settlement2'));
const Savings = React.lazy(() => import('./savings'));
const Outlet = React.lazy(() => import('./outlet'));
const Teams = React.lazy(() => import('./teams'));
const Settings = React.lazy(() => import('./settings'));
const Referral = React.lazy(() => import('./referral'));
const Notification = React.lazy(() => import('./notification'));
const Viewer = React.lazy(() => import('./viewer'));
const Admin = React.lazy(() => import('./admin'));
const Upload = React.lazy(() => import('./upload'));
const TeamsAdded = React.lazy(() => import('./teams-added'));
const Note = React.lazy(() => import('./note'));
const Active = React.lazy(() => import('./active'));
const Money2 = React.lazy(() => import('./money2'));
const EmptyTerminal = React.lazy(() => import('./empty-terminal'));
const LogoutIcon = React.lazy(() => import('./logout'));
const InfoIcon = React.lazy(() => import('./info-icon'));
const NotificationLoading = React.lazy(() => import('./notification-loading'));
const NotificationCheck = React.lazy(() => import('./notification-check'));
const NotificationError = React.lazy(() => import('./notification-error'));
const Trash = React.lazy(() => import('./trash'));
const Confirm = React.lazy(() => import('./confirm'));
const ComingSoonIcon = React.lazy(() => import('./coming-soon'));
const WithdrawalIcon = React.lazy(() => import('./withdrawal'));
const CheckSmall = React.lazy(() => import('./check-small'));
const OnboardingPerson = React.lazy(() => import('./onboarding-person'));
const OnboardingBank = React.lazy(() => import('./onboarding-bank'));
const OnboardingBusiness = React.lazy(() => import('./onboarding-business'));
const AddIcon2 = React.lazy(() => import('./add-icon'));
const DownIcon = React.lazy(() => import('./down-icon'));
const CaretRight2 = React.lazy(() => import('./caret-right-2'));
const WelcomeIcon = React.lazy(() => import('./welcome-icon'));
const TagCheck = React.lazy(() => import('./tag-check'));
const OnboardingComplete = React.lazy(() => import('./onboarding-complete'));
const TerminalImage = React.lazy(() => import('./terminal'));
const TerminalRequestSuccess = React.lazy(() => import('./terminal-request-success'));
const TerminalRequestFailure = React.lazy(() => import('./terminal-request-failure'));
const TerminalActivationDemo = React.lazy(() => import('./terminal-activation-demo'));
const SlantArrowIcon = React.lazy(() => import('./slant-arrow'));
const SetIcon = React.lazy(() => import('./set-icon'));
const ChevronDown = React.lazy(() => import('./chevron-down'));
const CheckMark = React.lazy(() => import('./check-mark'));
const DownArrow = React.lazy(() => import('./down-arrow'));
const SearchIcon = React.lazy(() => import('./search'));
const CloseIcon = React.lazy(() => import('./close'));
const CashIcon = React.lazy(() => import('./cash'));
const CashLoanIcon = React.lazy(() => import('./cash-loan'));
const CurrencyIcon = React.lazy(() => import('./currency'));
const WithdrawSuccessIcon = React.lazy(() => import('./successwithdraw'));
const AddMoneyIcon = React.lazy(() => import('./add-money'));
const Spinner = React.lazy(() => import('./spinner'));
const LogoSm = React.lazy(() => import('./logo-sm'));
const ActiveDot = React.lazy(() => import('./active-dot'));
const NewReferral = React.lazy(() => import('./new-referral'));
const NewDownload = React.lazy(() => import('./new-download'));
const ReferralOverview = React.lazy(() => import('./referral-overview'));
const ReferralOverview2 = React.lazy(() => import('./referral-overview2'));
const InviteUser = React.lazy(() => import('./invite-user'));
const CopyLink = React.lazy(() => import('./copy-link'));
const FacebookIcon = React.lazy(() => import('./facebook-icon'));
const TwitterIcon = React.lazy(() => import('./twitter-icon'));
const WhatsappIcon = React.lazy(() => import('./whatsapp-icon'));
// const Alert = React.lazy(() => import('./alert'));
const UploadIcon = React.lazy(() => import('./upload-icon'));
const Duplicate = React.lazy(() => import('./duplicate'));
const TransactionIconSm = React.lazy(() => import('./transaction-icon-sm'));
const SuccessfulWithrawIcon = React.lazy(() => import('./successful-withdraw'));
const NigFlag = React.lazy(() => import('./nig-flag'));
const PaymentSetupSuccess = React.lazy(() => import('./payment-setup-success'));
const BvnCheck = React.lazy(() => import('./bvn-check'));
const FileIcon = React.lazy(() => import('./file-icon'));
const EditIcon = React.lazy(() => import('./edit'));
const ViewIcon = React.lazy(() => import('./eye'));
const Tick = React.lazy(() => import('./tick'));
const MoreIcon = React.lazy(() => import('./more-icon'));
const AddNewItemIcon = React.lazy(() => import('./add-new-item-icon'));
const Unverified = React.lazy(() => import('./unverified'));
const Download = React.lazy(() => import('./download'));
const FilterIcon = React.lazy(() => import('./filter-icon'));
const CardProviders = React.lazy(() => import('./card-providers'));
const TransactionIcon = React.lazy(() => import('./transaction-icon'));
const AddBeneficiaryIcon = React.lazy(() => import('./add-beneficiary'));
const TransferSuccessIcon = React.lazy(() => import('./transfer-success'));
const SingleTransferIcon = React.lazy(() => import('./single-transfer'));
const SingleTransferInactiveIcon = React.lazy(() => import('./single-transfer-inactive'));
const BulkTransferIcon = React.lazy(() => import('./bulk-transfer'));
const BulkTransferActiveIcon = React.lazy(() => import('./bulk-transfer-active'));
const DownloadIcon = React.lazy(() => import('./download'));
const RemoveIcon = React.lazy(() => import('./remove'));
const DotsIcon = React.lazy(() => import('./dots'));
const CautionIcon = React.lazy(() => import('./caution'));
const CautionIcon2 = React.lazy(() => import('./caution2'));
const DeleteBeneficiaryIcon = React.lazy(() => import('./delete-beneficiary'));
const NewInfoIcon = React.lazy(() => import('./new-info-icon'));
const ErrorIcon = React.lazy(() => import('./error-icon'));
const Clock = React.lazy(() => import('./clock'));
const Xlsx = React.lazy(() => import('./xlsx-file'));
const CancelIcon = React.lazy(() => import('./cancel'));
const XlsxUploaded = React.lazy(() => import('./xlsx-upload-success'));
const ChevronUp = React.lazy(() => import('./chevron-up'));
const ChevronRight = React.lazy(() => import('./chevron-right'));
const YellowTick = React.lazy(() => import('./yellow-tick'));
const RenameLabelIcon = React.lazy(() => import('./rename-terminal-icon'));
const NewExportIcon = React.lazy(() => import('./new-export-icon'));
const DeleteIcon = React.lazy(() => import('./delete'));
const EditV = React.lazy(() => import('./edit-v'));
const NewDevice = React.lazy(() => import('./new-device'));
const NewDeviceSm = React.lazy(() => import('./new-device-sm'));
const StatusIcon = React.lazy(() => import('./status-icon'));
const ReferralOverviewEmpty = React.lazy(() => import('./referralOverview_empty'));
const ReferralOverviewEmpty2 = React.lazy(() => import('./referralOverview_empty2'));
const UnableToWithdrawIcon = React.lazy(() => import('./unable-to-withdraw'));
const ChevronLeft = React.lazy(() => import('./chevron-left'));
const SlidersIcon = React.lazy(() => import('./sliders'));
const SearchIcon2 = React.lazy(() => import('./search-2'));
const AddItem = React.lazy(() => import('./add-item'));
const PlusCircle = React.lazy(() => import('./plus-circle'));
const ArrowDownCircle = React.lazy(() => import('./arrow-down-circle'));
const ComingSoonRightArrow = React.lazy(() => import('./coming-soon-right-arrow'));
const RequestTerminalMobile = React.lazy(() => import('./request-terminal'));
const MyTeamsMobile = React.lazy(() => import('./my-teams'));
const InventoryIcon = React.lazy(() => import('./inventory'));
const InventoryMobile = React.lazy(() => import('./inventory-mobile'));
const List = React.lazy(() => import('./list'));
const UpTrend = React.lazy(() => import('./up-trend'));
const DownTrend = React.lazy(() => import('./down-trend'));
const Cart = React.lazy(() => import('./cart'));
const Pay = React.lazy(() => import('./pay'));
const UploadIcon2 = React.lazy(() => import('./yellow-upload-icon'));
const ImagePlaceholder = React.lazy(() => import('./image-placeholder-icon'));
const YellowPlusIcon = React.lazy(() => import('./new-plus-icon'));
const PayInGreyBanner = React.lazy(() => import('./pay-in-grey-banner'));
const InventorySuccessIcon = React.lazy(() => import('./inventory-success-icon'));
const Suspend = React.lazy(() => import('./suspend-icon'));
const Unsuspend = React.lazy(() => import('./unsuspend-icon'));
// const CircledCheck = React.lazy(() => import('./circled-check'));
// const Bulb = React.lazy(() => import('./bulb'));
// const OutlinedX = React.lazy(() => import('./outline-x'));
const TeamsNew = React.lazy(() => import('./teams-new'));
const Pages = React.lazy(() => import('./pages'));
const Activity = React.lazy(() => import('./activity'));
const AccountCreatedIcon = React.lazy(() => import('./account-created'));
const UserFilled = React.lazy(() => import('./user-filled-dark'));
const InvalidTokenIcon = React.lazy(() => import('./invalid-token'));
const SettingsIcon = React.lazy(() => import('./settings-badge'));
const PromptIcon = React.lazy(() => import('./prompt'));
const AddCategorySuccessIcon = React.lazy(() => import('./add-category-success'));
const AddCategoryErrorIcon = React.lazy(() => import('./add-category-error'));
const NotificationAndBadgeIcon = React.lazy(() => import('./notification-and-badge-icon'));
const EmptyWebhookIcon = React.lazy(() => import('./empty-webhook-icon'));
const FilterCalenderIcon = React.lazy(() => import('./filter-calender-icon'));
const EnableAutoSettlementIcon = React.lazy(() => import('./enable-auto-settlement-icon'));
const AttentionIcon = React.lazy(() => import('./attention'));
const VerifyIcon = React.lazy(() => import('./verify'));
const UploadUserIcon = React.lazy(() => import('./upload-user'));

const WelcomeIcon2 = React.lazy(() => import('./welcome-icon2'));
const SettlementAccountCreatedIcon = React.lazy(() => import('./settlement-account-created'));
const PinSetupCompleteIcon = React.lazy(() => import('./pin-setup-complete-icon'));
const AddDiscountSuccessIcon = React.lazy(() => import('./add-discount-success'));
const AddDiscountErrorIcon = React.lazy(() => import('./add-discount-error'));
const EmptySettlementIcon = React.lazy(() => import('./empty-settlement'));
const StorefrontNewIcon = React.lazy(() => import('./storefront-new'));
const CompleteOrdersIcon = React.lazy(() => import('./complete-orders'));
const PendingOrdersIcon = React.lazy(() => import('./pending-orders'));
const TotalOrdersIcon = React.lazy(() => import('./total-orders'));
const OngoingOrdersIcon = React.lazy(() => import('./ongoing-orders'));
const MenuGettingStartedImage = React.lazy(() => import('./menu-getting-started-image'));
const AddImageCoverPhoto = React.lazy(() => import('./add-image-cover'));
const UploadCloudIcon = React.lazy(() => import('./upload-cloud'));
const InfoCircleIcon = React.lazy(() => import('./info-circle'));
const PlusAddIcon = React.lazy(() => import('./plusadd'));
const BusinessInfoIcon = React.lazy(() => import('./business-info'));

const SortDownIcon = React.lazy(() => import('./sort-down'));
const SortUpIcon = React.lazy(() => import('./sort-up'));
const GlobeIcon = React.lazy(() => import('./globe'));
const RefreshIcon = React.lazy(() => import('./refresh'));
const ArrowUpIcon = React.lazy(() => import('./arrow-up'));
const ArrowDownIcon = React.lazy(() => import('./arrow-down'));
const ArrowMidIcon = React.lazy(() => import('./arrow-mid'));
const ModalInvoiceIcon = React.lazy(() => import('./modal-invoices'));
const ModalCustomersIcon = React.lazy(() => import('./modal-customers'));
const ModalInventoryIcon = React.lazy(() => import('./modal-inventory'));
const ModalOutletsIcon = React.lazy(() => import('./modal-outlets'));
const ModalOverviewIcon = React.lazy(() => import('./modal-overview'));
const ModalReferralsIcon = React.lazy(() => import('./modal-referrals'));
const ModalTeamsIcon = React.lazy(() => import('./modal-teams'));
const ModalTransactionIcon = React.lazy(() => import('./modal-transaction'));
const ModalPaymentIcon = React.lazy(() => import('./modal-payment'));
const ModalMobileOverviewIcon = React.lazy(() => import('./modal-mobile-overview'));
const ModalMobileInvoiceIcon = React.lazy(() => import('./modal-mobile-invoices'));
const ModalMobileCustomersIcon = React.lazy(() => import('./modal-mobile-customers'));
const ModalMobileInventoryIcon = React.lazy(() => import('./modal-mobile-inventory'));
const ModalMobileOutletsIcon = React.lazy(() => import('./modal-mobile-outlets'));
const ModalMobileReferralsIcon = React.lazy(() => import('./modal-mobile-referrals'));
const ModalMobileTeamsIcon = React.lazy(() => import('./modal-mobile-teams'));
const ModalMobileTransactionIcon = React.lazy(() => import('./modal-mobile-transactions'));
const ModalMobilePaymentIcon = React.lazy(() => import('./modal-mobile-payments'));
const SortDownBlackIcon = React.lazy(() => import('./sort-down-black'));
const SortUpBlackIcon = React.lazy(() => import('./sort-up-black'));
const OutlineCheckIcon = React.lazy(() => import('./outline-check'));
const BioVerifiedOutline = React.lazy(() => import('./bio-verified-outline'));
const Call = React.lazy(() => import('./call'));
const RedAlert = React.lazy(() => import('./red-alert'));
const PlainAlert = React.lazy(() => import('./plain-alert'));
const SVGLoader = React.lazy(() => import('./loader'));
const SuccessCheck = React.lazy(() => import('./success-check'));
const FailedCheck = React.lazy(() => import('./failed-check'));
const ResetPasswordModalIcon = React.lazy(() => import('./reset-password-modal'));
const POSMiniBackImg = React.lazy(() => import('./mini_back'));
const POSMiniFrontImg = React.lazy(() => import('./mini_front'));
const NotificationBellEmptyIcon = React.lazy(() => import('./notification-bell-empty'));
const NotificationBellIcon = React.lazy(() => import('./notification-bell'));
const OutlineBusinessIcon = React.lazy(() => import('./outline-business'));
const CancelTrialIcon = React.lazy(() => import('./cancel-trial'));
const CancelSuccessIcon = React.lazy(() => import('./cancel-success'));
const FilePDF = React.lazy(() => import('./file-pdf'));

const OutlineNotification = React.lazy(() => import('./outline-notification'));
const OutlineCheckC = React.lazy(() => import('./outline-check-c'));

export { ReactComponent as MerchantTypeInfo } from './merchant-type-info.svg';
export { ReactComponent as BvnVerification } from './bvn-verification.svg';
export { ReactComponent as IgreeLoading } from './igree-loading.svg';
export { ReactComponent as EmptySelfie } from './empty-selfie.svg';
export { ReactComponent as CircledCheck } from './circled-check.svg';
export { ReactComponent as Alert } from './alert.svg';
export { ReactComponent as Bulb } from './bulb.svg';
export { ReactComponent as AsteriskInfo } from './asterisk-info.svg';
export { ReactComponent as EndOfTableData } from './end-of-data.svg';
export { ReactComponent as OutlinedX } from './outline-x.svg';
export { ReactComponent as ActivityIcon } from './activity.svg';


export {
  Suspend,
  Unsuspend,
  EmptyWebhookIcon,
  NotificationAndBadgeIcon,
  AccountCreation,
  AcceptPayment,
  Logo,
  LogoWhite,
  CaretLeft,
  Category,
  Discount,
  Send,
  Users3,
  Users2,
  Work,
  Menu,
  Avatar,
  TinyCaret,
  WalletMinus,
  UserOctagon,
  Bag,
  Plus,
  TopUp,
  Withdraw,
  Profile,
  Basket,
  Payment,
  Pin,
  Settlement,
  CloseModal,
  WelcomeImage,
  StoreFront,
  Business,
  EmptyIcon,
  Money,
  Money4,
  ShopPlus,
  CardReceive,
  ReceiptItem,
  Discover,
  DocumentDownload,
  Calendar,
  CirclePlus,
  CaretRight,
  ExportIcon,
  CheckIcon,
  WelcomeIcon2,
  AddIcon,
  MoneyIcon,
  BriefcaseIcon,
  Tip,
  Card,
  Transfer,
  Info,
  AddCard,
  AddLogo,
  CheckCircle,
  Home1,
  MakePayment,
  Balance,
  Settlement2,
  Savings,
  Outlet,
  Teams,
  Settings,
  Referral,
  Notification,
  Viewer,
  Admin,
  Upload,
  TeamsAdded,
  Note,
  Active,
  Money2,
  EmptyTerminal,
  LogoutIcon,
  InfoIcon,
  NotificationLoading,
  NotificationCheck,
  NotificationError,
  Trash,
  Confirm,
  ComingSoonIcon,
  WithdrawalIcon,
  CheckSmall,
  OnboardingPerson,
  OnboardingBank,
  OnboardingBusiness,
  AddIcon2,
  DownIcon,
  CaretRight2,
  WelcomeIcon,
  TagCheck,
  OnboardingComplete,
  TerminalImage,
  TerminalRequestSuccess,
  SettlementAccountCreatedIcon,
  TerminalRequestFailure,
  TerminalActivationDemo,
  SlantArrowIcon,
  SetIcon,
  ChevronDown,
  CheckMark,
  DownArrow,
  SearchIcon,
  CloseIcon,
  CashIcon,
  CashLoanIcon,
  CurrencyIcon,
  WithdrawSuccessIcon,
  AddMoneyIcon,
  Spinner,
  LogoSm,
  ActiveDot,
  NewReferral,
  NewDownload,
  ReferralOverview,
  ReferralOverview2,
  InviteUser,
  CopyLink,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  // Alert,
  UploadIcon,
  Duplicate,
  TransactionIconSm,
  SuccessfulWithrawIcon,
  NigFlag,
  PaymentSetupSuccess,
  BvnCheck,
  FileIcon,
  EditIcon,
  ViewIcon,
  Tick,
  MoreIcon,
  AddNewItemIcon,
  Unverified,
  Download,
  FilterIcon,
  CardProviders,
  TransactionIcon,
  AddBeneficiaryIcon,
  TransferSuccessIcon,
  SingleTransferIcon,
  SingleTransferInactiveIcon, //
  BulkTransferIcon,
  BulkTransferActiveIcon,
  DownloadIcon,
  RemoveIcon,
  DotsIcon,
  CautionIcon,
  CautionIcon2,
  DeleteBeneficiaryIcon,
  NewInfoIcon,
  ErrorIcon,
  Clock,
  Xlsx,
  CancelIcon,
  XlsxUploaded,
  ChevronUp,
  ChevronRight,
  YellowTick,
  RenameLabelIcon,
  NewExportIcon,
  DeleteIcon,
  EditV,
  NewDevice,
  NewDeviceSm,
  StatusIcon,
  ReferralOverviewEmpty,
  ReferralOverviewEmpty2,
  UnableToWithdrawIcon,
  ChevronLeft,
  SlidersIcon,
  SearchIcon2,
  AddItem,
  PlusCircle,
  ArrowDownCircle,
  ComingSoonRightArrow,
  RequestTerminalMobile,
  MyTeamsMobile,
  InventoryIcon,
  InventoryMobile,
  List,
  UpTrend,
  DownTrend,
  Cart,
  Pay,
  UploadIcon2,
  ImagePlaceholder,
  YellowPlusIcon,
  PayInGreyBanner,
  InventorySuccessIcon,
  // Bulb,
  TeamsNew,
  Pages,
  Activity,
  AccountCreatedIcon,
  UserFilled,
  InvalidTokenIcon,
  SettingsIcon,
  PromptIcon,
  AddCategorySuccessIcon,
  AddCategoryErrorIcon,
  FilterCalenderIcon,
  EnableAutoSettlementIcon,
  AttentionIcon,
  VerifyIcon,
  UploadUserIcon,
  PinSetupCompleteIcon,
  AddDiscountErrorIcon,
  AddDiscountSuccessIcon,
  EmptySettlementIcon,
  StorefrontNewIcon,
  CompleteOrdersIcon,
  PendingOrdersIcon,
  TotalOrdersIcon,
  OngoingOrdersIcon,
  MenuGettingStartedImage,
  AddImageCoverPhoto,
  UploadCloudIcon,
  InfoCircleIcon,
  PlusAddIcon,
  BusinessInfoIcon,
  SortDownIcon,
  SortUpIcon,
  SortDownBlackIcon,
  SortUpBlackIcon,
  GlobeIcon,
  RefreshIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowMidIcon,
  ModalInvoiceIcon,
  ModalCustomersIcon,
  ModalInventoryIcon,
  ModalOutletsIcon,
  ModalOverviewIcon,
  ModalReferralsIcon,
  ModalTeamsIcon,
  ModalTransactionIcon,
  ModalPaymentIcon,
  ModalMobileOverviewIcon,
  ModalMobileInvoiceIcon,
  ModalMobileCustomersIcon,
  ModalMobileInventoryIcon,
  ModalMobileOutletsIcon,
  ModalMobileReferralsIcon,
  ModalMobileTeamsIcon,
  ModalMobileTransactionIcon,
  ModalMobilePaymentIcon,
  OutlineCheckIcon,
  BioVerifiedOutline,
  Call,
  RedAlert,
  PlainAlert,
  SVGLoader,
  SuccessCheck,
  FailedCheck,
  ResetPasswordModalIcon,
  POSMiniBackImg,
  POSMiniFrontImg,
  NotificationBellEmptyIcon,
  NotificationBellIcon,
  OutlineBusinessIcon,
  CancelTrialIcon,
  CancelSuccessIcon,
  FilePDF,
  OutlineNotification,
  OutlineCheckC,
};
