import styled from 'styled-components';
import { Product } from '../../../../types/inventory/products';
import ItemImage from '../../../inventory/components/ItemImage';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: 10px;
  }
`;

const ProductNameWithImage = ({ product }: { product: Product }) => {
  const { imageUrl, name } = product;
  return (
    <Wrapper>
      <ItemImage imageUrl={imageUrl} />
      {name}
    </Wrapper>
  );
};

export default ProductNameWithImage;
