export const invoiceTypes = {
  DRAFTS: 'DRAFT',
  PAID: 'PAID',
  OVERDUE: 'OVERDUE',
  UNPAID: 'UNPAID',
};

export const taxTypes = {
  INCLUSIVE: 'inclusive',
  EXCLUSIVE: 'exclusive',
  NOTAX: 'noTax',
};

export const transactionTypes = {
  DELIVERED: 'DELIVERED',
  SUCCESS: 'SUCCESS',
  PAYMENT_SUCCESSFUL: 'PAYMENT_SUCCESSFUL',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  CANCELLED: 'CANCELLED',
  REFUND: 'REFUND',
  REVERSED_BY_VENDOR: 'REVERSED_BY_VENDOR',
};
