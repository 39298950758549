import styled from 'styled-components';

export const GuestModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;

  @media ${({ theme }) => `${theme.media.lg}`} {
    display: flex;
  }
`;

export const GuestModalImageWrapper = styled.div`
  height: 350px;
  background: #eeeeee;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media ${({ theme }) => `${theme.media.lg}`} {
  }
`;

export const GuestModalContentWrapper = styled.div`
  height: 350px;
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 390px;
  text-align: center;

  @media ${({ theme }) => `${theme.media.lg}`} {
  }
`;
export const GuestModalContentTitle = styled.div`
  padding: 40px 0 20px 0;

  @media ${({ theme }) => `${theme.media.lg}`} {
  }
`;

export const GuestModalContentButton = styled.div`
  padding: 30px 0;
  width: 100%;

  @media ${({ theme }) => `${theme.media.lg}`} {
  }
`;

export const GuestModalContentLogin = styled.div`
  cursor: pointer;
  &:hover {
    span {
      color: ${({ theme }) => theme.nColors['n-grey4']};
    }
  }
  span {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  }
`;

export const Wrapper = styled.div<{ open: boolean }>`
  width: 250px;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 1rem;

  height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary.white};
  display: flex;
  flex-direction: column;
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.1); */
  transform: ${({ open }) => (open ? 'translate(0%)' : 'translate(-100%)')};
  transition: 300ms ease;

  @media ${({ theme }) => `${theme.media.xl}`} {
    transform: none;
  }
`;

export const NavTop = styled.div`
  display: flex;
  /* margin: 6.5rem 2.8rem 0 3.2rem; */
  height: 6rem;
  width: 100%;
  align-items: center;
  padding-left: 2rem;
  display: none;

  @media ${({ theme }) => `${theme.media.lg}`} {
    display: flex;
  }
`;

export const MobileNavTop = styled.div`
  height: 10rem;

  @media ${({ theme }) => `${theme.media.lg}`} {
    display: none;
  }
`;

export const MobileNavTopClose = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 2rem 0 auto;
`;

export const NavTopButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => `${theme.colors.primary.black6}`};
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  cursor: pointer;
`;

export const NavigationContentHolder = styled.div`
  padding: 0 20px;
  max-height: 80vh;
  overflow: auto;
`;

// Navigation section
export const NavigationSectionHolder = styled.div`
  margin-bottom: 0;
`;

export const NavigationSectionTitle = styled.div<{ active?: boolean }>`
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  font-feature-settings: 'cv11' on;
  color: #121212;
  margin-top: 20px;
`;

// Navigation option
export const NavigationOptionHolder = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 8px 0px;
  ::before {
    ${({ active, theme }) =>
      active
        ? ` content: "";
    width: 3px;
    height: 3.8rem;
    background-color: ${theme.colors.primary.blue1};
    position: absolute;
    left: -2rem;
    top: 0;`
        : `
    content: "";
    display: none;
  `};
  }
`;

export const NavigationOptionContent = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #999999;
  svg {
    path {
      fill: ${({ active }) => `${active ? 'transparent' : 'transparent'}`};
      stroke: ${({ theme, active }) => `${active ? theme.colors.primary.green1 : theme.colors.primary.black3}`};
      /* margin-left: 1.2rem; */
    }
  }
  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.nColors['n-dark']} !important;
      }
    }
    color: ${({ theme }) => theme.nColors['n-dark']};
  }
`;

export const NavigationOptionName = styled.div<{ active?: boolean }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ active }) => `${active ? '#121212' : 'inherit'}`};
  margin-left: 1.2rem;
  p {
    white-space: nowrap;
    overflow: hidden;
    width: inherit;
    text-overflow: ellipsis;
  }
`;

export const NavigationOptionCaretHolder = styled.div<{ active?: boolean; show?: boolean }>`
  ${({ show }) => (show ? `transform: rotate(90deg);` : `transform: rotate(-90deg);`)};

  svg {
    path {
      stroke: ${({ theme, active }) => `${active ? theme.colors.primary.green1 : theme.colors.primary.black3}`};
    }
  }
`;

// Navigation option sub
export const NavigationSubSectionHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  border-left: ${({ theme }) => `1px solid ${theme?.colors.primary.black4}`};
  margin-left: 30px;
`;

export const NavigationSubRouteHolder = styled.div<{ active?: boolean }>`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 1.8em;
  text-align: left;
  border-radius: 0.8rem;
  color: ${({ theme, active }) => ` ${active ? theme?.colors.primary.green1 : theme?.colors.primary.green2}`};
  cursor: pointer;
  position: relative;
  // margin-bottom: 1.3rem;

  :last-child {
    margin-bottom: 0;
  }

  ::before {
    ${({ active, theme }) =>
      active
        ? ` content: "";
    width: 1px;
    height: 2.4rem;
    background-color: ${theme.colors.primary.blue1};
    position: absolute;
    left: -2.1rem;
    top: 0;`
        : `
    content: "";
    display: none;
  `};
  }
`;

// navigation footer
export const NavigationFooterHolder = styled.div`
  margin: 0 2rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => ` ${theme?.colors.primary.white}`};
  max-height: 30vh;
`;

export const NavigationFooterExtra = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.8rem;
  background-color: ${({ theme }) => `${theme.colors.primary.green6}`};
  color: ${({ theme }) => `${theme.colors.primary.white}`};
`;

// user section
export const UserSection = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
`;

export const UserDetails = styled.div`
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
