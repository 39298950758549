import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Overview = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M9.74 3.108a.368.368 0 0 1 .52 0l6.632 6.631a.368.368 0 1 1-.52.521l-.846-.844v6.479c0 .61-.495 1.105-1.105 1.105H5.58c-.61 0-1.105-.495-1.105-1.105v-6.48l-.845.846a.368.368 0 1 1-.521-.522l6.631-6.631Zm-4.53 5.57v7.217c0 .203.165.368.369.368h2.21a.368.368 0 0 0 .369-.368v-2.948c0-.61.495-1.105 1.105-1.105h1.474c.61 0 1.105.495 1.105 1.105v2.948c0 .203.165.368.368.368h2.211a.369.369 0 0 0 .369-.368V8.679L10 3.889 5.21 8.68Zm5.958 7.585a1.102 1.102 0 0 1-.063-.368v-2.948a.369.369 0 0 0-.368-.368H9.263a.368.368 0 0 0-.368.368v2.948c0 .129-.022.253-.063.368h2.336Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Overview;
