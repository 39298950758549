import { Button, IconsEnum, NIcons } from 'kudi-component-library';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styled from 'styled-components';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import useStepCounter from '../models/useStepCounter';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BackButton = styled(Button)`
  background: ${({ theme }) => theme.nColors['n-light']};
  color: #4d4d4d;
  font-weight: 400;
  width: fit-content;
  line-height: 16px;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  align-items: center;
  border: 0.6px solid #e6e6e6;
  span {
    display: flex;
    align-items: center;
  }
`;

const ProgressBarContainer = styled.div`
  width: 64px;
  height: 64px;
`;

const ProgressBarInner = styled.div`
  display: flex;
  font: unset;
  flex-direction: column;
  gap: 3px;
  font-weight: 500;
  margin-top: -5px;
  font-size: 10px;
  line-height: 16px;
  color: #cccccc;
  strong {
    font-weight: 500;
    font-size: 20px;
    color: #0d0d0d;
    letter-spacing: -0.8px;
  }
`;

const OnboardingStepCounter = ({ step }: { step: number }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { totalStep, cleanPercentage } = useStepCounter();
  const percentage = cleanPercentage((step / totalStep) * 100);

  return (
    <Wrapper>
      <BackButton variant="small" onClick={() => navigate(-1)}>
        <NIcons icon={IconsEnum.OUTLINE_CHEVRON_LEFT} size={isMobile ? 'xs' : 'sm'} />
        Back
      </BackButton>
      <ProgressBarContainer>
        <CircularProgressbarWithChildren value={percentage}>
          <ProgressBarInner>
            STEP
            <strong>0{step}</strong>
          </ProgressBarInner>
        </CircularProgressbarWithChildren>
      </ProgressBarContainer>
    </Wrapper>
  );
};

export default OnboardingStepCounter;
