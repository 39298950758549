import { Button, Typography } from 'kudi-component-library';
import styled, { DefaultTheme } from 'styled-components';
import { invoiceTypes } from '../../utils/filterTypes';

export const HeaderText = styled.h3<{ color?: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ color }) => (color ? '30px' : '24px')};
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  font-feature-settings: 'cv11' on;
  color: ${({ theme, color }) => (color ? theme.colors.primary.black4 : theme.colors.primary.green1)};
`;

export const Text = styled.p<{ bottomMargin?: string; bold?: boolean; transform?: string; isTitle?: boolean }>`
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: ${({ bottomMargin }) => bottomMargin || '0.5em'};
  margin-right: 0.5em;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.primary.green1};
  ${({ bold }) =>
    bold &&
    `
      font-weight: 600;
  `}

  ${({ isTitle }) =>
    isTitle &&
    `
  font-weight: 500;
  `}

  ${({ transform }) =>
    transform &&
    `
      text-transform: ${transform};
  `}

  p:not(:first-child) {
    margin-top: 10px;
  }
`;

export const PageWrapper = styled.div`
  padding: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    padding: 5rem 2rem;
  }
`;

export const ViewInvoicePageWrapper = styled.div`
  background-color: #eeeeee;

  @media ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ViewInvoiceInnerPageWrapper = styled.div<{ isFullWidth?: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary.white};
  padding: 2rem;
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '80%')};
    margin: ${({ isFullWidth }) => (isFullWidth ? '0' : '10rem auto')};
  }
  @media ${({ theme }) => theme.media.lg} {
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '60%')};
  }
`;

export const HeaderWrapper = styled.div<{ justify?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ justify }) => (justify ? 'row' : 'column')};
  align-items: ${({ justify }) => (justify ? 'center' : 'start')};

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    align-items: center;
  }
`;

export const HeaderContentLeft = styled.div`
  display: flex;

  @media ${({ theme }) => theme.media.md} {
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderContentRight = styled.div<{ justify?: boolean }>`
  margin-top: ${({ justify }) => (justify ? '0' : '2rem')};

  button {
    height: fit-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: flex;
  gap: 2rem;
  width: ${({ justify }) => (justify ? 'fit-content' : '100%')};

  @media ${({ theme }) => theme.media.md} {
    margin-bottom: 0;
    margin-top: 0;
    width: fit-content;
  }

  @media ${({ theme }) => theme.media.lg} {
    button {
      padding: 1rem 2rem;
    }
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30rem;
`;

export const TopInnerWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const BottomInnerWrapper = styled.div`
  margin-top: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    width: 30%;
  }
`;
export const CustomModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  @media ${({ theme }) => theme.media.lg} {
    padding: 0 4rem 4rem 4rem;
  }
`;

export const ModalTitle = styled.h4`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const ModalDescription = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  color: #717171;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.media.lg} {
    margin-bottom: 2rem;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
`;

export const NotificationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  width: 100%;
`;

export const RemdinderFrequencyWrapper = styled.div`
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  width: 100%;
  padding: 10px;
`;

export const ButtonItemWrapper = styled.div`
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

export const NotificationButton = styled.button<{ isActive?: boolean; addBorder?: boolean }>`
  background: transparent;
  border: ${({ isActive, addBorder }) => {
    if (addBorder) {
      return 'none';
    }
    if (isActive) {
      return '1px solid #FFCC00';
    }
    return '1px solid #efefef';
  }};
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  margin-right: 1rem;
  cursor: pointer;

  button [x-apple-data-detectors] {
    border-bottom: 0 !important;
    cursor: default !important;
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }
`;

export const ReminderButton = styled.button<{ isActive?: boolean }>`
  background: ${({ isActive, theme }) => (isActive ? theme.colors.primary.white : 'transparent')};
  border: ${({ isActive }) => (isActive ? '1px solid #FFCC00' : 'none')};
  border-radius: 4px;
  padding: 7px;
  width: 100%;
  margin-right: 1rem;
  cursor: pointer;

  button [x-apple-data-detectors] {
    border-bottom: 0 !important;
    cursor: default !important;
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }
`;

export const InnerModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 0 4rem 0;
  @media ${({ theme }) => theme.media.lg} {
    padding: 6rem 4rem 4rem 4rem;
  }
`;

export const FormWrapper = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || '4rem 0'};
  width: 100%;
`;

export const FormInputWrapper = styled.div<{ pb?: string }>`
  padding-bottom: ${({ pb }) => pb || '2.2rem'};

  input {
    font-family: 'Inter';
  }
`;

export const InputWrapper = styled.div<{ displayProp?: string; width?: string; fromSelect?: boolean }>`
  display: ${({ displayProp }) => displayProp || 'block'};
  width: 100%;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.md} {
    width: ${({ width }) => (width ? '93%' : '40%')};
  }

  @media ${({ theme }) => theme.media.lg} {
    width: ${({ width }) => width || '30%'};
  }

  textarea {
    font-family: 'Inter';
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  input {
    font-family: 'Inter';
  }
`;

export const SelectInputWrapper = styled.div<{ displayProp?: string; width?: string; fromSelect?: boolean }>`
  display: ${({ displayProp }) => displayProp || 'block'};
  width: 100%;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.md} {
    width: ${({ width, fromSelect }) => (width && fromSelect ? '43%' : '100%')};
  }

  @media ${({ theme }) => theme.media.lg} {
    width: ${({ width }) => width || '30%'};
  }

  textarea {
    font-family: 'Inter';
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  input {
    font-family: 'Inter';
  }
`;

export const TaxSelectInputWrapper = styled.div<{ displayProp?: string; width?: string; fromSelect?: boolean }>`
  display: ${({ displayProp }) => displayProp || 'block'};
  width: 100%;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.md} {
    width: 15%;
  }

  @media ${({ theme }) => theme.media.lg} {
    width: ${({ width }) => width};
  }
  margin-left: 10px;

  textarea {
    font-family: 'Inter';
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  input {
    font-family: 'Inter';
  }
`;

export const UnderLinedText = styled.div<{ top?: number }>`
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.green8};
  margin-top: ${({ top }) => (top ? `${top}px` : '8px')};
  cursor: pointer;
  display: flex;
  justify-content: center;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 180%;
    color: ${({ theme }) => theme.colors.primary.green1};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue1};
  }
`;

export const Separator = styled.div<{ height?: string; width?: string }>`
  height: 20px;

  @media ${({ theme }) => theme.media.md} {
    width: ${({ width }) => width || '0px'};
    height: ${({ height }) => height || '0px'};
  }
`;

export const FlexWrapper = styled.div<{ top?: string; borderTop?: string; paddingTop?: string; direction?: string }>`
  border-top: ${({ theme, borderTop }) => (borderTop ? `${borderTop} solid ${theme.colors.primary.black6}` : 'none')};
  padding-top: 0;
  display: flex;
  justify-content: ${({ direction }) => (direction ? 'space-between' : 'unset')};
  align-items: ${({ direction }) => (direction ? 'center' : 'unset')};
  flex-direction: ${({ direction }) => direction || 'column-reverse'};
  margin-top: ${({ top }) => top || 0};

  @media ${({ theme }) => theme.media.md} {
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: space-between;
    padding-top: ${({ paddingTop }) => paddingTop || '3rem'};
  }
`;

export const FilterSearchWrapper = styled.div<{
  top?: string;
  borderTop?: string;
  paddingTop?: string;
  direction?: string;
}>`
  border-top: ${({ theme, borderTop }) => (borderTop ? `${borderTop} solid ${theme.colors.primary.black6}` : 'none')};
  padding-top: 0;
  display: flex;
  justify-content: ${({ direction }) => (direction ? 'space-between' : 'unset')};
  align-items: ${({ direction }) => (direction ? 'center' : 'unset')};
  flex-direction: ${({ direction }) => direction || 'column'};
  margin-top: ${({ top }) => top || 0};
  align-items: center;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: space-between;
    padding-top: ${({ paddingTop }) => paddingTop || '3rem'};
  }
`;

export const SearchInputContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  @media ${({ theme }) => theme.media.md} {
    margin-right: 15px;
  }
`;

export const TotalSummaryWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 6rem;
  border-top: ${({ theme }) => `1px solid ${theme.nColors['n-grey2']}`};
  padding-top: 15px;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.white};
  padding: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    padding: 3rem;
  }
`;

export const TopRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    border: none;
  }
`;

export const TopLeftWrapper = styled.div`
  width: 100%;
`;

export const LeftWrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ width }) => width || '100%'};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary.black6}`};

  @media ${({ theme }) => theme.media.md} {
    justify-content: start;
    align-items: start;
    border: none;
  }
`;

export const SummaryWrapper = styled.div<{ width?: string }>`
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    width: 40%;
  }

  @media ${({ theme }) => theme.media.lg} {
    width: ${({ width }) => width || '100%'};
  }
`;

export const TextWrapper = styled.div<{ position?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ position }) => (position ? 'baseline' : 'unset')};
  margin-top: 1rem;

  @media ${({ theme }) => theme.media.md} {
    margin-top: 0;
    justify-content: ${({ position }) => (position ? 'baseline' : 'space-between')};
  }
`;

export const PaymentTextWrapper = styled.div<{ position?: string }>`
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
  align-items: ${({ position }) => (position ? 'baseline' : 'unset')};

  @media ${({ theme }) => theme.media.md} {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

export const TableWrapper = styled.div`
  table {
    min-width: 0;
    width: 100%;
  }

  tr {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 1rem;
    width: 100%;

    @media ${({ theme }) => theme.media.md} {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }

    @media ${({ theme }) => theme.media.lg} {
      display: table-row !important;
    }
  }
`;

export const DesktopAddNewItemWrapper = styled.div`
  display: none;

  svg {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
  }
`;

export const MobileAddNewItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  svg {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const DeleteIconWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 15px;

  svg {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.media.md} {
    margin-top: 0;
    justify-content: start;
  }

  @media ${({ theme }) => theme.media.lg} {
    justify-content: start;
    align-items: center;
    padding-top: 15.5px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: end;

  button {
    height: fit-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:first-child {
    margin-right: 10px;
  }
`;

export const SideContainer = styled.div`
  margin-top: 1rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary.black6}`};

  @media ${({ theme }) => theme.media.md} {
    border: none;
  }
`;

export const ViewInvoiceFooter = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.black1};
  padding: 10px;

  p {
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.white};
  }
`;

export const LabelWrapper = styled.label`
  margin-top: 2rem;
  display: flex;
  align-items: center;

  span {
    font-style: normal;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    letter-spacing: -0.02em;
    font-feature-settings: 'cv11' on;
    color: ${({ theme }) => theme.colors.primary.green1};
    margin-right: 5px;
  }
`;

const handleStateColor = (state: any, theme: DefaultTheme) => {
  // eslint-disable-next-line default-case
  switch (state) {
    case invoiceTypes.DRAFTS:
      return theme.nColors['n-grey5'];
    case invoiceTypes.PAID:
      return theme.nColors['n-green'];
    case invoiceTypes.OVERDUE:
      return theme.nColors['n-red'];
    case invoiceTypes.UNPAID:
      return theme.nColors['n-yellow7'];
  }
};

const handleBackgroundStateColor = (state: any, theme: DefaultTheme) => {
  // eslint-disable-next-line default-case
  switch (state) {
    case invoiceTypes.DRAFTS:
      return theme.nColors['n-grey1'];
    case invoiceTypes.PAID:
      return theme.nColors['n-green1'];
    case invoiceTypes.OVERDUE:
      return theme.nColors['n-red1'];
    case invoiceTypes.UNPAID:
      return theme.nColors['n-yellow1'];
  }
};

export const InvoiceStatusText = styled.div<{ state?: string }>`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.2rem 1rem;
  background-color: ${({ theme, state }) => handleBackgroundStateColor(state, theme)};

  p {
    color: ${({ theme, state }) => handleStateColor(state, theme)};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: 'cv11' on;
  }
`;

export const OverviewBalanceMiniTitle = styled.h3`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on, 'cv11' on;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const OutgoingOverviewCardWrapper = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

export const OutgoingOverviewCard = styled.div`
  @media ${({ theme }) => theme.media.md} {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  @media ${({ theme }) => theme.media.lg} {
    display: flex;
  }
`;

export const OutgoingOverviewCardSubWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const OutgoingOverviewCardText = styled.h4`
  color: ${({ theme }) => theme.nColors['n-dark']};
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 18px;
  font-feature-settings: 'cv11' on;
  @media ${({ theme }) => theme.media.lg} {
    font-size: 1.4rem;
    margin-bottom: 0.6rem;
  }
`;

export const OutgoingOverviewCardValue = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.primary.green1};
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
`;

export const PaymentInformationWrapper = styled.div<{ index: number }>`
  margin-bottom: 1rem;
  ${({ index }) =>
    String(index) === '1' &&
    `
  margin-left: 5rem;`}
`;

export const LabelText = styled.p`
  line-height: 150%;
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  padding-top: 16px;
  @media ${({ theme }) => theme.media.lg} {
    margin-top: 3rem;
    padding-top: 3.1rem;
    padding-bottom: 1.1rem;
  }
`;

export const BoldText = styled.p<{ largerFont?: boolean }>`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ largerFont }) => (largerFont ? '18px' : '16px')};
  margin-right: 0.5em;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const ClipBoardWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  height: 45px;
  border: 1px solid ${({ theme }) => theme.colors.primary.black6};
  border-radius: 5px;
`;

export const LinkButtonWrapper = styled.button`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary.black6};
  border: none;
  border-radius: 5px;
`;

export const PlaceHolderInput = styled.input`
  width: 80%;
  border: none;
  border-radius: 5px;
  padding: 10px;
`;

export const MoreActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    height: fit-content;
    width: fit-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MarkAsPaidWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  button {
    height: fit-content;
    width: fit-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const InvoiceHeaderWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const ViewInvoiceTableWrapper = styled.div``;

export const MerchantGridContent = styled.div``;
export const CustomerGridContent = styled.div``;
export const InvoiceDateGridContent = styled.div``;
export const StatusGridContent = styled.div``;

export const MerchantContent = styled.div``;
export const CustomerContent = styled.div``;
export const DateContent = styled.div``;
export const StatusContent = styled.div`
  @media ${({ theme }) => theme.media.lg} {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
`;

export const MerchantInvoiceIdContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
`;

export const GrayText = styled.p<{ boldText?: boolean; bottomMargin?: string; align?: string; title?: boolean }>`
  font-style: normal;
  font-size: 16px;
  line-height: 12px;
  margin-right: 0.5rem;
  margin-bottom: ${({ bottomMargin }) => bottomMargin || '1em'};
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: #717171;
  text-align: ${({ align }) => align || 'start'};
  font-weight: ${({ theme, boldText }) => (boldText ? theme.fontWeights.semiBold : theme.fontWeights.regular)};

  p:not(:first-child) {
    margin-top: 10px;
  }
  ${({ title }) =>
    title &&
    `
  font-weight: 500;
  `}
`;

export const SummaryKey = styled(GrayText)<{ totalKey?: boolean }>`
  color: ${({ theme, totalKey }) => (totalKey ? theme.nColors['n-grey5'] : theme.nColors['n-grey4'])};
  font-weight: ${({ totalKey }) => (totalKey ? 500 : 400)};
  line-height: 150%;
  font-size: ${({ totalKey }) => (totalKey ? '16px' : '14px')};
  letter-spacing: -0.019em;
`;

export const RightSummaryWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  width: 100%;
`;

export const RightSummaryInnerWrapper = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.media.md} {
    width: 50%;
  }

  @media ${({ theme }) => theme.media.lg} {
    width: 35%;
  }
`;

export const TotalWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.black5};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.black5};
  height: 8rem;
  display: flex;
`;

export const MessageAndTotalLabelWrapper = styled.div`
  width: 50%;
  border-right: 1px solid ${({ theme }) => theme.colors.primary.black5};
  display: flex;
  justify-content: end;
  align-items: center;

  @media ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
  }

  @media ${({ theme }) => theme.media.lg} {
    width: 80%;
  }
`;

export const TotalTextlWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;

  p {
    margin-left: 10px;
    text-align: right;
    width: 100%;
  }

  @media ${({ theme }) => theme.media.md} {
    width: 30%;
  }

  @media ${({ theme }) => theme.media.lg} {
    width: 20%;
  }
`;

export const MobileMessageTextWrapper = styled.div`
  display: block;
  margin-top: 1rem;
  @media ${({ theme }) => theme.media.md} {
    display: none;
    margin-top: 0;
  }
`;

export const DesktopMessageTextWrapper = styled.div`
  display: none;
  margin-top: 1rem;
  @media ${({ theme }) => theme.media.md} {
    display: block;
    margin-top: 0;
  }
`;

export const PaymentWrapper = styled.div<{ hasMultipleDetails: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 2rem;
  width: 100%;

  > ${PaymentInformationWrapper} {
    width: 50%;
    &:first-child {
      border-right: none;
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary.black4};
    }
  }

  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row;

    > ${PaymentInformationWrapper} {
      &:first-child {
        border-right: ${({ hasMultipleDetails, theme }) =>
          hasMultipleDetails ? `2px solid ${theme.colors.primary.black4}` : 'none'};
        border-bottom: none;
      }
    }
  }
`;

export const RegularGrayText = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 27px;
  margin-right: 0.5em;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: #717171 !important;
`;

export const TaxFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 0;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: row;
    width: 100%;
  }
`;

export const TaxInputWrapper = styled.div``;

export const InvoiceTitleHeader = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.primary.black1};
  padding: 1.7rem;

  p {
    color: ${({ theme }) => theme.colors.primary.white};
    font-weight: 500 !important;
  }

  @media ${({ theme }) => theme.media.lg} {
    background-color: ${({ theme }) => theme.colors.primary.white};
    padding: 0;
    p {
      color: ${({ theme }) => theme.colors.primary.black1};
      font-weight: 700 !important;
    }
  }
`;

export const ClearWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 15.5px;
  cursor: pointer;
  @media ${({ theme }) => theme.media.md} {
    justify-content: start;
  }
  @media ${({ theme }) => theme.media.lg} {
    align-items: center;
    jusitfy-content: center;
  }
`;

// view invoice mobile
export const ViewInvoiceMobileWrapper = styled.div``;

export const MobileFlexWrapper = styled.div<{
  direction?: string;
  gap?: number | string;
  justify?: string;
  margin?: string | number;
  padding?: string | number;
}>`
  ${({ direction, gap, justify, margin, padding }) => `
    display:flex;
    flex-direction: ${direction || 'row'};
    gap: ${gap || 'unset'};
    justify-content: ${justify || 'flex-start'};
    align-items:center;
    width: 100%;
    margin: ${margin || 0};
    padding: ${padding || 0};
    `}
`;

export const ViewInvoiceMobileTopSection = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.nColors['n-grey1']}`};
`;

export const InvoiceNo = styled(Typography)`
  line-height: 30px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
`;

export const KeyValueContainer = styled.div<{ align?: string }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  ${({ align }) =>
    align &&
    `
  align-items: ${align}`}
`;

export const InvoiceMobileKeyText = styled(Typography)`
  line-height: 24px;
`;

export const InvoiceMobileValueText = styled(Typography)<{ bold?: boolean; customColor?: boolean }>`
  line-height: 150%;
  letter-spacing: -0.019em;
  ${({ customColor }) =>
    customColor &&
    `
  color: #757575;
  `};
  ${({ bold }) =>
    bold &&
    `
  color: #1E1E1E;
  text-transform: capitalize;
  font-weight: 500;
`};
`;

// invoice mobile view detail section

export const InvoiceItemsDetailSection = styled.div`
  h3 {
    font-weight: 600;
    padding-top: 20px;
    padding-left: 20px;
  }
  padding-bottom: 25px;
`;

export const InvoiceDetailListItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  div.detailsContainer {
    width: 100%;
    padding: 20px 20px 40px;
    border-bottom: ${({ theme }) => `1px solid ${theme.nColors['n-grey1']}`};
    display: flex;
    gap: 10px;
  }
  p.description {
    padding-bottom: 15px;
    border-bottom: ${({ theme }) => `1px solid ${theme.nColors['n-grey1']}`};
  }
  div.details {
    width: 100%;
  }
  div.qtyNAmount {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  div.qty,
  div.amount {
    display: flex;
    gap: 10px;
  }
`;

// pricing section
export const PricingSection = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  div {
    display: flex;
    justify-content: space-between;
  }
`;

export const TotalSection = styled.div`
  display: flex;
  border-top: ${({ theme }) => `1px solid ${theme.nColors['n-grey2']}`};
  width: 100%;
  justify-content: space-between;
  p {
    font-weight: 500;
    padding: 13px 20px;
  }
  div.value {
    display: flex;
  }
  div.vert {
    border: ${({ theme }) => `1px solid ${theme.nColors['n-grey2']}`};
  }
  border-bottom: ${({ theme }) => `1px solid ${theme.nColors['n-grey2']}`};
`;

export const OptionMessageContainer = styled.div`
  display: flex;
  padding: 25px 20px;
`;

export const AccountSection = styled.div`
  display: flex;
  padding: 30px 20px;
  flex-direction: column;
  gap: 20px;
  background: rgba(238, 238, 238, 0.3);
  h3 {
    font-weight: 500;
  }

  div.accountInfoList {
    display: flex;
    flex-direction: column;
    gap: 7px;
    div {
      display: flex;
      gap: 5px;
    }
  }
`;

export const MobileFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px;
  align-items: center;
  p {
    line-height: 150%;
  }
`;

export const EmptyTableWrapper = styled.div<{ svgWidth?: string }>`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  svg {
    width: ${({ svgWidth }) => svgWidth || '200px !important'};
    height: auto;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
    color: #717171;
  }

  @media ${({ theme }) => theme.media.lg} {
    svg {
      width: ${({ svgWidth }) => svgWidth || '260px !important'};
      height: auto;
    }

    p {
      margin-bottom: 7.5rem;
      font-size: 16px;
      line-height: 24px;
      max-width: 38rem;
    }
  }
`;

export const OutlinedButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.nColors['n-grey2']};
  height: 38px;
  padding: 0 10px;
  background: none;
  span {
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;
