import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const paymentsSlice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {},
});

export default paymentsSlice.reducer;
