import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetAllNotificationsResponse,
  NotificationsApiAxiosParamCreator,
  ToggleNotificationsResponse,
  SuccessfulNotificationsResponse,
  ArchiveNotificationsResponse,
} from '../../../api_spec_client';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../app/store';
import { ReduxAction } from '../../../types/redux';
import axios from '../../../utils/http';
import { Notification } from '../types';

// ----------------- FETCH ALL NOTIFICATIONS ----------------//

export const fetchAllNotifications = createAsyncThunk<GetAllNotificationsResponse, ReduxAction, { state: RootState }>(
  '/notifications/fetchAllNotifications',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    try {
      const {
        notifications: {
          pagination: { page, pageSize },
          filter: { status, category },
        },
      } = getState();
      const apiClient = NotificationsApiAxiosParamCreator();

      const requestOptions = await apiClient.getAllNotifications(page, pageSize, status, category);

      const response: GetAllNotificationsResponse = await axios.get(requestOptions.url, {
        ...requestOptions.options,
      });

      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ----------------- MARK ALL AS READ ----------------//

export const markAllNotifications = createAsyncThunk<GetAllNotificationsResponse, ReduxAction, { state: RootState }>(
  '/notifications/markAllNotifications',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    try {
      const {
        notifications: {
          filter: { category },
        },
      } = getState();
      const apiClient = NotificationsApiAxiosParamCreator();

      const requestOptions = await apiClient.markAllAsRead(category);

      const { data }: { data: GetAllNotificationsResponse } = await axios.post(requestOptions.url, {
        ...requestOptions.options,
      });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ----------------- ARCHIVE NOTIFICATIONS ----------------//
interface ArchiveNotificationsArgs extends ReduxAction {
  notificationIds?: Array<number>;
}

export const ArchiveNotifications = createAsyncThunk<
  ArchiveNotificationsResponse,
  ArchiveNotificationsArgs,
  { state: RootState }
>(
  '/notifications/archiveNotification',
  async ({ onSuccess, onFailure, notificationIds }, { rejectWithValue, getState }) => {
    try {
      const {
        notifications: { checkedList, notifications },
      } = getState();
      const apiClient = NotificationsApiAxiosParamCreator();

      const requestOptions = await apiClient.markManyAsArchived(
        notifications.filter((item) => checkedList.includes(item.__access_key)).map((item) => item.id as number)
      );

      const { data }: { data: ArchiveNotificationsResponse } = await axios.delete(
        requestOptions.url,
        requestOptions.options
      );

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ----------------- DELETE NOTIFICATIONS ----------------//
interface DeleteNotificationsArgs extends ReduxAction {
  notificationId: number;
}

export const deleteNotification = createAsyncThunk<
  ArchiveNotificationsResponse,
  DeleteNotificationsArgs,
  { state: RootState }
>('/notifications/deleteNotification', async ({ onSuccess, onFailure, notificationId }, { rejectWithValue }) => {
  try {
    const apiClient = NotificationsApiAxiosParamCreator();

    const requestOptions = await apiClient.archiveNotification(notificationId);

    const { data }: { data: ArchiveNotificationsResponse } = await axios.delete(
      requestOptions.url,
      requestOptions.options
    );

    if (onSuccess) onSuccess(data);
    return data;
  } catch (error) {
    if (onFailure) onFailure(error);
    return rejectWithValue({ error });
  }
});

// ----------------- TOGGLE NOTIFICATIONS ----------------//
interface ToggleNotificationArgs extends ReduxAction {
  notification: Notification;
}

export const toggleNotification = createAsyncThunk<
  ToggleNotificationsResponse,
  ToggleNotificationArgs,
  { state: RootState }
>(
  '/notifications/toggleNotification',
  async ({ onSuccess, onFailure, notification }, { rejectWithValue, getState }) => {
    if (!notification.id) return rejectWithValue({});
    try {
      const apiClient = NotificationsApiAxiosParamCreator();

      const requestOptions = await apiClient.toggleNotification(notification?.id);

      const { data }: { data: ToggleNotificationsResponse } = await axios.patch(requestOptions.url);

      if (onSuccess) onSuccess(data);

      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ----------------- READ NOTIFICATIONS ----------------//

export const MarkNotificationsRead = createAsyncThunk<
  SuccessfulNotificationsResponse,
  ReduxAction,
  { state: RootState }
>('/notifications/readNotification', async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
  const {
    notifications: { notifications, checkedList },
  } = getState();
  try {
    const apiClient = NotificationsApiAxiosParamCreator();

    const requestOptions = await apiClient.markManyAsRead(
      notifications.filter((item) => checkedList.includes(item.__access_key)).map((item) => item.id as number)
    );

    const { data }: { data: SuccessfulNotificationsResponse } = await axios.post(
      requestOptions.url,
      requestOptions.options.data,
      { ...requestOptions.options }
    );

    if (onSuccess) onSuccess(data);
    return data;
  } catch (error) {
    if (onFailure) onFailure(error);
    return rejectWithValue({ error });
  }
});
