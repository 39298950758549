import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { SummaryOverallData, CardBankData, CardStatusBankData, ICardProviderData } from '../../../types/serviceStatus';
import { fetchSummaryData, fetchPerformanceData, fetchGraphData } from './thunkActions';

interface InitialState {
  summaryData: SummaryOverallData;
  performanceData: Array<CardBankData>;
  graphData: Array<CardStatusBankData>;
  cardProviderData: Array<ICardProviderData>;
  isLoading: boolean;
}

const initialState: InitialState = {
  isLoading: false,
  summaryData: {
    BankData: [],
    leastBankData: [],
    overAllTrend: '',
    overAllPercentageReliability: 0,
    topPercentageReliability: 0,
    topPerformerTrend: 0,
    leastPercentageReliability: 0,
    leastPerformerTrend: '',
  },
  performanceData: [],
  graphData: [],
  cardProviderData: [],
};

export const cardBankPerformanceSlice = createSlice({
  name: 'cardBankPerformance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch summary data
    builder
      .addCase(fetchSummaryData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSummaryData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchSummaryData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.summaryData = payload;
      });

    // fetch card bank data
    builder
      .addCase(fetchPerformanceData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPerformanceData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchPerformanceData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.performanceData = payload?.cardBankData || [];
      });

    // fetch graph data
    builder
      .addCase(fetchGraphData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGraphData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchGraphData.fulfilled, (state, { payload }) => {
        payload?.cardStatusBankData.forEach((item) => {
          item.dateTime = moment.unix((item.dateTime as number) / 1000).format('HH:mm');
        });
        state.isLoading = false;
        state.cardProviderData = payload?.cardProviderData || [];
        state.graphData = payload?.cardStatusBankData || [];
      });
  },
});

export default cardBankPerformanceSlice.reducer;
export * from './thunkActions';
