import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { notifictionId } from './constants';

export const firebaseConfig = {
  apiKey: 'AIzaSyAn-aVDj9zf0BLm59NAdPGXZC43n3vvfCc',
  authDomain: 'business-banking-93cc1.firebaseapp.com',
  databaseURL: 'https://business-banking-93cc1-default-rtdb.firebaseio.com',
  projectId: 'business-banking-93cc1',
  storageBucket: 'business-banking-93cc1.appspot.com',
  messagingSenderId: '374159656381',
  appId: '1:374159656381:web:46ded5449543474131fed4',
  measurementId: 'G-MG8RQTWYWF',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const firebaseMessaging = firebase.messaging();

export const getToken = () =>
  firebaseMessaging
    .getToken({
      vapidKey: 'BL4VzvwPeYIa8p8I-6Vcw1Blzzw_zyG5D--DOZ5D5w2bZAWxFhKlN_IGM5aMTrTyIqNPt-GN0X1xvEFCSUD-L78',
    })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem(notifictionId, currentToken);
      }
    })
    .catch((err) => {});
