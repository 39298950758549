import { Discount } from '../../../types/inventory/discounts';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface DiscountsResponse extends ApiResponse {
  data: {
    list: Discount[];
    pageToken: string;
  };
}

const fetchDiscounts = async ({ params }: { params?: URLSearchParams }) => {
  const url = `/webbe/v1/storehouse/stores/discounts/filter?${params ? params.toString() : ''}`;
  try {
    const result: DiscountsResponse = await axios.get(url);
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchDiscounts;
