import { Button, Typography } from 'kudi-component-library';
import styled from 'styled-components';
import OutletModalIcon from './outlet-modal-icon';

export const Wrapper = styled.div<{ isCompact?: boolean; padding?: string }>`
  display: flex;
  padding: ${({ padding }) => padding || '30px'};
  flex-direction: column;
  margin-top: ${({ isCompact }) => (isCompact ? '0' : '30px')};
  margin-bottom: ${({ isCompact }) => (isCompact ? '0' : '20px')};
`;
export const HeaderWrapper = styled.div<{ isCompact?: boolean }>`
  text-align: center;
  margin-bottom: ${({ isCompact }) => (isCompact ? '0' : '40px')};

  p {
    margin: ${({ isCompact }) => (isCompact ? '5px 0' : '20px 0')};
  }
`;
export const IconWrapper = styled.div<{ isCompact: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ isCompact }) => (isCompact ? '5px' : '20px')};
`;
export const CancelTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const CancelText = styled(Typography)`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  padding-bottom: 5px;
  margin: 20px 0;
  width: fit-content;
`;
export const FormInputWrapper = styled.div<{ width?: string; mb?: string }>`
  margin-bottom: ${({ mb }) => mb || '20px'};
  width: 100%;

  div {
    margin-bottom: 20px;
  }

  @media ${({ theme }) => theme.media.sm} {
    width: ${({ width }) => width || '441px'};
  }
`;

const OutletModalContent = ({
  title,
  description,
  continueButtonText,
  cancelButtonText,
  onCancel,
  onContinue,
  isLoading,
  children,
  icon,
  hasIcon = true,
  isCompact = false,
}: {
  title: string;
  description?: string;
  continueButtonText: string;
  cancelButtonText?: string;
  onCancel?: () => void;
  onContinue: () => void;
  isLoading?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  hasIcon?: boolean;
  isCompact?: boolean;
}) => (
  <Wrapper isCompact={isCompact}>
    {hasIcon ? <IconWrapper isCompact={isCompact}>{icon || <OutletModalIcon />}</IconWrapper> : null}
    <HeaderWrapper isCompact={isCompact}>
      <Typography variant="n-text5" color="dark">
        {title}
      </Typography>
      {description ? (
        <Typography variant="n-text2" color="grey4">
          {description}
        </Typography>
      ) : null}
    </HeaderWrapper>

    {children}

    <Button variant="medium" width="100%" onClick={onContinue} loading={isLoading} disabled={isLoading}>
      {continueButtonText}
    </Button>
    {cancelButtonText && onCancel ? (
      <CancelTextWrapper>
        <CancelText variant="n-text2" color="grey4" onClick={onCancel}>
          {cancelButtonText}
        </CancelText>
      </CancelTextWrapper>
    ) : null}
  </Wrapper>
);

export default OutletModalContent;
