import { AxiosResponse } from 'axios';
import axios from '../../utils/merchantAcqServiceHttp';
import { MenuListProduct } from '../../types/menu/menuList';

interface BulkItemsResponse extends AxiosResponse {
  failedItems: { id: number; name: string }[];
  items: MenuListProduct[];
}
const postUploadBulkItems = async (payload: { file: FormData; storeId: string }) => {
  try {
    const result: BulkItemsResponse = await axios.post(`/webbe/v1/storehouse/items/bulk`, payload.file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default postUploadBulkItems;
