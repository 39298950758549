/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { BeneficiariesData, TransferList, BulkTransferUsersData, SchedulePaymentData } from '../../types/transactions';
import { TransferRequestResponse } from '../../pages/transfers/transfersrequest/utils';

// ---------------------------   FETCH BENEFICIARIES START   ---------------------------//

export interface FetchBeneficiariesResponse extends ApiResponse {
  data: BeneficiariesData[];
  msg?: string;
}

export interface FetchBeneficiariesArgs extends ReduxAction {
  page: number;
}

export const fetchBeneficiaries = createAsyncThunk(
  '/vas/beneficiary/findAll',
  async ({ page, onSuccess, onFailure }: FetchBeneficiariesArgs, { rejectWithValue }) => {
    try {
      const response: FetchBeneficiariesResponse = await axios.get(`/vas/beneficiary/v1/findAll?page=${page}`);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH BENEFICIARIES END   ---------------------------//

// ---------------------------   ACCOUNT LOOK UP START   ---------------------------//

export interface AccountLookUpResponse extends ApiResponse {
  data: {
    data: {
      accountName: string;
    };
    status: string;
    successful: boolean;
  };
  message: string;
  dataList?: null;
  meta?: null;
}

export interface AccountLookUpArgs extends ReduxAction {
  accountNumber: string;
  bankCode: string;
}

export const accountLookUp = createAsyncThunk(
  '/vas/beneficiary/lookup',
  async ({ bankCode, accountNumber, onSuccess, onFailure }: AccountLookUpArgs, { rejectWithValue }) => {
    try {
      const { data }: AccountLookUpResponse = await axios.post(`/vas/beneficiary/v1/lookup`, {
        bankCode,
        accountNumber,
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   ACCOUNT LOOK UP END   ---------------------------//

// ---------------------------   KYC ACCOUNT LOOK UP START   ---------------------------//
export interface KycAccountLookUpResponse {
  status: boolean;
  message: string;
  data: {
    message: string;
    accountName: string;
    bankUnavailable: boolean;
    insufficientFunds: boolean;
    kycLimitExceeded: boolean;
    transactionCharge: number;
    kycLevel: number;
    successful: boolean;
  };
  dataList?: null;
  meta?: null;
  error?: null;
}

export interface KycAccountLookUpArgs extends ReduxAction {
  userId: string | null | undefined;
  accountNumber: string;
  bankCode: string;
  amount: string;
  type: string;
}

export const kycAccountLookUp = createAsyncThunk(
  '/vas/payouts/v1/bank/lookup',
  async (
    { userId, bankCode, accountNumber, amount, type, onSuccess, onFailure }: KycAccountLookUpArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: KycAccountLookUpResponse = await axios.post(`/vas/payouts/v1/bank/lookup`, {
        userId,
        bankCode,
        accountNumber,
        amount,
        type,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------    ACCOUNT LOOK UP END   ---------------------------//

// ---------------------------   ADD BENEFICIARY START   ---------------------------//

export interface AddBeneficiaryArgs extends ReduxAction {
  accountNumber: string;
  bankCode: string;
  bankName: string;
  beneficiaryName: string;
  nickName: string;
}

export const addBeneficiary = createAsyncThunk(
  '/vas/beneficiary/create',
  async (
    { bankCode, accountNumber, bankName, beneficiaryName, nickName, onSuccess, onFailure }: AddBeneficiaryArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ReduxAction = await axios.post(`/vas/beneficiary/v1/create`, {
        bankCode,
        accountNumber,
        bankName,
        beneficiaryName,
        nickName,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   ADD BENEFICIARY END   ---------------------------//

// ---------------------------   DELETE BENEFICIARY START   ---------------------------//

interface DeleteBeneficiaryArgs extends ReduxAction {
  id: number | string | null;
}

export const deleteBeneficiary = createAsyncThunk(
  '/vas/beneficiary/delete',
  async ({ id, onSuccess, onFailure }: DeleteBeneficiaryArgs, { rejectWithValue }) => {
    try {
      if (!id) {
        throw new Error('id not supplied');
      }
      const response: ReduxAction = await axios.delete(`/vas/beneficiary/v1/delete/${id}`);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   DELETE BENEFICIARY END   ---------------------------//

// ---------------------------   EDIT BENEFICIARY START   ---------------------------//

export interface EditBeneficiaryArgs extends ReduxAction {
  beneficiaryId: string;
  accountNumber: string;
  beneficiaryName: string;
  bankName: string;
  bankCode: string;
  nickName: string;
}

export const editBeneficiary = createAsyncThunk(
  '/vas/beneficiary/edit',
  async (
    {
      beneficiaryId,
      bankCode,
      accountNumber,
      bankName,
      beneficiaryName,
      nickName,
      onSuccess,
      onFailure,
    }: EditBeneficiaryArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ReduxAction = await axios.put(`/vas/beneficiary/v1/edit`, {
        beneficiaryId,
        bankCode,
        accountNumber,
        bankName,
        beneficiaryName,
        nickName,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   EDIT BENEFICIARY END   ---------------------------//

// ---------------------------   NOMBA ACCOUNT LOOK UP START   ---------------------------//

export interface NombaAccountLookUpResponse extends ApiResponse {
  data: {
    data: {
      accountName: string;
    };
    message?: string;
    transactionCharge?: number;
    status?: string;
  };
}

export interface NombaAccountLookUpArgs extends ReduxAction {
  userId: string | null | undefined;
  amount: string;
  accountNumber: string;
  type: string;
}

export const nombaAccountLookUp = createAsyncThunk(
  '/vas/payouts/v1/nomba/lookup',
  async (
    { userId, amount, accountNumber, type, onSuccess, onFailure }: NombaAccountLookUpArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: NombaAccountLookUpResponse = await axios.post(`/vas/payouts/v1/nomba/lookup`, {
        userId,
        amount,
        accountNumber,
        type,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// --------------------------- NOMBA ACCOUNT LOOK UP END   ---------------------------//

// ---------------------------   FETCH TRANSFERS START   ---------------------------//

export interface FetchTransfersResponse extends ApiResponse {
  data: {
    transfers: TransferList[];
  };
  msg?: string;
}

export interface SearchTransfersArgs extends ReduxAction {
  filter?: string;
  page: number;
}

export const fetchTransfers = createAsyncThunk(
  '/vas/payouts/v1/transfers',
  async ({ filter, page, onSuccess, onFailure }: SearchTransfersArgs, { rejectWithValue }) => {
    try {
      const response: FetchTransfersResponse = await axios.post(`/vas/payouts/v1/transfers?page=${page}`, { filter });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);
export interface TransferRequestsArgs extends ReduxAction {
  page: number;
  size: number;
  dateFrom: string;
  dateTo: string;
  status: string;
}

export const fetchTransfersRequest = createAsyncThunk(
  '/vas/payouts/v1/payment-request',
  async ({ page, size, dateFrom, dateTo, status, onSuccess, onFailure }: TransferRequestsArgs, { rejectWithValue }) => {
    try {
      const response: TransferRequestResponse = await axios.get(
        `/vas/payouts/v1/payment-request?size=${size}&page=${page}&dateFrom=${dateFrom}&dateTo=${dateTo}&status=${status}`
      );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH TRANSFERS END   ---------------------------//

// ---------------------------   SINGLE TRANSFER TO NOMBA START   ---------------------------//

export interface TransferToNombaArgs extends ReduxAction {
  email: string;
  beneficiaryName: string;
  amount: number;
  frequency?: string;
  transferType: string;
  scheduledDate: string | null;
  repeatPayment: boolean;
  userPin: string;
}

export interface TransferToNombaResponse {
  data: {
    status: string;
    otpDest: string;
    coreRef: string;
  };
}

export const transferToNombaAccount = createAsyncThunk(
  '/vas/single-transfer-to-nomba',
  async (
    {
      email,
      beneficiaryName,
      amount,
      frequency,
      transferType,
      scheduledDate,
      repeatPayment,
      userPin,
      onSuccess,
      onFailure,
    }: TransferToNombaArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: TransferToNombaResponse = await axios.post(`/vas/payouts/v1/single-transfer-to-nomba`, {
        email,
        beneficiaryName,
        amount,
        frequency,
        transferType,
        scheduledDate,
        repeatPayment,
        userPin,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SINGLE TRANSFER TO NOMBA END   ---------------------------//

// ---------------------------   SINGLE TRANSFER TO BANK START   ---------------------------//

export interface SingleTransferToBankArgs extends ReduxAction {
  bankName: string;
  bankCode: string;
  beneficiaryName: string;
  account: string;
  amount: number;
  transferType: string;
  frequency?: string;
  scheduledDate: string;
  repeatPayment: boolean;
  accountSweep?: boolean;
  narration?: string;
  userPin?: string;
}

export const transferToBankAccount = createAsyncThunk(
  '/vas/single-transfer-to-bank',
  async (
    {
      bankName,
      bankCode,
      account,
      beneficiaryName,
      amount,
      transferType,
      frequency,
      scheduledDate,
      repeatPayment,
      accountSweep,
      narration,
      userPin,
      onSuccess,
      onFailure,
    }: SingleTransferToBankArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ReduxAction = await axios.post(`/vas/payouts/v1/single-transfer-to-bank`, {
        bankName,
        bankCode,
        account,
        beneficiaryName,
        amount,
        transferType,
        frequency,
        scheduledDate,
        repeatPayment,
        accountSweep,
        narration,
        userPin,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SINGLE TRANSFER TO BANK END   ---------------------------//

// ---------------------------   UPLOAD SAMPLE DOCUMENT START   ---------------------------//

export interface UploadDocArg extends ReduxAction {
  file: File;
}

export const uploadSampleDocument = createAsyncThunk(
  '/vas/upload-sample-bank',
  async ({ file, onSuccess, onFailure }: UploadDocArg, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      let progress;
      const response: ReduxAction = await axios.post(`/vas/payouts/v1/upload-sample-bank`, formData, {
        onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
          // progress = (progressEvent.loaded / progressEvent.total) * 50;
          const { loaded, total } = progressEvent;
          progress = Math.floor((loaded * 100) / total);
        },
      });
      const finalResponse = {
        response,
        progress,
      };
      if (onSuccess) onSuccess(finalResponse);
      return finalResponse;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   UPLOAD SAMPLE DOCUMENT END   ---------------------------//

// ---------------------------   BULK TRANSFER TO BANK START   ---------------------------//

export interface BulkTransferBankArgs extends ReduxAction {
  userPin: string;
  memo: string;
  saveBeneficiaries: boolean;
  transferType: string;
  scheduledDate?: string;
  paymentRequestList: BulkTransferUsersData[];
}

export const bulkTransferToBank = createAsyncThunk(
  '/vas/payouts/v1/bulk-transfer-to-bank',
  async (
    {
      userPin,
      memo,
      saveBeneficiaries,
      transferType,
      scheduledDate,
      paymentRequestList,
      onSuccess,
      onFailure,
    }: BulkTransferBankArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ReduxAction = await axios.post(`/vas/payouts/v1/bulk-transfer-to-bank`, {
        userPin,
        memo,
        saveBeneficiaries,
        transferType,
        scheduledDate,
        paymentRequestList,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   BULK TRANSFER TO BANK END   ---------------------------//

// ---------------------------   FETCH SCHEDULED PAYMENT START   ---------------------------//

export interface FetchScheduledPaymentResponse extends ApiResponse {
  data: {
    paymentRequestList: SchedulePaymentData[];
  };
  msg?: string;
}

export const fetchAllScheduledPayments = createAsyncThunk(
  '/vas/all-scheduled-transactions',
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const response: FetchScheduledPaymentResponse = await axios.get('/vas/payouts/v1/all-scheduled-transactions');
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH SCHEDULED PAYMENT END   ---------------------------//

// ---------------------------   EDIT SCHEDULED PAYMENT START   ---------------------------//

export interface EditScheduledPaymentArgs extends ReduxAction {
  reference: string;
  scheduledDate: string;
  frequency: string;
  repeatPayment: boolean;
  amount: number;
}

export const editScheduledPayment = createAsyncThunk(
  '/vas/edit-scheduled-transfer',
  async (
    { reference, scheduledDate, frequency, repeatPayment, amount, onSuccess, onFailure }: EditScheduledPaymentArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: ReduxAction = await axios.put(`/vas/payouts/v1/edit-scheduled-transfer`, {
        reference,
        scheduledDate,
        frequency,
        repeatPayment,
        amount,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   EDIT SCHEDULED PAYMENT END   ---------------------------//

// ---------------------------   CANCEL SCHEDULED PAYMENT START   ---------------------------//

interface CancelScheduledPaymentArgs extends ReduxAction {
  id: number | string | null;
}

export const cancelScheduledPayment = createAsyncThunk(
  '/vas/cancel-scheduled-transfer',
  async ({ id, onSuccess, onFailure }: CancelScheduledPaymentArgs, { rejectWithValue }) => {
    try {
      if (!id) {
        throw new Error('id not supplied');
      }
      const response: ReduxAction = await axios.delete(`/vas/payouts/v1/cancel-scheduled-transfer/${id}`);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   CANCEL SCHEDULED PAYMENT END   ---------------------------//

// ---------------------------   SERVICE CHARGE START   ---------------------------//

interface ServiceChargeArgs extends ReduxAction {
  amount: number | string;
  isNomba?: boolean;
}

export const getServiceCharge = createAsyncThunk(
  '/vas/service-charge',
  async ({ amount, isNomba, onSuccess, onFailure }: ServiceChargeArgs, { rejectWithValue }) => {
    try {
      if (!amount) {
        throw new Error('amount not supplied');
      }

      if (isNomba) {
        const response: ReduxAction = await axios.get(`/vas/payouts/v1/service-charge/${amount}?to-bank=false`);
        if (onSuccess) onSuccess(response);
        return response;
      }
      const response2: ReduxAction = await axios.get(`/vas/payouts/v1/service-charge/${amount}`);
      if (onSuccess) onSuccess(response2);
      return response2;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SERVICE CHARGE END   ---------------------------//

// ---------------------------   SEARCH BENEFICIARIES START   ---------------------------//

interface SearchBeneficiariesArgs extends ReduxAction {
  filter: string;
  page: number;
}

export const searchBeneficiaries = createAsyncThunk(
  '/vas/beneficiary/v1/filterBeneficiary',
  async ({ filter, page, onSuccess, onFailure }: SearchBeneficiariesArgs, { rejectWithValue }) => {
    try {
      if (!filter) {
        throw new Error('filter not supplied');
      }
      const response: ReduxAction = await axios.get(`/vas/beneficiary/v1/filterBeneficiary/${filter}?page=${page}`);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SEARCH BENEFICIARIES END   ---------------------------//

// ---------------------------   SEARCH SCHEDULED START   ---------------------------//

interface SearchScheduledArgs extends ReduxAction {
  param: string;
}

export const searchScheduledPayments = createAsyncThunk(
  '/vas/payouts/v1/filter-all-scheduled-transactions',
  async ({ param, onSuccess, onFailure }: SearchScheduledArgs, { rejectWithValue }) => {
    try {
      if (!param) {
        throw new Error('param not supplied');
      }
      const response: ReduxAction = await axios.get(`/vas/payouts/v1/filter-all-scheduled-transactions/${param}`);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SEARCH SCHEDULED END   ---------------------------//

// ---------------------------   SEND RECEIPT TO EMAIL START   ---------------------------//

export interface SendReceiptArgs extends ReduxAction {
  reference: string;
  emailList: string[];
}

export const sendReceiptsToEmails = createAsyncThunk(
  '/vas/payouts/v1/send-payment-receipt-to-email',
  async ({ reference, emailList, onSuccess, onFailure }: SendReceiptArgs, { rejectWithValue }) => {
    try {
      const response: ReduxAction = await axios.post(`/vas/payouts/v1/send-payment-receipt-to-email`, {
        reference,
        emailList,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   SEND RECEIPT TO EMAIL END   ---------------------------//

// ---------------------------   FILTER BENEFICIARIES START   ---------------------------//

export interface FilterBeneficiariesResponse extends ApiResponse {
  data: BeneficiariesData[];
  msg?: string;
}

export interface FilterBeneficiariesArgs extends ReduxAction {
  bankName: string | Date | null;
  startDate: string | Date | null;
  endDate: string | Date | null;
  page: number;
}

export const filterBeneficiariesBackend = createAsyncThunk(
  '/vas/beneficiary/filter',
  async (
    { bankName, startDate, endDate, page, onSuccess, onFailure }: FilterBeneficiariesArgs,
    { rejectWithValue }
  ) => {
    try {
      const response: FilterBeneficiariesResponse = await axios.post(
        `/vas/beneficiary/v1/filterBeneficiaries?page=${page}`,
        {
          bankName,
          startDate,
          endDate,
        }
      );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FILTER BENEFICIARIES  END   ---------------------------//

// ---------------------------   FILTER TRANSFERS START   ---------------------------//

export interface FilterTransfersResponse extends ApiResponse {
  data: TransferList[];
  msg?: string;
}

export interface FilterTransfersArgs extends ReduxAction {
  status: string | Date | null;
  startDate: string | Date | null;
  endDate: string | Date | null;
  page: number;
  onSuccess: (response: FilterTransfersResponse) => void;
  onFailure: (error: any) => void;
}

export const filterTransfersBackend = createAsyncThunk(
  '/vas/transfers/filter',
  async ({ status, startDate, endDate, page, onSuccess, onFailure }: FilterTransfersArgs, { rejectWithValue }) => {
    try {
      const response: FilterTransfersResponse = await axios.post(`/vas/payouts/v1/filter-transactions?page=${page}`, {
        status,
        startDate,
        endDate,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   FILTER TRANSFERS  END   ---------------------------//

// ---------------------------   DOWNLOAD TRANSFER CSV START   ---------------------------//

export interface DownloadTransfersCsvArgs extends ReduxAction {
  status: string;
  dateFrom: string;
  dateTo: string;
}

export const downloadTransfersCsv = createAsyncThunk(
  '/vas/payouts/v1/export-transfers-to-csv',
  async ({ status, dateFrom, dateTo, onSuccess, onFailure }: DownloadTransfersCsvArgs, { rejectWithValue }) => {
    try {
      const response: ApiResponse = await axios.get(
        `/vas/payouts/v1/export-transfers-to-csv/${status}?dateFrom=${dateFrom}&dateTo=${dateTo}`
      );
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   DOWNLOAD TRANSFER CSV  END   ---------------------------//

// ---------------------------   VERIFY OTP START   ---------------------------//
export interface VerifyOtpArgs extends ReduxAction {
  transactionRef: string;
  transactionOtp: string;
}

export const verifyOtp = createAsyncThunk(
  '/vas/payouts/v1/validate-otp',
  async ({ transactionRef, transactionOtp, onSuccess, onFailure }: VerifyOtpArgs, { rejectWithValue }) => {
    try {
      const response: ApiResponse = await axios.post(`/vas/payouts/v1/validate-otp`, {
        transactionRef,
        transactionOtp,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   VERIFY OTP END   ---------------------------//

// ---------------------------   RESEND OTP START   ---------------------------//
export interface ResendOtpArgs extends ReduxAction {
  transactionRef: string;
  otpType: string;
}

export const resendOtp = createAsyncThunk(
  '/vas/payouts/v1/resend-otp',
  async ({ transactionRef, otpType, onSuccess, onFailure }: ResendOtpArgs, { rejectWithValue }) => {
    try {
      const response: ApiResponse = await axios.post(`/vas/payouts/v1/resend-otp`, {
        transactionRef,
        otpType,
      });
      if (onSuccess) onSuccess(response);
      return response;
    } catch (error) {
      if (onFailure) onFailure(error);
      rejectWithValue({ error });
    }
  }
);

// ---------------------------   RESEND OTP END   ---------------------------//
