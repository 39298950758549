import styled from 'styled-components';
import { Typography, NTheme as theme } from 'kudi-component-library';

const TransfersNavigationTabWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 0.4rem;
  background-color: ${theme.colors.primary.white};
  height: 50px;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;

  @media ${theme.media.lg} {
    justify-content: flex-start;
  }
`;

const TransfersNavigationItem = styled(Typography)<{ active?: boolean }>`
  padding: 0 1.5rem;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  text-transform: capitalize;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media ${theme.media.lg} {
    padding: 0 3rem;
    margin-right: 2rem;
  }

  ${({ active }) =>
    active
      ? `::after {
    content: '';
    height: 3px;
    width: 100%;
    background-color: ${theme.colors.primary.blue1};
    position: absolute;
    bottom: -0.7rem;
    left: 0;
  }`
      : ''}
`;

interface NavigationTabProps {
  options: {
    text: string;
    value: string;
    position: number;
  }[];
  setActive: (tab: number) => void;
  activeTab: number;
}

const Transferstabs = ({ options, setActive, activeTab }: NavigationTabProps) => (
  <TransfersNavigationTabWrapper>
    {options.map((item, index) => (
      <TransfersNavigationItem
        variant="n-text3"
        color={activeTab === item.position ? 'dark' : 'grey3'}
        key={`Tab-nav${index}`}
        active={activeTab === item.position}
        onClick={() => setActive(item.position)}
      >
        {item.text}
      </TransfersNavigationItem>
    ))}
  </TransfersNavigationTabWrapper>
);

export default Transferstabs;
