import HttpConfig from './httpConfig';
import appConfig from './config';

class MerchantService extends HttpConfig {}

const useWebbeHeaders = true;

const merchantAcqHttp = new MerchantService(appConfig.merchantAcqServiceUrl, useWebbeHeaders);

export default merchantAcqHttp;
