import { IssueCategory } from '../defs';
import { selectIssueCategory, selectIssueSubCategory, updateIssueCreationStage } from '../reducer/slice';
import useSubCategorySelection from './subCategorySelection';

const useCategorySelection = () => {
  const { dispatch, handleSubCategoryClick, categories, subCategories } = useSubCategorySelection();

  // Category click
  const handleCategoryClick = (category: IssueCategory) => {
    dispatch(selectIssueCategory(category));
    if (!Object.keys(subCategories).includes(String(category.index))) {
      handleSubCategoryClick(category);
    } else {
      dispatch(selectIssueSubCategory(null));
      dispatch(updateIssueCreationStage('type'));
    }
  };

  // List of categories
  const List = categories.map((item) => ({
    action: () => {
      handleCategoryClick(item);
    },
    ...item,
  }));

  return { List };
};

export default useCategorySelection;
