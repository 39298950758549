const useStepCounter = () => {
  const totalStep = 4;
  function cleanPercentage(percentage: number) {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
  }
  return { totalStep, cleanPercentage };
};

export default useStepCounter;
