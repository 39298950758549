/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  login,
  fetchUser,
  signup,
  createBusiness,
  sendToken,
  setPassword,
  updateUserOnVerification,
  getUserRegistration,
  getBoltUserByPhone,
  UpgradeBusinessKYCLevel,
} from './thunkActions';
import { FullUser, BusinessAccountCreation, BoltPhoneInfo, NewOnboardingInfo, SignUpStage } from '../../types/auth';
// eslint-disable-next-line import/no-cycle
import { phoneNumberConvert, storeDeviceId } from '../../utils';

interface InitialState {
  fullUser: FullUser | null | undefined;
  parentBusinessId: number | string | null;
  isLoading: boolean;
  onboarding1: BusinessAccountCreation;
  onBoardingComplete: boolean;
  showAccountSetup: boolean;
  accountSetupType: 'default' | 'post-onboarding';
  accountSetupMode: 'settlement account' | 'transction pin';
  migratedBoltInfo: BoltPhoneInfo | null;
  deviceId: string | null;
  onboardingInfo: NewOnboardingInfo;
  onboardingStage: SignUpStage;
  callbackUrlCode: string;
  initV2?: boolean;
  iamTokenRole?: string;
  actionPostLogin: Record<string, string>;
  tempDeviceInfo?: {
    email?: string;
    phone?: string;
  };
}

const onboarding1Initial: BusinessAccountCreation = {
  userId: '',
  businessName: '',
  email: '',
  businessType: '',
  phoneNumber: '',
  parentBusinessId: null,
  businessCategory: 'Unknown',
};

const newOnboardingInitialValues: NewOnboardingInfo = {
  businessName: '',
  merchantType: '',
  businessType: '',
  businessCategory: '',
  businessAddress: '',
  addressInfo: {
    state: '',
    lga: '',
  },
  incomeRange: '',
  userAccountInfo: {
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
    password: '',
    verificationIds: ['', ''],
    preRegistrationId: '',
    emailVerificationId: '',
    phoneVerificationId: '',
  },
  bvnVerificationInfo: {
    bvn: null,
    dateOfBirth: '',
    bvnVerificationId: '',
  },
  channel: 'WEB',
  loginRequest: {
    username: '',
    password: '',
    loginMode: 'EMAIL',
    verificationIds: ['', ''],
    channel: '',
    os: '',
    accessType: 'MOBILE',
    deviceName: '',
    deviceUniqueId: null,
    browser: '',
    ipAddress: '',
    ipCountry: '',
    otpVerified: true,
    deviceFCMToken: '',
    imei: '',
  },
  selfieUrl: null,
  referralCode: '',
};

// TODO: @beatz-steph -  clean up previous onboarding system

const initialState: InitialState = {
  isLoading: false,
  fullUser: null,
  parentBusinessId: null,
  onboarding1: onboarding1Initial,
  onBoardingComplete: true,
  showAccountSetup: false,
  accountSetupType: 'default',
  accountSetupMode: 'settlement account',
  migratedBoltInfo: null,
  deviceId: null,
  onboardingInfo: newOnboardingInitialValues,
  // onboardingStage: 'onboarding-campaign',
  onboardingStage: 'mechant-type',
  callbackUrlCode: '',
  iamTokenRole: localStorage.getItem('IAMuserRole') || '',
  actionPostLogin: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setParentBusinessId: (state, action: PayloadAction<{ parentBusinessId: number | string | null }>) => {
      state.parentBusinessId = action.payload.parentBusinessId;
    },
    setOnboardingComplete: (state, action: PayloadAction<boolean>) => {
      state.onBoardingComplete = action.payload;
    },
    setOnboarding1: (state, action: PayloadAction<Partial<BusinessAccountCreation>>) => {
      const newOnbaordingData = { ...state?.onboarding1, ...action.payload };
      state.onboarding1 = newOnbaordingData;
    },
    clearOnboarding1: (state) => {
      state.onboarding1 = onboarding1Initial;
    },
    clearAuth: (state) => {
      state.fullUser = null;
    },
    setShowAccountSetup: (
      state,
      action: PayloadAction<{
        show: boolean;
        type?: 'default' | 'post-onboarding';
        mode?: 'settlement account' | 'transction pin';
      }>
    ) => {
      state.showAccountSetup = action.payload.show;
      state.accountSetupType = action.payload.type || 'default';
      state.accountSetupMode = action.payload.mode || 'settlement account';
    },
    setMigratedUserInfo: (state, action: PayloadAction<BoltPhoneInfo>) => {
      state.migratedBoltInfo = action.payload;
    },
    updateMigratedUserInfo: (state, action: PayloadAction<Partial<BoltPhoneInfo>>) => {
      state.migratedBoltInfo = { ...state.migratedBoltInfo, ...action.payload, email: '' } as BoltPhoneInfo;
    },
    clearMigratedInfo: (state) => {
      state.migratedBoltInfo = null;
    },
    setDeviceId: (state, action: PayloadAction<string>) => {
      state.deviceId = action.payload;
    },
    setOnboardingInfo: (state, action: PayloadAction<Partial<NewOnboardingInfo>>) => {
      state.onboardingInfo = { ...state.onboardingInfo, ...action.payload };
    },

    setOnboardingStage: (state, action: PayloadAction<SignUpStage>) => {
      state.onboardingStage = action.payload;
    },
    setCallbackUrlCode: (state, action: PayloadAction<string>) => {
      state.callbackUrlCode = action.payload;
    },
    setInitV2: (state, action: PayloadAction<boolean>) => {
      state.initV2 = action.payload;
    },
    setIamTokenRole: (state, action: PayloadAction<string>) => {
      state.iamTokenRole = action.payload;
    },
    updatePostLoginAction: (state, action: PayloadAction<Record<string, string>>) => {
      state.actionPostLogin = action.payload;
    },
  },
  extraReducers: (builder) => {
    // login
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
        // next arguement after state is action
      });

    // sign up
    builder
      .addCase(signup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(signup.fulfilled, (state) => {
        state.isLoading = false;
      });

    // fetch user
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fullUser = action.payload;
        storeDeviceId({
          phoneNumber: phoneNumberConvert(action.payload?.phoneNumber as string, '+234') as string,
          email: action.payload?.email as string,
          id: state.deviceId || '',
        });
      });

    // onboarding phase 1
    builder
      .addCase(createBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBusiness.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createBusiness.fulfilled, (state) => {
        state.isLoading = false;
      });

    // resend token
    builder
      .addCase(sendToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendToken.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(sendToken.fulfilled, (state) => {
        state.isLoading = false;
      });

    // set password
    builder
      .addCase(setPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.isLoading = false;
      });

    // update user on verification
    builder
      .addCase(updateUserOnVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserOnVerification.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateUserOnVerification.fulfilled, (state) => {
        state.isLoading = false;
      });

    // get user registration with phone number / email
    builder
      .addCase(getUserRegistration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserRegistration.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserRegistration.fulfilled, (state, action) => {
        state.isLoading = false;
        const tempDeviceInfo: { email?: string; phone?: string } = {};

        if (action.meta.arg.params.email) {
          tempDeviceInfo.email = action.meta.arg.params.email;
        } else {
          tempDeviceInfo.phone = action.meta.arg.params.phone;
        }

        state.tempDeviceInfo = tempDeviceInfo;
      });

    // get bolt user by phone number
    builder
      .addCase(getBoltUserByPhone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBoltUserByPhone.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBoltUserByPhone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.migratedBoltInfo = action?.payload?.data || null;
      });
    // Upgrade business KYC level V2
    builder
      .addCase(UpgradeBusinessKYCLevel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpgradeBusinessKYCLevel.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(UpgradeBusinessKYCLevel.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setParentBusinessId,
  setOnboarding1,
  clearAuth,
  setOnboardingComplete,
  clearOnboarding1,
  setShowAccountSetup,
  setMigratedUserInfo,
  updateMigratedUserInfo,
  clearMigratedInfo,
  setDeviceId,
  setOnboardingInfo,
  setOnboardingStage,
  setCallbackUrlCode,
  setInitV2,
  setIamTokenRole,
  updatePostLoginAction,
} = authSlice.actions;
export default authSlice.reducer;
export * from './thunkActions';
