import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/merchantAcqServiceHttp';
import { ReduxAction, ApiResponse } from '../../../types/redux';
import { Customer, IMeta } from '../../../types/invoicing';
import { getParentBusinessId } from '../../../utils';

interface CreateCustomerArgument extends ReduxAction {
  values: { fullName: string; email: string; phoneNumber: string };
}

interface UpdateCustomerArguments extends ReduxAction {
  values: { fullName: string; email: string; phoneNumber: string };
  id: number | string | null;
}

interface FetchCustomerArgument extends ReduxAction {
  id: number | string | null;
}

interface DeleteCustomerArgument extends ReduxAction {
  id: number | string | null;
}

export interface FetchCustomersResponse extends ApiResponse {
  data: Customer[];
  meta: IMeta;
}

export interface FetchCustomersArgs extends ReduxAction {
  page?: number;
  limit?: number;
}

export interface FetchCustomerResponse extends ApiResponse {
  data: Customer;
}

export interface SearchCustomerArgs extends ReduxAction {
  page?: number;
  limit?: number;
  searchQuery: number | string | null;
}

export const createCustomer = createAsyncThunk(
  '/customer/create',
  async (
    { values: { fullName, email, phoneNumber }, onSuccess, onFailure }: CreateCustomerArgument,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post(
        '/webbe/v1/customer',
        { fullName, email, phoneNumber },
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) onSuccess(data);
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const updateCustomer = createAsyncThunk(
  '/customer/update',
  async (
    { values: { fullName, email, phoneNumber }, id, onSuccess, onFailure }: UpdateCustomerArguments,
    { rejectWithValue }
  ) => {
    try {
      const data = await axios.put(
        `/webbe/v1/customer/customer-ref/${id as string}`,
        { fullName, email, phoneNumber },
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const fetchAllCustomers = createAsyncThunk(
  '/customrer/get',
  async ({ page, limit, onSuccess, onFailure }: FetchCustomersArgs, { rejectWithValue }) => {
    try {
      const data: FetchCustomersResponse = await axios.get(
        `/webbe/v1/customer/all?page=${page as number}&size=${limit as number}`,
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const searchCustomer = createAsyncThunk(
  '/customrer/search',
  async ({ page, limit, searchQuery, onSuccess, onFailure }: SearchCustomerArgs, { rejectWithValue }) => {
    try {
      const data: FetchCustomersResponse = await axios.get(
        `/webbe/v1/customer/search?value=${searchQuery as string}&page=${page as number}&size=${limit as number}`,
        {
          headers: {
            businessId: getParentBusinessId(),
          },
        }
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const fetchCustomer = createAsyncThunk(
  '/customer/fetchCustomer',
  async ({ id, onSuccess, onFailure }: FetchCustomerArgument, { rejectWithValue }) => {
    try {
      const { data }: FetchCustomerResponse = await axios.get(`customer/v1/${id as string}`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customer/delete',
  async ({ id, onSuccess, onFailure }: DeleteCustomerArgument, { rejectWithValue }) => {
    try {
      const data = await axios.delete(`/webbe/v1/customer/customer-id/${id as string}`, {
        headers: {
          businessId: getParentBusinessId(),
        },
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
