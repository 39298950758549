/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { ActionsProp } from '../defs';
import { MoreIcon } from '../../../assets/svg';
import useDropdown from '../../../hooks/useDropdown';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const ViewMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
`;
const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ style }) => (style ? style.width : '100%')};
  right: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px;
  width: max-content;
  z-index: 1;
  margin-top: 40px;
  position: absolute;
`;
const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.nColors['n-grey1']};
    border-radius: 0px 0px 5px 5px;
  }
`;

interface MoreActionInterface {
  actions: ActionsProp[];
  rowData: Record<string, string>;
  viewMore?: boolean;
}

const MoreActions = ({ actions, rowData, viewMore }: MoreActionInterface) => {
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();

  const handleMoreActions = () => {
    setShowDropdown((state) => !state);
  };

  const handleSelectAction = (action: ActionsProp) => {
    setShowDropdown((state) => !state);
    action.onClickAction(rowData);
  };

  if (actions.length === 1 && viewMore) {
    return (
      <ViewMoreButton onClick={() => handleSelectAction(actions[0])}>
        <NIcons width="15" color="n-grey4" icon={IconsEnum.OUTLINE_EYE} />
        <Typography color="n-grey4" fontSize="14px" style={{ marginLeft: '4px' }}>
          View More
        </Typography>
      </ViewMoreButton>
    );
  }

  return (
    <Wrapper>
      <IconWrapper ref={dropdownButtonRef} onClick={() => handleMoreActions()}>
        <MoreIcon />
      </IconWrapper>
      {showDropdown ? (
        <Dropdown ref={dropdownRef}>
          {actions.map((action) =>
            action.restrict && action.restrict.values?.includes(rowData[action.restrict.property]) ? null : (
              <DropdownItemWrapper
                key={`more_actions_item_${Math.random()}`}
                onClick={() => handleSelectAction(action)}
              >
                <Flex justifyContent="center" alignItems="center" style={{ gap: '10px' }}>
                  {action.icon && action.icon}
                  <Typography variant="n-text2" color="dark">
                    {action.text}
                  </Typography>
                </Flex>
              </DropdownItemWrapper>
            )
          )}
        </Dropdown>
      ) : null}
    </Wrapper>
  );
};

export default MoreActions;
