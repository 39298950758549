import { Badge, Checkbox } from 'kudi-component-library';
import { useState } from 'react';
import { MobileSchema, Schema } from '../../../components/newTable';
import { Product } from '../../../types/inventory/products';
import { formatCurrency, capitalizeFirstLetter } from '../../../utils';
import ProductNameWithImage from './components/ProductNameWithImage';
import { MenuListProduct } from '../../../types/menu/menuList';

export const SelectMenuTableSchema = ({
  data,
  setSelected,
  selected,
}: {
  data: MenuListProduct[] | undefined;
  setSelected: React.Dispatch<any>;
  selected: number[];
}): Schema[] => {
  const [allToggled, setAllToggled] = useState(false);

  const toggleSelectAll = () => {
    const selectedCopy = data?.map(({ id }) => id);
    if (!allToggled) {
      setSelected(selectedCopy);
    } else {
      setSelected([]);
    }
    setAllToggled(!allToggled);
  };

  const toggleRow = (id: number) => {
    let newSelected = [];
    if (selected.includes(id)) {
      newSelected = selected.filter((item: any) => item !== id);
      setSelected(newSelected);
    } else {
      newSelected = [...selected, id];
      setSelected(newSelected);
    }
  };

  return [
    {
      text: 'checkbox',
      isCheckbox: true,
      cell: (id: number) => (
        <Checkbox
          name="checkbox"
          checkMarkColor="#000"
          checked={selected.includes(id)}
          onChange={() => toggleRow(id)}
        />
      ),
      header: () => (
        <Checkbox name="checkbox" checkMarkColor="#000" checked={allToggled} onChange={() => toggleSelectAll()} />
      ),
    },
    {
      text: 'Meal name',
      accessor: 'name',
      render: (renderNameData: Product) => <ProductNameWithImage product={renderNameData} />,
    },
    {
      text: 'Category',
      render: (renderCategoryData: MenuListProduct) => <>{capitalizeFirstLetter(renderCategoryData.category.name)}</>,
    },

    {
      text: 'Amount',
      render: (renderAmountData: MenuListProduct) => <>{formatCurrency(renderAmountData?.price || 0)}</>,
    },
  ];
};

export const MenuTableSchema: Schema[] = [
  {
    text: 'serialNumber',
    isSerialNumber: true,
  },
  {
    text: 'Stock Unit',
    accessor: 'sku',
  },
  {
    text: 'Meal name',
    accessor: 'name',
    render: (data: Product) => <ProductNameWithImage product={data} />,
  },
  {
    text: 'Category',
    render: (data: MenuListProduct) => <>{capitalizeFirstLetter(data.category.name)}</>,
  },

  { text: 'Amount', render: (data: MenuListProduct) => <>{formatCurrency(data?.price || 0)}</> },
  {
    text: 'Status',
    render: (data: MenuListProduct) => {
      if (!data.published) {
        return (
          <Badge variant="default" textColor="n-dark">
            Draft
          </Badge>
        );
      }
      if (!data.enabled) {
        return (
          <Badge variant="error" textColor="n-dark">
            Disabled
          </Badge>
        );
      }
      return (
        <Badge variant="success" textColor="n-dark">
          Active
        </Badge>
      );
    },
  },
];

export const MenuTableMobileSchema: MobileSchema = {
  lt: { accessor: 'name', render: (product: Product) => <ProductNameWithImage product={product} /> },
  rt: { accessor: 'price', render: (product: Product) => <>{formatCurrency(product.price)}</> },
  lb: { accessor: 'description' },
  rb: { accessor: 'quantity' },
};
