import { useState } from 'react';
import moment from 'moment';
import DatePicker, { DatePickerProps } from 'react-date-picker';

import { Label, InputItemErrorWrapper, CalendarItemWrapper, CalendarItem, CalendarIconWrapper } from '../component';
import ErrorIcon from '../ErrorIcon';
import CalendarIcon from '../CalenderIcon';
import { FormInputWrapper } from '../inputs/styles';

interface CalendarProps extends Pick<DatePickerProps, 'name'> {
  label?: string;
  name: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  width?: string;
  range?: boolean;
  dateFormat?: string;
  maxDate?: Date;
  minDate?: Date;
  isForm?: boolean;
  formattedValue?: string;
}

const Calendar = ({
  label,
  // name,
  value,
  onChange,
  disabled,
  error,
  placeholder,
  width,
  dateFormat,
  maxDate,
  minDate,
  formattedValue,
  ...props
}: CalendarProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const [date, setDate] = useState<Date>(moment(value).toDate());

  const handleChange = (e: Date) => {
    setDate(e);
    onChange(moment(e).format(dateFormat || 'YYYY-MM-DD'));
  };

  return (
    <FormInputWrapper width={width} onBlur={() => setIsFocus(false)}>
      <Label isForm={props.isForm} mb="0px">
        {label}
      </Label>
      <CalendarItemWrapper>
        {isOpen && (
          <DatePicker
            onChange={handleChange}
            value={date}
            isOpen={isOpen}
            onCalendarOpen={() => setIsFocus(true)}
            onCalendarClose={() => {
              setIsFocus(false);
              setIsOpen(false);
            }}
            maxDate={maxDate}
            minDate={minDate}
            closeCalendar={false}
            format={dateFormat || 'YYYY-MM-DD'}
          />
        )}
        <CalendarItem
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          isFocus={isFocus}
          disabled={disabled}
          error={error}
        >
          {formattedValue || value || placeholder}

          <CalendarIconWrapper active={!!value}>
            <CalendarIcon />
          </CalendarIconWrapper>
        </CalendarItem>
      </CalendarItemWrapper>

      {error ? (
        <InputItemErrorWrapper>
          <ErrorIcon /> <span> {error} </span>
        </InputItemErrorWrapper>
      ) : null}
    </FormInputWrapper>
  );
};

export default Calendar;
