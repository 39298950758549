/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { useAppSelector } from '../../../app/hooks';
import { Mixpanel } from '../../../hooks/useMixpanel';

const useTracking = () => {
  const { fullUser } = useAppSelector((state) => state.auth);
  const user_id = fullUser?.id as number;

  const trackNotificationOpened = (notification_type: string, notification_id: number, category: string) => {
    Mixpanel.track('Notification_Opened', {
      platform: 'WEB',
      notification_type,
      notification_id,
      category,
      user_id,
    });
    return null;
  };

  const trackNotificationClicked = (notification_type: string, notification_id: number, category: string) => {
    Mixpanel.track('Notification_Clicked', {
      platform: 'WEB',
      notification_type,
      notification_id,
      user_id,
      category,
      destination: '',
    });
  };

  const trackNotificationArchived = (notification_type: string, notification_id: number, category: string) => {
    Mixpanel.track('Notification_Archived', {
      notification_type,
      notification_id,
      user_id,
      category,
    });
  };

  const trackNotificationMarkedRead = (notification_type: string, notification_id: number, category: string) => {
    Mixpanel.track('Notification_Marked_Read', {
      notification_type,
      notification_id,
      user_id,
      category,
    });
  };

  const trackNotificationMarkedUnread = (notification_type: string, notification_id: number, category: string) => {
    Mixpanel.track('Notification_Marked_Unread', {
      notification_type,
      notification_id,
      user_id,
      category,
    });
  };

  const trackSetNotificationPreference = (
    notification_type: string,
    notification_id: number,
    category: string,
    frequency: number
  ) => {
    Mixpanel.track('Set_Notification_Preferences', {
      user_id,
      notification_type,
      notification_id,
      frequency,
      category,
    });
  };

  const trackMultipleNotificationsMarkedRead = (notification_type: string, count: number, category: string) => {
    Mixpanel.track('Multiple_Notifications_Marked_Read', {
      user_id,
      notification_type,
      count,
      category,
    });
  };

  const trackMultipleNotificationsMarkedUnread = (notification_type: string, count: number, category: string) => {
    Mixpanel.track('Multiple_Notifications_Marked_Unread', {
      user_id,
      notification_type,
      count,
      category,
    });
  };

  const trackMultipleNotificationsArchived = (notification_type: string, count: number, category: string) => {
    Mixpanel.track('Multiple_Notifications_Archived', {
      user_id,
      notification_type,
      count,
      category,
    });
  };

  const trackCategorySelected = (category: string) => {
    Mixpanel.track('Multiple_Notifications_Archived', { user_id, category });
  };

  return {
    trackNotificationOpened,
    trackNotificationClicked,
    trackNotificationArchived,
    trackNotificationMarkedRead,
    trackNotificationMarkedUnread,
    trackSetNotificationPreference,
    trackMultipleNotificationsMarkedRead,
    trackMultipleNotificationsMarkedUnread,
    trackMultipleNotificationsArchived,
    trackCategorySelected,
  };
};

export default useTracking;
