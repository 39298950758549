import { Badge } from 'kudi-component-library';
import { nColorsInterface } from 'kudi-component-library/dist/theme';
import { SettlementStatus, SettlementTransactionStatus } from '../../../../types/banking/settlements';
import { capitalizeFirstLetter } from '../../../../utils';

interface StatusColors {
  [key: string]: { background: keyof nColorsInterface; color: keyof nColorsInterface };
}

const statusColors: StatusColors = {
  PENDING: { background: 'n-grey1', color: 'n-grey5' },
  SETTLED: { background: 'n-green1', color: 'n-green' },
  PAID: { background: 'n-green1', color: 'n-green' },
  REFUND: { background: 'n-red1', color: 'n-red' },
  REVERSED: { background: 'n-red1', color: 'n-red' },
  CREDIT: { background: 'n-green1', color: 'n-green' },
  DEBIT: { background: 'n-red1', color: 'n-red' },
};

const SettlementStatusBadge = ({ status }: { status: SettlementStatus | SettlementTransactionStatus }) => {
  const isStatusAvailable = Object.keys(statusColors).includes(status);
  const textColor = isStatusAvailable ? statusColors[status].color : 'n-grey5';
  const backgroundColor = isStatusAvailable ? statusColors[status].background : 'n-grey1';

  return (
    <Badge textColor={textColor} backgroundColor={backgroundColor}>
      {isStatusAvailable ? capitalizeFirstLetter(status) : 'N/A'}
    </Badge>
  );
};

export default SettlementStatusBadge;
