import TableStateComponent from './stateComponent';
import NetworkErrorSvg from '../../../assets/svg/network-error';
import TableNoDataSvg from '../../../assets/svg/table-no-data';
import EndOfDataSvg from '../../../assets/svg/end-of-data';

export const NetworkErrorState = ({ message }: { message?: string }) => (
  <TableStateComponent
    icon={<NetworkErrorSvg />}
    message={
      message ||
      'We’re sorry, we are unable to fetch data due to network error. Kindly check your network and try again.'
    }
    messageWidth="492px"
  />
);

export const NoDataState = ({ message }: { message?: string }) => (
  <TableStateComponent
    icon={<TableNoDataSvg />}
    message={message || 'We’re sorry, but there doesn’t seem to be any data available to display.'}
  />
);

export const EndOfDataState = ({ message }: { message?: string }) => (
  <TableStateComponent
    icon={<EndOfDataSvg />}
    message={message || 'You have come to do end of the data available for this section.'}
  />
);

export const NoDataSwitcher = ({ page, message }: { page?: number; message?: string }) =>
  !page || page === 1 ? <NoDataState message={message} /> : <EndOfDataState message={message} />;
