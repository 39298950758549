import { Formik } from 'formik';
import Onboardingv3 from '../index';
import FormHeader from './form-header';
import OnboardingStepCounter from './step-counter';
import { ChooseAccountTypeWrapper, FormBodyWrapper, VerificationInfoWrapper } from './styles';
import useVerification from '../models/useVerification';
import TextInputV2 from '../../../../components/inputs';
import { Button, IconsEnum, NIcons } from 'kudi-component-library';
import { Separator } from '../../../../styles/generic';
import useIsMobile from '../../../../hooks/useIsMobile';

const Verification = () => {
  const { accountType, initialValues, validationSchema, isValidated, setIsValidated, onSubmit } = useVerification();
  const isMobile = useIsMobile();
  return (
    <Onboardingv3>
      <ChooseAccountTypeWrapper>
        <OnboardingStepCounter step={3} />
        <FormHeader
          title="Verification"
          description={
            <>
              Kindly enter you bank verification number (BVN) {accountType === 'corporate' && 'and RC number'} to verify
              your information{accountType === 'corporate' ? ' and business.' : '.'}
            </>
          }
        />
        <Formik
          initialValues={initialValues}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // eslint-disable-next-line no-console
            console.log(values);
            setSubmitting(true);
            if (accountType === 'individual' && isValidated.bvn) {
              onSubmit();
            } else if (accountType === 'corporate' && isValidated.bvn && isValidated.rcNumber) {
              onSubmit();
            }
          }}
        >
          {({ values, handleChange, handleSubmit, touched, errors, isValid, isSubmitting, handleBlur }) => {
            const isBvnValidated = isValidated.bvn && !errors.bvn;
            const isRcNumberValidated = isValidated.rcNumber && !errors.rcNumber;
            return (
              <form onSubmit={handleSubmit}>
                <FormBodyWrapper>
                  <TextInputV2
                    label="BVN"
                    type="number"
                    name="bvn"
                    value={values.bvn}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value.length === 11) {
                        setIsValidated((prevState) => ({
                          ...prevState,
                          bvn: true,
                        }));
                      }
                    }}
                    onBlur={handleBlur}
                    error={touched.bvn && errors.bvn}
                  />
                  <VerificationInfoWrapper validated={isBvnValidated}>
                    <div id="icon-wrapper" className={isBvnValidated ? 'success-icon' : ''}>
                      {isBvnValidated ? (
                        <NIcons icon={IconsEnum.OUTliNE_CHECK_C} />
                      ) : (
                        <NIcons icon={IconsEnum.OUTLINE_HELP} />
                      )}
                    </div>
                    <p>
                      {isBvnValidated ? (
                        'BVN verification complete'
                      ) : (
                        <>
                          Can’t remember? Click here or dial <strong>#565*0#</strong> to retrieve
                        </>
                      )}
                    </p>
                  </VerificationInfoWrapper>
                  {accountType === 'corporate' && (
                    <TextInputV2
                      label="RC Number"
                      type="number"
                      name="rcNumber"
                      value={values.rcNumber}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value.length >= 6 || e.target.value.length <= 8) {
                          setIsValidated((prevState) => ({
                            ...prevState,
                            rcNumber: true,
                          }));
                        }
                      }}
                      onBlur={handleBlur}
                      error={touched.rcNumber && errors.rcNumber}
                    />
                  )}
                  {isRcNumberValidated && (
                    <VerificationInfoWrapper validated={isRcNumberValidated}>
                      <div id="icon-wrapper" className="success-icon">
                        <NIcons icon={IconsEnum.OUTLINE_HELP} />
                      </div>
                      <p>RC verification complete</p>
                    </VerificationInfoWrapper>
                  )}
                  <Separator height={isMobile ? '20px' : '40px'} />
                  <Button
                    variant="medium"
                    type="submit"
                    width="100%"
                    disabled={!isValid || isSubmitting}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Continue
                  </Button>
                </FormBodyWrapper>
              </form>
            );
          }}
        </Formik>
      </ChooseAccountTypeWrapper>
    </Onboardingv3>
  );
};

export default Verification;
