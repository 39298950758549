import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { GuestNavigation } from '../../guestNavigation';
import { Layout, NavigationWrapper, ContentWrapper, MainPageContentHolder } from './style';
import { GuestHeader } from '../../header';
import ErrorBoundary from '../../../utils/errorBoundary';
import { FileHolderContextWrapper } from '../../../utils/filesHolderContext';

export const GuestDashboardLayout = () => {
  const [open, setOpen] = useState<boolean>(false);
  const appData = localStorage.getItem('appData');
  const token = localStorage.getItem('token');

  if (appData || token) {
    return <Navigate to="/dashboard/business/card-bank-performance" replace />;
  }

  return (
    <Layout>
      <NavigationWrapper
        open={open}
        onClick={() => {
          setOpen(false);
        }}
      >
        <GuestNavigation open={open} setOpen={setOpen} />
      </NavigationWrapper>
      <ContentWrapper>
        <GuestHeader setOpen={setOpen} />
        <MainPageContentHolder>
          <ErrorBoundary>
            <FileHolderContextWrapper>
              <Outlet />
            </FileHolderContextWrapper>
          </ErrorBoundary>
        </MainPageContentHolder>
      </ContentWrapper>
    </Layout>
  );
};
