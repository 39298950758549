import { createAsyncThunk } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../../app/store';
import { ReduxAction } from '../../../../types/redux';
import axios from '../../../../utils/http';
import { issueResolutionURL } from '../../constants';
import { FetchedIssue } from '../../defs';

interface GetAnIssueArgs extends ReduxAction {
  ticketId: string;
}

interface GetAnIssueResponse {
  ticket: FetchedIssue;
}

export const GetAnIssue = createAsyncThunk<GetAnIssueResponse, GetAnIssueArgs, { state: RootState }>(
  '/support/get_an_issue',
  async ({ onSuccess, onFailure, ticketId }, { rejectWithValue }) => {
    try {
      const { data }: { data: GetAnIssueResponse } = await axios.get(`/${issueResolutionURL}/ticket/${ticketId}`, {});
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
