import { MenuListResponse } from '../../../types/menu/menuList';
import axios from '../../../utils/merchantAcqServiceHttp';

const fetchMenuListAliasDetails = async ({
  alias,
  outletId,
  params,
}: {
  alias: string;
  outletId?: string;
  params?: URLSearchParams;
}) => {
  const url = `webbe/v1/menus/${alias}`;
  try {
    const result: MenuListResponse = await axios.get(url, { headers: { ...(outletId && { outletId }) }, params });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchMenuListAliasDetails;
