import { ICreateInventoryProduct } from '../../types/inventoryProducts';
import axios from '../../utils/merchantAcqServiceHttp';

const putUpdateMenuListItem = async (payload: ICreateInventoryProduct, id: string, outletId?: string) => {
  try {
    const result = await axios.put(`/webbe/v1/storehouse/items/${id}`, payload, {
      headers: { ...(outletId && { outletId }) },
    });

    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default putUpdateMenuListItem;
