import styled from 'styled-components';
import { ChevronDown } from '../../../../assets/svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

const BtnText = styled.p<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? '#C6C6C6' : '#121212')};
`;

const Prev = styled.button<{ disabled?: boolean }>`
  width: 97px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: none;
  outline: none;
  margin-right: 15px;
  cursor: pointer;
  svg {
    margin-right: 10px;
    transform: rotate(90deg);
    path {
      stroke: ${({ disabled }) => (disabled ? '#C6C6C6' : '#121212')};
    }
  }
`;

const Next = styled.button`
  width: 72px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  :disabled {
    color: #c6c6c6;
  }
  svg {
    margin-left: 10px;
    transform: rotate(-90deg);
    path {
      stroke: ${({ disabled }) => (disabled ? '#C6C6C6' : '#121212')};
    }
  }
`;

interface PaginationProps {
  id: string;
  currentPage: number;
  totalItems?: number;
  setCurrentPage: (page: number) => void;
}

const ArrowPagination = ({ id, currentPage, totalItems, setCurrentPage }: PaginationProps) => (
  <Wrapper id={id}>
    <Prev
      id="arrow-pagination-prev-button"
      disabled={currentPage === 1}
      onClick={() => setCurrentPage(currentPage - 1)}
    >
      <ChevronDown />
      <BtnText id="arrow-pagination-prev-button-text" disabled={currentPage === 1}>
        Previous
      </BtnText>
    </Prev>
    <Next
      id="arrow-pagination-next-button"
      disabled={totalItems ? totalItems < 10 : true}
      onClick={() => setCurrentPage(currentPage + 1)}
    >
      <BtnText id="arrow-pagination-next-button-text" disabled={totalItems ? totalItems < 10 : true}>
        Next
      </BtnText>
      <ChevronDown />
    </Next>
  </Wrapper>
);

export default ArrowPagination;
