import React, { createContext, ReactNode, useContext, useState } from 'react';

interface HeaderTitlecontextProps {
  pageTitle: string;
  setPageTitle: (value: string) => void;
}

export const HeaderTitlecontext = createContext<HeaderTitlecontextProps>({
  pageTitle: '',
  setPageTitle: () => {},
});

/**
 * returns pageTitle
 * @returns
 */

export const useGetPageTitle = () => {
  const { pageTitle }: HeaderTitlecontextProps = useContext(HeaderTitlecontext);

  return pageTitle;
};

export const useHeaderContext = () => {
  const info = useContext(HeaderTitlecontext);

  return info;
};

interface Props {
  children: ReactNode;
}

export const HeaderContextWrapper = ({ children }: Props) => {
  const [pageTitle, setPageTitle] = useState<string>('');
  return (
    <HeaderTitlecontext.Provider value={React.useMemo(() => ({ pageTitle, setPageTitle }), [pageTitle])}>
      {children}
    </HeaderTitlecontext.Provider>
  );
};
