import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiResponse, ReduxAction } from '../../../../types/redux';
import { RootState } from '../../../../app/store';
import axios from '../../../../utils/merchantAcqServiceHttp';
import { DowlineTransaction } from '../../defs';

const base = window._env_.REACT_APP_BASEURL;
interface DownlineTransactionsResponse extends ApiResponse {
  data: Array<DowlineTransaction>;
  next: string;
}

export const DownlineTransactions = createAsyncThunk<DownlineTransactionsResponse, ReduxAction, { state: RootState }>(
  '/referral/downline-transaction',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    const {
      referral: {
        downline: { selectedDownline },
      },
    } = getState();
    try {
      const data: DownlineTransactionsResponse = await axios.get(
        `${base}/referral/downline/${selectedDownline?.phoneNumber}/transactions`,
        { params: { pageSize: 20 } }
      );

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

interface NextDownlineTransactionsArgs extends ReduxAction {
  page: number;
}

export const NextDownlineTransactions = createAsyncThunk<
  DownlineTransactionsResponse,
  NextDownlineTransactionsArgs,
  { state: RootState }
>('/referral/next-downline-transaction', async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
  const {
    referral: {
      downline: {
        transactions: { cursor },
      },
    },
  } = getState();
  try {
    const data: DownlineTransactionsResponse = await axios.get(`${base}/referral/transactions/scroll`, {
      params: {
        cursor,
      },
    });

    if (onSuccess) onSuccess(data);
    return data;
  } catch (error) {
    if (onFailure) onFailure(error);
    return rejectWithValue({ error });
  }
});
