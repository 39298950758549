import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconsEnum, NIcons } from 'kudi-component-library';

import { HeaderTopFlexActionsWrapper, RemoveSvgStroke, TootTipIconWrapper } from '../../styles';
import Tooltip from '../../../../components/Tooltip';
import useRedux from '../../../../hooks/useRedux';
import { setStatus } from '../../reducer/slice';
import Archive from './archive';
import {
  ArchiveNotifications,
  MarkNotificationsRead,
  fetchAllNotifications,
  markAllNotifications,
} from '../../reducer/thunkActions';
import useTracking from '../../models/analytics';
import { OutlineCheckC } from '../../../../assets/svg';

const DesktopFiltersAndActions = () => {
  const navigate = useNavigate();
  const {
    dispatch,
    state: {
      notifications: {
        filter: { status },
        allChecked,
        checkedList,
        notifications,
      },
    },
  } = useRedux();

  const { trackNotificationArchived, trackMultipleNotificationsArchived, trackMultipleNotificationsMarkedRead } =
    useTracking();

  const [notificationPreferenceToolTip, setNotificationPreferenceToolTip] = useState<boolean>(false);
  const [ShowOnluUnreadToolTip, setShowOnluUnreadToolTip] = useState<boolean>(false);
  const [markAllreadToolTip, setMarkAllreadToolTip] = useState<boolean>(false);
  const [archiveAllToolTip, setArchiveAllToolTip] = useState<boolean>(false);

  const mark = () => {
    if (allChecked) {
      dispatch(markAllNotifications({ onSuccess: () => dispatch(fetchAllNotifications({})) }));
    } else {
      const entries = notifications.filter((item) => checkedList.includes(item.__access_key));
      trackMultipleNotificationsMarkedRead(entries?.[0]?.type as string, checkedList.length, entries?.[0]?.category);
      dispatch(MarkNotificationsRead({ onSuccess: () => dispatch(fetchAllNotifications({})) }));
    }
  };
  const archive = () => {
    if (checkedList.length > 1) {
      const entries = notifications.filter((item) => checkedList.includes(item.__access_key));
      trackMultipleNotificationsArchived(entries?.[0]?.type as string, checkedList.length, entries?.[0]?.category);
    } else {
      const entry = notifications.find((item) => item.__access_key === checkedList[0]);
      trackNotificationArchived(entry?.type as string, entry?.id as number, entry?.category as string);
    }
    dispatch(ArchiveNotifications({ onSuccess: () => dispatch(fetchAllNotifications({})) }));
  };

  const nextFilter = () => {
    let text = '';
    let action;

    switch (status) {
      case 'UNREAD':
        text = 'Show All';
        action = () => dispatch(setStatus(undefined));
        break;
      case undefined:
        text = 'Show only Unread';
        action = () => dispatch(setStatus('UNREAD'));
        break;
      default:
        break;
    }

    return { text, action };
  };

  return (
    <HeaderTopFlexActionsWrapper>
      {checkedList.length > 0 && status !== 'ARCHIVED' ? (
        <TootTipIconWrapper
          onMouseEnter={() => setArchiveAllToolTip(true)}
          onMouseLeave={() => setArchiveAllToolTip(false)}
          onClick={archive}
        >
          {archiveAllToolTip ? (
            <Tooltip
              containerStyle={{
                bottom: '40px',
                right: '-5px',
              }}
              arrowStyle={{
                top: '25px',
                right: '0px',
              }}
              label="Archive selected"
            />
          ) : null}
          <Archive />
        </TootTipIconWrapper>
      ) : null}
      {(checkedList.length > 0 || allChecked) && status !== 'ARCHIVED' ? (
        <TootTipIconWrapper
          onMouseEnter={() => setMarkAllreadToolTip(true)}
          onMouseLeave={() => setMarkAllreadToolTip(false)}
          onClick={mark}
        >
          {markAllreadToolTip ? (
            <Tooltip
              containerStyle={{
                bottom: '40px',
                right: '-5px',
              }}
              arrowStyle={{
                top: '25px',
                right: '0px',
              }}
              label={allChecked ? 'Mark all as read' : 'Mark selected as read'}
            />
          ) : null}
          <OutlineCheckC scale={0.7} />
        </TootTipIconWrapper>
      ) : (
        <>
          <TootTipIconWrapper
            onMouseEnter={() => setNotificationPreferenceToolTip(true)}
            onMouseLeave={() => setNotificationPreferenceToolTip(false)}
            onClick={() => navigate('/dashboard/control/settings/preferences')}
          >
            {notificationPreferenceToolTip ? (
              <Tooltip
                containerStyle={{
                  bottom: '40px',
                  right: '-60px',
                }}
                arrowStyle={{
                  top: '25px',
                  right: '55px',
                }}
                label="Notification Preference"
              />
            ) : null}
            <NIcons icon={IconsEnum.OUTLINE_SETTINGS} />
          </TootTipIconWrapper>

          <TootTipIconWrapper
            onMouseEnter={() => setShowOnluUnreadToolTip(true)}
            onMouseLeave={() => setShowOnluUnreadToolTip(false)}
            onClick={nextFilter().action}
          >
            {ShowOnluUnreadToolTip ? (
              <Tooltip
                containerStyle={{
                  bottom: '40px',
                  right: '-10px',
                }}
                arrowStyle={{
                  top: '25px',
                  right: '5px',
                }}
                label={nextFilter().text}
              />
            ) : null}
            {status === 'UNREAD' ? (
              <RemoveSvgStroke>
                <NIcons icon={IconsEnum.BG_FILTER} />
              </RemoveSvgStroke>
            ) : (
              <NIcons icon={IconsEnum.OUTLINE_TEXT_CENTER} />
            )}
          </TootTipIconWrapper>
        </>
      )}
    </HeaderTopFlexActionsWrapper>
  );
};

export default DesktopFiltersAndActions;
