import dayjs from 'dayjs';
import { Formik } from 'formik';
import { Button, TextInput, Typography } from 'kudi-component-library';
import styled from 'styled-components';
import Calendar from '../../../../components/Calendar';
import Modal from '../../../../components/Modal';
import { HeaderWrapper, Wrapper } from './styles';
import useDownLoadReportModel from './useDownloadReport';

const FormInputWrapper = styled.div`
  margin-bottom: 25px;
`;

const DownloadReportModal = ({
  showDownloadReportModal,
  handleCloseDownloadReportModal,
  outletId,
}: {
  showDownloadReportModal: boolean;
  handleCloseDownloadReportModal: () => void;
  outletId?: string;
}) => {
  const { initialValues, validationSchema, handleDownloadReport } = useDownLoadReportModel({ outletId });
  return (
    <Modal showModal={showDownloadReportModal} onClose={handleCloseDownloadReportModal}>
      <Wrapper>
        <HeaderWrapper>
          <Typography variant="n-text5" color="dark">
            Generate Settlement Report
          </Typography>
          <Typography variant="n-text2" color="grey4">
            Choose the period below to download settlement report
          </Typography>
        </HeaderWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => handleDownloadReport({ values, setSubmitting })}
          validationSchema={validationSchema}
        >
          {({ values, handleSubmit, isSubmitting, isValid, setFieldValue }) => (
            <>
              <FormInputWrapper>
                <Calendar
                  isForm
                  label="Start Date"
                  maxDate={dayjs().toDate()}
                  width="100%"
                  name="startDate"
                  value={values.startDate}
                  disabled={isSubmitting}
                  dateFormat="MM/DD/YYYY"
                  onChange={(value) => setFieldValue('startDate', value)}
                  formattedValue={dayjs(values.startDate).format('DD/MM/YYYY')}
                />
              </FormInputWrapper>
              <FormInputWrapper>
                <Calendar
                  isForm
                  label="End Date"
                  minDate={dayjs(values.startDate).toDate()}
                  maxDate={dayjs().toDate()}
                  width="100%"
                  name="endDate"
                  value={values.endDate}
                  dateFormat="MM/DD/YYYY"
                  onChange={(value) => setFieldValue('endDate', value)}
                  disabled={isSubmitting}
                  formattedValue={dayjs(values.endDate).format('DD/MM/YYYY')}
                />
              </FormInputWrapper>
              <FormInputWrapper>
                <TextInput
                  label="Document Type"
                  name="documentType"
                  value="CSV"
                  onChange={() => {}}
                  disabled
                  width="100%"
                />
              </FormInputWrapper>
              <Button
                variant="medium"
                width="100%"
                onClick={() => {
                  handleCloseDownloadReportModal();
                  handleSubmit();
                }}
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
              >
                Download
              </Button>
            </>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  );
};

export default DownloadReportModal;
