import { Checkbox, Typography } from 'kudi-component-library';
import { IssueDetailsInfoActiveItem, IssueDetailsInfoWrapper } from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import Skeleton from '../../../../components/skeleton';
import { Separator } from '../../../overview/styles';

const ActiveInfo = () => {
  const {
    state: {
      support: {
        issueList: { isLoading, selectedIssue },
      },
    },
  } = useRedux();
  const list = [
    { title: 'Issue Received', description: 'Our customer support has received your issue.', checked: true },
    { title: 'In progress', description: 'Customer support is working on resolving this issue', checked: true },
    {
      title: 'Issue Resolved',
      description:
        'You can reach out to the delivery courier. Your meal is almost there for you to enjoy to its fullest',
      checked: false,
    },
  ];

  return !selectedIssue && !isLoading ? null : (
    <IssueDetailsInfoWrapper>
      <Typography color="n-grey5" fontWeight={500} fontSize="16px" lineHeight="24px">
        Resolution history
      </Typography>
      <div>
        {list.map((item) =>
          isLoading ? (
            <div key={`Skeleton-issue-details${Math.random()}`}>
              <Skeleton width="100%" height="60px" />
              <Separator height="20px" />
            </div>
          ) : (
            <IssueDetailsInfoActiveItem key={Math.random()}>
              <div className="checkWrapper">
                <Checkbox width="20px" height="20px" checkMarkColor="#000" checked={item?.checked} name="" />
              </div>
              <p className="title">{item.title}</p>
              <span className="description">{item?.description}</span>
            </IssueDetailsInfoActiveItem>
          )
        )}
      </div>
      <div />
    </IssueDetailsInfoWrapper>
  );
};

export default ActiveInfo;
