import { Typography, Button, NTheme as theme } from 'kudi-component-library';
import { useFlags } from 'flagsmith/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ChevronLeft, NotificationBellEmptyIcon, NotificationBellIcon } from '../../../../assets/svg';
import useDropdown from '../../../../hooks/useDropdown';
import {
  fetchAllNotifications,
  markAllNotifications,
  toggleNotification,
} from '../../../../pages/notification/reducer/thunkActions';
import {
  Badge,
  Content,
  DetailsContainer,
  EmptyContainer,
  Footer,
  Header,
  NotificationDropdownWrapper,
  NotificationWrapper,
  ViewBtn,
  ViewContainer,
} from './styles';
import { MobileTableItemUI } from '../../../../pages/notification/components/mobile/mobile-table-item';
import { Notification } from '../../../../pages/notification/types';
import {
  resetPaginationAndFilter,
  setCategory,
  setStatus,
  toggleOpen,
} from '../../../../pages/notification/reducer/slice';
import { FLAGSMITH_FEATURES } from '../../../../utils/constants';

const NotificationDropdown = () => {
  const { bb_dashboard_notification: webNotification } = useFlags([FLAGSMITH_FEATURES.BB_DASHBOARD_NOTIFICATION]);
  const match = useLocation();
  const notificationsRoute = '/dashboard/business/notifications/';

  const show = !match.pathname.includes(notificationsRoute);
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { notifications, totalUnread } = useAppSelector((state) => state.notifications);
  const [view, setView] = useState<'details' | 'list'>('list');
  const [isShown, setIsShown] = useState<Notification | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleMarkAllAsRead = () => {
    dispatch(markAllNotifications({}));
  };

  const handleToggle = (notification: Notification) => {
    dispatch(toggleOpen(notification));
    if (notification.status === 'UNREAD') {
      dispatch(toggleNotification({ notification }));
    }
  };

  const handleItemClick = (item: Notification) => {
    const currIndex = notifications?.findIndex((val) => val.__access_key === item.__access_key);
    handleToggle(item);
    setCurrentIndex(currIndex || 0);
    setView('details');
    setIsShown(item);
  };

  const handleNext = () => {
    const item = notifications?.[currentIndex + 1];
    setCurrentIndex(currentIndex + 1);
    handleToggle(item);
    setIsShown(item || {});
  };

  const handlePrev = () => {
    const item = notifications?.[currentIndex - 1];
    setCurrentIndex(currentIndex - 1);
    handleToggle(item);
    setIsShown(item || {});
  };

  const onClick = () => {
    setShowDropdown(!showDropdown);
    dispatch(setCategory(undefined));
  };

  const viewAll = () => {
    navigate('/dashboard/business/notifications/all');
    setShowDropdown(false);
  };

  // fetch notifications
  useEffect(() => {
    if (!webNotification) return;
    if (show) dispatch(resetPaginationAndFilter());
    if (show) dispatch(fetchAllNotifications({}));
  }, [dispatch, webNotification, show]);
  return show ? (
    <NotificationWrapper ref={dropdownButtonRef}>
      <NotificationBellIcon onClick={onClick} cursor="pointer" />
      {totalUnread ? <Badge>{totalUnread}</Badge> : null}
      {showDropdown && (
        <NotificationDropdownWrapper ref={dropdownRef}>
          {view === 'list' ? (
            <>
              <Header>
                <Typography color="dark" fontSize="16px" fontWeight={500} lineHeight="16px">
                  {totalUnread} Unread Notifications
                </Typography>

                {totalUnread ? (
                  <button type="button" onClick={handleMarkAllAsRead}>
                    Mark all as read
                  </button>
                ) : null}
              </Header>

              {!totalUnread ? (
                <EmptyContainer>
                  <NotificationBellEmptyIcon />
                  <Typography variant="n-text3">No new notifications</Typography>
                </EmptyContainer>
              ) : (
                <Content>
                  {notifications?.map((item) => (
                    <MobileTableItemUI
                      textWidth="300px"
                      data={item}
                      onClick={() => handleItemClick(item)}
                      key={item?.id}
                    />
                  ))}
                </Content>
              )}

              <ViewContainer>
                <ViewBtn onClick={viewAll}>View all</ViewBtn>
              </ViewContainer>
            </>
          ) : (
            <DetailsContainer>
              <div className="header">
                <button type="button" onClick={() => setView('list')}>
                  <ChevronLeft />
                </button>

                <Typography fontSize="16px" fontWeight={500} lineHeight="20px">
                  Notification
                </Typography>
              </div>

              <Typography fontSize="16px" fontWeight={500} lineHeight="20px">
                {isShown?.title}
              </Typography>
              <div className="body">
                <Typography fontSize="14px" fontWeight={400} lineHeight="18px">
                  {isShown?.message}
                </Typography>
              </div>

              <Footer>
                <Button
                  variant="medium"
                  color="tertiary"
                  width="100%"
                  height="41px"
                  style={{ border: `1px solid ${theme.nColors['n-grey3']}` }}
                  onClick={handlePrev}
                  disabled={currentIndex === 0}
                >
                  Previous
                </Button>
                <Button
                  variant="medium"
                  color="secondary"
                  width="100%"
                  height="41px"
                  onClick={handleNext}
                  disabled={currentIndex + 1 === notifications.length}
                >
                  Next
                </Button>
              </Footer>
            </DetailsContainer>
          )}
        </NotificationDropdownWrapper>
      )}
    </NotificationWrapper>
  ) : null;
};

export default NotificationDropdown;
