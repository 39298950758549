import { Logo } from '../../../../assets/svg';
import { IconsEnum, NIcons } from 'kudi-component-library';
import useIsMobile from '../../../../hooks/useIsMobile';
import { TopRightText, Wrapper } from './styles';
import { LogoWrapper, TopRightWrapper } from './styles';
import { useNavigate } from 'react-router-dom';

const OnboardingHeader = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <Wrapper>
      <LogoWrapper onClick={() => navigate('/auth/login')}>
        <Logo />
      </LogoWrapper>
      <TopRightWrapper>
        <TopRightText variant={isMobile ? 'n-text0' : 'n-text1'}>
          Need help, <span>contact us</span>
        </TopRightText>
        <div onClick={() => navigate('/auth/login')}>
          <NIcons icon={IconsEnum.OUTLINE_X} width="16" height="16" />
        </div>
      </TopRightWrapper>
    </Wrapper>
  );
};

export default OnboardingHeader;
