import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import dayjs from 'dayjs';
import { AppliedFilterIconWrapper, AppliedFilterWrapper, AppliedFiltersWrapper } from './styles';
import { capitalizeFirstLetter } from '../../utils';
import { AppliedFilter } from './defs';

const AppliedFilters = ({
  appliedFilters,
  handleRemoveFilter,
}: {
  appliedFilters: AppliedFilter[];
  handleRemoveFilter: (query: string) => void;
}) =>
  appliedFilters.length ? (
    <AppliedFiltersWrapper id="applied_filters_container" data-testid="applied_filters_container">
      {appliedFilters.map(({ query, value }) => (
        <AppliedFilterWrapper
          key={`active_filter_${query}_${JSON.stringify(value)}`}
          id="applied_filter_item"
          data-testid="applied_filter_item"
        >
          <Typography variant="n-text1" color="grey5">
            {query === 'dateRange'
              ? `${dayjs(value[0]).format('DD/MM/YYYY')}-${dayjs(value[1]).format('DD/MM/YYYY')}`
              : capitalizeFirstLetter(String(value).replace('_', ' ').toLowerCase())}
          </Typography>
          <AppliedFilterIconWrapper onClick={() => handleRemoveFilter(query)}>
            <NIcons icon={IconsEnum.OUTLINE_X} size="sm" />
          </AppliedFilterIconWrapper>
        </AppliedFilterWrapper>
      ))}
    </AppliedFiltersWrapper>
  ) : null;

export default AppliedFilters;
