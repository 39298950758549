import { Button, Typography } from 'kudi-component-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const OutlinedButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.nColors['n-grey2']};
  height: 38px;
  padding: 0 10px;
  background: none;
`;

export const SettlementAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  padding: 20px 20px;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 768px) {
    flex-direction: row;
    height: 100px;
  }
`;

export const SettlementAccountsLinkContainer = styled.div`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  svg {
    stroke-width: 2.5px;
  }
`;

export const SettlementAccountsLinkText = styled(Typography)`
  text-decoration: underline;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 1px;
`;

export const SettlementAccount = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: fit-content;
  }
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const SettlementsFilterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: baseline;
  @media ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

export const FilterContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
`;

export const SettlementsDownloadButton = styled.div<{ width?: string; disabled?: boolean }>`
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 52px;
  border-radius: 5px;
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.nColors['n-light']};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const PrimaryAccountWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 3px;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 768px) {
    width: fit-content;
    justify-content: flex-start;
  }
`;
