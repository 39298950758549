import { OfficeListWrapper, SupportPageDetailsWrapper } from '../../styles';
import Header from '../base/header';
import OfficeItem from '../base/office_item';
import useRedux from '../../../../hooks/useRedux';
import { updateCurrentPage } from '../../reducer/slice';

const OfficeAddress = () => {
  const {
    dispatch,
    state: {
      support: {
        contacts: {
          info: { addresses },
        },
      },
    },
  } = useRedux();

  return (
    <>
      <Header
        desktopTitle="Office Addresses"
        desktopDescription="Find us at one of our office addresses below"
        desktopCloseAction={() => dispatch(updateCurrentPage('home'))}
        mobileBackAction={() => dispatch(updateCurrentPage('home'))}
        mobileTitle="Customer support"
      />
      <SupportPageDetailsWrapper mobilePadding="20px">
        <OfficeListWrapper>
          {addresses.map((item) => (
            <OfficeItem location={item.description} address={item.contactValue} key={Math.random()} />
          ))}
        </OfficeListWrapper>
      </SupportPageDetailsWrapper>
    </>
  );
};

export default OfficeAddress;
