import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const AtmCard = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M3 6.579A2.579 2.579 0 0 1 5.579 4h8.842A2.579 2.579 0 0 1 17 6.579v5.895a2.579 2.579 0 0 1-2.579 2.579H5.58A2.579 2.579 0 0 1 3 12.473V6.58Zm.737 1.842v4.053c0 1.017.825 1.842 1.842 1.842h8.842a1.842 1.842 0 0 0 1.842-1.842V8.42H3.737Zm12.526-.737H3.737V6.58c0-1.017.825-1.842 1.842-1.842h8.842c1.017 0 1.842.825 1.842 1.842v1.105ZM5.947 11.737c0-.204.165-.369.369-.369h.737a.368.368 0 0 1 0 .737h-.737a.368.368 0 0 1-.369-.368Zm3.685 0c0-.204.165-.369.368-.369h.737a.369.369 0 0 1 0 .737H10a.368.368 0 0 1-.368-.368Z"
      clipRule="evenodd"
    />
  </svg>
);

export default AtmCard;
