import { useState } from 'react';

const useSessionTimeout = () => {
  // const stringedExpiresAt = localStorage.getItem('expiresAt');
  // const expiresAt = stringedExpiresAt ? JSON.parse(stringedExpiresAt) : undefined;
  // const currentTime = new Date().getTime();

  const [openSessionWarningModal, setOpenSessionWarningModal] = useState(false);

  const handleOpenSessionWarningModal = () => {
    setOpenSessionWarningModal(true);
  };

  const handleCloseSessionWarningModal = () => setOpenSessionWarningModal(false);

  return {
    openSessionWarningModal,
    handleOpenSessionWarningModal,
    handleCloseSessionWarningModal,
  };
};

export default useSessionTimeout;
