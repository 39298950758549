import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/http';
import { ReduxAction, ApiResponse } from '../../types/redux';
import { QRCode } from '../../types/qrCode';

interface fetchQRCodeArgs extends ReduxAction {
  userId: string | null |undefined;
}

export interface fetchQRCodeResponse extends ApiResponse {
  status: boolean,
  message: string,
  data: QRCode
}

export const fetchQRCode = createAsyncThunk(
  '/cfi/qr-code',
  async ({ userId, onSuccess, onFailure  }: fetchQRCodeArgs, { rejectWithValue }) => {
    try {
      const response: fetchQRCodeResponse = await axios.get(`/cfi/qr-code/${userId}`);
      if (onSuccess) onSuccess(response.message);
      return response.data;
    } catch (error: any) {
      if (onFailure) onFailure(error?.data?.message);
      rejectWithValue({ error });
    }
  }
);