export const currentEnumList = {
  businessSector: ['Entertainment', 'Education', 'Unknown'],
  period: ['PREVIOUS_WEEK', 'PREVIOUS_MONTH'],
  idType: [
    'NIMC Slip',
    'International passport',
    "Voter's card",
    'National ID card',
    'Drivers License',
    'BVN',
    'Unknown',
  ],
  gender: ['Male', 'Female', 'Unknown'],
  referralStatus: ['REGISTERED', 'ACTIVE', 'PENDING', 'INACTIVE', 'DECLINED'],
  walletType: ['Primary Wallet', 'Cash Wallet', 'Commission Wallet', 'Unknown'],
  accountType: ['Savings Account', 'Current Account', 'Unknown'],
  channelType: ['APP', 'ANDROID', 'IOS', 'WEB', 'MARKETPLACE'],
  referralLevel: ['none', 'level_one', 'level_two', 'level_three', 'level_four', 'level_five'],
  boltReferrerType: [
    'NONE',
    'AGGREGATOR',
    'SUPER_AGENT',
    'REGULAR',
    'MARKETER',
    'FAO',
    'OPM',
    'KUDI_GO',
    'TELESALES',
    'RM',
    'EMPLOYEE',
    'SUPER_AGGREGATOR',
    'CHIEF_AGGREGATOR_OFFICER',
    'ACTIVATION',
    'MAO',
    'KAM',
    'SMAO',
    'MP',
    'MR',
    'PC',
  ],
  duration: ['WEEKLY', 'MONTHLY'],
  governmentIdType: ['Bvn', 'NIN', "Driver's License", 'Unknown'],
  businessPaymentOption: ['Physical Storefront', 'Event Stand', 'Kudi Agents', 'Online Portals', 'Unknown'],
  businessCategory: [
    'Sole Proprietor',
    'Private Limited Company',
    'Registered Partnership',
    'Incorporated Trusties',
    'Unregistered',
  ],
  businessType: [
    'Agency Banking',
    'Fast Food Restaurants',
    'Pharmacies',
    'Health and Beauty Spas',
    'Retail Merchants',
    'Barber and Beauty Shops',
    'Electrical Parts and Equipments',
    'Dry Cleaners',
    'Stationary/Office Supplies',
    'Paints, Varnishes and Supplies',
    'Cleaning Services',
    'Food Delivery Businesses',
    'Car Washes',
    'Others',
    'Legal Services',
    'Charities and Social Services',
    'Fuel Station',
    'Wholesale Merchants',
    'Hotels/Motels and Resorts',
    'Media and Advertising',
    'Hospitals',
    'Schools',
    'Travel Agency',
    'Beauty Spas',
    'Barber and Hair Saloon',
    'Entertainment',
    'Religious Organizations',
    'Distributor',
    'Sport Betting',
    'Boutique',
    'Transport',
    'Unknown',
  ],
  merchantType: ['Individual Business', 'Corporate Business', 'Unknown'],
  businessIncomeRange: ['0 - 5,000,000', '5,000,000 - 20,000,000', '20,000,000 - 60,000,000', '60,000,000 - ABOVE'],
};

export type AppEnumList = typeof currentEnumList;
