import { Typography } from 'kudi-component-library';
import { ActiveComments } from '../../styles';
import useRedux from '../../../../hooks/useRedux';

const IssueComments = () => {
  const {
    state: {
      support: {
        issueList: { selectedIssue },
      },
    },
  } = useRedux();
  return selectedIssue && selectedIssue?.comments?.length > 0 ? (
    <>
      <Typography color="n-dark" fontWeight={700} fontSize="16px" lineHeight="24px">
        Comments
      </Typography>
      <ActiveComments>
        <div className="bulletPoints">
          {selectedIssue?.comments.map((item) => (
            <>
              <li>From {item.author}</li>
              <p>{item.text}</p>
            </>
          ))}
        </div>
      </ActiveComments>
    </>
  ) : null;
};

export default IssueComments;
