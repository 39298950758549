import React, { ReactNode, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../../assets/svg';
import RenderTopRight from './RenderTopRight';
import { Layout, TopWrapper, LogoWrapper } from './components';

import { useAppDispatch } from '../../../app/hooks';
import { clearAuth } from '../../../features/auth/slice';

import ErrorBoundary from '../../../utils/errorBoundary';
import useIsOnboardingAllowed from '../../../hooks/useIsOnboardingAllowed';
import AuthFooter from './AuthFooter';
import { useFlags } from 'flagsmith/react';
import { FLAGSMITH_FEATURES } from '../../../utils/constants';
import OnboardingHeader from '../../../pages/auth/onboardingv3/components/header';

interface OnboardingLayoutProps {
  children: ReactNode;
  Component?: FunctionComponent;
  ComponentSm?: FunctionComponent;
  LogoClick?: () => void;
  isOnboarding?: boolean;
}

export const OnboardingLayout = ({
  children,
  Component,
  ComponentSm,
  LogoClick = () => {},
  isOnboarding = false,
}: OnboardingLayoutProps) => {
  const { web_onboarding_v3: onboardingv3Flag } = useFlags([FLAGSMITH_FEATURES.WEB_ONBOARDING_V3]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useIsOnboardingAllowed();

  const handleLogoClick = () => {
    dispatch(clearAuth());
    LogoClick();
    navigate('/auth/login');
  };

  return (
    <Layout>
      {onboardingv3Flag.enabled && isOnboarding ? (
        <OnboardingHeader />
      ) : (
        <TopWrapper>
          <LogoWrapper onClick={handleLogoClick}>
            <Logo />
          </LogoWrapper>
          {/* adding top right content */}
          <RenderTopRight Component={Component} ComponentSm={ComponentSm} />
        </TopWrapper>
      )}
      <ErrorBoundary>{children}</ErrorBoundary>
      <AuthFooter />
    </Layout>
  );
};
