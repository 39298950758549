import { SupportPageDetailsWrapper, MainDetailsInfoWrapper } from '../../styles';
import Header from '../base/header';
import useRedux from '../../../../hooks/useRedux';
import {
  selectIssueType,
  selectTransaction,
  toggleShow,
  updateCurrentPage,
  updateIssueCreationStage,
} from '../../reducer/slice';
import ActiveSummary from './active_summary';
import SubmittedSummary from './submitted_summary';

const Summary = () => {
  const {
    dispatch,
    state: {
      support: {
        issueCreatedId,
        issueList: { closeAll },
      },
    },
  } = useRedux();

  const previousIssueCreationStep = () => {
    dispatch(updateCurrentPage('issue-creation'));
    dispatch(updateIssueCreationStage('transactions'));
  };

  const closeIssueResolution = () => {
    dispatch(updateCurrentPage('home'));
    dispatch(updateIssueCreationStage('category'));
    dispatch(selectIssueType(null));
    dispatch(selectTransaction(null));
    dispatch(toggleShow());
  };

  const Goback = () => {
    if (closeAll) {
      closeIssueResolution();
    } else {
      previousIssueCreationStep();
    }
  };

  return (
    <>
      <Header
        desktopTitle="Summary"
        desktopDescription="See the summary of your issues"
        desktopCloseAction={Goback}
        mobileBackAction={Goback}
        mobileTitle="Summary"
      />
      <SupportPageDetailsWrapper mobileBg="#fff" mobilePadding="0 0 60px">
        <MainDetailsInfoWrapper>{!issueCreatedId ? <ActiveSummary /> : <SubmittedSummary />}</MainDetailsInfoWrapper>
      </SupportPageDetailsWrapper>
    </>
  );
};

export default Summary;
