import React from 'react';
import { Button, Text } from 'kudi-component-library';
import { FullBox, FullFlex } from '../../../styles/generic';
import { DesktopWrapper, MobileWrapper } from '../../loan-provider/components/filter';
import { TableWrapper, Table, TBody, THead, Tr, Th, Td } from '../../loan-provider/components/table';
import { DotsIcon } from '../../../assets/svg';
import BulkTransferModal from './bulktransfermodal';
import { BulkTransferUsersData } from '../../../types/transactions';
import { formatCurrency } from '../../../utils';
import ArrowPagination from '../../loan-provider/components/arrow-paginate';

export default function Bulktransfertable({
  showUnverified,
  tableData,
  formRef,
  bulkSum,
  setSelectedData,
  setUserId,
  setUserIndex,
}: {
  showUnverified: boolean;
  bulkSum: number;
  tableData: Array<BulkTransferUsersData>;
  formRef: React.MutableRefObject<HTMLElement | null>;
  setSelectedData: React.Dispatch<React.SetStateAction<BulkTransferUsersData | null>>;
  setUserId: React.Dispatch<React.SetStateAction<number>>;
  setUserIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [showBulkTransferModal, setShowBulkTransferModal] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showMore, setShowMore] = React.useState(false);
  const [rowIndex, setRowIndex] = React.useState(-1);
  const pageSize = 10;
  const start = pageSize * currentPage - pageSize;
  const end = pageSize * currentPage;

  return (
    <FullBox mt="20px" fontWeight="regular" width="100%">
      <DesktopWrapper>
        <TableWrapper>
          <Table>
            <THead>
              <Tr>
                <Th>Account Name</Th>
                <Th>Bank</Th>
                <Th>Account Number</Th>
                <Th>Remark</Th>
                <Th>Amount</Th>
                <Th>More</Th>
              </Tr>
            </THead>

            <TBody>
              {tableData.length > 10
                ? tableData.slice(start, end).map(
                    (
                      data: {
                        account: string;
                        amount: number;
                        bankName: string;
                        bankCode: string | undefined;
                        beneficiaryName: string;
                        narration: string;
                      },
                      index
                    ) => (
                      <div key={index}>
                        <Tr cursor="pointer">
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.beneficiaryName ? '-' : data?.beneficiaryName}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.bankName ? '-' : data?.bankName}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.account ? '-' : data?.account}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.narration ? '-' : data?.narration}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {data?.amount === 0 || !data?.amount ? '-' : formatCurrency(data?.amount)}
                          </Td>
                          <Td
                            style={{ fontWeight: '400', fontSize: '14px' }}
                            color="#121212"
                            onClick={() => {
                              if (index === rowIndex) {
                                setShowMore(!showMore);
                              } else {
                                setShowMore(!showMore);
                                setRowIndex(index);
                              }
                            }}
                          >
                            <DotsIcon />
                          </Td>
                        </Tr>
                        {showMore && rowIndex === index && (
                          <div
                            style={{
                              position: 'relative',
                              top: '0rem',
                              left: '450%',
                              width: '141px',
                              zIndex: '10',
                            }}
                          >
                            <FullBox bg="white" p="15px" mb="10px" borderRadius="5px" border="1px solid #EDF1FC">
                              <div>
                                <Text
                                  style={{ cursor: 'pointer' }}
                                  fontSize="14px"
                                  fontWeight="regular"
                                  color="#121212"
                                  mb="6px"
                                  onClick={() => {
                                    setSelectedData(data);
                                  }}
                                >
                                  Edit
                                </Text>
                                <Text
                                  style={{ cursor: 'pointer' }}
                                  fontSize="14px"
                                  fontWeight="regular"
                                  color="#121212"
                                  onClick={() => {
                                    setUserId(index);
                                  }}
                                >
                                  Delete
                                </Text>
                              </div>
                            </FullBox>
                          </div>
                        )}
                      </div>
                    )
                  )
                : tableData.map(
                    (
                      data: {
                        account: string;
                        amount: number;
                        bankName: string;
                        bankCode: string | undefined;
                        beneficiaryName: string;
                        narration: string;
                      },
                      index
                    ) => (
                      <>
                        <Tr key={index} cursor="pointer">
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.beneficiaryName ? '-' : data?.beneficiaryName}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.bankName ? '-' : data?.bankName}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.account ? '-' : data?.account}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {!data?.narration ? '-' : data?.narration}
                          </Td>
                          <Td style={{ fontWeight: '400', fontSize: '14px', color: '#121212' }}>
                            {data?.amount === 0 || !data?.amount ? '-' : formatCurrency(data?.amount)}
                          </Td>
                          <Td
                            style={{ fontWeight: '400', fontSize: '14px' }}
                            color="#121212"
                            onClick={() => {
                              if (index === rowIndex) {
                                setShowMore(!showMore);
                              } else {
                                setShowMore(!showMore);
                                setRowIndex(index);
                              }
                            }}
                          >
                            <DotsIcon />
                          </Td>
                        </Tr>
                        {showMore && rowIndex === index && (
                          <div
                            style={{
                              position: 'relative',
                              top: '0rem',
                              left: '450%',
                              width: '141px',
                              zIndex: '10',
                            }}
                          >
                            <FullBox bg="white" p="15px" mb="10px" borderRadius="5px" border="1px solid #EDF1FC">
                              <div>
                                <Text
                                  style={{ cursor: 'pointer' }}
                                  fontSize="14px"
                                  fontWeight="regular"
                                  color="#121212"
                                  mb="6px"
                                  onClick={() => {
                                    setSelectedData(data);
                                  }}
                                >
                                  Edit
                                </Text>
                                <Text
                                  style={{ cursor: 'pointer' }}
                                  fontSize="14px"
                                  fontWeight="regular"
                                  color="#121212"
                                  onClick={() => {
                                    setUserId(index);
                                  }}
                                >
                                  Delete
                                </Text>
                              </div>
                            </FullBox>
                          </div>
                        )}
                      </>
                    )
                  )}
            </TBody>
          </Table>
        </TableWrapper>

        {!showUnverified && (
          <Button
            width="100%"
            variant="large"
            style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '30px' }}
            onClick={() => {
              setShowBulkTransferModal(true);
            }}
          >
            Send bulk transfer
          </Button>
        )}

        <BulkTransferModal
          showBulkTransferModal={showBulkTransferModal}
          setShowBulkTransferModal={setShowBulkTransferModal}
          amount={bulkSum}
          tableData={tableData}
        />
      </DesktopWrapper>

      <MobileWrapper>
        {tableData.map((data, index) => (
          <FullFlex
            onClick={() => {
              setSelectedData(data);
              setUserIndex(index);
              formRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }}
            key={index}
            p="10px"
            justifyContent="space-between"
          >
            <FullBox>
              <Text textAlign="left" style={{ fontWeight: 'normal', fontSize: '14px', color: '#717171' }}>
                {' '}
                {!data?.beneficiaryName ? '-' : data?.beneficiaryName}
              </Text>
              <Text textAlign="left" style={{ fontWeight: 'normal', fontSize: '10px', color: '#717171' }}>
                {!data?.bankName ? '-' : data?.bankName}
              </Text>
            </FullBox>
            <FullBox>
              <Text textAlign="right" style={{ fontWeight: 'normal', fontSize: '14px', color: '#717171' }}>
                {data?.amount === 0 || !data?.amount ? '-' : formatCurrency(data?.amount)}
              </Text>

              <Text textAlign="right" style={{ fontWeight: 'normal', fontSize: '10px', color: '#717171' }}>
                {' '}
                {!data?.account ? '-' : data?.account}
              </Text>
            </FullBox>
          </FullFlex>
        ))}

        {!showUnverified && (
          <Button
            width="100%"
            variant="large"
            style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '30px', marginBottom: '30px' }}
            onClick={() => {
              setShowBulkTransferModal(true);
            }}
          >
            Send bulk transfer
          </Button>
        )}

        <BulkTransferModal
          showBulkTransferModal={showBulkTransferModal}
          setShowBulkTransferModal={setShowBulkTransferModal}
          amount={bulkSum}
          tableData={tableData}
        />
      </MobileWrapper>

      <ArrowPagination
        id="bulk-pagination"
        currentPage={currentPage}
        totalItems={tableData?.length}
        setCurrentPage={setCurrentPage}
      />
    </FullBox>
  );
}
