import { NavigationTabWrapper, NavigationItem } from './components';
import { NavigationTabItemsEnum } from '../../utils/constants';
import { MapEnums } from '../../types/dashboard';
import { NavigationTabProps } from './types';

export const NavigationTab = ({ active, options, itemsMr }: NavigationTabProps) => (
  <NavigationTabWrapper>
    {options.map((item, index) => (
      <NavigationItem itemMr={itemsMr} to={`${item.route}`} key={`Tab-nav${index}`} isactive={active === item.route}>
        {item.Icon}
        {(NavigationTabItemsEnum as MapEnums)[item.text] || item.text}
      </NavigationItem>
    ))}
  </NavigationTabWrapper>
);
