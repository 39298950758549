import { OfficeListWrapper, SupportPageDetailsWrapper } from '../../styles';
import Header from '../base/header';
import OfficeItem from '../base/office_item';
import useRedux from '../../../../hooks/useRedux';
import { updateCurrentPage } from '../../reducer/slice';

const CallLine = () => {
  const {
    dispatch,
    state: {
      support: {
        contacts: {
          info: { phones },
        },
      },
    },
  } = useRedux();

  return (
    <>
      <Header
        desktopTitle="Call lines"
        desktopDescription="Reach us on any of the the call lines below"
        desktopCloseAction={() => dispatch(updateCurrentPage('home'))}
        mobileBackAction={() => dispatch(updateCurrentPage('home'))}
        mobileTitle="Customer support"
      />
      <SupportPageDetailsWrapper mobilePadding="20px">
        <OfficeListWrapper>
          {phones.map((item, index) => (
            <OfficeItem
              href={`tel:+${item.contactValue}`}
              location={`${item.description} ${index + 1}`}
              address={item.contactValue}
              key={Math.random()}
            />
          ))}
        </OfficeListWrapper>
      </SupportPageDetailsWrapper>
    </>
  );
};

export default CallLine;
