import { Typography } from 'kudi-component-library';
import styled from 'styled-components';

export const TeamUpContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const TeamUpHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

export const TeamUpTitle = styled.div`
  p {
    font-weight: 600;
  }
  @media ${({ theme }) => theme.media.md} {
    p {
      font-weight: 700;
    }
  }
`;
export const TeamUpSubtitle = styled.div`
  p {
    font-size: 14px;
  }
`;

export const TeamUpInputsWrapper = styled.div`
  display: block;

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
`;

export const TeamsUpInputSection = styled.div<{ width?: string }>`
  padding-bottom: 2.2rem;
  width: 100%;

  @media ${({ theme }) => theme.media.lg} {
    width: ${({ width }) => width || '48%'};
  }
`;

export const TeamUpInputTextTerms = styled.div<{ top?: number }>`
  margin-top: ${({ top }) => (top ? `${top}px` : '8px')};
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
`;

export const TeamUpInputTextWrapper = styled.div`
  color: ${({ theme }) => theme.nColors['n-grey4']};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-left: 10px;
`;

export const TeamUpInputTextTermsItem = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  color: ${({ theme }) => theme.nColors['n-dark']};
  pointer: cursor;
`;

export const AccountCreatedContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  height: 55vh;
`;

export const AccountCreatedTextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

// team-loading styles

export const TeamLoadingWrapper = styled.div<{ isDefault: boolean }>`
  height: ${({ isDefault }) => (isDefault ? ' 100vh' : '100%')};
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    width: 50px;
    height: 50px;
    path {
      animation-name: changeSvgFill;
      animation-duration: 1.5s;
      animation-timing-function: ease-out;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }
  @media ${({ theme }) => theme.media.md} {
    svg {
      width: ${({ isDefault }) => (isDefault ? '12%' : '50px')};
      height: ${({ isDefault }) => (isDefault ? '12%' : '50px')};
    }
  }
  ${({ theme }) => `
  @keyframes changeSvgFill {
    0% {
      fill: ${theme.nColors['n-dark']};
      border-radius: 100%;
    }

    50% {
      fill: ${theme.nColors['n-grey2']};
    }

    100% {
      fill: ${theme.nColors['n-dark']};
    }
  }
  `}
`;

// found user

export const FoundUserContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  max-width: 338px;
  width: 100%;
`;

export const FoundUserTitle = styled(Typography)`
  margin-top: 10px;
`;

export const FoundUserDescription = styled(Typography)`
  margin-bottom: 20px;
`;

// invalid token
export const InvalidTokenDescription = styled(Typography)`
  max-width: 400px;
`;
