import React, { useEffect } from 'react';
import { useSelect } from 'downshift';
import { ChevronDown } from '../../../assets/svg';
import {
  PaginationWrapper,
  PaginationList,
  PaginationItem,
  PaginationConfig,
  PaginationConfigText,
  PaginationConfigDropDownWrapper,
  PaginationConfigDropDownContent,
  PaginationConfigDropDownMenuWrapper,
  PaginationConfigDropDownMenuItem,
} from '../styles';

const pageSet = [10, 20, 30];

const Pagination = ({
  onPaginationChange = () => {},
  page = 1,
  pageSize = 10,
  total = 100,
}: {
  onPaginationChange?: (value: { page?: number | null; pageSize?: number | null }) => void;
  page?: number;
  pageSize?: number;
  total?: number;
  executeScroll?: () => void;
}) => {
  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: pageSet,
    onSelectedItemChange: (e) => {
      onPaginationChange({ pageSize: e.selectedItem, page: 0 });
    },
  });

  let pageList = total / pageSize < 1 ? 1 : Math.floor(total / pageSize);
  if (total / pageSize > 1 && total % pageSize) {
    pageList += 1;
  }

  return (
    <PaginationWrapper>
      <PaginationList>
        {total > 1 &&
          Array(pageList)
            .fill(0)
            .map((item, index) => (
              <PaginationItem
                onClick={() => {
                  onPaginationChange({ pageSize, page: index });
                }}
                active={page === index}
              >
                {index + 1}
              </PaginationItem>
            ))}
      </PaginationList>
      <PaginationConfig>
        <PaginationConfigText>Show per page</PaginationConfigText>
        <PaginationConfigDropDownWrapper>
          <PaginationConfigDropDownContent {...getToggleButtonProps()} isOpen={isOpen}>
            {selectedItem ? selectedItem : pageSize} <ChevronDown />
          </PaginationConfigDropDownContent>
          {isOpen ? (
            <PaginationConfigDropDownMenuWrapper {...getMenuProps()}>
              {pageSet.map((item, index) => (
                <PaginationConfigDropDownMenuItem {...getItemProps({ item, index })} key={`pagination-drop-${index}`}>
                  {item}
                </PaginationConfigDropDownMenuItem>
              ))}
            </PaginationConfigDropDownMenuWrapper>
          ) : null}
        </PaginationConfigDropDownWrapper>
        {total > 1 ? (
          <PaginationConfigText>
            Showing {pageSize * page + 1}-{pageSize * (page + 1) < total ? pageSize * (page + 1) : total} 0f {total}
          </PaginationConfigText>
        ) : null}
      </PaginationConfig>
    </PaginationWrapper>
  );
};

export default Pagination;
