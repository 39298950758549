import styled from 'styled-components';
import { OnboardingLayout } from '../../../components/layout';
import { ReactNode, useEffect } from 'react';
import { useFlags } from 'flagsmith/react';
import { FLAGSMITH_FEATURES } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  height: calc(100svh - 173px);
  overflow-y: scroll;
  width: 100%;
  justify-content: center;
`;

const Onboardingv3 = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { web_onboarding_v3: onboardingv3Flag } = useFlags([FLAGSMITH_FEATURES.WEB_ONBOARDING_V3]);

  useEffect(() => {
    if (!onboardingv3Flag.enabled) {
      navigate('/auth/login');
    }
  }, [onboardingv3Flag.enabled]);

  return (
    <OnboardingLayout isOnboarding>
      <Wrapper>{children}</Wrapper>
    </OnboardingLayout>
  );
};

export default Onboardingv3;
