import useRedux from '../../../../hooks/useRedux';
import HeroImage from '../../assets/header_image.png';
import { Avatar, MobileHero, MobileHeroWelcomeQuestion, MobileHeroWelcomeText } from '../../styles';

const Hero = () => {
  const {
    state: {
      auth: { fullUser },
    },
  } = useRedux();

  return (
    <MobileHero id="big-hero" style={{ backgroundImage: `url(${HeroImage})` }}>
      <Avatar />
      <MobileHeroWelcomeText>Hi {fullUser?.firstName},</MobileHeroWelcomeText>
      <MobileHeroWelcomeQuestion>How can we help you today?</MobileHeroWelcomeQuestion>
    </MobileHero>
  );
};

export default Hero;
