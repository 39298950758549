import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import {
  createInvite,
  createReferral,
  UpgradeReferral,
  ReferralUpgradeEligibility,
  GetReferralOverview,
  UpgradePartnerReferral,
  GetReferralDownlines,
  AssignExistingUsertoReferrer,
  CompleteBoltReferralCreation,
  MoveReferralCommissionToPrimaryWallet,
  GetReferralByUserID,
  GetReferralByUserPrimaryID,
  AddPNDToUser,
  UpgradeKYCLevelForUser,
  GetReferedDownlineDetail,
  GetReferedDownlineResponse,
} from './thunkActions';
import { ReferralDownlines } from '../../types/referrals';
import { addExtraReducers, addReducer } from './helperSlice';
import { DowlineTransaction, TerminalArrayListItemMap } from '../../pages/referral/defs';

interface Pagination {
  limit?: number;
  currentPage?: number;
  totalPages?: number;
  total?: number;
}

export interface IReferralData {
  balance: number;
  status: string;
  totalActiveReferrals: number;
  totalInActiveReferrals: number;
  totalReferrals: number;
  totalPendingReferrals: number;
  totalActiveTerminals: number;
  totalAssignedTerminals: number;
  totalTerminals: number;
  totalUnassignedTerminals: number;
}

export type DownlineModal = 'PND' | 'DeactivatePND' | 'UpgradeKYC' | 'MapTerminal' | 'UnmapTerminal' | null;
export interface ReferralInitialState {
  isLoading: boolean;
  noOfUsers: number | string;
  referralOverviewData: IReferralData | null;
  getReferralByUserId: Record<string | number, Record<string, string> | null>;
  getReferralByUserPrimaryID: Record<string | number, [] | Record<string, string> | null>;
  getReferralDownlines: Array<ReferralDownlines>;
  totalGetReferralDownlines: Array<ReferralDownlines>;
  getReferralDownlinesChuncked: Array<Array<ReferralDownlines>>;
  upgradePartnerToReferralData: Record<string | number, [] | Record<string, string> | null>;
  GetReferedDownlineDetailData: GetReferedDownlineResponse | null;
  filters: Record<string, string | Date>;
  pagination: Pagination;

  // Downline section
  downline: {
    selectedDownline: ReferralDownlines | null;
    selectedTerminal: TerminalArrayListItemMap | null;
    showModal: DownlineModal;
    isLoading: DownlineModal;
    transactions: {
      list: Record<number, Array<DowlineTransaction>>;
      data: Array<DowlineTransaction>;
      loading: boolean;
      cursor: null | string;
    };
    terminals: {
      data: TerminalArrayListItemMap[];
      loading: boolean;
    };
  };
}

const initialState: ReferralInitialState = {
  isLoading: false,
  noOfUsers: 0,
  referralOverviewData: null,
  getReferralByUserId: {},
  getReferralByUserPrimaryID: {},
  getReferralDownlines: [],
  getReferralDownlinesChuncked: [],
  totalGetReferralDownlines: [],
  upgradePartnerToReferralData: {},
  GetReferedDownlineDetailData: null,
  filters: {},
  pagination: {
    currentPage: 1,
    limit: 50,
    totalPages: 0,
    total: 0,
  },
  downline: {
    selectedDownline: null,
    selectedTerminal: null,
    showModal: null,
    isLoading: null,
    transactions: {
      list: {},
      data: [],
      loading: false,
      cursor: null,
    },
    terminals: {
      data: [],
      loading: false,
    },
  },
};

const initializePagination = (
  state: ReferralInitialState,
  getReferralDownlines: Array<ReferralDownlines>,
  filters?: Record<string, string | Date>,
  limit?: number
) => {
  const chunked = _.chunk(getReferralDownlines, limit ?? state.pagination.limit) as Array<Array<ReferralDownlines>>;

  state.getReferralDownlinesChuncked = chunked;
  state.pagination.currentPage = 1;
  state.pagination.totalPages = chunked.length ?? 1;
  state.pagination.total = getReferralDownlines.length;
  state.getReferralDownlines = chunked[0] ?? [];
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<Record<string, string | Date>>) => {
      state.filters = action.payload;
      initializePagination(state, state.totalGetReferralDownlines, action.payload);
    },

    updatePagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      if (state.pagination.limit === action?.payload?.limit) {
        state.pagination.currentPage = action.payload.currentPage;
        state.getReferralDownlines = state.getReferralDownlinesChuncked[action.payload.currentPage || 0];
      } else {
        state.pagination.limit = action.payload.limit;
        initializePagination(state, state.totalGetReferralDownlines, undefined, action.payload.limit);
      }
    },
    ...addReducer,
  },
  extraReducers: (builder) => {
    // Create referral
    builder
      .addCase(createReferral.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReferral.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createReferral.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Invite referral
    builder
      .addCase(createInvite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvite.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createInvite.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Referral upgrade eligibility check
    builder
      .addCase(ReferralUpgradeEligibility.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ReferralUpgradeEligibility.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(ReferralUpgradeEligibility.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Upgrade referral to partner
    builder
      .addCase(UpgradeReferral.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpgradeReferral.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(UpgradeReferral.fulfilled, (state, action) => {
        state.isLoading = false;
        state.upgradePartnerToReferralData = action.payload;
      });
    // Get referral overview
    builder
      .addCase(GetReferralOverview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetReferralOverview.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetReferralOverview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.referralOverviewData = action.payload.data;
      });
    // Upgrade partner refferal
    builder
      .addCase(UpgradePartnerReferral.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpgradePartnerReferral.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(UpgradePartnerReferral.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Get referral downlines
    builder
      .addCase(GetReferralDownlines.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetReferralDownlines.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetReferralDownlines.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalGetReferralDownlines = action.payload.data ?? [];
        initializePagination(state, action.payload.data ?? []);
      });
    // Assign Existing User to Referrer
    builder
      .addCase(AssignExistingUsertoReferrer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AssignExistingUsertoReferrer.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(AssignExistingUsertoReferrer.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Complete Bolt Referral Creation
    builder
      .addCase(CompleteBoltReferralCreation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CompleteBoltReferralCreation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(CompleteBoltReferralCreation.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Move Referral Commission to Primary Wallet
    builder
      .addCase(MoveReferralCommissionToPrimaryWallet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(MoveReferralCommissionToPrimaryWallet.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(MoveReferralCommissionToPrimaryWallet.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Get referral by User ID
    builder
      .addCase(GetReferralByUserID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetReferralByUserID.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetReferralByUserID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getReferralByUserId = action.payload;
      });
    // Get referral by User Primary ID
    builder
      .addCase(GetReferralByUserPrimaryID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetReferralByUserPrimaryID.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetReferralByUserPrimaryID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getReferralByUserPrimaryID = action.payload;
      });
    // Add a PND to a user
    builder
      .addCase(AddPNDToUser.pending, (state) => {
        state.isLoading = true;
        state.downline.isLoading = 'PND';
      })
      .addCase(AddPNDToUser.rejected, (state) => {
        state.isLoading = false;
        state.downline.isLoading = null;
      })
      .addCase(AddPNDToUser.fulfilled, (state) => {
        state.isLoading = false;
        state.downline.isLoading = null;
      });
    // Upgrade KYC for user
    builder
      .addCase(UpgradeKYCLevelForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(UpgradeKYCLevelForUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(UpgradeKYCLevelForUser.fulfilled, (state) => {
        state.isLoading = false;
      });
    // Get referred downline detail
    builder
      .addCase(GetReferedDownlineDetail.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(GetReferedDownlineDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.GetReferedDownlineDetailData = action.payload;
      });

    addExtraReducers(builder);
  },
});

const defaultReducer = referralSlice.actions;

type BaseReferralReducerActions = typeof defaultReducer;

interface ReducersActions extends BaseReferralReducerActions {
  selectDownline: ActionCreatorWithPayload<ReferralDownlines | null, 'referral/updatePagination'>;
  toggleShowModal: ActionCreatorWithPayload<DownlineModal, 'referral/toggleShowModal'>;
  selectTerminal: ActionCreatorWithPayload<TerminalArrayListItemMap | null, 'referral/selectTerminal'>;
  selectTransactionData: ActionCreatorWithPayload<number, 'referral/selectTransactionDate'>;
  resetTransactionData: ActionCreatorWithoutPayload<'resetTransactionData'>;
}

export const {
  updateFilters,
  updatePagination,
  selectDownline,
  toggleShowModal,
  selectTerminal,
  selectTransactionData,
  resetTransactionData,
} = referralSlice.actions as ReducersActions;
export default referralSlice.reducer;
export * from './thunkActions';
