import { createAsyncThunk } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../../app/store';
import { ReduxAction } from '../../../../types/redux';
import axios from '../../../../utils/http';
import { issueResolutionURL } from '../../constants';
import { IssueCategory } from '../../defs';

interface GetAllCategoriesResponse {
  message: string;
  data: IssueCategory[];
}

export const GetAllCategories = createAsyncThunk<GetAllCategoriesResponse, ReduxAction, { state: RootState }>(
  '/support/get_all_categories',
  async ({ onSuccess, onFailure }, { rejectWithValue }) => {
    try {
      const data: GetAllCategoriesResponse = await axios.get(`/${issueResolutionURL}/categories/all`);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
