import styled from 'styled-components';

const InputWrapper = styled.div`
  input {
    display: none;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;

  p {
    color: #121212;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
`;

const InputBox = styled.div`
  border: 1px dashed #eeeeee;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .uploaded-state {
    background: #eeeeee;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    .file {
      display: flex;
      align-items: center;
      gap: 9px;

      svg {
        width: 16px;
        height: 16px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #555555;
      }
    }
  }

  .empty-state {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #c6c6c6;
      margin-left: 10px;
    }
  }
`;

export { InputWrapper, Label, InputBox };
