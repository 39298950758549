import { useLocation } from 'react-router-dom';
import { NavigationOptionInterface } from '../../types/navigation';
import { NavigationOptionHolder, NavigationOptionContent, NavigationOptionName } from './style';
import { useNavigationContext } from './data';
import { useAppDispatch } from '../../app/hooks';
import { setGuestMenuOption } from '../../features/dashboard/slice';

interface NavigationOptionProps extends NavigationOptionInterface {
  baseRoute: string;
}

const NavigationOption = ({ name, route, Icon, baseRoute }: NavigationOptionProps) => {
  const match = useLocation();
  const dispatch = useAppDispatch();
  const { setOpen } = useNavigationContext();

  const handleClick = () => {
    setOpen(false);
    dispatch(setGuestMenuOption(route));
  };

  const baseRouteCheck = match.pathname.split('/')[2];
  const routeCheck = match.pathname.split('/')[3];

  const active: boolean = baseRouteCheck === baseRoute && routeCheck === route;

  return (
    <NavigationOptionHolder id="guest-navigation-content-options-wrapper" active={active} onClick={handleClick}>
      <NavigationOptionContent id="guest-navigation-content-options-main" active={active}>
        <Icon id="guest-navigation-content-options-main-icon" width="20px" height="20px" strokeWidth="1.5px" />
        <NavigationOptionName id="guest-navigation-content-options-main-name" active={active}>
          {name}
        </NavigationOptionName>
      </NavigationOptionContent>
    </NavigationOptionHolder>
  );
};
export default NavigationOption;
