import { Typography } from 'kudi-component-library';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { formatCurrency, formatTableDateValue, transformTransactionSummaryByType } from '../../../../../utils';
import {
  ResultCard,
  ResultCardRow,
  ResultDateTime,
  ResultsEmptyWrapper,
  ResultStatus,
  ResultsTextWrapper,
  ResultSummary,
  ResultsWrapper,
  SearchDropdownHeader,
  SearchDropdownHeaderText,
} from '../styles';
import { transformStatustext } from '../utils';

import { SearchResultsProps } from './defs';

const GlobalSearchResults = ({ results }: { results: SearchResultsProps[] }) => {
  const isMobile = useIsMobile(600);
  return (
    <ResultsWrapper id="global_search_filter_results">
      <SearchDropdownHeader>
        <SearchDropdownHeaderText>Your results</SearchDropdownHeaderText>
      </SearchDropdownHeader>
      {results && results.length ? (
        results.map(
          ({ id, amount, status, timeCreated, type, customerBillerId }: SearchResultsProps, index: number) => (
            <ResultsTextWrapper key={`global_search_result_${index}`} to={`/dashboard/banking/transactions/${id}`}>
              <ResultCard isMobile={isMobile}>
                <ResultCardRow>
                  <ResultSummary variant="n-text2" color="grey4">
                    {transformTransactionSummaryByType(type)} {customerBillerId}
                  </ResultSummary>
                  <Typography variant="n-text2" color="grey4">
                    {formatCurrency(amount)}
                  </Typography>
                </ResultCardRow>
                <ResultCardRow>
                  <ResultDateTime variant="n-text1">
                    {formatTableDateValue(timeCreated as unknown as Date)}
                  </ResultDateTime>
                  <ResultStatus statusText={status}>{transformStatustext(status)}</ResultStatus>
                </ResultCardRow>
              </ResultCard>
            </ResultsTextWrapper>
          )
        )
      ) : (
        <ResultsEmptyWrapper>Sorry there are no results for that search term</ResultsEmptyWrapper>
      )}
    </ResultsWrapper>
  );
};

export default GlobalSearchResults;
