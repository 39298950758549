import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, OtpInput, Text } from 'kudi-component-library';
import Modal from '../../components/Modal';
import { FullBox, FullFlex } from '../../styles/generic';
import { Separator } from '../business-tools/styles';
import CountDown from '../../components/CountDown';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { verifyOtp, resendOtp } from '../../features/transfers/thunkActions';
import { useToast } from '../../hooks/useToast';
import { ModalContent } from '../../components/Modal/style';
import { TOAST_TIMER } from '../../utils/constants';
import { BeneficiariesData } from '../../types/transactions';
import { concealText } from '../../utils';
import USSDOtp from '../auth/components/ussdOtp';

interface InitialValues {
  otp: string;
}
const InitialFormValues: InitialValues = {
  otp: '',
};

export default function ConfirmOtp({
  transRef,
  showOtpModal,
  saveBen,
  setShowOtpModal,
  setShowPopup,
  setShowResponseModal,
  setShowTransferToNombaModal,
  setNombaEmail,
  setName,
  setAmount,
  setStep,
  setBankName,
  setAccountName,
  setDescription,
  setAccountNumber,
  setBeneficiaryName,
  setSaveBen,
  setUserPin,
  setSelectedData,
  handleAddBeneficiary,
  otpDest,
}: {
  showOtpModal: boolean;
  saveBen?: boolean | null | undefined;
  transRef: string;
  handleAddBeneficiary?: () => void;
  setShowOtpModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setStep?: React.Dispatch<React.SetStateAction<number>>;
  setBankName?: React.Dispatch<React.SetStateAction<string>>;
  setName?: React.Dispatch<React.SetStateAction<string>>;
  setNombaEmail?: React.Dispatch<React.SetStateAction<string>>;
  setAccountName?: React.Dispatch<React.SetStateAction<string>>;
  setDescription?: React.Dispatch<React.SetStateAction<string>>;
  setAccountNumber?: React.Dispatch<React.SetStateAction<string>>;
  setBeneficiaryName?: React.Dispatch<React.SetStateAction<string>>;
  setUserPin?: React.Dispatch<React.SetStateAction<string>>;
  setSaveBen?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTransferToNombaModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData?: React.Dispatch<React.SetStateAction<BeneficiariesData | null>>;
  otpDest: string;
}) {
  const [transactionPin, setTransactionPin] = React.useState<string>('');
  const [otpError, setOtpError] = React.useState<string>('');
  const [timer, setTimer] = React.useState<number>(5);
  const dispatch = useAppDispatch();
  const toast = useToast(TOAST_TIMER);
  const { inProgress } = useAppSelector((state) => state.transfers);

  const { fullUser } = useAppSelector((state) => state.auth);

  const confirmTokenSchema = Yup.object().shape({
    token: Yup.string().length(4).required(),
  });
  function handleOtpChange(val: string) {
    setTransactionPin(val);
    setOtpError('');
  }

  const isDestEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(otpDest);

  function handleVerifyOtp() {
    dispatch(
      verifyOtp({
        transactionRef: transRef,
        transactionOtp: transactionPin,

        onFailure: (error: { data: { data: { message: string } } }) => {
          if (error.data.data.message === 'Unknown verification token') {
            setOtpError('Incorrect OTP entered');
          }
        },

        onSuccess: () => {
          setShowOtpModal(false);
          setShowResponseModal(true);
          if (saveBen && handleAddBeneficiary) {
            handleAddBeneficiary();
          }
        },
      })
    );
  }

  function handleResendOtp() {
    dispatch(
      resendOtp({
        transactionRef: transRef,
        otpType: isDestEmail ? 'EMAIL' : 'PHONE',

        onFailure: (error: { data: { message: string } }) => {
          toast('error', error?.data?.message || 'Unable to resend OTP. Please try again later');
        },

        onSuccess: () => {
          toast('success', `OTP successfully resent to your ${isDestEmail ? 'email' : 'phone number'} `);
        },
      })
    );
  }

  return (
    <ModalContent
      onClick={() => {
        setShowOtpModal(true);
      }}
    >
      <Modal
        showModal={showOtpModal}
        onClose={() => {
          setShowOtpModal(false);
          setAmount(0);
          setStep?.(0);
          setBankName?.('');
          setAccountName?.('');
          setAccountNumber?.('');
          setBeneficiaryName?.('');
          setDescription?.('');
          setSaveBen?.(false);
          setName?.('');
          setNombaEmail?.('');
          setUserPin?.('');
          setShowPopup?.(false);
          setSelectedData?.(null);
          setShowTransferToNombaModal?.(false);
        }}
      >
        <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pl="30px" pr="30px" pb="10px">
          <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
            OTP Authentication
          </Text>
          <Text color="#121212" fontSize="12px" textAlign="center" mt="10px">
            Please enter the 4-digit OTP we sent to your {isDestEmail ? 'email' : 'phone number'} {concealText(otpDest)}
          </Text>

          <FullBox mt="60px" width="100%">
            <Formik initialValues={InitialFormValues} onSubmit={() => {}} validationSchema={confirmTokenSchema}>
              <OtpInput
                id="single-transfer-otp"
                inputsNumber={4}
                name="transactionPin"
                label="Enter OTP"
                value={transactionPin}
                onChange={(e) => handleOtpChange(e.target.value)}
                error={otpError}
                type="password"
                style={{ color: '#121212' }}
              />
            </Formik>
          </FullBox>

          <FullBox width="100%" mt="60px">
            <Separator height="1rem" />
            {transactionPin === '' || transactionPin.length < 4 || inProgress ? (
              <Button
                id="single-transfer-confirm-disabled"
                width="100%"
                variant="large"
                style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                disabled
              >
                Verify account
              </Button>
            ) : (
              <Button
                id="single-transfer-confirm-enabled"
                onClick={() => {
                  handleVerifyOtp();
                }}
                width="100%"
                variant="large"
                style={{ backgroundColor: '#FFCC00', color: '#121212' }}
              >
                Verify account
              </Button>
            )}
          </FullBox>
          <Text
            color="#121212"
            fontSize="12px"
            textAlign="center"
            mt="20px"
            style={{ borderBottom: '2px solid #FFCC00' }}
          >
            {timer === 0 ? (
              <span
                id="resend-otp-button"
                style={{ cursor: 'pointer' }}
                role="button"
                onKeyDown={() => {}}
                tabIndex={0}
                onClick={() => {
                  if (timer) {
                    return;
                  }
                  handleResendOtp();
                  setTimer(5);
                }}
              >
                Resend OTP
              </span>
            ) : (
              <CountDown {...{ minutes: timer, seconds: 0, cb: () => setTimer(0) }} />
            )}
            <Separator height="20px" />
            {isDestEmail ? null : <USSDOtp time={10} />}
          </Text>
        </FullFlex>
      </Modal>
    </ModalContent>
  );
}
