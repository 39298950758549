import { useAppDispatch } from '../../../../../app/hooks';
import { ArrowDownCircle, PlusCircle } from '../../../../../assets/svg';
import { setShowAddModal, setShowWithdrawModal } from '../../../../../features/banking/bankingModalControl/slice';
import {
  SearchDropdownHeader,
  SearchDropdownHeaderText,
  SearchDropdownOptionWrapper,
  SearchDropdownOptionText,
  QuickActionsWrapper,
  QuickAction,
  QuickActionText,
  SearchDropdownHeaderClickableText,
} from '../styles';
import { RecentSearches } from './defs';

interface RecentSearchesProps {
  clearRecentSearches: () => void;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  recentSearches: RecentSearches;
  handleSelectRecentSearch: (searchTerm: string) => void;
}

const GlobalSearchRecent = ({
  clearRecentSearches,
  setShowDropdown,
  recentSearches,
  handleSelectRecentSearch,
}: RecentSearchesProps) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <SearchDropdownHeader>
        <QuickActionsWrapper>
          <SearchDropdownHeaderText>Quick actions</SearchDropdownHeaderText>
          <QuickAction onClick={() => dispatch(setShowAddModal(true))} id="global_search_filter_add_money">
            <PlusCircle color="#121212" strokeWidth={1} width={18} />
            <QuickActionText>Add money</QuickActionText>
          </QuickAction>
          <QuickAction onClick={() => dispatch(setShowWithdrawModal(true))} id="global_search_filter_withdraw">
            <ArrowDownCircle color="#121212" strokeWidth={1} width={18} />
            <QuickActionText>Withdraw</QuickActionText>
          </QuickAction>
        </QuickActionsWrapper>
      </SearchDropdownHeader>
      <SearchDropdownHeader>
        <SearchDropdownHeaderText>Recent searches</SearchDropdownHeaderText>
        <SearchDropdownHeaderClickableText
          onClick={() => {
            clearRecentSearches();
            setShowDropdown((state) => !state);
          }}
          id="global_search_filter_clear_recent_searches"
        >
          Clear all
        </SearchDropdownHeaderClickableText>
      </SearchDropdownHeader>
      {recentSearches.map((searchTerm: string, index: number) => (
        <SearchDropdownOptionWrapper
          key={`recent_search_${index}`}
          onClick={() => handleSelectRecentSearch(searchTerm)}
        >
          <SearchDropdownOptionText>{searchTerm}</SearchDropdownOptionText>
        </SearchDropdownOptionWrapper>
      ))}
    </>
  );
};

export default GlobalSearchRecent;
