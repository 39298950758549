import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Box } from 'rebass/styled-components';
import { NTheme as theme } from 'kudi-component-library';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import AppRouter from './router';
import GlobalStyles from './styles/global';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from './components/Toast';
import { ToastProvider } from './utils/toastContext';
import { store } from './app/store';
import config from './utils/config';
import { getToken } from './utils/firebase';

getToken();

function App() {
  const TRACKING_ID = 'G-F4H83CDY9T';
  ReactGA.initialize(TRACKING_ID);

  return (
    <Provider store={store}>
      <ToastProvider>
        <FlagsmithProvider options={{ environmentID: config.flagsmith_api_key }} flagsmith={flagsmith}>
          <Box>
            <ToastContainer />
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <AppRouter />
            </ThemeProvider>
          </Box>
        </FlagsmithProvider>
      </ToastProvider>
    </Provider>
  );
}

export default App;
