import { Formik } from 'formik';
import Onboardingv3 from '../index';
import useBusinessInformation from '../models/useBusinessInformation';
import FormHeader from './form-header';
import OnboardingStepCounter from './step-counter';
import { ChooseAccountTypeWrapper, FormActionContainer, FormBodyWrapper, FormRow, TransparentButton } from './styles';
import TextInputV2 from '../../../../components/inputs';
import { Button } from 'kudi-component-library';

import DropdownInputV2 from '../../../../components/inputs/dropdownInputV2';
import { useToast } from '../../../../hooks/useToast';
import { useNavigate } from 'react-router-dom';

const BusinessInformation = () => {
  const toast = useToast(3000);
  const navigate = useNavigate();

  const { InitialFormValues, validationSchema, states, setSelectedState, lgas, categories } = useBusinessInformation();
  return (
    <Onboardingv3>
      <ChooseAccountTypeWrapper>
        <OnboardingStepCounter step={4} />
        <FormHeader
          title="Business Information"
          description={<>Kindly provide us correct information about your business.</>}
        />
        <Formik
          validationSchema={validationSchema}
          initialValues={InitialFormValues}
          validateOnMount
          onSubmit={(values, { setSubmitting }) => {
            // eslint-disable-next-line no-console
            console.log(values);
            setSubmitting(true);
            toast('success', 'You have successfully completed the onboarding process');
            navigate('/login');
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            touched,
            errors,
            isValid,
            isSubmitting,
            handleBlur,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormBodyWrapper>
                  <TextInputV2
                    label="Business Name"
                    type="text"
                    name="businessName"
                    value={values.businessName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.businessName && errors.businessName}
                  />
                  <DropdownInputV2
                    marginTop="0px"
                    label="Business Catergory"
                    name="businesCategory"
                    width="100%"
                    error={touched.businesCategory && errors.businesCategory}
                    onChange={(e) => {
                      setFieldValue('businesCategory', e?.value);
                    }}
                    options={categories}
                  />
                  <FormRow>
                    <DropdownInputV2
                      marginTop="0px"
                      label="State"
                      name="state"
                      width="100%"
                      error={touched.state && errors.state}
                      options={states}
                      onChange={(e) => {
                        setSelectedState({ value: e?.value as string, label: e?.value as string });
                        setFieldValue('state', e?.value);
                      }}
                    />
                    <DropdownInputV2
                      marginTop="0px"
                      label="LGA"
                      name="lga"
                      width="100%"
                      error={touched.lga && errors.lga}
                      onChange={(e) => {
                        setFieldValue('lga', e?.value);
                      }}
                      options={lgas}
                    />
                  </FormRow>
                </FormBodyWrapper>
                <FormActionContainer>
                  <Button type="submit" width="100%" variant="medium" disabled={!isValid || isSubmitting}>
                    Continue to dashboard
                  </Button>
                  <TransparentButton type="button" variant="medium" width="100%" color="tertiary" disabled>
                    Complete verfication
                  </TransparentButton>
                </FormActionContainer>
              </form>
            );
          }}
        </Formik>
      </ChooseAccountTypeWrapper>
    </Onboardingv3>
  );
};

export default BusinessInformation;
