import {
  ICreateInventoryProduct,
  CreateProductResponse,
  ICreateProductResponse,
} from '../../../types/inventoryProducts';
import axios from '../../../utils/merchantAcqServiceHttp';

const updateStorehouseProduct = async (payload: ICreateInventoryProduct, id: string) => {
  try {
    const { data }: ICreateProductResponse = await axios.put(`/webbe/v1/storehouse/items/${id}`, payload);

    return Promise.resolve<CreateProductResponse>({ error: null, data, status: true });
  } catch (error: any) {
    return Promise.resolve<CreateProductResponse>({
      error: error?.data?.errors[0] || error?.data?.message,
      data: null,
      status: false,
    });
  }
};

export default updateStorehouseProduct;
