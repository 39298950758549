import { OutlinedX, CircledCheck, Alert, Bulb } from '../../assets/svg';
import { capitalizeFirstLetter } from '../../utils';
import { useToastDispatchContext, useToastStateContext } from '../../utils/toastContext';
import {
  Message,
  NotificationIconContainer,
  Title,
  ToastContent,
  ToastContentWrapper,
  ToastHolder,
  ToastMessage,
  ToastWrapper,
} from './style';
import { ToastType } from './type';

export default function Toast({ type, message, id, title, cb }: ToastType) {
  const dispatch = useToastDispatchContext();

  return (
    <ToastWrapper
      onClick={() => {
        cb?.();
        dispatch({ id, type: 'DELETE_TOAST', toast: { id, type, message, title } });
      }}
    >
      <ToastContentWrapper isTitled={!!title}>
        <ToastContent>
          <NotificationIconContainer state={type} id="notification-icon-container">
            {type === 'success' ? <CircledCheck /> : null}
            {type === 'info' ? <Bulb /> : null}
            {type === 'error' ? <Alert stroke="#FF2200" /> : null}
          </NotificationIconContainer>
          <ToastMessage>
            {title && <Title> {capitalizeFirstLetter(title)}</Title>}
            <Message>{message}</Message>
          </ToastMessage>
        </ToastContent>
        <OutlinedX className="close-icon-svg" />
      </ToastContentWrapper>
    </ToastWrapper>
  );
}

export function ToastContainer() {
  const { toasts } = useToastStateContext();

  return (
    <ToastHolder>
      {toasts &&
        toasts.map((toast: ToastType) => (
          <Toast
            id={toast.id}
            key={toast.id}
            title={toast?.title}
            type={toast.type}
            message={typeof toast.message === 'string' ? toast.message : 'An error occurred'}
            cb={toast?.cb}
          />
        ))}
    </ToastHolder>
  );
}
