import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStyles, InputWrapper, LabelText } from './styles';
import { CreatableDropdownProps, DefaultOnChange, DropdownInputProps } from './types';
import { DropdownIndicator } from './components';

const DropdownInput = (props: DropdownInputProps) => {
  const {
    label,
    width,
    placeholder,
    value,
    isDisabled = false,
    onChange,
    options,
    name,
    defaultValue,
    marginTop,
    showSearchIcon = false,
  } = props;
  return (
    <InputWrapper width={width} marginTop={marginTop}>
      {label && <LabelText variant="n-text1">{label}</LabelText>}
      <Select
        name={name}
        placeholder={placeholder}
        components={{ DropdownIndicator: showSearchIcon ? DropdownIndicator : undefined }}
        classNamePrefix="select"
        value={value}
        defaultValue={defaultValue}
        onChange={onChange as DefaultOnChange | undefined}
        styles={customStyles}
        options={options}
        isSearchable
        isDisabled={isDisabled}
        theme={(defaultTheme) => ({
          ...defaultTheme,
          borderRadius: 5,
          colors: {
            ...defaultTheme.colors,
            text: '#000',
            primary25: '#f4f2f2',
            primary: '#d3d3d3',
          },
        })}
      />
    </InputWrapper>
  );
};

export const CreatableDropdownInput = (props: CreatableDropdownProps) => {
  const {
    label,
    width,
    placeholder,
    value,
    isDisabled = false,
    onChange,
    options,
    name,
    defaultValue,
    marginTop,
    onCreateOption,
  } = props;
  return (
    <InputWrapper width={width} marginTop={marginTop}>
      {label && <LabelText variant="n-text1">{label}</LabelText>}
      <CreatableSelect
        name={name}
        isClearable
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        styles={customStyles}
        options={options}
        isSearchable
        isDisabled={isDisabled}
        onCreateOption={onCreateOption}
        createOptionPosition="first"
        theme={(defaultTheme) => ({
          ...defaultTheme,
          borderRadius: 5,
          colors: {
            ...defaultTheme.colors,
            text: '#000',
            primary25: '#f4f2f2',
            primary: '#d3d3d3',
          },
        })}
      />
    </InputWrapper>
  );
};

export default DropdownInput;
