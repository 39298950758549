import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface DownloadReportResponse extends ApiResponse {
  message: string;
  success: boolean;
  data: {
    downloadTitle: string;
    downloadId: string;
    estimatedReadyTime: string;
  };
}

const downloadSettlementReport = async ({
  settlementId,
  startDate,
  endDate,
  outletId,
}: {
  settlementId?: string;
  startDate?: string;
  endDate?: string;
  outletId?: string;
}) => {
  let url = '/webbe/v1/autosettlement';
  let outletBaseUrl = '/webbe/v1/accounts/outlets';
  let settlementBaseUrl = outletId ? outletBaseUrl : url;
  let settlementListBaseUrl = outletId ? outletBaseUrl : url;

  if (startDate && endDate) {
    url = settlementListBaseUrl + `/settlement-list/download?dateFrom=${startDate}&dateTo=${endDate}`;
  }
  if (settlementId) {
    url = settlementBaseUrl + `/settlement/transactions/download?settlementId=${settlementId}`;
  }

  try {
    const result: DownloadReportResponse = await axios.get(url, {
      ...(outletId && { headers: { subAccountId: outletId } }),
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default downloadSettlementReport;
