import { Typography } from 'kudi-component-library';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const HeaderWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;

  p {
    margin: 20px 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const SettlementsAccountContent = styled.div`
  padding: 40px 20px;
`;

export const SettlementsAccountModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 0px;
  @media ${({ theme }) => theme.media.lg} {
    padding: 44px 23px;
  }
`;

export const SettlementAccountsTitle = styled(Typography)`
  font-weight: 700;
  letter-spacing: -0.48px;
`;

export const BodyTitle = styled.div`
  display: flex;
  gap: 10px;
`;

export const BodyTitleIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  padding: 8px;
  background-color: ${({ theme }) => theme.nColors['n-yellow']};
`;

export const BodyTitleTextSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const BodyTitleHeader = styled(Typography)`
  font-weight: 500;
`;

export const BodyContentListContainer = styled.div`
  margin-top: 57px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 44px;
  column-gap: 44px;
  overflow-y: auto;
  @media ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SettlementAccountItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ItemTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ItemTitle = styled(Typography)`
  line-height: 30px;
  letter-spacing: -0.4px;
`;

export const SplitAccountInfo = styled.div`
  display: flex;
  gap: 11px;
`;

export const AccountNumber = styled(Typography)`
  font-weight: 500;
  color: ${({ theme }) => theme.nColors['n-grey3a']};
`;
