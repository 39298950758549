import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Settings = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M8.635 5.073c.347-1.43 2.383-1.43 2.73 0a.773.773 0 0 0 1.153.478c1.258-.766 2.697.673 1.93 1.93a.773.773 0 0 0 .479 1.154c1.43.347 1.43 2.383 0 2.73a.773.773 0 0 0-.478 1.153c.766 1.258-.673 2.697-1.93 1.93a.773.773 0 0 0-1.154.479c-.347 1.43-2.383 1.43-2.73 0a.773.773 0 0 0-1.154-.478c-1.257.766-2.696-.673-1.93-1.93a.773.773 0 0 0-.478-1.154c-1.43-.347-1.43-2.383 0-2.73a.773.773 0 0 0 .478-1.154c-.766-1.257.673-2.696 1.93-1.93a.773.773 0 0 0 1.154-.478Zm2.116.15c-.19-.788-1.311-.788-1.502 0a1.405 1.405 0 0 1-2.096.867c-.692-.421-1.484.37-1.063 1.063a1.405 1.405 0 0 1-.868 2.096c-.787.19-.787 1.311 0 1.502a1.405 1.405 0 0 1 .868 2.096c-.421.692.37 1.484 1.063 1.063a1.405 1.405 0 0 1 2.096.868c.19.787 1.311.787 1.502 0a1.405 1.405 0 0 1 2.096-.868c.692.421 1.484-.37 1.063-1.063a1.405 1.405 0 0 1 .868-2.096c.787-.19.787-1.311 0-1.502a1.405 1.405 0 0 1-.868-2.096c.421-.692-.37-1.484-1.063-1.063a1.405 1.405 0 0 1-2.096-.868ZM10 8.42a1.579 1.579 0 1 0 0 3.158 1.579 1.579 0 0 0 0-3.158ZM7.79 10a2.21 2.21 0 1 1 4.42 0 2.21 2.21 0 0 1-4.42 0Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Settings;
