import { useState } from 'react';
import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import DatePicker from 'react-date-picker';
import dayjs from 'dayjs';
import { AppliedFilter, FilterConfig, FilterOnChange, FilterConfigOption, DateRange } from './defs';
import {
  CalendarFilterWrapper,
  FilterItemDropDown,
  FilterItemDropDownItem,
  FilterItemHeaderWrapper,
  FilterItemWrapper,
  FlexGap,
} from './styles';
import useDropdown from '../../hooks/useDropdown';

interface SelectedOption extends FilterConfigOption {
  query?: string;
}

const FilterItem = ({
  configItem,
  onChange,
  handleApplyFilter,
  appliedFilters,
}: {
  configItem: FilterConfig;
  onChange?: FilterOnChange;
  handleApplyFilter: (value: AppliedFilter) => void;
  appliedFilters: AppliedFilter[];
}) => {
  const dateToday = dayjs().toDate();
  const { type, name, options, query } = configItem;
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();

  let selectedOption: SelectedOption | undefined;
  let dateOption: DateRange | undefined;

  const thisAppliedFilter = appliedFilters.find((item) => item.query === query);
  if (thisAppliedFilter) {
    if (type === 'select') selectedOption = options.find((option) => option.value === thisAppliedFilter?.value);
    if (type === 'date') dateOption = thisAppliedFilter.value as unknown as DateRange;
  }

  const [selected, setSelected] = useState<SelectedOption | null>(selectedOption || null);
  const [dateFrom, setDateFrom] = useState<Date>(
    dateOption ? dayjs(dateOption[0]).toDate() : dayjs().subtract(30, 'days').toDate()
  );
  const [dateTo, setDateTo] = useState<Date>(dateOption ? dayjs(dateOption[1]).toDate() : dateToday);

  const handleSelect = (itemSelected: FilterConfigOption) => {
    setSelected({ ...itemSelected, query });
    setShowDropdown((state) => !state);
    handleApplyFilter({ query, value: itemSelected.value });
    if (onChange) onChange({ query, value: itemSelected.value });
  };

  const handleChangeDate = (value: DateRange) => {
    const selectedDateRange = [dayjs(value[0]).toString(), dayjs(value[1]).toString()];
    setDateFrom(value[0]);
    setDateTo(value[1]);
    handleApplyFilter({
      query,
      value: selectedDateRange,
    });
    if (onChange) onChange({ query, value: selectedDateRange });
  };

  return (
    <FilterItemWrapper>
      <FilterItemHeaderWrapper
        ref={dropdownButtonRef}
        onClick={() => setShowDropdown((state) => !state)}
        show={showDropdown}
      >
        {type === 'select' ? (
          <>
            <Typography variant="n-text2" color="dark">
              {name}
            </Typography>
            <Typography variant="n-text2" color="grey3">
              {selected ? selected.text : 'Select'}
            </Typography>
            <NIcons icon={IconsEnum.OUTLINE_CHEVRON_DOWN} />
          </>
        ) : null}

        {type === 'date' ? (
          <>
            <FlexGap>
              <NIcons icon={IconsEnum.OUTLINE_CALENDAR_1} />
              <Typography variant="n-text2" color="dark">
                From
              </Typography>
              <Typography variant="n-text1" color="grey3">
                {dayjs(dateFrom).format('DD/MM/YY')}
              </Typography>
            </FlexGap>
            <FlexGap>
              <Typography variant="n-text2" color="dark">
                To
              </Typography>
              <Typography variant="n-text1" color="grey3">
                {dayjs(dateTo).format('DD/MM/YY')}
              </Typography>
              <NIcons icon={IconsEnum.OUTLINE_CHEVRON_DOWN} />
            </FlexGap>
          </>
        ) : null}
      </FilterItemHeaderWrapper>

      {type === 'select' && showDropdown ? (
        <FilterItemDropDown ref={dropdownRef}>
          {options?.map((item) => (
            <FilterItemDropDownItem
              onClick={() => handleSelect(item)}
              active={selected?.value === item.value}
              key={`filter_item_dropdown_item_${item.value}`}
            >
              {item.text}
            </FilterItemDropDownItem>
          ))}
        </FilterItemDropDown>
      ) : null}

      {type === 'date' && showDropdown ? (
        <CalendarFilterWrapper ref={dropdownRef}>
          <DatePicker
            onChange={(value: DateRange) => handleChangeDate(value)}
            value={[dateFrom, dateTo]}
            format="YYYY-MM-DD"
            isOpen={showDropdown}
            onCalendarOpen={() => setShowDropdown(true)}
            onCalendarClose={() => setShowDropdown(false)}
            selectRange
          />
        </CalendarFilterWrapper>
      ) : null}
    </FilterItemWrapper>
  );
};

export default FilterItem;
