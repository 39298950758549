import { Typography } from 'kudi-component-library';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getResultStatusColor } from './utils';

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 44px;
  background: rgba(238, 238, 238, 0.5);
  border-radius: 5px;
  width: 481px;
  position: relative;

  @media (max-width: 600px) {
    width: 237px;
  }
`;
export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    position: absolute;
  }
`;
export const SearchInputForm = styled.form`
  width: 100%;
`;
export const SearchInput = styled.input`
  color: #121212;
  padding-left: 30px;
  width: 100%;
  margin-right: 10px;
  border: none;
  background: transparent;

  &::placeholder {
    color: #c6c6c6;
  }
`;
export const SearchDropdownWrapper = styled.div`
  background: #ffffff;
  border-radius: 5px;
  width: 481px;
  height: 382px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 60px;
  overflow-y: scroll;

  @media (max-width: 600px) {
    width: 237px;
  }
`;
export const SearchDropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 20px;
`;
export const SearchDropdownHeaderText = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #999999;
  margin-bottom: 10px;
`;
export const SearchDropdownHeaderClickableText = styled(SearchDropdownHeaderText)`
  cursor: pointer;
`;
export const SearchDropdownOptionWrapper = styled.div`
  height: 42px;
  cursor: pointer;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(238, 238, 238, 0.5);
  }
`;
export const SearchDropdownOptionText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  padding: 10px 20px;
`;
export const SearchDropdownOptionCategory = styled.div`
  background: #fff5cc;
  border-radius: 5px;
  padding: 3px 6px;
  font-weight: 500;
  font-size: 10px;
  color: #806600;
`;
export const QuickActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const QuickAction = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  cursor: pointer;
`;
export const QuickActionText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
  margin-left: 10px;
`;
export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;
export const LoaderText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-top: 20px;
`;
export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ResultsEmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const ResultsTextWrapper = styled(Link)`
  /* cursor: pointer; */
  border-radius: 0px 0px 5px 5px;
  padding: 10px 20px;
  text-decoration: none;

  &:hover {
    background: rgba(238, 238, 238, 0.5);
  }
`;
export const ResultsText = styled.span`
  color: #121212;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
export const CloseIconWrapper = styled.span`
  margin-right: 20px;
  cursor: pointer;
`;
export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(238, 238, 238);
  /* padding: 20px; */
`;
export const FilterLabel = styled.label`
  color: #121212;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
export const FilterTextInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 22px;
  padding: 1.4rem;
  margin-top: 14px;
  margin-bottom: 10px;
`;
export const FilterItem = styled.div`
  padding: 10px 20px;
`;
export const FilterActionWrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const FilterAction = styled.div`
  margin-left: 20px;
`;
export const ClearActionWrapper = styled(FilterAction)`
  button {
    background-color: #121212;
    color: #ffffff;
  }
`;
export const ResultCard = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cv11' on;
  ${({ isMobile }) =>
    isMobile &&
    `p {
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }`}
`;

export const ResultCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

export const ResultStatus = styled.p<{ statusText: string }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: ${({ theme, statusText }) => getResultStatusColor(statusText, theme)};
`;

export const ResultDateTime = styled(Typography)`
  line-height: 22px;
`;

export const ResultSummary = styled(Typography)`
  line-height: 18px;
`;
