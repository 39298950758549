import { FormEvent, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { CloseIcon, SearchIcon2, SlidersIcon } from '../../../../assets/svg';
import useDropdown from '../../../../hooks/useDropdown';
import { useToast } from '../../../../hooks/useToast';
import { WebBeError } from '../../../../types/error';
import { ApiResponse } from '../../../../types/redux';
import axios from '../../../../utils/merchantAcqServiceHttp';
import { RecentSearches, SearchFilterProps, SearchResultsProps } from './components/defs';
import GlobalSearchFilter from './components/Filter';
import GlobalSearchLoader from './components/Loader';
import GlobalSearchRecent from './components/Recent';
import GlobalSearchResults from './components/Results';
import {
  SearchWrapper,
  SearchInput,
  SearchInputWrapper,
  SearchDropdownWrapper,
  SearchInputForm,
  CloseIconWrapper,
} from './styles';
import { formatRequestDate } from './utils';

const getRecentSearches = (): RecentSearches => {
  const recentSearchStr = localStorage.getItem('recentSearches');
  return JSON.parse(recentSearchStr || '[]') as RecentSearches;
};
const updateRecentSearches = (term: string): void => {
  const currentTerms = localStorage.getItem('recentSearches') || '[]';
  const searchArr = JSON.parse(currentTerms) as RecentSearches;
  searchArr.push(term);
  localStorage.setItem('recentSearches', JSON.stringify(searchArr));
};

const clearRecentSearches = (): void => {
  localStorage.recentSearches = '[]';
};

const defaultFilter = {
  dateFrom: formatRequestDate(),
  dateTo: formatRequestDate(),
};

interface SearchResponse extends ApiResponse {
  data: Array<SearchResultsProps>;
}

const GlobalSearch = () => {
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();

  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchResultsProps[] | null>(null);
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  const [filter, setFilter] = useState<SearchFilterProps>(defaultFilter);
  const { parentBusinessId } = useAppSelector((state) => state.auth);

  const recentSearches = getRecentSearches() || [];

  const toast = useToast(3000);

  const fetchSearchResults = async ({
    search,
    dateFrom = '',
    dateTo = '',
  }: {
    search: string;
    dateFrom?: string;
    dateTo?: string;
  }) => {
    const url =
      dateFrom && dateTo
        ? `/webbe/v1/transactions/search?dateFrom=${dateFrom}&dateTo=${dateTo}`
        : '/webbe/v1/transactions/search';
    try {
      setIsLoading(true);
      const result: SearchResponse = await axios.post(url, {
        businessId: parentBusinessId,
        value: search || searchText,
      });
      setIsLoading(false);
      setSearchResults(result.data);
    } catch (err) {
      setIsLoading(false);
      toast('error', (err as WebBeError)?.data?.message || 'Error getting results');
    }
  };

  const handleSelectRecentSearch = (searchTerm: string) => {
    setSearchText(searchTerm);
    fetchSearchResults({ search: searchTerm });
    updateRecentSearches(searchTerm);
  };

  const handleSubmitSearch = (e: FormEvent) => {
    e.preventDefault();
    fetchSearchResults({ search: searchText });
    updateRecentSearches(searchText);
  };

  const handleClearSearchTerm = () => {
    setSearchText('');
    setSearchResults(null);
  };

  const handleSubmitFilterSearch = () => {
    const { dateFrom, dateTo } = filter;
    setIsFilterActive(false);
    fetchSearchResults({
      search: searchText,
      dateFrom,
      dateTo,
    });
  };

  const handleClearFilterSearch = () => {
    handleClearSearchTerm();
    setFilter(defaultFilter);
  };

  return (
    <SearchWrapper ref={dropdownButtonRef}>
      <SearchInputWrapper>
        <SearchIcon2 color="#8c8c8c" />
        <SearchInputForm onSubmit={handleSubmitSearch}>
          <SearchInput
            type="text"
            name="search_text"
            placeholder="Search in your dashboard"
            onFocus={() => setShowDropdown((state) => state || !state)}
            onChange={(e) => setSearchText(e.target.value)}
            autoComplete="off"
            value={searchText}
            id="global_search_main_input"
          />
        </SearchInputForm>
      </SearchInputWrapper>

      <CloseIconWrapper onClick={handleClearSearchTerm} id="global_search_clear_input">
        <CloseIcon width="12px" height="12px" color="#121212" stroke="1" />
      </CloseIconWrapper>
      <SlidersIcon
        color="#8c8c8c"
        onClick={() => {
          setIsFilterActive((state) => !state);
          setSearchResults(null);
          setShowDropdown((state) => state || !state);
        }}
        cursor="pointer"
        id="global_search_filter_trigger"
      />
      {showDropdown && (
        <SearchDropdownWrapper ref={dropdownRef}>
          {isFilterActive && (
            <GlobalSearchFilter
              searchText={searchText}
              setSearchText={setSearchText}
              handleSubmitFilterSearch={handleSubmitFilterSearch}
              handleClearFilterSearch={handleClearFilterSearch}
              setFilter={setFilter}
              filter={filter}
            />
          )}
          {isLoading && <GlobalSearchLoader />}
          {!isLoading && searchResults && <GlobalSearchResults results={searchResults} />}
          {!isLoading && !searchResults && !isFilterActive && (
            <GlobalSearchRecent
              clearRecentSearches={clearRecentSearches}
              setShowDropdown={setShowDropdown}
              recentSearches={recentSearches}
              handleSelectRecentSearch={handleSelectRecentSearch}
            />
          )}
        </SearchDropdownWrapper>
      )}
    </SearchWrapper>
  );
};

export default GlobalSearch;
