/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import styled from 'styled-components';
import { Badge, Button, IconsEnum, NIcons, Radio, Typography } from 'kudi-component-library';
import { ChevronRight, CloseModal, CopyLink, Spinner } from '../../../assets/svg';
import {
  TransactionInfoWrapper,
  TransactionInfoBacground,
  TransactionInfoContentWrapper,
  TransactionInfoClose,
} from '../../../components/TransactionInfo/styles';
import { formatCurrency, formatDateWithTime } from '../../../utils';
import { NoDataState } from '../../../components/newTable/state';
import {
  IssueTypeModalContainer,
  IssueTypeModalContent,
  IssueTypeModalContentClose,
  IssueTypeModalContentTypesWrapper,
} from '../../banking/transactions/styles';

import useIssueFromTrasaction from '../../support/models/issueFromTrasaction';
import LoadingState from '../../banking/transactions/transaction-details/loading-state';
import { IssueBox, TransactionFooter } from '../../banking/transactions/transaction-details/styles';
import { TransferRequestData } from './utils';
import {
  ActionButtonsWrapper,
  ArrowSummary,
  ContentFlex,
  ContentFlexWrapper,
  NIconWrapper,
  NIconWrapperv2,
  SummaryCard,
} from './styles';
import { FullFlex } from '../../../styles/generic';
import { useToast } from '../../../hooks/useToast';

const Wrapper = styled(TransactionInfoWrapper)`
  align-items: flex-start;
  flex-direction: column;
`;

const ContentWrapper = styled(TransactionInfoContentWrapper)`
  max-width: 540px;
  padding: 2rem 0 !important;
  align-items: start;
  height: 100%;
`;
const ContentWrapperTop = styled(ContentWrapper)`
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-grey1']};
  border-radius: 5px 5px 0 0;
  height: auto;
  padding: 2rem !important;
`;
const ContentWrapperBottom = styled(ContentWrapper)`
  border-radius: 0 0 5px 5px;
`;
const ContentWrapperLoader = styled(ContentWrapper)`
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;
const Header = styled(Typography)`
  top: 2rem;
  left: 2rem;
`;

const TransferRequestDetailsModal = ({
  showModal,
  onClose,
  handleUpdateModal,
  transferRequestDetails,
}: {
  showModal: boolean;
  onClose: () => void;
  handleUpdateModal: (val: string) => void;
  transferRequestDetails: TransferRequestData | null;
}) => {
  const [isLoading] = useState<boolean>(false);
  const toast = useToast(3000);

  const handleCopy = (text: string | undefined) => {
    if (!text) return;
    navigator.clipboard.writeText(text);
    toast('success', 'Copied to clipboard!');
  };

  const {
    handleTransactionIssueButtonCLick,
    handleIssueTypeSelection,
    issues,
    isFetching,
    selectedType,
    onContinue,
    showIssuetypesModal,
    closeModal,
  } = useIssueFromTrasaction({
    transactionInfo: null,
  });

  const statusOptions = {
    APPROVED: 'Request Approved',
    PENDING_APPROVAL: 'Pending Approval',
    REJECTED: 'Request Rejected',
    EXPIRED: 'Request Expired',
  };

  // prettier-ignore
  const badgeColor =
    transferRequestDetails?.paymentRequestStatus === 'APPROVED'
      ? 'n-green1'
      : transferRequestDetails?.paymentRequestStatus === 'PENDING_APPROVAL'
      ? 'n-yellow1'
      : 'n-red1';

  // prettier-ignore
  const badgeTextColor =
    transferRequestDetails?.paymentRequestStatus === 'APPROVED'
      ? 'n-green7'
      : transferRequestDetails?.paymentRequestStatus === 'PENDING_APPROVAL'
      ? 'n-yellow8'
      : 'n-red7';

  const iamTokenRole = JSON.parse(localStorage.getItem('IAMuserRole') as '{}') as string;

  return showModal ? (
    <Wrapper>
      <TransactionInfoBacground onClick={onClose} />
      <ContentWrapperTop>
        <TransactionInfoClose onClick={onClose}>
          <CloseModal />
        </TransactionInfoClose>
        <Header variant="n-text4" color="dark">
          Transaction details
        </Header>
      </ContentWrapperTop>

      {isLoading ? (
        <ContentWrapperLoader>
          <LoadingState />
        </ContentWrapperLoader>
      ) : null}

      {transferRequestDetails ? (
        <ContentWrapperBottom>
          <div style={{ overflow: 'scroll', width: '100%', paddingBottom: '80px' }}>
            <SummaryCard>
              <NIconWrapper>
                {transferRequestDetails?.paymentRequestStatus === 'APPROVED' ? (
                  <NIcons icon={IconsEnum.OUTLINE_UP} />
                ) : (
                  <NIcons icon={IconsEnum.OUTLINE_CLOCK} />
                )}
              </NIconWrapper>
              <Typography color="n-grey4" fontWeight={600} fontSize="12px">
                {transferRequestDetails?.paymentRequestStatus === 'APPROVED' ? 'Money Out' : 'Transfer Request'}
              </Typography>
              <Typography fontWeight={700} fontSize="20px">
                {formatCurrency(Number(transferRequestDetails?.amount))}
              </Typography>
              <Typography color="n-grey4" fontSize="12px">
                Initiated on{' '}
                <span style={{ fontWeight: 700 }}>
                  {formatDateWithTime(new Date(transferRequestDetails?.createdAt))}
                </span>
              </Typography>
            </SummaryCard>
            <ContentFlexWrapper style={{ gap: '3px' }}>
              <ArrowSummary>
                <FullFlex flexDirection="column" justifyContent="flex-start" alignItems="flex-start" gap="8px">
                  <Typography color="n-grey4" fontSize="12px">
                    Transfer Initiated by
                  </Typography>
                  <Typography style={{ textAlign: 'left' }} color="n-dark" fontWeight={600} fontSize="14px">
                    {transferRequestDetails?.submittedBy?.firstName} ({transferRequestDetails?.submittedBy?.role})
                  </Typography>
                </FullFlex>
                <NIconWrapperv2 status="APPROVED">
                  <NIcons icon={IconsEnum.OUTLINE_CHECK} color="n-light" />
                </NIconWrapperv2>
              </ArrowSummary>
              <ArrowSummary>
                <FullFlex flexDirection="column" justifyContent="flex-start" alignItems="flex-start" gap="8px">
                  <Typography color="n-grey4" fontSize="12px">
                    Waiting for approval
                  </Typography>
                  <Typography color="n-dark" fontWeight={600} fontSize="14px">
                    Owner/Admin
                  </Typography>
                </FullFlex>
                <NIconWrapperv2 status={transferRequestDetails?.paymentRequestStatus}>
                  {transferRequestDetails?.paymentRequestStatus === 'APPROVED' ? (
                    <NIcons icon={IconsEnum.OUTLINE_CHECK} color="n-light" />
                  ) : transferRequestDetails?.paymentRequestStatus === 'PENDING_APPROVAL' ? (
                    <NIcons icon={IconsEnum.OUTLINE_CLOCK} color="n-light" />
                  ) : (
                    <NIcons icon={IconsEnum.OUTLINE_X} color="n-light" />
                  )}
                </NIconWrapperv2>
              </ArrowSummary>
            </ContentFlexWrapper>

            <ContentFlexWrapper>
              <ContentFlex>
                <Typography color="n-grey4" fontSize="12px">
                  To be sent to
                </Typography>
                <Typography color="n-grey6" fontSize="14px">
                  {transferRequestDetails?.beneficiaryName}
                </Typography>
              </ContentFlex>
              <ContentFlex>
                <Typography color="n-grey4" fontSize="12px">
                  Status
                </Typography>
                <Typography color="n-grey4" fontSize="14px">
                  <Badge variant="success" backgroundColor={badgeColor} textColor={badgeTextColor}>
                    {statusOptions[transferRequestDetails?.paymentRequestStatus as keyof typeof statusOptions]}{' '}
                  </Badge>
                </Typography>
              </ContentFlex>
            </ContentFlexWrapper>

            <ContentFlexWrapper>
              <ContentFlex>
                <Typography color="n-grey4" fontSize="12px">
                  Bank name
                </Typography>
                <Typography color="n-grey6" fontSize="14px">
                  {transferRequestDetails?.bankName}
                </Typography>
              </ContentFlex>
              <ContentFlex>
                <Typography color="n-grey4" fontSize="12px">
                  Account number
                </Typography>
                <FullFlex width="100%" justifyContent="space-between" alignItems="center">
                  <Typography color="n-grey6" fontSize="14px">
                    {transferRequestDetails?.account}
                  </Typography>
                  <CopyLink onClick={() => handleCopy(transferRequestDetails?.account)} style={{ cursor: 'pointer' }} />
                </FullFlex>
              </ContentFlex>
            </ContentFlexWrapper>

            <ContentFlexWrapper>
              <ContentFlex>
                <Typography color="n-grey4" fontSize="12px">
                  Description
                </Typography>
                <Typography color="n-grey6" fontSize="14px">
                  {transferRequestDetails?.narration || 'No description'}
                </Typography>
              </ContentFlex>
            </ContentFlexWrapper>

            <ContentFlexWrapper>
              <ContentFlex>
                <Typography color="n-grey4" fontSize="12px">
                  Transaction type
                </Typography>
                <Typography color="n-grey6" fontSize="14px">
                  Outflow
                </Typography>
              </ContentFlex>
              <ContentFlex>
                <Typography color="n-grey4" fontSize="12px">
                  Initiator
                </Typography>
                <Typography color="n-grey4" fontSize="14px">
                  {transferRequestDetails?.submittedBy?.firstName} {transferRequestDetails?.submittedBy?.lastName}
                </Typography>
              </ContentFlex>
            </ContentFlexWrapper>
            {transferRequestDetails?.transactionId && (
              <ContentFlexWrapper>
                <ContentFlex>
                  <Typography color="n-grey4" fontSize="12px">
                    Transaction ID
                  </Typography>
                  <FullFlex width="100%" justifyContent="space-between" alignItems="center">
                    <Typography style={{ textAlign: 'left' }} color="n-grey6" fontSize="14px">
                      {transferRequestDetails?.transactionId}
                    </Typography>
                    <CopyLink
                      onClick={() => handleCopy(transferRequestDetails?.transactionId)}
                      style={{ cursor: 'pointer' }}
                    />
                  </FullFlex>
                </ContentFlex>
              </ContentFlexWrapper>
            )}
            {transferRequestDetails?.sessionId && (
              <ContentFlexWrapper>
                <ContentFlex>
                  <Typography color="n-grey4" fontSize="12px">
                    Session ID
                  </Typography>
                  <FullFlex width="100%" justifyContent="space-between" alignItems="center">
                    <Typography style={{ textAlign: 'left' }} color="n-grey6" fontSize="14px">
                      {transferRequestDetails?.sessionId}
                    </Typography>
                    <CopyLink
                      onClick={() => handleCopy(transferRequestDetails?.sessionId)}
                      style={{ cursor: 'pointer' }}
                    />
                  </FullFlex>
                </ContentFlex>
              </ContentFlexWrapper>
            )}

            {transferRequestDetails?.approvedDate && transferRequestDetails?.approvedBy && (
              <ContentFlexWrapper>
                <ContentFlex>
                  <Typography color="n-grey4" fontSize="12px">
                    Date Approved
                  </Typography>
                  <Typography color="n-grey6" fontSize="14px" fontWeight={700}>
                    {transferRequestDetails?.approvedDate
                      ? formatDateWithTime(new Date(transferRequestDetails?.approvedDate))
                      : ''}
                  </Typography>
                </ContentFlex>
                <ContentFlex>
                  <Typography color="n-grey4" fontSize="12px">
                    Approved by
                  </Typography>
                  <Typography color="n-grey4" fontSize="14px" fontWeight={700}>
                    {transferRequestDetails?.approvedBy ? `${transferRequestDetails?.approvedBy}` : 'Not approved yet'}
                  </Typography>
                </ContentFlex>
              </ContentFlexWrapper>
            )}
          </div>

          <TransactionFooter>
            {transferRequestDetails?.paymentRequestStatus === 'PENDING_APPROVAL' &&
              (iamTokenRole === 'OWNER' || iamTokenRole === 'ADMIN') && (
                <ActionButtonsWrapper>
                  <Button onClick={() => handleUpdateModal('approve')} variant="medium" width="100%">
                    Approve this transfer
                  </Button>
                  <Button
                    onClick={() => handleUpdateModal('reject')}
                    variant="medium"
                    width="100%"
                    style={{ border: '2px solid red', background: '#FFF' }}
                  >
                    Reject transfer
                  </Button>
                </ActionButtonsWrapper>
              )}

            <IssueBox onClick={handleTransactionIssueButtonCLick}>
              <NIcons icon={IconsEnum.OUTLINE_HELP} />
              <div className="content">
                <Typography className="header-text">Report Transaction</Typography>
                <Typography>Report an issue with this payment</Typography>
              </div>
              {isFetching ? <Spinner /> : <ChevronRight />}
            </IssueBox>
          </TransactionFooter>
          {showIssuetypesModal ? (
            <IssueTypeModalContainer onClick={closeModal}>
              <IssueTypeModalContent onClick={(e) => e.stopPropagation()}>
                <IssueTypeModalContentClose onClick={closeModal}>
                  <NIcons icon={IconsEnum.OUTLINE_X} />
                </IssueTypeModalContentClose>
                <Typography fontSize="20px" lineHeight="30px" fontWeight={400}>
                  Select what’s wrong
                </Typography>
                <IssueTypeModalContentTypesWrapper>
                  {issues.map((item) => (
                    <Radio
                      onChange={() => handleIssueTypeSelection(item)}
                      defaultChecked={selectedType?.id === item.id}
                      name={item.description}
                      value={item.id}
                    >
                      {item.description}
                    </Radio>
                  ))}
                </IssueTypeModalContentTypesWrapper>
                <Button disabled={!selectedType} variant="medium" width="100%" onClick={onContinue}>
                  Continue
                </Button>
              </IssueTypeModalContent>
            </IssueTypeModalContainer>
          ) : null}
        </ContentWrapperBottom>
      ) : null}

      {!transferRequestDetails && !isLoading ? (
        <ContentWrapperLoader>
          <NoDataState />
        </ContentWrapperLoader>
      ) : null}
    </Wrapper>
  ) : null;
};

export default TransferRequestDetailsModal;
