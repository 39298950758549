import { IconsEnum, NIcons } from 'kudi-component-library';
import useDropdown from '../../hooks/useDropdown';
import { ButtonDropDownProps } from './defs';
import {
  ButtonWrapper,
  InnerBtnText,
  OptionItem,
  OptionItemsWrapper,
  PrimaryButton,
  SecondaryButton,
  Wrapper,
} from './styles';

const ButtonDropdown = ({
  type,
  buttonText,
  options,
  icon,
  variant = 'xsmall',
  showDropDownIcon,
}: ButtonDropDownProps) => {
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();

  return (
    <Wrapper ref={dropdownButtonRef}>
      <ButtonWrapper>
        {type === 'primary' ? (
          <PrimaryButton
            variant={variant}
            Icon={icon ? <NIcons icon={icon} /> : undefined}
            onClick={() => setShowDropdown((state) => !state)}
          >
            <InnerBtnText>
              {buttonText}
              {showDropDownIcon ? <NIcons height="16" width="16" icon={IconsEnum.OUTLINE_CHEVRON_DOWN} /> : undefined}
            </InnerBtnText>
          </PrimaryButton>
        ) : (
          <SecondaryButton
            variant={variant}
            Icon={icon ? <NIcons icon={icon} color="n-light" /> : undefined}
            onClick={() => setShowDropdown((state) => !state)}
          >
            <InnerBtnText>
              {buttonText}
              {showDropDownIcon ? <NIcons height="16" width="16" icon={IconsEnum.OUTLINE_CHEVRON_DOWN} /> : undefined}
            </InnerBtnText>
          </SecondaryButton>
        )}
      </ButtonWrapper>
      {showDropdown ? (
        <OptionItemsWrapper ref={dropdownRef}>
          {options.map(({ text, onClick }) => (
            <OptionItem
              variant="n-text2"
              color="dark"
              onClick={() => {
                setShowDropdown((state) => !state);
                onClick();
              }}
            >
              {text}
            </OptionItem>
          ))}
        </OptionItemsWrapper>
      ) : null}
    </Wrapper>
  );
};

export default ButtonDropdown;
