import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const PaymentLinks = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M18.169 5.831a3.114 3.114 0 0 0-4.404 0l-.982.982a.46.46 0 0 1-.65-.65l.982-.982a4.033 4.033 0 0 1 5.704 5.704l-3.574 3.573a4.033 4.033 0 0 1-5.703 0 .46.46 0 0 1 .65-.65 3.114 3.114 0 0 0 4.403 0l3.574-3.573a3.114 3.114 0 0 0 0-4.404Zm-5.36 5.36a3.114 3.114 0 0 0-4.404 0L4.83 14.765a3.114 3.114 0 1 0 4.404 4.404l.984-.984a.46.46 0 1 1 .65.65l-.984.984a4.033 4.033 0 0 1-5.704-5.704l3.574-3.573a4.033 4.033 0 0 1 5.703 0 .46.46 0 0 1-.65.65Z"
      clipRule="evenodd"
    />
  </svg>
);

export default PaymentLinks;
