/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  GetAllCategories,
  GetAllTypes,
  GetAllIssues,
  GetAnIssue,
  GetContactDetails,
  CreateAnIssue,
  FetchTransactions,
} from './thunk_actions';
import { Contact, CreationStage, FetchedIssue, IssueCategory, IssueType, SupportPages } from '../defs';
import { extractContactInfo, groupIssueCategoriesAndSub } from '../functions';
import { NewTransactions } from '../../../types/transactions';

interface InitialState {
  currentPage: SupportPages;
  show: boolean;

  issueCreation: {
    categories: IssueCategory[];
    subCategories: Record<number, IssueCategory[]>;
    currentCreationStage: CreationStage;
    selectedType: IssueType | null;
    selectedCategory: IssueCategory | null;
    selectedSubCategory: IssueCategory | null;
    showTransactionFilter: boolean;
    selectedCategoryTypeList: IssueType[];
    transactions: NewTransactions[];
    selectedTransaction: NewTransactions | null;
    issueDocumentList: Record<string, string>;
    issueDecription: string;
    filters: {
      date?: string[];
      source?: string;
      status?: string;
    };
    pagination: {
      limit: number;
      nextCursor: string | null;
      lastPage: boolean;
    };
    isLoading: boolean;
    isFetching: boolean;
  };

  issueList: {
    issues: FetchedIssue[];
    selectedIssue: FetchedIssue | null;
    filters: {
      date?: string[];
      status?: string;
      type?: string;
    };
    showFilter: boolean;
    pagination: {
      limit: number;
      page: number;
      lastPage: boolean;
    };
    isLoading: boolean;
    isFetching: boolean;

    closeAll: boolean;
  };

  contacts: {
    info: {
      social: {
        instagram: string;
        facebook: string;
        twitter: string;
      };
      addresses: Contact[];
      phones: Contact[];
      email: string;
    };
    isLoading: boolean;
  };

  issueCreatedId: string | null;
}

const filterInitial = {
  date: [moment().subtract(1, 'month').add(1, 'day').toString(), moment().toString()],
};

const initialState: InitialState = {
  currentPage: 'home',
  show: false,
  issueCreation: {
    categories: [],
    subCategories: {},
    currentCreationStage: 'category',
    selectedType: null,
    selectedCategory: null,
    selectedSubCategory: null,
    showTransactionFilter: false,
    selectedCategoryTypeList: [],
    transactions: [],
    selectedTransaction: null,
    issueDocumentList: {},
    filters: { ...filterInitial },
    issueDecription: '',
    pagination: {
      limit: 20,
      nextCursor: null,
      lastPage: false,
    },
    isLoading: false,
    isFetching: false,
  },

  issueList: {
    issues: [],
    selectedIssue: null,
    filters: {
      ...filterInitial,
    },
    showFilter: false,
    pagination: {
      limit: 20,
      page: 0,
      lastPage: false,
    },
    isLoading: false,
    isFetching: false,

    closeAll: false,
  },

  contacts: {
    info: {
      social: {
        instagram: '',
        facebook: '',
        twitter: '',
      },
      addresses: [],
      phones: [],
      email: '',
    },
    isLoading: false,
  },

  issueCreatedId: null,
};

export const supportSlice = createSlice({
  name: 'support',
  initialState,

  // ============================ REDUCERS ========================//

  reducers: {
    // -----------------------  GENERAL ACTIONS START --------------------------//

    toggleShow: (state) => {
      if (state.show) {
        state.issueList.closeAll = false;
      }
      state.show = !state.show;
    },

    setIssueDetailsCloseAll: (state, action: PayloadAction<boolean>) => {
      state.issueList.closeAll = action.payload;
    },

    // -----------------------  GENERAL ACTIONS END --------------------------//

    // -----------------------  ISSUE CREATION ACTIONS START --------------------------//

    updateCurrentPage: (state, action: PayloadAction<SupportPages>) => {
      state.currentPage = action.payload;
    },
    updateIssueCreationStage: (state, action: PayloadAction<CreationStage>) => {
      state.issueCreation.currentCreationStage = action.payload;
    },
    selectIssueCategory: (state, action: PayloadAction<IssueCategory | null>) => {
      state.issueCreation.selectedCategory = action.payload;
    },
    selectIssueSubCategory: (state, action: PayloadAction<IssueCategory | null>) => {
      state.issueCreation.selectedSubCategory = action.payload;
    },
    selectIssueType: (state, action: PayloadAction<IssueType | null>) => {
      state.issueCreation.selectedType = action.payload;
    },
    selectTransaction: (state, action: PayloadAction<NewTransactions | null>) => {
      state.issueCreation.selectedTransaction = action.payload;
    },
    updateDocumentList: (state, action: PayloadAction<{ key: string; value: string }>) => {
      const { key, value } = action.payload;
      state.issueCreation.issueDocumentList = { ...state.issueCreation.issueDocumentList, [key]: value };
    },
    updateIssueDescription: (state, action: PayloadAction<string>) => {
      state.issueCreation.issueDecription = action.payload;
    },
    updateShowTransactionFilter: (state, action: PayloadAction<boolean>) => {
      state.issueCreation.showTransactionFilter = action.payload;
    },
    updateTransactionFilters: (state, action: PayloadAction<Partial<Record<string, string | string[]>>>) => {
      state.issueCreation.filters = {
        date: [moment().subtract(1, 'month').add(1, 'day').toString(), moment().toString()],
        ...action.payload,
      };
    },

    clearTransactionFilters: (state) => {
      state.issueCreation.filters = { ...filterInitial };
    },
    clearTransactionPagination: (state) => {
      state.issueCreation.transactions = [];
      state.issueCreation.pagination = {
        nextCursor: null,
        lastPage: false,
        limit: 50,
      };
    },

    clearTypeList: (state) => {
      state.issueCreation.selectedCategoryTypeList = [];
    },

    clearTransactions: (state) => {
      state.issueCreation.transactions = [];
      state.issueCreation.pagination.lastPage = false;
      state.issueCreation.pagination.nextCursor = null;
    },

    removeCreatedId: (state) => {
      state.issueCreatedId = null;
    },

    // -----------------------  ISSUE CREATION ACTIONS END --------------------------//

    // -----------------------  ISSUE LIST ACTIONS START --------------------------//

    selectIssueItem: (state, action: PayloadAction<FetchedIssue | null>) => {
      state.issueList.selectedIssue = action.payload;
    },

    updateIssueListFilter: (state, action: PayloadAction<Partial<Record<string, string | string[]>>>) => {
      const old = current(state.issueList.filters);
      state.issueList.filters = { ...old, ...action.payload };
      state.issueList.pagination.lastPage = false;
      state.issueList.pagination.page = 0;
    },

    clearIssueListFilter: (state) => {
      state.issueList.filters = {
        ...filterInitial,
      };
      state.issueList.issues = [];
      state.issueList.pagination = {
        limit: 20,
        page: 0,
        lastPage: false,
      };
    },

    clearIssueListPagination: (state) => {
      state.issueList.pagination = {
        ...state.issueList.pagination,
        page: 0,
        lastPage: false,
      };
    },

    updateShowIssueListFilter: (state, action: PayloadAction<boolean>) => {
      state.issueList.showFilter = action.payload;
    },

    // -----------------------  ISSUE LIST ACTIONS END --------------------------//
  },
  // ============================ EXTRA REDUCERS ========================//

  extraReducers(builder) {
    // -----------------------  GENERAL THUNKS START --------------------------//

    // Get Contact details
    builder
      .addCase(GetContactDetails.pending, (state) => {
        state.contacts.isLoading = true;
      })
      .addCase(GetContactDetails.rejected, (state) => {
        state.contacts.isLoading = false;
      })
      .addCase(GetContactDetails.fulfilled, (state, action) => {
        const data = extractContactInfo(action.payload.data);
        state.contacts.info = data;
        state.contacts.isLoading = false;
      });

    // -----------------------  GENERAL THUNKS END --------------------------//

    // -----------------------  ISSUE LIST THUNKS START --------------------------//

    // Get all issues
    builder
      .addCase(GetAllIssues.pending, (state) => {
        state.issueList.issues = [];
        state.issueList.isLoading = true;
      })
      .addCase(GetAllIssues.rejected, (state) => {
        state.issueList.isLoading = false;
      })
      .addCase(GetAllIssues.fulfilled, (state, action) => {
        state.issueList.isLoading = false;
        state.issueList.issues = action.payload?.data || [];
        if (action.payload?.data && action.payload?.data?.length < state.issueList.pagination.limit) {
          state.issueList.pagination.lastPage = true;
        } else if (!action.payload?.data) {
          state.issueList.pagination.lastPage = true;
        } else {
          state.issueList.pagination.lastPage = false;
        }
      });

    // Get an Issue
    builder
      .addCase(GetAnIssue.pending, (state) => {
        state.issueList.isLoading = true;
      })
      .addCase(GetAnIssue.rejected, (state) => {
        state.issueList.isLoading = false;
      })
      .addCase(GetAnIssue.fulfilled, (state, action) => {
        state.issueList.isLoading = false;
        state.issueList.selectedIssue = action.payload.ticket;
      });

    // -----------------------  ISSUE LIST THUNKS END --------------------------//

    // -----------------------  ISSUE CREATION THUNKS START --------------------------//

    // Get all Categories
    builder
      .addCase(GetAllCategories.pending, (state) => {
        state.issueCreation.isFetching = true;
      })
      .addCase(GetAllCategories.rejected, (state) => {
        state.issueCreation.isFetching = false;
      })
      .addCase(GetAllCategories.fulfilled, (state, action) => {
        const { main, sub } = groupIssueCategoriesAndSub(action.payload.data);
        state.issueCreation.categories = main;
        state.issueCreation.subCategories = sub;
        state.issueCreation.isFetching = false;
      });

    // Get all Types
    builder
      .addCase(GetAllTypes.pending, (state) => {
        state.issueCreation.selectedCategoryTypeList = [];
        state.issueCreation.isFetching = true;
      })
      .addCase(GetAllTypes.rejected, (state) => {
        state.issueCreation.isFetching = false;
      })
      .addCase(GetAllTypes.fulfilled, (state, action) => {
        state.issueCreation.selectedCategoryTypeList = action.payload.data;
        state.issueCreation.isFetching = false;
      });

    // Fetch transactions
    builder
      .addCase(FetchTransactions.pending, (state) => {
        state.issueCreation.isLoading = true;
      })
      .addCase(FetchTransactions.rejected, (state) => {
        state.issueCreation.isLoading = false;
      })
      .addCase(FetchTransactions.fulfilled, (state, action) => {
        if (!state.issueCreation.pagination.nextCursor) {
          state.issueCreation.transactions = action.payload.data;
        } else {
          state.issueCreation.transactions = [...state.issueCreation.transactions, ...action.payload.data];
        }
        state.issueCreation.pagination.nextCursor = action.payload.next;

        if (!action.payload.next) {
          state.issueCreation.pagination.lastPage = true;
        }
        state.issueCreation.isLoading = false;
      });

    // Create an Issue
    builder
      .addCase(CreateAnIssue.pending, (state) => {
        state.issueCreation.isLoading = true;
      })
      .addCase(CreateAnIssue.rejected, (state) => {
        state.issueCreation.isLoading = false;
      })
      .addCase(CreateAnIssue.fulfilled, (state, payload) => {
        state.issueCreation.isLoading = false;
        state.issueCreatedId = payload.payload?.data?.id;
        state.issueCreation.filters = { ...filterInitial };
        state.issueCreation.transactions = [];
        state.issueCreation.pagination = {
          nextCursor: null,
          lastPage: false,
          limit: 50,
        };
        state.issueCreation.issueDocumentList = {};
      });

    // -----------------------  ISSUE CREATION THUNkS END --------------------------//
  },
});

export const {
  updateCurrentPage,
  updateIssueCreationStage,
  selectIssueCategory,
  selectIssueSubCategory,
  selectIssueType,
  selectTransaction,
  updateShowTransactionFilter,
  updateTransactionFilters,
  toggleShow,
  clearTypeList,
  clearTransactions,
  clearTransactionFilters,
  clearTransactionPagination,
  updateDocumentList,
  updateIssueDescription,
  removeCreatedId,
  selectIssueItem,
  updateIssueListFilter,
  clearIssueListFilter,
  clearIssueListPagination,
  updateShowIssueListFilter,
  setIssueDetailsCloseAll,
} = supportSlice.actions;
export default supportSlice.reducer;
