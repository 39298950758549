import { LogoSm } from '../../../../../assets/svg';
import { LoaderText, LoaderWrapper } from '../styles';

const GlobalSearchLoader = () => (
  <LoaderWrapper>
    <LogoSm />
    <LoaderText>Fetching results...</LoaderText>
  </LoaderWrapper>
);

export default GlobalSearchLoader;
