import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Outlets = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M12.805 3.102a.412.412 0 0 0-.542 0L8.208 6.649a.412.412 0 0 0-.14.31v9.63c0 .227.184.411.411.411h8.11A.412.412 0 0 0 17 16.59v-9.63a.412.412 0 0 0-.14-.31l-4.055-3.547Zm3.371 4.044v9.03H8.891v-9.03l3.643-3.187 3.642 3.187Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : theme.nColors['n-grey3']}
      fillRule="evenodd"
      d="M8.892 9.493a.412.412 0 0 0-.412-.411H3.412A.412.412 0 0 0 3 9.493v7.096c0 .227.184.411.412.411H8.48a.412.412 0 1 0 0-.823H3.824V9.905H8.48a.412.412 0 0 0 .412-.412ZM12.534 14.15a.412.412 0 0 0-.412.412v2.027a.412.412 0 0 0 .824 0v-2.027a.412.412 0 0 0-.412-.412ZM14.466 11.52a.412.412 0 0 0-.412-.411h-3.04a.412.412 0 1 0 0 .823h3.04a.412.412 0 0 0 .412-.411ZM14.466 8.48a.412.412 0 0 0-.412-.412h-3.04a.412.412 0 0 0 0 .824h3.04a.412.412 0 0 0 .412-.412Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Outlets;
