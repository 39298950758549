import styled from 'styled-components';
import NombaGif from '../../../../assets/images/Nomba.gif';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Loader = styled.img`
  width: 50%;
`;

const LoadingState = () => (
  <LoaderWrapper>
    <Loader src={NombaGif} alt="Loading..." />
  </LoaderWrapper>
);

export default LoadingState;
