import styled from 'styled-components';
import { convertHexToRGBA } from '../../utils';
import { Flex } from 'rebass';

// styling for header top
export const TopRightTextSm = styled.p`
  font-size: 1.4rem;
  font-style: normal;
  line-height: 2.2rem;
  letter-spacing: 0px;
  text-align: right;
  color: ${({ theme }) => theme.nColors['n-grey4']};
  cursor: pointer;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue1};
`;

export const TopRightText = styled.p`
  font-size: 1.4rem;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0px;
  text-align: right;
  color: ${({ theme }) => theme.nColors['n-grey4']};
  cursor: pointer;

  span {
    font-weight: 400;
    color: ${({ theme }) => theme.nColors['n-dark']};
    line-height: 120%;
    font-size: 1.4rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue1};
  }
`;

export const LoginContentTextHolder = styled.div<{ mb?: string; mbs?: string }>`
  margin-top: 50px;
  margin-bottom: ${({ mbs }) => mbs || '3.4rem'};

  @media ${({ theme }) => theme.media.lg} {
    margin-bottom: ${({ mb }) => mb || '5.4rem'};
    margin-top: 0;
  }
`;

export const BackButtonWrapper = styled.div`
  margin-bottom: 0.8rem;
`;

export const Title = styled.h2<{ align?: string; alignSm?: string }>`
  font-size: 2.4rem;
  line-height: 120%;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: ${({ alignSm }) => alignSm || 'left'};
  color: ${({ theme }) => theme.colors.primary.green1};
  margin-bottom: 0.8rem;
  line-height: 2.9rem;

  span {
    text-transform: capitalize;
    font-weight: 600;
  }

  @media ${({ theme }) => theme.media.lg} {
    font-size: 3.2rem;
    line-height: 4rem;
    text-align: ${({ align }) => align || 'left'};
  }
`;

export const SubText = styled.h3<{ mt?: string; mb?: string; mbSm?: string; mtSm?: string }>`
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.5rem;
  margin-top: ${({ mtSm }) => mtSm || '1.2rem'};
  margin-bottom: ${({ mbSm }) => mbSm || '0'};

  span {
    color: ${({ theme }) => theme.colors.primary.green1};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.5rem;
  }

  @media ${({ theme }) => theme.media.lg} {
    margin-top: ${({ mt }) => mt || '1.2rem'};
    margin-bottom: ${({ mb }) => mb || '0'};
  }
`;

export const SubAction = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 2.2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  span,
  button,
  a {
    color: ${({ theme }) => theme.colors.primary.green1};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.2rem;
    background-color: transparent;
    border: none;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue1};
    text-decoration: none;
  }
`;

export const InputsWrapper = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || '4rem 0'};
  width: 100%;
`;

export const InputsWrapperBottom = styled.div`
  padding-bottom: 4rem;
`;

export const InputsItemWrapper = styled.div<{ pb?: string }>`
  padding-bottom: ${({ pb }) => pb || '4.2rem'};
`;

export const InputRow = styled.div<{ width?: string }>`
  display: flex;
  margin-bottom: 4rem;
  width: ${({ width }) => width || '100%'};
`;

export const HolderColumn = styled.div`
  margin-bottom: 4rem;
`;

export const InputColumn = styled.div`
  flex: 50%;
  padding-bottom: 2.4rem;
  padding-right: 2rem;
`;

export const TagColumn = styled.div`
  flex: 25%;
  padding-right: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  label {
    margin: 0;
  }
`;

export const TagButton = styled.div`
  padding-bottom: 2.4rem;
`;

export const InputText = styled.div`
  padding-bottom: 2.2rem;
  width: 100%;

  @media ${({ theme }) => theme.media.lg} {
    width: 47%;
  }
`;

export const UnderInputText = styled.div<{ top?: number; disabled?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.green8};
  margin-top: ${({ top }) => (top ? `${top}px` : '8px')};
  display: flex;
  align-items: flex-start;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 180%;
    color: ${({ theme }) => theme.colors.primary.green1};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue1};
    cursor: pointer;
  }

  .btn {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.primary.black4 : theme.colors.primary.green1)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    border-bottom: 1px solid ${({ theme, disabled }) => (disabled ? 'transparent' : theme.colors.primary.blue1)};
  }
`;

export const UnderInputTextWrapper = styled.div`
  color: #717171;
`;

export const UnderInputTextWrapper2 = styled.div`
  color: ${({ theme }) => theme.nColors['n-dark']};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const UnderInputTextTerms = styled.div<{ top?: number; bottom?: number }>`
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.green8};
  margin-top: ${({ top }) => (top ? `${top}px` : '8px')};
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '8px')};
  display: flex;
  align-items: flex-start;
`;

export const UnderInputTextTerms2 = styled.div<{ top?: number; bottom?: number }>`
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.green8};
  margin-top: ${({ top }) => (top ? `${top}px` : '8px')};
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '8px')};
  display: flex;
  margin-right: 20.2rem;
  align-items: center;
`;

export const UnderInputTextTermsItem = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 180%;
  text-decoration: underline;
`;
export const UnderInputTextTermsItem2 = styled.span`
  font-style: normal;
  font-weight: 300;
  /* font-size: 1.3rem; */
  line-height: 180%;
  text-decoration: underline;
`;

// Login page styling
export const LoginWrapper = styled.div`
  padding: 0rem 2rem 0 2rem;
  overflow-y: scroll;
  padding-bottom: 2rem;
  height: calc(100svh - 170px);

  @media ${({ theme }) => theme.media.md} {
    padding-top: 7rem;
  }
`;

export const LoginContent = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
`;

export const SignupContent = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
`;

export const BackButtonHolder = styled.div`
  margin-bottom: 1.4rem;
`;

export const USSDCardHlder = styled.div`
  margin-bottom: 2.3rem;
  width: 100%;
`;
export const USSDCard = styled.div`
  background: ${({ theme }) => theme.nColors['n-yellow1']};
  border-radius: 10px;
  width: 100%;
  padding: 2.5rem 2.2rem;
`;
export const USSDCardTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export const USSDCardTopInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 180%;
  color: #444444;
`;

export const USSDCardTopCode = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 49px;
  line-height: 140%;
  text-align: center;
  color: #121212;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #e0dfdf;
  width: 100%;
`;

export const USSDCardBottom = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export const USSDCardBottomIcon = styled.div`
  margin-top: 2.1rem;
`;
export const USSDCardBottomInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 180%;
  text-align: center;
  color: #444444;
  width: 80%;
`;

export const SignUpInputsWrapper = styled.div`
  display: block;

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const MerchantTypeBusinessNameWrapper = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.media.sm} {
    width: 50%;
  }
`;

export const MerchantTypeCardsWrapper = styled.div`
  display: grid;
  gap: 4rem;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.media.md} {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const MerchantTypeCardWrapper = styled.div`
  display: grid;
  gap: 4rem;

  @media ${({ theme }) => theme.media.lg} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const MerchantTypeInputWrapper = styled.div`
  width: 100%;
`;

export const BusinessTypeInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    transform: scale(70%);
    margin-right: 1rem;
  }
`;

export const MigratedBvnFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ResndButtonWraper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const DeviceManagementIconWrapper = styled.div`
  display: none;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
  }
`;

export const DeviceManagementIconWrapperSm = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const DeviceManagementOTPWrapper = styled.div`
  input:nth-child(4) {
    margin-right: 0;
  }
  margin-bottom: 4.2rem;
`;

export const HideonSm = styled.div`
  display: none;
  @media ${({ theme }) => theme.media.lg} {
    display: block;
  }
`;

export const HideonLg = styled.div`
  @media ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const UssdCodeWrapper = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: 100%;
  background: ${convertHexToRGBA('#999999', 0.05)};
  height: 57px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 14px;

  svg {
    margin-left: 20px;
  }
`;

export const UssdText = styled.div`
  display: flex;
  align-items: center;
`;

export const UssdCode = styled.p`
  font-weight: bold;
`;

// Login attempt error
export const AlertPlainWrapper = styled.div`
  display: flex;
  text-align: left;
  justify-content: center;
  /* align-items: center; */
  margin-top: 40px;
  border: 1px solid ${({ theme }) => theme.nColors['n-grey2a']};
  background: ${({ theme }) => theme.nColors['n-grey0']};
  border-radius: 5px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  /* svg {
    transform: scale(140%);
  } */

  @media ${({ theme }) => theme.media.lg} {
    text-align: center;
    align-items: center;
    padding-left: 20px;
  }
`;

export const AlertPlainText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.nColors['n-dark']};
  margin-left: 8px;

  @media ${({ theme }) => theme.media.lg} {
    margin-left: 10px;
    margin-left: 4px;
  }
`;

export const AlertWrapper = styled.div`
  display: flex;
  text-align: left;
  justify-content: center;
  /* align-items: center; */
  margin-top: 40px;
  border: 1px solid ${({ theme }) => theme.nColors['n-red']};
  background: ${({ theme }) => theme.nColors['n-red1']};
  border-radius: 5px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  /* svg {
    transform: scale(140%);
  } */

  @media ${({ theme }) => theme.media.lg} {
    text-align: center;
    align-items: center;
    padding-left: 20px;
  }
`;

export const AlertText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.nColors['n-red']};
  margin-left: 8px;

  @media ${({ theme }) => theme.media.lg} {
    margin-left: 10px;
    margin-left: 4px;
  }
`;

export const AuthFooterBox = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  padding-top: 32px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
`;
