/* eslint-disable prettier/prettier */

import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../types/redux';
import axios from '../../utils/http';

interface SettingsResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: {
    businessId: string;
    twoStepVerificationEnabled: boolean;
    chargeMerchantForTransactions: boolean;
    checkoutReceiptRecipient: string;
    paymentModes: [
      {
        paymentMethod: string | undefined;
        enabled: boolean;
      },
      {
        paymentMethod: string | undefined;
        enabled: boolean;
      },
      {
        paymentMethod: string | undefined;
        enabled: boolean;
      },
      {
        paymentMethod: string | undefined;
        enabled: boolean;
      }
    ];
  } | null;
  dataList?: null;
  meta?: null;
  error?: string | null;
}

interface Update2faSettings {
  transferPin: string;
  status: boolean;
}

interface UpdatePaymentPreference {
  transactionPin: string;
  twoStepVerificationState: boolean | undefined;
  chargeMerchantForTransactions: boolean | undefined;
  checkoutReceiptRecipient: string | undefined;
  paymentModes: [
    {
      paymentMethod: string;
      enabled: boolean | undefined;
    },
    {
      paymentMethod: string;
      enabled: boolean | undefined;
    },
    {
      paymentMethod: string;
      enabled: boolean | undefined;
    },
    {
      paymentMethod: string;
      enabled: boolean | undefined;
    }
  ];
}
interface SettlementResponse extends ApiResponse {
  BLOCKED_FUND: {
    amount: number;
    next_sweep: string;
    order_count: number | null;
  } | null;
  CHECKOUT: {
    amount: number;
    next_sweep: string;
    order_count: number | null;
  } | null;
  PURCHASE_INTERNATIONAL: {
    amount: number;
    next_sweep: string;
    order_count: number | null;
  } | null;
  TOTAL_AMOUNT: number;
}
export enum LIENTYPE {
  CHECKOUT = 'CHECKOUT',
  PURCHASE_INTERNATIONAL = 'PURCHASE_INTERNATIONAL',
}

interface WalletManualSweepRequest {
  lienType: LIENTYPE;
}

interface WalletManualSweepResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: null;
  dataList: null;
  meta: null;
  error: null;
}

const useFetch2faSettings = () => {
  const [response, setResponse] = useState<SettingsResponse | null>(null);
  const [error, setError] = useState<Error | AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  const fetch2faSettings = async () => {
    try {
      const result: SettingsResponse = await axios.get(`/business-preference`);
      setLoading(false);
      setResponse(result);
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        setError(err);
      }
    }
  };

  useEffect(() => {
    fetch2faSettings();
  }, [reload]);

  return { response, error, loading, reload, setReload };
};

const useFetchSettlmentWallets = () => {
  const [response, setResponse] = useState<SettlementResponse | null>(null);
  const [error, setError] = useState<Error | AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  const fetchSettlementWallets = async () => {
    try {
      const result = await axios.get(`/wallet/future-settlements`);
      setLoading(false);
      setResponse(result.data);
    } catch (err) {
      setLoading(false);
      if (err instanceof Error) {
        setError(err);
      }
    }
  };

  useEffect(() => {
    fetchSettlementWallets();
  }, [reload]);

  return { response, error, loading, reload, setReload };
};

const walletManualSweep = async (payload: WalletManualSweepRequest) => {
  try {
    const result: WalletManualSweepResponse = await axios.post(`/wallet/manual-sweep?lienType=${payload.lienType}`);
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

const update2faSettings = async (payload: Update2faSettings) => {
  try {
    const result: SettingsResponse = await axios.post('/business-preference/two-step-transfer-verification', {
      ...payload,
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

const updatePaymentSettings = async (payload: Update2faSettings) => {
  try {
    const result: SettingsResponse = await axios.post('/business-preference/checkout-fee-flag', {
      ...payload,
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

const updatePaymentMethodReference = async (payload: UpdatePaymentPreference) => {
  try {
    const result: SettingsResponse = await axios.put('/business-preference', {
      ...payload,
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export {
  useFetch2faSettings,
  useFetchSettlmentWallets,
  update2faSettings,
  updatePaymentSettings,
  updatePaymentMethodReference,
  walletManualSweep,
};
