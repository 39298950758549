import styled from 'styled-components';

export const TransactionInfoWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
`;

export const TransactionInfoBacground = styled.div`
  background: #121212;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
`;

export const TransactionInfoContentWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  padding-top: 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  max-height: 95vh;

  @media ${({ theme }) => theme.media.md} {
    max-width: 77.4rem;
    height: 100%;
    margin-left: auto;
    border-radius: 5px;
    padding-top: 13rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 0;
    max-height: 100vh;
  }
`;

export const TransactionInfoContentIconWrapper = styled.div`
  transform: scale(0.7);
  @media ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

export const TransactionInfoContentIconWrapperSm = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.md} {
    display: block;
  }
`;

export const TransactionInfoClose = styled.div`
  width: 3rem;
  height: 3rem;
  background: #eeeeee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2rem;
  right: 2rem;

  svg {
    scale: 0.8;
    cursor: pointer;
  }
`;

export const TransactionInfoTitle = styled.h4`
  color: #717171;
  font-size: 1.4rem;
  font-weight: 300;

  @media ${({ theme }) => theme.media.md} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

export const TransactionInfoAmount = styled.h3`
  color: #121212;
  font-size: 2.4rem;
  font-weight: 700;
  margin-top: 0.4rem;

  @media ${({ theme }) => theme.media.md} {
    font-size: 3.22rem;
    line-height: 3.4rem;
    padding-bottom: 5.5rem;
  }
`;

export const TransactionInfoContentMain = styled.div`
  display: grid;
  grid-template-columns: 1 fr;
  width: 100%;
  max-height: 40vh;
  overflow-y: scroll;

  @media ${({ theme }) => theme.media.md} {
    grid-template-columns: 1fr 1fr;
    gap: 6.9rem 0;
    border-top: 1px solid #c6c6c6;
    padding-top: 5.5rem;
    max-height: 100%;
    overflow-y: unset;
  }
`;

export const TransactionInfoContentMainItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.6rem 2rem;
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  gap: 1rem;

  @media ${({ theme }) => theme.media.md} {
    flex-direction: column;
    justify-content: flex-start;
    text-align: end;
    border-bottom: none;
    padding: 0;
    gap: 0.6rem;
  }

  :nth-child(2n + 1) {
    text-align: start;
  }
`;

export const TransactionInfoContentMainItemTitle = styled.h4`
  color: #999999;
  font-size: 1.4rem;
  line-height: 2.2rem;
  white-space: nowrap;

  @media ${({ theme }) => theme.media.md} {
    font-size: 1.6rem;
    line-height: 140%;
  }
`;

export const TransactionInfoContentMainItemValue = styled.h4`
  color: #121212;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-transform: capitalize;

  @media ${({ theme }) => theme.media.md} {
    font-size: 1.8rem;
    line-height: 140%;
  }
`;

export const TransactionInfoBottom = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: inherit;
  color: #717171;
  flex-direction: column;
  padding: 1.6rem 0 2.3rem;

  p {
    color: #121212;
    border-bottom: 1px solid #121212;
  }

  @media ${({ theme }) => theme.media.md} {
    margin-top: auto;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    border-top: 1px solid #c6c6c6;
    justify-content: center;
  }
`;

const colourMap: Record<string, string> = {
  success: '#11BB00',
  new: '#121212',
  failed: '#FF2200',
};

export const TransactionStatus = styled.span<{ type: string }>`
  color: ${({ type }) => (colourMap?.[type] ? colourMap?.[type] : '#121212')};
  font-weight: 400;
`;
