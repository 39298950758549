import { AltButton, Badge, Button, IconsEnum, Typography } from 'kudi-component-library';
import useDropdown from '../../hooks/useDropdown';
import { FilterProps } from './defs';
import {
  FilterHeader,
  FilterWrapper,
  FilterItemsWrapper,
  FilterActionsWrapper,
  FilterIcon,
  FilterMainWrapper,
  FilterContainer,
} from './styles';
import FilterItem from './filter-item';
import SearchInput from '../SearchInput';
import useFilterModel from './useFilter';
import AppliedFilters from './applied-filters';
import useIsMobile from '../../hooks/useIsMobile';

const Filter = ({
  searchPlaceholder,
  onSearch,
  onClearSearch,
  filterConfig,
  onApplyFilters,
  onClearFilters,
  onChange,
  hasSearch = true,
  alignItems,
  isTourRun = {
    isDropdownOpen: false,
    sampleFilters: [],
  },
}: FilterProps) => {
  const isMobile = useIsMobile();
  const { dropdownRef, dropdownButtonRef, showDropdown, setShowDropdown } = useDropdown();
  const {
    appliedFilters: filterInternalState,
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
  } = useFilterModel({ onApplyFilters });

  const appliedFilters = isTourRun.isDropdownOpen ? isTourRun.sampleFilters : filterInternalState;

  return (
    <FilterContainer alignItems={alignItems} flexDirection="column" width="100%">
      <FilterMainWrapper data-testid="filter_component_container" id="filter_component_container">
        {hasSearch ? (
          <SearchInput
            placeholder={searchPlaceholder || 'Search'}
            id="searchInputContainer"
            data-testid="searchInputContainer"
            onSearch={(searchTerm: string) => onSearch(searchTerm)}
            onRemove={onClearSearch}
            type="text"
          />
        ) : null}

        {isMobile ? <AppliedFilters appliedFilters={appliedFilters} handleRemoveFilter={handleRemoveFilter} /> : null}

        <FilterWrapper ref={dropdownButtonRef} id="filter_button" role="button" data-testid="filter_button">
          <FilterHeader onClick={() => setShowDropdown((state) => !state)}>
            <Typography variant="n-text2" color="dark">
              {`Filter ${appliedFilters.length ? 'Applied' : ''}`}
            </Typography>
            {appliedFilters.length ? (
              <Badge type="notification" backgroundColor="n-yellow" textColor="n-grey7">
                {appliedFilters.length}
              </Badge>
            ) : (
              <FilterIcon icon={IconsEnum.OUTLINE_FILTER_1} />
            )}
          </FilterHeader>
          {showDropdown || isTourRun.isDropdownOpen ? (
            <FilterItemsWrapper
              ref={dropdownRef}
              id="filter_dropdown_container"
              data-testid="filter_dropdown_container"
            >
              {filterConfig.map((item) => (
                <FilterItem
                  configItem={item}
                  onChange={onChange}
                  key={`filter_item_${item.query}`}
                  handleApplyFilter={handleApplyFilter}
                  appliedFilters={appliedFilters}
                />
              ))}
              <FilterActionsWrapper>
                <AltButton
                  color="tertiary"
                  variant="medium"
                  disabled={!appliedFilters.length}
                  onClick={() => {
                    setShowDropdown(false);
                    handleClearFilters();
                    onClearFilters();
                  }}
                >
                  Clear
                </AltButton>
                <Button
                  color="primary"
                  variant="medium"
                  disabled={!appliedFilters.length}
                  onClick={() => {
                    setShowDropdown(false);
                    onApplyFilters(appliedFilters);
                  }}
                >
                  Apply filter
                </Button>
              </FilterActionsWrapper>
            </FilterItemsWrapper>
          ) : null}
        </FilterWrapper>
      </FilterMainWrapper>
      {!isMobile ? <AppliedFilters appliedFilters={appliedFilters} handleRemoveFilter={handleRemoveFilter} /> : null}
    </FilterContainer>
  );
};

export default Filter;
