/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { BackButton } from 'kudi-component-library';
import React, { ReactNode } from 'react';
import { CloseIcon } from '../../assets/svg';
import { ModalWrapper, ModalBackground, ModalContentWrapper, ModalContent, ModalClose } from './style';

export interface ModalProps {
  showModal: boolean;
  onClose: () => void;
  children: ReactNode;
  backClick?: () => void;
  showBack?: boolean;
  noCloseIcon?: boolean;
  customDesktopWidth?: string;
  customTabletWidth?: string;
  modalContentStyles?: React.CSSProperties;
  padding?: string;
  closeBg?: string;
  closeBtnHeight?: string;
  closeBtnWidth?: string;
  padCloseButton?: boolean;
  maxHeight?: string;
  v2?: boolean;
}

const Content = ({
  onClose,
  showModal,
  children,
  showBack,
  backClick,
  customDesktopWidth,
  customTabletWidth,
  modalContentStyles,
  padding,
  closeBg,
  noCloseIcon,
  padCloseButton,
  closeBtnHeight,
  closeBtnWidth,
  maxHeight,
  v2 = false,
}: ModalProps) =>
  showModal ? (
    <ModalWrapper>
      <ModalBackground onClick={onClose}>
        <ModalContentWrapper
          customTabletWidth={customTabletWidth}
          customDesktopWidth={customDesktopWidth}
          padding={padding}
          v2={v2}
          style={{ ...modalContentStyles }}
        >
          <ModalContent onClick={(e) => e.stopPropagation()} padding={padding} maxHeight={maxHeight}>
            {showBack ? <BackButton onClick={backClick}>Back</BackButton> : null}
            {noCloseIcon ? null : (
              <ModalClose
                onClick={onClose}
                closeBg={closeBg}
                padCloseButton={padCloseButton}
                height={closeBtnHeight}
                width={closeBtnWidth}
              >
                <CloseIcon />
              </ModalClose>
            )}
            {children}
          </ModalContent>
        </ModalContentWrapper>
      </ModalBackground>
    </ModalWrapper>
  ) : null;

const Modal = ({ ...props }: ModalProps) => <Content {...{ ...props }} />;

export default Modal;
