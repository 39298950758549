import { Typography } from 'kudi-component-library';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { Separator } from '../../../styles/generic';

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  min-height: 139px;
  background-color: ${({ theme }) => theme.nColors['n-light']};
  border-radius: 5px;
  margin-bottom: 20px;

  &:hover {
    div:first-of-type {
      background: rgba(255, 204, 0, 0.3);
    }
  }

  @media ${({ theme }) => theme.media.sm} {
    width: 22%;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(198, 198, 198, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface SummaryCardProps {
  icon: ReactElement;
  title: string;
  total: string;
}

const SummaryCard = ({ icon, title, total }: SummaryCardProps) => (
  <Wrapper>
    <IconWrapper>{icon}</IconWrapper>
    <Separator height="1rem" />
    <Typography variant="n-text2" color="grey4">
      {title}
    </Typography>
    <Separator height="1rem" />
    <Typography variant="n-text4" color="dark">
      {total}
    </Typography>
  </Wrapper>
);

export default SummaryCard;
