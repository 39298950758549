import { Typography } from 'kudi-component-library';
import styled from 'styled-components';

export const ProductComingSoonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  margin: 2rem;
  background: ${({ theme }) => theme.nColors['n-light']};
  @media ${({ theme }) => theme.media.lg} {
    padding: 8rem 15rem;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  text-align: center;
`;

export const DetailSection = styled.div<{ alternate?: boolean }>`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  @media ${({ theme }) => theme.media.lg} {
    margin-top: 95px;
    flex-direction: ${({ alternate }) => (alternate ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
  }
  @media only screen and (min-width: 1980px) {
    gap: 150px;
    width: 100%;
    justify-content: center;
    display: flex;
  }
`;

export const SvgHolder = styled.div`
  svg {
    width: 100%;
    height: 100%;
    display: none;
  }
  @media ${({ theme }) => theme.media.lg} {
    svg {
      display: initial;
      width: initial;
      height: initial;
    }
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media ${({ theme }) => theme.media.lg} {
    max-width: 673px;
  }
`;

export const ProductTitle = styled.div`
  p {
    border-bottom: ${({ theme }) => `5px solid ${theme.nColors['n-yellow']}`};
  }
  display: flex;
  justify-content: center;
  width: 100%;
  @media ${({ theme }) => theme.media.lg} {
    width: fit-content;
    justify-content: flex-start;
  }
`;

export const ProductDescription = styled(Typography)`
  font-weight: 400;
`;

export const DiscoverSection = styled.div`
  text-transform: capitalize;
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const DiscoverText = styled(Typography)`
  font-weight: 600;
`;
