import Modal from '../../../../../components/Modal';
import {
  BottomBar,
  BottomBarContainer,
  ExpirationTracker,
  FormContainer,
  Header,
  ResendWrapper,
  Subtitle,
  Title,
  VerifyDetailsWrapper,
} from './styles';
import { Formik } from 'formik';
import { OtpInput } from 'kudi-component-library';
import { Flex } from 'rebass';
import * as Yup from 'yup';
import CountDown from '../../../../../components/CountDown';
import { useState } from 'react';

interface OtpInitialValues {
  otp: string;
}

const VerifyEmail = ({
  showModal,
  onClose,
  onSuccess,
  email,
}: {
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
  email: string;
}) => {
  const InitialFormValues: OtpInitialValues = {
    otp: '',
  };
  const verifyEmailSchema = Yup.object().shape({
    otp: Yup.string().min(3, 'Enter a valid OTP').required('OTP is required'),
  });

  const [timer, setTimer] = useState<number>(1);

  return (
    <Modal showModal={showModal} onClose={onClose} v2>
      <Header>
        <Title>Now, verify your email address</Title>
        <Subtitle>
          A 4-digit code has been sent to <span>{email}</span>. <br /> Kindly check your email inbox and enter the code.
        </Subtitle>
      </Header>
      <VerifyDetailsWrapper>
        <Formik
          initialValues={InitialFormValues}
          validationSchema={verifyEmailSchema}
          onSubmit={(values, { setSubmitting }) => {
            // eslint-disable-next-line no-console
            console.log(values);
            setSubmitting(true);
            onSuccess();
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <FormContainer onSubmit={handleSubmit}>
                <Flex maxWidth={264}>
                  <OtpInput
                    width="100%"
                    name="otp"
                    inputsNumber={4}
                    value={values.otp}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value.length === 4) {
                        handleSubmit();
                      }
                    }}
                    error={touched.otp && errors.otp ? errors.otp : undefined}
                    disabled={isSubmitting}
                  />
                </Flex>
              </FormContainer>
            );
          }}
        </Formik>
        {!!timer && (
          <ExpirationTracker>
            The code sent expires in{' '}
            <span>
              {' '}
              <CountDown {...{ minutes: timer, seconds: 0, cb: () => setTimer(0) }} />
            </span>
          </ExpirationTracker>
        )}
        <ResendWrapper>
          <p>Didn't receive the code?</p> &nbsp;{' '}
          <span
            role="button"
            onClick={() => {
              if (timer) {
                return;
              }
              setTimer(1);
            }}
          >
            Resend
          </span>
        </ResendWrapper>
      </VerifyDetailsWrapper>
      <BottomBarContainer>
        <BottomBar filled />
        <BottomBar filled />
      </BottomBarContainer>
    </Modal>
  );
};

export default VerifyEmail;
