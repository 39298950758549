import { useLocation } from 'react-router-dom';
import { NavigationSectionInterface } from '../../types/navigation';
import NavigationOption from './navigationOption';
import { NavigationSectionHolder, NavigationSectionTitle } from './style';

const NavigationSection = ({ title, data, baseRoute }: NavigationSectionInterface) => {
  const match = useLocation();

  const base = match.pathname.split('/')[2];

  const active = baseRoute === base;
  return (
    <NavigationSectionHolder id="guest-navigation-content-wrapper">
      {title && (
        <NavigationSectionTitle id="guest-navigation-content-title" active={active}>
          {title}
        </NavigationSectionTitle>
      )}
      {data.map((item, index) => (
        <NavigationOption {...item} baseRoute={baseRoute} key={`navigation-option-${index}`} />
      ))}
    </NavigationSectionHolder>
  );
};

export default NavigationSection;
