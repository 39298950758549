import { Discount } from '../../../types/inventory/discounts';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface AddDiscountResponse extends ApiResponse {
  data: Record<string, Array<Discount>>;
}

const addDiscount = async ({
  name,
  discountPercent,
  storeId,
}: {
  name: string;
  discountPercent: string;
  storeId: string;
}) => {
  try {
    const result: AddDiscountResponse = await axios.post('/webbe/v1/storehouse/stores/discounts', {
      name,
      discountPercent,
      storeId,
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default addDiscount;
