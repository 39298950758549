export const dateFormatter = (dateValue: string | Date) => {
  const date = new Date(dateValue);
  const month = date.toLocaleString('default', { month: 'short' });
  return month;
};

export const timeFormatter = (dateWithTime: string | Date) => {
  const time = new Date(dateWithTime);
  const formattedTime = time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return formattedTime;
};

export const getScheduleDate = (dateValue: string | Date) => {
  const dayOfWeekName = new Date(dateValue).toLocaleString('default', { weekday: 'long' });
  return dayOfWeekName;
};
