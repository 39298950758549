import { createSlice } from '@reduxjs/toolkit';
import {
  fetchProviderProfile,
  fetchProviderConfig,
  fetchInterestConfig,
  fetchLoanHistory,
  fetchSummary,
  fetchWalletTransactions,
  fetchWalletBalance,
  fetchInterestRates,
} from './thunkActions';
import {
  ProviderProfile,
  ProviderConfig,
  Summary,
  InterestConfig,
  WalletTransaction,
  LoanHistory,
  InterestRate,
} from '../../types/loanProvider';

interface InitialState {
  isLoading: boolean;
  providerProfile: ProviderProfile | null;
  providerConfig: ProviderConfig | null;
  summary: Summary | null;
  interestConfig: InterestConfig[] | null;
  walletBalance: number;
  walletTransactions: WalletTransaction[] | null;
  loanHistory?: {
    data: LoanHistory[];
    meta: {
      currentPage: number;
      pageSize: number;
      totalPages: number;
      totalRows: number;
    };
  };
  interestRates: InterestRate[];
}

const initialState: InitialState = {
  isLoading: false,
  providerProfile: {
    createdAt: '',
    id: '',
    name: '',
    userId: '',
    userPrimaryWalletId: '',
    revenueWalletId: '',
    revenuePercentage: 0,
    preference: 0,
    enabled: true,
    webhookId: '',
    creditScore: 0,
  },
  providerConfig: {
    id: '',
    channel: '',
    thresholdBalance: 0,
    contactEmail: '',
    contactPhone: null,
  },
  summary: {
    loanAmountDisbursed: 0,
    loanAmountCollected: 0,
    totalLoanRequestCount: 0,
    nonPerformingLoanAmount: 0,
    totalInterestEarned: 0,
    totalOverdueLoanAmount: 0,
  },
  interestConfig: [],
  walletBalance: 0,
  walletTransactions: [],
  loanHistory: {
    data: [],
    meta: {
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      totalRows: 0,
    },
  },
  interestRates: [],
};

export const loanProviderSlice = createSlice({
  name: 'loanProvider',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch loan provider profile
    builder.addCase(fetchProviderProfile.fulfilled, (state, { payload }) => {
      state.providerProfile = payload || null;
    });

    // fetch loan provider config
    builder.addCase(fetchProviderConfig.fulfilled, (state, { payload }) => {
      state.providerConfig = payload || null;
    });

    // fetch loan provider summary
    builder.addCase(fetchSummary.fulfilled, (state, { payload }) => {
      state.summary = payload || null;
    });

    // fetch loan provider interest Config
    builder.addCase(fetchInterestConfig.fulfilled, (state, { payload }) => {
      state.interestConfig = payload || null;
    });

    // fetch loan provider wallet balance
    builder.addCase(fetchWalletBalance.fulfilled, (state, { payload }) => {
      state.walletBalance = payload || 0;
    });

    // fetch loan provider wallet transactions
    builder.addCase(fetchWalletTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWalletTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchWalletTransactions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.walletTransactions = payload || [];
    });

    // fetch loan history
    builder.addCase(fetchLoanHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLoanHistory.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLoanHistory.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.loanHistory = payload;
    });

    // fetch loan provider interest Rates
    builder.addCase(fetchInterestRates.fulfilled, (state, { payload }) => {
      state.interestRates = payload || [];
    });
  },
});

export default loanProviderSlice.reducer;
export * from './thunkActions';
