import { Typography } from 'kudi-component-library';
import styled from 'styled-components';
import { PageWrapper } from '../../styles/generic';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CancelTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CancelText = styled(Typography)`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  padding-bottom: 5px;
  margin: 20px 0;
  width: fit-content;
`;

export const FormInputWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;

  div {
    margin-bottom: 20px;
  }

  @media ${({ theme }) => theme.media.sm} {
    width: 441px;
  }
`;

export const StorePageWrapper = styled(PageWrapper)`
  height: 100%;
`;

export const StoreWrapper = styled.div`
  background-color: ${({ theme }) => theme.nColors['n-light']};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15% 5%;

  svg {
    margin-bottom: 20px;
  }

  @media ${({ theme }) => theme.media.sm} {
    height: 99.8%;
  }
`;
export const SubheaderWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;

  @media ${({ theme }) => theme.media.sm} {
    width: 378px;
  }
`;
