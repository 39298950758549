import { FC } from 'react';
import { Button } from 'kudi-component-library';
import Modal from '../../../../components/Modal';
import {
  InnerModalWrapper,
  ModalTitle,
  ModalDescription,
  ActionsWrapper,
  UnderLinedText,
} from '../../../invoicing/styles';
import { InventorySuccessIcon } from '../../../../assets/svg';
import { NewProduct } from '../../../../types/inventory/products';

interface AddProductSuccessModalProps {
  onClose: () => void;
  showSuccessModal: boolean;
  handleAddAnotherMenuItem: () => void;
  productValues: NewProduct;
  handleRefetch?: () => void;
}

const AddMenuProductSuccessModal: FC<AddProductSuccessModalProps> = ({
  showSuccessModal,
  onClose,
  handleAddAnotherMenuItem,
  productValues,
  handleRefetch,
}) => {
  const hasEdited = !!productValues?.id;
  return (
    <Modal
      showModal={showSuccessModal}
      onClose={() => {
        if (handleRefetch) {
          handleRefetch();
          onClose();
        }
      }}
    >
      <InnerModalWrapper>
        <InventorySuccessIcon />
        <ModalTitle>{`Successfully ${hasEdited ? 'Edited' : 'Added'}`}</ModalTitle>
        <ModalDescription>{`You have successfully ${hasEdited ? 'edited' : 'added'} ${
          productValues?.name || 'a new product'
        } ${hasEdited ? 'in' : 'to'} your catalogue.`}</ModalDescription>
        <ActionsWrapper>
          {hasEdited ? null : (
            <Button variant="small" width="100%" height="45px" onClick={handleAddAnotherMenuItem}>
              Add another product
            </Button>
          )}
          <UnderLinedText
            top={20}
            onClick={() => {
              if (handleRefetch) {
                handleRefetch();
                onClose();
              }
            }}
          >
            <span> Close </span>
          </UnderLinedText>
        </ActionsWrapper>
      </InnerModalWrapper>
    </Modal>
  );
};

export default AddMenuProductSuccessModal;
