import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { NIcons, Typography, IconsEnum } from 'kudi-component-library';
import { HeaderTopFlex } from '../menu/styles';

import { NavigationTab } from '../../components/navigationTab';
import ErrorBoundary from '../../utils/errorBoundary';
import { PageWrapper, Separator } from '../../styles/generic';
import PageLoad from '../../components/PageLoad';
import { NotificationLayoutProps } from './types';
import { useNotificationLayoutModel } from './models/layout';
import NotificationMobileHeader from './components/mobile/mobile-header';
import { NotificationHideOnMobile } from './styles';
import useRedux from '../../hooks/useRedux';
import { setCategory, setStatus, updatePagination } from './reducer/slice';
import AllNotifications from './components/table/all-notifications';
import DesktopFiltersAndActions from './components/table/desktop-filters-and-actions';

const IconsMap = ['', IconsEnum.OUTLINE_BUSINESS, IconsEnum.OUTLINE_GIFT, IconsEnum.OUTLINE_ANNOUNCEMENT];

export const NotificationLayout = ({ routes }: NotificationLayoutProps) => {
  const { activeRoute } = useNotificationLayoutModel();

  const { dispatch } = useRedux();

  useEffect(() => {
    dispatch(setCategory(undefined));
    dispatch(setStatus(undefined));
    dispatch(updatePagination({ page: 0, pageSize: 10 }));
  }, [dispatch]);

  return (
    <PageWrapper>
      <NotificationHideOnMobile>
        <HeaderTopFlex>
          <div>
            <Typography variant="n-text6" color="dark">
              Notification
            </Typography>
          </div>
          <DesktopFiltersAndActions />
        </HeaderTopFlex>
        <NavigationTab
          active={activeRoute}
          options={Object.values(routes).map((item, index) => ({
            text: item,
            route: item,
            Icon: IconsMap[index] ? <NIcons icon={IconsMap[index] as IconsEnum} /> : <AllNotifications />,
          }))}
        />
      </NotificationHideOnMobile>
      <NotificationMobileHeader activeRoute={activeRoute} routes={routes} />
      <Separator height="2rem" />
      <ErrorBoundary>
        <Suspense fallback={<PageLoad height="50vh" />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </PageWrapper>
  );
};
