import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { useState } from 'react';
import {
  DocumentViewerExpanded,
  DocumentViewerExpandedClose,
  DocumentViewerExpandedItem,
  DocumentViewerExpandedToggleItem,
  DocumentViewerExpandedToggleWrapper,
  DocumentViewerMiniItem,
  DocumentViewerMiniItemList,
  DocumentViewerWrapper,
} from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import config from '../../../../utils/config';

const DocumentViewer = () => {
  const {
    state: {
      support: {
        issueList: { selectedIssue },
      },
    },
  } = useRedux();

  const list = selectedIssue?.images?.map((item) => `${config.mediaServiceUrl}/images/${item.id}?size=orig`) || [];

  const [selected, setSelected] = useState(0);
  const [show, setShow] = useState(false);

  const goBack = () => {
    if (selected === 0) return;

    setSelected((prev) => prev - 1);
  };

  const goForward = () => {
    if (selected === list.length - 1) return;

    setSelected((prev) => prev + 1);
  };

  const close = () => {
    setShow(false);
    setSelected(0);
  };

  const onSelect = (value: number) => {
    setSelected(value);
    setShow(true);
  };

  return selectedIssue && selectedIssue?.images?.length > 0 ? (
    <DocumentViewerWrapper>
      <Typography fontWeight={400} fontSize="14px" lineHeight="22px">
        Picture Attached
      </Typography>
      <DocumentViewerMiniItemList>
        {list.map((item, index) => (
          <DocumentViewerMiniItem alt="" src={item} onClick={() => onSelect(index)} />
        ))}
      </DocumentViewerMiniItemList>
      {show ? (
        <DocumentViewerExpanded>
          <DocumentViewerExpandedClose onClick={close}>
            <NIcons icon={IconsEnum.OUTLINE_X} />
          </DocumentViewerExpandedClose>
          <DocumentViewerExpandedItem src={list[selected]} />
          <DocumentViewerExpandedToggleWrapper>
            <DocumentViewerExpandedToggleItem onClick={goBack}>
              <NIcons icon={IconsEnum.OUTLINE_CHEVRON_LEFT} />
            </DocumentViewerExpandedToggleItem>
            <Typography fontWeight={400} fontSize="14px" lineHeight="22px" color="n-light">
              {selected + 1}/{list.length}
            </Typography>
            <DocumentViewerExpandedToggleItem onClick={goForward}>
              <NIcons icon={IconsEnum.OUTLINE_CHEVRON_RIGHT} />
            </DocumentViewerExpandedToggleItem>
          </DocumentViewerExpandedToggleWrapper>
        </DocumentViewerExpanded>
      ) : null}
    </DocumentViewerWrapper>
  ) : null;
};

export default DocumentViewer;
