/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { MobileSchema, Schema as OrdersTableSchema } from '../../../components/newTable';

export const AnalyticsSchema: OrdersTableSchema[] = [
  { text: 'S/N', isSerialNumber: true },
  { text: 'Name of Item', accessor: 'name' },
  {
    text: 'Category',
    accessor: 'category',
    render: (value) => <span>{value.category.name}</span>,
  },
  { text: 'Description', accessor: 'description' },
  { text: 'Amount', accessor: 'price', isCurrency: true },
];
export const MobileAnalyticsSchema: MobileSchema = {
  lt: {
    accessor: 'name',
  },
  rt: {
    accessor: 'category',
    render: (data) => <span>{data.category.name}</span>,
  },
  lb: {
    accessor: 'description',
  },
  rb: { accessor: 'price' },
};
