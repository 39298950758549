import { WebBeError } from '../../../../types/error';
import axios from '../../../../utils/merchantAcqServiceHttp';

interface PollDownloadCsvProps {
  downloadId: string;
}

const timer = (delay: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

async function pollDownloadCsv({ downloadId }: PollDownloadCsvProps) {
  let isDownloaded = false;
  let response = null;
  let serverError = null;
  while (!isDownloaded) {
    await timer(10000);
    try {
      response = await axios.get(`/webbe/v1/download/${downloadId}`);
      if (response) {
        isDownloaded = true;
      }
    } catch (error) {
      /* stop polling when there is an error */
      isDownloaded = true;
      serverError = error as WebBeError;
    }
  }
  return { response, serverError };
}

export default pollDownloadCsv;
