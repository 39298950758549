import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import {
  FormInput,
  FormInputInnerWrapper,
  FormInputWrapper,
  PhoneInputSeparator,
  PhoneInputWrapper,
  InputItemErrorWrapper,
} from './styles';
import Nigeria from '../../assets/svg/nigeria';
import ErrorIcon from '../ErrorIcon';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  #password-visibility-btn {
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 10px;
    line-height: 16px;
    color: #1a1a1a;
    margin: 0;
  }
`;

interface TextInputV2Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isPhone?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string | boolean;
}

const TextInputV2 = ({ label, isPhone = false, error, ...props }: TextInputV2Props) => {
  const [show, setShow] = useState<boolean>(true);
  return (
    <Wrapper>
      <FormInputWrapper>
        {props.value ? <label>{label}</label> : null}
        <FormInputInnerWrapper>
          {isPhone ? (
            <>
              <PhoneInputWrapper>
                <Nigeria />
                +234
              </PhoneInputWrapper>
              <PhoneInputSeparator />
            </>
          ) : null}
          <FormInput
            {...props}
            placeholder={props.placeholder ?? label}
            type={props.type === 'password' ? (show ? 'password' : 'text') : props.type}
          />
          {props.type === 'password' && (
            <button type="button" id="password-visibility-btn" onClick={() => setShow(!show)}>
              {show ? 'Show' : 'Hide'}
            </button>
          )}
        </FormInputInnerWrapper>
      </FormInputWrapper>
      {error ? (
        <InputItemErrorWrapper>
          <ErrorIcon /> <span> {error} </span>
        </InputItemErrorWrapper>
      ) : null}
    </Wrapper>
  );
};

export default TextInputV2;
