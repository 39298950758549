import { Typography } from 'kudi-component-library';
import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  display: flex;
  padding: 20px;
  height: 35rem;
  border-radius: 5px;
  background: ${({ theme }) => theme.nColors['n-light']};
`;

export const TableStateWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 138px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
`;

export const StateMessage = styled(Typography)<{ width?: string }>`
  max-width: ${({ width }) => width || '390px'};
`;

export const ActionSectionWrapper = styled.div`
  margin-top: 20px;
`;
