import { IssueTypesWrapper } from '../../styles';
import IssueCreationItem from '../base/issue_creation_item';
import useSubCategorySelection from '../../models/subCategorySelection';

const Type = () => {
  const { List } = useSubCategorySelection();

  return (
    <IssueTypesWrapper>
      {List?.map((item) => (
        <IssueCreationItem {...item} />
      ))}
    </IssueTypesWrapper>
  );
};
export default Type;
