import { Button } from 'kudi-component-library';
import { ReactComponent as EmptyIssues } from '../../assets/empty_issues.svg';
import { EmptyIssueListWraper } from '../../styles';
import { Separator } from '../../../../styles/generic';

const EmptyIssueList = ({ title, action, loading }: { title: string; action: () => void; loading: boolean }) => (
  <EmptyIssueListWraper>
    <EmptyIssues />
    <Separator height="20px" />
    <p>You currently do not have any {title} issue. If you encounter any issue, use the button below</p>
    <Separator height="40px" />
    {title !== 'completed' ? (
      <Button onClick={action} variant="medium" width="255px" disabled={loading} loading={loading}>
        Submit an Issue
      </Button>
    ) : null}
  </EmptyIssueListWraper>
);

export default EmptyIssueList;
