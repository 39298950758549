import { useToastDispatchContext } from '../utils/toastContext';

export function useToast(delay: number) {
  const dispatch = useToastDispatchContext();

  function toast(type: 'success' | 'error' | 'info', message: string, title?: string, cb?: () => void) {
    const id = Math.random().toString(36).substr(2, 9);
    dispatch({
      type: 'ADD_TOAST',
      toast: {
        type,
        message,
        id,
        title,
        cb,
      },
    });

    setTimeout(() => {
      cb?.();
      dispatch({
        type: 'DELETE_TOAST',
        id,
        toast: {
          type,
          message,
          id,
          title,
          cb,
        },
      });
    }, delay);
  }

  return toast;
}
