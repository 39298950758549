import { createAsyncThunk } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../../app/store';
import { ReduxAction } from '../../../../types/redux';
import axios from '../../../../utils/http';
import { issueResolutionURL } from '../../constants';
import { IssueType } from '../../defs';

interface GetAllTypesResponse {
  message: string;
  data: IssueType[];
}

interface GetAllTypesArgs extends ReduxAction {
  type?: string;
  onSuccess: (value: GetAllTypesResponse) => void;
}

export const GetAllTypes = createAsyncThunk<GetAllTypesResponse, GetAllTypesArgs, { state: RootState }>(
  '/support/get_all_types',
  async ({ onSuccess, onFailure, type }, { rejectWithValue, getState }) => {
    try {
      const {
        support: {
          issueCreation: { selectedSubCategory },
        },
      } = getState();

      const data: GetAllTypesResponse = await axios.get(`/${issueResolutionURL}/issues`, {
        params: {
          type: type || selectedSubCategory?.transactionTypeKey,
        },
      });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
