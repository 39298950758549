import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { ReduxAction } from '../../../../types/redux';
import axios from '../../../../utils/merchantAcqServiceHttp';
import { transactionBaseURl } from '../../constants';
import { NewTransactions } from '../../../../types/transactions';
import { customFilterDateFormatter } from '../../../../utils';

interface FetchTransactionsResponse {
  message: string;
  data: NewTransactions[];
  next: string;
}

export const FetchTransactions = createAsyncThunk<FetchTransactionsResponse, ReduxAction, { state: RootState }>(
  '/support/fetch_transaction',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    const {
      support: {
        issueCreation: { pagination, filters },
      },
    } = getState();
    try {
      let data: FetchTransactionsResponse;

      if (pagination.nextCursor) {
        data = await axios.get(`/${transactionBaseURl}/scroll`, {
          params: {
            cursor: pagination.nextCursor,
          },
        });
      } else {
        const dateRange = filters?.date ? [...filters.date] : ['', ''];

        const params: { limit: number; dateFrom: string; dateTo: string; source?: string; status?: string } = {
          limit: pagination.limit,
          dateFrom: customFilterDateFormatter(dateRange?.[0]),
          dateTo: customFilterDateFormatter(dateRange?.[1]),
        };

        if (filters.source) {
          params.source = filters.source;
        }

        if (filters.status) {
          params.status = filters.status;
        }

        data = await axios.get(
          `/${transactionBaseURl}/filter`,

          {
            params,
          }
        );
      }

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
