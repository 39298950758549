import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiResponse, ReduxAction } from '../../../../types/redux';
import { RootState } from '../../../../app/store';
import axios from '../../../../utils/merchantAcqServiceHttp';

interface MapTerminalResponse extends ApiResponse {
  data: null;
}

interface MapTerminalArgs extends ReduxAction {
  values: {
    terminalId: string;
    agentPhone: string;
  };
}

const base = window._env_.REACT_APP_BASEURL;

export const MapTerminal = createAsyncThunk<MapTerminalResponse, MapTerminalArgs, { state: RootState }>(
  '/referral/map-terminal',
  async ({ onSuccess, onFailure, values }, { rejectWithValue }) => {
    try {
      const data: MapTerminalResponse = await axios.post(`${base}/terminal/assign-terminal`, null, { params: values });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
