import { useState } from 'react';

import CountDown from '../../../components/CountDown';
import { UssdCode, UssdCodeWrapper, UssdText } from '../styles';
import { Call } from '../../../assets/svg';

/**
 *
 * @param time in seconds
 * @returns
 */
const USSDOtp = ({ time }: { time: number }) => {
  const [ussdtimer, setUssdTimer] = useState<number>(time);

  return (
    <>
      <CountDown {...{ minutes: 0, seconds: ussdtimer, cb: () => setUssdTimer(0), hidden: true }} />
      <UssdCodeWrapper show={!ussdtimer}>
        <UssdText>
          Get OTP by dialing USSD code <UssdCode>*347*727*1#</UssdCode>
        </UssdText>
        <Call />
      </UssdCodeWrapper>
    </>
  );
};

export default USSDOtp;
