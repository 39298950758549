import styled from 'styled-components';

import DefaultItemImage from './defaultItemImage.jpeg';

const Image = styled.img<{ size?: string }>`
  width: ${({ size }) => size || '30px'};
  height: ${({ size }) => size || '30px'};
  border-radius: 5px;
`;

const ItemImage = ({ imageUrl, size }: { imageUrl: string; size?: string }) => (
  <Image src={imageUrl || DefaultItemImage} size={size} loading="lazy" alt="Item" />
);

export default ItemImage;
