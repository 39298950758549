import styled from 'styled-components';

// general
export const Wrapper = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || `6rem 5.7rem`};
  align-items: flex-start;
`;

export const PageTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TopRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TopRightButtonHolder = styled.div`
  margin-left: 2.3rem;
`;

export const Content = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop || '4.4rem'};
  width: 100%;
`;

// headings
export const SmallHeading = styled.h2`
  font-size: 2.1rem;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 2.9rem;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const LargeHeading = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-style: normal;
  font-size: 2.8rem;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const MininText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.black2};
  width: 38rem;
`;

// Emptystate

export const EmptyWrapper = styled.div`
  max-width: 58rem;
  margin: 3rem auto 0 auto;
`;

export const More = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.primary.black5};
  margin: 0 auto;
  display: flex;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  div {
    width: 0.33rem;
    height: 0.33rem;
    background-color: ${({ theme }) => theme.colors.primary.blue7};
    border-radius: 50%;
  }
`;

// create outlet
export const CreateOuletWrapper = styled(Wrapper)`
  width: 87rem;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CreateOutletFormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  width: 87rem;
  justify-content: space-between;
`;
export const CreateOutletFormWrapperSection = styled.div`
  width: 42rem;
`;

export const Separator = styled.div<{ height?: string; width?: string }>`
  height: ${({ height }) => height || '1rem'};
  width: ${({ width }) => width || '0rem'};
`;

// card
export const CardWrapper = styled.div`
  border: 1px solid #edf1fc;
  border-radius: 1.4rem;
  padding: 2.8rem 2.5rem;
`;

export const CardTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 180%;
  color: ${({ theme }) => theme.colors.primary.green1};
  margin-bottom: 0.7rem;
`;

//
export const TerminalInfoWrapper = styled.div`
  display: flex;
  width: 45%;
  justify-content: flex-start;
`;

export const FlexWrap = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || '2rem'};
  justify-content: space-between;
`;

export const TerminalInfoIconHolder = styled.div`
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary.blue5};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
`;

export const TerminalInfoText = styled.p`
  color: ${({ theme }) => theme.colors.primary.black2};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 180%;
`;

export const TerminalInfoValue = styled.p`
  color: ${({ theme }) => theme.colors.primary.green1};
  font-style: normal;
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 140%;
`;

export const OutletInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const OutletInfoWrapperSection = styled.div`
  width: 49%;
`;

// Available payment channels

export const OutletChannelItem = styled.p`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.black5};
  margin-bottom: 4rem;

  :last-child {
    border: none;
  }
`;

export const OutletChannelTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.primary.black2};
`;

export const OutletChannelValue = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.primary.green1};
`;

export const OutletChannelLink = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 160.3%;
  text-align: right;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.primary.green6};
`;

// avatar

export const FullNameHolder = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
`;

export const FullName = styled.p`
  color: ${({ theme }) => theme.colors.primary.green1};
  margin-left: 0.8rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 180%;
`;
