/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Formik } from 'formik';
import { Button, Checkbox, Text, TextInput, Typography, NTheme as theme } from 'kudi-component-library';
import BankSelect from 'react-select';
import styled, { useTheme } from 'styled-components';
import Modal from '../../../components/Modal';
import { FullBox, FullFlex } from '../../../styles/generic';
import { ErrorIcon, NewInfoIcon, WithdrawSuccessIcon } from '../../../assets/svg';

import {
  addBeneficiary,
  fetchBeneficiaries,
  FetchBeneficiariesResponse,
  kycAccountLookUp,
  KycAccountLookUpResponse,
  transferToBankAccount,
  TransferToNombaResponse,
} from '../../../features/transfers/thunkActions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { BeneficiariesData, BankTransferPayloadData } from '../../../types/transactions';
import { formatCurrency } from '../../../utils';
import { Separator } from '../../business-tools/styles';
import { useToast } from '../../../hooks/useToast';
import { fetchBalance } from '../../../features/banking/balance/slice';
import ConfirmOtp from '../otp';
import { setOnboardingStage } from '../../../features/auth/slice';
import { TOAST_TIMER } from '../../../utils/constants';
import { Bank } from '../../../types/dashboard';
import { fetchAllBanks } from '../../../features/dashboard/thunkActions';
import TransactionPinModal from './PinModal';
import { useFetch2faSettings } from '../../../queries/two-factor';

interface InitialValues {
  beneficiaryName: string;
}
const InitialFormValues: InitialValues = {
  beneficiaryName: '',
};

const MessageWrapper = styled.div`
  background-color: ${theme.nColors['n-grey1']};
  padding: 24px;
  text-align: center;
  margin-top: 40px;
  border-radius: 8px;
  border: 1px solid ${theme.nColors['n-grey3']};
`;

function ErrorResponseModal({
  error,
  showErrorResponseModal,
  setBankName,
  setAccountNumber,
  setBeneficiaryName,
  setAmount,
  setAccountName,
  setDescription,
  setStep,
  setTransactionPin,
  setOtpError,
  setSaveBen,
  selectedData,
  setSelectedData,
  setShowErrorResponseModal,
}: {
  error: string;
  showErrorResponseModal: boolean;
  selectedData?: { id?: string; bankName: string; accountNo: string; name: string; nickName: string };
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setBankName: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setAccountName: React.Dispatch<React.SetStateAction<string>>;
  setAccountNumber: React.Dispatch<React.SetStateAction<string>>;
  setBeneficiaryName: React.Dispatch<React.SetStateAction<string>>;
  setTransactionPin: React.Dispatch<React.SetStateAction<string>>;
  setOtpError: React.Dispatch<React.SetStateAction<string>>;
  setSaveBen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData?: React.Dispatch<React.SetStateAction<BeneficiariesData | null>>;
}) {
  return (
    <Modal
      showModal={showErrorResponseModal}
      onClose={() => {
        if (!selectedData) {
          setStep(0);
          setAmount(0);
          setBankName('');
          setAccountName('');
          setAccountNumber('');
          setBeneficiaryName('');
          setDescription('');
          setTransactionPin('');
          setOtpError('');
          setSaveBen(false);
          setShowErrorResponseModal(false);
        } else {
          setAmount(0);
          setDescription('');
          setTransactionPin('');
          setOtpError('');
          setSelectedData && setSelectedData(null);
          setSaveBen(false);
          setShowErrorResponseModal(false);
        }
      }}
    >
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" pb="10px">
        <ErrorIcon />

        <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mt="30px">
          Transaction Creation Failed
        </Text>
        <Text color="#717171" fontSize="14px" textAlign="center">
          {error}
        </Text>

        <Button
          id="single-transfer-close-error"
          onClick={() => {
            if (!selectedData) {
              setStep(0);
              setAmount(0);
              setBankName('');
              setAccountName('');
              setAccountNumber('');
              setBeneficiaryName('');
              setDescription('');
              setOtpError('');
              setTransactionPin('');
              setSaveBen(false);
              setShowErrorResponseModal(false);
            } else {
              setAmount(0);
              setDescription('');
              setTransactionPin('');
              setOtpError('');
              setSelectedData && setSelectedData(null);
              setSaveBen(false);
              setShowErrorResponseModal(false);
            }
          }}
          width="80%"
          variant="large"
          style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '90px' }}
        >
          Close
        </Button>
      </FullFlex>
    </Modal>
  );
}

function ResponseModal({
  amount,
  beneficiary,
  showResponseModal,
  setSelectedData,
  setShowPinModal,
  setShowResponseModal,
}: {
  amount: number;
  beneficiary: string;
  showResponseModal: boolean;
  setShowPinModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData?: React.Dispatch<React.SetStateAction<BeneficiariesData | null>>;
}) {
  const bankingEntityType = localStorage.getItem('bankingEntityType');
  const iamTokenRole = JSON.parse(localStorage.getItem('IAMuserRole') as '{}') as string;

  return (
    <Modal
      showModal={showResponseModal}
      onClose={() => {
        setSelectedData && setSelectedData(null);
        setShowResponseModal(false);
      }}
    >
      <FullFlex flexDirection="column" justifyContent="center" alignItems="center" mt="40px" pb="20px">
        <WithdrawSuccessIcon />
        <Typography color="n-dark" fontWeight={700} fontSize="24px" style={{ margin: '30px 0' }}>
          Transfer Initiated
        </Typography>

        {amount && (
          <Typography color="n-grey3" lineHeight="22px" style={{ textAlign: 'center' }}>
            You have successfully initiated a transfer of <br />
            <span style={{ color: '#121212' }}> {formatCurrency(amount)} </span> to{' '}
            <span style={{ color: '#121212' }}>{beneficiary}</span>.
          </Typography>
        )}

        {bankingEntityType === 'BUSINESS' && iamTokenRole !== 'ADMIN' && iamTokenRole !== 'OWNER' && (
          <MessageWrapper>
            <Typography color="n-grey7" fontSize="14px" lineHeight="18px">
              Transfer request would be sent successfully, once approved by owner of this account or an administrator,
              and would expire if not approved after <span style={{ fontWeight: 700 }}>48 hours</span>.
            </Typography>
          </MessageWrapper>
        )}

        <Button
          id="single-transfer-close-success"
          onClick={() => {
            setShowPinModal(false);
            setShowResponseModal(false);
            setSelectedData && setSelectedData(null);
            window.location.reload();
          }}
          width="100%"
          variant="large"
          style={{ backgroundColor: '#FFCC00', color: '#121212', marginTop: '60px' }}
        >
          Done
        </Button>
      </FullFlex>
    </Modal>
  );
}

function ConfirmTransactionModal({
  showConfirmModal,
  setSelectedData,
  setShowConfirmModal,
  setBankName,
  setAccountNumber,
  setBeneficiaryName,
  setAmount,
  setAccountName,
  setDescription,
  setStep,
  saveBen,
  fee,
  setSaveBen,
  singleTransferPayload,
  selectedData,
}: {
  showConfirmModal: boolean;
  saveBen: boolean;
  fee: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setBankName: React.Dispatch<React.SetStateAction<string>>;
  setAccountName: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setAccountNumber: React.Dispatch<React.SetStateAction<string>>;
  setBeneficiaryName: React.Dispatch<React.SetStateAction<string>>;
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveBen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData?: React.Dispatch<React.SetStateAction<BeneficiariesData | null>>;
  singleTransferPayload: {
    bankName: string;
    bankCode: string;
    accountNumber: string;
    beneficiaryName: string;
    amount: number;
    transferType: string;
    frequency?: string;
    scheduledDate: string;
    narration?: string;
    repeatPayment: boolean;
    saveBen?: boolean;
  } | null;
  selectedData?: { id?: string; bankName: string; accountNo: string; name: string; nickName: string };
}) {
  const [showOtpModal, setShowOtpModal] = React.useState<boolean>(false);
  const [transactionPin, setTransactionPin] = React.useState<string>('');
  const [otpError, setOtpError] = React.useState<string>('');
  const [transError, setTransError] = React.useState<string>('');
  const [transRef, setTransRef] = React.useState<string>('');
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [showErrorResponseModal, setShowErrorResponseModal] = React.useState(false);
  const [otpDest, setOtpDest] = useState<string>('');
  const [showPinModal, setShowPinModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { isSubmitting } = useAppSelector((state) => state.transfers);
  const { fullUser } = useAppSelector((state) => state.auth);
  const responseToast = useToast(TOAST_TIMER);

  function handleOtpChange(val: string) {
    setTransactionPin(val);
    setOtpError('');
  }
  function handleAddBeneficiary() {
    if (singleTransferPayload !== null) {
      dispatch(
        addBeneficiary({
          accountNumber: singleTransferPayload?.accountNumber,
          beneficiaryName: singleTransferPayload?.beneficiaryName,
          bankName: singleTransferPayload?.bankName,
          bankCode: singleTransferPayload?.bankCode,
          nickName: '',
          onFailure: (error: { data: { msg: string } }) => {
            if (error?.data?.msg?.toLowerCase().includes('Beneficiary already exists'.toLowerCase())) {
              responseToast('error', error?.data?.msg);
              setShowConfirmModal(false);
              setShowResponseModal(true);
            } else {
              responseToast('error', error?.data?.msg);
            }
          },
          onSuccess: () => {
            responseToast('success', 'Beneficiary successfully added.');
            setShowConfirmModal(false);
            setShowResponseModal(true);
          },
        })
      );
    }
  }

  function confirmTransfer() {
    if (singleTransferPayload !== null) {
      Mixpanel.track('Enter Transaction Pin', {
        first_name: String(fullUser?.firstName),
        last_name: String(fullUser?.lastName),
        phone_number: String(fullUser?.phoneNumber),
        email: String(fullUser?.email),
        transaction_pin: transactionPin,
      });
      dispatch(
        transferToBankAccount({
          bankName: singleTransferPayload.bankName,
          bankCode: singleTransferPayload.bankCode,
          account: singleTransferPayload.accountNumber,
          beneficiaryName: singleTransferPayload.beneficiaryName,
          amount: singleTransferPayload.amount,
          transferType: singleTransferPayload.transferType.toUpperCase(),
          frequency: singleTransferPayload?.frequency?.toUpperCase(),
          scheduledDate: singleTransferPayload.scheduledDate,
          repeatPayment: singleTransferPayload.repeatPayment,
          narration: singleTransferPayload.narration,
          userPin: transactionPin,
          onFailure: (error) => {
            if (error?.data?.message === 'Incorrect Pin') {
              setOtpError(error?.data?.message);
            } else {
              setTransError(error?.data?.message || error?.data?.msg);
              setShowConfirmModal(false);
              setShowPinModal(false);
              setShowErrorResponseModal(true);
            }
          },
          onSuccess: (data: TransferToNombaResponse) => {
            if (data.data.status === 'PENDING_OTP_VALIDATION') {
              setShowOtpModal(true);
              setSaveBen;
              setOtpDest(data.data.otpDest);
              setTransRef(data.data.coreRef);
              setShowConfirmModal(false);
              setShowPinModal(false);
              return;
            }
            if (saveBen) {
              handleAddBeneficiary();
            } else {
              setShowConfirmModal(false);
              setShowResponseModal(true);
            }
          },
        })
      );
    }
  }

  const { response } = useFetch2faSettings();
  const bankingEntityType = localStorage.getItem('bankingEntityType');
  const iamTokenRole = JSON.parse(localStorage.getItem('IAMuserRole') as '{}') as string;

  const handleProceed = () => {
    if (
      bankingEntityType === 'BUSINESS' &&
      response?.data?.twoStepVerificationEnabled &&
      !['ADMIN', 'OWNER', 'ACCOUNTANT'].includes(iamTokenRole)
    ) {
      confirmTransfer();
    } else {
      setShowConfirmModal(false);
      setShowPinModal(true);
    }
  };

  return (
    <>
      <Modal
        showModal={showConfirmModal}
        onClose={() => {
          if (!selectedData) {
            setStep(0);
            setAmount(0);
            setBankName('');
            setAccountName('');
            setAccountNumber('');
            setBeneficiaryName('');
            setDescription('');
            setTransactionPin('');
            setOtpError('');
            setSaveBen(false);
            setShowConfirmModal(false);
          } else {
            setAmount(0);
            setTransactionPin('');
            setDescription('');
            setOtpError('');
            setSelectedData && setSelectedData(null);
            setSaveBen(false);
            setShowConfirmModal(false);
          }
        }}
      >
        <FullFlex
          height="650px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pl="30px"
          pr="30px"
          pb="10px"
        >
          <Text color="#121212" fontSize="24px" fontWeight="bold" textAlign="center" mb="30px" mt="30px">
            Confirm this transaction
          </Text>

          <FullBox style={{ border: '1px dashed #FFCC00', borderRadius: '5px' }} width="100%" p="20px">
            <FullFlex justifyContent="space-between" mb="20px">
              <Typography color="n-grey3">Amount</Typography>
              <Typography variant="n-text4" style={{ textAlign: 'right' }}>
                {' '}
                {singleTransferPayload !== null && formatCurrency(singleTransferPayload?.amount)}
              </Typography>
            </FullFlex>

            <FullFlex justifyContent="space-between" mb="20px">
              <Typography color="n-grey3">Fees</Typography>
              <Typography variant="n-text3" style={{ textAlign: 'right' }}>
                {' '}
                {singleTransferPayload !== null && formatCurrency(fee)}
              </Typography>
            </FullFlex>

            <FullFlex justifyContent="space-between" mb="20px">
              <Typography color="n-grey3">Recipient's account</Typography>
              <Typography variant="n-text3" style={{ textAlign: 'right' }}>
                {' '}
                {singleTransferPayload?.accountNumber}
              </Typography>
            </FullFlex>

            <FullFlex justifyContent="space-between" mb="20px">
              <Typography color="n-grey3">Bank</Typography>

              <Typography variant="n-text3" style={{ textAlign: 'right' }}>
                {' '}
                {singleTransferPayload?.bankName}
              </Typography>
            </FullFlex>

            <FullFlex justifyContent="space-between">
              <Typography color="n-grey3">Recipient's name</Typography>
              <Typography variant="n-text3" style={{ textAlign: 'right' }}>
                {' '}
                {singleTransferPayload?.beneficiaryName}
              </Typography>
            </FullFlex>
          </FullBox>
          <FullBox width="100%" style={{ marginTop: 'auto' }}>
            <FullFlex alignItems="center" mb="10px">
              <NewInfoIcon fontSize="12px" />
              <Text color="#121212" fontSize="12px" ml="10px">
                Submitted payments cannot be recalled
              </Text>
            </FullFlex>
          </FullBox>

          <FullBox width="100%">
            <Separator height="1rem" />
            <Button
              id="single-transfer-confirm"
              onClick={() => {
                handleProceed();
              }}
              width="100%"
              variant="large"
              style={{ backgroundColor: '#FFCC00', color: '#121212' }}
            >
              Proceed
            </Button>
          </FullBox>
        </FullFlex>
      </Modal>
      {showPinModal && (
        <TransactionPinModal
          showModal={showPinModal}
          isSubmitting={isSubmitting}
          otpError={otpError}
          transactionPin={transactionPin}
          handleOtpChange={handleOtpChange}
          confirmTransfer={confirmTransfer}
          handleCloseModal={() => setShowPinModal(false)}
        />
      )}
      {singleTransferPayload !== null && (
        <ResponseModal
          setShowPinModal={setShowPinModal}
          amount={singleTransferPayload?.amount || 5000}
          beneficiary={singleTransferPayload?.beneficiaryName || 'John Doe'}
          showResponseModal={showResponseModal}
          setShowResponseModal={setShowResponseModal}
          setSelectedData={setSelectedData}
        />
      )}
      <ErrorResponseModal
        error={transError}
        showErrorResponseModal={showErrorResponseModal}
        setShowErrorResponseModal={setShowErrorResponseModal}
        setBankName={setBankName}
        setAccountNumber={setAccountNumber}
        setBeneficiaryName={setBeneficiaryName}
        setAmount={setAmount}
        setStep={setStep}
        setAccountName={setAccountName}
        setDescription={setDescription}
        setTransactionPin={setTransactionPin}
        setOtpError={setOtpError}
        setSaveBen={setSaveBen}
        setSelectedData={setSelectedData}
        selectedData={selectedData && selectedData}
      />
      <ConfirmOtp
        showOtpModal={showOtpModal}
        setShowOtpModal={setShowOtpModal}
        transRef={transRef}
        setShowResponseModal={setShowResponseModal}
        setBankName={setBankName}
        setAccountNumber={setAccountNumber}
        setBeneficiaryName={setBeneficiaryName}
        setAmount={setAmount}
        setStep={setStep}
        setAccountName={setAccountName}
        setDescription={setDescription}
        setSaveBen={setSaveBen}
        saveBen={saveBen}
        setSelectedData={setSelectedData}
        setUserPin={setTransactionPin}
        handleAddBeneficiary={handleAddBeneficiary}
        otpDest={otpDest}
      />
    </>
  );
}

interface IMyComponentProps {
  showSingleTransfer: boolean;
  setShowSingleTransfer: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData?: React.Dispatch<React.SetStateAction<BeneficiariesData | null>>;
  selectedData?: { id?: string; bankName: string; accountNo: string; name: string; nickName: string };
  amountUsed?: string;
  bankUsed?: string;
  accountNumberUsed?: string;
  bankCodeUsed?: string;
  showFlyoutValue?: string;
}

export default function SingleTransfer(props: IMyComponentProps) {
  const {
    showSingleTransfer,
    setSelectedData,
    setShowSingleTransfer,
    selectedData,
    amountUsed,
    bankUsed,
    accountNumberUsed,
    bankCodeUsed,
    showFlyoutValue,
  } = props;

  const [beneficiariesList, setBeneficiariesList] = React.useState<BeneficiariesData[]>([]);
  const [filteredBeneficiariesList, setFilteredBeneficiariesList] = React.useState<BeneficiariesData[]>([]);
  const [newBeneficiary, setNewBeneficiary] = React.useState<boolean>(true);
  const [savedBeneficiary, setSavedBeneficiary] = React.useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState<boolean>(false);
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
  const [saveBen, setSaveBen] = React.useState<boolean>(false);
  const [repeat] = React.useState<boolean>(false);
  const [bankName, setBankName] = React.useState<string>(selectedData ? selectedData?.bankName : '');
  const [bankCode, setBankCode] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState(selectedData ? selectedData?.accountNo : '');
  const [accountLookupError, setAccountLookupError] = React.useState('');
  const [bankError, setBankError] = React.useState('');
  const [accountName, setAccountName] = React.useState('');
  const [beneficiaryName, setBeneficiaryName] = React.useState(selectedData ? selectedData?.name : '');
  const [description, setDescription] = React.useState('');
  const [schedule] = React.useState('Immediately');
  const [frequency] = React.useState('Daily');
  const [step, setStep] = React.useState(0);
  const [amount, setAmount] = React.useState<number>(0);
  const [fee, setFee] = React.useState<number>(0);
  const [inputError, setInputError] = React.useState('');
  const [date] = React.useState<string>(moment().format('yyyy-MM-DD'));
  const [timeVal] = React.useState('');
  const [singleTransferPayload, setSingleTransferPayload] = React.useState<BankTransferPayloadData | null>(null);

  const { fullUser, parentBusinessId } = useAppSelector((state) => state.auth);
  const { inProgress } = useAppSelector((state) => state.transfers);
  const { bankList, inProgress: bankListinProgress } = useAppSelector((state) => state.dashboard);
  const toast = useToast(TOAST_TIMER);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { availableBalance, isLoading } = useAppSelector((state) => state.banking.balance);

  function getBankNameByCode(code: string | undefined) {
    for (let i = 0; i < bankList.length; i++) {
      if (bankList[i].code === bankCodeUsed) {
        setBankName(bankList[i].name);
        return;
      }
    }
  }

  useEffect(() => {
    if (showFlyoutValue === 'Resend') {
      setAmount(Number(amountUsed));
      setBankCode(String(bankCodeUsed));
      setAccountNumber(String(accountNumberUsed));
    }

    getBankNameByCode(bankCodeUsed);
  }, [amountUsed, bankUsed, bankCodeUsed, accountNumberUsed]);

  // redirect for kyc upgrade
  const goToKycLevel = () => {
    dispatch(setOnboardingStage('upgrade-kyc-level'));
    navigate('/auth/sign-up-new');
  };

  const FetchBalances = useCallback(() => {
    if (!parentBusinessId) return;

    const onSuccess = () => {};
    const onFailure = () => {};

    dispatch(fetchBalance({ onSuccess, onFailure }));
  }, [dispatch]);

  useEffect(() => {
    FetchBalances();
  }, [FetchBalances]);

  const AccountLookUp = useCallback(() => {
    if (amount < 1 || !amount || accountNumber === '') return;
    if (accountNumber.length < 10) {
      setAccountName('');
      return;
    }
    const selectedBank = bankList.find((bank) => bank.name === bankName);
    if (!selectedBank) {
      return;
    }

    dispatch(
      kycAccountLookUp({
        userId: fullUser?.connectedBusinesses[0].coreUserId,
        bankCode: selectedBank?.code,
        accountNumber,
        amount: `${amount}`,
        type: 'transfer',
        onFailure: (result) => {
          if (result.data.data === null) {
            setAccountLookupError(result.data.message);
            setInputError('');
            setAccountName('');
            setBankCode('');
            return;
          }
          if (result.data.data.kycLimitExceeded || result.data.data.insufficientFunds) {
            setInputError(result.data.message);
            setBankError('');
            setAccountName('');
            setBankCode('');
          } else if (result.data.data.bankUnavailable) {
            setBankError(result.data.message);
            setInputError('');
            setAccountName('');
            setBankCode('');
          } else {
            setAccountLookupError(result.data.message);
            setInputError('');
            setAccountName('');
            setBankCode('');
          }
        },
        onSuccess: (result: KycAccountLookUpResponse) => {
          setInputError('');
          setBankError('');
          setAccountLookupError('');
          setFee(result.data.transactionCharge);
          setAccountName(result.data.accountName);
          setBankCode(selectedBank?.code);
        },
      })
    );
  }, [dispatch, amount, accountNumber, bankName]);

  useEffect(() => {
    dispatch(fetchAllBanks({}));
  }, [dispatch]);

  useEffect(() => {
    AccountLookUp();
  }, [AccountLookUp]);

  useEffect(() => {
    const onFailure = () => {
      toast('error', 'Something went wrong');
    };

    const onSuccess = (data: FetchBeneficiariesResponse) => {
      setBeneficiariesList(data.data);
      setFilteredBeneficiariesList(data.data);
    };

    dispatch(fetchBeneficiaries({ page: 1, onSuccess, onFailure }));
  }, [dispatch, savedBeneficiary]);

  function handleTransfer() {
    const datestring = `${date} ${timeVal}`;
    const dateObj = new Date(datestring);
    const userdate = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
    const usertime = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
    const finalDate = `${userdate} ${usertime}`;

    const data = {
      bankName,
      beneficiaryName: accountName || beneficiaryName,
      amount,
      bankCode,
      accountNumber,
      transferType: schedule,
      frequency,
      scheduledDate: finalDate,
      repeatPayment: repeat,
      narration: description,
      userId: '',
      userPin: '',
      saveBen,
    };
    setSingleTransferPayload(data);
  }

  function filterBeneficiaries(val: string) {
    setBeneficiaryName(val);
    const result = beneficiariesList.filter((item) => item.name.toLowerCase().includes(val.toLowerCase()));
    setFilteredBeneficiariesList(result);
  }

  function validateAccountNumber(val: string) {
    // check length
    if (val.length < 10 && val.length !== 0) {
      setAccountLookupError('Account number must be at least 10 digits');

      return;
    }
    if (val.length > 10) {
      setAccountLookupError('Account number must not exceed 10 digits');

      return;
    }
    // verify only digits
    const isnum = /^\d+$/.test(val);
    if (!isnum) {
      setAccountLookupError('Account number must contain only numbers');

      return;
    }
    setAccountLookupError('');
  }

  const formattedBankList = bankList.map((bank: Bank) => ({ label: bank.name, value: bank.name }));
  const theme = useTheme();

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background: theme.nColors['n-light'],
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '50px',
      border: '1px solid #EFEFEF',
      '&:hover': {
        color: '#EFEFEF',
        border: `1px solid ${theme.nColors['n-grey2']}`,
        outlineColor: theme.nColors['n-grey2'],
        background: theme.nColors['n-grey1'],
      },
      boxShadow: '0 0 1px #EFEFEF',
    }),

    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? '#000' : 'inherit',
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    }),

    indicatorsContainer: (provided: any) => ({
      ...provided,
      border: 'none',
    }),

    indicatorSeparator: (provided: any) => ({
      ...provided,
      width: '0',
    }),

    indicatorContainer: (provided: any) => ({
      ...provided,
      '&:hover': {
        color: '#EFEFEF',
      },
    }),

    menu: (provided: any) => ({
      ...provided,
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2) !important',
      border: 'none',
    }),

    singleValue: (provided: any) => ({
      ...provided,
      fontSize: '16px',
      fontFamily: 'Inter',
      color: theme.nColors['n-dark'],
      fontWeight: '400',
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      fontFeatureSettings: 'pnum 1,lnum 1,cv11 1',
      fontfamily: 'Inter,sans-serif !important',
      fontWeight: 400,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: '16px',
      fontFamily: 'Inter',
      color: theme.nColors['n-grey3'],
    }),
  };
  return (
    <FullBox p="0px 30px">
      <Modal
        showModal={showSingleTransfer}
        onClose={() => {
          setAmount(0);
          setBankName('');
          setAccountName('');
          setAccountNumber('');
          setBeneficiaryName('');
          setDescription('');
          setStep(0);
          setSelectedData && setSelectedData(null);
          setSaveBen(false);
          setShowSingleTransfer(false);
        }}
      >
        {step === 0 ? (
          <>
            <Text
              textAlign="center"
              color="#121212"
              fontSize="24px"
              fontWeight="bold"
              mb="10px"
              style={{ paddingTop: '20px' }}
            >
              Single transfer
            </Text>
            <Text textAlign="center" color="#717171" fontSize="14px" fontWeight="regular" mb="20px">
              How much are you looking to send
            </Text>
            <FullBox bg="#FAFAFA" p="15px">
              <Text textAlign="center" color="#8C8C8C" fontSize="12px">
                {isLoading ? 'Fetching balance...' : `Wallet balance: ${formatCurrency(availableBalance)}`}
              </Text>
            </FullBox>

            <FullBox p="15px">
              {showFlyoutValue !== 'Resend' ? (
                <FullFlex style={{ border: '1px solid #EEEEEE' }} justifyContent="space-between">
                  <Text
                    id="single-transfer-new-beneficiary"
                    style={{
                      borderBottom: newBeneficiary ? '3px solid #FFCC00' : '',
                      width: '50%',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                    color={newBeneficiary ? '#121212' : '#999999'}
                    fontSize="14px"
                    fontWeight="regular"
                    textAlign="center"
                    onClick={() => {
                      setNewBeneficiary(true);
                      setSavedBeneficiary(false);
                    }}
                  >
                    New Beneficiary
                  </Text>
                  <Text
                    id="single-transfer-saved-beneficiary"
                    style={{
                      borderBottom: savedBeneficiary ? '3px solid #FFCC00' : '',
                      width: '50%',
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                    color={savedBeneficiary ? '#121212' : '#999999'}
                    fontSize="14px"
                    fontWeight="regular"
                    textAlign="center"
                    onClick={() => {
                      setSavedBeneficiary(true);
                      setNewBeneficiary(false);
                    }}
                  >
                    Saved Beneficiary
                  </Text>
                </FullFlex>
              ) : null}

              {/* New Beneficiary Starts */}

              {newBeneficiary && (
                <FullBox mt="20px">
                  <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                    <FullBox width="100%" mb="20px">
                      <Text fontSize="12px" color="#444444" mb="4px" style={{ width: '100%' }}>
                        Amount
                      </Text>

                      {showFlyoutValue === 'Resend' ? (
                        <TextInput
                          id="amount"
                          label=""
                          name="amount"
                          value={`${formatCurrency(amount)}`}
                          onChange={() => {}}
                          style={{ border: accountLookupError ? '1px solid #ff2200' : '' }}
                          width="100%"
                          disabled
                        />
                      ) : (
                        <CurrencyInput
                          id="input-amount"
                          name="input-amount"
                          placeholder={!amount ? `${formatCurrency(0)}` : `${formatCurrency(amount)}`}
                          prefix="₦"
                          decimalsLimit={2}
                          onValueChange={(value) => {
                            if (Number(value) < 1) {
                              setInputError('Minimum amount to transfer is ₦1');
                            } else {
                              setInputError('');
                              setAmount(Number(value));
                            }
                          }}
                          width="100%"
                          style={{
                            width: '100%',
                            height: '45px',
                            border: '1px solid #EFEFEF',
                            borderRadius: '4px',
                            outline: 'none',
                            fontSize: '14px',
                            padding: '0 12px',
                          }}
                        />
                      )}

                      <FullBox onClick={() => false}>
                        {inputError !== '' && (
                          <Text id="input-amount-error" textAlign="left" color="#FF2200" fontSize="12px">
                            {inputError}{' '}
                            {inputError.toLowerCase().includes('kyc') && (
                              <p>
                                Please{' '}
                                <u style={{ color: 'blue', cursor: 'pointer' }} onClick={() => goToKycLevel()}>
                                  click here
                                </u>{' '}
                                to upgrade
                              </p>
                            )}
                          </Text>
                        )}
                      </FullBox>
                    </FullBox>

                    <FullBox mb="20px" width="100%" id="single-transfer-bank">
                      {showFlyoutValue === 'Resend' ? (
                        <Text fontSize="12px" color="#444444" mb="4px" style={{ width: '100%' }}>
                          Bank Name
                        </Text>
                      ) : null}

                      {showFlyoutValue === 'Resend' ? (
                        <TextInput
                          id="bankName"
                          label=""
                          name="bankName"
                          value={bankName}
                          onChange={() => {}}
                          style={{ border: accountLookupError ? '1px solid #ff2200' : '' }}
                          width="100%"
                          disabled
                        />
                      ) : (
                        <BankSelect
                          name="Banks"
                          placeholder="Select or Search Bank"
                          isSearchable={true}
                          options={formattedBankList}
                          onChange={(option) => {
                            option && setBankName(option?.value);
                          }}
                          isDisabled={bankListinProgress}
                          styles={customStyles}
                          theme={(defaultTheme) => ({
                            ...defaultTheme,
                            borderRadius: 5,
                            colors: {
                              ...defaultTheme.colors,
                              text: '#000',
                              primary25: '#f4f2f2',
                              primary: '#d3d3d3',
                            },
                          })}
                          defaultValue={{ label: selectedData ? bankName : 'Select or Search Bank', value: bankName }}
                        />
                      )}

                      <FullBox onClick={() => false}>
                        {bankError !== '' && (
                          <Text id="input-amount-error" textAlign="left" color="#FF2200" fontSize="12px">
                            {bankError}
                          </Text>
                        )}
                      </FullBox>
                    </FullBox>

                    <FullBox width="100%" mb="20px">
                      {showFlyoutValue === 'Resend' ? (
                        <Text fontSize="12px" color="#444444" mb="4px" style={{ width: '100%' }}>
                          Account Number
                        </Text>
                      ) : null}

                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        {showFlyoutValue === 'Resend' ? (
                          <TextInput
                            id="accountNumber"
                            label=""
                            name="accountNumber"
                            value={accountNumber}
                            onChange={() => {}}
                            style={{ border: accountLookupError ? '1px solid #ff2200' : '' }}
                            width="100%"
                            disabled
                          />
                        ) : (
                          <TextInput
                            id="single-transfer-account-number"
                            label="Account number"
                            placeholder="Enter your account"
                            name="account_number"
                            value={accountNumber}
                            onChange={(e) => {
                              validateAccountNumber(e.target.value);
                              setAccountNumber(e.target.value);
                            }}
                            style={{ border: accountLookupError ? '1px solid #ff2200' : '' }}
                            width="100%"
                            error={accountLookupError}
                          />
                        )}
                      </Formik>
                    </FullBox>
                    {showFlyoutValue === 'Resend' ? (
                      <Text fontSize="12px" color="#444444" mb="4px" style={{ width: '100%' }}>
                        Account Name
                      </Text>
                    ) : null}
                    <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                      {showFlyoutValue === 'Resend' ? (
                        <TextInput
                          id="single-transfer-account-name"
                          label=""
                          placeholder={inProgress ? 'Please wait...' : ''}
                          name="account_name"
                          value={accountName || selectedData?.name || ''}
                          disabled
                          onChange={() => {}}
                          width="100%"
                          style={{ marginBottom: '20px' }}
                        />
                      ) : (
                        <TextInput
                          id="single-transfer-account-name"
                          label="Account name"
                          placeholder={inProgress ? 'Please wait...' : ''}
                          name="account_name"
                          value={accountName || selectedData?.name || ''}
                          disabled
                          onChange={() => {}}
                          width="100%"
                          style={{ marginBottom: '20px' }}
                        />
                      )}
                    </Formik>

                    {showFlyoutValue !== 'Resend' ? (
                      <FullBox width="100%" mb="20px">
                        <Checkbox
                          id="single-transfer-save-this-beneficiary"
                          name="save"
                          checked={saveBen}
                          onClick={() => setSaveBen(!saveBen)}
                        >
                          Save this beneficiary
                        </Checkbox>
                      </FullBox>
                    ) : null}

                    <FullBox width="100%" mb="10px">
                      {showFlyoutValue === 'Resend' ? (
                        <Text fontSize="12px" color="#444444" mb="4px" style={{ width: '100%' }}>
                          Description
                        </Text>
                      ) : null}
                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        {showFlyoutValue === 'Resend' ? (
                          <TextInput
                            id="description"
                            label=""
                            name="description"
                            value={description}
                            onChange={() => {}}
                            style={{ border: accountLookupError ? '1px solid #ff2200' : '' }}
                            width="100%"
                            disabled
                          />
                        ) : (
                          <TextInput
                            id="single-transfer-description"
                            label="Description (Optional)"
                            placeholder=""
                            name="description"
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                            width="100%"
                          />
                        )}
                      </Formik>
                    </FullBox>
                  </FullFlex>
                </FullBox>
              )}
              {/* New Beneficiary Ends */}

              {/* Saved Beneficiary Starts */}
              {savedBeneficiary && (
                <FullBox mt="20px">
                  <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                    <FullBox width="100%" mb="20px">
                      <Text fontSize="12px" color="#444444" mb="4px" style={{ width: '100%' }}>
                        Amount
                      </Text>
                      <CurrencyInput
                        id="input-amount"
                        name="input-amount"
                        placeholder={!amount ? `${formatCurrency(0)}` : `${formatCurrency(amount)}`}
                        prefix="₦"
                        decimalsLimit={2}
                        onValueChange={(value) => {
                          if (Number(value) < 1) {
                            setInputError('Minimum amount to transfer is ₦1');
                          } else {
                            setInputError('');
                            setAmount(Number(value));
                          }
                        }}
                        width="100%"
                        style={{
                          width: '100%',
                          height: '45px',
                          border: '1px solid #EFEFEF',
                          borderRadius: '4px',
                          outline: 'none',
                          fontSize: '14px',
                          padding: '0 12px',
                        }}
                      />
                      <FullBox onClick={() => false}>
                        {inputError !== '' && (
                          <Text id="input-amount-error" textAlign="left" color="#FF2200" fontSize="12px">
                            {inputError}{' '}
                            {inputError.toLowerCase().includes('kyc') && (
                              <p>
                                Please{' '}
                                <u style={{ color: 'blue', cursor: 'pointer' }} onClick={() => goToKycLevel()}>
                                  click here
                                </u>{' '}
                                to upgrade
                              </p>
                            )}
                          </Text>
                        )}
                      </FullBox>
                    </FullBox>
                    <FullBox style={{ display: 'flex', position: 'relative', width: '100%' }}>
                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        <TextInput
                          id="single-transfer-select-beneficiary"
                          label="Beneficary list"
                          placeholder="Search by typing beneficary name"
                          name="Beneficiaries"
                          value={beneficiaryName}
                          onChange={(e) => filterBeneficiaries(e.target.value)}
                          width="100%"
                          style={{ marginBottom: '10px' }}
                          onClick={() => {
                            setShowDropDown(!showDropDown);
                          }}
                        />
                      </Formik>
                    </FullBox>

                    {showDropDown && (
                      <FullBox
                        mb="20px"
                        width="100%"
                        bg="white"
                        style={{
                          border: '1px solid #EEEEEE',
                          borderRadius: '5px',
                          padding: '20px',
                          overflow: 'scroll',
                          height: '190px',
                        }}
                      >
                        {filteredBeneficiariesList.map((list, index) => (
                          <div
                            id="saved-beneficiary-dropdown"
                            key={index}
                            style={{ marginBottom: '6px', cursor: 'pointer' }}
                          >
                            <Text
                              id="single-transfer-beneficiary-item"
                              color="#121212"
                              fontSize="16px"
                              onClick={() => {
                                setBankCode(list.bankCode);
                                setBeneficiaryName(list.name);
                                setBankName(list.bankName);
                                setAccountNumber(list.accountNo);
                                setShowDropDown(false);
                              }}
                            >
                              {list.name}
                            </Text>
                            <Text color="#717171" fontSize="12px">
                              {list.bankName} - {list.accountNo}
                            </Text>
                          </div>
                        ))}
                      </FullBox>
                    )}

                    <FullBox width="100%" mb="10px">
                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        <TextInput
                          id="single-transfer-description"
                          label="Description (Optional)"
                          placeholder=""
                          name="description"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          width="100%"
                        />
                      </Formik>
                    </FullBox>
                  </FullFlex>
                </FullBox>
              )}
              {/* Saved Beneficiary Ends */}

              <FullBox width="100%" mt="50px">
                <FullFlex alignItems="center" mb="10px">
                  <NewInfoIcon fontSize="12px" />
                  <Text id="bank-transaction-fee" color="#121212" fontSize="12px" ml="10px">
                    You will be charged {formatCurrency(fee)} for this transaction
                  </Text>
                </FullFlex>
                {amount < 1 ||
                Number.isNaN(amount) ||
                inputError !== '' ||
                bankError !== '' ||
                bankCode === '' ||
                accountLookupError !== '' ||
                (accountName === '' && beneficiaryName === '') ||
                accountNumber === '' ? (
                  <Button
                    id="single-transfer-next-step-disabled"
                    disabled
                    width="100%"
                    variant="large"
                    style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    id="single-transfer-next-step"
                    onClick={() => {
                      handleTransfer();
                      setShowConfirmModal(!showConfirmModal);
                      setShowSingleTransfer(false);
                    }}
                    width="100%"
                    variant="large"
                    style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                  >
                    Next
                  </Button>
                )}
              </FullBox>
            </FullBox>
          </>
        ) : null}
      </Modal>
      {singleTransferPayload !== null && (
        <ConfirmTransactionModal
          singleTransferPayload={singleTransferPayload}
          showConfirmModal={showConfirmModal}
          setShowConfirmModal={setShowConfirmModal}
          setBankName={setBankName}
          setAccountNumber={setAccountNumber}
          setBeneficiaryName={setBeneficiaryName}
          setAmount={setAmount}
          setStep={setStep}
          setAccountName={setAccountName}
          setDescription={setDescription}
          saveBen={saveBen}
          fee={fee}
          setSaveBen={setSaveBen}
          setSelectedData={setSelectedData}
          selectedData={selectedData && selectedData}
        />
      )}
    </FullBox>
  );
}
