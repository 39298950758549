import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const useVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const accountType = location.pathname?.split('/auth/onboardingv3')?.[1]?.split('/')?.[1] || 'account-type';

  const [isValidated, setIsValidated] = useState<{
    bvn: boolean;
    rcNumber: boolean;
  }>({
    bvn: false,
    rcNumber: false,
  });

  const getIntialValues = (accountType: string) => {
    if (accountType === 'individual') {
      return {
        bvn: '',
      };
    } else {
      return {
        bvn: '',
        rcNumber: '',
      };
    }
  };

  const individualValidationSchema = Yup.object().shape({
    bvn: Yup.string()
      .required('BVN is required')
      .min(11, 'Minimum of 11 digits')
      .max(11, 'Maximum of 11 digits')
      .trim(),
  });

  const corporateValidationSchema = Yup.object().shape({
    bvn: Yup.string()
      .required('BVN is required')
      .min(11, 'Minimum of 11 digits')
      .max(11, 'Maximum of 11 digits')
      .trim(),
    rcNumber: Yup.string()
      .required('RC Number is required')
      .min(6, 'Enter a valid RC Number')
      .max(8, 'Enter a valid RC number')
      .trim(),
  });

  const getValidationSchema = (accountType: string) => {
    if (accountType === 'individual') {
      return individualValidationSchema;
    } else {
      return corporateValidationSchema;
    }
  };

  const onSubmit = () => {
    navigate(`/auth/onboardingv3/${accountType}/business-information`);
  };

  return {
    accountType,
    initialValues: getIntialValues(accountType),
    validationSchema: getValidationSchema(accountType),
    isValidated,
    setIsValidated,
    toggleIsValidated: (key: 'bvn' | 'rcNumber') => {
      setIsValidated((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    },
    onSubmit,
  };
};

export default useVerification;
