import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../../app/store';
import { ReduxAction } from '../../../../types/redux';
import axios from '../../../../utils/http';
import { issueResolutionURL } from '../../constants';
import { FetchedIssue } from '../../defs';

interface GetAllIssuesResponse {
  message: string;
  data: FetchedIssue[] | null;
}

export const GetAllIssues = createAsyncThunk<GetAllIssuesResponse, ReduxAction, { state: RootState }>(
  '/support/get_all_issues',
  async ({ onSuccess, onFailure }, { rejectWithValue, getState }) => {
    try {
      const {
        support: {
          issueList: {
            pagination: { limit, page },
            filters: { date, status, type },
          },
        },
      } = getState();

      const from = moment(date?.[0]).startOf('day').format('yyyy/MM/DD');
      const to = moment(date?.[1]).endOf('day').format('yyyy/MM/DD');

      const { data }: { data: GetAllIssuesResponse } = await axios.get(`/${issueResolutionURL}/ticket`, {
        params: { limit, page, status, type, to, from },
      });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
