export const formatCurrency = (num: number | string, space = true) => {
  const localNumber = typeof num === 'string' ? parseFloat(num) : num;

  return Math.sign(localNumber || 0) === -1
    ? `-₦${space ? ` ` : ''}${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        .slice(1)}`
    : `₦${space ? ` ` : ''}${localNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};
export const formatFixedCharge = (num: number | string, space = true) => {
  const localNumber = typeof num === 'string' ? parseFloat(num) : num;

  return Math.sign(localNumber || 0) === -1
    ? `-₦${space ? ` ` : ''}${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        .slice(1)}`
    : `₦${space ? ` ` : ''}${localNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatTransactionType = (type: string) => {
  const splitText = type.split('_');
  const formattedText = splitText.map((text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase());
  return formattedText.join(' ');
};
