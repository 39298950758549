import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useToast } from '../../../../hooks/useToast';
import { NewStoreValues } from '../../../../types/inventory/store';
import { setInventoryStore } from '../../../../features/dashboard/slice';
import { WebBeError } from '../../../../types/error';
import createStore from '../../../../queries/inventory/store/createStore';
import { STATES } from '../../../../utils/constants';
import { Mixpanel } from '../../../../hooks/useMixpanel';
import { getUserBrowser } from '../../../../utils';

interface StoreWithStateValues {
  storeName: '';
  state: '';
  lga: '';
  description: '';
}

type HandleSubmitCreateStoreWithState = (
  values: StoreWithStateValues,
  setSubmitting: (isSubmitting: boolean) => void
) => void;

const useCreateStoreModel = ({ onClose }: { onClose: () => void }) => {
  const toast = useToast(3000);
  const [lgas, setLgas] = useState<{ text: string; value: string }[]>([]);
  const [selectedState, setSelectedState] = useState<string>('');
  const { fullUser } = useAppSelector((state) => state.auth);

  const initialValues: StoreWithStateValues = {
    storeName: '',
    state: '',
    lga: '',
    description: '',
  };

  const validationSchema = Yup.object({
    storeName: Yup.string().required('Store name is required'),
    state: Yup.string().required('State is required'),
    lga: Yup.string().required('LGA is required'),
    description: Yup.string().required('Description is required'),
  });

  const dispatch = useAppDispatch();

  const handleCreateNewStore: HandleSubmitCreateStoreWithState = async (values, setSubmitting) => {
    const finalValues: NewStoreValues = {
      storeName: values.storeName,
      storeLocation: `${values.lga},${values.state}`,
      description: values.description,
    };
    Mixpanel.track('MENU_STORE_SETUP_INITIATED', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
    });
    const { error: createError, result: createResult } = await createStore({
      ...finalValues,
    });
    setSubmitting(false);

    if (createResult) {
      dispatch(setInventoryStore(createResult.data));
      toast('success', 'You have successfully set up your store-front', 'Store set-up successfully');
      Mixpanel.track('MENU_CREATE_STORE', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
      });
      onClose();
    }

    if (createError) {
      toast(
        'error',
        (createError as WebBeError)?.data?.message || 'An error occured while trying to set up your store-front',
        'Something went wrong'
      );
      Mixpanel.track('MENU_ERROR_CREATING_STORE', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
      });
    }
  };

  const areCustomFieldsSet = (values: StoreWithStateValues) => {
    if (!values.storeName) {
      toast('error', 'Please add a store Name');
      return false;
    }
    if (!values.state) {
      toast('error', 'Please add a state');
      return false;
    }
    if (!values.lga) {
      toast('error', 'Please add an LGA');
      return false;
    }
    if (!values.description) {
      toast('error', 'Please add a descrition');
      return false;
    }
    return true;
  };

  const states = Object.keys(STATES).map((key) => ({ text: key, value: key }));

  useEffect(() => {
    if (selectedState) {
      const lgamap = STATES[selectedState].map((item) => ({ text: item, value: item }));
      setLgas(lgamap);
    }
  }, [selectedState]);

  return {
    areCustomFieldsSet,
    initialValues,
    validationSchema,
    handleCreateNewStore,
    states,
    setSelectedState,
    lgas,
    selectedState,
  };
};

export default useCreateStoreModel;
