import { useEffect } from 'react';
import { Box, Flex } from 'rebass';
import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import styled from 'styled-components';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { formatCurrency, getUserBrowser } from '../../../utils';
import { Separator } from '../../overview/styles';
import { useAppSelector } from '../../../app/hooks';
import SummaryCard from '../components/summaryCard';
import { AnalyticsSchema, MobileAnalyticsSchema } from './schemas';
import Table from '../../../components/newTable';
import useGetAnalytics from './model/useGetAnalytics';
import Loading from '../../teams/auth/components/loading';
import { AnalyticsTopSellingItems } from '../../../types/menu/menuList';

const ActivitiesHeader = styled(Box)`
  border-radius: 5px;
  background: ${({ theme }) => theme.nColors['n-light']};
  padding: 20px;
  wisth: 100%;
`;

const MenuAnalytics = () => {
  const { fullUser } = useAppSelector((state) => state.auth);

  const { isLoading, analyticsBody } = useGetAnalytics();

  useEffect(() => {
    Mixpanel.track('MENU_VIEW_ANALYTICS_PAGE', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
    });
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Flex minHeight="70vh" backgroundColor="#fff" justifyContent="center" alignItems="center">
          <Loading message="Loading..." isDefault={false} />
        </Flex>
      ) : (
        <Box>
          <ActivitiesHeader>
            <Typography variant="n-text4">Activities</Typography>
          </ActivitiesHeader>
          <Separator height="2rem" />
          <Flex style={{ gap: '21px' }} flexWrap="wrap">
            <SummaryCard
              title="Orders in Value"
              total={formatCurrency(`${(analyticsBody?.data.totalRevenue as number) || 0}`)}
              icon={<NIcons icon={IconsEnum.OUTLINE_PAY} />}
            />
            <SummaryCard
              title="All Items"
              total={`${(analyticsBody?.data.totalItem as number) || 0}`}
              icon={<NIcons icon={IconsEnum.OUTLINE_LIST} />}
            />
            <SummaryCard
              title="Orders in Volume"
              total={`${(analyticsBody?.data.totalOrder as number) || 0}`}
              icon={<NIcons icon={IconsEnum.OUTLINE_CHECKLIST} />}
            />
          </Flex>

          <Box>
            <Box mt="30px" mb="16px">
              <Typography variant="n-text4" color="dark">
                Best Selling Items
              </Typography>
            </Box>
            <Table
              schema={AnalyticsSchema}
              mobileSchema={MobileAnalyticsSchema}
              data={analyticsBody?.data.topSellingItems as AnalyticsTopSellingItems[]}
              emptyText="You don’t have any yet"
              flatBottom
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MenuAnalytics;
