import { Typography } from 'kudi-component-library';
import styled from 'styled-components';

export const QRHeaderButtonWrapper = styled.div`
  cursor: pointer;
`;

export const QRnModalText = styled(Typography)`
  width: 100%;
  text-align: left;
  padding: 0 10px;
`;

export const QRModalBr = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.nColors['n-grey1']};
  width: 100%;
  position: relative;
`;

export const QRModalContent = styled.div`
  height: 100%;
  width: 100%;
  max-height: 70vh;
  overflow: scroll;
  text-align: center;
  padding-bottom: 24px;

  .under-text {
    display: flex;
    justify-content: center;
  }
`;

export const QRModalBody = styled.div`
  background: ${({ theme }) => theme.nColors['n-grey0']};
  width: 100%;
  height: 351px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QRBgWrapper = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 300px;
  background-color: ${({ theme }) => theme.nColors['n-yellow']};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;
