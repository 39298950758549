import { Store } from '../../../types/inventory/store';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface StoreResponse extends ApiResponse {
  data: Store;
}

const fetchStore = async () => {
  try {
    const result: StoreResponse = await axios.get('/webbe/v1/storehouse/stores');
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchStore;
