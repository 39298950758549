import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NTheme as theme } from 'kudi-component-library';
import { fetchTransactionSummary } from './thunkActions';
import { OverviewCharts, OverviewLineChart, OverviewPieChart } from '../../types/overviewCharts';

const sumValues = (arr: Array<number>) => arr.reduce((prev, curr) => (prev ? prev + curr : 0 + curr), 0);

interface InitialState {
  chartsData: OverviewCharts;
  loading: boolean;
}

const initialState: InitialState = {
  chartsData: {
    lineChart: [],
    pieChart: [],
    piechartV2: [],
  },
  loading: false,
};

export const overviewChartsSlice = createSlice({
  name: 'overviewCharts',
  initialState,
  reducers: {
    updateCharts: (state, action: PayloadAction<OverviewCharts>) => {
      state.chartsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch transaction summary
    builder
      .addCase(fetchTransactionSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransactionSummary.fulfilled, (state, { payload }) => {
        let lineChartData: OverviewLineChart[] = [];
        let pieChartData: OverviewPieChart[] = [];
        let piechartDataV2: OverviewPieChart[] = [];

        if (payload.graphData) {
          lineChartData = payload?.graphData?.daySummary.map((day: string, i: number) => ({
            period: day || '',
            value: payload?.graphData ? payload.graphData.totalValue[i] / 1000 : 0,
            volume: (payload?.graphData?.totalVolume[i] as string) || 0,
          }));

          // Will replace this with piechartDataV2 when we are deploying to production
          pieChartData = [
            { type: 'Withdrawals', value: sumValues(payload?.graphData.withdrawalValue), color: '#FFCC00' },
            { type: 'Transfers', value: sumValues(payload?.graphData.transferValue), color: '#121212' },
            { type: 'QR', value: sumValues(payload?.graphData.payByQrValue), color: '#717171' },
            { type: 'Purchase', value: sumValues(payload?.graphData.purchaseValue), color: '#F2C4C4C4' },
            { type: 'Pay by transfer', value: sumValues(payload?.graphData.payByTransferValue), color: '#11BB00' },
          ];

          piechartDataV2 = [
            {
              type: 'Card transactions',
              value: sumValues(payload?.graphData.withdrawalValue) + sumValues(payload?.graphData.purchaseValue),
              color: theme.nColors['n-yellow'],
            },
            {
              type: 'Transfer Inflow',
              value:
                sumValues(payload?.graphData.payByTransferValue) +
                sumValues(payload?.graphData.payByQrValue) +
                sumValues(payload?.graphData.walletTopupValue),
              color: theme.nColors['n-grey4'],
            },
            {
              type: 'Transfer Outflow',
              value: sumValues(payload?.graphData.transferValue),
              color: theme.nColors['n-dark'],
            },
          ];

          state.chartsData.pieChart = pieChartData;
          state.chartsData.piechartV2 = piechartDataV2;
        }

        if (payload.data && !Array.isArray(payload.data)) {
          lineChartData = payload?.data?.daySummary.map((day: string, i: number) => ({
            period: day || '',
            value: payload?.data ? payload.data.totalValue[i] / 1000 : 0,
            volume: (payload?.data?.totalVolume[i] as string) || 0,
          }));
        }

        state.chartsData.lineChart = lineChartData;
        state.loading = false;
      })
      .addCase(fetchTransactionSummary.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { updateCharts } = overviewChartsSlice.actions;
export default overviewChartsSlice.reducer;
