import { useEffect } from 'react';
import { IssueTypesWrapper } from '../../styles';
import IssueCreationItem from '../base/issue_creation_item';
import { clearTransactions } from '../../reducer/slice';
import useCategoryAndTypeSelection from '../../models/categorySelection';
import { useAppDispatch } from '../../../../app/hooks';

const Category = () => {
  const dispatch = useAppDispatch();

  const { List } = useCategoryAndTypeSelection();

  useEffect(() => {
    dispatch(clearTransactions());
  }, [dispatch]);

  return (
    <IssueTypesWrapper>
      {List?.map((item) => (
        <IssueCreationItem {...item} key={`issue-category-${Math.random()}`} />
      ))}
    </IssueTypesWrapper>
  );
};
export default Category;
