import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'kudi-component-library';
import Modal from '../../../components/Modal';
import {
  ActionModalDescription,
  ActionModalTitle,
  ActionModalWrapper,
  TransferWrapper,
  TransferValue,
  TransferNumber,
} from '../styles';
import { Duplicate } from '../../../assets/svg';

import { fetchKTA } from '../../../features/banking/balance/slice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Copied from './Copied';

const AddMoneyModal = ({ onClose, showModal }: { onClose: () => void; showModal: boolean }) => {
  const accountRef = useRef(null);
  const dispatch = useAppDispatch();
  const [copied, setCopied] = useState<boolean>(false);

  const handleIconChange = () => {
    setTimeout(() => setCopied(false), 500);
  };

  const { parentBusinessId } = useAppSelector((state) => state.auth);
  const { KTA } = useAppSelector((state) => state.banking.balance);
  const onCopy = () => {
    navigator.clipboard.writeText(KTA?.[0]?.accountNumber || '');
    setCopied(true);
    handleIconChange();
  };

  const handleFetchKTA = useCallback(() => {
    if (!parentBusinessId) return;
    const onSuccess = (data: string) => {};
    const onFailure = () => {};

    dispatch(fetchKTA({ values: { id: parentBusinessId }, onFailure, onSuccess }));
  }, [parentBusinessId, dispatch]);

  useEffect(() => {
    handleFetchKTA();
  }, [handleFetchKTA]);

  return (
    <Modal showModal={showModal} onClose={onClose}>
      <ActionModalWrapper>
        <ActionModalTitle variant="n-text5" color="dark">
          Bank Transfer
        </ActionModalTitle>
        <ActionModalDescription variant="n-text2" color="grey4">
          Transfer money to the bank account details below
        </ActionModalDescription>
        <TransferWrapper>
          <span>Bank</span>
          <TransferValue>{KTA?.[0]?.bankName}</TransferValue>
          <span>Account number</span>
          <TransferNumber onClick={onCopy}>
            <span ref={accountRef}>{KTA?.[0]?.accountNumber} </span>
            {copied ? <Copied /> : <Duplicate />}
          </TransferNumber>
          <span>Account name</span>
          <TransferValue>{KTA?.[0]?.accountName}</TransferValue>
        </TransferWrapper>
        <Button variant="medium" width="100%" onClick={onClose}>
          Ok
        </Button>
      </ActionModalWrapper>
    </Modal>
  );
};

export default AddMoneyModal;
