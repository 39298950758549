/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/no-cycle */
import React from 'react';
import { Button, Text } from 'kudi-component-library';
import moment from 'moment';
import Modal from '../../../components/Modal';
import { FullBox, FullFlex } from '../../../styles/generic';
import { BeneficiariesData } from '../../../types/transactions';
import SingleTransfer from '../singleandbulktransfer/singletransfermodal';
import EditBeneficiaryModal from './editbeneficiarymodal';
import DeleteBeneficiaryModal from './deletebeneficiarymodal';

export default function Beneficiarymodal({
  showResponseModal,
  showDetailsModal,
  setShowDetailsModal,
  setShowResponseModal,
  beneficiary,
  reload,
  setReload,
}: {
  showResponseModal: boolean;
  showDetailsModal: boolean;
  setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  beneficiary: BeneficiariesData;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  reload?: boolean;
}) {
  const [showSingleTransfer, setShowSingleTransfer] = React.useState<boolean>(false);
  const [showEditBeneficiaryModal, setShowEditBeneficiaryModal] = React.useState(false);
  const [showDeleteBeneficiaryModal, setShowDeleteBeneficiaryModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<BeneficiariesData | null>(null);

  return (
    <Modal showModal={showDetailsModal} onClose={() => setShowDetailsModal(false)}>
      <Text
        textAlign="center"
        fontSize="22px"
        color="#121212"
        style={{ borderBottom: '1px solid #C6C6C6', paddingBottom: '20px' }}
      >
        Beneficiary Details
      </Text>
      <FullFlex p="20px 10px" justifyContent="flex-start">
        <FullBox>
          <Text style={{ fontWeight: 'normal', fontSize: '16px', color: '#999999' }}>Date</Text>
          <Text style={{ fontWeight: 'normal', fontSize: '12px', color: '#717171' }}>
            {moment(beneficiary.createdAt).date()} {moment(beneficiary.createdAt).format('MMM')},{' '}
            {moment(beneficiary.createdAt).year()}, {moment(beneficiary.createdAt).format('LT')}
          </Text>
        </FullBox>
      </FullFlex>
      <hr />
      <FullFlex p="20px 10px" justifyContent="flex-start">
        <FullBox>
          <Text style={{ fontWeight: 'normal', fontSize: '16px', color: '#999999' }}>Account name</Text>
          <Text style={{ fontWeight: 'normal', fontSize: '12px', color: '#717171' }}>{beneficiary?.name}</Text>
        </FullBox>
      </FullFlex>
      <hr />
      <FullFlex p="20px 10px" justifyContent="flex-start">
        <FullBox>
          <Text style={{ fontWeight: 'normal', fontSize: '16px', color: '#999999' }}>Account number</Text>
          <Text style={{ fontWeight: 'normal', fontSize: '12px', color: '#717171' }}>{beneficiary?.accountNo}</Text>
        </FullBox>
      </FullFlex>
      <hr />
      <FullFlex p="20px 10px" justifyContent="flex-start">
        <FullBox>
          <Text style={{ fontWeight: 'normal', fontSize: '16px', color: '#999999' }}>Beneficiary nickname</Text>
          <Text style={{ fontWeight: 'normal', fontSize: '12px', color: '#717171' }}>
            {beneficiary?.nickName ? beneficiary?.nickName : 'N/A'}
          </Text>
        </FullBox>
      </FullFlex>
      <hr />

      <FullBox mt="50px">
        <Button
          id="transfer-beneficiary-btn"
          onClick={() => {
            setSelectedData(beneficiary);
            setShowSingleTransfer(!showSingleTransfer);
          }}
          width="100%"
          variant="small"
          style={{ backgroundColor: '#FFCC00', color: '#121212', marginBottom: '10px', height: '40px' }}
        >
          Transfer
        </Button>
        <Button
          id="edit-beneficiary-btn"
          onClick={() => {
            setSelectedData(beneficiary);
            setShowEditBeneficiaryModal(!showEditBeneficiaryModal);
          }}
          width="100%"
          variant="small"
          style={{ backgroundColor: '#121212', color: '#FFCC00', marginBottom: '10px', height: '40px' }}
        >
          Edit
        </Button>
        <Button
          id="delete-beneficiary-btn"
          onClick={() => {
            setShowDeleteBeneficiaryModal(!showDeleteBeneficiaryModal);
          }}
          width="100%"
          variant="small"
          style={{
            backgroundColor: '#FFFFFF',
            color: '#121212',
            marginBottom: '10px',
            height: '40px',
            border: '1px solid #ccc',
          }}
        >
          Delete
        </Button>
      </FullBox>

      {showSingleTransfer && selectedData !== null && (
        <SingleTransfer
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          showSingleTransfer={showSingleTransfer}
          setShowSingleTransfer={setShowSingleTransfer}
        />
      )}

      {selectedData !== null && !showSingleTransfer && setReload && (
        <EditBeneficiaryModal
          showResponseModal={showResponseModal}
          setShowResponseModal={setShowResponseModal}
          reload={reload}
          setReload={setReload}
          showEditBeneficiaryModal={showEditBeneficiaryModal}
          setShowEditBeneficiaryModal={setShowEditBeneficiaryModal}
          beneficiary={selectedData}
        />
      )}
      {showDeleteBeneficiaryModal && selectedData !== null && (
        <DeleteBeneficiaryModal
          reload={reload}
          setReload={setReload}
          beneficiary={selectedData}
          showDeleteBeneficiaryModal={showDeleteBeneficiaryModal}
          setShowDeleteBeneficiaryModal={setShowDeleteBeneficiaryModal}
        />
      )}
    </Modal>
  );
}