import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 45px;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #0d0d0d;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #808080;
  span {
    color: #4d4d4d;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0px;
  @media ${({ theme }) => theme.media.md} {
    margin-top: 40px 0px;
  }
`;

export const VerifyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20rem;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  @media ${({ theme }) => theme.media.md} {
    margin-bottom: 40px;
  }
`;

export const ExpirationTracker = styled.div`
  background: #f5fff2;
  border: 1px solid #d6ffcc;
  padding: 8px 24px;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  gap: 3px;
  margin-bottom: 20px;
  color: #808080;

  span {
    color: #1a1a1a;
  }
`;

export const ResendWrapper = styled.div`
  display: flex;
  p {
    color: #808080;
  }
  span {
    border-bottom: 1px solid #1a1a1a;
    cursor: pointer;
  }
`;

export const BottomBarContainer = styled.div`
  display: flex;
  gap: 5px;
  max-width: 368px;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 0 auto 20px;
`;

export const BottomBar = styled.div<{ filled?: boolean }>`
  width: 100%;
  height: 5px;
  border-radius: 4px;
  background: ${({ theme, filled }) => (filled ? theme.nColors['n-yellow'] : theme.nColors['n-grey2'])};
`;
