import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { StepsWrapper } from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import { CreationStage } from '../../defs';

const Steps = () => {
  const {
    state: {
      support: {
        issueCreation: { currentCreationStage },
      },
    },
  } = useRedux();

  const stageMap: Record<CreationStage, number> = {
    category: 1,
    type: 2,
    transactions: 3,
  };

  return currentCreationStage ? (
    <StepsWrapper>
      <NIcons icon={IconsEnum.OUTLINE_CHECK} />
      <Typography fontSize="12px" lineHeight="18px" fontWeight={500}>
        Step {stageMap[currentCreationStage]} of 3
      </Typography>
    </StepsWrapper>
  ) : null;
};

export default Steps;
