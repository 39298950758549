/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable prettier/prettier */

import React, { useEffect, useCallback } from 'react';
import moment from 'moment';
import { Button, Text } from 'kudi-component-library';
import { useFlags } from 'flagsmith/react';
import { FullBox, FullFlex, PageWrapper } from '../../styles/generic';
import useSetPageTitle from '../../hooks/useSetPageTitle';
import { BeneficiariesData, TransferList } from '../../types/transactions';
import Transferstable from './singleandbulktransfer/transferstable';
import BeneficiariesTable from './beneficiaries/beneficiariestable';
import { Plus } from '../../assets/svg';
import AddBeneficiaryModal from './beneficiaries/addbeneficiarymodal';
import TransferToNomba from './nombatransfer/transfertonomba';
import {
  fetchBeneficiaries,
  FetchBeneficiariesResponse,
  FetchTransfersResponse,
  fetchTransfers,
  searchBeneficiaries,
  FilterBeneficiariesResponse,
  filterBeneficiariesBackend,
  FilterTransfersResponse,
  filterTransfersBackend,
  fetchTransfersRequest,
} from '../../features/transfers/thunkActions';
import { useAppDispatch } from '../../app/hooks';
import Filter from '../../components/newFilter';
import { TransferFilterConfigV2, BeneficiariesFilterConfigV2, TransferRequestsFilterConfig } from './filterConfigs';
import TransferToBank from './singleandbulktransfer/transfertobank';
import { useToast } from '../../hooks/useToast';
import { TOAST_TIMER, FLAGSMITH_FEATURES } from '../../utils/constants';
import { Separator } from '../overview/styles';
import Transferstabs from './TransfersTabs';
import { AppliedFilter } from '../../components/newFilter/defs';
import TransferRequest from './transfersrequest/transferrequest';
import { TransferRequestResponse } from './transfersrequest/utils';
import { useFetch2faSettings } from '../../queries/two-factor';

interface Options {
  text: string;
  value: string;
  position: number;
}

interface QueryParams {
  status: string;
  bank?: string;
  startDate: string;
  endDate: string;
}

export default function ViewTransfers() {
  const [showPopup, setShowPopup] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [reload, setReload] = React.useState(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<TransferList | null>(null);
  const [showAddBeneficiaryModal, setShowAddBeneficiaryModal] = React.useState(false);
  const [showTransferToNombaModal, setShowTransferToNombaModal] = React.useState(false);
  const [showTransferToBankModal, setShowTransferToBankModal] = React.useState(false);
  const [allTransfers, setAllTransfers] = React.useState<TransferList[]>([]);
  const [allBeneficiaries, setAllBeneficiaries] = React.useState<BeneficiariesData[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [activeTab, setAtiveTab] = React.useState<number>(0);
  const [newQueries, setNewQueries] = React.useState<QueryParams | null>(null);
  const [transferRequests, setTransferRequests] = React.useState<TransferRequestResponse | null>(null);

  const toast = useToast(TOAST_TIMER);
  const dispatch = useAppDispatch();

  const { web_transfers: webTransfer } = useFlags([FLAGSMITH_FEATURES.WEB_TRANSFER]);

  useSetPageTitle('TRANSFER');

  const { response } = useFetch2faSettings();
  const bankingEntityType = localStorage.getItem('bankingEntityType');
  const iamTokenRole = JSON.parse(localStorage.getItem('IAMuserRole') as '{}') as string;

  const btnText =
    bankingEntityType === 'BUSINESS' &&
    response?.data?.twoStepVerificationEnabled &&
    iamTokenRole !== 'ADMIN' &&
    iamTokenRole !== 'OWNER'
      ? 'Raise Transfer Request'
      : 'Transfer Money';

  const TabOptions: Array<Options> = [
    { text: 'Transfer History', value: 'transfer', position: 0 },
    ...[{ text: 'Transfer Request', value: 'transfer-requests', position: 1 }],
    // { text: 'Transfer Request', value: 'transfer-requests', position: 1 },
    { text: 'Manage Beneficiaries', value: 'beneficiaries', position: 2 },
  ];

  const setActive = (tab: number) => {
    setAtiveTab(tab);
  };

  const handleActiveTab = (index: number) => {
    setShowPopup(false);
    setActive(index);
  };

  const handleApplyFilters = (filterItems: AppliedFilter[]) => {
    let newFIlters = {};
    filterItems.forEach((item: AppliedFilter) => {
      const { query, value } = item;
      if (query === 'dateRange') {
        newFIlters = {
          ...newFIlters,
          startDate: moment.utc(value[0], 'ddd, DD MMM YYYY HH:mm:ss [GMT]').format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment.utc(value[1], 'ddd, DD MMM YYYY HH:mm:ss [GMT]').format('YYYY-MM-DD HH:mm:ss'),
        };
      } else {
        newFIlters = {
          ...newFIlters,
          [query]: value,
        };
      }
    });

    return setNewQueries(newFIlters as QueryParams);
  };

  const handleClearFilters = () => {
    setNewQueries(null);
    setReload(!reload);
  };
  // fetch transfers history
  const fetchTransfersMain = () => {
    setLoading(true);
    const onFailure = () => {
      toast('error', 'Unable to fetch transfers');
      setLoading(false);
    };

    const onSuccess = (data: FetchTransfersResponse) => {
      if (data?.data === null) {
        return;
      }
      setLoading(false);
      setAllTransfers(data?.data?.transfers);
    };

    dispatch(
      fetchTransfers({
        page: currentPage,
        onSuccess,
        onFailure,
      })
    );
  };
  // fetch transfer requests
  const fetchTransfersRequestMain = () => {
    setLoading(true);
    const onFailure = (error: { data: { message: string } }) => {
      toast('error', error?.data?.message || 'Unable to fetch transfers request');
      setLoading(false);
    };

    const onSuccess = (data: TransferRequestResponse) => {
      setLoading(false);
      setTransferRequests(data);
    };

    dispatch(
      fetchTransfersRequest({
        page: currentPage,
        size: 10,
        dateFrom: '',
        dateTo: '',
        status: '',
        onSuccess,
        onFailure,
      })
    );
  };
  // fetch beneficiaries
  const fetchBeneficiariesMain = () => {
    setLoading(true);
    const onFailure = () => {
      toast('error', 'Unable to fetch beneficiaries');
      setLoading(false);
    };

    const onSuccess = (data: FetchBeneficiariesResponse) => {
      setLoading(false);
      setAllBeneficiaries(data.data);
    };

    dispatch(fetchBeneficiaries({ page: currentPage, onSuccess, onFailure }));
  };

  useEffect(() => {
    if (activeTab === 0) {
      fetchTransfersMain();
    } else if (activeTab === 1) {
      fetchTransfersRequestMain();
    } else {
      fetchBeneficiariesMain();
    }
  }, [dispatch, activeTab, currentPage, reload]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  // handle search
  const handleSearch = (val: string) => {
    if (activeTab === 0) {
      const onFailure = (responseMessage: { data: { message: string } }) => {
        toast('error', responseMessage.data.message || 'Something went wrong');
      };

      const onSuccess = (data: FetchTransfersResponse) => {
        if (data?.data === null) {
          toast('error', 'No result found');
          setAllTransfers([]);
          return;
        }
        setAllTransfers(data?.data?.transfers);
      };
      dispatch(
        fetchTransfers({
          filter: `${val}`,
          page: currentPage,
          onSuccess,
          onFailure,
        })
      );
    } else if (activeTab === 2) {
      const onFailure = (responseMessage: { data: { message: string } }) => {
        toast('error', responseMessage.data.message || 'Something went wrong');
      };

      const onSuccess = (data: FetchBeneficiariesResponse) => {
        if (data?.data === null) {
          toast('error', 'No result found');
          setAllBeneficiaries([]);
          return;
        }
        setAllBeneficiaries(data?.data);
      };
      dispatch(
        searchBeneficiaries({
          filter: val,
          page: currentPage,
          onSuccess,
          onFailure,
        })
      );
    } else {
      const onFailure = (error: { data: { message: string } }) => {
        toast('error', error?.data?.message || 'Unable to fetch transfers request');
      };

      const onSuccess = (data: TransferRequestResponse) => {
        setTransferRequests(data);
      };

      dispatch(
        fetchTransfersRequest({
          page: currentPage,
          size: 10,
          dateFrom: '',
          dateTo: '',
          status: '',
          onSuccess,
          onFailure,
        })
      );
    }
  };

  const handleClearSearch = () => {
    setReload(!reload);
  };

  const handleFilters = useCallback(() => {
    if (activeTab === 0) {
      const onFailure = (responseMessage: { data: { message: string } }) => {
        toast('error', responseMessage.data.message || 'Something went wrong');
      };

      const onSuccess = (data: FilterTransfersResponse) => {
        if (data?.data === null) {
          toast('error', 'No result found');
          return;
        }
        setAllTransfers(data?.data);
      };

      dispatch(
        filterTransfersBackend({
          status: newQueries?.status || '',
          startDate: newQueries?.startDate || '',
          endDate: newQueries?.endDate || '',
          page: currentPage,
          onSuccess,
          onFailure,
        })
      );
    } else if (activeTab === 1) {
      const onFailure = (responseMessage: { data: { message: string } }) => {
        toast('error', responseMessage.data.message || 'Something went wrong');
      };

      const onSuccess = (data: TransferRequestResponse) => {
        setLoading(false);
        setTransferRequests(data);
      };

      dispatch(
        fetchTransfersRequest({
          page: currentPage,
          size: 10,
          dateFrom: newQueries?.startDate || '',
          dateTo: newQueries?.endDate || '',
          status: newQueries?.status || '',
          onSuccess,
          onFailure,
        })
      );
    } else {
      const onFailure = (responseMessage: { data: { message: string } }) => {
        toast('error', responseMessage.data.message || 'Something went wrong');
      };

      const onSuccess = (data: FilterBeneficiariesResponse) => {
        if (data?.data === null) {
          toast('error', 'No result found');
          return;
        }
        setAllBeneficiaries(data?.data);
      };

      dispatch(
        filterBeneficiariesBackend({
          bankName: newQueries?.bank || '',
          startDate: newQueries?.startDate || '',
          endDate: newQueries?.endDate || '',
          page: currentPage,
          onSuccess,
          onFailure,
        })
      );
    }
  }, [dispatch, newQueries]);

  useEffect(() => {
    handleFilters();
  }, [newQueries]);

  function useOutsideAlerter(
    ref: React.MutableRefObject<HTMLDivElement | null>,
    ref2: React.MutableRefObject<HTMLDivElement | null>
  ) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          ref2.current &&
          !ref2.current.contains(event.target as Node)
        ) {
          setShowPopup(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, ref2]);
  }
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const wrapperRef2 = React.useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, wrapperRef2);

  const placeholderText =
    activeTab === 0
      ? 'Search by reference'
      : activeTab === 1
      ? 'Search in transactions'
      : 'Search by name or account number';

  const defaultFilterConfig =
    activeTab === 0
      ? TransferFilterConfigV2
      : activeTab === 1
      ? TransferRequestsFilterConfig
      : BeneficiariesFilterConfigV2;

  return (
    <PageWrapper>
      {/* Top Bar */}
      <FullFlex width="100%" justifyContent="space-between" alignItems="center">
        <Text fontSize="30px" fontWeight="bold" color="#121212">
          Transfer
        </Text>
        <FullBox>
          {webTransfer.enabled
            ? activeTab === 0 && (
                <FullBox ref={wrapperRef2} style={{ position: 'relative' }}>
                  <Button
                    id="make-transfer-btn"
                    width="100%"
                    height="45px"
                    variant="large"
                    style={{ backgroundColor: '#FFCC00', color: '#121212' }}
                    onClick={() => {
                      setShowPopup(!showPopup);
                    }}
                  >
                    <Text fontSize="12px">{btnText}</Text>
                  </Button>
                </FullBox>
              )
            : activeTab === 0 && (
                <FullBox style={{ position: 'relative' }}>
                  <Button
                    id="make-transfer-btn-disabled"
                    width="100%"
                    height="45px"
                    variant="large"
                    onMouseEnter={() => toast('error', 'Transfer is currently disabled. Please try again later.')}
                    style={{ backgroundColor: '#FFCC00', opacity: '0.3', color: '#121212' }}
                  >
                    <Text fontSize="12px">Money Transfer Disabled</Text>
                  </Button>
                </FullBox>
              )}

          {activeTab === 2 && (
            <FullBox>
              <Button
                id="add-beneficiary-btn"
                width="100%"
                height="45px"
                variant="large"
                style={{ backgroundColor: '#FFCC00', color: '#121212', lineHeight: '0' }}
                onClick={() => setShowAddBeneficiaryModal(!showAddBeneficiaryModal)}
              >
                <FullFlex justifyContent="space-between" alignItems="center">
                  <Plus />
                  <Text fontSize="12px">Add beneficiary</Text>
                </FullFlex>
              </Button>

              <AddBeneficiaryModal
                reload={reload}
                setReload={setReload}
                showAddBeneficiaryModal={showAddBeneficiaryModal}
                setShowAddBeneficiaryModal={setShowAddBeneficiaryModal}
              />
            </FullBox>
          )}

          {showPopup && activeTab === 0 && !showTransferToBankModal && !showTransferToNombaModal && (
            <div
              ref={wrapperRef}
              style={{ position: 'absolute', top: '5rem', right: '0rem', width: '231px', zIndex: '10' }}
            >
              <FullBox bg="white" p="20px 19px" borderRadius="5px" border="1px solid #EDF1FC">
                <FullFlex flexDirection="column" alignItems="center" justifyContent="flex-center">
                  <FullBox>
                    <Text
                      id="transfer-to-bank"
                      fontSize="14px"
                      fontWeight="regular"
                      color="#121212"
                      mb="19px"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowTransferToBankModal(!showTransferToBankModal)}
                    >
                      Transfer to Bank Account
                    </Text>

                    <Text
                      id="transfer-to-nomba"
                      fontSize="14px"
                      fontWeight="regular"
                      color="#121212"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowTransferToNombaModal(!showTransferToNombaModal)}
                    >
                      Transfer to Nomba Account
                    </Text>
                  </FullBox>
                </FullFlex>
              </FullBox>
            </div>
          )}
          <TransferToBank
            setShowPopup={setShowPopup}
            showTransferToBankModal={showTransferToBankModal}
            setShowTransferToBankModal={setShowTransferToBankModal}
          />
          <TransferToNomba
            setShowPopup={setShowPopup}
            showTransferToNombaModal={showTransferToNombaModal}
            setShowTransferToNombaModal={setShowTransferToNombaModal}
          />
        </FullBox>
      </FullFlex>

      <Transferstabs options={TabOptions} setActive={(index) => handleActiveTab(index)} activeTab={activeTab} />

      <Separator height="4rem" />
      <Filter
        searchPlaceholder={placeholderText}
        filterConfig={defaultFilterConfig}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        onSearch={(val: string) => handleSearch(val)}
        onClearSearch={handleClearSearch}
      />
      <Separator height="2rem" />

      {/* Tables */}
      {activeTab === 0 && (
        <Transferstable
          transfersData={allTransfers}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          showDetailsModal={showDetailsModal}
          setShowDetailsModal={setShowDetailsModal}
          loading={loading}
          handlePaginationChange={handlePaginationChange}
        />
      )}

      {activeTab === 1 && (
        <TransferRequest
          currentPage={currentPage}
          loading={loading}
          setReload={setReload}
          handlePaginationChange={handlePaginationChange}
          data={transferRequests}
        />
      )}

      {activeTab === 2 && (
        <BeneficiariesTable
          reload={reload}
          loading={loading}
          setReload={setReload}
          beneficiariesData={allBeneficiaries}
          handlePaginationChange={handlePaginationChange}
        />
      )}
    </PageWrapper>
  );
}
