/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Button, Typography, OtpInput } from 'kudi-component-library';
import Modal from '../../../components/Modal';
import { HeaderWrapper, TwoFactorModalWrapper } from '../../settings/styles';
import { Spinner } from '../../../assets/svg';

const TransactionPinModal = ({
  showModal,
  otpError,
  transactionPin,
  isSubmitting,
  handleCloseModal,
  confirmTransfer,
  handleOtpChange,
}: {
  showModal: boolean;
  isSubmitting: boolean;
  otpError: string;
  transactionPin: string;
  handleCloseModal: () => void;
  confirmTransfer: () => void;
  handleOtpChange: (val: string) => void;
}) => (
  <Modal showModal={showModal} onClose={handleCloseModal}>
    <TwoFactorModalWrapper>
      <HeaderWrapper>
        <Typography fontSize="24px" fontWeight={700}>
          Enter transaction pin
        </Typography>
        <Typography color="n-grey3" style={{ marginTop: '24px' }}>
          Enter your password to confirm this action
        </Typography>
      </HeaderWrapper>
      <OtpInput
        label="Enter transaction PIN"
        type="password"
        width="100%"
        name="otp"
        value={transactionPin || ''}
        onChange={(e) => handleOtpChange(e.target.value)}
        inputsNumber={4}
        error={otpError || ''}
      />

      <Button
        disabled={transactionPin.length < 4 || isSubmitting}
        style={{ marginTop: '8rem' }}
        variant="medium"
        width="100%"
        onClick={() => confirmTransfer()}
      >
        {isSubmitting ? <Spinner /> : 'Confirm transfer'}
      </Button>
    </TwoFactorModalWrapper>
  </Modal>
);

export default TransactionPinModal;
