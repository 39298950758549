/* eslint-disable no-shadow */

export enum TransactionTypes {
  topup = 'Airtime Topup',
  transfer = 'Funds Transfer',
  gotv = 'GOtv Subscription',
  dstv = 'DStv Subscription',
  phcn = 'PHCN Bill Payment',
  smile = 'Smile Bill Payment',
  ntel = 'Ntel Bill Payment',
  jamb = 'Jamb Subscription',
  withdrawal = 'Cash Withdrawal',
  wallet_topup = 'Transfer Payment',
  p2p = 'P2P',
  data = 'Data Subscription',
  pos_purchase = 'Pos Purchase',
  referral_earning = 'Referral Earning',
  cash_in = 'Voucher Cash In',
  cash_out = 'Voucher Cash out',
  insurance = 'Insurance',
  betting = 'Betting',
  waec = 'WAEC',
  collections = 'Merchant Collection',
  kshock_disbursement = 'KShock Loan Disbursement',
  kshock_withdrawal_lp = 'KShock Loan Provider Withdrawal',
  kshock_repayment = 'KShock Loan Repayment',
  kshock_overdue_interest = 'KShock Overdue Interest',
  saving_deposit = 'Savings Deposit',
  saving_withdrawal = 'Savings Withdrawal',
  purchase = 'Card Payment',
  pos_refund = 'POS Refund',
  pay_by_qr = 'Pay By QR',
  pay_by_transfer = 'Pay By Transfer',
  online_checkout = 'Online Checkout',
  online_checkout_international_card = 'Online Checkout International Card',
  pos_installment_payment = 'POS Installment Payment',
  qrt_credit = 'QRT Credit',
  qrt_debit = 'QRT Debit',
  startimes = 'Startimes Subscription',
  vact_transfer = 'Virtual Account Transfer',
}

export enum TransactionStatus {
  NEW = 'Pending',
  PENDING_PAYMENT = 'Pending',
  PENDING_BILLING = 'Pending',
  PENDING_FRAUD_CHECK = 'Pending',
  BILLER_PURCHASE_FAILED = 'Pending',
  SUCCESS = 'Success',
  PAYMENT_SUCCESSFUL = 'Success',
  PAYMENT_FAILED = 'Failed',
  CANCELLED = 'Failed',
  REVERSED_BY_VENDOR = 'Failed',
}

export enum DisputeMerchantStatus {
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  AWAITING_FEEDBACK = 'AWAITING_FEEDBACK',
}

export enum DisputeVerdict {
  MANUAL_DECLINED = 'MANUAL_DECLINED',
  MANUAL_APPROVED = 'MANUAL_APPROVED',
}

export enum TransactionSourse {
  pos = 'Terminal',
  android_app = 'Android App',
  ios_app = 'IOS App',
  fb_messenger = 'Messenger',
  business_banking_web = 'Web',
  ussd = 'USSD',
  'k-shock' = 'Kshock',
  'non-applicable' = 'Not Applicable',
  'com' = 'Commissions',
  'watch-tower' = 'Watch Tower',
  'nqr' = 'NQR',
}

export const creditTransactionType = [
  'withdrawal',
  'wallet_topup',
  'referral_earning',
  'cash_in',
  'collections',
  'kshock_disbursement',
  'saving_deposit',
  'purchase',
  'pos_refund',
  'pay_by_qr',
  'pay_by_transfer',
  'online_checkout',
  'qrt_credit',
  'vact_transfer',
];

export const successfulTransactionStatus = ['PAYMENT_SUCCESSFUL', 'SUCCESS'];

export enum TransactionsFilterSource {
  ANDROID_APP = 'Android app',
  IOS_APP = 'IOS app',
  POS = 'POS',
  WEB = 'Web',
}

export enum TransactionsFilterStatus {
  NEW = 'New',
  PENDING_PAYMENT = 'Payment pending',
  PAYMENT_SUCCESSFUL = 'Payment successful',
  PAYMENT_FAILED = 'Payment failed',
  PENDING_BILLING = 'Pending purchase',
  BILLER_PURCHASE_FAILED = 'Pending purchase',
  SUCCESS = 'Completed successfully',
  CANCELLED = 'Cancelled',
  REFUND = 'Refunded',
  PENDING_FRAUD_CHECK = 'Pending fraud check',
  REVERSED_BY_VENDOR = 'Reversed',
}

export enum DisputesChannel {
  ONLINE_CHECKOUT = 'ONLINE_CHECKOUT',
  POS = 'POS',
}

export enum DisputesFilterStatus {
  DECLINED = 'Declined',
  APPROVED = 'Approved',
}

export enum DisputeResolution {
  ACCEPT = 'APPROVED',
  DECLINE = 'DECLINED',
}

export enum PaymentLinkStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
export enum PaymentLinkType {
  COLLECT_PAYMENT = 'Collect a payment',
  SELL_ITEM = 'Sell an item',
  ACCEPT_DONATION = 'Accept a donation',
  SELL_EVENT = 'Sell an event',
}
export enum PaymentLinkFrequency {
  ONE_TIME = 'One time',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi_weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  TWICE_A_YEAR = 'Twice a year',
  YEARLY = 'Yearly',
}
