import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { TransactionDataPoint } from '../../../../types/transactions';
import { WebBeError } from '../../../../types/error';
import fetchTransaction from '../../../../queries/banking/transactions/fetchTransaction';
import { useToast } from '../../../../hooks/useToast';
import { TOAST_TIMER } from '../../../../utils/constants';
import { FetchTransactionResponse } from '../../../../types/banking/transactions';
import { getDetailbyKey } from './schema';

const useTransactionDetailsModal = ({
  transactionRef,
  outletId,
  isOutletSettlement,
}: {
  transactionRef: string;
  outletId?: string;
  isOutletSettlement?: boolean;
}) => {
  const [transaction, setTransaction] = useState<TransactionDataPoint[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<WebBeError | null>(null);
  const [amount, setAmount] = useState<TransactionDataPoint | null>(null);
  const [status, setStatus] = useState<TransactionDataPoint | null>(null);
  const [fullTransaction, setFullTransaction] = useState<FetchTransactionResponse | null>(null);

  const { parentBusinessId } = useAppSelector((state) => state.auth);
  const toast = useToast(TOAST_TIMER);

  const handleOnClose = (onClose: () => void) => {
    setTransaction(null);
    onClose();
  };

  useEffect(() => {
    const requestController = new AbortController();

    async function handleFetchTransaction() {
      setIsLoading(true);
      const { result, error: fetchError } = await fetchTransaction({
        transactionRef,
        outletId,
        isOutletSettlement,
      });
      setFullTransaction(result);
      setIsLoading(false);

      if (result?.data.length) {
        const emptyDataPoint = { title: '', value: '' };
        const transactionArr = result.data;
        let amountObj: TransactionDataPoint = emptyDataPoint;
        let statusObj: TransactionDataPoint = emptyDataPoint;
        const transactionSubsection: TransactionDataPoint[] = [];

        transactionArr.forEach((item) => {
          if (item.title === 'Amount') {
            amountObj = item;
          } else if (item.title === 'Status') {
            statusObj = item;
          } else {
            transactionSubsection.push(item);
          }
        });
        setAmount(amountObj);
        setStatus(statusObj);

        setTransaction(transactionSubsection);
      }

      if (fetchError) {
        setError(fetchError as WebBeError);
      }
    }

    handleFetchTransaction();

    return () => requestController.abort();
  }, [parentBusinessId, transactionRef, outletId]);

  if (error) {
    toast('error', (error.data.message as string) || 'An error occured while fetching transaction');
  }

  // transaction details breakdown
  const special_item_keys = ['time created', 'transaction type', 'description', 'status', 'amount', 'user id'];

  const default_keys = ['Fee', 'transaction source'];

  const bottom_item_keys = ['transaction reference', 'session id'];

  const copyable_items = ['reference', 'stan', 'rrn'];

  const senderDetails = transaction?.filter((item) => item?.title?.toLowerCase()?.includes('sender')) || [];

  const recipientDetails = transaction?.filter((item) => item?.title?.toLowerCase()?.includes('recipient')) || [];

  const terminalDetails = transaction?.filter((item) => item?.title?.toLowerCase()?.includes('terminal id')) || [];

  const bottomItems = bottom_item_keys.map((item) => getDetailbyKey(transaction, item));

  const mainSectionItems = default_keys
    .map((x) => getDetailbyKey(transaction, x))
    .concat(senderDetails, recipientDetails, terminalDetails, getDetailbyKey(transaction, 'rrn'));

  const non_additonal_item_keys = mainSectionItems
    ?.map((item) => item?.title.toLowerCase())
    .concat(special_item_keys, default_keys, bottom_item_keys);

  const additionalItems = transaction?.filter((item) => !non_additonal_item_keys.includes(item.title.toLowerCase()));

  return {
    transaction,
    isLoading,
    handleOnClose,
    amount,
    status,
    fullTransaction,
    timeCreated: getDetailbyKey(transaction, 'time created'),
    transactionType: getDetailbyKey(transaction, 'transaction type'),
    mainSectionItems,
    special_item_keys,
    bottomItems,
    additionalItems,
    copyable_items,
  };
};

export default useTransactionDetailsModal;
