/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchBalance,
  fetchWithdrawalAccounts,
  initiateWithdrawal,
  fetchKTA,
  getPND,
  fetchCheckoutBalance,
} from './thunkActions';
import { WithDrawalAccount, KTA } from '../../../types/balance';
import { IPndResponse } from '../../../types/pndCheck';

interface InitialState {
  ledgerBalance: number;
  availableBalance: number;
  outletBalance: number | null;
  consolidatedBalance: number | null;
  lien_amount: number | null;
  checkoutBalance: number;
  withdrawalAccounts: WithDrawalAccount | null | undefined;
  faultyCashOut: boolean;
  KTA: KTA[] | null;
  PND: IPndResponse | null;
  isLoading: boolean;
  isFetchingBalance: boolean;
  inProgress: boolean;
  isBalanceError: boolean;
}

const initialState: InitialState = {
  ledgerBalance: 0,
  availableBalance: 0,
  checkoutBalance: 0,
  consolidatedBalance: null,
  lien_amount: null,
  outletBalance: null,
  withdrawalAccounts: null,
  faultyCashOut: false,
  KTA: null,
  PND: null,
  isLoading: false,
  inProgress: false,
  isFetchingBalance: false,
  isBalanceError: false,
};

export const balanceSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // setUser(state, action: PayloadAction<{ user: User }>) {
    //   state.user = action.payload.user;
    // },
  },
  extraReducers: (builder) => {
    //   fetch balance
    builder
      .addCase(fetchBalance.pending, (state) => {
        state.isFetchingBalance = true;
      })
      .addCase(fetchBalance.rejected, (state) => {
        state.isFetchingBalance = false;
        state.isBalanceError = true;
      });

    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.isFetchingBalance = false;
      state.availableBalance = action.payload?.amount || 0;
      state.ledgerBalance = action.payload?.amount || 0;
      state.outletBalance = action.payload?.totalOutletsBalance || null;
      state.consolidatedBalance = action.payload?.consolidatedBalance || null;
      state.lien_amount = action.payload?.lien_amount || null;
    });

    builder.addCase(fetchCheckoutBalance.fulfilled, (state, action) => {
      state.isFetchingBalance = false;
      state.checkoutBalance = action.payload?.amount || 0;
    });

    //   fetch withdrwal accounts
    builder
      .addCase(fetchWithdrawalAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWithdrawalAccounts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchWithdrawalAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.withdrawalAccounts = action.payload;

        if (!!action.payload?.accountName && !!action.payload?.accountNumber && !!action.payload?.bankCode) {
          state.faultyCashOut = false;
        } else {
          state.faultyCashOut = true;
        }
      });

    //   initiate withdrawal
    builder
      .addCase(initiateWithdrawal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initiateWithdrawal.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(initiateWithdrawal.fulfilled, (state) => {
        state.isLoading = false;
      });

    //   initiate withdrawal
    builder
      .addCase(fetchKTA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchKTA.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchKTA.fulfilled, (state, action) => {
        state.isLoading = false;
        state.KTA = action?.payload || null;
      });

    // PND
    builder
      .addCase(getPND.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPND.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getPND.fulfilled, (state, action) => {
        state.isLoading = false;
        state.PND = action.payload || null;
      });
  },
});

export default balanceSlice.reducer;
export * from './thunkActions';
