import { NTheme as theme } from 'kudi-component-library';
import { ActiveProps } from './types';

const Disputes = (props: ActiveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={17.5} cy={6.5} r={5.5} fill={props.active ? theme.nColors['n-yellow'] : '#F2F2F2'} />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M3.033 3.033A1.477 1.477 0 0 1 4.077 2.6h11.846A1.477 1.477 0 0 1 17.4 4.077v9.154a1.477 1.477 0 0 1-1.477 1.477H9.731l-3.25 2.604A.4.4 0 0 1 5.83 17v-2.292H4.077A1.477 1.477 0 0 1 2.6 13.23V4.077c0-.392.156-.767.433-1.044Zm1.044.367a.677.677 0 0 0-.677.677v9.154a.677.677 0 0 0 .677.677H6.23c.22 0 .4.179.4.4v1.859l2.71-2.172a.4.4 0 0 1 .25-.087h6.332a.677.677 0 0 0 .677-.677V4.077a.677.677 0 0 0-.677-.677H4.077Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M10.237 6.116a1.215 1.215 0 0 0-1.452 1.192.4.4 0 1 1-.8 0A2.015 2.015 0 1 1 10.4 9.283v.179a.4.4 0 0 1-.8 0v-.539c0-.22.18-.4.4-.4a1.215 1.215 0 0 0 .237-2.407ZM10.296 11.885a.4.4 0 0 0-.296-.67.67.67 0 1 0 0 1.339.4.4 0 0 0 .296-.67Z"
      clipRule="evenodd"
    />
    <path
      fill={props.active ? theme.nColors['n-dark'] : '#808080'}
      fillRule="evenodd"
      d="M9.6 11.615c0-.22.18-.4.4-.4a.67.67 0 1 1 0 1.339.4.4 0 0 1-.296-.67.398.398 0 0 1-.104-.269Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Disputes;
