/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'rebass/styled-components';

export interface FullProps {
  borderRadius?: string;
  border?: string;
  borderBottom?: string;
  borderTop?: string;
  position?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gridGap?: string;
  borderLeft?: string;
  borderRight?: string;
  gap?: string;
  sx?: string;
  sy?: string;
  display?: string;
  cursor?: string;
  padding?: string;
  width?: string | string[];
  grid?: boolean;
  printWidth?: string;
}

export const FullBox = styled(Box)<FullProps>`
  display: ${(props) => props.grid && 'grid'};
  position: relative;
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  border-top: ${(props) => props.borderTop};
  border-left: ${(props) => props.borderLeft};
  border-right: ${(props) => props.borderRight};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-template-columns: ${(props) => props.gridTemplateRows};
  grid-gap: ${(props) => props.gridGap};
  cursor: ${(props) => props.cursor};
  width: ${(props) => props.width};

  ${({ sx }) =>
    sx &&
    css`
      & > *:not(:last-child) {
        margin-right: ${sx};
      }
    `}
  ${({ sy }) =>
    sy &&
    css`
      & > * {
        margin-bottom: ${sy};
      }
    `}
`;
export const FullFlex = styled(Flex)<FullProps>`
  position: relative;
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  border-top: ${(props) => props.borderTop};
  border-left: ${(props) => props.borderLeft};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  border-right: ${(props) => props.borderRight};
  cursor: ${(props) => props.cursor};
  gap: ${(props) => props.gap};
  @media print {
    display: ${(props) => props.display};
    width: ${(props) => props.printWidth};
  }
  padding: ${(props) => props.padding}
    ${({ sx }) =>
      sx &&
      css`
        & > *:not(:last-child) {
          margin-right: ${sx};
        }
      `}
    ${({ sy }) =>
      sy &&
      css`
        & > * {
          margin-bottom: ${sy};
        }
      `};
`;

export const EmptyButton = styled.button<{
  color?: string;
  forntSize?: string;
  textDecoration?: string;
  fontWeight?: string;
}>`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: ${(props) => props.forntSize || '600'};
  font-size: ${(props) => props.forntSize || '12px'};
  text-decoration: ${(props) => props.textDecoration || 'none'};
`;

export const ExtraLinkText = styled.div`
  color: ${({ theme }) => theme.colors.primary.green6};
  background-color: transparent;
  border: none;
  outline: none;
  display: block;
  font-size: '14px';
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const LinkButton = styled.button<{
  color?: string;
  forntSize?: string;
  textDecoration?: string;
}>`
  color: ${(props) => props.color || 'inherit'};
  background-color: transparent;
  border: none;
  outline: none;
  display: inline-block;
  font-size: ${(props) => props.forntSize || '14px'};
  text-decoration: ${(props) => props.textDecoration || 'underline'};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export const CardLink = styled(Link)<{
  color?: string;
  textDecoration?: string;
}>`
  color: ${(props) => props.color || 'inherit'};
  background-color: transparent;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: ${(props) => props.textDecoration || 'none'};
  cursor: pointer;
  display: block;
`;

export const InputsWrapper = styled(FullBox)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;

export const ContentWrapper = styled(FullBox)`
  padding: 0 20px;
  @media ${({ theme }) => `${theme.media.lg}`} {
    padding: 0 50px;
  }
`;

export const BalanceContentWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  border: 1px solid #efefef;
  padding: 25px 26px;
  margin-bottom: 22px;
  background: white;
  border-radius: 14px;
`;

export const BalanceContentColumn = styled.div`
  margin-top: 20px;
  flex: 60%;
`;

export const InnerWrapper1 = styled.div`
  display: flex;
  /* margin-right: 50px; */
`;

export const InnerColumn1 = styled.div`
  flex: 33.3%;
  /* margin-right: 10px; */
  margin-right: 20px;
`;

export const InnerWrapper2 = styled.div`
  display: flex;
`;

export const InnerColumn2 = styled.div`
  flex: 50%;
  margin-top: 20px;
  /* margin-left: 250px; */
  /* align-items: flex-end; */
  /* display: flex; */
  /* justify-content: space-between; */
`;

export const DashboardCardHolder = styled.div`
  height: calc(100% - 50px);

  div {
    height: 100%;
    div {
      height: 42px;
    }
  }
`;

export const MerchantCardHolder = styled.div`
  height: 100%;
  cursor: pointer;
  max-width: 100%;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.media.sm} {
    max-width: 47%;
    margin-bottom: 0;
  }

  /* @media ${({ theme }) => theme.media.md} {
    max-width: 50%;
  } */

  div {
    height: 100%;
    border: 1px solid #efefef;
    div {
      height: 30px;
      width: 30px;
      border: none;
      margin-right: 10px;
      /* span {
        border-radius: 15px;
      } */
    }
  }
`;

export const PageWrapper = styled.div`
  padding: 2rem;

  @media ${({ theme }) => theme.media.lg} {
    padding: 5rem 2rem 2rem;
  }
`;

export const HeaderText = styled.h3`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  font-feature-settings: 'cv11' on;
  color: ${({ theme }) => theme.colors.primary.green1};

  @media ${({ theme }) => theme.media.lg} {
    margin-top: 0;
    margin-bottom: 0;
    margin-bottom: 4rem;
  }
`;

export const HeaderTopFlex = styled.div`
  @media ${({ theme }) => theme.media.lg} {
    align-items: flex-start;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Separator = styled.div<{ height?: string; width?: string }>`
  height: ${({ height }) => height || '1rem'};
  width: ${({ width }) => width || '0rem'};
`;
