import FormHeader from './form-header';
import UserCirle from '../../../../assets/svg/user-circle';
import OfficeBulding from '../../../../assets/svg/office-building';
import { AccountTypeIconWrapper, AccountTypeItem, AccountTypeListWrapper, ChooseAccountTypeWrapper } from './styles';
import { IconsEnum, NIcons } from 'kudi-component-library';
import { useNavigate } from 'react-router-dom';
import Onboardingv3 from '../index';

const ChooseAccountType = () => {
  const navigate = useNavigate();
  const accountTypes = [
    {
      name: 'Individual business',
      description: 'For SMEs that are not CAC registered',
      icon: <UserCirle />,
      route: '/auth/onboardingv3/individual/create-account',
    },
    {
      name: 'Corporate business',
      description: 'For businesses that are CAC-registered',
      icon: <OfficeBulding />,
      route: '/auth/onboardingv3/corporate/create-account',
    },
  ];
  return (
    <Onboardingv3>
      <ChooseAccountTypeWrapper>
        <FormHeader title="Choose account type" description="Kindly choose your preferred business account type." />
        <AccountTypeListWrapper>
          {accountTypes.map((accountType) => (
            <AccountTypeItem key={accountType.name} onClick={() => navigate(accountType.route)}>
              <AccountTypeIconWrapper>{accountType.icon}</AccountTypeIconWrapper>
              <section className="right-section">
                <div>
                  <p>{accountType.name}</p>
                  <p>{accountType.description}</p>
                </div>
                <NIcons icon={IconsEnum.OUTLINE_CHEVRON_RIGHT} />
              </section>
            </AccountTypeItem>
          ))}
        </AccountTypeListWrapper>
      </ChooseAccountTypeWrapper>
    </Onboardingv3>
  );
};

export default ChooseAccountType;
