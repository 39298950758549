import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  z-index: 50;
  height: 6rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.primary.black5};
  padding: 0 20px;
  position: fixed;
  left: 0;
  right: 0;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.16); */
  margin-left: 0;
  position: relative;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05));

  @media ${({ theme }) => `${theme.media.xl}`} {
    width: 100%;
    border-bottom: none;
    padding: 0 3.2rem;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media print {
    display: none;
  }
`;

export const HeaderMenuWrapper = styled.div`
  @media ${({ theme }) => `${theme.media.xl}`} {
    display: none;
  }
`;

export const HeaderAvatarWrapper = styled.div`
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary.black1};
  border-radius: 50%;
  cursor: pointer;

  @media ${({ theme }) => `${theme.media.xl}`} {
    margin-left: auto;
    margin-right: 0.9rem;
  }
`;

export const HeaderIconHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary.blue1};
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
`;

export const HeaderTitle = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.lg} {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSizes[2]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 2rem;
    letter-spacing: 0.2em;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary.green3};
  }
`;

export const HeaderDropDownWrapper = styled.div<{ hide?: boolean }>`
  width: ${({ hide }) => (hide ? '0' : 'calc(100% - 4rem)')};
  height: ${({ hide }) => (hide ? '0' : 'fit-content')};
  position: absolute;
  top: 7rem;
  right: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  opacity: ${({ hide }) => (hide ? '0' : '1')};

  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;

  @media ${({ theme }) => theme.media.lg} {
    width: ${({ hide }) => (hide ? '0' : '18rem')};
    height: ${({ hide }) => (hide ? '0' : 'fit-content')};
    right: 2rem;
  }
`;

export const HeaderDropDownItem = styled.div<{ active?: boolean }>`
  height: 5rem;
  background-color: ${({ active, theme }) => (active ? theme.colors.primary.blue6 : theme.colors.primary.white)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 2rem;
  cursor: pointer;

  :hover {
    background-color: #f4f2f2;
  }

  span {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: ${({ theme }) => theme.colors.primary.green1};
  }
`;

export const HeaderDropDownHiddenButton = styled.button`
  height: 0;
  width: 0;
  opacity: 0;
`;

export const HeaderAvatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const RightSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 23px;
`;

export const LogoutWrapper = styled.div`
  cursor: pointer;
  @media ${({ theme }) => `${theme.media.xl}`} {
    margin-left: auto;
    margin-right: 0.9rem;
  }

  &:hover {
    span {
      color: ${({ theme }) => theme.nColors['n-grey4']};
    }
  }
  span {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.nColors['n-yellow']};
  }
`;
