import { NewStoreValues, Store } from '../../../types/inventory/store';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface CreateStoreResponse extends ApiResponse {
  data: Store;
}

const createStore = async ({ storeName, storeLocation, description }: NewStoreValues) => {
  try {
    const result: CreateStoreResponse = await axios.post('/webbe/v1/storehouse/stores/create', {
      storeName,
      storeLocation,
      description,
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default createStore;
