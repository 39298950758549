import { Outlet } from 'react-router-dom';
import { Layout } from '../../guestDashboard/style';
import ErrorBoundary from '../../../../utils/errorBoundary';
import { FileHolderContextWrapper } from '../../../../utils/filesHolderContext';
import { CrossPlatformContentWrapper, CrossPlatformMainPageContentHolder } from './styles';

export const CrossPlatfromLayout = () => (
  <Layout>
    <CrossPlatformContentWrapper>
      <CrossPlatformMainPageContentHolder>
        <ErrorBoundary>
          <FileHolderContextWrapper>
            <Outlet />
          </FileHolderContextWrapper>
        </ErrorBoundary>
      </CrossPlatformMainPageContentHolder>
    </CrossPlatformContentWrapper>
  </Layout>
);
