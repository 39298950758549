/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from '../../utils/http';
import * as apiSpecClient from '../../api_spec_client';
import { ReduxAction, ApiResponse } from '../../types/redux';
import {
  BusinessAccountCreation,
  CreateRegistrationReq,
  BoltAgentData,
  BoltPhoneInfo,
  LoginOptions,
  IAMObjectType,
  NewOnboardingInfo,
  BusinessDocs,
  FullUser,
} from '../../types/auth';
import { OtpVerificationPurpose, BusinessMigrationRequirementResponseData } from '../../api_spec_client';
import { storeDeviceId } from '../../utils';
import { RootState } from '../../app/store';

// ---------------------------   MOCK  ---------------------------//

// ---------------------------   LOGIN START   ---------------------------//

export interface LoginResponseData {
  name: string;
  userId: number | string;
  parentBusinessId: number | string | null;
  deviceUniqueId: string;
  payload: {
    token: string;
    expiresAt: string;
  };
  iamTokenData?: IAMObjectType;
  role: string;
  bankingEntityId?: string;
  bankingEntityType?: string;
}
export interface LoginResponse extends ApiResponse {
  data?: LoginResponseData;
  dataList: null;
  meta: null;
}

export interface LoginArgs extends ReduxAction {
  values: {
    username: string;
    password: string;
    loginMode: 'EMAIL' | 'PHONE';
    coreBusinessId?: string;
    emailOtpVerificationId?: string;
    phoneOtpVerificationId?: string;
  };
  options?: LoginOptions;
  authInfo: {
    verificationIds: [string, string];
    deviceId?: string;
  };
  deviceFCMToken?: string;
}

export const login = createAsyncThunk(
  '/auth/login',
  async (
    {
      values: { username, password, loginMode, coreBusinessId, emailOtpVerificationId, phoneOtpVerificationId },
      options,
      authInfo,
      deviceFCMToken,
      onSuccess,
      onFailure,
    }: LoginArgs,
    { rejectWithValue }
  ) => {
    const loginItems = {
      username,
      password,
      loginMode,
      emailOtpVerificationId,
      phoneOtpVerificationId,
      deviceFCMToken,
      ...options,
      ...authInfo,
    };
    try {
      const { data }: LoginResponse = await axios.post(
        '/auth/login/v2',
        coreBusinessId ? { ...loginItems, coreBusinessId } : loginItems
      );

      if (onSuccess) {
        localStorage.setItem('bankingEntityType', data?.bankingEntityType || '');
        onSuccess(data);
      }

      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   LOGIN END   ---------------------------//

// ---------------------------   VERIFY RESET OTP START   ---------------------------//
// export interface VerifyResetResponse extends ApiResponse {
//   data?: null;
//   dataList: null;
//   meta: null;
//   message?: string;
// }

// interface VerifyResetArgs extends ReduxAction {
//   values: {
//     userRegistrationId: string | number;
//     token: string;
//     medium: 'PHONE' | 'EMAIL';
//   };
// }

// export const verifyReset = createAsyncThunk(
//   '/auth/verify-reset-token',
//   async ({ values, onSuccess, onFailure }: VerifyResetArgs, { rejectWithValue }) => {
//     try {
//       const { data }: VerifyResetResponse = await axios.post('/user/validate-otp', values);
//       if (onSuccess) onSuccess(data);
//       return data;
//     } catch (error) {
//       if (onFailure) onFailure(error);
//       return rejectWithValue({ error });
//     }
//   }
// );

// ---------------------------   VERIFY RESET OTP END   ---------------------------//

// ---------------------------   RESET PASSWORD START   ---------------------------//

export interface ResetPasswordResponse extends ApiResponse {
  data?: LoginResponseData;
  dataList: null;
  meta: null;
  headers: {
    device_id: string;
  };
}

interface ResetPasswordArgs extends ReduxAction {
  values: {
    userId: string | number | null | undefined;
    userRegistrationId?: string | number | null | undefined;
    newPassword: string;
    emailVerificationId: string;
    phoneVerificationId: string;
  };
}

export const resetPassword = createAsyncThunk<ResetPasswordResponse, ResetPasswordArgs, { state: RootState }>(
  '/auth/reset-password',
  async ({ values, onSuccess, onFailure }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { tempDeviceInfo },
      } = getState();
      const data: ResetPasswordResponse = await axios.post('/user/v2/reset-password', values);

      storeDeviceId({
        phoneNumber: tempDeviceInfo?.phone || '',
        email: tempDeviceInfo?.email || '',
        id: data.headers.device_id || '',
      });

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   RESET PASSWORD END   ---------------------------//

// ---------------------------   RESET PASSWORD IN SETTINGS PAGE   ---------------------------//
export interface ResetSettingsPasswordResponse extends ApiResponse {
  data?: LoginResponseData;
  dataList: null;
  meta: null;
}

interface ResetSettingsPasswordArgs extends ReduxAction {
  values: {
    userId: string | number;
    newPassword: string;
    oldPassword: string;
  };
}

export const resetSettingsPassword = createAsyncThunk(
  '/settings/reset-password',
  async ({ values, onSuccess, onFailure }: ResetSettingsPasswordArgs, { rejectWithValue }) => {
    try {
      const { data }: ResetPasswordResponse = await axios.post('/user/reset-password-settings-area', values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   RESET PASSWORD IN SETTINGS PAGE END   ---------------------------//

// ---------------------------   SIGNUP START   ---------------------------//
export interface SignupResponseData {
  firstName: string;
  lastName: string;
  parentBusinessId: number | string | null;
  phoneNumber: string;
  email: string;
}

export interface SignupResponse extends ApiResponse {
  dataList: null;
  meta: null;
  data?: SignupResponseData;
}

interface SignupArgs extends ReduxAction {
  values: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    password: string;
    referralCode?: string;
    agent: boolean;
    verificationIds: [string, string];
  };
}

export const signup = createAsyncThunk(
  '/user/create',
  async ({ values, onSuccess, onFailure }: SignupArgs, { rejectWithValue }) => {
    try {
      const { data }: SignupResponse = await axios.post('/user/create', values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   SIGNUP END   ---------------------------//

// ---------------------------   CREATE BUSINESS START   ---------------------------//

export interface CreateBusinessResponse extends ApiResponse {
  dataList: null;
  meta: null;
  data: string;
}

interface CreateBusinessArgs extends ReduxAction {
  values: BusinessAccountCreation;
}

export const createBusiness = createAsyncThunk(
  '/user/business-create',
  async ({ values, onSuccess, onFailure }: CreateBusinessArgs, { rejectWithValue }) => {
    try {
      const { data }: CreateBusinessResponse = await axios.post('/business/create', { ...values, createdIn: 'WEB' });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   CREATE BUSINESS END   ---------------------------//

// ---------------------------   FETCH USER INFO START   ---------------------------//

export interface FetchUserResponse extends ApiResponse {
  data?: FullUser;
  dataList: null;
  meta: null;
}

interface FetchUserArgs extends ReduxAction {
  values: { id: string | number };
}

export const fetchUser = createAsyncThunk(
  '/auth/FetchUser',
  async ({ values: { id }, onSuccess, onFailure }: FetchUserArgs, { rejectWithValue }) => {
    try {
      const { data }: FetchUserResponse = await axios.get(`/user/${id}`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   FETCH USER INFO END   ---------------------------//

// // ---------------------------   VERIFY BUSINESS START   ---------------------------//

// export interface VerifyBusinessResponse extends ApiResponse {
//   data: null;
//   dataList: null;
//   meta: null;
// }

// interface VerifyBusinessArgs extends ReduxAction {
//   values: {
//     phoneNumber: string | null;
//     token: string | null;
//   };
// }

// export const verifyBusiness = createAsyncThunk(
//   '/auth/verify-business',
//   async ({ values, onSuccess, onFailure }: VerifyBusinessArgs, { rejectWithValue }) => {
//     try {
//       const data: VerifyBusinessResponse = await axios.post(`/user/validate-otp`, values);
//       if (onSuccess) onSuccess(data);
//       return data;
//     } catch (error) {
//       if (onFailure) onFailure(error);
//       return rejectWithValue({ error });
//     }
//   }
// );

// // ---------------------------   VERIFY BUSINESS END   ---------------------------//

// ---------------------------   SEND TOKEN START   ---------------------------//

export interface SendTokenResponse extends ApiResponse {
  data: {
    status: boolean;
    verificationId: string;
  };
  dataList: null;
  meta: null;
  message: string;
}

type OTPParams = 'isPasswordResetOtp' | 'isWelcomeOtp' | 'isGeneralPurposeOtp';

interface SendTokenArgs extends ReduxAction {
  values: {
    userId: number | string | null | undefined;
    userRegistrationId: number | string | null | undefined;
    medium: 'EMAIL' | 'PHONE';
  };
  params: OTPParams;
}

export const sendToken = createAsyncThunk(
  '/auth/send-token',
  async ({ values, onSuccess, onFailure, params }: SendTokenArgs, { rejectWithValue }) => {
    try {
      const data: SendTokenResponse = await axios.post(`/user/send-verification-token`, values, {
        params: { [params]: true },
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   SEND TOKEN END   ---------------------------//

// ---------------------------   SET PASSWORD START   ---------------------------//

export interface SetPasswordResponse extends ApiResponse {
  data?: FullUser;
  dataList: null;
  meta: null;
}

interface SetPasswordArgs extends ReduxAction {
  values: { phoneNumber: string; password: string; email: string };
}

export const setPassword = createAsyncThunk(
  '/auth/set-password',
  async ({ values, onSuccess, onFailure }: SetPasswordArgs, { rejectWithValue }) => {
    try {
      const data: SetPasswordResponse = await axios.put(`/user/existing-user-password-setup`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   SET PASSWORD END   ---------------------------//

// ---------------------------   CREATE REGISTRATION START   ---------------------------//

export interface CreateRegistrationResponseData {
  id: number | string;
  createdAt: string;
  updatedAt: null | string;
  deletedAt: null | string;
  firstName: string;
  lastName: null | string;
  phoneNumber: string;
  email: string;
}

export interface CreateRegistrationResponse extends ApiResponse {
  data?: CreateRegistrationResponseData;
  dataList: null;
  meta: null;
}

export interface CreateRegistrationArgs extends ReduxAction {
  values: CreateRegistrationReq;
}

export const createRegistration = createAsyncThunk(
  '/auth/create-registration',
  async ({ values, onSuccess, onFailure }: CreateRegistrationArgs, { rejectWithValue }) => {
    try {
      const data: CreateRegistrationResponse = await axios.post(`/user-registration/pre-register`, values);

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   CREATE REGISTRATION END   ---------------------------//

// ---------------------------   VALIDATE TOKEN START   ---------------------------//

export interface ValidateTokenResponse extends ApiResponse {
  message: string;
  data: null;
  dataList: null;
  meta: null;
}

interface ValidateTokenArgs extends ReduxAction {
  values: {
    userId: string | number | null;
    token: string;
    medium: 'EMAIL' | 'PHONE';
    verificationId: string;
    userRegistrationId: string | number | null;
  };
}

export const validateToken = createAsyncThunk(
  '/auth/validate-token',
  async ({ values, onSuccess, onFailure }: ValidateTokenArgs, { rejectWithValue }) => {
    try {
      const data: ValidateTokenResponse = await axios.post(`/user/validate-otp`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   VALIDATE TOKEN END   ---------------------------//

// ---------------------------  NEW MFA VALIDATE TOKEN START   ---------------------------//

export interface NewValidateTokenResponse extends ApiResponse {
  message: string;
  data: null;
  dataList: null;
  meta: null;
}

interface NewValidateTokenArgs extends ReduxAction {
  values: {
    token: string;
    verificationId: string;
    purpose: OtpVerificationPurpose;
  };
}

export const newValidateToken = createAsyncThunk(
  '/auth/validate-token',
  async ({ values, onSuccess, onFailure }: NewValidateTokenArgs, { rejectWithValue }) => {
    try {
      const apiClient = apiSpecClient.MFAResourceApiAxiosParamCreator();

      const requestOptions = await apiClient.verifyOTP({
        token: values.token,
        verificationId: values.verificationId,
        purpose: values.purpose,
      });

      const response = await axios.post(requestOptions.url, JSON.parse(requestOptions.options.data));
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------  NEW MFA VALIDATE TOKEN END   ---------------------------//

// ---------------------------  GET BOLT USER START   ---------------------------//

export interface GetBoltUserResponse extends ApiResponse {
  data?: BoltAgentData;
  dataList: null;
  meta: null;
}

interface GetBoltUserArgs extends ReduxAction {
  values: { userRegistrationId: string | number };
}

export const getBoltUser = createAsyncThunk(
  '/auth/get-bolt-user',
  async ({ values, onSuccess, onFailure }: GetBoltUserArgs, { rejectWithValue }) => {
    try {
      const data: GetBoltUserResponse = await axios.get(`/user/get/${values.userRegistrationId}`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------  GET BOLT USER END   ---------------------------//

// ---------------------------  UPDATE USER ON VERIFICATION START   ---------------------------//

export interface UpdateUserOnVerificationResponse extends ApiResponse {
  data?: {
    id: string;
    createdAt: string;
    updatedAt: null | string;
    deletedAt: null | string;
    firstName: null | string;
    lastName: null | string;
    phoneNumber: string;
    email: string;
  };
  dataList: null;
  meta: null;
}

interface UpdateUserOnVerificationArgs extends ReduxAction {
  values: { userId: string | number; registrationId: string | number };
}

export const updateUserOnVerification = createAsyncThunk(
  '/auth/update-user-on-verification',
  async (
    { values: { userId, registrationId }, onSuccess, onFailure }: UpdateUserOnVerificationArgs,
    { rejectWithValue }
  ) => {
    try {
      const data: UpdateUserOnVerificationResponse = await axios.put(`/user/update/${userId}`, {
        registrationId,
        phoneOtpVerified: true,
        emailOtpVerified: true,
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------  UPDATE USER ON VERIFICATION END   ---------------------------//

// ---------------------------  GET USER REGISTRATION BY PHONE/EMAIL START   ---------------------------//

export interface GetUserRegistrationResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: {
    id: string;
    email: string;
    phoneNumber: string;
  };
}

interface GetUserRegistrationArgs extends ReduxAction {
  params: { phone?: string; email?: string };
}

export const getUserRegistration = createAsyncThunk(
  '/auth/get-user-registration',
  async ({ params, onSuccess, onFailure }: GetUserRegistrationArgs, { rejectWithValue }) => {
    try {
      const data: GetUserRegistrationResponse = await axios.get(`/user/preview`, { params });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------  GET USER REGISTRATION BY PHONE/EMAIL END   ---------------------------//

// ---------------------------  GET BOLT USER BY PHONE START   ---------------------------//

export interface GetBoltUserByPhoneResponse extends ApiResponse {
  data?: BusinessMigrationRequirementResponseData;
}

interface GetBoltUserByPhoneArgs extends ReduxAction {
  phoneNumber: string;
  pin: string;
}

export const getBoltUserByPhone = createAsyncThunk(
  '/auth/get-business-migration-requirements',
  // '/auth/get-bolt-user-by-phone',

  async ({ phoneNumber, pin, onSuccess, onFailure }: GetBoltUserByPhoneArgs, { rejectWithValue }) => {
    try {
      const apiClient = apiSpecClient.BusinessMigrationResourceApiAxiosParamCreator(); // Create the axios parameter creator

      const requestOptions = await apiClient.getBusinessMigrationRequirements({
        phoneNumber,
        pin,
      });

      const response = await axios.post(requestOptions.url, JSON.parse(requestOptions.options.data));

      if (onSuccess) onSuccess({ ...response.data, data: { ...response.data?.data, transactionPin: pin } });

      return { ...response.data, data: { ...response.data?.data, transactionPin: pin || '' } as BoltPhoneInfo };

      // if (onSuccess) onSuccess({ ...data, data: { ...data?.data, transactionPin: pin } });
      // return { ...data, data: { ...data?.data, transactionPin: pin || '' } as BoltPhoneInfo };
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------  GET BOLT USER BY PHONE END   ---------------------------//

// // ---------------------------  SEND VERIFICATION TOKEN TO MIGRATED USER START   ---------------------------//

// export interface SendVerificationTokenToMigratedUserResponse extends ApiResponse {
//   data?: null;
//   dataList: null;
//   message: string;
//   meta: null;
// }

// interface SendVerificationTokenToMigratedUserArgs extends ReduxAction {
//   info: { email?: string; phoneNumber?: string; firstName?: string; medium: 'EMAIL' | 'PHONE' };
//   params: { isGeneralPurposeOtp?: boolean; isWelcomeOtp?: boolean; isPasswordResetOtp?: boolean };
// }

// export const sendVerificationTokenToMigratedUser = createAsyncThunk(
//   '/auth/send-migrated-user-otp',
//   async (
//     {
//       info,
//       params: { isGeneralPurposeOtp = false, isWelcomeOtp = false, isPasswordResetOtp = false },
//       onSuccess,
//       onFailure,
//     }: SendVerificationTokenToMigratedUserArgs,
//     { rejectWithValue }
//   ) => {
//     try {
//       const data: SendVerificationTokenToMigratedUserResponse = await axios.post(
//         `/user/send-verification-token`,
//         info,
//         { params: { isGeneralPurposeOtp, isWelcomeOtp, isPasswordResetOtp } }
//       );
//       if (onSuccess) onSuccess(data);
//       return data;
//     } catch (error) {
//       if (onFailure) onFailure(error);
//       return rejectWithValue({ error });
//     }
//   }
// );

// ---------------------------  SEND VERIFICATION TOKEN TO MIGRATED USER END   ---------------------------//

// ---------------------------  VALIDATE VERIFICATION TOKEN TO MIGRATED USER START   ---------------------------//

// export interface ValidateVerificationTokenToMigratedUserResponse extends ApiResponse {
//   data?: null;
//   dataList: null;
//   message: string;
//   meta: null;
// }

// interface ValidateVerificationTokenToMigratedUserArgs extends ReduxAction {
//   info: {
//     userRegistrationId?: string | number;
//     phoneNumber?: string;
//     email?: string;
//     token: string;
//     medium: 'EMAIL' | 'PHONE';
//   };
// }

// export const validateVerificationTokenToMigratedUser = createAsyncThunk(
//   '/auth/validate-migrated-user-otp',
//   async ({ info, onSuccess, onFailure }: ValidateVerificationTokenToMigratedUserArgs, { rejectWithValue }) => {
//     try {
//       const data: ValidateVerificationTokenToMigratedUserResponse = await axios.post(`/user/validate-otp`, info);
//       if (onSuccess) onSuccess(data);
//       return data;
//     } catch (error) {
//       if (onFailure) onFailure(error);
//       return rejectWithValue({ error });
//     }
//   }
// );

// ---------------------------  VALIDATE VERIFICATION TOKEN TO MIGRATED USER END   ---------------------------//

// ---------------------------   MIGRATED USER CREATE BUSINESS START   ---------------------------//

export interface MigratedUserCreateBusinessResponse extends ApiResponse {
  data?: null;
  dataList: null;
  message: string;
  meta: null;
}

export type Channel = 'ANDROID' | 'IOS' | 'WEB' | 'WATCHTOWER';

interface MigratedUserCreateBusinessArgs extends ReduxAction {
  info: apiSpecClient.BusinessMigrationRequest;
}

export const migratedUserCreateBusiness = createAsyncThunk(
  '/auth/migrated-user-create-business',
  async ({ info, onSuccess, onFailure }: MigratedUserCreateBusinessArgs, { rejectWithValue }) => {
    try {
      const apiClient = apiSpecClient.BusinessMigrationResourceApiAxiosParamCreator();

      const requestOptions = await apiClient.performBusinessMigration(info);

      const response: AxiosResponse<MigratedUserCreateBusinessResponse> = await axios.post(
        requestOptions.url,
        JSON.parse(requestOptions.options.data)
      );
      const data: MigratedUserCreateBusinessResponse = response.data;

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   MIGRATED USER CREATE BUSINESS END   ---------------------------//

// ---------------------------   BOLT USER EXISTS START   ---------------------------//

export interface BoltUserExistsResponse extends ApiResponse {
  data?: null;
  dataList: null;
  message: string;
  meta: null;
}

interface BoltUserExistsArgs extends ReduxAction {
  phoneNumber: string | number;
}

export const boltUserExists = createAsyncThunk(
  '/auth/bolt-user-exists',
  async ({ phoneNumber, onSuccess, onFailure }: BoltUserExistsArgs, { rejectWithValue }) => {
    try {
      const data: BoltUserExistsResponse = await axios.get(`/user/check/${phoneNumber}`);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   BOLT USER EXISTS END   ---------------------------//

// ---------------------------   BUSINESS EMAIL EXISTS START   ---------------------------//

export interface BusinessEmailExistsResponse extends ApiResponse {
  data?: null;
  dataList: null;
  message: string;
  meta: null;
}

interface BusinessEmailExistsArgs extends ReduxAction {
  email: string | number;
}

interface CancelSession extends ReduxAction {
  userId: string;
  token?: string | null;
}

export const businessEmailExists = createAsyncThunk(
  '/auth/business-email-exists',
  async ({ email, onSuccess, onFailure }: BusinessEmailExistsArgs, { rejectWithValue }) => {
    try {
      const data: BusinessEmailExistsResponse = await axios.get(`/business/all`, { params: { email } });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   BUSINESS EMAIL EXISTS END   ---------------------------//

// ---------------------------   CANCEL AUTH ( LOGOUT ) START  ---------------------------//

export const cancelAuthSession = createAsyncThunk(
  '/auth/logout',
  async ({ userId, onSuccess, onFailure, token }: CancelSession, { rejectWithValue }) => {
    const config: AxiosRequestConfig = {};

    if (token) {
      const parsedToken: string = JSON.parse(token) ?? '';
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${parsedToken}`,
      };
    }
    try {
      const data = await axios.post(
        `/auth/logout`,
        { userId, token: JSON.parse(token as string) },
        {
          headers: config.headers,
        }
      );
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   CANCEL AUTH ( LOGOUT ) END   ---------------------------//

// ---------------------------   PRE-REGISTRATION (TEMPORARY USER REGISTRATION) START   ---------------------------//

export interface CreateTempRegistrationResponse extends ApiResponse {
  data?: {
    id: string;
    phoneNumber: string;
    email: string;
  };
  dataList: null;
  message: string;
  meta: null;
}

interface CreateTempRegistrationArgs extends ReduxAction {
  phoneNumber: string;
  email: string;
}

export const createTempRegistration = createAsyncThunk(
  '/onboard/init',
  async ({ phoneNumber, email, onSuccess, onFailure }: CreateTempRegistrationArgs, { rejectWithValue }) => {
    try {
      const data: CreateTempRegistrationResponse = await axios.post(`/onboard/init`, {
        phoneNumber,
        email,
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   PRE-REGISTRATION (TEMPORARY USER REGISTRATION) END   ---------------------------//

// ---------------------------   IGREE BVN VERIFICATION INIT START   ---------------------------//

export interface IgreeInitResponse extends ApiResponse {
  status: true;
  message: string;
  data: string;
}

interface IgreeInitArgs extends ReduxAction {
  customerPhone: string;
  callbackUrl: string;
}

export const IgreeInit = createAsyncThunk(
  '/verification/v1/bvn/init',
  async ({ customerPhone, callbackUrl, onSuccess, onFailure }: IgreeInitArgs, { rejectWithValue }) => {
    try {
      const data: IgreeInitResponse = await axios.post(`/verification/v1/bvn/init`, { customerPhone, callbackUrl });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   IGREE BVN VERIFICATION INIT END   ---------------------------//

// ---------------------------   IGREE BVN VERIFICATION VERIFY START   ---------------------------//

export interface IgreeVerifyResponse extends ApiResponse {
  status: true;
  message: string;
  data: {
    dob: string;
    firstName: string;
    gender: string;
    idType: string;
    lastName: string;
    middleName: null;
    phoneNumber: string;
    bvn: string;
    score: 100;
    verificationId: string;
  };
  dataList: null;
  meta: null;
  error: null;
}

interface IgreeVerifyArgs extends ReduxAction {
  number: string;
  code: string;
  firstName: string;
  lastName: string;
  dob: string;
}

export const IgreeVerify = createAsyncThunk(
  '/verification/v1/bvn/verify',
  async ({ number, firstName, lastName, code, dob, onSuccess, onFailure }: IgreeVerifyArgs, { rejectWithValue }) => {
    try {
      const data: IgreeVerifyResponse = await axios.post(`/verification/v1/bvn/verify`, {
        number,
        firstName,
        lastName,
        code,
        dob,
        vendorRefresh: true,
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   IGREE BVN VERIFICATION VERIFY END   ---------------------------//

// ---------------------------   NEW CREATE ACCOUNT START   ---------------------------//

interface NewCreateAccountArgs extends ReduxAction {
  values: NewOnboardingInfo;
}

export const newCreateAccount = createAsyncThunk(
  '/onboard/account',
  async ({ values, onSuccess, onFailure }: NewCreateAccountArgs, { rejectWithValue }) => {
    try {
      const data: LoginResponse = await axios.post(`/onboard/account`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// ---------------------------   NEW CREATE ACCOUNT END   ---------------------------//

// -------------------- Upgrade business KYC level V2 ---------------------- //

export interface UpgradeBusinessKYCLevelResponseData {
  data?: {
    status: boolean;
    message: string;
    data: string;
  };
}

export interface UpgradeBusinessKYCLevelResponse extends ApiResponse {
  data?: UpgradeBusinessKYCLevelResponseData;
  dataList: null;
  meta: null;
}

interface UpgradeBusinessKYCLevelArgs extends ReduxAction {
  values: {
    idType: string | null | undefined;
    idNumber: string | number | null | undefined;
    nextOfKinInfo: {
      firstName: string | null | undefined;
      lastName: string | null | undefined;
      phoneNumber: string | null | undefined;
    };
  };
}

export const UpgradeBusinessKYCLevel = createAsyncThunk(
  '/onboard/business/upgrade-kyc',
  async ({ values, onSuccess, onFailure }: UpgradeBusinessKYCLevelArgs, { rejectWithValue }) => {
    try {
      const data: UpgradeBusinessKYCLevelResponse = await axios.post(`/onboard/upgrade-kyc`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- End Upgrade business KYC level V2 ---------------------- //

interface UploadCorporateBusinessDocsArgs extends ReduxAction {
  values: BusinessDocs;
}

export const uploadCorporateBusinessDocuments = createAsyncThunk(
  '/docs-review/corporate',
  async ({ values, onSuccess, onFailure }: UploadCorporateBusinessDocsArgs, { rejectWithValue }) => {
    try {
      const data: LoginResponse = await axios.post(`/docs-review/corporate`, values);
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- CHECK USER EXIST BEFORE BEGIN ONBOARDING START ---------------------- //

interface CheckUserExistsArgs extends ReduxAction {
  params: { phone: string; email: string };
}

export interface CheckUserExistsResponse {
  status: boolean;
  message: string;
  data: {
    phoneExists: boolean;
    emailExists: boolean;
    userExiistsOnBolt: boolean;
    businessExists: boolean;
  };
}

export const checkUserExists = createAsyncThunk(
  '/auth/get-user',
  async ({ params, onSuccess, onFailure }: CheckUserExistsArgs, { rejectWithValue }) => {
    try {
      const data: CheckUserExistsResponse = await axios.get(`user/existing`, { params });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

// -------------------- CHECK USER EXIST BEFORE BEGIN ONBOARDING END ---------------------- //

interface NewSendTokenArgs extends ReduxAction {
  values: {
    userId?: number;
    phoneNumber?: string;
    email?: string;
    purpose: OtpVerificationPurpose;
    medium: 'PHONE' | 'EMAIL';
  };
}

export const newSendToken = createAsyncThunk(
  '/auth/send-token',
  async ({ values, onSuccess, onFailure }: NewSendTokenArgs, { rejectWithValue }) => {
    try {
      const apiClient = apiSpecClient.MFAResourceApiAxiosParamCreator();

      const requestOptions =
        values?.medium === 'PHONE'
          ? await apiClient.phoneRequestOTP({
              userId: values.userId,
              phoneNumber: values.phoneNumber,
              purpose: values.purpose,
            })
          : await apiClient.requestEmailOTP({
              email: values.email,
              userId: values.userId,
              purpose: values.purpose,
            });

      const response = await axios.post(requestOptions.url, JSON.parse(requestOptions.options.data));

      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
