import { CreateMenuListItem, CreateMenuListItemResponse } from '../../../types/menu/menuList';
import axios from '../../../utils/merchantAcqServiceHttp';

const createMenuListProducts = async (payload: CreateMenuListItem, menuId: string, outletId?: string) => {
  try {
    const result: CreateMenuListItemResponse = await axios.put(
      `/webbe/v1/menus/${menuId}/add-or-remove-items`,
      payload,
      { headers: { ...(outletId && { outletId }) } }
    );

    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default createMenuListProducts;
