import { Category } from '../../../types/inventory/category';
import { ApiResponse } from '../../../types/redux';
import axios from '../../../utils/merchantAcqServiceHttp';

interface CategoriesResponse extends ApiResponse {
  data: {
    list: Category[];
    pageToken: string;
  };
}

const fetchCategories = async ({ params, outletId }: { params?: URLSearchParams; outletId?: string }) => {
  const url = `/webbe/v1/storehouse/stores/categories/filter?${params ? params.toString() : ''}`;
  try {
    const result: CategoriesResponse = await axios.get(url, { headers: { ...(outletId && { outletId: outletId }) } });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export default fetchCategories;
