import { FilterConfig, FilterConfigOption } from '../../components/newFilter/defs';
import { TransactionsFilterSource, TransactionsFilterStatus } from '../../utils/transactionEnums';
import { IssueListFilterStatus } from './defs';

export const routes = {
  home: '',
  office_address: 'office-address',
};

const transactionSourceOptions: FilterConfigOption[] = Object.entries(TransactionsFilterSource).map(
  ([value, text]) => ({
    text,
    value,
  })
);

const transactionStatusOptions: FilterConfigOption[] = Object.entries(TransactionsFilterStatus).map(
  ([value, text]) => ({
    text,
    value,
  })
);

const issuesStatusOptions: FilterConfigOption[] = Object.entries(IssueListFilterStatus).map(([value, text]) => ({
  text,
  value,
}));

export const transactionFilterConfig: FilterConfig[] = [
  {
    name: 'Date',
    type: 'date',
    query: 'date',
    options: [],
  },
  {
    name: 'Source',
    type: 'select',
    query: 'source',
    options: transactionSourceOptions,
  },
  {
    name: 'Status',
    type: 'select',
    query: 'status',
    options: transactionStatusOptions,
  },
];

export const issueFilterConfig: FilterConfig[] = [
  {
    name: 'Date',
    type: 'date',
    query: 'date',
    options: [],
  },
  {
    name: 'Status',
    type: 'select',
    query: 'status',
    options: issuesStatusOptions,
  },
];

export const issueResolutionURL = 'issue-resolutions';
export const issueContactsURL = 'v1/contact-details';
export const transactionBaseURl = 'webbe/v1/transactions';
