import dayjs from 'dayjs';
import { FilterConfig } from '../../../components/Filter/defs';
import { MobileSchema, Schema as SettlementsTableSchema } from '../../../components/newTable';
import { Settlement, SettlementTransaction, SettlementTransactionStatus } from '../../../types/banking/settlements';
import { capitalizeFirstLetter, formatCurrency } from '../../../utils';
import SettlementStatusBadge from './settlement/settlement-status-badge';
import ShortText from '../../../components/ShortText';
import { TransactionAmountWrapper } from '../transactions/styles';
import { creditSettlementTransactionType, settlementTransactionStatus } from '../../../utils/pages/banking';

export const SettlementsFilterConfig: Array<FilterConfig> = [
  {
    name: 'Search',
    type: 'searchInput',
    query: 'settlementId',
    options: [],
    inputPlaceholder: 'Search by Settlement ID',
  },
  {
    name: 'From',
    type: 'date',
    query: 'dateFrom',
    options: [],
    dateFilterType: 'from',
  },
  {
    name: 'To',
    type: 'date',
    query: 'dateTo',
    options: [],
    dateFilterType: 'to',
  },
];

const SettlementDate = ({ date }: { date: string }) => <span>{dayjs(date).format('MMMM DD, YYYY')}</span>;

export const SettlementsSchema: SettlementsTableSchema[] = [
  {
    text: 'Settlement Date',
    accessor: 'settlementDate',
    render: (settlement: Settlement) => <SettlementDate date={settlement.settlementDate} />,
  },
  {
    text: 'Settlement Id',
    accessor: 'settlementId',
    render: (settlement: Settlement) =>
      settlement.settlementId ? <ShortText text={settlement.settlementId} withCopy /> : null,
    hasCopyIcon: true,
  },
  {
    text: 'Transaction Id',
    accessor: 'settlementPostingTransactionId',
    render: (settlement: Settlement) =>
      settlement.settlementPostingTransactionId ? (
        <ShortText text={settlement.settlementPostingTransactionId} withCopy />
      ) : null,
    hasCopyIcon: true,
  },
  { text: 'Settlement Amount', accessor: 'totalSettledAmount', isCurrency: true },
  { text: 'Settlement Fee', accessor: 'settlementFee', isCurrency: true },
  {
    text: 'Status',
    accessor: 'status',
    render: (settlement: Settlement) => <SettlementStatusBadge status={settlement.status} />,
  },
];

export const SplitSettlementsSchema: SettlementsTableSchema[] = [
  {
    text: 'Settlement Date',
    accessor: 'settlementDate',
    render: (settlement: Settlement) => <SettlementDate date={settlement.settlementDate} />,
  },
  {
    text: 'Bank Name',
    accessor: 'settlementBankName',
  },
  {
    text: 'Account Number',
    accessor: 'settlementAccount',
  },
  {
    text: 'Settlement Id',
    accessor: 'settlementId',
    render: (settlement: Settlement) =>
      settlement.settlementId ? <ShortText text={settlement.settlementId} withCopy={false} /> : null,
  },
  { text: 'Settlement Amount', accessor: 'totalSettledAmount', isCurrency: true },
  { text: 'Settlement Fee', accessor: 'settlementFee', isCurrency: true },
  {
    text: 'Status',
    accessor: 'status',
    render: (settlement: Settlement) => <SettlementStatusBadge status={settlement.status} />,
  },
];

export const MobileSettlementsSchema: MobileSchema = {
  lt: {
    accessor: 'settlementId',
    render: (settlement: Settlement) =>
      settlement.settlementId ? <ShortText text={settlement.settlementId} withCopy={false} /> : null,
  },
  rt: {
    accessor: 'totalSettledAmount',
    render: (settlement: Settlement) => <span>{formatCurrency(settlement.totalSettledAmount)}</span>,
  },
  lb: {
    accessor: 'settlementDate',
    render: (settlement: Settlement) => <SettlementDate date={settlement.settlementDate} />,
  },
  rb: {
    accessor: 'status',
    render: (settlement: Settlement) => <SettlementStatusBadge status={settlement.status} />,
  },
};

export const SettlementTransactionsSchema: SettlementsTableSchema[] = [
  { text: 'Transaction date', accessor: 'transactionDate', isDate: true },
  { text: 'Transaction Id', accessor: 'transactionId' },
  { text: 'Transaction Fee', accessor: 'transactionFee', isCurrency: true },
  { text: 'Amount', accessor: 'transactionAmount', isCurrency: true },
  {
    text: 'Status',
    accessor: 'transactionStatus',
    render: (transaction: SettlementTransaction) =>
      transaction.transactionStatus ? <SettlementStatusBadge status={transaction.transactionStatus} /> : null,
  },
];

export const MobileSettlementTransactionsSchema: MobileSchema = {
  lt: {
    accessor: 'transactionId',
  },
  rt: {
    accessor: 'transactionAmount',
    render: (transaction: SettlementTransaction) => <span>{formatCurrency(transaction.transactionAmount)}</span>,
  },
  lb: {
    accessor: 'transactionDate',
    isDate: true,
  },
  rb: {
    accessor: 'transactionStatus',
    render: (transaction: SettlementTransaction) =>
      transaction.transactionStatus ? <SettlementStatusBadge status={transaction.transactionStatus} /> : null,
  },
};

// split-settlement schema
export const SettlementLumpSumTransactionSchema: SettlementsTableSchema[] = [
  {
    text: 'Settlement Date',
    accessor: 'settlementDate',
    render: (settlement: Settlement) => <SettlementDate date={settlement.settlementDate} />,
  },
  {
    text: 'Settlement Id',
    accessor: 'settlementId',
    render: (settlement: Settlement) =>
      settlement.settlementId ? <ShortText text={settlement.settlementId} withCopy={false} /> : null,
  },
  { text: 'Total Settlement Amount', accessor: 'totalSettledAmount', isCurrency: true },
  { text: ' Total Settlement Fee', accessor: 'settlementFee', isCurrency: true },
  {
    text: 'Split Count',
    accessor: 'splitCount',
  },
  {
    text: 'Status',
    accessor: 'status',
    render: (settlement: Settlement) => <SettlementStatusBadge status={settlement.status} />,
  },
];

export const MobileSettlementLumpSumTransactionSchema: MobileSchema = MobileSettlementsSchema;

export const SettlementSplitSettlementSchema: SettlementsTableSchema[] = [
  {
    text: 'Settlement Date',
    accessor: 'settlementDate',
    render: (settlement: Settlement) => <SettlementDate date={settlement.settlementDate} />,
  },
  {
    text: 'Bank',
    accessor: 'bankDetails',
  },
  {
    text: 'Account number',
    accessor: 'accountNumber',
  },
  {
    text: 'Settlement Id',
    accessor: 'settlementId',
    render: (settlement: Settlement) =>
      settlement.settlementId ? <ShortText text={settlement.settlementId} withCopy={false} /> : null,
  },
  { text: 'Amount', accessor: 'settlementAmount', isCurrency: true },
  { text: 'Fee', accessor: 'settlementFee', isCurrency: true },
  {
    text: 'Status',
    accessor: 'status',
    render: (settlement: Settlement) => <SettlementStatusBadge status={settlement.status} />,
  },
];

export const MobileSettlementSplitSettlementSchema: MobileSchema = {
  lt: {
    accessor: 'settlementId',
    render: (settlement: Settlement) =>
      settlement.settlementId ? <ShortText text={settlement.settlementId} withCopy={false} /> : null,
  },
  rt: {
    accessor: 'settlementAmount',
    render: (settlement: Settlement) => <span>{formatCurrency(settlement.totalSettledAmount)}</span>,
  },
  lb: {
    accessor: 'settlementDate',
    render: (settlement: Settlement) => <SettlementDate date={settlement.settlementDate} />,
  },
  rb: {
    accessor: 'status',
    render: (settlement: Settlement) => <SettlementStatusBadge status={settlement.status} />,
  },
};

// single settlement view schema for split settlement

export const SplitSettlementTransactionsSchema: SettlementsTableSchema[] = [
  { text: 'Date', accessor: 'transactionDate', isDate: true },
  {
    text: 'Channel',
    accessor: 'transactionChannel',
    render: (transaction: SettlementTransaction) => (
      <span>{transaction?.transactionChannel ? capitalizeFirstLetter(transaction?.transactionChannel) : ''}</span>
    ),
  },
  {
    text: 'Transaction Type',
    accessor: 'transactionType',
    render: (transaction: SettlementTransaction) => (
      <span>{transaction?.transactionType ? capitalizeFirstLetter(transaction?.transactionType) : ''}</span>
    ),
  },
  { text: 'Transaction Fee', accessor: 'transactionFee', isCurrency: true },
  {
    text: 'Amount',
    accessor: 'transactionAmount',
    render: ({ transactionAmount, transactionStatus }: SettlementTransaction) => {
      const handleTransactionType = (transactionType: SettlementTransactionStatus) => {
        const isCredit = creditSettlementTransactionType.includes(transactionType.toLowerCase());
        return isCredit;
      };

      return (
        <div>
          {settlementTransactionStatus.includes(transactionStatus) ? (
            <TransactionAmountWrapper isCredit={handleTransactionType(transactionStatus)}>
              <p>
                {handleTransactionType(transactionStatus) ? '+' : '-'} {formatCurrency(transactionAmount)}
              </p>
            </TransactionAmountWrapper>
          ) : (
            <p style={{ paddingLeft: '13px' }}> {formatCurrency(transactionAmount)}</p>
          )}
        </div>
      );
    },
  },
  {
    text: 'Status',
    accessor: 'transactionStatus',
    render: (transaction: SettlementTransaction) =>
      transaction.transactionStatus ? <SettlementStatusBadge status={transaction.transactionStatus} /> : null,
  },
];

export const SplitSettlementsMobileTransactionsSchema: MobileSchema = {
  ...MobileSettlementTransactionsSchema,
  lt: {
    accessor: 'transactionChannel',
  },
};
