import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { useToast } from '../../../../hooks/useToast';
import addCategory from '../../../../queries/inventory/categories/addCategory';
import fetchCategories from '../../../../queries/inventory/categories/fetchCategories';
import { Category, HandleAddNewCategory } from '../../../../types/inventory/category';
import { TOAST_TIME_LIMIT } from '../../../inventory/constants';
import { Mixpanel } from '../../../../hooks/useMixpanel';
import { getUserBrowser } from '../../../../utils';

const useAddNewMenuCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | AxiosError | null>(null);
  const [showNewCategoryModal, setShowNewCategoryModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [refetch, setRefetch] = useState<boolean>(false);
  const { inventoryStore: store } = useAppSelector((state) => state.dashboard);
  const { fullUser } = useAppSelector((state) => state.auth);
  const toast = useToast(TOAST_TIME_LIMIT);

  const handleSearchCategories = (name: string) => {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.set('name', name);
    setSearchParams(newParams.toString());
  };

  const handleClearSearch = () => {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.delete('name');
    setSearchParams(newParams.toString());
  };

  const handleCloseNewCategoryModal = () => setShowNewCategoryModal(false);

  const handleOpenNewCategoryModal = () => setShowNewCategoryModal(true);

  const handleRefetchCategories = () => setRefetch(!refetch);

  const handleAddNewCategory: HandleAddNewCategory = async (values, setSubmitting) => {
    const { name, description } = values;

    const { error: addError, result: addResult } = await addCategory({
      name,
      description,
      storeId: store?.storeId || '',
    });
    setSubmitting(false);
    setShowNewCategoryModal(false);
    if (addResult) {
      Mixpanel.track('MENU_ADD_NEW_CATEGORY', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
        category: name,
      });
      toast('success', 'You have successfully added a new category');
      handleRefetchCategories();
    }

    if (addError) {
      Mixpanel.track('MENU_ERROR_ADDING_NEW_CATEGORY', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
        category: name,
      });
      toast('error', 'An error occured while trying to add a new category');
    }
  };

  useEffect(() => {
    const request = new AbortController();
    async function handleFetchCategoriess() {
      setIsLoading(true);
      const { result, error: fetchError } = await fetchCategories({});
      setIsLoading(false);
      if (result?.data) {
        setCategories(result.data.list);
      }
      if (fetchError instanceof Error) setError(fetchError);
    }

    handleFetchCategoriess();
    return () => request.abort();
  }, [refetch, searchParams]);

  return {
    categories,
    error,
    isLoading,
    handleSearchCategories,
    handleClearSearch,
    showNewCategoryModal,
    handleCloseNewCategoryModal,
    handleOpenNewCategoryModal,
    handleAddNewCategory,
    handleRefetchCategories,
  };
};

export default useAddNewMenuCategories;
