import { useIsMobile } from '../../app/hooks';
import ArrowTipIcon from '../../assets/svg/arrow-tip';
import { TooltipArrowWrapper, TooltipText, TooltipWrapper } from './styles';
import { ToolTipProps } from './type';

const Tooltip = ({ label, containerStyle = {}, arrowStyle = {}, showArrow = true, length, textWrap }: ToolTipProps) => {
  const isMobile = useIsMobile();
  return (
    <TooltipWrapper containerStyle={containerStyle}>
      {showArrow ? (
        <TooltipArrowWrapper arrowStyle={arrowStyle}>
          <ArrowTipIcon />
        </TooltipArrowWrapper>
      ) : null}
      <TooltipText textWrap={textWrap} variant="n-text2" color="light" as="div">
        <p>{length && isMobile ? `${label.substring(0, length)}...` : label}</p>
      </TooltipText>
    </TooltipWrapper>
  );
};

export default Tooltip;
