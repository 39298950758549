import { ChangeEvent, useEffect } from 'react';
import { TextInput, Typography, Button, NIcons, IconsEnum } from 'kudi-component-library';
import { Formik } from 'formik';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import Modal from '../../../../components/Modal';
import { Separator } from '../../../overview/styles';
import { UploadCloudIcon } from '../../../../assets/svg';
import DropdownInput from '../../../../components/DropdownInput';
import { Category } from '../../../../types/inventory/category';
import { Discount } from '../../../../types/inventory/discounts';
import { HandleCreateNewProduct, NewProduct } from '../../../../types/inventory/products';
import { useAppSelector } from '../../../../app/hooks';
import {
  ButtonBox,
  CancelText,
  CoverImageDescriptionBox,
  FormInputFlexWrapper,
  FormInputWrapper,
  FormWrapper,
  MenuModalSubHeader,
  ProductImage,
  UploadIconText,
} from '../../styles';
import { CancelTextWrapper } from '../../../inventory/styles';
import useAddNewMenuItem from '../models/useAddNewMenuItem';
import { Mixpanel } from '../../../../hooks/useMixpanel';
import { getUserBrowser } from '../../../../utils';
import NewMealImage from '../../../../assets/images/NewMealFood.png';
import Loading from '../../../teams/auth/components/loading';
import { CreateMenuItems, CreateMenuResponse, MenuListResponse } from '../../../../types/menu/menuList';

const NewValueButton = styled.span`
  position: absolute;
  right: 0;
  cursor: pointer;
  border: solid 1px #eee;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 23px;
  height: 50px;
  background: white;
`;
const FoodImage = styled.img`
  position: absolute;
  border-radius: 12px;
  right: 0;
  bottom: 0;
  width: 173px;
`;

const EditImageBox = styled(Box)`
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  right: 20px;
  z-index: 1;
`;
const EditIconLabel = styled(Box)`
  border-radius: 28px;
  border: 6px solid var(--state-layers-primary-opacity-20, rgba(255, 204, 0, 0.2));
  background: ${({ theme: IconTheme }) => IconTheme.nColors['n-light']};
  box-shadow: 0px 0px 0px 5px rgba(255, 204, 0, 0.2);
  display: flex;
  width: 30px;
  height: 30px;
  padding: 9px;
  justify-content: center;
  align-items: center;
`;
const SearchValueIcon = styled(Box)`
  position: absolute;
  right: 120px;
  top: 24px;
  z-index: 1;
  background: white;
  height: 46px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface AddNewProductModalInterface {
  showModal: boolean;
  onClose: () => void;
  handleOpenNewCategoryModal: () => void;
  handleCreateMenuItem: HandleCreateNewProduct;
  productValues: NewProduct;
  newMenu?: boolean;
  categories: Category[];
  imageUrl: string;
  isLoading: boolean;
  productBody: MenuListResponse | undefined;
  handleCreateMenu: (
    product: CreateMenuItems,
    createMenuImageUrl: string
  ) => Promise<CreateMenuResponse | null | undefined>;
  createMenuListItem: ({ menuId, itemId }: { menuId: string; itemId: number[] }) => Promise<void>;
}

function getOption(items: Category[] | Discount[], id: string) {
  const option = items.find((item) => String(item.id) === id);
  return option ? { label: option.name, value: option.id } : undefined;
}

function getOptions(items: Category[] | Discount[]) {
  return items.map((item) => ({ label: item.name, value: item.id }));
}

const AddNewMenuItemModal = ({
  showModal,
  onClose,
  handleCreateMenuItem,
  productValues,
  newMenu,
  handleOpenNewCategoryModal,
  categories,
  imageUrl,
  isLoading,
  productBody,
  createMenuListItem,
  handleCreateMenu,
}: AddNewProductModalInterface) => {
  const { inventoryStore: store } = useAppSelector((state) => state.dashboard);
  const { validationSchema, handleUploadImage, areCustomFieldsSet } = useAddNewMenuItem({
    productBody,
    createMenuListItem,
    handleCreateMenu,
  });

  const isEditing = !!productValues.id;

  const { fullUser } = useAppSelector((state) => state.auth);
  useEffect(() => {
    if (productValues) {
      Mixpanel.track('MENU_VIEW_EDIT_ITEM', {
        userID: fullUser?.id as string,
        timeStamp: new Date().toString(),
        userBrowser: getUserBrowser(),
      });
      return;
    }

    Mixpanel.track('MENU_VIEW_ADD_ITEM', {
      userID: fullUser?.id as string,
      timeStamp: new Date().toString(),
      userBrowser: getUserBrowser(),
    });
  }, []);

  return (
    <Modal
      showModal={showModal}
      onClose={onClose}
      customDesktopWidth="500px"
      customTabletWidth="65rem"
      padding="0"
      padCloseButton
      modalContentStyles={{ borderRadius: '5px' }}
    >
      <Box p="30px">
        {isLoading ? (
          <Flex minHeight="70vh" backgroundColor="#fff" justifyContent="center" alignItems="center">
            <Loading message="Please wait..." isDefault={false} />
          </Flex>
        ) : (
          <>
            <Typography variant="n-text5" color="dark">
              {isEditing ? 'Edit' : 'Create'} your menu item
            </Typography>
            <MenuModalSubHeader variant="n-text2" color="grey4">
              Enter correct details for the menu item
            </MenuModalSubHeader>
            <Separator height="2rem" />

            <Formik
              initialValues={productValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleCreateMenuItem(values, setSubmitting, imageUrl, newMenu);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Box style={{ position: 'relative' }} pb="100px">
                  <CoverImageDescriptionBox p="20px" my="20px" flexDirection="column">
                    {values.imageUrl ? (
                      <>
                        <EditImageBox>
                          <input
                            type="file"
                            id="sample-doc"
                            hidden
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleUploadImage(event, setFieldValue)}
                          />
                          <label htmlFor="sample-doc" style={{ cursor: 'pointer' }}>
                            <EditIconLabel>
                              <span>
                                <NIcons icon={IconsEnum.OUTLINE_EDIT_V} height="12" width="12" />
                              </span>
                            </EditIconLabel>
                          </label>
                        </EditImageBox>

                        <ProductImage src={values.imageUrl} alt="menu image" />
                      </>
                    ) : (
                      <>
                        <Flex flexDirection="column">
                          <Typography variant="n-text2" style={{ marginBottom: '5px' }}>
                            You have no single item photo. Your items sell better when you have a well-taken, bright
                            photos.
                          </Typography>
                          <Typography color="n-grey2a" variant="n-text1">
                            800x400px (jpeg, png or svg) – Max. file size is 4MB
                          </Typography>
                        </Flex>
                        <Box mt="20px">
                          <input
                            type="file"
                            id="sample-doc"
                            hidden
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleUploadImage(event, setFieldValue)}
                          />
                          <label htmlFor="sample-doc" style={{ cursor: 'pointer' }}>
                            <Flex style={{ gap: '10px' }} alignItems="center">
                              <UploadCloudIcon />
                              <UploadIconText variant="n-text2">Upload cover photo</UploadIconText>
                            </Flex>
                          </label>
                        </Box>
                        <FoodImage src={NewMealImage} alt="food" />
                      </>
                    )}
                  </CoverImageDescriptionBox>
                  <Separator height="2rem" />

                  <FormWrapper>
                    <FormInputFlexWrapper>
                      <FormInputWrapper>
                        <TextInput
                          width="100%"
                          name="name"
                          label="Name of Item"
                          placeholder="e.g Rice, Bread, Burger"
                          value={values.name || ''}
                          onChange={handleChange}
                          error={touched.name && errors.name ? errors.name : undefined}
                          disabled={isSubmitting}
                          id="menu-add-new-meal-name-input"
                        />
                      </FormInputWrapper>
                    </FormInputFlexWrapper>
                    <FormInputWrapper>
                      <TextInput
                        width="100%"
                        name="description"
                        label="Meal description"
                        placeholder="Describe your Meal"
                        value={values.description || ''}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        error={touched.description && errors.description ? errors.description : undefined}
                        id="menu-add-new-meal-description-input"
                      />
                    </FormInputWrapper>
                    <FormInputFlexWrapper>
                      <FormInputWrapper>
                        <TextInput
                          width="100%"
                          name="price"
                          type="number"
                          label="Price (NGN)"
                          placeholder="NGN 0.00"
                          value={values.price}
                          disabled={isSubmitting}
                          onChange={handleChange}
                          error={touched.price && errors.price ? errors.price : undefined}
                          id="menu-add-new-meal-price-input"
                        />
                      </FormInputWrapper>
                    </FormInputFlexWrapper>
                    <FormInputFlexWrapper>
                      <FormInputWrapper>
                        <NewValueButton onClick={() => handleOpenNewCategoryModal()}>
                          <NIcons icon={IconsEnum.OUTLINE_ADD} /> <Typography>Add New</Typography>
                        </NewValueButton>
                        <SearchValueIcon>
                          <NIcons icon={IconsEnum.OUTLINE_CHEVRON_DOWN} />
                        </SearchValueIcon>
                        <DropdownInput
                          label="Meal Category"
                          name="categoryId"
                          options={[...getOptions(categories)]}
                          width="100%"
                          onChange={(newValue) => {
                            setFieldValue('categoryId', newValue?.value);
                          }}
                          marginTop="0px"
                          defaultValue={getOption(categories, values.categoryId)}
                        />
                      </FormInputWrapper>
                    </FormInputFlexWrapper>
                    <Separator height="2rem" />
                    <ButtonBox>
                      <Button
                        disabled={isSubmitting || !(isValid && dirty)}
                        onClick={() => {
                          setFieldValue('storeId', store?.storeId);
                          if (areCustomFieldsSet(values)) handleSubmit();
                        }}
                        width="100%"
                        height="45px"
                        variant="small"
                        id="meni-add-new-meal-submit-button"
                      >
                        {isEditing ? 'Edit and Publish Item' : 'Publish Item'}
                      </Button>
                      {productValues.published ? (
                        <CancelTextWrapper>
                          <CancelText
                            variant="n-text2"
                            color="dark"
                            onClick={() => {
                              if (!isSubmitting) {
                                setFieldValue('published', false);
                                if (areCustomFieldsSet(values)) handleSubmit();
                              }
                            }}
                            id="menu-add-new-meal-cancel-button"
                          >
                            Save as draft
                          </CancelText>
                        </CancelTextWrapper>
                      ) : null}
                    </ButtonBox>
                  </FormWrapper>
                </Box>
              )}
            </Formik>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AddNewMenuItemModal;
