import { ApiResponse } from '../../../../types/redux';
import axios from '../../../../utils/http';

export interface TransferRequestData {
  id: number;
  transactionId?: string;
  referenceId?: string;
  sessionId?: string;
  businessId: number;
  bankName: string;
  bankCode: string;
  account: string;
  beneficiaryName: string;
  amount: string;
  transferType: string;
  frequency: string;
  scheduledDate: string;
  repeatPayment: boolean;
  narration: string;
  submittedBy: {
    id: string;
    firstName: string;
    lastName: string;
    role: string;
  };
  ipCountry: string;
  ipAddress: string;
  deviceId: string | null;
  deviceSource: string | null;
  notificationSent: boolean;
  approvedBy: string | null;
  approvedDate: string | null;
  paymentRequestStatus: string;
  createdAt: string;
}

export interface TransferRequestResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: {
    content: TransferRequestData[];
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalRows: number;
    totalElements: number;
    pageMeta: {
      currentPage: number;
      pageSize: number;
      totalPages: number;
      totalRows: number;
      totalElements: number;
    };
  };
  dataList?: null;
  meta?: null;
  error?: string | null;
}

interface UpdateTransferResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: TransferRequestData;
  dataList?: null;
  meta?: null;
  error?: string | null;
}

interface UpdateTransferRequest {
  paymentRequestId: string;
  paymentRequestStatus: string;
  userPin: string;
  notes?: string;
}

export const blankTransactionResponse: TransferRequestResponse = {
  data: {
    content: [],
    currentPage: 0,
    pageSize: 0,
    totalPages: 0,
    totalRows: 0,
    totalElements: 0,
    pageMeta: {
      currentPage: 0,
      pageSize: 0,
      totalPages: 0,
      totalRows: 0,
      totalElements: 0,
    },
  },
  message: '',
  status: false,
};

export const sampleTransferRequestData: TransferRequestResponse = {
  status: true,
  message: 'Payment request found',
  data: {
    content: [
      {
        id: 470497,
        businessId: 253622,
        bankName: 'Union Bank',
        bankCode: '044',
        account: '2209068957',
        beneficiaryName: 'REDMOUSE514 Pty. Ltd.',
        amount: '1000.00',
        transferType: 'IMMEDIATELY',
        frequency: 'DAILY',
        scheduledDate: '2021-09-14T00:00:00.000Z',
        repeatPayment: false,
        narration: 'Payment for service',
        submittedBy: {
          id: '1234',
          firstName: 'Akinkunmi',
          lastName: 'Oyehan',
          role: 'Accountant',
        },
        ipCountry: 'ngn',
        ipAddress: 'afsdf',
        deviceId: null,
        deviceSource: null,
        notificationSent: false,
        approvedBy: null,
        approvedDate: null,
        paymentRequestStatus: 'PENDING_APPROVAL',
        createdAt: '2021-09-14T00:00:00.000Z',
      },
      {
        id: 470497,
        businessId: 253622,
        transactionId: 'Web-Transfer-In-47D0E-48ca679e-1324-4e23-bbd7-778993',
        sessionId: 'Web-Transfer-In-47D0E-48ca679e-1324-4e23-bbd7-778993',
        bankName: 'Zenith Bank',
        bankCode: '044',
        account: '2209068957',
        beneficiaryName: 'REDMOUSE514 Pty. Ltd.',
        amount: '1000.00',
        transferType: 'IMMEDIATELY',
        frequency: 'DAILY',
        scheduledDate: '2021-09-14T00:00:00.000Z',
        repeatPayment: false,
        narration: 'Payment for service',
        submittedBy: {
          id: '1234',
          firstName: 'Toluwase',
          lastName: 'Collins',
          role: 'Accountant',
        },
        ipCountry: 'ngn',
        ipAddress: 'afsdf',
        deviceId: null,
        deviceSource: null,
        notificationSent: false,
        approvedBy: 'Isaac Ezeh',
        approvedDate: '2021-09-14T00:00:00.000Z',
        paymentRequestStatus: 'APPROVED',
        createdAt: '2021-09-10T00:00:00.000Z',
      },
      {
        id: 470497,
        businessId: 253622,
        bankName: 'Access Bank',
        bankCode: '044',
        account: '2209068957',
        beneficiaryName: 'REDMOUSE514 Pty. Ltd.',
        amount: '1000.00',
        transferType: 'IMMEDIATELY',
        frequency: 'DAILY',
        scheduledDate: '2021-09-14T00:00:00.000Z',
        repeatPayment: false,
        narration: 'Payment for service',
        submittedBy: {
          id: '1234',
          firstName: 'Kelechi',
          lastName: 'Uma',
          role: 'Accountant',
        },
        ipCountry: 'ngn',
        ipAddress: 'afsdf',
        deviceId: null,
        deviceSource: null,
        notificationSent: false,
        approvedBy: null,
        approvedDate: null,
        paymentRequestStatus: 'REJECTED',
        createdAt: '2021-09-12T00:00:00.000Z',
      },
      {
        id: 470497,
        businessId: 253622,
        bankName: 'Access Bank',
        bankCode: '044',
        account: '2209068957',
        beneficiaryName: 'REDMOUSE514 Pty. Ltd.',
        amount: '1000.00',
        transferType: 'IMMEDIATELY',
        frequency: 'DAILY',
        scheduledDate: '2021-09-14T00:00:00.000Z',
        repeatPayment: false,
        narration: 'Payment for service',
        submittedBy: {
          id: '1234',
          firstName: 'Usman',
          lastName: 'Adio',
          role: 'Accountant',
        },
        ipCountry: 'ngn',
        ipAddress: 'afsdf',
        deviceId: null,
        deviceSource: null,
        notificationSent: false,
        approvedBy: null,
        approvedDate: null,
        paymentRequestStatus: 'EXPIRED',
        createdAt: '2021-09-12T00:00:00.000Z',
      },
    ],
    currentPage: 0,
    pageSize: 2,
    totalPages: 1,
    totalRows: 1,
    totalElements: 0,
    pageMeta: {
      currentPage: 0,
      pageSize: 2,
      totalPages: 1,
      totalRows: 1,
      totalElements: 0,
    },
  },
  dataList: null,
  meta: null,
  error: null,
};

const updateTransferStatus = async (payload: UpdateTransferRequest) => {
  try {
    const result: UpdateTransferResponse = await axios.post('/vas/payouts/v1/payment-request/update', {
      ...payload,
    });
    return await Promise.resolve({ error: null, result });
  } catch (error) {
    return Promise.resolve({ error, result: null });
  }
};

export { updateTransferStatus };
