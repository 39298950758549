import styled from 'styled-components';

export const CrossPlatformContentWrapper = styled.div`
  width: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.nColors['n-grey1']};
  height: 100%;
  overflow-y: auto;
`;

export const CrossPlatformMainPageContentHolder = styled.div`
  height: calc(100vh - 3rem);
  width: 100%;
  padding-bottom: 12rem;
  overflow-y: scroll;

  @media ${({ theme }) => `${theme.media.xl}`} {
    padding-bottom: 0;
  }
`;
