import styled from 'styled-components';

export const FormInputWrapper = styled.div<{ isError?: boolean; padding?: string; width?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || '10px 15px'};
  height: 56px;
  justify-content: center;
  background: rgba(242, 242, 242, 0.4);
  label {
    font-size: 10px;
    font-weight: 500;
    color: #808080;
    line-height: 16px;
  }
  input::placeholder {
    color: #808080;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: none !important;
  }
  border: ${({ isError, theme }) =>
    isError ? `1px solid ${theme.nColors['n-red']}` : `1px solid ${theme.nColors['n-grey1']}`};
  border-radius: 8px;
  &:hover {
    border: 1px solid #ffd633;
    background: #fffcf2;
  }
  ${({ width }) => width && `width: ${width}`};
`;

export const FormInput = styled.input`
  outline: none;
  border: none;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  background: transparent;
`;

export const FormInputInnerWrapper = styled.div`
  display: flex;
`;

export const PhoneInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
`;

export const PhoneInputSeparator = styled.div`
  height: 20px;
  width: 2px;
  background: rgba(230, 230, 230, 1);
  margin-right: 10px;
`;

export const InputItemErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.nColors['n-red']};

  span {
    display: block;
    margin-left: 4px;
  }
`;
