import React, { useEffect } from 'react';

const CountDown = ({
  minutes,
  seconds,
  cb,
  hidden,
}: {
  minutes: number;
  seconds: number;
  cb: () => void;
  hidden?: boolean;
}) => {
  const [[mins, secs], setTime] = React.useState([0, 0]);

  const tick = () => {
    if (mins === 0 && secs === 0) {
      setTime([0, 0]);
      cb();
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  useEffect(() => {
    setTime([minutes, seconds]);
  }, [minutes, seconds]);
  return hidden ? null : <div>{mins > 0 || secs > 0 ? `0${mins}:${secs < 10 ? `0${secs}` : secs}` : ''}</div>;
};

export default CountDown;
