import React from 'react';
import { Text } from 'kudi-component-library';
import { timeData } from '../../data';
import { FullBox, FullFlex } from '../../styles/generic';
import { Clock } from '../../assets/svg';

export default function TimePicker({
  selectedTime,
  setTimeVal,
  showTimes,
  setShowTimes,
}: {
  selectedTime: string;
  showTimes: boolean;
  setTimeVal: React.Dispatch<React.SetStateAction<string>>;
  setShowTimes: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <FullBox>
      <Text fontSize="14px" fontWeight="regular" color="#444444" mb="4px">
        Time
      </Text>
      <FullFlex
        alignItems="center"
        onClick={() => setShowTimes(!showTimes)}
        style={{
          marginBottom: '10px',
          width: '100%',
          height: '45px',
          border: '1px solid #EFEFEF',
          borderRadius: '4px',
          outline: 'none',
          fontSize: '14px',
          padding: '0 12px',
        }}
      >
        <Clock />
        <Text ml="5px" fontWeight="regular">
          {selectedTime || '0.00'}
        </Text>
      </FullFlex>
      {showTimes && (
        <FullBox
          width="100%"
          mb="30px"
          p="20px 15px"
          style={{
            backgroundColor: '#FBFBFB',
            border: '1px solid #EFEFEF',
            overflowY: 'scroll',
            height: '300px',
          }}
        >
          {timeData.map((item) => (
            <FullBox
              style={{
                fontSize: '14px',
                fontWeight: 'bolder',
                lineHeight: '21px',
                marginBottom: '6px',
                cursor: 'pointer',
                letterSpacing: '0px',
                padding: '5px 15px',
              }}
              onClick={() => {
                setTimeVal(item?.text);
                setShowTimes(false);
              }}
            >
              {item?.text}
            </FullBox>
          ))}
        </FullBox>
      )}
    </FullBox>
  );
}
