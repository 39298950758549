import useRedux from '../../hooks/useRedux';
import { toggleShow, updateCurrentPage } from './reducer/slice';
import Home from './components/home';
import OfficeAddress from './components/home_extra/office_address';
import { SupportPageContentWrapper, SupportPageWrapper } from './styles';
import MyIssues from './components/my-issues';
import IssueDetails from './components/details';
import Summary from './components/summary';
import IssueCreation from './components/issue_creation';
import { SupportPages } from './defs';
import ErrorBoundary from '../../utils/errorBoundary';
import CallLine from './components/home_extra/call_line';

const SupportView = () => {
  const {
    state: {
      support: { show, currentPage },
    },
  } = useRedux();

  const pageMap: Record<SupportPages, JSX.Element> = {
    home: <Home />,
    'office-adrress': <OfficeAddress />,
    'my-issues': <MyIssues />,
    details: <IssueDetails />,
    summary: <Summary />,
    'issue-creation': <IssueCreation />,
    'call-line': <CallLine />,
  };
  const { dispatch } = useRedux();

  const onclose = () => {
    dispatch(updateCurrentPage('home'));
    dispatch(toggleShow());
  };
  return show ? (
    <SupportPageWrapper onClick={onclose}>
      <SupportPageContentWrapper onClick={(e) => e.stopPropagation()}>
        <ErrorBoundary>{pageMap[currentPage]}</ErrorBoundary>
      </SupportPageContentWrapper>
    </SupportPageWrapper>
  ) : null;
};

export default SupportView;
