import { createContext, useReducer, useContext, ReactNode } from 'react';
import { ToastType } from '../components/Toast/type';

interface InitialState {
  toasts: ToastType[];
}

type UpdateAction = {
  type: string;
  toast: ToastType;
  id?: string | number;
};

const initialState: InitialState = {
  toasts: [],
};

const dispatchInitial: (action: UpdateAction) => void = () => {};

const ToastStateContext = createContext(initialState);
const ToastDispatchContext = createContext(dispatchInitial);

function ToastReducer(state: InitialState, action: UpdateAction) {
  switch (action.type) {
    case 'ADD_TOAST': {
      const toastArrayLength = state.toasts.length;
      const prevMessage = state.toasts[toastArrayLength - 1]?.message;
      if (prevMessage === action.toast.message) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    }
    case 'DELETE_TOAST': {
      const updatedToasts = state.toasts.filter((e) => e.id !== action.id);
      return {
        ...state,
        toasts: updatedToasts,
      };
    }
    default: {
      throw new Error('unhandled action');
    }
  }
}

export function ToastProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(ToastReducer, initialState);

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>{children}</ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
}

export const useToastStateContext = () => useContext(ToastStateContext);
export const useToastDispatchContext = () => useContext(ToastDispatchContext);
