import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ErrorBoundary from '../../../utils/errorBoundary';
import { NavigationTab } from '../../navigationTab';
import { PageWrapper, HeaderText } from '../../../styles/generic';
import { fetchBusinessDetails } from '../../../features/business/thunkActions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useToast } from '../../../hooks/useToast';

interface SettingsLayoutProps {
  routes: {
    [x: string]: string;
  };
}

export const SettingsLayout = ({ routes }: SettingsLayoutProps) => {
  const toast = useToast(3000);
  const dispatch = useAppDispatch();
  const match = useLocation();
  const activeRoute = match.pathname.split('/')[4];

  const { parentBusinessId } = useAppSelector((state) => state.auth);
  // fetch business details
  useEffect(() => {
    if (!parentBusinessId) return;

    const onFailure = (error: { data: { message: string } }) => {
      toast('error', error?.data?.message || `Couldn't fetch business profile`);
    };

    dispatch(
      fetchBusinessDetails({
        onFailure,
      })
    );
  }, [dispatch, parentBusinessId]);
  return (
    <PageWrapper>
      <HeaderText>Settings</HeaderText>
      <NavigationTab
        itemsMr="10px"
        active={activeRoute}
        options={Object.values(routes).map((item) => ({ text: item, route: item }))}
      />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </PageWrapper>
  );
};
