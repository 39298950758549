import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IconsEnum, NIcons } from 'kudi-component-library';
import Tooltip from '../Tooltip';
import { TOAST_TIMER } from '../../utils/constants';
import { useToast } from '../../hooks/useToast';
import useIsMobile from '../../hooks/useIsMobile';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  position: relative;
`;

const IconWrapper = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;

  svg {
    color: inherit;
  }
`;

type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

const ShortText = ({
  text,
  withCopy = true,
  length = 20,
  mobileTextLength,
  hasToolTip = false,
}: {
  text: string;
  withCopy?: boolean;
  length?: number;
  mobileTextLength?: number;
  hasToolTip?: boolean;
}) => {
  const toast = useToast(TOAST_TIMER);
  const isMobile = useIsMobile(1363);
  const [textLength, setTextLength] = useState<number>(length);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleCopy = (event: ClickEvent) => {
    event.preventDefault();

    navigator.clipboard.writeText(text);
    toast('success', 'Reference copied to clipboard.');
  };

  useEffect(() => {
    if (isMobile) {
      const mobileLength: number = mobileTextLength ?? 6;
      setTextLength(mobileLength);
    } else {
      setTextLength(length);
    }
  }, [isMobile, length]);

  return (
    <Wrapper onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      {showTooltip && hasToolTip && text ? (
        <Tooltip
          label={text}
          showArrow={false}
          containerStyle={{
            bottom: '25px',
          }}
        />
      ) : null}

      {text && String(text).trim()?.length > textLength ? (
        <span>{`${text.substring(0, textLength)}...`}</span>
      ) : (
        <span>{text}</span>
      )}

      {withCopy ? (
        <IconWrapper onClick={(event: ClickEvent) => handleCopy(event)}>
          <NIcons icon={IconsEnum.OUTLINE_DUPLICATE} size="sm" />
        </IconWrapper>
      ) : null}
    </Wrapper>
  );
};

export default ShortText;
