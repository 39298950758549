import Skeleton from '../../../../components/skeleton';
import { IssueOptionWrapper } from '../../styles';
import IssueOptionItem, { OptionItemProp } from './issue_option';

const IssueOptionList = ({ list, loading }: { list: OptionItemProp[]; loading?: boolean }) => (
  <IssueOptionWrapper>
    {loading
      ? Array(3)
          .fill('a')
          .map(() => <Skeleton height="40px" key={Math.random()} />)
      : list?.map((item) => <IssueOptionItem {...item} key={Math.random()} />)}
  </IssueOptionWrapper>
);

export default IssueOptionList;
