import { Badge, BadgeStatus } from 'kudi-component-library';
import Header from '../base/header';
import { IssueDetailsText, IssueDetailsWrapper, SupportPageDetailsWrapper } from '../../styles';
import useRedux from '../../../../hooks/useRedux';
import { selectIssueItem, toggleShow, updateCurrentPage } from '../../reducer/slice';
import ActiveInfo from './active_info';
import CompletedInfo from './completed_info';
import IssueComments from '../base/issue_comments';
import DocumentViewer from '../base/document_viewer';
import { IssueListFilterStatus } from '../../defs';
import { NoShowOnDesktop } from '../../../overview/styles';
import Skeleton from '../../../../components/skeleton';

const IssueDetails = () => {
  const {
    dispatch,
    state: {
      support: {
        issueList: { selectedIssue, closeAll, isLoading },
      },
    },
  } = useRedux();

  const details = {
    variant: selectedIssue?.status === IssueListFilterStatus.RESOLVED ? 'default' : 'success',
    text: selectedIssue?.status === IssueListFilterStatus.RESOLVED ? 'Resolved' : 'Active',
  };

  const goBack = () => {
    dispatch(selectIssueItem(null));
    if (closeAll) {
      dispatch(toggleShow());
      dispatch(updateCurrentPage('home'));
    } else {
      dispatch(updateCurrentPage('my-issues'));
    }
  };

  const title = selectedIssue && !isLoading ? `#${selectedIssue?.id || ''}` : '';

  return (
    <>
      <Header
        desktopTitle={title}
        desktopCloseAction={goBack}
        mobileBackAction={goBack}
        desktopBackAction={goBack}
        mobileTitle="Details"
      />
      <SupportPageDetailsWrapper top="136px" mobilePadding="20px">
        <IssueDetailsWrapper>
          <IssueDetailsText>
            <span>Ticket status:</span>
            {isLoading ? <Skeleton height="30px" width="100px" /> : null}
            {!isLoading && selectedIssue ? (
              <Badge variant={details.variant as BadgeStatus}>{details.text}</Badge>
            ) : null}
          </IssueDetailsText>
          <NoShowOnDesktop>
            <IssueDetailsText>
              <span>Issue ID:</span> {isLoading ? <Skeleton width="30px" height="24px" /> : <p>{title}</p>}
            </IssueDetailsText>
          </NoShowOnDesktop>
          {selectedIssue?.status === IssueListFilterStatus.RESOLVED ? <CompletedInfo /> : <ActiveInfo />}
          <IssueComments />
          <DocumentViewer />
        </IssueDetailsWrapper>
      </SupportPageDetailsWrapper>
    </>
  );
};

export default IssueDetails;
