import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/merchantAcqServiceHttp';
import { ReduxAction } from '../../types/redux';
import { GraphDataResponse } from '../../types/overviewCharts';

export type FilterValueRecordType = string | string[];
export interface FetchTransactionSummaryArgs extends ReduxAction {
  dateFrom: string;
  dateTo: string;
  filterParams?: Record<string, FilterValueRecordType> | null;
}

export const fetchTransactionSummary = createAsyncThunk(
  '/customer/fetch-transaction-summary',
  async ({ dateFrom, dateTo, filterParams, onFailure }: FetchTransactionSummaryArgs, { rejectWithValue }) => {
    try {
      const { graphData, data }: GraphDataResponse = await axios.get(
        `/webbe/v1/transactions/find-by-day-summary?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        { params: filterParams }
      );
      return { graphData, data };
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
