// source: src/proto/qr.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.Money', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.QrCodeStatus', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.QrCodeType', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.RequestMetaData', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.TransactionStatus', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse', null, global);
goog.exportSymbol('proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.displayName = 'proto.com.nomba.business_banking.qrcodeservice.RequestMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.Money = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.Money, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.Money.displayName = 'proto.com.nomba.business_banking.qrcodeservice.Money';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.displayName = 'proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.displayName = 'proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.displayName = 'proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    uniquedeviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipaddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ipcountry: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appversion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    posrrn: jspb.Message.getFieldWithDefault(msg, 6, ""),
    postid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    token: jspb.Message.getFieldWithDefault(msg, 8, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
  return proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniquedeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpaddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpcountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppversion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosrrn(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUniquedeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpaddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIpcountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppversion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPosrrn();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPostid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string uniqueDeviceId = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getUniquedeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setUniquedeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ipAddress = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getIpaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setIpaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ipCountry = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getIpcountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setIpcountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string appVersion = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getAppversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setAppversion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string posRrn = 6;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getPosrrn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setPosrrn = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string posTid = 7;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getPostid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setPostid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string token = 8;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string alias = 9;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tag = 10;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.Money.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.Money.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.Money} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.Money.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.Money;
  return proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.Money} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.Money.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.Money} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.Money.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.Money} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.Money.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.Money.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.Money} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.Money.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    otp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    approved: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest;
  return proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 4:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string otp = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pin = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RequestMetaData metaData = 4;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 4));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool approved = 5;
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.setApproved = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.clearApproved = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionRequest.prototype.hasApproved = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse;
  return proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 3:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reference = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TransactionStatus status = 3;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus}
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.getStatus = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.ApprovedTransactionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    narration: jspb.Message.getFieldWithDefault(msg, 5, ""),
    echodata: msg.getEchodata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest;
  return proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 4:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEchodata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEchodata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 bankingEntityId = 3;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional RequestMetaData metaData = 4;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 4));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string narration = 5;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bytes echoData = 6;
 * @return {!(string|Uint8Array)}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getEchodata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes echoData = 6;
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getEchodata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEchodata()));
};


/**
 * optional bytes echoData = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.getEchodata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEchodata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionRequest.prototype.setEchodata = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    narration: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string narration = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitStaticTransactionExternalRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    narration: jspb.Message.getFieldWithDefault(msg, 5, ""),
    echodata: msg.getEchodata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest;
  return proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 4:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEchodata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEchodata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 bankingEntityId = 3;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional RequestMetaData metaData = 4;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 4));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string narration = 5;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bytes echoData = 6;
 * @return {!(string|Uint8Array)}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getEchodata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes echoData = 6;
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getEchodata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEchodata()));
};


/**
 * optional bytes echoData = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.getEchodata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEchodata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionRequest.prototype.setEchodata = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    narration: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string narration = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionExternalRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    narration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    echodata: msg.getEchodata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest;
  return proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEchodata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEchodata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bankingEntityId = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string narration = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bytes echoData = 5;
 * @return {!(string|Uint8Array)}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.getEchodata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes echoData = 5;
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.getEchodata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEchodata()));
};


/**
 * optional bytes echoData = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.getEchodata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEchodata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionRequest.prototype.setEchodata = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    narration: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string narration = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitDynamicTransactionExternalRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    reference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    message: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse;
  return proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 7:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Money money = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string reference = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 bankingEntityId = 5;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string message = 6;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TransactionStatus status = 7;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus}
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.getStatus = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.InitTransactionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    expirydate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    linkedtype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdat: jspb.Message.getFieldWithDefault(msg, 8, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 9, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 11, ""),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    qrcodestatus: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse;
  return proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnername(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirydate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 6:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (reader.readEnum());
      msg.setLinkedtype(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    case 13:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeStatus} */ (reader.readEnum());
      msg.setQrcodestatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getExpirydate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLinkedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCreatedat();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getQrcodestatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ownerName = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getOwnername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setOwnername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Money money = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string expiryDate = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getExpirydate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setExpirydate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 bankingEntityId = 5;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional QrCodeType type = 6;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional QrCodeLinkedType linkedType = 7;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getLinkedtype = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setLinkedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string createdAt = 8;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getCreatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string narration = 9;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string alias = 10;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string tag = 11;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool expired = 12;
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional QrCodeStatus qrCodeStatus = 13;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeStatus}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.getQrcodestatus = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeStatus} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeStatus} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.prototype.setQrcodestatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest;
  return proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 3, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dateupdated: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dateprocessed: jspb.Message.getFieldWithDefault(msg, 7, ""),
    senderbankingentity: jspb.Message.getFieldWithDefault(msg, 8, 0),
    receiverbankingentity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sendername: jspb.Message.getFieldWithDefault(msg, 11, ""),
    receivername: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse;
  return proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateupdated(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateprocessed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSenderbankingentity(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceiverbankingentity(value);
      break;
    case 10:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendername(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceivername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDateupdated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDateprocessed();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSenderbankingentity();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getReceiverbankingentity();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSendername();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReceivername();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional Money money = 1;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 1));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reference = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string narration = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dateCreated = 5;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dateUpdated = 6;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getDateupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setDateupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string dateProcessed = 7;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getDateprocessed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setDateprocessed = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 senderBankingEntity = 8;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getSenderbankingentity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setSenderbankingentity = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 receiverBankingEntity = 9;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getReceiverbankingentity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setReceiverbankingentity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional TransactionStatus status = 10;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getStatus = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string senderName = 11;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getSendername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setSendername = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string receiverName = 12;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.getReceivername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusResponse.prototype.setReceivername = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest;
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 bankingEntityId = 1;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 limit = 1;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesTransactionExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest;
  return proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 bankingEntityId = 1;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestMetaData metaData = 1;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 1));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.GetVirtualQrCodeExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    linkedtype: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest;
  return proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 4:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 5:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (reader.readEnum());
      msg.setLinkedtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getLinkedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional QrCodeType type = 1;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 bankingEntityId = 3;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional RequestMetaData metaData = 4;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 4));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional QrCodeLinkedType linkedType = 5;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.getLinkedtype = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeRequest.prototype.setLinkedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f),
    linkedtype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 4:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (reader.readEnum());
      msg.setLinkedtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
  f = message.getLinkedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional QrCodeType type = 1;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional QrCodeLinkedType linkedType = 4;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType}
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.getLinkedtype = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.LinkQrCodeExternalRequest.prototype.setLinkedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    link: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    linkedtype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    alias: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tag: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse;
  return proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 5:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (reader.readEnum());
      msg.setLinkedtype(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLinkedtype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bankingEntityId = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string link = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional QrCodeType type = 5;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional QrCodeLinkedType linkedType = 6;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getLinkedtype = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setLinkedtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string alias = 7;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tag = 8;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeResponse.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    otp: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 6, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest;
  return proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 2:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 7:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Money money = 1;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 1));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QrCodeType type = 2;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 bankingEntityId = 3;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string otp = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pin = 5;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string narration = 6;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional RequestMetaData metaData = 7;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 7));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    otp: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 5, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 2:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 6:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Money money = 1;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 1));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QrCodeType type = 2;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string otp = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string pin = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.getPin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.setPin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string narration = 5;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional RequestMetaData metaData = 6;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 6));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    link: jspb.Message.getFieldWithDefault(msg, 6, ""),
    otp: jspb.Message.getFieldWithDefault(msg, 7, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse;
  return proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 5:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Money money = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 bankingEntityId = 4;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional QrCodeType type = 5;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string link = 6;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string otp = 7;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string alias = 8;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.CreateDynamicQrCodeResponse.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reference: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest;
  return proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reference = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatusRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    transactionpin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 5, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest;
  return proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 2:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionpin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 6:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTransactionpin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Money money = 1;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 1));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QrCodeType type = 2;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 bankingEntityId = 3;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string transactionPin = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.getTransactionpin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.setTransactionpin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string narration = 5;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional RequestMetaData metaData = 6;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 6));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    transactionpin: jspb.Message.getFieldWithDefault(msg, 3, ""),
    narration: jspb.Message.getFieldWithDefault(msg, 4, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 2:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionpin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNarration(value);
      break;
    case 5:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTransactionpin();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNarration();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional Money money = 1;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 1));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QrCodeType type = 2;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string transactionPin = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.getTransactionpin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.setTransactionpin = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string narration = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.getNarration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.setNarration = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RequestMetaData metaData = 5;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 5));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    otp: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse;
  return proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string otp = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.SignQrCodeTransactionResponse.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest;
  return proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 size = 1;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse;
  return proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.GenerateQrCodesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qrcodetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest;
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 2:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setQrcodetype(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQrcodetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 bankingEntityId = 1;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional QrCodeType qrCodeType = 2;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.getQrcodetype = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.setQrcodetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    qrcodetype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setQrcodetype(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQrcodetype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional QrCodeType qrCodeType = 1;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.getQrcodetype = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.setQrcodetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeList: jspb.Message.toObjectList(msg.getCodeList(),
    proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse;
  return proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.deserializeBinaryFromReader);
      msg.addCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QrCodeLookUpResponse code = 1;
 * @return {!Array<!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.prototype.getCodeList = function() {
  return /** @type{!Array<!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse, 1));
};


/**
 * @param {!Array<!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse>} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.prototype.setCodeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.prototype.addCode = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.nomba.business_banking.qrcodeservice.QrCodeLookUpResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.FetchQrCodesResponse.prototype.clearCodeList = function() {
  return this.setCodeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    terminalid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    money: (f = msg.getMoney()) && proto.com.nomba.business_banking.qrcodeservice.Money.toObject(includeInstance, f),
    sendername: jspb.Message.getFieldWithDefault(msg, 3, ""),
    completedat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    echodata: msg.getEchodata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification;
  return proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminalid(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.Money;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.Money.deserializeBinaryFromReader);
      msg.setMoney(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompletedat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 6:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEchodata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTerminalid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMoney();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.Money.serializeBinaryToWriter
    );
  }
  f = message.getSendername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompletedat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getEchodata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
};


/**
 * optional string terminalId = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getTerminalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setTerminalid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Money money = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.Money}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getMoney = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.Money} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.Money, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.Money|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setMoney = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.clearMoney = function() {
  return this.setMoney(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.hasMoney = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string senderName = 3;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getSendername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setSendername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string completedAt = 4;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getCompletedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setCompletedat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phoneNumber = 5;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional QrCodeType type = 6;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getType = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.QrCodeType} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional TransactionStatus status = 7;
 * @return {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getStatus = function() {
  return /** @type {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.nomba.business_banking.qrcodeservice.TransactionStatus} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bytes echoData = 8;
 * @return {!(string|Uint8Array)}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getEchodata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes echoData = 8;
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getEchodata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEchodata()));
};


/**
 * optional bytes echoData = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEchodata()`
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.getEchodata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEchodata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.QrTransactionNotification.prototype.setEchodata = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bankingentityid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest;
  return proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBankingentityid(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBankingentityid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 bankingEntityId = 2;
 * @return {number}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.getBankingentityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.setBankingentityid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest;
  return proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RequestMetaData metaData = 2;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 2));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalRequest.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    metadata: (f = msg.getMetadata()) && proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse;
  return proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = new proto.com.nomba.business_banking.qrcodeservice.RequestMetaData;
      reader.readMessage(value,proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.nomba.business_banking.qrcodeservice.RequestMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RequestMetaData metaData = 3;
 * @return {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.getMetadata = function() {
  return /** @type{?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData} */ (
    jspb.Message.getWrapperField(this, proto.com.nomba.business_banking.qrcodeservice.RequestMetaData, 3));
};


/**
 * @param {?proto.com.nomba.business_banking.qrcodeservice.RequestMetaData|undefined} value
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse} returns this
*/
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse} returns this
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.nomba.business_banking.qrcodeservice.UnLinkQrCodeExternalResponse.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.com.nomba.business_banking.qrcodeservice.TransactionStatus = {
  SUCCESSFUL: 0,
  PENDING: 1,
  FAILED: 2,
  PENDING_APPROVAL: 3,
  QR_EXPIRED: 4,
  PROCESSING: 5,
  APPROVAL_TIMEOUT: 6,
  DECLINED: 7,
  CANCELLED: 8
};

/**
 * @enum {number}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeStatus = {
  ACTIVE: 0,
  INACTIVE: 1,
  EXPIRED: 2,
  PAID: 3,
  USED: 4,
  BLOCKED: 5,
  QR_CODE_STATUS_UNDEFINED: 6
};

/**
 * @enum {number}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeType = {
  CUSTOMER_STATIC: 0,
  MERCHANT_STATIC: 1,
  CUSTOMER_DYNAMIC: 2,
  MERCHANT_DYNAMIC: 3,
  QR_CODE_TYPE_UNDEFINED: 4
};

/**
 * @enum {number}
 */
proto.com.nomba.business_banking.qrcodeservice.QrCodeLinkedType = {
  PHYSICAL: 0,
  VIRTUAL: 1,
  NONE: 2
};

goog.object.extend(exports, proto.com.nomba.business_banking.qrcodeservice);
