import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigationSubrouteInterface } from '../../types/navigation';

import { NavigationSubRouteHolder } from './components';

import { useNavigationContext } from './data';

interface NavigationSubRouteProps extends NavigationSubrouteInterface {
  active: boolean;
  prevPath: string;
}

const NavigationSubRoute = ({ name, active, path, prevPath }: NavigationSubRouteProps) => {
  const navigate = useNavigate();
  const match = useLocation();

  const { setOpen } = useNavigationContext();

  const handleClick = () => {
    navigate(`${prevPath}/${path}`);
    setOpen(false);
  };

  const selected = active && match.pathname.split('/')[4] === path;
  return (
    <NavigationSubRouteHolder
      id="navigation-content-options-sub-wrapper-content"
      onClick={handleClick}
      active={selected}
    >
      {name}
    </NavigationSubRouteHolder>
  );
};

export default NavigationSubRoute;
