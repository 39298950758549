import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBusinessDetails } from './thunkActions';
import { Business } from '../../types/business';

interface InitialState {
  isLoading: boolean;
  business: Business | null | undefined;
}

const initialState: InitialState = {
  isLoading: false,
  business: {
    id: '',
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    name: '',
    merchantType: '',
    email: '',
    businessType: '',
    businessCategory: '',
    verified: false,
    phoneNumber: '',
    website: null,
    isOutlet: false,
    hasOutlets: false,
    parentBusinessId: null,
    boltAgentId: '',
    walletId: '',
    coreUserId: '',
    businessRegistrationState: [],
    businessProfile: {
      id: '',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      firstName: null,
      lastName: null,
      middleName: null,
      gender: null,
      dateOfBirth: null,
      bvn: null,
      idType: null,
      idDocumentUrl: null,
      documentsReviewStatus: null,
      directors: [],
      businessDocument: {
        id: 372,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        memorandumDocUrl: null,
        proofOfAddressDocUrl: null,
        certOfIncorporationDocUrl: null,
        shareHoldingDocUrl: null,
        cacStatusReportDocUrl: null,
        businessLicenseDocUrls: null,
        governmentContractDocUrls: null,
        cooperativeLicenseDocUrl: null,
        constitutionDocUrl: null,
        listOfExecutivesUrl: null,
        listOfMembersUrl: null,
      },
      businessName: null,
      businessCategory: null,
      businessType: null,
      businessAddress: {
        id: '',
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        areaInfo: '',
        state: '',
        city: '',
        lga: '',
        landmark: '',
        address: '',
      },
      businessSector: null,
      taxIdNumber: null,
    },
    registeredUsers: [],
    businessPaymentOptions: null,
    creatorUserId: '',
    cashoutAccountCreated: true,
    outlet: false,
    onboardingCompleteV1: false,
  },
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //   fetch business
    builder
      .addCase(fetchBusinessDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBusinessDetails.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchBusinessDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.business = action.payload;
      });
  },
});

export default businessSlice.reducer;
export * from './thunkActions';
