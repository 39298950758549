import { IconsEnum, NIcons } from 'kudi-component-library';
import {
  IssueOptionLeft,
  IssueOption,
  IssueOptionExtra,
  IssueOptionInconWrapper,
  IssueOptionArrowWrapper,
  IssueOptionBodyWrapper,
} from '../../styles';
import Spinner from '../../../../assets/svg/spinner';

export interface OptionItemProp {
  Icon: JSX.Element;
  badge?: boolean;
  text: string;
  description?: string;
  extra?: React.ReactNode;
  action: () => void;
  iconBg?: boolean;
  open?: boolean;
  loading?: boolean;
  href?: string;
}

const IssueOptionItem = ({
  Icon,
  badge,
  text,
  description,
  extra,
  action,
  iconBg,
  open,
  loading,
  href,
}: OptionItemProp) => (
  <IssueOptionBodyWrapper href={href} onClick={action}>
    <IssueOption>
      <IssueOptionLeft>
        <IssueOptionInconWrapper iconBg={iconBg}>
          {badge ? <div /> : null}
          {Icon}
        </IssueOptionInconWrapper>

        <p>
          <span>{text}</span>
          {description ? <span className="details">{description}</span> : null}
        </p>
      </IssueOptionLeft>
      <IssueOptionArrowWrapper open={open}>
        {loading ? <Spinner /> : <NIcons icon={IconsEnum.OUTLINE_CHEVRON_RIGHT} />}
      </IssueOptionArrowWrapper>
    </IssueOption>
    {extra ? <IssueOptionExtra>{extra}</IssueOptionExtra> : null}
  </IssueOptionBodyWrapper>
);

export default IssueOptionItem;
