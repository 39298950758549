/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useState } from 'react';
import { Button, Typography, OtpInput, TextInput } from 'kudi-component-library';
import Modal from '../../../components/Modal';
import { BodyWrapper, HeaderWrapper, TwoFactorModalWrapper } from '../../settings/styles';
import { FullBox } from '../../../styles/generic';
import { CautionIcon2, Spinner, WithdrawSuccessIcon } from '../../../assets/svg';
import { CancelText, CancelTextWrapper } from '../../outlets/outlet/modals/outlet-modal-content';
import { TransferRequestData, updateTransferStatus } from './utils';
import { formatCurrency } from '../../../utils';
import TransferRejectedIcon from '../../../assets/svg/transfer-rejected';

const ApprovedScreen = ({
  amount,
  beneficiaryName,
  setReload,
  handleCloseModal,
}: {
  amount: string | number;
  beneficiaryName: string;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => void;
}) => (
  <FullBox>
    <HeaderWrapper>
      <WithdrawSuccessIcon />
      <BodyWrapper>
        <Typography fontSize="24px" lineHeight="28px" fontWeight={700}>
          Transfer sent
        </Typography>
      </BodyWrapper>
    </HeaderWrapper>

    <Typography variant="n-text3" style={{ textAlign: 'center' }}>
      You have successfully approved a transfer of <br />{' '}
      <span style={{ fontWeight: 700 }}>{formatCurrency(Number(amount))}</span> to{' '}
      <span style={{ fontWeight: 700 }}>{beneficiaryName}</span>.
    </Typography>

    <Button
      style={{ marginTop: '8rem' }}
      variant="medium"
      width="100%"
      onClick={() => {
        setReload(true);
        handleCloseModal();
      }}
    >
      Done
    </Button>
  </FullBox>
);
const RejectedScreen = ({
  amount,
  beneficiaryName,
  submittedBy,
  setReload,
  handleCloseModal,
}: {
  amount: string | number;
  beneficiaryName: string;
  submittedBy: string;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => void;
}) => (
  <FullBox>
    <HeaderWrapper>
      <TransferRejectedIcon />
      <BodyWrapper>
        <Typography fontSize="24px" lineHeight="28px" fontWeight={700}>
          Transfer rejected
        </Typography>
      </BodyWrapper>
    </HeaderWrapper>

    <Typography variant="n-text3" style={{ textAlign: 'center' }}>
      You have rejected a transfer of <br /> <span style={{ fontWeight: 600 }}>{formatCurrency(Number(amount))}</span>{' '}
      to <span style={{ fontWeight: 700 }}>{beneficiaryName}</span>, initiated by{' '}
      <span style={{ fontWeight: 700 }}>{submittedBy}</span>.
    </Typography>

    <Button
      style={{ marginTop: '4rem' }}
      variant="medium"
      width="100%"
      onClick={() => {
        setReload(true);
        handleCloseModal();
      }}
    >
      Done
    </Button>
  </FullBox>
);

const UpdateTransferStatusModal = ({
  showModal,
  modalAction,
  transferRequestDetails,
  setReload,
  handleCloseModal,
}: {
  showModal: boolean;
  modalAction: string;
  transferRequestDetails: TransferRequestData | null;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => void;
}) => {
  const [step, setStep] = useState<number>(0);
  const [otp, setOtp] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [inProgress, setInProgress] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    setOtpError('');
  };

  const handleSubmit = async () => {
    const payload = {
      userPin: otp,
      notes,
      paymentRequestId: String(transferRequestDetails?.id),
      paymentRequestStatus: modalAction.toLowerCase() === 'approve' ? 'APPROVED' : 'REJECTED',
    };
    setInProgress(true);
    const response = await updateTransferStatus(payload);
    if (response?.result?.status) {
      setStep(2);
    } else {
      setInProgress(false);
      setOtpError((response?.error as { data: { message: string } })?.data?.message || 'An error occurred');
    }
  };

  const btnText = modalAction === 'approve' ? 'Confirm transfer' : 'Yes, reject transfer';

  return (
    <Modal showModal={showModal} onClose={handleCloseModal}>
      <TwoFactorModalWrapper>
        {step === 0 ? (
          modalAction === 'approve' ? (
            <FullBox>
              <HeaderWrapper>
                <CautionIcon2 />
                <BodyWrapper>
                  <Typography fontSize="24px" lineHeight="28px" fontWeight={700}>
                    Are you sure you want to approve this initiated transfer?
                  </Typography>
                </BodyWrapper>
              </HeaderWrapper>

              <Button style={{ marginTop: '4rem' }} variant="medium" width="100%" onClick={() => setStep(1)}>
                Yes, approve transfer
              </Button>
              <CancelTextWrapper>
                <CancelText variant="n-text2" color="grey4" onClick={handleCloseModal}>
                  No, cancel
                </CancelText>
              </CancelTextWrapper>
            </FullBox>
          ) : (
            <FullBox>
              <HeaderWrapper>
                <CautionIcon2 />
                <BodyWrapper>
                  <Typography fontSize="24px" lineHeight="28px" fontWeight={700}>
                    Are you sure you want to reject the initiated transfer?
                  </Typography>
                </BodyWrapper>
              </HeaderWrapper>

              <TextInput
                style={{ marginTop: '2rem' }}
                name="notes"
                label=""
                placeholder="Add notes"
                value={notes || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNotes(e.target.value);
                }}
              />

              <Button
                style={{ marginTop: '4rem', background: '#FF472B', color: '#FFF' }}
                variant="medium"
                width="100%"
                onClick={() => setStep(1)}
              >
                Yes, reject transfer
              </Button>
              <CancelTextWrapper>
                <CancelText variant="n-text2" color="grey4" onClick={handleCloseModal}>
                  No, cancel
                </CancelText>
              </CancelTextWrapper>
            </FullBox>
          )
        ) : step === 1 ? (
          <>
            <HeaderWrapper>
              <Typography fontSize="24px" fontWeight={700}>
                Enter transaction pin
              </Typography>
              <Typography color="n-grey3" style={{ marginTop: '24px' }}>
                Enter your password to confirm this action
              </Typography>
            </HeaderWrapper>
            <OtpInput
              label="Enter transaction PIN"
              type="password"
              width="100%"
              name="otp"
              value={otp || ''}
              onChange={handleChange}
              inputsNumber={4}
              error={otpError || ''}
            />

            <Button
              disabled={otp.length < 4 || inProgress}
              style={{ marginTop: '8rem' }}
              variant="medium"
              width="100%"
              onClick={() => handleSubmit()}
            >
              {inProgress ? <Spinner /> : btnText}
            </Button>
          </>
        ) : modalAction === 'approve' ? (
          <ApprovedScreen
            amount={transferRequestDetails?.amount || 0}
            beneficiaryName={transferRequestDetails?.beneficiaryName || ''}
            setReload={setReload}
            handleCloseModal={handleCloseModal}
          />
        ) : (
          <RejectedScreen
            amount={transferRequestDetails?.amount || 0}
            beneficiaryName={transferRequestDetails?.beneficiaryName || ''}
            submittedBy={transferRequestDetails?.submittedBy?.firstName || ''}
            setReload={setReload}
            handleCloseModal={handleCloseModal}
          />
        )}
      </TwoFactorModalWrapper>
    </Modal>
  );
};

export default UpdateTransferStatusModal;
