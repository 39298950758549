import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '../../../types/invoicing';

import {
  createCustomer,
  updateCustomer,
  fetchAllCustomers,
  fetchCustomer,
  deleteCustomer,
  searchCustomer,
} from './thunkActions';

interface InitialState {
  customers: Array<Customer>;
  totalCustomersChunked: Array<Array<Customer>>;
  totalCustomers: Array<Customer>;
  singleCustomer: Customer;
  isFetchingCustomer: boolean;
  isLoading: boolean;
  isDeletingCustomer: boolean;
  selectedCustomer: Customer;
  showAddCustomerModal: boolean;
  showSuccessModal: boolean;
  isCustomerSelected: boolean;
  showPrompt: boolean;
  showDropdown: boolean;
}

const initialState: InitialState = {
  customers: [],
  totalCustomersChunked: [],
  totalCustomers: [],
  singleCustomer: {} as Customer,
  isFetchingCustomer: false,
  isDeletingCustomer: false,
  isLoading: false,
  selectedCustomer: {} as Customer,
  isCustomerSelected: false,
  showAddCustomerModal: false,
  showSuccessModal: false,
  showPrompt: false,
  showDropdown: false,
};

export const customersSlice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    setSelectedCustomer: (state, action: PayloadAction<Customer>) => {
      state.selectedCustomer = action.payload;
    },
    setCustomerData: (state, action: PayloadAction<Customer>) => {
      state.customers = [...state.customers, action.payload];
      state.showAddCustomerModal = false;
    },
    setShowAddCustomerModal: (state, action: PayloadAction<boolean>) => {
      state.showAddCustomerModal = action.payload;
    },
    setShowSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.showAddCustomerModal = action.payload;
    },
    setIsCustomerSelected: (state, action: PayloadAction<boolean>) => {
      state.isCustomerSelected = action.payload;
    },
    setShowPrompt: (state, action: PayloadAction<boolean>) => {
      state.showPrompt = action.payload;
    },
    setShowDropdown: (state, action: PayloadAction<boolean>) => {
      state.showDropdown = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Customer
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.isFetchingCustomer = true;
      })
      .addCase(fetchCustomer.rejected, (state) => {
        state.isFetchingCustomer = false;
      })
      .addCase(fetchCustomer.fulfilled, (state, { payload }) => {
        state.isFetchingCustomer = false;
        state.singleCustomer = payload!;
      });

    // Search for customers
    builder
      .addCase(searchCustomer.pending, (state) => {
        state.isFetchingCustomer = true;
      })
      .addCase(searchCustomer.rejected, (state) => {
        state.isFetchingCustomer = false;
      })
      .addCase(searchCustomer.fulfilled, (state, { payload }) => {
        state.isFetchingCustomer = false;
        state.customers = payload.data || [];
      });

    // Fetch all customers
    builder
      .addCase(fetchAllCustomers.pending, (state) => {
        state.isFetchingCustomer = true;
        state.isCustomerSelected = false;
        state.showAddCustomerModal = false;
        state.showSuccessModal = false;
        state.showPrompt = false;
      })
      .addCase(fetchAllCustomers.rejected, (state) => {
        state.isFetchingCustomer = false;
      })
      .addCase(fetchAllCustomers.fulfilled, (state, { payload }) => {
        state.isFetchingCustomer = false;
        state.customers = payload.data || [];
      });

    // Create a new customer
    builder
      .addCase(createCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCustomer.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createCustomer.fulfilled, (state) => {
        state.isLoading = false;
        state.showAddCustomerModal = false;
        state.showSuccessModal = true;
      });

    // Update customer
    builder
      .addCase(updateCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCustomer.fulfilled, (state) => {
        state.isLoading = false;
        state.showAddCustomerModal = false;
        state.showSuccessModal = true;
      });

    // Delete customer
    builder
      .addCase(deleteCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCustomer.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCustomer.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSelectedCustomer,
  setCustomerData,
  setShowAddCustomerModal,
  setShowSuccessModal,
  setIsCustomerSelected,
  setShowPrompt,
  setShowDropdown,
} = customersSlice.actions;

export default customersSlice.reducer;
export * from './thunkActions';
