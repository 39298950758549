import { Button } from 'kudi-component-library';
import styled from 'styled-components';

export const WarningModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0px 20px;

  @media ${({ theme }) => theme.media.md} {
    padding: 40px;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const WarningClockWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoutOutlineButton = styled(Button)`
  background: none;
  border: 1px solid #cccccc;
`;
