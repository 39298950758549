import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../utils/merchantAcqServiceHttp';
import { ApiResponse, ReduxAction } from '../../types/redux';
import { TransferAlertsPhoneObject } from './slice';

export interface TransactionAlertsPayload extends ReduxAction {
  payload: {
    transactionAlert: {
      emailAlertOn: boolean;
      phoneNumberAlertOn: boolean;
      alertEmails: string[];
      alertPhoneNumbers: TransferAlertsPhoneObject[];
    };
  };
}

export interface TransactionAlertsReponse extends ApiResponse {
  data: {
    transactionAlert: {
      emailAlertOn: boolean;
      phoneNumberAlertOn: boolean;
      alertEmails: string[];
      alertPhoneNumbers: TransferAlertsPhoneObject[];
    };
  };
}

export const fetchTransactionAlerts = createAsyncThunk(
  'transactionAlerts/fetchTransactionAlerts',
  async ({ onFailure, onSuccess, userEmail }: ReduxAction & { userEmail: string }, { rejectWithValue }) => {
    const params = { preferenceType: 'TRANSACTION_ALERT', userEmail };
    try {
      const { data }: TransactionAlertsReponse = await http.get('/webbe/v1/account-preference', {
        params,
      });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const updateTransactionAlerts = createAsyncThunk(
  'transactionAlerts/updateTransactionAlerts',
  async ({ payload, onFailure, onSuccess }: TransactionAlertsPayload, { rejectWithValue }) => {
    try {
      const { data }: TransactionAlertsReponse = await http.post(
        '/webbe/v1/account-preference/transaction-alerts',
        payload
      );
      if (onSuccess) onSuccess(data.transactionAlert);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
