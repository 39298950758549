import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../utils/http';
import { ReduxAction, ApiResponse } from '../../../types/redux';
import { SummaryOverallData, CardBankData, CardStatusBankData } from '../../../types/serviceStatus';

interface FetchSummaryArgs extends ReduxAction {
  startDate: string;
  endDate: string;
  noOfPerformance: number;
}

interface FetchPerformanceArgs extends ReduxAction {
  startDate: string;
  endDate: string;
}

interface FetchGraphArgs extends ReduxAction {
  startDate: string;
  endDate: string;
  bankName: string;
}

export interface FetchSummaryResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: SummaryOverallData;
  error: string;
}

export interface FetchPerformanceResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: {
    cardBankData: Array<CardBankData>;
  };
  error: string;
}

export interface FetchGraphResponse extends ApiResponse {
  status: boolean;
  message: string;
  data: {
    cardProviderData: never[];
    cardStatusBankData: Array<CardStatusBankData>;
  };
  error: string;
}

export const fetchSummaryData = createAsyncThunk(
  '/service-status/summary-data',
  async ({ startDate, endDate, noOfPerformance, onFailure }: FetchSummaryArgs, { rejectWithValue }) => {
    try {
      const response: FetchSummaryResponse = await axios.post('cardPerformance/get-top-least-performance/', {
        startDate,
        endDate,
        noOfPerformance,
      });
      return response.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const fetchPerformanceData = createAsyncThunk(
  '/service-status/performance-data',
  async ({ startDate, endDate, onFailure }: FetchPerformanceArgs, { rejectWithValue }) => {
    try {
      const response: FetchPerformanceResponse = await axios.post('cardPerformance/getservice/', {
        startDate,
        endDate,
      });
      return response?.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const fetchGraphData = createAsyncThunk(
  '/service-status/graph-data',
  async ({ startDate, endDate, bankName, onFailure }: FetchGraphArgs, { rejectWithValue }) => {
    try {
      const response: FetchGraphResponse = await axios.post('cardPerformance/getstatus/', {
        startDate,
        endDate,
        bankName,
      });
      return response?.data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
