const OutletModalIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle opacity="0.3" cx="40" cy="40" r="40" fill="#EEEEEE" />
    <path
      d="M40.0003 32.5003L40.0126 41.1449M39.9881 47.5003H40.0126M40.0003 58.3337C50.1255 58.3337 58.3337 50.1255 58.3337 40.0003C58.3337 29.8751 50.1255 21.667 40.0003 21.667C29.8751 21.667 21.667 29.8751 21.667 40.0003C21.667 50.1255 29.8751 58.3337 40.0003 58.3337Z"
      stroke="#FF2200"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OutletModalIcon;
