/* eslint-disable import/no-cycle */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/slice';
import bankingBalanceReducer from '../features/banking/balance/slice';
import dashboardReducer from '../features/dashboard/slice';
import terminalReducer from '../features/terminals/slice';
import businessReducer from '../features/business/slice';
import transactionReducer from '../features/transactions/slice';
import settingsReducer from '../features/settings/slice';
import loanProviderReducer from '../features/loanProvider/slice';
import qrCodeReducer from '../features/qrCode/slice';
import cardBankPerformanceReducer from '../features/serviceStatus/cardBankPerformance/slice';
import paymentsReducer from '../features/invoicing/payments/slice';
import outgoingReducer from '../features/invoicing/outgoing/slice';
import customersReducer from '../features/invoicing/customers/slice';
import referralReducer from '../features/referral/slice';
import transferReducer from '../features/transfers/slice';
import overviewChartsReducer from '../features/overviewCharts/slice';
import commissionBalanceReducer from '../features/banking/commissionBalance/slice';
import activeWalletReducer from '../features/banking/activeWallet/slice';
import bankingModalControlReducer from '../features/banking/bankingModalControl/slice';
import notifications from '../pages/notification/reducer/slice';
import support from '../pages/support/reducer/slice';
import QR from '../pages/qr/reducer/slice';
import TransactionAlerts from '../features/transaction-alerts/slice';

const banking = combineReducers({ balance: bankingBalanceReducer });

const invoicing = combineReducers({
  payments: paymentsReducer,
  outgoing: outgoingReducer,
  customers: customersReducer,
});

const serviceStatusReducer = combineReducers({
  cardBankPerformance: cardBankPerformanceReducer,
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    banking,
    dashboard: dashboardReducer,
    terminal: terminalReducer,
    business: businessReducer,
    transactions: transactionReducer,
    settings: settingsReducer,
    loanProvider: loanProviderReducer,
    qrCode: qrCodeReducer,
    invoicing,
    referral: referralReducer,
    transfers: transferReducer,
    overviewCharts: overviewChartsReducer,
    commissionBalance: commissionBalanceReducer,
    activeWallet: activeWalletReducer,
    bankingModalControl: bankingModalControlReducer,
    serviceStatus: serviceStatusReducer,
    notifications,
    support,
    QR,
    TransactionAlerts,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['/user/create/rejected', '/auth/get-bolt-user/rejected'],
        ignoredPaths: [],
      },
    }).concat(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
