import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Text, TextInput } from 'kudi-component-library';
import { Formik } from 'formik';
import CurrencyInput from 'react-currency-input-field';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { CancelIcon, CaretLeft, CautionIcon, Xlsx, XlsxUploaded } from '../../../assets/svg';
import { FullBox, FullFlex } from '../../../styles/generic';
import { bankOptions, bankCodes } from '../../../data';
import Bulktransfertable from './bulktransfertable';
import {
  accountLookUp,
  fetchBeneficiaries,
  FetchBeneficiariesResponse,
  uploadSampleDocument,
} from '../../../features/transfers/thunkActions';
import { Mixpanel } from '../../../hooks/useMixpanel';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { BeneficiariesData, BulkTransferUsersData } from '../../../types/transactions';
import { formatCurrency } from '../../../utils';
import Select from '../../../components/Select';
import { SplitWrapper } from '../../settings/styles';
import { DesktopWrapper, MobileWrapper } from '../../loan-provider/components/filter';
import 'react-circular-progressbar/dist/styles.css';
import formatBytes from '../../../utils/formatFileSize';
import { useToast } from '../../../hooks/useToast';
import { TOAST_TIMER } from '../../../utils/constants';

interface InitialValues {
  accountNumber: string;
}
const InitialFormValues: InitialValues = {
  accountNumber: '',
};

export default function BulkTransfer() {
  const [newBeneficiary, setNewBeneficiary] = React.useState<boolean>(true);
  const [savedBeneficiary, setSavedBeneficiary] = React.useState<boolean>(false);
  const [beneficiariesList, setBeneficiariesList] = React.useState<BeneficiariesData[]>([]);
  const [filteredBeneficiariesList, setFilteredBeneficiariesList] = React.useState<BeneficiariesData[]>([]);
  const [unverifiedList, setUnverifiedList] = React.useState<BulkTransferUsersData[]>([]);
  const [verifiedList, setVerifiedList] = React.useState<BulkTransferUsersData[]>([]);
  const [uploadList, setUploadList] = React.useState<BulkTransferUsersData[]>([]);
  const [selectedData, setSelectedData] = React.useState<BulkTransferUsersData | null>(null);
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
  const [showUnverified, setShowUnverified] = React.useState<boolean>(false);
  const [bankName, setBankName] = React.useState<string>('');
  const [accountNumber, setAccountNumber] = React.useState('');
  const [accountLookupError, setAccountLookupError] = React.useState('');
  const [bulkAmount, setBulkAmount] = React.useState<number>(0);
  const [bulkAmountUnverified, setBulkAmountUnverified] = React.useState<number>(0);
  const [amount, setAmount] = React.useState<number>(0);
  const [accountName, setAccountName] = React.useState('');
  const [remark, setRemark] = React.useState('');
  const [uploadedDoc, setUploadedDoc] = React.useState<File>();
  const [userId, setUserId] = React.useState<number>(-1);
  const [userIndex, setUserIndex] = React.useState<number>(-1);
  const [uploadProgress, setUploadProgress] = React.useState<number>(10);

  const formRef = React.useRef<null | HTMLElement>(null);
  const listRef = React.useRef<null | HTMLElement>(null);

  const { fullUser } = useAppSelector((state) => state.auth);
  const { availableBalance, isLoading } = useAppSelector((state) => state.banking.balance);

  const navigate = useNavigate();
  const toast = useToast(TOAST_TIMER);

  const dispatch = useAppDispatch();
  const { isSubmitting, inProgress, isUploading } = useAppSelector((state) => state.transfers);

  function filterBeneficiaries(val: string) {
    setAccountName(val);
    const result = beneficiariesList.filter((item) => item.name.toLowerCase().includes(val.toLowerCase()));
    setFilteredBeneficiariesList(result);
  }

  const AccountLookUp = useCallback(() => {
    if (bankName === '' || accountNumber === '') return;
    if (accountNumber.length < 10) {
      setAccountName('');
      return;
    }
    const selectedBank = bankCodes.find((bank) => bank.name === bankName);
    if (!selectedBank) {
      return;
    }
    dispatch(
      accountLookUp({
        bankCode: selectedBank?.bankCode,
        accountNumber,
        onFailure: () => {
          setAccountLookupError('Account not found');
        },
        onSuccess: (value: {
          data: {
            accountName: string;
          };
          status: string;
          successful: boolean;
        }) => {
          setAccountName(value.data.accountName);
        },
      })
    );
  }, [dispatch, accountNumber, bankName]);

  useEffect(() => {
    AccountLookUp();
  }, [AccountLookUp]);

  const UploadDocument = useCallback(() => {
    if (!uploadedDoc) {
      return;
    }
    dispatch(
      uploadSampleDocument({
        file: uploadedDoc,
        onFailure: () => {
          toast('error', 'Something went wrong');
        },
        onSuccess: (value: {
          progress: number;
          response: {
            data: { bulkUploadResponseList: []; unverifiedDataList: [] };
            status: boolean;
            message: string;
          };
        }) => {
          const sum = value?.response?.data?.bulkUploadResponseList?.reduce(
            (a, b: { amount: number }) => +a + +b.amount,
            0
          );
          const sum2 = value?.response?.data?.unverifiedDataList?.reduce(
            (a, b: { amount: number }) => +a + +b.amount,
            0
          );

          listRef?.current?.scrollIntoView({ behavior: 'smooth' });
          // setUploadedDoc(undefined);
          setUploadProgress(value?.progress);
          setUploadList(value?.response?.data?.bulkUploadResponseList);
          setVerifiedList(value?.response?.data?.bulkUploadResponseList);
          setUnverifiedList(value?.response?.data?.unverifiedDataList);
          setBulkAmount(sum);
          setBulkAmountUnverified(sum2);
        },
        // onSuccess: (value: {
        //   data: { bulkUploadResponseList: []; unverifiedDataList: [] };
        //   status: boolean;
        //   message: string;
        // }) => {
        //   const sum = value?.data?.bulkUploadResponseList?.reduce((a, b: { amount: number }) => +a + +b.amount, 0);
        //   const sum2 = value?.data?.unverifiedDataList?.reduce((a, b: { amount: number }) => +a + +b.amount, 0);

        //   listRef?.current?.scrollIntoView({ behavior: 'smooth' });
        //   setUploadedDoc(undefined);
        //   setUploadList(value?.data?.bulkUploadResponseList);
        //   setVerifiedList(value?.data?.bulkUploadResponseList);
        //   setUnverifiedList(value?.data?.unverifiedDataList);
        //   setBulkAmount(sum);
        //   setBulkAmountUnverified(sum2);
        // },
      })
    );
  }, [dispatch, uploadedDoc]);

  useEffect(() => {
    UploadDocument();
  }, [UploadDocument]);

  useEffect(() => {
    if (selectedData) {
      setAccountNumber(selectedData?.account === null ? '' : selectedData?.account);
      setAccountName(selectedData?.beneficiaryName === null ? '' : selectedData?.beneficiaryName);
      setAmount(selectedData?.amount === null ? 0 : selectedData?.amount);
      setRemark(selectedData?.narration === null ? '' : selectedData?.narration);
      setBankName(selectedData?.bankName === null ? '' : selectedData?.bankName);
    }
  }, [selectedData]);

  const deleteUser = useCallback(() => {
    if (userId < 0) {
      return;
    }
    if (showUnverified) {
      const newData = unverifiedList.filter((_, index) => index !== userId);
      const sum = newData.reduce((a, b: { amount: number }) => +a + +b.amount, 0);
      setBulkAmountUnverified(sum);
      setUnverifiedList(newData);
    } else {
      const newData = verifiedList.filter((_, index) => index !== userId);
      const sum = newData.reduce((a, b: { amount: number }) => +a + +b.amount, 0);
      setVerifiedList(newData);
      setBulkAmount(sum);
    }
  }, [userId, showUnverified]);

  useEffect(() => {
    deleteUser();
  }, [deleteUser]);

  function handleAddBeneficiary() {
    const payload = {
      account: accountNumber,
      amount,
      bankName,
      bankCode: undefined,
      beneficiaryName: accountName,
      narration: remark,
    };

    const itExist = uploadList.find((list) => {
      return list.account === payload.account;
    });

    if (itExist) {
      toast('error', 'Account already added');
      return;
    }

    const newRes = [payload, ...uploadList];
    const completeEntries = newRes?.filter(
      (user: { beneficiaryName: string; bankName: string; account: string; amount: number }) =>
        user?.beneficiaryName && user?.bankName && user?.account && user?.amount
    );

    const sum = newRes?.reduce((a, b: { amount: number }) => +a + +b.amount, 0);
    setUploadList(newRes);
    setVerifiedList(completeEntries);
    setBulkAmount(sum);

    setAmount(0);
    setAccountNumber('');
    setBankName('');
    setAccountName('');
    setRemark('');
    setSavedBeneficiary(true);
    setNewBeneficiary(false);
  }

  function handleEditBeneficiary() {
    if (selectedData !== null) {
      if (showUnverified) {
        selectedData.account = accountNumber;
        selectedData.amount = amount;
        selectedData.bankName = bankName;
        selectedData.beneficiaryName = accountName;
        selectedData.narration = remark;

        const selectedBank = bankCodes.find((bank) => bank.name === bankName);
        if (!selectedBank) {
          return;
        }
        selectedData.bankCode = selectedBank?.bankCode;

        const newState = [...verifiedList, selectedData];
        const sum = newState?.reduce((a, b: { amount: number }) => +a + +b.amount, 0);

        const newUnverified = unverifiedList.filter((item) => item !== selectedData);
        const sum2 = newUnverified?.reduce((a, b: { amount: number }) => +a + +b.amount, 0);

        setBulkAmount(sum);
        setBulkAmountUnverified(sum2);
        setVerifiedList(newState);
        setUnverifiedList(newUnverified);
      } else {
        const newState = verifiedList.map((user) => {
          const isSame = selectedData === user;

          if (isSame) {
            return {
              ...user,
              account: accountNumber,
              amount,
              bankName,
              beneficiaryName: accountName,
              narration: remark,
            };
          }

          return user;
        });
        const sum = newState?.reduce((a, b: { amount: number }) => +a + +b.amount, 0);
        setBulkAmount(sum);
        setVerifiedList(newState);
      }
    }
  }

  useEffect(() => {
    const onFailure = () => {
      toast('error', 'Something went wrong');
    };

    const onSuccess = (data: FetchBeneficiariesResponse) => {
      setBeneficiariesList(data.data);
      setFilteredBeneficiariesList(data.data);
    };

    dispatch(fetchBeneficiaries({ page: 1, onSuccess, onFailure }));
  }, [dispatch, savedBeneficiary]);

  return (
    <FullBox>
      {/* Top Bar */}
      <FullFlex
        mt="60px"
        pl="20px"
        pt="30px"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/dashboard/payments/transfer')}
      >
        <CaretLeft />
        <Text ml="20px"> Back to transfer</Text>
      </FullFlex>

      <Text style={{ paddingLeft: '20px' }} mt="20px" mb="30px" color="#121212" fontSize="32px" fontWeight="bold">
        Bulk transfer
      </Text>

      <DesktopWrapper style={{ padding: '20px' }}>
        <FullFlex>
          <FullBox width="30%">
            <FullBox bg="white" p="20px" height="270px">
              <Text>Upload Document</Text>
              {!isUploading && uploadedDoc === undefined && (
                <>
                  <FullBox
                    p="20px"
                    mt="20px"
                    style={{
                      border: '1px dashed #C6C6C6',
                      borderRadius: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      type="file"
                      id="sample-doc"
                      hidden
                      onChange={(e) => {
                        if (e.target.files) {
                          setUploadedDoc(e.target.files[0]);
                        }
                      }}
                    />
                    <label htmlFor="sample-doc" style={{ cursor: 'pointer' }}>
                      {' '}
                      <Xlsx />
                    </label>
                    <Text color="#121212" fontSize="16px" mt="10px">
                      Click to upload an Excel file
                    </Text>
                    <Text color="#121212" fontSize="12px" mt="10px">
                      Maximum file size 20MB.
                    </Text>
                  </FullBox>
                  <Text
                    onClick={() => {
                      window.open(`${process.env.PUBLIC_URL}/BulkTransferSheet.xlsx`);
                    }}
                    mt="20px"
                    color="#717171"
                    fontSize="12px"
                    style={{ cursor: 'pointer', textAlign: 'center' }}
                  >
                    <u>Click here to Download EXCEL sample</u>
                  </Text>
                </>
              )}

              {isUploading && (
                <FullBox
                  p="20px"
                  mt="20px"
                  style={{
                    border: '1px dashed #C6C6C6',
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FullBox style={{ width: 46, height: 46 }}>
                    <CircularProgressbar
                      value={uploadProgress}
                      text={`${uploadProgress}%`}
                      styles={buildStyles({
                        // Text size
                        textSize: '26px',
                        // Colors
                        pathColor: '#FFCC00',
                        textColor: '#717171',
                        trailColor: '#EEEEEE',
                      })}
                    />
                  </FullBox>
                  <Text mt="10px" mb="10px">
                    Uploading file...
                  </Text>
                  <FullBox
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setUploadedDoc(undefined);
                      setUploadProgress(0);
                      setUploadList([]);
                      setVerifiedList([]);
                      setUnverifiedList([]);
                      setBulkAmount(0);
                      setBulkAmountUnverified(0);
                    }}
                  >
                    <CancelIcon />
                  </FullBox>
                </FullBox>
              )}

              {uploadedDoc && !isUploading && (
                <FullBox
                  p="20px"
                  mt="20px"
                  style={{
                    border: '1px dashed #C6C6C6',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <FullBox
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <XlsxUploaded />
                    <FullBox ml="5px">
                      <Text color="#121212" fontSize="14px" fontWeight="regular">
                        {uploadedDoc.name}
                      </Text>
                      <Text color="#717171" fontSize="12px" fontWeight="regular">
                        {formatBytes(uploadedDoc.size)}
                      </Text>
                    </FullBox>
                  </FullBox>
                  <FullBox
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setUploadedDoc(undefined);
                      setUploadProgress(0);
                      setUploadList([]);
                      setVerifiedList([]);
                      setUnverifiedList([]);
                      setBulkAmount(0);
                      setBulkAmountUnverified(0);
                    }}
                  >
                    <CancelIcon />
                  </FullBox>
                </FullBox>
              )}
            </FullBox>
            <Text color="#121212" fontSize="14px" mt="10px" mb="10px">
              Or you can add beneficiary manually
            </Text>
            <FullBox bg="white">
              <FullFlex justifyContent="space-between">
                <Text
                  style={{
                    borderBottom: newBeneficiary ? '3px solid #FFCC00' : '',
                    width: '50%',
                    padding: '10px',
                    cursor: 'pointer',
                  }}
                  color={newBeneficiary ? '#121212' : '#999999'}
                  fontSize="14px"
                  fontWeight="regular"
                  textAlign="center"
                  onClick={() => {
                    setNewBeneficiary(true);
                    setSavedBeneficiary(false);
                    setAmount(0);
                    setAccountName('');
                    setAccountNumber('');
                    setRemark('');
                  }}
                >
                  New Beneficiary
                </Text>
                <Text
                  style={{
                    borderBottom: savedBeneficiary ? '3px solid #FFCC00' : '',
                    width: '50%',
                    padding: '10px',
                    cursor: 'pointer',
                  }}
                  color={savedBeneficiary ? '#121212' : '#999999'}
                  fontSize="14px"
                  fontWeight="regular"
                  textAlign="center"
                  onClick={() => {
                    setSavedBeneficiary(true);
                    setNewBeneficiary(false);
                    setAmount(0);
                    setAccountName('');
                    setAccountNumber('');
                    setRemark('');
                  }}
                >
                  Saved Beneficiary
                </Text>
              </FullFlex>
              {/* New Beneficiary Starts */}

              {newBeneficiary && (
                <FullBox mt="20px" p="20px">
                  <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                    <FullBox mb="10px" width="100%">
                      <Select
                        name="Bankname"
                        label="Bank name"
                        placeholder="Select bank"
                        options={bankOptions[0]}
                        width="100%"
                        value={bankName}
                        onChange={(e) => {
                          Mixpanel.track('Select Bank', {
                            first_name: String(fullUser?.firstName),
                            last_name: String(fullUser?.lastName),
                            phone_number: String(fullUser?.phoneNumber),
                            email: String(fullUser?.email),
                            bank: String(e.target.value),
                          });
                          e.target.value && setBankName(e.target.value);
                        }}
                      />
                    </FullBox>

                    <FullBox width="100%" mb="10px">
                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        <TextInput
                          label="Account number"
                          placeholder="Enter your account"
                          name="account_number"
                          value={accountNumber}
                          onChange={(e) => {
                            setAccountLookupError('');
                            setAccountNumber(e.target.value);
                          }}
                          style={{ border: accountLookupError ? '1px solid #ff2200' : '' }}
                          width="100%"
                          error={accountLookupError}
                        />
                      </Formik>
                    </FullBox>

                    <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                      <TextInput
                        label="Account name"
                        placeholder=""
                        name="account_name"
                        value={inProgress ? 'Please wait...' : accountName}
                        readOnly
                        onChange={() => {}}
                        width="100%"
                        style={{ marginBottom: '10px' }}
                      />
                    </Formik>

                    <Text fontSize="14px" fontWeight="regular" color="#444444" mb="4px" style={{ width: '100%' }}>
                      Amount
                    </Text>
                    <CurrencyInput
                      id="input-amount"
                      name="input-amount"
                      placeholder={amount < 0 ? 'Enter amount' : `${formatCurrency(amount)}`}
                      prefix="₦"
                      decimalsLimit={2}
                      onValueChange={(value) => {
                        setAmount(Number(value));
                      }}
                      width="100%"
                      style={{
                        marginBottom: '10px',
                        width: '100%',
                        height: '45px',
                        border: '1px solid #EFEFEF',
                        borderRadius: '4px',
                        outline: 'none',
                        fontSize: '14px',
                        padding: '0 12px',
                      }}
                    />
                    <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                      <TextInput
                        label="Remark (Optional)"
                        placeholder="e.g Staff salary"
                        name="remark"
                        value={remark}
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                        width="100%"
                        style={{ marginBottom: '10px' }}
                      />
                    </Formik>
                  </FullFlex>
                </FullBox>
              )}
              {/* New Beneficiary Ends */}

              {/* Saved Beneficiary Starts */}
              {savedBeneficiary && (
                <FullBox mt="20px" p="20px">
                  <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                    <FullBox style={{ display: 'flex', position: 'relative', width: '100%' }}>
                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        <TextInput
                          label="Beneficary list"
                          placeholder="Search by typing beneficary name"
                          name="Beneficiaries"
                          value={accountName}
                          onChange={(e) => filterBeneficiaries(e.target.value)}
                          width="100%"
                          style={{ marginBottom: '10px' }}
                          onClick={() => {
                            setShowDropDown(!showDropDown);
                          }}
                        />
                      </Formik>
                    </FullBox>

                    {showDropDown && (
                      <FullBox
                        width="100%"
                        bg="white"
                        mb="20px"
                        style={{
                          border: '1px solid #EEEEEE',
                          borderRadius: '5px',
                          padding: '20px',
                          overflow: 'scroll',
                          height: '190px',
                        }}
                      >
                        {filteredBeneficiariesList.map((list, index) => (
                          <div key={index} style={{ marginBottom: '6px', cursor: 'pointer' }}>
                            <Text
                              color="#121212"
                              fontSize="16px"
                              onClick={() => {
                                setAccountName(list.name);
                                setBankName(list.bankName);
                                setAccountNumber(list.accountNo);
                                setShowDropDown(false);
                                setAmount(0);
                              }}
                            >
                              {list.name}
                            </Text>
                            <Text color="#717171" fontSize="12px">
                              {list.bankName} - {list.accountNo}
                            </Text>
                          </div>
                        ))}
                      </FullBox>
                    )}
                    <Text fontSize="14px" fontWeight="regular" color="#444444" mb="4px" style={{ width: '100%' }}>
                      Amount
                    </Text>
                    <CurrencyInput
                      id="input-amount"
                      name="input-amount"
                      placeholder={amount < 0 ? 'Enter amount' : `${formatCurrency(amount)}`}
                      prefix="₦"
                      decimalsLimit={2}
                      onValueChange={(value) => {
                        setAmount(Number(value));
                      }}
                      width="100%"
                      style={{
                        marginBottom: '10px',
                        width: '100%',
                        height: '45px',
                        border: '1px solid #EFEFEF',
                        borderRadius: '4px',
                        outline: 'none',
                        fontSize: '14px',
                        padding: '0 12px',
                      }}
                    />
                    <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                      <TextInput
                        label="Remark (Optional)"
                        placeholder="e.g Staff salary"
                        name="remark"
                        value={remark}
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                        width="100%"
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                      />
                    </Formik>
                  </FullFlex>
                </FullBox>
              )}
              {/* Saved Beneficiary Ends */}
              {selectedData !== null ? (
                <FullBox width="100%" mt="10px" p="20px">
                  <Button
                    onClick={() => {
                      handleEditBeneficiary();
                    }}
                    width="100%"
                    variant="xlarge"
                    style={{ backgroundColor: '#EEEEEE', color: '#121212' }}
                    disabled={!!isSubmitting}
                  >
                    {newBeneficiary && selectedData ? 'Update Beneficiary' : 'Add Beneficiary'}
                  </Button>
                </FullBox>
              ) : (
                <FullBox width="100%" p="20px">
                  <Button
                    onClick={() => {
                      handleAddBeneficiary();
                    }}
                    width="100%"
                    variant="large"
                    style={{ backgroundColor: '#EEEEEE', color: '#121212' }}
                    disabled={!!isSubmitting || amount === 0 || bankName === '' || accountNumber === ''}
                  >
                    {newBeneficiary && selectedData ? 'Update Beneficiary' : 'Add Beneficiary'}
                  </Button>
                </FullBox>
              )}
            </FullBox>
          </FullBox>
          {isUploading ? (
            <FullBox
              width="60%"
              bg="#fff"
              height="100vh"
              ml="1%"
              p="30px"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                <Text textAlign="center" color="#717171" fontSize="14px">
                  Processing Document. Please wait...
                </Text>
              </FullFlex>
            </FullBox>
          ) : (
            <>
              {uploadList.length > 0 && (
                <FullBox width="80%" ml="1%">
                  {unverifiedList.length > 0 && (
                    <FullFlex
                      borderRadius="5px"
                      bg="#fff"
                      height="64px"
                      p="10px"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <CautionIcon />
                      <Text color="#717171" fontSize="12px">
                        {unverifiedList.length} of the transfers could not be verified because they contained some error
                        or duplicate.
                      </Text>
                      <Button
                        onClick={() => {
                          setShowUnverified(!showUnverified);
                          setUserId(-1);
                        }}
                        width="30%"
                        variant="large"
                        style={{ backgroundColor: '#FFCC00', color: '#121212', fontSize: '12px', height: '40px' }}
                      >
                        {showUnverified ? 'View verified transfers' : 'View unverified transfers'}
                      </Button>
                    </FullFlex>
                  )}
                  <FullFlex
                    borderRadius="5px"
                    bg="#fff"
                    height="84px"
                    p="20px"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    mt="10px"
                  >
                    <FullFlex flexDirection="column">
                      <Text color="#717171" fontSize="14px">
                        Total amount to send
                      </Text>
                      <Text color="#121212" fontSize="14px" fontWeight="bold">
                        {showUnverified ? formatCurrency(bulkAmountUnverified) : formatCurrency(bulkAmount)}
                      </Text>
                    </FullFlex>

                    <FullFlex flexDirection="column">
                      <Text color="#717171" fontSize="14px">
                        Total verified beneficiary
                      </Text>
                      <Text color="#121212" fontSize="14px" fontWeight="bold">
                        {verifiedList.length}
                      </Text>
                    </FullFlex>

                    <FullFlex flexDirection="column">
                      <Text color="#717171" fontSize="14px">
                        Total unverified beneficiary
                      </Text>
                      <Text color="#121212" fontSize="14px" fontWeight="bold">
                        {unverifiedList.length}
                      </Text>
                    </FullFlex>

                    <FullFlex flexDirection="column">
                      <Text color="#717171" fontSize="14px">
                        Wallet Balance
                      </Text>
                      <Text color="#121212" fontSize="14px" fontWeight="bold">
                        {isLoading ? 'Fetching balance...' : formatCurrency(availableBalance)}
                      </Text>
                    </FullFlex>
                  </FullFlex>
                  {showUnverified ? (
                    <Bulktransfertable
                      formRef={formRef}
                      setSelectedData={setSelectedData}
                      setUserId={setUserId}
                      setUserIndex={setUserIndex}
                      showUnverified={showUnverified}
                      tableData={unverifiedList}
                      bulkSum={bulkAmount}
                    />
                  ) : (
                    <Bulktransfertable
                      formRef={formRef}
                      setSelectedData={setSelectedData}
                      setUserId={setUserId}
                      setUserIndex={setUserIndex}
                      showUnverified={showUnverified}
                      tableData={verifiedList}
                      bulkSum={bulkAmount}
                    />
                  )}
                </FullBox>
              )}

              {uploadList.length < 1 && (
                <FullBox
                  width="60%"
                  bg="#fff"
                  height="100vh"
                  ml="1%"
                  p="30px"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                    <Text textAlign="center" color="#717171" fontSize="14px">
                      You currently have not added any <br />
                      data yet. kindly upload file or add the data <br /> manually
                    </Text>
                  </FullFlex>
                </FullBox>
              )}
            </>
          )}
        </FullFlex>
      </DesktopWrapper>

      <MobileWrapper style={{ background: 'none', padding: '20px' }}>
        <SplitWrapper>
          <FullBox>
            <FullBox bg="white" p="20px" height="270px">
              <Text>Upload Document</Text>
              <FullBox
                p="20px"
                mt="20px"
                style={{
                  border: '1px dashed #C6C6C6',
                  borderRadius: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <input
                  type="file"
                  id="sample-doc"
                  hidden
                  onChange={(e) => {
                    if (e.target.files) {
                      setUploadedDoc(e.target.files[0]);
                    }
                  }}
                />
                <label htmlFor="sample-doc" style={{ cursor: 'pointer', color: 'red' }}>
                  <Xlsx />
                </label>
                <Text color="#121212" fontSize="16px" mt="10px">
                  Click to upload an Excel file
                </Text>
                <Text color="#121212" fontSize="12px" mt="10px">
                  Maximum file size 20MB.
                </Text>
              </FullBox>

              <Text
                onClick={() => {
                  window.open(`${process.env.PUBLIC_URL}/BulkTransferSheet.xlsx`);
                }}
                mt="20px"
                color="#717171"
                fontSize="12px"
                style={{ cursor: 'pointer', textAlign: 'center' }}
              >
                <u>Click here to Download EXCEL sample</u>
              </Text>
            </FullBox>
            <Text color="#121212" fontSize="14px" mt="10px" mb="10px">
              Or you can add beneficiary manually
            </Text>
            <FullBox bg="white">
              <FullFlex justifyContent="space-between">
                <Text
                  style={{
                    borderBottom: newBeneficiary ? '3px solid #FFCC00' : '',
                    width: '50%',
                    padding: '10px',
                    cursor: 'pointer',
                  }}
                  color={newBeneficiary ? '#121212' : '#999999'}
                  fontSize="14px"
                  fontWeight="regular"
                  textAlign="center"
                  onClick={() => {
                    setNewBeneficiary(true);
                    setSavedBeneficiary(false);
                  }}
                >
                  New Beneficiary
                </Text>
                <Text
                  style={{
                    borderBottom: savedBeneficiary ? '3px solid #FFCC00' : '',
                    width: '50%',
                    padding: '10px',
                    cursor: 'pointer',
                  }}
                  color={savedBeneficiary ? '#121212' : '#999999'}
                  fontSize="14px"
                  fontWeight="regular"
                  textAlign="center"
                  onClick={() => {
                    setSavedBeneficiary(true);
                    setNewBeneficiary(false);
                  }}
                >
                  Saved Beneficiary
                </Text>
              </FullFlex>
              {/* New Beneficiary Starts */}

              {newBeneficiary && (
                <FullBox mt="20px" p="20px">
                  <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                    <FullBox mb="10px" width="100%">
                      <Select
                        name="Bankname"
                        label="Bank name"
                        placeholder="Select bank"
                        options={bankOptions[0]}
                        width="100%"
                        value={bankName}
                        onChange={(e) => {
                          e.target.value && setBankName(e.target.value);
                        }}
                      />
                    </FullBox>

                    <FullBox width="100%" mb="10px">
                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        <TextInput
                          label="Account number"
                          placeholder="Enter your account"
                          name="account_number"
                          value={accountNumber}
                          onChange={(e) => {
                            setAccountLookupError('');
                            setAccountNumber(e.target.value);
                          }}
                          style={{ border: accountLookupError ? '1px solid #ff2200' : '' }}
                          width="100%"
                          error={accountLookupError}
                        />
                      </Formik>
                    </FullBox>

                    <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                      <TextInput
                        label="Account name"
                        placeholder=""
                        name="account_name"
                        value={inProgress ? 'Please wait...' : accountName}
                        readOnly
                        onChange={() => {}}
                        width="100%"
                        style={{ marginBottom: '10px' }}
                      />
                    </Formik>

                    <Text fontSize="14px" fontWeight="regular" color="#444444" mb="4px" style={{ width: '100%' }}>
                      Amount
                    </Text>
                    <CurrencyInput
                      id="input-amount"
                      name="input-amount"
                      placeholder={amount < 0 ? 'Enter amount' : `${formatCurrency(amount)}`}
                      prefix="₦"
                      decimalsLimit={2}
                      onValueChange={(value) => {
                        setAmount(Number(value));
                      }}
                      width="100%"
                      style={{
                        marginBottom: '10px',
                        width: '100%',
                        height: '45px',
                        border: '1px solid #EFEFEF',
                        borderRadius: '4px',
                        outline: 'none',
                        fontSize: '14px',
                        padding: '0 12px',
                      }}
                    />
                    <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                      <TextInput
                        label="Remark (Optional)"
                        placeholder="e.g Staff salary"
                        name="remark"
                        value={remark}
                        onChange={(e) => {
                          setRemark(e.target.value);
                        }}
                        width="100%"
                        style={{ marginBottom: '10px' }}
                      />
                    </Formik>
                  </FullFlex>
                </FullBox>
              )}
              {/* New Beneficiary Ends */}

              {/* Saved Beneficiary Starts */}
              {savedBeneficiary && (
                <FullBox mt="20px" p="20px">
                  <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                    <FullBox style={{ display: 'flex', position: 'relative', width: '100%' }}>
                      <Formik initialValues={InitialFormValues} onSubmit={() => {}}>
                        <TextInput
                          label="Beneficary list"
                          placeholder="Search by typing beneficary name "
                          name="Beneficiaries"
                          value={accountName}
                          onChange={(e) => filterBeneficiaries(e.target.value)}
                          width="100%"
                          style={{ marginBottom: '10px' }}
                          onClick={() => {
                            setShowDropDown(!showDropDown);
                          }}
                        />
                      </Formik>
                    </FullBox>

                    {showDropDown && (
                      <FullBox
                        width="100%"
                        bg="white"
                        mb="20px"
                        style={{
                          border: '1px solid #EEEEEE',
                          borderRadius: '5px',
                          padding: '20px',
                          overflow: 'scroll',
                          height: '190px',
                        }}
                      >
                        {filteredBeneficiariesList.map((list, index) => (
                          <div key={index} style={{ marginBottom: '6px', cursor: 'pointer' }}>
                            <Text
                              color="#121212"
                              fontSize="16px"
                              onClick={() => {
                                setAccountName(list.name);
                                setBankName(list.bankName);
                                setAccountNumber(list.accountNo);
                                setShowDropDown(false);
                              }}
                            >
                              {list.name}
                            </Text>
                            <Text color="#717171" fontSize="12px">
                              {list.bankName} - {list.accountNo}
                            </Text>
                          </div>
                        ))}
                      </FullBox>
                    )}
                    <Text fontSize="14px" fontWeight="regular" color="#444444" mb="4px" style={{ width: '100%' }}>
                      Amount
                    </Text>
                    <CurrencyInput
                      id="input-amount"
                      name="input-amount"
                      placeholder="Enter amount"
                      prefix="₦"
                      decimalsLimit={2}
                      onValueChange={(value) => {
                        setAmount(Number(value));
                      }}
                      width="100%"
                      style={{
                        marginBottom: '10px',
                        width: '100%',
                        height: '45px',
                        border: '1px solid #EFEFEF',
                        borderRadius: '4px',
                        outline: 'none',
                        fontSize: '14px',
                        padding: '0 12px',
                      }}
                    />
                  </FullFlex>
                </FullBox>
              )}
              {/* Saved Beneficiary Ends */}
              {selectedData !== null ? (
                <FullBox width="100%" mt="10px" p="20px">
                  <Button
                    onClick={() => {
                      handleEditBeneficiary();
                    }}
                    width="100%"
                    variant="xlarge"
                    style={{ backgroundColor: '#EEEEEE', color: '#121212', height: '48px', marginBottom: '20px' }}
                    disabled={!!isSubmitting}
                  >
                    {newBeneficiary && selectedData ? 'Update Beneficiary' : 'Add Beneficiary'}
                  </Button>
                  <Button
                    onClick={() => {
                      setUserId(userIndex);
                      listRef?.current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                    width="100%"
                    variant="small"
                    style={{
                      backgroundColor: '#121212',
                      color: '#FFCC00',
                      height: '48px',
                    }}
                  >
                    Delete
                  </Button>
                </FullBox>
              ) : (
                <FullBox width="100%" mt="10px" p="20px">
                  <Button
                    onClick={() => {
                      handleAddBeneficiary();
                    }}
                    width="100%"
                    variant="xlarge"
                    style={{ backgroundColor: '#EEEEEE', color: '#121212' }}
                    disabled={!!isSubmitting}
                  >
                    {newBeneficiary && selectedData ? 'Update Beneficiary' : 'Add Beneficiary'}
                  </Button>
                </FullBox>
              )}
            </FullBox>
          </FullBox>
          <FullBox width="100%">
            {isUploading ? (
              <FullBox bg="#fff" mt="20px" height="100vh" style={{ display: 'flex', justifyContent: 'center' }}>
                <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                  <Text textAlign="center" color="#717171" fontSize="14px">
                    Processing Document. Please wait...
                  </Text>
                </FullFlex>
              </FullBox>
            ) : (
              <>
                {uploadList.length > 0 && (
                  <FullBox mt="10px">
                    <FullFlex borderRadius="5px" bg="#fff" height="150px" p="20px" style={{ flexDirection: 'column' }}>
                      <FullFlex justifyContent="space-between">
                        <CautionIcon />
                        <Text color="#717171" fontSize="12px" ml="5px">
                          {unverifiedList.length} of the transfers could not be verified because they contained some
                          error or duplicate.
                        </Text>
                      </FullFlex>
                      <Button
                        onClick={() => {
                          setShowUnverified(!showUnverified);
                          setUserId(-1);
                        }}
                        width="100%"
                        variant="large"
                        style={{
                          backgroundColor: '#FFCC00',
                          color: '#121212',
                          fontSize: '12px',
                          height: '40px',
                          marginTop: '10px',
                        }}
                      >
                        {showUnverified ? 'View verified transfers' : 'View unverified transfers'}
                      </Button>
                    </FullFlex>
                    <FullBox mt="10px" p="20px 10px" borderRadius="5px" bg="#fff" height="190px">
                      <FullFlex justifyContent="space-between" alignItems="center" mb="20px">
                        <Text color="#717171" fontSize="14px">
                          Total amount to send
                        </Text>
                        <Text textAlign="right" color="#121212" fontSize="16px" fontWeight="bold">
                          {showUnverified ? formatCurrency(bulkAmountUnverified) : formatCurrency(bulkAmount)}
                        </Text>
                      </FullFlex>
                      <FullFlex justifyContent="space-between" alignItems="center" mb="20px">
                        <Text color="#717171" fontSize="14px">
                          Total verified beneficiary
                        </Text>
                        <Text textAlign="right" color="#121212" fontSize="16px" fontWeight="bold">
                          {verifiedList.length}
                        </Text>
                      </FullFlex>
                      <FullFlex justifyContent="space-between" alignItems="center" mb="20px">
                        <Text color="#717171" fontSize="14px">
                          Total unverified beneficiary
                        </Text>
                        <Text textAlign="right" color="#121212" fontSize="16px" fontWeight="bold">
                          {unverifiedList.length}
                        </Text>
                      </FullFlex>
                    </FullBox>

                    {showUnverified ? (
                      <Bulktransfertable
                        setSelectedData={setSelectedData}
                        setUserId={setUserId}
                        setUserIndex={setUserIndex}
                        showUnverified={showUnverified}
                        tableData={unverifiedList}
                        bulkSum={bulkAmount}
                        formRef={formRef}
                      />
                    ) : (
                      <Bulktransfertable
                        setSelectedData={setSelectedData}
                        setUserId={setUserId}
                        setUserIndex={setUserIndex}
                        showUnverified={showUnverified}
                        tableData={verifiedList}
                        bulkSum={bulkAmount}
                        formRef={formRef}
                      />
                    )}
                  </FullBox>
                )}

                {uploadList.length < 1 && (
                  <FullBox
                    bg="#fff"
                    height="100vh"
                    mt="20px"
                    p="30px"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <FullFlex flexDirection="column" justifyContent="center" alignItems="center">
                      <Text textAlign="center" color="#717171" fontSize="14px">
                        You currently have not added any <br />
                        data yet. kindly upload file or add the data <br /> manually
                      </Text>
                    </FullFlex>
                  </FullBox>
                )}
              </>
            )}
          </FullBox>
        </SplitWrapper>
      </MobileWrapper>
    </FullBox>
  );
}
